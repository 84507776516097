.advantage--ts {
  .section-head {
    &__title {
      font-size: 40px;
      line-height: 48px;
      @include font-600();
      @include maxtablet {
        font-size: 26px;
        line-height: 32px; } } }
  @include maxtablet {
    padding: 64px 16px 30px 16px; } }

.cta-simple--ts {
  .block-cta-alt--simple {
    .block-cta-alt__title {
      font-size: 26px;
      line-height: 32px;
      @include maxtablet {
        font-size: 24px;
        line-height: 32px; } }
    @include maxtablet {
      .container {
        padding-top: 0; } } } }

.pricing--ts {
  background: #143A72;
  color: #FFF;
  padding: 80px 0;
  @include maxtablet {
    padding: 64px 16px; }
  .section-head {
    margin-bottom: 70px;
    @include maxtablet {
      padding-top: 0; }
    &__title {
      color: #FFF;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;
      @include font-600();
      @include maxtablet {
        font-size: 26px;
        line-height: 32px; } } }
  .pricing-cards {
    .card {
      color: #545465;
      &__wrap {
        background: #FFF;
        border-radius: 4px;
        height: 100%; }
      &__head {
        background: #F2F4F7;
        border-bottom: 1px solid #E2E2E2;
        padding: 24px;
        border-radius: 4px 4px  0 0;
        h3 {
          min-height: 65px;
          div {
            position: relative;
            font-size: 18px;
            line-height: 30px;
            display: inline-block;
            span {
              display: block;
              font-size: 24px;
              line-height: 30px;
              @include mobilesm {
                font-size: 21px; } }
            &:nth-child(2) {
              padding-left: 50px;
              @include mobilesm {
                padding-left: 35px; }
              &::before {
                content: '';
                width: 30px;
                height: 30px;
                background: url('../images/ic-round-plus.svg') no-repeat center;
                background-size: contain;
                left: 5px;
                top: calc(50% - 15px);
                display: inline-block;
                position: absolute; } } } }

        .price {
          margin-bottom: 20px;
          .streak {
            display: block;
            text-decoration-line: line-through; }
          .pr {
            font-size: 32px;
            line-height: 40px;
            color: #2B2A35;
            @include font-500(); } } }
      &__toggle {
        margin-bottom: 16px;
        transition: all linear .3s;
        position: relative;
        &::after {
          content: '';
          width: 25px;
          height: 25px;
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
          background: url('../images/ic-chev-up.svg') no-repeat center;
          background-size: contain;
          transform: rotate(0deg);
          transition: all linear .3s; }
        &.collapsed {
          margin-bottom: 0;
          transition: all linear .3s;
          &::after {
            transform: rotate(180deg);
            transition: all linear .3s; } }
        span {
          color: #009BDE;
          cursor: pointer;
          @include font-500(); } }
      &__main {
        padding: 10px 24px;
        transition: all linear .3s;
        &.has-collapse {
          &.open {
            padding: 10px 24px 24px 24px;
            transition: all linear .3s; } }
        .card__collapse-wrap {
          .cat {
            color: #2B2A35;
            margin-bottom: 8px;
            display: block;
            @include font-500(); } } }
      &--highlight {
        .card__wrap {
          border-radius: 0px 0px 4px 4px;
          position: relative;
          &::before {
            content: 'JURNAL PREMIUM';
            display: block;
            color: #FFF;
            background: #009BDE;
            padding: 14px 0;
            text-align: center;
            border-radius: 10px 10px 0 0;
            position: absolute;
            top: -48px;
            width: 100%;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.1em;
            @include font-600(); } }
        .card__head {
          border-radius: 0; } } } } }


.features-block--ts {
  padding: 80px 0;
  &:nth-child(even) {
    .row {
      flex-direction: row-reverse; } }
  @include maxtablet {
    padding: 64px 16px;
    .row {
      flex-direction: column-reverse; }
    .promo-benefit__img {
      display: block; } }
  .testimonial-small {
    .item__box {
      background: #F2F4F7;
      color: #2B2A35; }
    @include maxtablet {
      .testimonial-small__wrap {
        padding: 30px 0 0 0; } } }
  .block-cta-alt--ts {
    .container {
      padding-bottom: 0;
      @include maxtablet {
        padding-left: 0;
        padding-right: 0; } }
    .block-cta-alt__title {
      &.small {
        font-size: 24px;
        line-height: 32px; } } }

  &.bg-gray {
    .testimonial-small {
      .item__box {
        background: #FFF; } } } }
