.modal--custom {
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; }
  .modal-dialog {
    margin: 0 auto;
    max-width: 801px;
    width: 100%;
    background: #E0EEFF;
    border-radius: 8px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    .modal-content {
      background: #E0EEFF;
      padding: 0;
      margin: 0;
      .modal-header {
        padding: 32px 0;
        text-align: center; }
      .modal-body {
        padding: 0 50px 24px 50px; }
      .modal-footer {
        background: #B4D3F2;
        padding: 24px 12px 32px;
        text-align: center;
        border-radius: 0 0 8px 8px; } } }
  @include maxtablet() {
    &::before {
      display: none; }
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 0 12px 24px 12px; } } } } }



.mp {
  &__subtitle {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 24px; }

  &__testimonial {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
    p {
      @include font-600();
      font-size: 20px;
      line-height: 28px; } }

  &__box {
    height: 100%;
    width: 100%;
    text-align: center;
    overflow: hidden;
    margin-bottom: 24px; }

  &__box-head {
    background: #009BDE;
    color: #FFF;
    text-align: center;
    padding: 8px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    @include font-600(); }

  &__box-body {
    background: #FFF;
    color: $text-dark;
    border-radius: 0 0 8px 8px;
    height: calc(100% - 65px); }

  &__box-number {
    display: inline-block;
    width: 132px;
    text-align: center;
    @include font-600();
    font-size: 52px;
    line-height: 56px;
    vertical-align: middle; }

  &__box-desc {
    display: inline-block;
    width: calc(100% - 137px);
    text-align: left;
    vertical-align: middle;
    padding: 12px 8px 12px 0; }

  &__meta {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 182px); }

  &__img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 16px;
    display: inline-block;
    vertical-align: middle;
    img {
      width: auto;
      height: 100%;
      object-fit: cover; } }

  &__info {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 81px); }

  &__name {
    @include font-600(); }

  &__client-logo {
    display: inline-block;
    vertical-align: middle;
    width: 170px;
    padding: 12px 0 12px 12px;
    border-left: 1px solid #009BDE;
    img {
      height: 60px;
      width: auto; } }

  @include maxtablet() {
    &__meta {
      display: block;
      width: 100%;
      margin: 0 auto 18px auto; }
    &__client-logo {
      display: block;
      text-align: left;
      width: 100%;
      padding: 12px 0;
      border: none; } } }

.mp-testi {
  height: 100%;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
  &__head {
    display: inline-block;
    padding: 14px 12px;
    border: 1px solid #009BDE;
    border-bottom: 1px solid transparent;
    border-radius: 8px 8px 0 0;
    color: #009BDE;
    text-align: center;
    background: #FFF;
    span {
      vertical-align: middle;
      @include font-600(); }
    .ico {
      width: 24px;
      height: 24px;
      margin-right: 12px; } }
  &__body {
    padding: 24px 20px 12px;
    background: #FFF;
    border: 1px solid #009BDE;
    border-radius: 8px;
    height: calc(100% - 75px); }
  &__logo {
    margin-bottom: 24px;
    img {
      height: 46px;
      width: auto; } }
  &__content {
    margin-bottom: 24px;
    min-height: 170px; }
  &__meta-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 14px auto;
    img {
      width: auto;
      height: 100%;
      object-fit: cover; } }
  &__name {
    @include font-600(); } }


.modal-success {

  .modal-dialog {
    margin: 0 auto;
    padding: 64px 0;

    .modal-content {
      text-align: center;
      padding: 32px;
      max-width: 632px;
      width: 100%;
      @include maxtablet() {
        padding: 16px; } }

    .modal-icon {
      margin-bottom: 16px;
      img {
        object-fit: contain; } }

    .modal-title {
      @include font-600();
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 16px;
      @include maxtablet() {
        font-size: 28px;
        line-height: 36px; } }

    .modal-desc {
      font-size: 16px;
      line-height: 24px; } } }

.modal-trial {
  width: 100%;
  &__content {
    padding: 24px;
    text-align: center;
    color: #232933; }
  &__action {
    padding: 0 24px 24px;
    text-align: center; }
  .modal-close {
    background: url('../images/ic-cancel-blue.svg') no-repeat;
    background-size: contain; }
  .modal-dialog {
    display: flex;
    align-items: center;
    max-width: 340px;
    min-height: calc(100vh - 96px);
    margin: 48px auto; }
  .modal-content {
    border-radius: 8px;
    padding: 0; }
  @include maxtablet() {
    .modal-content {
      margin-left: 16px;
      margin-right: 16px; } } }
