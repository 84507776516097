.clients-block {
  padding: 64px 0;
  &__title {
    margin-bottom: 24px;
    text-align: center; }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 845px;
    margin: 0 auto;
    li {
      display: inline-flex;
      height: 56px;
      width: auto;
      margin-bottom: 18px;
      padding: 0 28px;
      align-items: center;
      img {
        object-fit: contain;
        width: auto; }
      &.br {
        display: block;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 0; } } }
  @include maxtablet() {
    padding: 32px 8px;
    &__title {
      br {
        display: none; } }
    &__list {
      justify-content: center;
      li {
        padding: 0 8px;
        height: 55px;
        &.br {
          display: none; } } } } }


.product-featured--alt {
  background: #F2F4F7;
  .nav-container {
    .nav-tabs {
      .nav-item {
        .nav-link {
          background: #F2F4F7;
          &::after {
            background-color: #FFF; } } } } }
  .tab-content {
    background: #FFF; }
  @include maxtablet {
    padding: 32px 12px;
    .nav-container {
      position: absolute;
      background: #FFF;
      border: 1px solid $border;
      width: calc(100% - 24px);
      .nav-tabs {
        .nav-item {
          display: none;
          padding-left: 0;
          padding-right: 0;
          border: 1px solid $border;
          a.nav-link {
            background: #FFF; }
          &.active {
            display: block;
            border: none; } }
        &.show {
          .nav-item {
            display: block; } } } }
    .tab-content {
      margin-top: 75px;
      .tab-pane {
        padding: 12px 0 12px;
        .feature__wrap {
          .row {
            margin-bottom: 32px;
            &:last-child {
              margin-bottom: 0; } } } } } } }



.feature__wrap {
  .row {
    margin-bottom: 47px;
    &:nth-child(even) {
      flex-direction: row-reverse; } }
  .feature__content {
    text-align: left; }
  .feature__title {
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 8px!important; }
  .feature__desc {
    margin-bottom: 24px; } }


.tab-cs--single {
  padding: 64px 0;
  @include maxtablet {
    padding: 32px 8px;
    .tab-cs__content {
      visibility: visible;
      display: flex;
      opacity: 1;
      height: auto;
      .tab-cs__testimonial {
        padding: 20px; }
      .tab-cs__stats {
        padding: 20px; } } } }

.form-side {
  padding: 64px 0;
  background: #F2F4F7;
  @include maxtablet {
    padding: 32px 8px;
    &__head {
      margin-bottom: 26px; }
    &__img {
      margin-top: 16px;
      display: none; } } }

