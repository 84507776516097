.compliance {
  padding: 64px 0;
  &__title {
    text-align: center;
    margin-bottom: 10px; }
  &__desc {
    text-align: center;
    margin-bottom: 40px; }
  .item {
    padding: 50px 32px;
    text-align: center;
    background: #F1F5F9;
    backdrop-filter: blur(7.44421px);
    border-radius: 8px;
    height: 100%;
    margin-bottom: 24px;
    &__img {
      margin-bottom: 29px;
      img {
        height: 120px;
        width: auto; } } }
  @include maxtablet() {
    padding: 32px 0;
    .item {
      margin-bottom: 24px;
      height: calc(100% - 24px); } } }
