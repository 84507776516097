.compare-cards {
  &__title {
    text-align: center;
    @include maxtablet() {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 24px;
      br {
        display: none; } } }
  &__features {
    margin-bottom: 32px;
    .item {
      &__img {
        margin-bottom: 8px;
        img {
          display: block;
          width: 48px;
          height: 48px;
          object-fit: contain; } }
      &__title {
        font-size: 20px;
        line-height: 28px;
        @include font-500();
        color: #232933;
        margin-bottom: 8px; }
      &__wrap {
        padding: 32px; } }
    @include maxtablet() {
      margin-bottom: 0;
      .item {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 24px;
        &__img {
          position: absolute;
          top: 0;
          left: 0;
          img {
            display: block;
            width: 24px;
            height: 24px; } }
        &__title {
          font-size: 16px;
          line-height: 24px; }
        &__wrap {
          position: relative;
          padding: 0 0 0 36px; } } } }

  &__compare {
    justify-content: center;
    padding: 64px 8px;
    .compare-nav {
      display: none; }
    .item {
      &__head {
        padding: 24px;
        background: #EDF0F2;
        text-align: center;
        border-bottom: 1px solid #8B95A5;
        .sub {
          display: block;
          color: #232933; }
        .title {
          font-size: 20px;
          line-height: 28px;
          color: #232933;
          font-weight: 600; } }
      &__body {
        padding: 24px;
        .title {
          font-size: 16px;
          line-height: 24px;
          color: #232933;
          font-weight: 600;
          margin-bottom: 24px; }
        .custom-list {
          b {
            display: block;
            color: #232933; }
          li {
            margin-bottom: 32px;
            &:last-child {
              margin-bottom: 0; } } } }
      &__wrap {
        border: 1px solid #8B95A5;
        border-radius: 12px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column; }
      &--highlight {
        .item__head {
          background: #0055A2;
          border-bottom-color: #0055A2;
          .sub {
            color: #FFF; }
          .title {
            color: #FFF; } }
        .item__wrap {
          border-color: #0055A2;
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12); } } }
    @include maxtablet() {
      padding-top: 48px;
      padding-bottom: 48px;
      .compare-nav {
        display: block;
        position: relative;
        z-index: 2;
        &__wrap {
          display: flex;
          position: relative; }
        .nav-link {
          display: block;
          position: relative;
          width: 100%;
          text-align: center;
          border-radius: 12px 12px 0 0;
          background: #EDF0F2;
          padding: 12px 6px;
          border: 1px solid transparent;
          &.nav-link--highlight {
            &.active {
              border-color: #0055A2;
              .title {
                color: #0055A2; } } }
          &.active {
            border-color:#8B95A5 {}
            background: #FFF;
            .title {
              font-size: 20px;
              line-height: 28px; }
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 2px;
              position: absolute;
              top: 100%;
              left: 0;
              background: #FFF; } }
          .sub {
            font-size: 14px;
            line-height: 23px; }
          .title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600; } } }
      .item {
        display: none;
        &.active {
          display: block; }
        &__head {
          display: none; }
        &__wrap {
          border-radius: 0 0 12px 12px; }
        &--highlight {
          .item__wrap {
            top: auto; } } } } }

  &__nav {
    max-width: 880px;
    margin: 0 auto 32px;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: calc(100% + 24px);
      height: 1px;
      background: #CFCDC8;
      position: absolute;
      bottom: 0;
      left: -12px; }
    .nav-sm {
      display: none; }
    .nav {
      display: flex;
      justify-content: center;
      &-item {
        position: relative;
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
        // max-width: 16.666667%
 }        // flex: 0 0 16.666667%
      &-link {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        height: 80px;
        border-bottom: 2px solid transparent;
        img {
          display: block;
          max-height: 40px;
          max-width: 100%;
          height: auto;
          width: auto;
          // filter: grayscale(100)
          filter: brightness(0);
          opacity: .5; }
        &.active {
          border-bottom-color: #0055A2;
          img {
            filter: none;
            opacity: 1; } } } }
    .owl-nav {
      position: absolute;
      width: 100%;
      height: 0;
      left: 0;
      top: 50%;
      .owl-prev,
      .owl-next {
        position: absolute;
        left: -56px;
        top: calc(100% - 28px);
        outline: none !important;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
          background-image: url(../images/ic-page-left.svg);
          background-size: auto 32px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 0.4;
          transition: all .4s ease-in-out; }
        &.disabled {
          opacity: 0; }
        &:hover {
          span {
            opacity: 1; } } }
      .owl-next {
        left: auto;
        right: -56px;
        span {
          background-image: url(../images/ic-page-right.svg); } } }
    @include maxtablet() {
      &::after {
        display: none; }
      .nav-tabs {
        display: none; }
      .nav-sm {
        display: block;
        position: relative;
        z-index: 10;
        .nav {
          display: none;
          position: absolute;
          top: 60px;
          left: 0;
          width: 100%;
          background: #FFF;
          border: 1px solid $border;
          border-radius: 4px;
          padding: 6px 0; } }
      .nav-sm__selected {
        position: relative;
        display: block;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        color: #232933;
        display: block;
        height: auto;
        min-height: 52px;
        padding: 12px 32px 12px 0;
        border-bottom: 1px solid #0055A2;
        &::after {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 12px;
          right: 0;
          background: url(../images/ic-dropdown.svg) no-repeat center;
          background-size: contain; } }
      .nav-item {
        max-width: 100%;
        flex: 0 0 100%; }
      .nav-link {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #232933;
        display: block;
        height: auto;
        min-height: 40px;
        padding: 6px 12px;
        border: 0;
        &:hover {
          text-decoration: none; } } } }

  &__wrap {
    padding-top: 32px;
    padding-bottom: 32px; } }


.three-blocks {
  padding: 64px 8px;
  &__title {
    text-align: center;
    margin-bottom: 8px; }
  .item {
    padding: 32px;
    position: relative;
    &__icon {
      height: 32px;
      width: 32px;
      margin-bottom: 8px;
      img {
        object-fit: contain;
        width: 100%;
        height: auto; } }
    &__title {
      @include font-500();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px; } }
  @include maxtablet() {
    padding: 32px 8px;
    &__title {
      br {
        display: none; } }
    .item {
      padding: 12px 12px 12px 33px;
      &__icon {
        position: absolute;
        left: 0;
        width: 24px;
        height: 24px; } } } }


.video-block {
  padding: 64px 20px;
  .mpay-video {
    padding: 0;
    &__title {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 24px; }
    &__buttons {
      margin-top: 24px;
      text-align: center;
      a,.btn {
        margin-right: 14px;
        &:last-child {
          margin-right: 0; } } } }
  @include maxtablet() {
    padding: 32px 20px;
    .mpay-video {
      &__title {
        font-size: 28px;
        line-height: 36px; }
      &__buttons {
        a,.btn {
          width: 100%;
          margin-right: 0;
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0; } } } } } }


.pricing-block {
  padding: 64px 0;
  &__title {
    margin-bottom: 24px;
    text-align: center; }
  .pricing-table {
    padding-bottom: 0; }
  .pricing-table--alt {
    .pricing-table__td,
    .pricing-table__th {
      min-width: 25%; }
    .pricing-table__tr {
      .pricing-table__th {
        .head--alt {
          text-align: center;
          .head__title {
            color: #0055A2;
            margin-bottom: 24px; }
          .head__price {
            .pr {
              font-size: 24px;
              line-height: 32px;
              small {
                padding-left: 6px;
                font-size: 14px;
                line-height: 20px;
                vertical-align: middle; } } } } } } }
  .pricing-card {
    .head--alt {
      text-align: center;
      .head__title {
        color: #0055A2;
        margin-bottom: 24px; }
      .head__price {
        .pr {
          font-size: 24px;
          line-height: 32px;
          small {
            padding-left: 6px;
            font-size: 14px;
            line-height: 20px;
            vertical-align: middle; } } } }
    .pricing-card__slider {
      .owl-stage-outer {
        padding-bottom: 16px; } }
    .pricing-card__nav {
      .owl-dots__wrap {
        width: 100%;
        .owl-dots {
          .owl-dot {
            border: 0; } } } } }

  @include maxtablet() {
    padding: 32px 8px; } }


.list-block {
  padding: 64px 0;
  &__desc {
    margin-bottom: 24px; }
  @include maxtablet() {
    padding: 32px 8px; } }
