.single-price-card {
  padding: 64px 0;
  .badge {
    font-size: 14px;
    line-height: 20px;
    color: #FFF;
    padding: 2px 6px;
    border-radius: 12px;
    margin-bottom: 10px;
    &--red {
      background: #EF4444; } }
  &__meta {
    @include font-600();
    margin-bottom: 8px; }
  &__title {
    margin: 0 auto 60px;
    position: relative;
    max-width: 545px;
    width: 100%;
    &:after {
      content: '';
      height: 40px;
      width: 60%;
      background: url(../images/red-line.png) no-repeat center;
      background-size: contain;
      position: absolute;
      bottom: -30px;
      left: 20%; } }

  .card-price {
    padding: 24px;
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    justify-content: center;
    position: relative;
    border-radius: 6px;
    border: 1px solid #009BDE;
    background:#FFF {}
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 24px 48px 0px rgba(209, 209, 209, 0.75);
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      gap: 24px;
      position: relative; }
    &__title {
      @include font-600();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      text-align: center; }
    &__meta {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 24px;
      .price {
        font-size: 20px;
        line-height: 32px;
        width: 100%;
        flex: 0 0 100%;
        @include font-600();
        .tenor {
          display: inline-flex;
          font-size: 14px;
          line-height: 20px;
          @include font-400(); } } }
    &__action {
      margin-bottom: 24px;
      width: 100%;
      a.btn {
        width: 100%; } }
    &__floating {
      position: absolute;
      width: 227px;
      top: 0;
      right: calc(-227px - 40px);
      font-family: 'Permanent Marker', cursive;
      font-size: 32px;
      line-height: normal;
      color: #000;
      &::after {
        content: '';
        width: 75px;
        height: 75px;
        background: url(../images/arrow-testimonial.png) no-repeat center;
        background-size: contain;
        position: absolute;
        bottom: -70px;
        left: 0;
        transform: rotate(295deg); } } }

  @include maxtablet() {
    padding: 32px 0;
    .card-price {
      &__floating {
        display: none; } } } }
