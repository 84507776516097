.video-block {
  padding: 64px 0;
  text-align: center;
  &__title {
    margin-bottom: 8px; }
  &__frame {
    margin-top: 24px;
    max-height: 376px;
    .media {
      img {
        max-height: 376px;
        object-fit: cover;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border-radius: 8px; } } }
  .item {
    border: 1px solid #D0D6DD;
    border-radius: 4px;
    padding: 24px;
    height: 100%;
    text-align: left;
    &__wrap {
      margin-top: 24px; }
    &__icon {
      margin-bottom: 8px;
      img {
        height: 52px;
        width: auto; } }
    &__title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      @include font-600(); }
    &__desc {
      color: #232933;
      p {
        margin-bottom: 0; }
      strong {
        font-size: 20px;
        line-height: 28px;
        @include font-600();
        margin-top: 8px;
        display: inline-block; } } }

  @include maxtablet() {
    .item {
      margin-bottom: 24px;
      height: calc(100% - 24px); } }
  @include mobilelg() {
    padding: 32px 0;
    &__title {
      br {
        display: none; } } } }
