.fea-cta {
  padding: 64px 0;
  .container {
    background: #EAECFB;
    padding: 26px 32px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    .section__title {
      margin-bottom: 12px; } }
  @include maxtablet() {
    padding: 32px 0;
    margin-left: 16px;
    margin-right: 16px;
    .container {
      padding: 24px; } } }


.steps--box {
  margin-left: 16px;
  margin-right: 16px;
  .container {
    background: #F2F4F7;
    border-radius: 8px;
    padding: 24px; }
  @include maxtablet() {
    .container {
      .row {
        flex-direction: column-reverse; } } } }


.invoice-generator {
  .masthead {
    padding: 64px 0;
    @include maxtablet() {
      padding: 32px 0; } }
  &:first-child .invoice-generator__wrap {
    padding-top: 8rem; }
  &__wrap {
    padding: 64px 0;
    background: #F2F4F7;
    font-size: 14px;
    line-height: 1.5em; }
  label {
    display: block;
    font-size: 14px;
    line-height: 1.5em;
    color: $black;
    @include font-500;
    margin: 0.5rem 0;
    &.sr-only {
      opacity: 0; } }
  .form-control {
    display: block;
    width: 100%;
    border: 1px solid $border;
    height: 38px;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 1.5em; }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  input[type=number] {
    -moz-appearance: textfield; }
  textarea.form-control {
    height: auto;
    resize: none; }
  .form-upload {
    position: relative;
    &__button {
      input {
        display: block;
        opacity: 0;
        visibility: hidden;
        z-index: -10;
        height: 0;
        position: absolute; }
      label {
        display: block;
        width: 100%;
        padding: 1.5rem;
        border: 2px dashed $border;
        text-align: center;
        color: #777;
        cursor: pointer;
        font-size: 14px;
        line-height: 1.5em;
        @include font-400; }
      span.edit {
        display: none; } }
    &__preview {
      display: none;
      img {
        max-height: 90px; } }
    &.has-img {
      .form-upload__button {
        label {
          width: auto;
          display: inline-block;
          padding: 6px 12px;
          border: 1px solid $border;
          border-radius: 3px;
          color: #545465;
          background: #F2F4F7;
          @include font-500; }
        span.edit {
          display: block; }
        span.add,
        span.icon {
          display: none; } } }
    @include maxtablet {
      padding-bottom: 24px;
      border-bottom: 1px solid $border; } }

  .form-section {
    margin-bottom: 2rem;
    &--table {
      width: auto; }
    &--action {
      width: auto;
      margin-bottom: 0;
      border-top: 1px solid $border;
      text-align: right;
      padding: 2rem 2rem 0;
      .btn + .btn {
        margin-left: 1rem; }
      .btn-blue {
        min-width: 10rem; } }
    @include maxtablet() {
      margin-bottom: 12px; } }

  .form-row {
    align-items: center;
    margin-bottom: 0.75rem; }

  // .table
  //   width: 100%
  //   .tooltip-inner
  //     border-radius: 4px
  //     font-size: 14px
  //     line-height: 1.5em
  //   td
  //     border-bottom: 1px solid $border
  //   thead
  //     background: #f1f1f1
  //     border-radius: 4px
  //     overflow: hidden
  //     border: 1px solid #D0D6DD
  //     th
  //       padding: 10px 12px
  //       +font-600
  //       font-size: 14px
  //       line-height: 20px
  //       text-align: left
  //       vertical-align: top
  //       border-bottom: 1px solid #D0D6DD
  //   tbody
  //     td
  //       vertical-align: middle
  //       input
  //         border: 1px solid #D0D6DD
  //         background: #FFF
  //         padding: 10px 1px
  //         display: block
  //         width: 100%
  //         text-align: left
  //       textarea
  //         border: 1px solid #D0D6DD
  //         background: #FFF
  //         padding: 10px 1px
  //         display: block
  //         width: 100%
  //         text-align: left

  //   .btn-del
  //     display: inline-block
  //     cursor: pointer
  //   .item--template
  //     display: none
  #itemsunit {
    thead {
      th {} }
    tbody {
      // td
      //   &:nth-child(2),
      //   &:nth-child(3),
      //   &:nth-child(6)
      //     width: 6rem
      //   &:nth-child(3)
      //     input
      //       text-align: left
      //   &:nth-child(4),
      //   &:nth-child(5),
      //   &:nth-child(7)
      //     width: 11rem
      //   &:nth-child(8)
      //     width: 3.5rem
 } }      //     text-align: center
  #tablePyd {
    tbody {
      // td
      //   &:nth-child(1)
      //     input
      //       text-align: left
      //   &:nth-child(2)
      //     width: 6rem
      //   &:nth-child(3)
      //     width: 11rem
      //   &:nth-child(4)
      //     width: 3.5rem
 } }      //     text-align: center
  // .sum-action
  //   .btn
  //     border-radius: 3px
  //     padding: 0 1rem
  //     color: #545465
  //     +font-500
  //     font-size: 14px
  //     line-height: 32px
  //     background: #F2F4F7
  //     border: 1px solid $border
  //     &:hover
  //       background: #f1f1f1
  //       border: 1px solid darken($border, 5%)
  //     &.btn-plus
  //       &:before
  //         content: '\f067'
  //         font-family: FontAwesome
  //         font-size: 14px
  //         color: #777
  //         margin-right: 10px
  //     &+ .btn
  //       margin-left: 1rem
  .list-total {
    display: block;
    padding: 0 1.5rem 0 0;
    margin: 0;
    color: #212121;
    font-size: 14px;
    line-height: 1.5em;
    @include font-400;
    li {
      display: flex;
      p {
        display: block;
        width: 100%;
        max-width: 40%;
        flex: 0 0 40%;
        margin: 0 0 0.5rem;
        &.number {
          text-align: right;
          max-width: 60%;
          flex: 0 0 60%; } } }
    .list-total__final {
      border-top: 1px solid $border;
      padding: 0.5rem 0 0;
      font-size: 16px;
      line-height: 1.5em;
      @include font-500;
      @include maxtablet() {
        margin-bottom: 0; } }
    .list-total__pyd {
      padding: 0.75rem 0 0;
      border-top: 1px solid $border;
      display: none;
      &.show {
        display: flex; } }
    .list-total__tyhb {
      display: none; } }
  .sum-notes {
    display: none; }
  .notes {
    .notes_label {
      @include font-600();
      margin-bottom: 4px; }
    .notes_value {
      textarea {
        max-width: 432px; } } }
  .watermark {
    position: absolute;
    bottom: 0;
    left: 5px;
    visibility: hidden;
    opacity: 0; }
  &__box {
    padding: 24px;
    background: #FFF;
    //box-shadow: 0px 2px 4px rgba(0,0,0,0.1)
    border-radius: 8px;
    max-width: 936px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
    &.ready-to-print {
      width: 100%;
      max-width: 595pt;
      .form-upload__preview {
        img {
          max-height: 90px;
          width: auto;
          height: 100%; } }
      .form-upload__button {
        display: none;
        #invLogo,span.edit,span.add {
          display: none; } }
      .form-control {
        border: 0;
        padding: 0;
        margin-bottom: 5px;
        height: auto; }
      input[type="date"]::-webkit-inner-spin-button,
      input[type="date"]::-webkit-calendar-picker-indicator {
          display: none;
          -webkit-appearance: none; }
      .form-row {
        margin-bottom: 12px; }
      .sr-only {
        display: none; }
      .sum-action {
        visibility: hidden;
        opacity: 0;
        display: none; }
      .form-section {
        &.line {
          border-top: 1px solid #D0D6DD;
          padding-top: 24px; }
        .row {
          .reverse-sm {
            flex-direction: unset; } } }
      .form-section--action {
        display: none; }
      .form-section--table {
        margin: 0 auto; }
      .sum-notes {
        display: flex;
        position: relative;
        .watermark {
          position: absolute;
          bottom: 0;
          max-width: 500px;
          font-size: 14px;
          line-height: 20px; }
        &> div {
          display: block;
          margin-bottom: 8px; }
        .notes_value {
          display: block;
          @include font-400(); } }
      .notes {
        .notes_label {
          @include font-600(); } }
      span.print {
        display: block;
        visibility: visible;
        opacity: 1;
        margin-bottom: 4px; }
      .invoice_name {
        @include font-600();
        font-size: 20px;
        line-height: 28px;
        padding: 0;
        text-align: right; }
      input,
      textarea {
        user-select: none; }
      label {
        margin: 0; }
      .list-total {
        padding: 0 20px 0 0;
        margin: 0 0 0 120px;
        li {
          &.pemotongan,
          &.deposito {
            display: none; } } }
      .list-total__final {
        border-bottom: 1px solid $border; }
      .watermark {
        position: absolute;
        bottom: 0;
        left: 10px; }
      .table-wrap {
        .table {
          thead {
            tr {
              th {
                padding: 10px;
                &:first-child {
                  padding-left: 10px; }
                &:last-child {
                  display: none; } }
              th:nth-child(7) {
                border-right: 1px solid #E2E2E2; } } }
          tbody {
            tr {
              td {
                &:first-child {
                  padding-left: 0; }
                &:last-child {
                  display: none; }
                input {
                  height: auto;
                  user-select: none;
                  border: none;
                  padding: 10px 8px;
                  &.item_price,
                  &.item_total {
                    min-width: 135px;
                    max-width: 135px; } }
                &::before {
                  background: transparent; }
                textarea {
                  border: none;
                  min-width: 150px;
                  max-width: 150px; }
                &.b_tax,
                &.b_disc {
                  input {
                    padding: 8px 38px 8px 2px; } }
                &.b_total,
                &.b_price {
                  input {
                    padding: 8px 2px 8px 38px; } }
                .show-sm {
                  display: none; } } } } } }
      @include maxtablet() {
        .col-md-2 {
          flex: 0 0 16.666667%;
          max-width: 16.666667%; }
        .col-md-5 {
          flex: 0 0 41.666667%;
          max-width: 41.666667%; }
        .col-md-6 {
          flex: 0 0 50%;
          max-width: 50%; }
        .col-md-7 {
          flex: 0 0 58.333333%;
          max-width: 0 0 58.333333%; }
        .col-md-10 {
          flex: 0 0 83.333333%;
          max-width: 83.333333%; }
        .offset-md-2 {
          margin-left: 16.666667%; }
        table {
          thead {
            th {
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6),
              &:nth-child(7),
              &:nth-child(8), {
                display: table-cell; } } }
          tbody {
            td {
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6),
              &:nth-child(7),
              &:nth-child(8), {
                display: table-cell; }
              &:nth-child(2) {
                padding-top: 0; }
              .show-sm {
                display: none!important; } } } }
        .form-section {
          &.line {
            border-top: 1px solid #D0D6DD;
            padding-top: 24px; }
          &.row {
            &.reverse-sm {
              flex-direction: unset; } } } } }

    label {
      display: block;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      @include font-500;
      margin: 0.5rem 0;
      &.sr-only {
        opacity: 0; } }
    .form-control {
      display: block;
      width: 100%;
      border: 1px solid $border;
      height: 38px;
      padding: 6px 12px;
      font-size: 14px;
      border-radius: 6px;
      line-height: 20px; }
    span.print {
      color: #8B95A5;
      font-size: 12px;
      line-height: 16px;
      display: none;
      visibility: hidden;
      opacity: 0; }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    input[type=number] {
      -moz-appearance: textfield; }
    textarea.form-control {
      height: auto;
      resize: none; }
    .form-upload {
      position: relative;
      &__button {
        input {
          display: block;
          opacity: 0;
          visibility: hidden;
          z-index: -10;
          height: 0;
          position: absolute; }
        label {
          display: block;
          width: 100%;
          padding: 12px;
          border: 1px dashed #4B61DD;
          border-radius: 4px;
          text-align: center;
          color: #777;
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;
          background: #EAECFB;
          margin: 0;
          @include font-400; }
        span.icon {
          display: block;
          text-align: center;
          margin-bottom: 10px; }
        span.edit {
          display: none; } }
      &__preview {
        display: none; }
      &.has-img {
        .form-upload__button {
          label {
            width: auto;
            display: inline-block;
            padding: 6px 12px;
            border: 1px solid $border;
            border-radius: 3px;
            color: #545465;
            background: #F2F4F7;
            @include font-500; }
          span.edit {
            display: block; }
          span.add,
          span.icon {
            display: none; } } } }

    .form-section {
      margin-bottom: 24px;
      &.line {
        border-top: 1px solid #D0D6DD;
        padding-top: 24px; }
      &--table {
        width: auto;
        margin-left: 0;
        margin-right: 0; }
      &--action {
        width: auto;
        margin-left: -2rem;
        margin-right: -2rem;
        margin-bottom: 0;
        border-top: 1px solid $border;
        text-align: right;
        padding: 2rem 2rem 0;
        .btn + .btn {
          margin-left: 1rem; }
        .btn-blue {
          min-width: 10rem; } }
      @include maxtablet() {
        margin-bottom: 12px;
        &--action {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          padding: 0;
          .btn + .btn {
            margin-left: 0; } }
        &.row {
          &.reverse-sm {
            flex-direction: column-reverse; } }
        &.line {
          padding-top: 0;
          border-top: none;
          .form-row.row {
            .col-md-6 {
              .form-row.row {
                margin-bottom: 12px; } } } } } }
    .form-row {
      align-items: center;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0; }
      // margin-bottom: 0.75rem
      @include maxtablet() {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 24px; }
        &.margin-sm {
          margin-top: 24px; } } }
    .table {
      width: 100%;
      border: 1px solid $border;
      .tooltip-inner {
        border-radius: 4px;
        font-size: 14px;
        line-height: 1.5em; }
      thead {
        background: #EDF0F2;
        th {
          padding: 10px 12px;
          @include font-600;
          font-size: 14px;
          line-height: 1.5em;
          text-align: left;
          vertical-align: top;
          border-top: 1px solid $border; } }
      tbody {
        tr {
          border-bottom: 1px solid $border; }
        td {
          vertical-align: top;
          &:first-child {
            text-align: center;
            padding-top: 10px; }
          input {
            border: 1px solid #D0D6DD;
            background: #FFF;
            padding: 8px 12px;
            display: block;
            width: 100%;
            text-align: left;
            border-radius: 6px;
            &.item_qty {
              text-align: center; }
            &.item_qty,
            &.item_unit {
              min-width: 55px;
              max-width: 55px; }
            &.item_price,
            &.item_total {
              min-width: 144px;
              max-width: 144px;
              position: relative;
              padding-left: 38px;
              text-align: right; }
            &.item_disc,
            &.item_tax {
              min-width: 70px;
              max-width: 70px;
              padding-right: 38px;
              text-align: right; } }
          textarea {
            border: 1px solid #D0D6DD;
            background: #FFF;
            padding: 8px 12px;
            display: block;
            width: 100%;
            min-width: 260px;
            text-align: left;
            border-radius: 6px;
            overflow: hidden; }
          &.b_price,
          &.b_total {
            position: relative;
            &::before {
              content: 'Rp';
              width: 30px;
              height: 34px;
              display: inline-block;
              position: absolute;
              left: 3px;
              top: 3px;
              z-index: 2;
              background: #EDF0F2;
              border-radius: 4px 0px 0px 4px;
              text-align: center;
              padding: 6px 0;
              @include font-600(); } }
          &.b_tax,
          &.b_disc {
            position: relative;
            &::before {
              content: '%';
              width: 30px;
              height: 34px;
              display: inline-block;
              position: absolute;
              right: 3px;
              top: 3px;
              z-index: 2;
              background: #EDF0F2;
              border-radius: 0px 4px 4px 0px;
              text-align: center;
              padding: 7px 0;
              @include font-600(); } }
          &.b_del {
            padding-top: 8px; } } }
      .btn-del {
        display: inline-block;
        cursor: pointer; }
      .item--template {
        display: none; }
      @include maxtablet() {
        thead {
          th {
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8), {
              display: none; } } }
        tbody {
          td {
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8), {
              display: none; }
            &:nth-child(2), {
              padding-top: 10px; }
            .show-sm {
              margin-top: 10px;
              .row {
                margin-bottom: 10px;
                .col-6,
                .col-12 {
                  input,
                  textarea {
                    width: 100%;
                    max-width: 100%;
                    &::placeholder {
                      text-align: left; } }
                  &.b_price,
                  &.b_total {
                    position: relative;
                    &::before {
                      content: 'Rp';
                      width: 30px;
                      height: 34px;
                      display: inline-block;
                      position: absolute;
                      left: 14px;
                      top: 2px;
                      z-index: 2;
                      background: #EDF0F2;
                      border-radius: 4px 0px 0px 4px;
                      text-align: center;
                      padding: 6px 0;
                      @include font-600(); } }
                  &.b_tax,
                  &.b_disc {
                    position: relative;
                    &::before {
                      content: '%';
                      width: 30px;
                      height: 34px;
                      display: inline-block;
                      position: absolute;
                      right: 14px;
                      top: 2px;
                      z-index: 2;
                      background: #EDF0F2;
                      border-radius: 0px 4px 4px 0px;
                      text-align: center;
                      padding: 7px 0;
                      @include font-600(); } } } } } } } } }
    #itemsunit {
      thead {
        th {} }
      tbody {
        // td
        //   &:nth-child(2),
        //   &:nth-child(3),
        //   &:nth-child(6)
        //     width: 6rem
        //   &:nth-child(3)
        //     input
        //       text-align: left
        //   &:nth-child(4),
        //   &:nth-child(5),
        //   &:nth-child(7)
        //     width: 11rem
        //   &:nth-child(8)
        //     width: 3.5rem
 } }        //     text-align: center
    #tablePyd {
      tbody {
        td {
          // &:nth-child(1)
          //   input
          //     text-align: left
          // &:nth-child(2)
          //   width: 6rem
          // &:nth-child(3)
          //   width: 11rem
          // &:nth-child(4)
          //   width: 3.5rem
 } } }          //   text-align: center
    .sum-action {
      margin-bottom: 24px; }
    // .sum-action
    //   .btn
    //     border-radius: 3px
    //     padding: 0 1rem
    //     color: #545465
    //     +font-500
    //     font-size: 14px
    //     line-height: 32px
    //     background: #F2F4F7
    //     border: 1px solid $border
    //     &:hover
    //       background: #f1f1f1
    //       border: 1px solid darken($border, 5%)
    //     &.btn-plus
    //       &:before
    //         content: '\f067'
    //         font-family: FontAwesome
    //         font-size: 14px
    //         color: #777
    //         margin-right: 10px
    //     &+ .btn
    //       margin-left: 1rem
    .list-total {
      display: block;
      padding: 0 30px 0 0;
      margin: 0 0 0 45px;
      color: #212121;
      font-size: 14px;
      line-height: 1.5em;
      align-items: center;
      justify-content: space-between;
      @include font-400;
      li {
        display: flex;
        margin-bottom: 16px;
        p {
          display: block;
          width: 100%;
          max-width: 30%;
          flex: 0 0 30%;
          margin: 0 0 0.5rem;
          &.number {
            text-align: right;
            max-width: 70%;
            flex: 0 0 70%; } } }
      .subtotal {
        &> p {
          @include font-600(); } }
      .pemotongan,
      .deposito {
        p {
          padding: 10px 0; }
        .form-group {
          margin-left: 25px;
          position: relative;
          input {
            text-align: right; }
          &::before {
            content: 'Rp';
            width: 30px;
            height: 34px;
            display: inline-block;
            position: absolute;
            left: 3px;
            top: 3px;
            z-index: 2;
            background: transparent;
            border-radius: 4px 0px 0px 4px;
            text-align: center;
            padding: 6px 0;
            @include font-600(); } } }

      .list-total__final {
        border-top: 1px solid $border;
        padding: 0.5rem 0 0;
        font-size: 16px;
        line-height: 24px;
        @include font-600(); }
      .list-total__pyd {
        padding: 0.75rem 0 0;
        border-top: 1px solid $border;
        display: none;
        &.show {
          display: flex; } }
      .list-total__tyhb {
        font-size: 16px;
        line-height: 1.5em;
        display: none;
        @include font-500;
        &.show {
          display: flex; } }
      @include maxtablet {
        padding: 0;
        margin: 32px 0 0 0; } } }

  &.modal {
    .modal-dialog {
      padding: 5rem 2rem 2rem;
      margin: 15vh auto;
      .modal-content {
        padding: 20px;
        .form-section--action {
          padding-right: 0;
          padding-left: 0; } } } } }



.modal-preview {
  .modal-dialog {
    width: 100%;
    max-width: 595pt;
    margin: 0 auto; }
  .modal-content {
    padding: 0;
    .close {
      top: -20px;
      right: -32px;
      filter: invert(1); } } }


.modal-agreement {
  .modal-dialog {
    margin: 0 auto;
    max-width: 632px;
    .modal-content {
      padding: 32px;
      .modal-body {
        text-align: center;
        .on-progress {
          .icon {
            margin-bottom: 16px;
            img {
              height: 60px;
              width: auto; } } }
        .on-load,
        .on-stop {
          .icon {
            margin-bottom: 16px;
            img {
              height: 150px;
              width: auto; } }
          .label {
            @include font-600();
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px; }
          .form-mini {
            label {
              @include font-400();
              color: #232933;
              font-size: 14px;
              line-height: 20px; }
            .custom-checkbox {
              text-align: left; } } }
        .on-stop {
          .label {
            margin-bottom: 16px; }
          .p {
            margin-bottom: 24px; } } } } } }
