.faq-block {
  padding: 64px 0;
  background: #F9FBFD;
  .card {
    &__body {
      ul {
        padding-left: 20px;
        list-style: disc;
        li {
          margin-bottom: 4px; } } } }
  .section-head__action {
    padding-top: 16px;
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; } } }
  &--pricing {
    background: transparent; }

  &.animate {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .accordion--faq {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .section-head__content {
      transition-delay: .2s; }
    .section-head__action {
      transition-delay: .2s; }
    .accordion--faq {
      transition-delay: .2s; } }
  &.animate-in {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .accordion--faq {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include maxtablet {
    padding: 48px 8px;
    .section-head__action {
      a {
        margin-right: 0;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } } }

  .accordion {
    position: relative;
    margin-bottom: 32px;
    .card {
      position: relative;
      margin-bottom: 4px;
      &__header {
        position: relative;
        display: block;
        padding: 20px 56px 20px 20px;
        cursor: pointer;
        border-bottom: 1px solid #E2E2E2;
        transition: all .2s ease-in-out;
        &:after {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          background: url(../images/ic-chevron-down.svg) no-repeat center;
          background-size: contain;
          position: absolute;
          top: 20px;
          right: 20px;
          transform: rotate(180deg);
          transition: all .2s ease-in-out; }
        &.collapsed:after {
          transform: none; } }
      &__title {
        font-size: 16px;
        line-height: 24px;
        display: block;
        @include font-500();
        margin: 0;
        padding: 0; }
      &__body {
        background: #FFF;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75);
        padding: 24px 20px;
        margin-top: 12px;
        .img-sm {
          display: none;
          margin-top: 24px;
          @include mobile {
            text-align: center;
            display: inline-block;
            width: 100%;
            height: auto; } } }
      &__action {
        a {
          margin-right: 14px;
          &:last-child {
            margin-right: 0; } }
        @include maxtablet {
          margin-right: 0; } } }
    &--faq {
      .card {
        margin-bottom: 16px;
        background: #FFF;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid $border;
        transition: all .2s ease-in-out;
        &.open {
          margin-bottom: 24px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }
        &__header {
          padding: 28px 80px 12px 24px;
          border: 0;
          &:after {
            top: 28px;
            right: 16px;
            background: url(../images/ic-cancel.svg) no-repeat center;
            background-size: contain;
            transform: rotate(90deg); }
          &.collapsed {
            padding-bottom: 28px;
            .card__title {
              @include font-400(); }
            &:after {
              content: "";
              background: url(../images/ic-chevron-down.svg) no-repeat center;
              background-size: contain;
              transform: rotate(-90deg); } } }
        &__body {
          border-radius: 0;
          box-shadow: none;
          padding: 0 80px 52px 24px;
          margin: 0;
          .img-sm {
            display: none;
            margin-top: 24px;
            @include mobile {
              text-align: center;
              display: inline-block;
              width: 100%;
              height: auto; } } } } } } }

.faq-block--alt {
  background: #F2F4F7;
  padding: 64px 0;
  .card {
    &__body {
      ul {
        padding-left: 20px;
        list-style: disc;
        li {
          margin-bottom: 4px; } } } }
  @include maxtablet() {
    padding: 32px 8px;
    .section-head {
      &__content {
        max-width: 100%!important; } } } }

