.integrations-block {
  padding: 64px 0;
  &__actions {
    margin-top: 24px;
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; } } }
  &__list {
    .logo-marquee {
      padding-top: 64px;
      padding-bottom: 64px;
      &__group {
        display: flex;
        overflow: hidden;
        user-select: none;
        position: relative;
        &::before,
        &::after {
          content: "";
          z-index: 2;
          display: block;
          width: 64px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(89.8deg, #FFF 49.02%, rgba(255, 255, 255, 0) 113.2%); }
        &::after {
          left: auto;
          right: 0;
          transform: matrix(-1, 0, 0, 1, 0, 0); }
        &.reverse .logo-marquee__list {
          animation-direction: reverse;
          animation-delay: -3s; }
        &:first-child {
          .logo-marquee__list {
            padding-top: 15px; } }
        &:last-child {
          .logo-marquee__list {
            padding-bottom: 15px;
            margin-bottom: 0; } } }
      &__list {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-around;
        min-width: 100%;
        margin-bottom: 12px;
        animation: scroll-x 60s linear infinite;
        .item {
          display: grid;
          place-items: center;
          margin: 0 6px;
          width: 100px;
          height: 100px;
          border-radius: 24px;
          box-shadow: 0px 2.0299999713897705px 4.059999942779541px -2.7066667079925537px rgba(0, 0, 0, 0.12), 0px 4.059999942779541px 10.826666831970215px 0px rgba(0, 0, 0, 0.08), 0px 6.090000152587891px 18.946666717529297px 5.413333415985107px rgba(0, 0, 0, 0.05);
          img {
            display: block;
            max-width: 100px;
            max-height: 100px;
            width: auto;
            height: auto; } } }

      @include maxtablet {
        &__list {
          animation-duration: 30s; } }
      @include mobilelg() {
        &__group {
          &::after {
            width: 24px; } }
        &__list {
          .item {
            width: 68px;
            height: 68px;
            img {
              max-width: 68px;
              max-height: 68px; } } } } } }
  @include xsdesktop() {
    &__title {
      br {
        display: none; } } }
  @include maxtablet() {
    padding: 32px 0;
    &__list {
      margin-bottom: 24px; }
    &__actions {
      a {
        margin-right: 0;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } } } }
