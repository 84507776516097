.price-block {
  padding: 64px 0;
  &__title {
    margin-bottom: 8px; }
  &__desc {
    margin-bottom: 24px; }
  &__left {
    padding-right: 60px; }
  &__cards {
    .card {
      border: 1px solid #D0D6DD;
      border-radius: 6px;
      overflow: hidden;
      background: #FFF;
      height: calc(100% - 24px);
      margin-bottom: 24px;
      &.active {
        border-color: #009BDE;
        box-shadow:  0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12); }
      &__header {
        padding: 24px;
        .discount__badge {
          display: inline-block;
          border-radius: 24px;
          border: 1px solid #4B61DD;
          background: #FFF;
          color: #4B61DD;
          font-size: 14px;
          line-height: 1;
          margin-bottom: 8px;
          padding: 4px 12px; }
        .package {
          @include font-600();
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 8px; }
        .strike {
          color: #626B79;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
          text-decoration: line-through; }
        .pricing {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          padding: 0;
          margin: 0;
          .price {
            @include font-600();
            font-size: 20px;
            line-height: 32px; }
          .tenor {
            font-size: 14px;
            line-height: 20px;
            color: #626B79; } }
        .action {
          margin-top: 24px;
          a {
            width: 100%;
            display: block;
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0; } } } }

      &__body {
        padding: 0 24px 24px; } } }
  @include mobilelg() {
    padding: 32px 0;
    &__title {
      br {
        display: none; } }
    &__left {
      padding-right: 0; } } }
