/* ------------------------------------------------------------------------------------------------ */
/* responsive */
/* ------------------------------------------------------------------------------------------------ */
@include responsive(1600px,1200px) {
  .moz {
    .web-wrapper {
      transform: scale(0.8);
      transform-origin: 0 0;
      width: 125%;
      float: left; } }
  .webkit {
    zoom: 0.8;
    zoom: 80%; }
  .slist,.ss2 {
    &_colimg {
      img {
        max-height: 80vh; } } }
  .modal {
    &-backdrop {
      width: 120%;
      height: 120%; }

    &-open {
      .web-wrapper {
        transform: none !important; } }
    &-download {
      iframe {
        height: 100vh; } } } }
@include responsive(0,1600px) {
  .web-wrapper {
    height: auto !important; } }
@include responsive(1200px) {
  .web-wrapper {
    height: auto !important; } }
@include responsive(0,1200px) {
  .hs1 {
    .container {
      padding-bottom: 30px; } }
  .hs2,.ins,.cs2 {
    padding-bottom: 80px; }
  .hs3 {
    padding: 80px 0; }

  .pr2 {
    .container {
      padding: 80px 0; }
    &+ .ss2 {
      margin-top: 40px; } }
  .pr4,.pr1,.in1, {
    padding-bottom: 80px; }
  .ptb-80,.cmp .container {
    padding: 80px 0; }
  .s1 {
    &_vwrap {
      margin-top: 100px; } }
  .slist {
    &_item {
      padding: 80px 0; } }
  .ss2 {
    padding-bottom: 40px;
    &_item {
      padding: 40px 0; } }
  .ins2 {
    padding: 30px 0 80px; }
  .css {
    padding-bottom: 80px;
    &_testimony {
      padding: 80px 0; } } }
@include responsive(1200px) {
  .footer {
    &_social {
      a:not(:last-child) {
        margin-right: 5px; } } } }

@include minltablet {
  .visible-md-down {
    display: none; }
  .ss2 {
    &_item {
      &:nth-child(even) {
        .ss2_coltext {
          margin-left: 8.33%; } } } }
  .cs2 {
    .col-lg-4 {
      &:nth-child(n+4) {
        margin-top: 32px; } } }
  .css {
    .col-lg-8 {
      padding-right: 10%; } } }

@include mintablet {
  .visible-xs {
    display: none; }
  .modal {
    h2 {
      font-size: 24px;
      line-height: 1.33; } }

  .hs2 {
    &_colimg {
      flex: 0 0 59.77%;
      max-width: 59.77%; }
    &_coltext {
      flex: 0 0 40%;
      max-width: 40%; } }
  h1.small,article h1 {
    font-size: 32px; letter-spacing: -0.5px;  line-height: 1.25; }
  .in1 {
    &_item {
      margin: 0 0 24px; }
    .col-md-6 {
      &:nth-child(2n) {
        transform: translateY(15%);
        &:last-child {
          margin-bottom: 9.61%; } } } }
  .ins2 {
    &_item2 {
      &:nth-child(n+4) {
        margin-top: 48px; } } }
  .slist {
    &_item {
      &:nth-child(even) {
        .slist_colimg {
          order: 2; } } } }
  .ss2 {
    padding-bottom: 60px;
    img {
      max-width: 90%; }
    &_item {
      &:nth-child(even) {
        .ss2_colimg {
          order: 2;
          text-align: right; } } } }

  .in2 {
    &_item {
      margin: 0 0 24px; }
    &_items {
      .col-md-6 {
        &:nth-child(even) {
          transform: translateY(24px); } } } }
  .footer {
    &_addr {
      &_item {
        h3,p {
          display: inline; }

        padding-left: 85px;
        position: relative;
        h3 {
          position: absolute;
          top: 0;
          left: 0; } } } }
  .css {
    .row.justify-content-between {

      &:not(:first-child) {
        .css_sidebar {
          margin-top: -200px; } } } }

  .ebk {
    h3 {
      font-size: 24px;
      line-height: 1.33; } } }
@include ltablet {
  .menu-insight-header-container {
    margin: 0 -10px; }
  .pr1 {
    &_item {
      padding: 10.67% 12.67%; } } }



@include tablet {
  .footer {
    .col-lg-2 {
      margin-bottom: 24px; }
    &_sc11 {
      padding-top: 40px;
      padding-bottom: 16px; }
    &_sc12 {
      padding-bottom: 40px;
      div.col-6:not(.footer_addr) {
        left: auto;
        right: 16px;
        top: -58px; } } }
  .pr4 {
    h2 {
      font-size: 28px; } }
  .slist {
    &_coltext {
      padding-top: 5%; } }
  .cs2 {
    .col-lg-4 {
      &:nth-child(n+3) {
        margin-top: 32px; } } }

  .hero {
    &_imgs {
      max-height: 550px; } } }
@include tablets;




@include maxtablet {
  .burger-menu {
    display: block; }
  .menu-insight-header-container {
    margin: -8px 0; }
  .hidden-md-down {
    display: none; }




  .footer {
    &_sc12 {
      .col-6:not(.footer_addr) {
        position: absolute;
        left: -36%;
        top: 32px;
        display: block;
        width: auto; } } }

  .blog {
    &_slider {
      .blog_item {
        &-img {
          border-radius: 6px 6px 0 0; }
        &-text {
          padding: 32px 16px 24px; }
        &-cat {
          padding: 16px 16px 32px;
          &:before {
            display: block; } } }
      .owl-nav {
        top: 30%; } }
    &_related {
      .blog_wrap {
        .col-lg-4 {
          &:last-child {
            display: none; } } } } }
  .modal:not(.proto) {
    .modal-dialog {
      max-width: 100%; } }

  .masthead--contact {
    .masthead__media {
      margin-top: 2rem;
      img {
        display: block;
        width: 100%;
        height: auto; } } }

  .masthead__action {
    a {
      margin-left: 0;
      &.first {
        margin-bottom: 16px; } } }

  .our-customer {
    &__list {
      margin-left: -12px;
      margin-right: -12px;
      .item {
        max-width: 33.333333%;
        flex: 0 0 33.333333%;
        padding: 6px 12px; } } } }


@include mobile {
  html, body {
    font-size: 16px;
    line-height: 24px; }
  .slider-xs {
    margin: 0;
    .tab-pane {
      display: block; } }
  .hidden-xs {
    display: none; }
  .show-xs {
    display: block; }
  .block-xs {
    display: block !important; }
  // [class*="col-lg"],[class*="col-md-"]
  //   &:not(:last-child)
  //     margin: 0 0 24px
  // [class*="mt-"]
  //   margin-top: 32px
  // [class*="mb-"]
  //   margin-bottom: 32px

  // h1
  //   font-size: 30px
  //   &.small
  //     h1
  //     font-size: 24px
  // h2
  //   font-size: 24px
  // .font-21
  //   font-size: 18px
  .not-found {
    h1 {
        font-size: 160px; }
    p {
        font-size: 16px; } }

  .hero {
    padding-bottom: 50px;
    &_action a + a {
      margin-left: 12px; }
    &_imgs {
      max-height: 260px; } }
  .hs1 {
    .container {
      padding: 50px 12px; }
    &_stat {
      padding-left: 122px;
      p {
        font-size: 18px; } } }
  .hs2 {
    &_item {
      margin-top: 20px;
      &.active {
        &:after {
          top: -23px;
          transform: rotate(-90deg);
          right: calc(50% - 10px); } } }
    .owl-item {
      &.active {
        .hs2_item {
          background: $green;
          color: #fff; } } } }

  .hs25 {
    padding: 0 0 50px; }
  .ptd-120 {
    padding: 50px 0; }
  .ptd-162 {
    padding-bottom: 50px; }
  .footer {
    &_sc11 {
      padding: 40px 0  20px; }
    &_sc12 {
      padding-bottom: 40px;
      .col-6:not(.footer_addr) {
        right: 16px;
        top: -58px;
        left: auto;
        padding: 0;
        width: 165px;
        max-width: none; } }
    &_lang.bootstrap-select {
      .bttn {
        text-align: left;
        padding: 0; } }
    &_sc2 {
      text-align: center;
      span {
        display: block;
        margin-top: 8px; }
      .text-right {
        text-align: center; } }
    &_addr {
      margin: 0 !important;
      &_item {
        h3 {
          display: block; } } } }
  .pr1 {
    &_item {
      padding: 30px 0; }
    &_action {
      .btn {
        padding: 0 12px; } }
    .col-md-6:not(:last-child) {
      margin: 0;
      border-bottom: 1px solid #aaa; } }
  .pr4 {
    padding-bottom: 50px; }
  .ins {
    &_title {
      margin: 0 0 32px; }

    &_logos {
      img {
        margin: 5px;
        max-height: 35px; } }
    &_img {
      margin: 0 0 36px; }
    &_box {
      margin: 32px 0; }
    &_link {
      max-width: 48px;
      &:not(:last-child) {
        margin: 0 0 32px;
        &:before {
          height: 32px; } } }
    &_row {
      position: relative; }
    &_colnav {
      flex: 0 0 75px;
      max-width: 75px; }
    &_coltext {
      flex: 0 0 calc(100% - 76px);
      max-width: calc(100% - 76px); } }
  .ins2 {
    padding: 50px 0; }
  .cmp {
    .container {
      padding-left: 12px;
      padding-right: 12px; }
    .row {
      margin: 0 30px;
      .col-md-4 {
        margin: 0; } }
    &_item {
      &:not(.active) {
        .cmp_box {
          margin: 0; } } } }
  .s1 {
    padding: 120px 0 50px;
    &_vwrap {
      margin-top: 50px; }
    &_vtext {
      display: block;
      margin-top: 12px; }
    &_video {
      width: 100%; } }
  //.ss1
  //padding-top: 120px
  .ss2 {
    &_item + .ss2_item {
      padding-top: 0; } }

  .slist {
    &_item {
      padding: 50px 0; }
    &_coltext {
      padding-top: 0; } }
  .cs2 {
    padding-bottom: 60px; }

  .css {
    &_hero {
      margin: 0 -12px;
      margin: 0 0 24px; }
    &_testimony {
      padding: 60px 0; }
    &_wrap {
      position: static !important; } }
  .sform {
    &_box {
      padding: 32px; }
    h1 {
      font-size: 18px; } }
  .static {
    padding-top: 120px; }


  .blog {

    &_related {
      .blog_wrap {
        .col-lg-4 {
          margin-bottom: 32px;
          &:last-child {
            display: block;
            margin-bottom: 0; } } } }
    &_category {
      //display: flex
      //flex-direction: column
      display: block;
      &-wrap {
        display: block;
        width: 100%;
        margin: 0;
        text-align: center;
        .blog_category-item {
          display: inline-block;
          float: none;
          padding: 4px 10px; } }

      .blog_search {
        margin-top: 16px;
        width: 100%;
        background: #fff;
        padding: 5px 0;
        border: 1px solid #E2E2E2;
        border-radius: 4px;
        .btn-search {
          &:before {
            left: 5px; }
          span {
            max-width: 0;
            opacity: 0; } }
        form {
          display: flex;
          justify-content: center;
          input {
            text-align: left; } }
        &.search-open {}
        input {
          max-width: 100% !important;
          width: 100% !important;
          opacity: 1; } } }
    &_search {
      &.search-open {
        input {
          max-width: 120px; } } }

    &_slider {
      .blog_item {
        &-img {
          border-radius: 6px 6px 0 0; }
        &-text {
          padding: 0 16px 24px; } }
      .owl-nav {
        top: 18%; } } }

  .bs {
    &-share {
      flex-wrap: wrap;
      .col-left {
        max-width: 100%;
        flex: 0 0 100%; }
      .tags {
        padding: 24px 0 0; } }
    &-subscription {
      &_wrap {
        max-width: 100%;
        flex: 0 0 100%; } } }
  .ebk {
    padding-top: 120px;
    &_item {
        &:not(:last-child) {
            margin: 0 0 50px; } } }
  .modal.proto {
    .modal-dialog {
      padding: 32px 20px; } }
  .select-tab {
    padding: 0;
    width: 100%; }
  .bs {
    .gform_wrapper {
      .field_description_below .gfield_description {
        position: relative; }

      .gform_body {
        width: 100%;
        max-width: 100%; }
      .gform_footer {
        width: 100%;
        max-width: 100%;
        padding-top: 16px !important; } } }

  .our-customer {
    padding: 0 12px; }

  .form-meeting {
    padding: 5rem 0;
    &__img {
      display: none; } }

  .col-reverse-sm {
    flex-direction: column-reverse; }

  .block-clients {
    padding-top: 3rem;
    padding-bottom: 3rem;
    &__title {
      text-align: center; }
    &__desc {
      text-align: center;
      max-width: 600px;
      margin: 0 auto 1.5rem;
      p {
        margin-bottom: 1rem; } }
    ul.clients {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 33.333333%;
        flex: 0 0 33.333333%;
        margin: 16px 0;
        padding: 0 16px;
        img {
          width: auto;
          height: 32px; }
        .img--2 {
          display: none; } } }

    &--pricing {
      background: #F2F4F7;
      padding-top: 0; } } }
