// .animate
//   position: relative
//   transition: all 1s cubic-bezier(0, 0, 0.18, 0.93) 0s
//   &.animate--zoomin
//     opacity: 0
//     transform: scale(2)
//     transform-origin: center
//     transition: all .3s linear
//     backface-visibility: hidden
//     -webkit-font-smoothing: subpixel-antialiased
//     will-change: transform
//     &.animate--in
//       opacity: 1
//       -webkit-transform: scale(1) translate3d( 0, 0, 0) perspective(1px)
//       -moz-transform: scale(1) translate3d( 0, 0, 0) perspective(1px)
//       -o-transform: scale(1) translate3d( 0, 0, 0) perspective(1px)
//       -ms-transform: scale(1) translate3d( 0, 0, 0) perspective(1px)
//       transform: scale(1) translate3d( 0, 0, 0) perspective(1px)
//   &.animate--fade
//     opacity: 0
//     &.animate--in
//       opacity: 1
//   &.animate--up
//     // top: 100px
//     transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1)
//     opacity: 0
//     transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1)
//     &.animate--in
//       // top: 0
//       transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1)
//       opacity: 1
//   &.animate--down
//     top: -100px
//     opacity: 0
//     &.animate--in
//       top: 0
//       opacity: 1
//   &.animate--left
//     left: -100px
//     opacity: 0
//     &.animate--in
//       left: 0
//       opacity: 1
//   &.animate--delay-1
//     transition-delay: 0.25s
//   &.animate--delay-2
//     transition-delay: 0.5s
//   &.animate--delay-3
//     transition-delay: 0.75s
//   &.animate--delay-4
//     transition-delay: 1s
//   &.animate--delay-5
//     transition-delay: 1.25s


@mixin bounce() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce2;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounce2 {
  0% {
    transform: scale(1,1) translateX(0); }
  10% {
    transform: scale(1.1,.9) translateX(0); }
  30% {
    transform: scale(.9,1.1) translateX(-4px); }
  50% {
    transform: scale(1.05,.95) translateX(6px); }
  57% {
    transform: scale(1,1) translateX(-2px); }
  64% {
    transform: scale(1,1) translateX(0); }
  100% {
    transform: scale(1,1) translateX(0); } }

@mixin bounceRevert() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounceRev;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounceRev {
  0% {
    transform: scale(1,1) translateX(0); }
  10% {
    transform: scale(1.1,.9) translateX(0); }
  30% {
    transform: scale(.9,1.1) translateX(4px); }
  50% {
    transform: scale(1.05,.95) translateX(-6px); }
  57% {
    transform: scale(1,1) translateX(2px); }
  64% {
    transform: scale(1,1) translateX(0); }
  100% {
    transform: scale(1,1) translateX(0); } }

@mixin bounceUp() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounceUp;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounceUp {
  0% {
    transform: scale(1,1) translateY(0); }
  10% {
    transform: scale(1.1,.9) translateY(0); }
  30% {
    transform: scale(.9,1.1) translateY(-4px); }
  50% {
    transform: scale(1.05,.95) translateY(6px); }
  57% {
    transform: scale(1,1) translateY(-2px); }
  64% {
    transform: scale(1,1) translateY(0); }
  100% {
    transform: scale(1,1) translateY(0); } }

@mixin pulse() {
  box-shadow: 0 0 0 rgba(0,0,0, 0.4);
  animation: pulse 2s infinite; }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0.4); }
  70% {
    box-shadow: 0 0 0 14px rgba(0,0,0, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0); } }

@mixin pulseSm() {
  box-shadow: 0 0 0 rgba(0,135,217, 0.4);
  animation: pulseSm 2s infinite; }

@keyframes pulseSm {
  0% {
    box-shadow: 0 0 0 0 rgba(0,135,217, 0.6); }
  70% {
    box-shadow: 0 0 0 8px rgba(0,135,217, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(0,135,217, 0); } }

@mixin pulseBig() {
  box-shadow: 0 0 0 rgba(255,255,255, 1);
  animation: pulseBig 2s infinite; }

@keyframes pulseBig {
  0% {
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.4); }
  70% {
    box-shadow: 0 0 0 30px rgba(255,255,255, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255,255,255, 0); } }

@mixin pulseBlue() {
  box-shadow: 0 0 0 rgba(75, 97, 221, 1);
  animation: pulseBlue 2s infinite; }

@keyframes pulseBlue {
  0% {
    box-shadow: 0 0 0 0 rgba(75, 97, 221, 0.4); }
  70% {
    box-shadow: 0 0 0 30px rgba(75, 97, 221, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(75, 97, 221, 0); } }

@mixin pulse2() {
  box-shadow: 0 0 0 rgba(0,95,191, 0.8);
  animation: pulse2 3s infinite; }

@keyframes pulse2 {
  0% {
    box-shadow: 0 0 0 0 rgba(0,95,191, 0.8); }
  70% {
    box-shadow: 0 0 0 18px rgba(0,95,191, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(0,95,191, 0); } }

@mixin dash($duration) {
  stroke-dasharray: 1000;
  animation: dash $duration linear; }
@keyframes dash {
  from {
    stroke-dashoffset: 1000; }
  to {
    stroke-dashoffset: 0; } }

@mixin dash2($duration) {
  stroke-dasharray: 1500;
  animation: dash2 $duration linear; }
@keyframes dash2 {
  from {
    stroke-dashoffset: 1500; }
  to {
    stroke-dashoffset: 0; } }


.slideOutDown {
  animation-name: slideOutDown; }

@keyframes  slideOutDown {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(1.25); } }

.comeOut {
  animation-name: comeOut; }

@keyframes  comeOut {
  0% {
    opacity: 1;
    transform: scale(.5); }
  100% {
    opacity: 0;
    transform: scale(1); } }

@mixin progressBar($duration) {
  animation: progressBar $duration linear; }
@keyframes progressBar {
  0% {
    max-width: 0%; }
  100% {
    max-width: 100%; } }

@mixin pulseCustom($r, $g, $b, $name) {
  box-shadow: 0 0 0 rgba($r, $g, $b, 0.4);
  animation: $name 2s infinite;

  @keyframes #{$name} {
    0% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0.4); }
    70% {
      box-shadow: 0 0 0 14px rgba($r, $g, $b, 0); }
    100% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0); } } }


@mixin pulseBlueSm() {
  box-shadow: 0 0 0 rgba(75, 97, 221, 1);
  animation: pulseBlueSm 2s infinite;
  border-radius: 50%; }
@keyframes pulseBlueSm {
  0% {
    box-shadow: 0 0 0 0 rgba(75, 97, 221, 0.4); }
  70% {
    box-shadow: 0 0 0 30px rgba(75, 97, 221, 0); }
  80% {
    box-shadow: 0 0 0 0 rgba(75, 97, 221, 0); } }

@mixin bounceDown() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounceDown;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounceDown {
  0% {
    transform: scale(1,1) translateY(0); }
  10% {
    transform: scale(1.1,.9) translateY(0); }
  30% {
    transform: scale(.9,1.1) translateY(-4px); }
  50% {
    transform: scale(1.05,.95) translateY(6px); }
  57% {
    transform: scale(1,1) translateY(-2px); }
  64% {
    transform: scale(1,1) translateY(0); }
  100% {
    transform: scale(1,1) translateY(0); } }
