.changelog {
  &__head {
    background: #F1F5F9;
    position: relative;
    &::after {
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      content: '';
      display: block;
      background: url(../images/ornament-changelog.png) no-repeat center right;
      background-size: contain; }
    &-sub {
      display: block;
      margin: 0 0 8px;
      @include font-600();
      color: #232933; }
    &-title {
      display: block;
      margin: 0 0 16px;
      font-size: 40px;
      line-height: 56px; }
    &-desc {
      max-width: 600px; }
    &-content {
      padding-top: 64px;
      padding-bottom: 64px; }
    &-image {
      display: none;
      img {
        display: block;
        margin-left: auto; } } }
  &__body {
    padding-top: 64px;
    padding-bottom: 64px;
    .sticky-full {
      transition: all .4s ease; } }
  &__upcoming {
    .cu-head {
      padding: 10px 24px;
      background: #71717A;
      border-radius: 4px 4px 0 0;
      color: #FFF;
      font-size: 14px;
      line-height: 24px;
      @include font-600(); }
    .cu-content {
      padding: 16px 24px;
      background: #F2F4F7;
      border-radius: 0 0 4px 4px;
      color: #626B79;
      ul {
        position: relative;
        li {
          padding-left: 24px;
          position: relative;
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background: #626B79;
            position: absolute;
            top: 10px;
            left: 8px; } } } } }
  &__sidebar {
    padding-top: 0;
    padding-bottom: 24px;
    transition: all .4s ease;
    &.stick {
      padding-right: 0; }
    &.show-top {
      padding-top: 32px;
      transition: all .4s ease; } }
  &__filter {
    padding-right: 40px;
    max-height: calc(100vh - 435px);
    overflow: hidden;
    .title {
      display: block;
      padding: 0 0 16px;
      @include font-600();
      color: #232933;
      border-bottom: 1px solid #D0D6DD; }
    .filter-acc {
      position: relative;
      max-height: 285px;
      .item {
        // &:last-child()
        //   .item__body
        //     border-bottom-color: transparent
        &_count {
          b {
            padding: 2px 6px;
            background: #E8F5EB;
            border-radius: 12px;
            font-size: 14px;
            line-height: 20px;
            color: #3C914D; } }
        &__head {
          position: relative;
          display: block;
          font-size: 14px;
          line-height: 24px;
          @include font-600();
          color: #232933;
          padding: 12px 40px 12px 0;
          border-bottom: 1px solid #D0D6DD;
          cursor: pointer;
          &> span {
            font-size: 14px;
            line-height: 24px;
            color: #626B79; }
          &::after {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background: url(../images/ic-dropdown.svg) no-repeat;
            background-size: contain;
            position: absolute;
            right: 4px;
            top: 14px;
            transform: rotate(180deg);
            transition: all .4s ease; }
          &.collapsed::after {
            transform: none; }
          &:not(.collapsed) {
            border-bottom-color: transparent; } }
        &__body {
          border-bottom: 1px solid #D0D6DD;
          ul {
            position: relative;
            &> li {
              padding: 8px 0; } }
          &.collapse.show {
            max-height: 100px;
            overflow-x: hidden;
            overflow-y: auto; } } } }

    .filter-action {
      padding-top: 24px;
      .apply_filter {
        display: none; } } }
  &__cta {
    margin-top: 24px;
    padding-right: 40px;
    .box {
      border-radius: 8px;
      border: 1px solid #D0D6DD;
      background: #FFF;
      padding: 16px;
      font-size: 14px;
      line-height: 20px;
      @include font-600();
      &__title {
        margin-bottom: 8px; }
      &__action {
        margin-top: 16px;
        text-align: center;
        .btn {
          width: 100%; } } } }

  &__search {
    position: relative;
    padding-right: 40px;
    margin: 0 0 24px;
    .form-control {
      height: 44px;
      border-radius: 6px;
      padding-left: 44px; }
    .ic-search {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 12px;
      left: 12px;
      background: url(../images/ic-search.svg) no-repeat;
      background-size: contain; } }
  &__posts {
    &:first-child {
      .post:first-child {
        padding-top: 0; } }
    &.loading {
      position: relative;
      .post {
        opacity: 0.5; }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        background-image: url(../images/loading.svg);
        background-repeat: no-repeat;
        background-size: 64px;
        background-position: center center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2; } }
    .post {
      display: flex;
      padding-top: 48px;
      padding-bottom: 28px;
      border-bottom: 1px solid $border;
      .badge {
        display: inline-block;
        padding: 0 6px;
        font-size: 14px;
        line-height: 20px;
        border-radius: 10px;
        &--green {
          background-color: #14B8A6;
          color: #FFF; }
        &--purple {
          background-color: #8B5CF6;
          color: #FFF; }
        &--yellow {
          background-color: #F59E0B;
          color: #FFF; }
        &--gray {
          background-color: #8B95A5;
          color: #FFF; }
        &--blue {
          background-color: #3B82F6;
          color: #FFF; } }
      &__title {
        font-size: 32px;
        line-height: 48px;
        margin: 0 0 40px; }
      &__items {
        .item {
          padding-top: 20px;
          padding-bottom: 20px;
          &__badge {
            margin: 0 0 16px;
            font-size: 14px;
            line-height: 20px; }
          &__content {
            a {
              color: #4B61DD; }
            h3 {
              font-size: 16px;
              line-height: 24px;
              margin: 0 0 10px; }
            p {
              margin: 0 0 16px;
              &:last-child {
                margin: 0; } }
            img {
              display: block;
              max-width: 100%;
              height: auto; } } } }
      &__date {
        display: block;
        width: 100%;
        max-width: 15%;
        flex: 0 0 15%;
        margin-top: 10px;
        color: #626B79; }
      &__content {
        display: block;
        width: 100%;
        max-width: 85%;
        flex: 0 0 85%; }
      &--empty {
        min-height: 50vh;
        display: block;
        text-align: center;
        .img {
          display: block;
          width: 169px;
          height: 141px;
          background: url(../images/not-found.png) no-repeat;
          background-size: contain;
          margin: 0 auto; }
        .title {
          font-size: 20px;
          line-height: 28px;
          @include font-600();
          margin-bottom: 8px; }
        .text {
          display: block;
          text-align: center;
          color: #626B79;
          margin-bottom: 16px; } } } }
  &__more {
    padding-top: 48px;
    text-align: center; }
  &__sticky-sm {
    display: none; }
  @include desktop() {
    &__filter {
      overflow-x: hidden;
      overflow-y: auto; } }
  @include maxtablet() {
    &__head {
      padding-left: 8px;
      padding-right: 8px;
      &-content {
        padding-top: 32px;
        padding-bottom: 32px; }
      &::after {
        display: none; } }
    &__body {
      padding-top: 32px; }
    &__sidebar {
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 24px; }
    &__search {
      padding-right: 0;
      margin: 0 0 24px; }
    &__cta {
      padding-right: 0; }
    &__filter {
      padding-right: 0;
      .filter-action {
        display: none !important; }
      &.set-as-sticky,
      &.set-sticky {
        display: block;
        width: 100%;
        height: 100%;
        background: #FFF;
        position: fixed;
        top: 0;
        left: -100vw;
        opacity: 0;
        visibility: hidden;
        z-index: 1001;
        padding: 54px 0 104px;
        transition: all .4s ease;
        .title {
          display: none; }
        .sticky-sm-close {
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 20px;
          right: 20px;
          background: url(../images/ic-cross.svg) no-repeat;
          background-size: contain; }
        .filter-acc {
          display: block;
          width: 100%;
          max-height: calc(100vh - 158px);
          overflow: auto;
          padding: 0 20px 32px;
          .custom-control {
            padding-left: 24px;
            min-height: 20px;
            line-height: 20px; }
          .custom-control-label {
            font-size: 14px;
            line-height: 20px; }
          .custom-control-label::before {
            width: 16px;
            height: 16px;
            left: -24px; }
          .custom-control-label::after {
            width: 16px;
            height: 16px;
            left: -24px; }
          .custom-control-input:checked ~ .custom-control-label::before {
            background-size: 8px; } }
        .filter-action {
          position: fixed;
          bottom: 0;
          left: 0;
          width: calc(100vw + 8px);
          padding: 32px 24px;
          margin-left: -8px;
          margin-right: -8px;
          box-shadow: 0px -3px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
          .apply_filter {
            display: block; }
          .btn {
            padding: 7px 16px;
            margin: 0 8px !important;
            white-space: nowrap; } } }
      &.show-main-sm {
        opacity: 1;
        visibility: visible;
        left: 0;
        .filter-action {
          display: flex !important; } } }
    &__upcoming {
      padding-left: 8px;
      padding-right: 8px; }
    &__posts {
      padding-left: 8px;
      padding-right: 8px;
      display: block;
      .post {
        display: block;
        &__date {
          max-width: 100%;
          flex: 0 0 100%;
          margin: 0 0 24px; }
        &__content {
          max-width: 100%;
          flex: 0 0 100%; } } }
    &__sticky-sm {
      display: block;
      &--bar {
        display: flex;
        position: fixed;
        width: 100%;
        top: 0;
        opacity: 0;
        left: 0;
        background: #FFF;
        padding: 16px;
        z-index: 99;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
        box-shadow: 0px 3px 5px rgba(119, 119, 119, 0.2);
        transition: all .4s ease;
        &.show {
          opacity: 1;
          top: 56px;
          z-index: 101; }
        .changelog__search {
          margin: 0; }
        .changelog__filter-button {
          padding-left: 24px; }
        span.open-filter {
          display: block;
          font-size: 16px;
          line-height: 24px;
          padding: 10px 0;
          text-align: center;
          white-space: nowrap;
          color: #4B61DD;
          @include font-600(); } } } } }

.sticky-full .changelog__sidebar {
  max-height: calc(100vh - 120px);
  overflow: auto;
  @include maxtablet() {
    max-height: initial; } }

body.show-filter {
  position: fixed; }
