.masthead--ltp {
  padding-top: 0;
  .masthead {
    &__logo {
      margin-bottom: 64px;
      img {
        display: block;
        height: 55px;
        width: auto;
        margin: 0 auto; } }
    &__icon {
      padding-top: 24px;
      padding-bottom: 35px;
      img {
        display: block;
        height: 90px;
        width: auto;
        margin: 0 auto; } }
    &__title {
      margin-bottom: 28px; }
    &__desc {
      max-width: 800px;
      margin: 0 auto; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px;
      text-align: center; } }

  @include maxtablet {
    .masthead {
      &__logo {
        margin-bottom: 32px;
        img {
          height: 40px; } }
      &__icon {
        padding-bottom: 32px;
        img {
          height: 80px; } }
      &__wrap {
        padding-top: 64px;
        padding-bottom: 64px; } } } }


.ltp-product {
  background: #F2F4F7;
  &__title {
    text-align: center;
    margin-bottom: 28px; }
  &__desc {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 64px; }
  &__wrap {
    padding-top: 40px;
    padding-bottom: 56px; }
  .item {
    margin-bottom: 24px;
    &__logo {
      margin-bottom: 14px;
      img {
        display: block;
        height: 46px;
        width: auto;
        margin: 0 auto; } }
    &__img {
      margin-bottom: 20px;
      img {
        display: block;
        max-width: 100%;
        height: auto;
        width: auto;
        margin: 0 auto; } }
    &__title {
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px; }
    &__desc {
      text-align: center; }
    &__action {
      text-align: center;
      padding-top: 48px; }
    &__wrap {
      background: #fff;
      border-radius: 4px;
      padding: 40px; } }
  @include maxtablet() {
    &__desc {
      margin-bottom: 32px; }
    &__wrap {
      padding-left: 24px;
      padding-right: 24px; }
    .item {
      &__title {
        font-size: 20px;
        line-height: 30px; }
      &__action {
        padding-top: 32px; }
      &__wrap {
        padding: 24px; } } } }

.modal-ltp {
  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    color: #2B2A35;
    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background: url("../images/ic-cancel.svg") no-repeat center;
      background-size: contain; }
    span {
      display: inline-block;
      vertical-align: middle; } }
  &__logo {
    margin-bottom: 32px;
    img {
      display: block;
      margin: 0 auto;
      height: 64px;
      width: auto; } }
  &__img {
    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      margin: 0 auto; } }
  &__content {
    position: relative; }
  &__action {
    margin-top: 40px; }
  .modal-body {
    padding-left: 80px;
    padding-right: 80px; }
  .modal-dialog {
    max-width: 1224px;
    margin: auto; }

  @include maxtablet() {
    &__img {
      margin-bottom: 24px;
      img {
        max-width: 80%;
        height: auto; } }
    &__content {
      padding-bottom: 110px; }
    &__action {
      z-index: 10;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 24px 24px 16px;
      background: #FFF;
      border-top: 1px solid $border; }
    .close {
      top: 24px;
      right: 24px; }
    .modal-dialog {
      margin: 0; }
    .modal-content {
      margin: 0;
      border-radius: 0;
      padding-top: 80px; }
    .modal-body {
      padding-left: 24px;
      padding-right: 24px; } } }
