.client-testimonial {
  padding: 64px 0;
  background: #F2F4F7;
  .testi-big {
    margin-top: 128px;
    margin-bottom: 32px;
    padding: 32px;
    background: #FFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 78px;
    &__img {
      max-width: 394px;
      flex: 0 0 100%;
      display: flex;
      position: relative;
      img {
        width: 100%;
        object-fit: contain;
        margin-right: 78px;
        margin-top: -140px; } }
    &__content {
      flex: 0 0 calc(100% - (394px + 78px)); }
    &__quote {
      margin: 0 0 26px 0;
      p {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } }
    &__meta {
      display: flex;
      justify-content: space-between;
      align-items: center;
      b {
        @include font-600(); }
      b,span {
        display: block; } }
    &__logo {
      width: auto;
      height: 50px;
      object-fit: contain; }
    &__action {
      margin-top: 32px; } }
  .testi-sm {
    background: #FFF;
    border-radius: 4px 0px 0px 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    padding: 32px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &__quote {
      margin: 0 0 24px 0;
      p {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } }
    &__meta {
      display: flex;
      align-items: center; }
    &__img {
      height: 105px;
      width: auto;
      margin-right: 16px;
      img {
        height: 105px;
        width: 96px;
        object-fit: contain; } }
    &__info {
      b,span {
        display: block; }
      span {
        margin-bottom: 4px; } } }
  @include maxtablet() {
    padding: 32px 0;
    .testi-big {
      flex-direction: column;
      padding: 24px 12px;
      margin-top: 85px;
      margin-bottom: 24px;
      gap: 24px;
      &__img {
        margin-right: 0;
        margin-bottom: 8px;
        margin-top: 0;
        height: 292px; }
      &__meta {
        flex-direction: column;
        align-items: flex-start; }
      &__info {
        margin-bottom: 12px; } }
    .testi-sm {
      padding: 24px 12px;
      margin-bottom: 24px;
      height: calc(100% - 24px);
      &__img {
        display: none; } } } }
