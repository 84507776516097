.propose {
  padding: 64px 0;
  &.bg-gray {
    background: #F1F5F9; }
  &.propose--alt {
    .item {
      &__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        @include font-600(); } } }
  &__title {
    text-align: center; }
  &__desc {
    text-align: center;
    margin-bottom: 24px; }
  .item {
    border: 1px solid #D0D6DD;
    border-radius: 4px;
    padding: 24px;
    height: calc(100% - 24px);
    background: #FFF;
    margin-bottom: 24px;
    &__icon {
      margin-bottom: 8px;
      img {
        height: 52px;
        width: auto; } }
    &__title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      @include font-600(); }
    &__desc {
      color: #232933;
      p {
        margin-bottom: 0; }
      strong {
        font-size: 20px;
        line-height: 28px;
        @include font-600();
        margin-top: 8px;
        display: inline-block; } } }

  &.in-app {
    padding: 40px 0;
    .propose__title {
      font-size: 20px;
      line-height: 28px; }
    .item__wrap {
      .item {
        border: 0;
        padding: 0;
        &__icon {
          img {
            height: 72px;
            width: 86px;
            object-fit: contain; } }
        &__title {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px; }
        &__desc {
          p {
            color: #626B79; } } } } }

  @include maxtablet() {
    padding: 32px 0;
    .item {
      margin-bottom: 24px;
      height: calc(100% - 24px); } } }
