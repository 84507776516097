.recom-bundle--alt {
  padding-top: 64px;
  padding-bottom: 64px;
  h2 {
    text-align: center;
    margin-bottom: 48px; }
  .recom-bundle__nav {
    .item {
      position: relative;
      padding: 20px 60px 20px 32px;
      background: #FFF;
      border: 1px solid $border;
      border-radius: 4px;
      margin-bottom: 12px;
      transition: all .3s ease;
      &[data-toggle] {
        cursor: pointer; }
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url("../images/ic-chevron-right-round.svg") no-repeat center;
        background-size: contain;
        position: absolute;
        top: calc(50% - 12px);
        right: 30px;
        opacity: 0;
        transition: all .3s ease; }
      &__logo {
        position: relative;
        margin-bottom: 12px;
        img {
          display: block;
          height: 26px;
          width: auto; } }
      &.active {
        border-color: $primary;
        background: rgba(0, 95, 191, 0.1)!important;
        &:after {
          opacity: 1;
          right: 24px; } }
      &:not(.active):hover {
        background: #F2F4F7; } } }
  .tab-content {
    height: 100%;
    padding-bottom: 12px; }
  .tab-pane {
    padding: 32px;
    border: 1px solid $border;
    border-radius: 4px;
    height: 100%;
    &__sm {
      display: none; }
    h3 {
      margin-bottom: 16px; }
    span.cat {
      color: #2B2A35;
      font-size: 16px;
      line-height: 24px;
      @include font-500();
      display: block;
      margin-bottom: 8px; }
    ul.custom-list {
      &> li {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0; } } }
    .price {
      &:not(:last-child) {
        margin-bottom: 24px; }
      .ps {
        font-size: 14px;
        line-height: 20px;
        color: #777; }
      .pn {
        font-size: 28px;
        line-height: 48px;
        @include font-500();
        color: #2B2A35; }
      .pt {
        font-size: 16px;
        line-height: 24px;
        @include font-500();
        color: #2B2A35; } }
    .tab-action {
      padding-top: 32px; } }
  @include maxtablet() {
    padding: 48px 8px;
    &__nav {
      display: none; }
    .tab-pane {
      margin-bottom: 24px;
      display: block;
      height: auto;
      opacity: 1;
      &__sm {
        display: block;
        .item__logo {
          margin-bottom: 12px; }
        .item__desc {
          margin-bottom: 24px; } }
      .hide-sm {
        display: none; }
      &:last-child {
        margin-bottom: 0; } } } }


.pricing-table {
  position: relative;
  &__header {
    background: #FFF;
    position: relative;
    transition: all linear .3s;
    &.sticky-me1,
    &.sticky-me2 {
      &.stick {
        top: 53px!important;
        transition: all linear .3s;
        background: #FFF;
        z-index: 5;
        .pricing-table {
          &__td,
          &__th {
            .head {
              &__desc {
                display: none; }
              &__price {
                span {
                  &.badge,
                  &.label {
                    display: none; } } } } } }

        &.show-top {
          top: 94px!important;
          transition: all linear .3s; } } }
    .pricing-table {
      &__td,
      &__th {
        text-align: left;
        &:first-child {
          min-width: 28.6%!important;
          width: 100%;
          align-items: end;
          font-size: 20px;
          line-height: 32px; }
        .head {
          display: block;
          height: 100%;
          width: 100%;
          &__title {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 8px; }
          &__desc {
            font-size: 14px;
            line-height: 20px;
            color: #626B79;
            margin-bottom: 24px; }
          &__price {
            .pr {
              font-size: 20px;
              line-height: 32px;
              margin-bottom: 24px;
              display: block;
              @include font-500; }
            span {
              &.badge {
                display: inline-block;
                vertical-align: middle;
                color: #005FBF;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                border: 1px solid #005FBF;
                background: #FFF;
                padding: 0 16px;
                border-radius: 14px;
                margin: 0 0 16px;
                text-decoration: none;
                min-height: 20px;
                &.hide {
                  opacity: 0; } }
              &.label {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 16px;
                display: block;
                color: #9090A2;
                text-decoration: line-through;
                min-height: 20px; } } }
          &__buttons {
            text-align: center;
            .btn.cta-waba {
              padding: 12px 6px 12px 4px; }
            a {
              margin-bottom: 14px;
              &:last-child {
                margin-bottom: 0; } } }
          &.head--new {
            .head__pill {
              @include font-600;
              padding: 2px 12px;
              display: inline-block;
              margin-bottom: 10px;
              background: #009BDE;
              font-size: 14px;
              line-height: 20px;
              color: #FFF;
              opacity: 0;
              visibility: hidden;
              border-radius: 6px;
              &.show {
                opacity: 1;
                visibility: visible; } }
            .head__price {
              margin-bottom: 24px;
              span {
                &.label {
                  margin-bottom: 8px; }
                &.pr {
                  margin-bottom: 8px;
                  &.discuss {
                    margin-top: 40px; }
                  small {
                    color: #9090A2;
                    margin-left: {} } }
                &.notes {
                  font-size: 14px;
                  line-height: 20px;
                  color: #9090A2; } } } } } } } }

  &__tr {
    display: flex;
    align-items: stretch;
    justify-content: center; }
  &__td,
  &__th {
    display: flex;
    padding: 16px;
    min-width: 23.8%;
    border-bottom: 1px solid $border;
    border-left: 1px solid $border;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-flow: column;
    &:first-child {
      border-left: 0;
      justify-content: start;
      text-align: left;
      align-items: start;
      min-width: 28.6%!important;
      width: 100%; }
    &> span {
      position: relative;
      display: block;
      width: 100%;
      &.check {
        width: 32px;
        height: 32px;
        background: url(../images/ic-check-green.svg) no-repeat center; }
      &.uncheck {
        width: 32px;
        height: 32px;
        background: url(../images/ic-close-round.svg) no-repeat center; }
      i {
        &.info {
          background: url('../images/ic-info.svg') no-repeat center;
          background-size: contain;
          width: 24px;
          height: 24px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 8px;
          cursor: help;
          position: absolute;
          top: 0;
          right: 0; } }
      &> .badge {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        padding: 2px 12px;
        background: #FBBF24;
        color: #FFF;
        border-radius: 6px;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        @include font-600; } }
    ul {
      list-style: disc;
      padding-left: 15px;
      margin-top: 14px;
      width: 100%;
      li {
        position: relative;
        width: 100%;
        margin-bottom: 8px;
        i {
          &.info {
            background: url('../images/ic-info.svg') no-repeat center;
            background-size: contain;
            width: 24px;
            height: 24px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 8px;
            cursor: help;
            position: absolute;
            top: 0;
            right: 0; } }
        &> .badge {
          display: inline-block;
          position: absolute;
          right: 0;
          top: 0;
          padding: 2px 12px;
          background: #FBBF24;
          color: #FFF;
          border-radius: 6px;
          font-size: 14px;
          line-height: 20px;
          margin: 0;
          @include font-600; } } } }

  &--alt {
    .pricing-table {
      &__header {
        .pricing-table {
          &__td,
          &__th {
            text-align: left;
            justify-content: end;
            align-items: start;
            &:first-child {
              min-width: 23.6%!important;
              span {
                @include font-600; } } } } }
      &__td,
      &__th {
        min-width: 19.1%;
        &:first-child {
          min-width: 23.6%!important; } } } } }

.pricing-card {
  &__wrapper {
    padding: 0!important; }
  &__slider {
    .owl-stage-outer {
      padding-bottom: 60px!important; }
    &.owl-carousel {
      display: block; } }
  &__item {
    border: 1px solid #8B95A5;
    background: #F2F4F7;
    border-radius: 6px;
    .head {
      display: block;
      height: 100%;
      width: 100%;
      padding: 16px;
      &__title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px; }
      &__desc {
        font-size: 14px;
        line-height: 20px;
        color: #626B79;
        margin-bottom: 24px; }
      &__price {
        .pr {
          font-size: 20px;
          line-height: 32px;
          margin-bottom: 24px;
          display: block;
          @include font-500; }
        span {
          &.badge {
            display: inline-block;
            vertical-align: middle;
            color: #005FBF;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            border: 1px solid #005FBF;
            background: #FFF;
            padding: 0 16px;
            border-radius: 14px;
            margin: 0 0 16px;
            text-decoration: none;
            min-height: 20px;
            &.hide {
              opacity: 0; } }
          &.label {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 16px;
            display: block;
            color: #9090A2;
            text-decoration: line-through;
            min-height: 20px; } } }
      &__buttons {
        text-align: center;
        a {
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0; } } } }
    .body {
      padding: 16px;
      border-top: 1px solid #D0D6DD;
      ul {
        li {
          position: relative;
          font-size: 16px;
          line-height: 24px;
          padding: 10px 5px 10px 40px;
          margin-bottom: 8px;
          &::before {
            content: '';
            width: 32px;
            height: 32px;
            background-size: contain;
            position: absolute;
            top: 6px;
            left: 0; }
          &.check {
            &::before {
              background: url('../images/ic-check-green.svg') no-repeat center; } }
          &.uncheck {
            &::before {
              background: url('../images/ic-close-round.svg') no-repeat center; } }
          &>ul {
            list-style: disc;
            padding: 0;
            margin-left: 15px;
            li {
              padding: 5px 10px;
              margin-bottom: 0;
              font-style: italic;
              &::before {
                display: none; } } } } } } }
  .owl-item.active {
    .pricing-card__item {
      background: #FFF;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12); } }
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    position: relative;
    width: 100%!important;
    transition: all linear .3s;
    &.stick {
      position: fixed!important;
      top: 55px!important;
      left: 0!important;
      width: 100%!important;
      opacity: 1!important;
      z-index: 2;
      background: #FFF;
      height: 130px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
      transition: all linear .3s;
      padding-left: 12px;
      padding-right: 12px;
      .owl-dots__wrap {
        #selectedItem {
          .item {
            &__cta {
              display: block; } } } }
      &.show-top {
        position: fixed!important; } }
    &.show-top {
      position: relative!important;
      opacity: 1!important; } }
  .owl-dots__wrap {
    display: inline-flex;
    width: calc(100% - 69px);
    flex-flow: column;
    justify-content: center;
    #selectedItem {
      @include font-600;
      display: block;
      text-align: center;
      .item {
        &__title {
          margin-bottom: 8px; }
        &__cta {
          display: none; } } }
    .owl-dots {
      margin: 0;
      .owl-dot {
        width: calc(100%/6);
        border-radius: 6px; } } }
  .owl-navs {
    display: inline-flex;
    width: 30px;
    .owl-prev {
      span {
        &::before {
          content: '';
          display: block;
          background: rgba(0, 0, 0, 0.5) url(../images/icon-chevron-right-white.svg) no-repeat center;
          background-size: contain;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          transform: scaleX(-1); } } }
    .owl-next {
      span {
        &::before {
          content: '';
          display: block;
          background: rgba(0, 0, 0, 0.5) url(../images/icon-chevron-right-white.svg) no-repeat center;
          background-size: contain;
          width: 30px;
          height: 30px;
          border-radius: 50%; } } } } }


.pricing-cards--alt {
  .card {
    &__head {
      h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include font-600(); }
      &> p {
        font-size: 16px;
        line-height: 24px; }
      .bonus {
        padding: 12px;
        border-radius: 4px;
        background: #B4D3F2;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        b {
          @include font-600();
          margin-bottom: 8px; }
        ul {
          list-style-type: disc;
          padding-left: 15px; } }
      .card__action {
        padding-top: 0; } }

    &__toggle {
      color: #4B61DD;
      transition: all linear .3s;
      @include font-600();
      font-size: 16px;
      line-height: 24px;
      border-bottom: 0;
      &:not(.collapsed) {
        border-bottom: 1px solid #D0D6DD;
        transition: all linear .3s; }
      &:hover {
        color: #4B61DD; }
      &::after {
        background: url(../images/ic-chev-blue-pricing.svg) no-repeat;
        background-size: contain; } }
    &__action {
      text-align: center;
      padding: 26px 0 6px 0; }
    &__collapse-wrap {
      padding: 20px 0;
      span.cat {
        @include font-600();
        font-size: 16px;
        line-height: 24px!important;
        margin-bottom: 12px;
        text-decoration: none!important;
        padding-left: 16px;
        padding-right: 16px; }
      ul.custom-list {
        .accr {
          color: #232933;
          position: relative;
          padding-left: 35px;
          cursor: pointer;
          padding: 8px 50px;
          margin-bottom: 0;
          &::before {
            width: 24px;
            height: 24px;
            top: 8px;
            left: 16px; }
          &::after {
            content: '';
            display: inline-block;
            background: url(../images/ic-chev-gray-pricing.svg) no-repeat center;
            background-size: contain;
            position: absolute;
            top: 9px;
            right: 16px;
            height: 24px;
            width: 24px;
            transform: rotate(0deg);
            transition: transform linear .3s; }
          &> span {
            display: block;
            margin-bottom: 0;
            transition: all linear .3s; }
          &.no-child {
            &::after {
              display: none!important;
              pointer-events: none!important; } }
          &.check {
            &::before {
              background: url(../images/ic-done.svg) no-repeat center;
              background-size: contain; } }
          &.medal {
            background: #ECF5FF;
            transition: background linear .3s;
            &::before {
              background: url(../images/ic-medal.svg) no-repeat center;
              background-size: contain; } }
          &.locked {
            color: #8B95A5;
            span {
              text-decoration: line-through; }
            .accr__content {
              color: #8B95A5; }
            &::before {
              background: url(../images/ic-locked.svg) no-repeat center;
              background-size: contain; } }
          &__content {
            color: #626B79;
            height: 0;
            margin: 0;
            opacity: 0;
            visibility: hidden;
            transition: all linear .1s; }
          &.active {
            padding-bottom: 12px;
            &> span {
              margin-bottom: 12px;
              transition: all linear .3s; }
            &::after {
              transform: rotate(180deg);
              transition: transform linear .3s; }
            .accr__content {
              height: auto;
              opacity: 1;
              visibility: visible;
              transition: all linear .3s; }
            &.medal {
              background: #FFF;
              transition: background linear .3s; } } } }
      .link-more {
        text-align: center;
        margin-top: 26px; } } } }


.modal--full-alt {
  .modal-body {
    padding-bottom: 0!important;
    .container {
      display: flex;
      height: 100%;
      align-items: center; }
    .promo-benefit {
      &__title {
        font-size: 32px;
        line-height: 48px; } } }
  @include maxtablet() {
    .modal-body {
      padding-top: 45%;
      .promo-benefit {
        &__title {
          font-size: 28px;
          line-height: 36px; }
        &__img {
          display: block;
          margin-bottom: 24px; }
        &__wrap {
          &> .row {
            flex-direction: row-reverse; } } } } } }
