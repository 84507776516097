.value-props {
  padding: 64px 0 0 0;
  background: #F1F5F9;
  &__list {
    .row {
      padding: 64px 0;
      .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 16px; }
      .desc {
        margin-bottom: 32px; }
      .action {
        a {
          margin-right: 16px;
          &:last-child {
            margin-right: 0; } } }
      &:nth-child(even) {
        flex-direction: row-reverse; } } }

  &__title {
    text-align: center; }

  @include maxtablet() {
    padding: 32px 0 0 0;
    &__list {
      .row {
        padding: 32px 0;
        flex-direction: column-reverse;
        &:nth-child(even) {
          flex-direction: column-reverse; }
        .img {
          img {
            display: block;
            margin: 0 auto; } } } } } }
