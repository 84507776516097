@import 'cores/mixin';


// Color
$primary: #005FBF;
$purple: #5315FF;
$green: #2AC397;
$green-text: #2BC656;
$red: #009BDE;
$brand: #009BDE;
$black: #2B2A35;
$blue: #4B61DD;
$border: #E2E2E2;
$muted: #B4B4B4;

// $text: #545465
// $text: #2B2A35
// $text: #626B79
$text: #232933;
$text-light: #545465;
$text-dark: #2B2A35;
$link: #334AC0;
$white: #FFF;



/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/fonts';
@import 'cores/base';
@import 'cores/layout';


/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/animation';
@import 'components/general';
@import 'components/header-inline';
@import 'components/header-blog';
@import 'components/form';
@import 'components/content';
@import 'components/content-blog';
@import 'components/footer';
@import 'components/responsive';
@import 'components/modal';


@import 'pages/partner';
@import 'pages/in-app-jurnalpay';
@import 'pages/mekari-pay';
@import 'pages/whats-new';
@import 'pages/changelog';
@import 'pages/pricing';
@import 'pages/thankyou';
@import 'pages/referral';
@import 'pages/contact-thankyou';
@import 'pages/talenta-starter';
@import 'pages/case-study-detail';
@import 'pages/landing-jurnal-talenta';
@import 'pages/homepage';
@import 'pages/mekari-capital';
@import 'pages/multi-outlet';
@import 'pages/partnership';
@import 'pages/onepager';
@import 'pages/contact';
@import 'pages/integration';
@import 'pages/bukalapak';
@import 'pages/feature-detail';
@import 'pages/formpage';
@import 'pages/invoice-generator';
@import 'pages/404';
@import 'pages/privacy-policy';
@import 'pages/promo';
@import 'pages/sitemap';
@import 'pages/directory';
@import 'pages/microsite';



@import 'parts/propose';
@import 'parts/accr-steps';
@import 'parts/banner-block';
@import 'parts/testi-compact';
@import 'parts/value-props';
@import 'parts/compliance';
@import 'parts/video-block';
@import 'parts/price-block';
@import 'parts/masthead-full';
@import 'parts/clients';
@import 'parts/tab-features';
@import 'parts/features';
@import 'parts/sidebox-block';
@import 'parts/integrations-block';
@import 'parts/tab-testimonial';
@import 'parts/faq';
@import 'parts/cta-box';
// @import 'parts/price-card'
@import 'parts/top-banner';
@import 'parts/masthead-logo-slider';
@import 'parts/client-testimonial';
@import 'parts/card-testimonial';
@import 'parts/block-feat';
@import 'parts/single-price-card';
@import 'parts/testimoni-twoblock';
@import 'parts/lottie-block';
