.testimonial--promo {
  .mpay-video {
    &__cover {
      padding-top: 56%;
      img {
        height: auto; } } } }

.product-featured {
  .nav-tabs.product-featured__tabs {
    &::before {
      display: none; }
    .nav-item {
      opacity: 1;
      overflow: hidden; }
    .nav-link {
      position: relative;
      display: block;
      border: 0;
      text-align: center;
      opacity: 1;
      color: #2B2A35;
      background: $white;
      padding: 1.25rem 3.5rem 3.5rem;
      overflow: hidden;
      .icon {
        display: block;
        object-fit: contain;
        object-position: 50% 50%;
        margin: 0 auto 1rem;
        filter: grayscale(100%); }
      .svg {
        path {
          fill: #545465;
          transition: all .4s ease; } }
      span {
        display: block;
        font-size: 1rem;
        line-height: 1.5em; }
      &.active {
        .icon {
          filter: grayscale(0); }
        &:after {
          content: "";
          display: block;
          width: 82px;
          height: 82px;
          border-radius: 41px;
          background-color: #E5E5E5;
          position: absolute;
          top: calc(100% - 41px);
          left: calc(50% - 41px);
          background-image: url(../images/ic-chevron-down-cyan.svg);
          background-repeat: no-repeat;
          background-position: center 30%; } } } }

  .tab-pane {
    padding: 42px 0;
    .tab-title-sm {
      display: none; }
    h2 {
      margin-bottom: 24px; }
    .img {
      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 0.25rem; } }

    .list-check {
      li {
        padding-bottom: 2rem; } } }
  .tab-content {
      background-color: #E5E5E5; }
  @include maxtablet() {
    padding-top: 2.5rem;
    background: #E5E5E5;
    .nav-container {
      margin: 0;
      padding-top: 52px;
      background: transparent; }
    .nav-item {
      width: 100%;
      left: 0;
      &.active {
        width: 100%;
        left: 0;
        &:after {
          right: 1.5rem; } } }
    .nav-link {
      color: $text-dark;
      font-size: 0.875rem;
      line-height: 1.429em;
      height: auto;
      max-height: initial;
      padding: 0.875rem 2.75rem 0.875rem 0.75rem!important;
      text-align: left!important;
      .icon {
        display: none!important; }
      span {
        display: block!important;
        br {
          display: none; } }
      &.active {
        max-height: initial;
        padding: 0.875rem 2.75rem 0.875rem 0.75rem;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        &::after {
          display: none!important; } } }
    .nav-tabs.product-featured__tabs {
      left: 0;
      top: 0;
      padding: 54px 0 0;
      margin-left: 0!important;
      margin-right: 0!important;
      position: absolute!important;
      &.show {
        .nav-link {
          height: auto;
          max-height: initial;
          padding: 0.875rem 2.75rem 0.875rem 0.75rem; } } }
    .tab-pane {
      padding: 2.5rem 0 4rem;
      .tab-title-sm {
        display: block;
        margin: 0 0 2.5rem;
        .icon {
          margin: 0 0 0.5rem;
          img {
            display: block;
            height: 3rem;
            width: auto;
            margin: 0 auto; } }
        .title {
          display: block;
          text-align: center;
          color: $text-dark;
          @include font-500();
          br {
            display: none; } } }
      .img {
        margin-bottom: 2rem;
        img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 0.25rem; } }
      .video {
        margin-bottom: 2rem; }
      h2 {
        font-size: 1.5rem;
        line-height: 1.5em;
        margin-bottom: 1.5rem; }
      .list-check {
        li {
          padding-bottom: 2rem; } } } } }

.card-box {
  background: #FFF;
  border: 1px solid #009BDE;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;
  height: calc(100% - 24px);
  &__header {
    text-align: center;
    border-bottom: 1px solid #009BDE;
    background: #E0EEFF;
    padding: 24px 16px; }
  &__icon {
    display: inline-block;
    width: 52px;
    height: 52px;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 8px; }
  &__title {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    p {
      margin-bottom: 0; }
    b {
      font-size: 20px;
      line-height: 28px;
      @include font-600; } }
  &__body {
    padding: 34px 24px 24px 24px; } }

.card-vid {
  background: #FFFFFF;
  border-radius: 6px;
  padding: 6px;
  margin-bottom: 50px;
  height: calc(100% - 50px);
  position: relative;
  &__img {
    margin-bottom: 20px;
    border-radius: 6px;
    overflow: hidden;
    img {
      height: 156px;
      width: auto;
      object-fit: cover; } }
  &__meta {
    font-size: 12px;
    line-height: 16px;
    color: #626B79;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    span {
      position: relative;
      &:nth-child(2) {
        margin-left: 15px;
        &::before {
          content: '•';
          position: absolute;
          left: -9px;
          top: -2px;
          display: inline-block; } } } }
  &__title {
    font-size: 20px;
    line-height: 28px;
    @include font-600();
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 17px; }
  &__action {
    position: absolute;
    bottom: -50px;
    left: 0;
    padding: 8px;
    background: #fff;
    width: 100%;
    border-radius: 0 0 6px 6px;
    opacity: 0;
    visibility: hidden;
    transition: all ease-out .3s;
    .btn {
      width: 100%; } }
  &:hover {
    .card-vid__action {
      opacity: 1;
      visibility: visible;
      transition: all ease-out .3s;
      z-index: 2; } }
  @include maxtablet() {
    &__img {
      img {
        width: 100%;
        height: auto; } }
    &__action {
      position: relative;
      opacity: 1;
      visibility: visible;
      bottom: 0; } } }

.fea-block {
  padding: 60px 0;
  &.row-reverse {
    .row {
      flex-direction: row-reverse;
      .promo-benefit__content {
        padding-right: 0;
        padding-left: 60px; } } }
  @include maxtablet() {
    padding: 40px 0;
    &.row-reverse {
      .row {
        flex-direction: column-reverse;
        .promo-benefit__content {
          padding-left: 0; } } }
    .row {
      flex-direction: column-reverse; }
    .promo-benefit__img {
      display: block; } } }
