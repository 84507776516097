.masthead--mpay {
  .masthead__desc {
    margin-bottom: 24px;
    p {
      &:last-child {
        margin-bottom: 0; } } } }

.mpay-stats {
  &__title {
    text-align: center;
    margin-bottom: 48px; }
  .item {
    &__title {
      color: #009BDE;
      font-size: 48px;
      line-height: 56px;
      font-weight: 600;
      margin-bottom: 4px; }
    &__text {
      font-weight: 600;
      max-width: 275px;
      font-size: 18px;
      line-height: 28px; } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include maxtablet() {
    &__title {
      text-align: left;
      br {
        display: none; } }
    .item {
      margin-bottom: 40px;
      &__title {
        font-size: 36px;
        line-height: 44px; }
      &:last-child {
        margin-bottom: 0; } }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px;
      padding-left: 24px;
      padding-right: 24px; } } }

.mpay-tabs {
  &__sub {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    text-align: center; }
  &__title {
    text-align: center;
    margin-bottom: 28px; }
  &__desc {
    margin-bottom: 64px;
    text-align: center; }
  &__wrap {
    padding-top: 80px; }
  &__nav {
    margin-bottom: 64px;
    display: flex;
    margin-left: -12px;
    margin-right: -12px;
    .nav-item {
      display: block;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
      padding-left: 12px;
      padding-right: 12px; }
    .nav-link {
      color: $text-dark;
      position: relative;
      padding: 24px;
      padding-left: 96px;
      display: block;
      border: 1px solid #E3E3E8;
      border-radius: 4px;
      .ic {
        display: block;
        position: absolute;
        width: 56px;
        height: 56px;
        max-width: 56px;
        top: 24px;
        left: 24px;
        filter: grayscale(1) brightness(0.9);
        transition: all .3s ease; }
      span.title {
        font-weight: 500;
        display: block;
        margin-bottom: 12px; }
      &.active {
        background: #F2F4F7;
        border: 1px solid #005FBF;
        box-shadow: 0px 0px 0px 1px rgba(0,95,191,1);
        .ic {
          filter: none; } } } }
  @include maxtablet() {
    &__title {
      br {
        display: none; } }
    &__wrap {
      padding: 64px 24px; }
    &__nav {
      margin-left: -8px;
      margin-right: -8px;
      margin-bottom: 0;
      .nav-item {
        padding-left: 8px;
        padding-right: 8px; }
      .nav-link {
        padding: 16px;
        .ic {
          position: relative;
          margin: 0 auto 10px;
          top: auto;
          left: auto;
          height: 32px;
          width: auto; }
        span.title {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0;
          text-align: center; }
        p {
          display: none; } } } } }


.mpay-video {
  padding-bottom: 80px;
  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px; }
  .btn-play {
    position: absolute;
    top: calc(50% - 50px);
    right: calc(50% - 50px);
    padding-left: 0;
    &::before {
      display: none; }
    &:hover {
      border: 0!important; }
    .bg {
      z-index: 2;
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50px;
      background-image: url(../images/ic-play-blue.svg);
      background-size: 22px;
      background-color: #FFF;
      background-repeat: no-repeat;
      background-position: 53% 33%;
      position: relative;
      @include pulseBig(); }
    span {
      z-index: 4;
      display: block;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: $text-dark;
      position: absolute;
      bottom: 20%;
      left: 0;
      text-align: center; } }
  &__cover {
    width: 100%;
    padding-top: 58%;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; } }
  @include maxtablet() {
    padding-bottom: 64px;
    padding-left: 24px;
    padding-right: 24px;
    &__title {
      margin-bottom: 16px; } } }

.mpay-block {
  &.bg-snow {
    background: #F2F4F7; }
  &__title {
    text-align: center; }
  &__desc {
    text-align: center;
    margin-bottom: 16px; }
  .item {
    &__wrap {
      padding-top: 30px;
      padding-bottom: 30px;
      align-items: center; } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 0; }
  @include maxtablet() {
    .item__img {
      padding: 24px 0; }
    &__wrap {
      padding: 64px 24px 0; } } }

.testimonial-industry--mpay {
  background: #FFF;
  &.bg-snow {
    background: #F2F4F7; }
  .testimonial-industry__wrap {
    padding: 48px 0; }
  @include maxtablet() {
    .testimonial-industry__wrap {
      padding: 0; } } }

.block-cta-alt--mpay {
  padding-top: 16px;
  @include maxtablet() {
    .block-cta-alt__title {
      padding-top: 0;
      font-size: 24px;
      line-height: 32px;
      &::before {
        display: none; } }
    .container {
      padding-top: 0;
      padding-bottom: 64px; } } }

.block-cs--mpay {
  background: #F2F4F7;
  padding-top: 80px;
  .block-cs {
    &__title {
      text-align: center;
      margin-bottom: 28px; }
    &__desc {
      text-align: center;
      max-width: 600px;
      margin: 0 auto 64px; } }
  .item__right {
    background: #FFF; } }

.support-block--mpay {
  .section-head {
    margin-bottom: 0; } }


.mpay-fea {
  &:nth-child(even) {
    .mpay-block {
      .item__wrap {
        flex-direction: row-reverse; } } }
  .testimonial-industry {
    .item {
      .item__box {
        background: #10253E;
        color: #FFF; } } }
  @include maxtablet() {
    .mpay-block {
      .item__wrap {
        flex-direction: column-reverse!important; } } } }

.home-why--alt {
  .item__img {
    .img {
      padding: 0; } }
  .item__wrap {
    height: 100%; } }
