.contact-ty {
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    background: #F2F4F7;
    position: absolute;
    left: 50%;
    top: 0; }
  &__logo {
    margin-bottom: 40px;
    img {
      display: block;
      height: 48px;
      width: auto; } }
  &__desc {
    margin-bottom: 32px;
    &+ hr {
      margin-bottom: 32px; } }
  &__form {
    margin-bottom: 80px;
    .form-title {
      margin-bottom: 32px; }
    .btn-group--custom {
      .btn {
        padding: 24px 16px 20px;
        border: 1px solid #e2e2e2;
        background-color: #FFF;
        .icon {
          display: block;
          height: 32px;
          width: auto;
          margin: 0 auto 12px; }
        span {
          font-size: 12px;
          line-height: 16px;
          color: #000000;
          font-weight: 400; } } }
    .form-group--products {
      padding-bottom: 40px; }
    .form-group--pic {
      display: none;
      padding-bottom: 20px;
      .pic-cb {
        margin-top: 8px;
        label {
          margin: 0;
          font-weight: 400; } } } }
  &__banner {
    .item {
      display: none;
      &.active {
        display: block; }
      &__img {
        margin-bottom: 24px;
        img {
          display: block;
          max-width: 100%;
          width: auto;
          height: auto; } }
      &__title {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 24px;
        font-weight: 600;
        color: #2B2A35; }
      &__desc {
        max-width: 600; } } }
  .contact-ty__left {
    padding-top: 40px;
    padding-right: 24px; }
  .contact-ty__right {
    padding-left: 48px; }

  @include maxtablet() {
    &::before {
      display: none; }
    &__form {
      margin-bottom: 48px; }
    .form-group--pic {
      .col-lg-6 {
        margin-bottom: 12px; } }
    .contact-ty__left {
      padding: 40px 24px 0; }
    .contact-ty__right {
      padding: 40px 24px 60px;
      background: #F2F4F7; } }


  &--alt {
    padding: 64px 0;
    background: #F2F4F7;
    .contact-ty__left {
      padding: 0; }
    .contact-ty__banner {
      .item {
        &__img {
          img {
            margin: 24px auto; } } } }
    .contact-ty__form {
      margin-bottom: 0;
      .btn-group--custom {
        .btn {
          height: calc(100% - 16px);
          text-align: left;
          padding: 16px 16px 16px 48px;
          position: relative;
          input[type="checkbox"] {
            position: absolute;
            visibility: visible;
            opacity: 1;
            z-index: 1;
            left: 20px;
            top: 25px; }
          .icon {
            margin: 0 0 12px 0; }
          span {
            color: #626B79;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
            display: block;
            &.more {
              color: #232933;
              margin-bottom: 0;
              a {
                color: #4B61DD;
                text-decoration: underline;
                &:hover {
                  text-decoration: none;
                  color: darken(#4B61DD, 30%); } } } } } } } } }


