.tab-features {
  padding: 64px 0;
  &.alt {
    .tab-features__tabs {
      .nav {
        .nav-item {
          .nav-link {
            font-size: 20px;
            line-height: 28px;
            padding: 24px 0; } } } } }
  &__head {
    text-align: center;
    margin-bottom: 24px; }
  &__tabs {
    justify-content: center;
    &.block {
      margin-bottom: 32px;
      .nav {
        background: #EDF0F2;
        border-radius: 48px;
        padding: 12px;
        border-bottom: none;
        .nav-item {
          .nav-link {
            padding: 12px;
            border-radius: 48px;
            border-bottom: none;
            @include font-400();
            &.active {
              background: #4B61DD;
              color: #fff;
              @include font-600(); } } } } }
    .nav {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      border-bottom: 1px solid #D0D6DD;
      padding: 0 24px;
      .nav-item {
        .nav-link {
          padding: 12px 0;
          font-size: 16px;
          line-height: 24px;
          color: #626B79;
          @include font-600();
          display: inline-flex;
          border-block: 2px solid transparent;
          &.active {
            border-bottom-color: #0055A2; }
          &:hover,
          &.active {
            color: #232933; } } } } }
  .tab-content {
    .tab-pane {
      .toggle-sm {
        display: none; }
      .content {
        &> section {
          padding: 32px 0 16px 0; } } } }

  @include maxtablet() {
    padding: 32px 0;
    .tab-content {
      .tab-pane {
        &:not(.active) {
          opacity: 1;
          display: block;
          .toggle-sm {
            display: flex;
            font-size: 16px;
            line-height: 24px;
            color: #626B79;
            border-bottom: 1px solid #D0D6DD;
            padding: 12px 0;
            @include font-600();
            position: relative;
            align-items: center;
            &::after {
              content: '';
              width: 24px;
              height: 24px;
              display: inline-flex;
              margin-left: auto;
              background: url(../images/ic-caret-down.svg) no-repeat center;
              background-position: contain;
              transform: rotate(0deg);
              transition: transform linear .3s; } }
          .content {
            height: 0;
            opacity: 0; } }
        &.active {
          .toggle-sm {
            display: flex;
            font-size: 16px;
            line-height: 24px;
            border-bottom: 1px solid #009BDE;
            color: #232933;
            padding: 12px 0;
            @include font-600();
            position: relative;
            align-items: center;
            &::after {
              content: '';
              width: 24px;
              height: 24px;
              display: inline-flex;
              margin-left: auto;
              background: url(../images/ic-caret-down.svg) no-repeat center;
              background-position: contain;
              transform: rotate(180deg);
              transition: transform linear .3s; } }
          .content {
            height: auto;
            opacity: 1;
            transition: opacity linear .3s;
            &> section {
              padding: 12px 0; } } } } } } }



