// Margin & Padding
$mg : 8 16 24 32 40 48 56 64 72 80 88;
@mixin layout($prefix:'') {
  @for $i from 1 through length($mg) {
    .#{$prefix}mb-#{nth($mg, $i)} {
      margin-bottom: #{nth($mg, $i)}px; }
    .#{$prefix}mt-#{nth($mg, $i)} {
      margin-top: #{nth($mg, $i)}px; }
    .#{$prefix}mr-#{nth($mg, $i)} {
      margin-right: #{nth($mg, $i)}px; }
    .#{$prefix}ml-#{nth($mg, $i)} {
      margin-left: #{nth($mg, $i)}px; }
    .#{$prefix}pb-#{nth($mg, $i)} {
      padding-bottom: #{nth($mg, $i)}px; }
    .#{$prefix}pt-#{nth($mg, $i)} {
      padding-top: #{nth($mg, $i)}px; }
    .#{$prefix}pr-#{nth($mg, $i)} {
      padding-right: #{nth($mg, $i)}px; }
    .#{$prefix}pl-#{nth($mg, $i)} {
      padding-left: #{nth($mg, $i)}px; } } }
@include layout();
