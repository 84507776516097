@charset "UTF-8";
@keyframes scroll-x { from { transform: translateX(0); }
  to { transform: translateX(calc(-100% - 32px)); } }

@keyframes scroll-y { from { transform: translateY(0); }
  to { transform: translateY(calc(-100% - 32px)); } }

/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* Fonts */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* Common */
/* ------------------------------------------------------------------------------------------------ */
*, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

hr { border: 0; border-top: 1px solid #e2e2e2; }

.tab-pane:not(.active) { display: none; }

* { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; text-decoration: none; font-feature-settings: "kern" 1; font-kerning: normal; }

*::-webkit-scrollbar { width: 5px; height: 5px; }

*::-webkit-scrollbar-track { background: #D0D6DD; border-radius: 10px; }

*::-webkit-scrollbar-thumb { background: rgba(75, 97, 221, 0.8); border-radius: 5px; }

*::-webkit-scrollbar-thumb:window-inactive { background: rgba(51, 51, 51, 0.4); }

@media (min-width: 576px) { .container { max-width: 540px; } }

@media (min-width: 768px) { .container { max-width: 720px; } }

@media (min-width: 992px) { .container { max-width: 960px; } }

@media (min-width: 1200px) { .container { max-width: 1248px; } }

.container { padding-left: 12px; padding-right: 12px; }

.row { margin-left: -12px; margin-right: -12px; }

.row > .col, .row > [class*="col-"] { padding-left: 12px; padding-right: 12px; }

.hidden { display: none !important; }

input, textarea { padding: 0; border-radius: 0; }

.block { display: block; }

.fright { float: right; }

.text-right { text-align: right; }

p { margin: 0; padding: 0; }

p strong, p b { font-weight: bold; }

p em, p i { font-style: italic; }

p u { text-decoration: underline; }

h1, h2, h3, h4 { margin: 0 0 8px; }

article p { word-break: break-word; -webkit-hyphens: none; -moz-hyphens: none; -ms-hyphens: none; hyphens: none; clear: both; }

article a:visited { text-decoration: underline; }

article a:hover { text-decoration: none; }

article blockquote { font-style: italic; }

article blockquote:before { content: "\201C"; display: inline-block; padding-right: 0.4em; }

article li ul, article li ol { margin: 0 1.5em; }

article ul, article ol { margin: 0 0 32px; padding-left: 20px; }

article ul:last-child, article ol:last-child { margin: 0; }

article ul { list-style-type: disc; }

article ol { list-style-type: decimal; }

article ol ol { list-style: upper-alpha; }

article ol ol ol { list-style: lower-roman; }

article ol ol ol ol { list-style: lower-alpha; }

article iframe { border: 0; }

ul, ol { padding: 0; margin: 0; }

figure { margin: 0; }

figcaption { font-size: 14px; line-height: 20px; color: #626B79; margin: 8px 0; }

img { max-width: 100%; height: auto; }

img.aligncenter { display: block; margin: auto; }

img.alignright { float: right; margin-left: 16px; }

.font-16, .tf h3, .tf .tf-cta__title, .footer h3, .footer .footer-menu__title { font-size: 16px; line-height: 26px; }

ul { list-style: none; }

label { font-weight: normal; }

html { line-height: 1.15; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; -ms-overflow-style: scrollbar; -webkit-tap-highlight-color: transparent; }

html, body { font-family: "Inter", sans-serif; font-weight: 400; font-size: 16px; line-height: 24px; margin: 0; color: #232933; text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased; }

html input, body input { font-family: inherit; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section { display: block; }

input:focus { outline: none; box-shadow: none; }

* { outline: none !important; }

.text-center { text-align: center; }

.mb-8 { margin-bottom: 8px; }

.mt-8 { margin-top: 8px; }

.mr-8 { margin-right: 8px; }

.ml-8 { margin-left: 8px; }

.pb-8 { padding-bottom: 8px; }

.pt-8 { padding-top: 8px; }

.pr-8 { padding-right: 8px; }

.pl-8 { padding-left: 8px; }

.mb-16 { margin-bottom: 16px; }

.mt-16 { margin-top: 16px; }

.mr-16 { margin-right: 16px; }

.ml-16 { margin-left: 16px; }

.pb-16 { padding-bottom: 16px; }

.pt-16 { padding-top: 16px; }

.pr-16 { padding-right: 16px; }

.pl-16 { padding-left: 16px; }

.mb-24 { margin-bottom: 24px; }

.mt-24 { margin-top: 24px; }

.mr-24 { margin-right: 24px; }

.ml-24 { margin-left: 24px; }

.pb-24 { padding-bottom: 24px; }

.pt-24 { padding-top: 24px; }

.pr-24 { padding-right: 24px; }

.pl-24 { padding-left: 24px; }

.mb-32 { margin-bottom: 32px; }

.mt-32 { margin-top: 32px; }

.mr-32 { margin-right: 32px; }

.ml-32 { margin-left: 32px; }

.pb-32 { padding-bottom: 32px; }

.pt-32 { padding-top: 32px; }

.pr-32 { padding-right: 32px; }

.pl-32 { padding-left: 32px; }

.mb-40 { margin-bottom: 40px; }

.mt-40 { margin-top: 40px; }

.mr-40 { margin-right: 40px; }

.ml-40 { margin-left: 40px; }

.pb-40 { padding-bottom: 40px; }

.pt-40 { padding-top: 40px; }

.pr-40 { padding-right: 40px; }

.pl-40 { padding-left: 40px; }

.mb-48 { margin-bottom: 48px; }

.mt-48 { margin-top: 48px; }

.mr-48 { margin-right: 48px; }

.ml-48 { margin-left: 48px; }

.pb-48 { padding-bottom: 48px; }

.pt-48 { padding-top: 48px; }

.pr-48 { padding-right: 48px; }

.pl-48 { padding-left: 48px; }

.mb-56 { margin-bottom: 56px; }

.mt-56 { margin-top: 56px; }

.mr-56 { margin-right: 56px; }

.ml-56 { margin-left: 56px; }

.pb-56 { padding-bottom: 56px; }

.pt-56 { padding-top: 56px; }

.pr-56 { padding-right: 56px; }

.pl-56 { padding-left: 56px; }

.mb-64 { margin-bottom: 64px; }

.mt-64 { margin-top: 64px; }

.mr-64 { margin-right: 64px; }

.ml-64 { margin-left: 64px; }

.pb-64 { padding-bottom: 64px; }

.pt-64 { padding-top: 64px; }

.pr-64 { padding-right: 64px; }

.pl-64 { padding-left: 64px; }

.mb-72 { margin-bottom: 72px; }

.mt-72 { margin-top: 72px; }

.mr-72 { margin-right: 72px; }

.ml-72 { margin-left: 72px; }

.pb-72 { padding-bottom: 72px; }

.pt-72 { padding-top: 72px; }

.pr-72 { padding-right: 72px; }

.pl-72 { padding-left: 72px; }

.mb-80 { margin-bottom: 80px; }

.mt-80 { margin-top: 80px; }

.mr-80 { margin-right: 80px; }

.ml-80 { margin-left: 80px; }

.pb-80 { padding-bottom: 80px; }

.pt-80 { padding-top: 80px; }

.pr-80 { padding-right: 80px; }

.pl-80 { padding-left: 80px; }

.mb-88 { margin-bottom: 88px; }

.mt-88 { margin-top: 88px; }

.mr-88 { margin-right: 88px; }

.ml-88 { margin-left: 88px; }

.pb-88 { padding-bottom: 88px; }

.pt-88 { padding-top: 88px; }

.pr-88 { padding-right: 88px; }

.pl-88 { padding-left: 88px; }

/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */
@keyframes bounce2 { 0% { transform: scale(1, 1) translateX(0); }
  10% { transform: scale(1.1, 0.9) translateX(0); }
  30% { transform: scale(0.9, 1.1) translateX(-4px); }
  50% { transform: scale(1.05, 0.95) translateX(6px); }
  57% { transform: scale(1, 1) translateX(-2px); }
  64% { transform: scale(1, 1) translateX(0); }
  100% { transform: scale(1, 1) translateX(0); } }

@keyframes bounceRev { 0% { transform: scale(1, 1) translateX(0); }
  10% { transform: scale(1.1, 0.9) translateX(0); }
  30% { transform: scale(0.9, 1.1) translateX(4px); }
  50% { transform: scale(1.05, 0.95) translateX(-6px); }
  57% { transform: scale(1, 1) translateX(2px); }
  64% { transform: scale(1, 1) translateX(0); }
  100% { transform: scale(1, 1) translateX(0); } }

@keyframes bounceUp { 0% { transform: scale(1, 1) translateY(0); }
  10% { transform: scale(1.1, 0.9) translateY(0); }
  30% { transform: scale(0.9, 1.1) translateY(-4px); }
  50% { transform: scale(1.05, 0.95) translateY(6px); }
  57% { transform: scale(1, 1) translateY(-2px); }
  64% { transform: scale(1, 1) translateY(0); }
  100% { transform: scale(1, 1) translateY(0); } }

@keyframes pulse { 0% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4); }
  70% { box-shadow: 0 0 0 14px rgba(0, 0, 0, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

@keyframes pulseSm { 0% { box-shadow: 0 0 0 0 rgba(0, 135, 217, 0.6); }
  70% { box-shadow: 0 0 0 8px rgba(0, 135, 217, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 135, 217, 0); } }

@keyframes pulseBig { 0% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4); }
  70% { box-shadow: 0 0 0 30px rgba(255, 255, 255, 0); }
  100% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@keyframes pulseBlue { 0% { box-shadow: 0 0 0 0 rgba(75, 97, 221, 0.4); }
  70% { box-shadow: 0 0 0 30px rgba(75, 97, 221, 0); }
  100% { box-shadow: 0 0 0 0 rgba(75, 97, 221, 0); } }

@keyframes pulse2 { 0% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0.8); }
  70% { box-shadow: 0 0 0 18px rgba(0, 95, 191, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0); } }

@keyframes dash { from { stroke-dashoffset: 1000; }
  to { stroke-dashoffset: 0; } }

@keyframes dash2 { from { stroke-dashoffset: 1500; }
  to { stroke-dashoffset: 0; } }

.slideOutDown { animation-name: slideOutDown; }

@keyframes slideOutDown { 0% { opacity: 1;
    transform: scale(1); }
  100% { opacity: 0;
    transform: scale(1.25); } }

.comeOut { animation-name: comeOut; }

@keyframes comeOut { 0% { opacity: 1;
    transform: scale(0.5); }
  100% { opacity: 0;
    transform: scale(1); } }

@keyframes progressBar { 0% { max-width: 0%; }
  100% { max-width: 100%; } }

@keyframes pulseBlueSm { 0% { box-shadow: 0 0 0 0 rgba(75, 97, 221, 0.4); }
  70% { box-shadow: 0 0 0 30px rgba(75, 97, 221, 0); }
  80% { box-shadow: 0 0 0 0 rgba(75, 97, 221, 0); } }

@keyframes bounceDown { 0% { transform: scale(1, 1) translateY(0); }
  10% { transform: scale(1.1, 0.9) translateY(0); }
  30% { transform: scale(0.9, 1.1) translateY(-4px); }
  50% { transform: scale(1.05, 0.95) translateY(6px); }
  57% { transform: scale(1, 1) translateY(-2px); }
  64% { transform: scale(1, 1) translateY(0); }
  100% { transform: scale(1, 1) translateY(0); } }

/* ------------------------------------------------------------------------------------------------ */
/* General */
/* ------------------------------------------------------------------------------------------------ */
body { transition: all .2s; }

body.ready { opacity: 1; }

body.full { background: #f9f9f9; }

body.pause { position: fixed; width: 100%; height: 100%; top: 0; left: 0; overflow: hidden; }

.web-wrapper { height: auto; }

h1 { font-family: "Inter", sans-serif; font-weight: 700; font-size: 40px; line-height: 56px; color: #2B2A35; margin: 0 0 8px; }

@media (max-width: 991px) { h1 { font-size: 28px; line-height: 36px; } }

h2 { font-family: "Inter", sans-serif; font-weight: 600; font-size: 32px; line-height: 48px; color: #2B2A35; margin: 0 0 8px; }

@media (max-width: 991px) { h2 { font-size: 28px; line-height: 36px; } }

h3 { font-family: "Inter", sans-serif; font-weight: 500; font-size: 24px; line-height: 32px; color: #2B2A35; margin: 0 0 8px; }

@media (max-width: 991px) { h3 { font-size: 20px; line-height: 28px; } }

h4 { font-family: "Inter", sans-serif; font-weight: 500; font-size: 20px; line-height: 32px; color: #2B2A35; margin: 0 0 8px; }

@media (max-width: 991px) { h4 { font-size: 16px; line-height: 24px; } }

small { font-family: "Inter", sans-serif; font-weight: 400; font-size: 14px; line-height: 30px; }

small b { font-family: "Inter", sans-serif; font-weight: 500; }

@media (max-width: 991px) { small { font-size: 0.875rem; line-height: 1.5rem; } }

b { font-family: "Inter", sans-serif; font-weight: 500; }

body.has-sb .header.fixed { top: 0; }

body.has-sb .header.fixed .header__top { max-height: 0; padding-top: 0; padding-bottom: 0; opacity: 0; transition: all .4s ease; }

body.has-sb .header.fixed.show-top { top: 0; }

body.has-sb .header.fixed.show-top .header__top { max-height: 40px; padding-top: 8px; padding-bottom: 8px; opacity: 1; visibility: visible; height: auto; }

body.has-sb main { padding-top: 88px; }

@media (max-width: 768px) { body.has-sb main { padding-top: 0; } }

.btn { display: inline-block; text-align: center; color: #fff; cursor: pointer; transition: all .2s; border-radius: 6px; background-color: #4B61DD; padding: 7px 24px; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; border: 1px solid #4B61DD; }

.btn:hover { background: #2740ce; }

.btn.small, .btn.btn-sm { padding: 0.4375rem 1.5rem; font-size: 16px; line-height: 1.5em; }

.btn.add { padding: 7px 24px 7px 16px; position: relative; }

.btn.add::before { content: ''; width: 24px; height: 24px; display: inline-block; vertical-align: middle; margin-top: -5px; margin-right: 8px; background: url("../images/ic-add-white.svg") no-repeat center; background-size: contain; }

.btn.add img { display: none !important; }

.btn.cta-waba { padding: 7px 16px 7px 16px; position: relative; }

.btn.cta-waba::before { content: ''; width: 24px; height: 24px; display: inline-block; vertical-align: middle; margin-top: -5px; margin-right: 8px; background: url("../images/ic-waba-white.svg") no-repeat center; background-size: contain; }

.btn.cta-waba img { display: none !important; }

.btn.cta-phone { padding: 7px 24px 7px 16px; position: relative; }

.btn.cta-phone::before { content: ''; width: 24px; height: 24px; display: inline-block; vertical-align: middle; margin-top: -5px; margin-right: 8px; background: url("../images/ic-phone.svg") no-repeat center; background-size: contain; }

.btn.cta-phone img { display: none !important; }

@media (max-width: 768px) { .btn { display: block; width: 100%; margin-right: 0; margin-bottom: 12px; }
  .btn::first-child { margin-bottom: 16px; } }

.btn-transparent { background: none; color: #545465; font-weight: 400; border-color: transparent; box-shadow: none; }

.btn-transparent.w-border { border-color: #E2E2E2; }

.btn-transparent:hover { background: none; color: #009BDE; }

.btn-transparent--invert { background: none; color: #005FBF; font-weight: 400; border-color: transparent; box-shadow: none; }

.btn-transparent--invert.w-border { border-color: #005FBF; }

.btn-transparent--invert:hover { background: none; color: #2B2A35; }

.btn-bred { color: #4B61DD; background: none; box-shadow: none; }

.btn-bred:hover { color: #FFF; background: #4B61DD; border-color: #4B61DD; }

.btn-block { display: block; width: 100%; }

.btn-blue { background: #4B61DD; border-color: #4B61DD; font-size: 16px; font-family: "Inter", sans-serif; font-weight: 500; box-shadow: none; }

.btn-blue:hover { background: #2740ce; border-color: #2740ce; }

.btn-blue:hover .with-arrow { animation-duration: 2s; animation-iteration-count: infinite; animation-name: bounce2; animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.btn-blue.with-arrow { position: relative; padding-right: 35px; color: #FFF; }

.btn-blue.with-arrow:after { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-arrow-right-w.svg) no-repeat center; background-size: contain; position: absolute; right: 10px; left: unset; top: calc(50% - 10px); }

.btn-blue.with-arrow:hover { text-decoration: none; }

.btn-blue.with-arrow:hover::after { right: 10px; left: unset; animation-duration: 2s; animation-iteration-count: infinite; animation-name: bounce2; animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.btn-blue .with-arrow { width: 20px; height: 20px; background: url(../images/ic-arrow-right-w.svg) no-repeat center; background-size: contain; display: inline-block; vertical-align: middle; margin-left: 8px; }

.btn-blue.play { padding: 12px 24px 12px 16px; position: relative; }

.btn-blue.play::before { content: ''; width: 24px; height: 24px; display: inline-block; vertical-align: middle; margin-top: -5px; margin-right: 8px; background: url("../images/ic-play-w.svg") no-repeat center; background-size: contain; }

.btn-blue-border { background: #FFF; color: #4B61DD; border-color: #4B61DD; font-weight: 500; box-shadow: none; }

.btn-blue-border.cta-waba::before { background: url("../images/ic-waba-purple.svg") no-repeat center; }

.btn-blue-border.add::before { background: url("../images/ic-add-blue.svg") no-repeat center; }

.btn-blue-border:hover { color: #FFF; background: #4B61DD; border-color: #4B61DD; }

.btn-blue-border:hover.cta-waba::before { background: url("../images/ic-waba-white.svg") no-repeat center; }

.btn-blue-border:hover.add::before { background: url("../images/ic-add-white.svg") no-repeat center; }

.btn-blue-border:hover .with-arrow { filter: invert(1) brightness(10); animation-duration: 2s; animation-iteration-count: infinite; animation-name: bounce2; animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.btn-blue-border .with-arrow { width: 20px; height: 20px; background: url(../images/ic-arrow-right.svg) no-repeat center; background-size: contain; display: inline-block; vertical-align: middle; margin-left: 8px; }

.btn-blue-border .icon { width: 20px; height: 20px; background-size: contain; background-repeat: no-repeat; background-position: center; display: inline-flex; margin-right: 8px; position: relative; transform: translateY(3px); }

.btn-blue-alt { background: #009BDE; border-color: #009BDE; font-weight: 500; box-shadow: none; }

.btn-blue-alt:hover { background: #0077ab; }

.btn-white { background: #FFF; color: #4B61DD; border-color: #FFF; box-shadow: none; }

.btn-white.cta-waba::before { background: url("../images/ic-waba-blue.svg") no-repeat center; }

.btn-white:hover { background: #4B61DD; border-color: #4B61DD; color: #fff; }

.btn-white:hover img { filter: invert(1) brightness(5); }

.btn-white-border { background: transparent; color: #FFF; border-color: #FFF; font-weight: 500; box-shadow: none; }

.btn-white-border:hover { color: #005FBF; background: #FFF; border-color: #FFF; }

.btn-white-border:hover.cta-waba::before { background: url("../images/ic-waba-blue.svg") no-repeat center; }

.btn-white-border.cta-waba::before { background: url("../images/ic-waba-white.svg") no-repeat center; }

.btn-cyan { color: #002A38; background: #5ED2FA; border-color: #5ED2FA; font-size: 16px; font-family: "Inter", sans-serif; font-weight: 500; box-shadow: none; }

.btn-cyan:hover { background: #2dc4f8; border-color: #2dc4f8; }

.btn-cyan.cta-waba { position: relative; padding-left: 16px; }

.btn-cyan.cta-waba::before { content: ""; display: inline-block; vertical-align: middle; position: relative; top: -2px; width: 24px; height: 24px; background: url("../images/ic-waba-dark.svg") no-repeat center; background-size: contain; margin-right: 6px; }

.btn-trans { background: transparent; color: #FFF; border-color: #E2E2E2; }

.btn-trans:hover { color: #4B61DD; background: #FFF; border-color: #E2E2E2; }

.btn-link { background: transparent; border-color: transparent; color: #232933; box-shadow: none; font-family: "Inter", sans-serif; font-weight: 500; }

.btn-link:hover { background: transparent; border-color: transparent; color: #4B61DD; box-shadow: none; }

.btn-link:hover .icon path { fill: #4B61DD; }

.btn-play { background: transparent; color: #4B61DD; border-color: transparent; box-shadow: none; padding-left: 16px; position: relative; }

.btn-play::before { content: ""; display: inline-block; vertical-align: middle; position: relative; top: -1px; width: 20px; height: 20px; padding: 2px; background-color: transparent; background-image: url("../images/ic-play-video.png"); background-repeat: no-repeat; background-position: center; background-size: contain; margin-right: 6px; }

.btn-play:hover { background: transparent; border: 1px solid transparent; }

.btn-icon .icon { display: inline-block; vertical-align: middle; position: relative; top: -1px; margin-right: 14px; height: 16px; width: auto; }

.btn-icon .icon path { transition: all .3s ease-in-out; }

.btn-icon .play-button { width: auto; height: 11px; display: inline-block; border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-left: 14px solid #545465; border-radius: 3px; margin: 2px 10px -4px 0; transition: all .3s ease-in-out; }

.btn-icon:hover .play-button { border-left-color: #005FBF; transition: all .3s ease-in-out; }

.btn.disabled { pointer-events: none; background: #EDF0F2; border-color: #EDF0F2; color: #8B95A5; }

.btn.disabled.cta-waba { pointer-events: none !important; }

.btn.disabled.cta-waba::before { filter: brightness(0.6); }

.btn-black { font-size: 15px; font-weight: 500; color: #ffffff; background: #2B2A35; border-color: #2B2A35; }

.btn-black:hover { background: #08080a; border-color: #08080a; }

.btn-purple { background: #4B61DD; border-radius: 6px; font-size: 14px; line-height: 20px; }

.btn-purple:hover { background: #2339b9; }

.btn-white-app { background: #FFF; border: 1px solid #EAECFB; border-radius: 6px; color: #4B61DD; font-size: 14px; line-height: 20px; }

.btn-white-app:hover { background: #f2f2f2; }

p { margin: 0 0 24px; }

p:last-child { margin: 0; }

p.line { margin: 0 0 8px; }

a { transition: all .2s; }

a.link { color: #4B61DD; }

a.link:hover { color: #172578; text-decoration: underline; }

a.with-arrow { color: #334AC0; }

a.with-arrow:hover { text-decoration: underline; }

a.with-arrow:hover:after { left: 4px; }

a.full-link { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }

a.link-blue { color: #1D65FF; text-decoration: underline; }

a.link-blue .fa { position: relative; left: 10px; top: 4px; font-size: 24px; transition: all .2s; }

a.link-blue:hover { color: #004ae9; }

a.link-blue:hover .fa { left: 5px; }

.owl-dots { text-align: center; margin: 16px 0; }

.owl-dot { width: 8px; height: 8px; background: #eee; border-radius: 50%; display: inline-block; margin: 0 6px; cursor: pointer; }

.owl-dot.active { background: #009BDE; }

.not-found { padding: 160px 0; text-align: center; color: #ccc; }

.not-found.maintenance { color: #777; }

.not-found.maintenance img { margin-bottom: 30px; }

.not-found.maintenance h1 { font-size: 32px; }

.not-found h1 { font-size: 240px; line-height: 1; }

.not-found p { font-size: 20px; line-height: 24px; }

.collapse:not(.show) { display: none; }

.collapsing { position: relative; height: 0; overflow: hidden; transition: height .35s ease; }

.flex-row-reverse { flex-direction: row-reverse !important; }

/** .tooltip padding-bottom: 11px .arrow position: absolute bottom: 0 border: solid border-width: 11px 10px 0 border-color: #212121 transparent */
.lazyloaded:not(.bg) { background-color: transparent; }

.pagination-row { display: block; padding: 20px 0 40px; }

.pagination { display: flex; justify-content: center; }

.page-item { display: block; min-width: auto; height: 24px; line-height: 24px; text-align: center; margin: 0 5px; padding: 0 5px; border-radius: 12px; color: #545465; }

.page-item.active { font-family: "Inter", sans-serif; font-weight: 500; }

.page-item.active .page-link { color: #4B61DD; }

.page-item.prev { margin-right: 16px; }

.page-item.next { margin-left: 16px; }

.page-link { font-size: 16px; line-height: 24px; color: #232933; }

.page-link .svg { display: block; height: 24px; width: auto; }

.page-link:hover { color: #4B61DD; }

.page-link:hover .svg path { stroke: #4B61DD; }

.owl-carousel { position: relative; }

.owl-nav { position: absolute; top: 50%; left: 0; right: 0; height: 0; }

.owl-nav button:focus { outline: none !important; }

.owl-nav .owl-prev, .owl-nav .owl-next { width: 30px; height: 30px; position: absolute; border-radius: 15px; overflow: hidden; top: -15px; background-size: contain; opacity: 1; transition: all .3s ease-in-out; cursor: pointer; }

.owl-nav .owl-prev:hover, .owl-nav .owl-next:hover { opacity: .5; }

.owl-nav .owl-prev { left: 0; }

.owl-nav .owl-next { right: 0; }

.owl-nav .prev, .owl-nav .next { display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5) url(../images/icon-chevron-right-white.svg) no-repeat center; background-size: contain; }

.owl-nav .prev { transform: scaleX(-1); }

body.modal-open { overflow: hidden; }

.modal { position: fixed; z-index: 1073; top: 0; left: 0; width: 101%; height: 100%; overflow: auto; display: none; transition: all .2s; }

.modal:not(.show) { opacity: 0; }

.modal:not(.show) .modal-dialog { transform: translate(0, -50px); }

.modal .close { position: absolute; top: 18px; right: 18px; cursor: pointer; opacity: 1; transition: all .3s ease-in-out; }

.modal .close img { display: block; width: 26px; height: auto; }

.modal .close:hover { opacity: .6; }

.modal .modal-dialog { transition: all .3s ease-in-out; max-width: 770px; pointer-events: none; }

.modal .modal-content { padding: 34px 0; background: #fff; margin: 30px auto; position: relative; border-radius: 6px; pointer-events: auto; box-shadow: 0 4px 8px 0 rgba(119, 119, 119, 0.1), 0 12px 20px 0 rgba(119, 119, 119, 0.2); }

.modal.proto .modal-dialog { padding: 48px 58px; max-width: 520px; }

.modal-backdrop { position: fixed; top: 0; left: 0; z-index: 1040; width: 100vw; height: 100vh; background-color: #000; opacity: 0; }

.modal-backdrop.show { opacity: 0.8 !important; }

.modal h2 { margin: 0 0 16px; }

.modal-download.proto article { max-width: 312px; margin: 0 0 24px; }

.modal-download.proto form { max-width: 392px; }

.modal-download iframe { height: 85vh; }

.modal-close { position: absolute; top: 8px; right: 8px; width: 20px; height: 20px; line-height: 1; }

.modal-close:hover { transform: rotate(90deg); }

.modal.modal-download .modal-content { box-shadow: none !important; }

.modal.modal--md:not(.show) { opacity: 0; }

.modal.modal--md:not(.show) .modal-dialog { transform: translate(0, 100%); }

.modal.modal--md .close { top: 32px; right: 40px; width: 70px; display: flex; }

.modal.modal--md .close .svg path { fill: #4B61DD; }

.modal.modal--md .close span { position: relative; top: 1px; display: inline-block; margin-left: 4px; vertical-align: middle; color: #4B61DD; }

.modal.modal--md ul.list-check h4 { margin-bottom: 4px; font-size: 20px; line-height: 28px; }

.modal.modal--md ul.list-check li { padding-bottom: 24px; }

.modal.modal--md ul.list-check li::before { top: 3px; }

.modal.modal--md .modal__icon { width: 40px; margin: 0 auto 8px; }

.modal.modal--md .modal__icon img { display: block; width: 100%; height: auto; }

.modal.modal--md .modal__img { position: relative; }

.modal.modal--md .modal__img img { display: block; width: 100%; height: auto; }

.modal.modal--md .modal__img small { display: block; position: absolute; padding-top: 16px; top: 100%; left: 0; font-size: 12px; line-height: 16px; }

.modal.modal--md .modal__vid { position: relative; }

.modal.modal--md .modal__vid img { display: block; width: 100%; height: auto; }

.modal.modal--md .modal__vid small { display: block; position: absolute; padding-top: 16px; top: 100%; left: 0; font-size: 12px; line-height: 16px; }

.modal.modal--md .modal__vid .btn-play { z-index: 2; display: block; width: 68px; height: 68px; border-radius: 34rem; background-color: #4B61DD; background-image: url(../images/ic-play-white.svg); background-repeat: no-repeat; background-position: center; position: absolute; bottom: calc(50% - 34px); right: calc(50% - 34px); }

.modal.modal--md .modal__vid .btn-play:hover { box-shadow: 0 0 0 rgba(0, 95, 191, 0.4); animation: primary 2s infinite; }

@keyframes primary { 0% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0.4); }
  70% { box-shadow: 0 0 0 14px rgba(0, 95, 191, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0); } }

.modal.modal--md .modal__title { margin-bottom: 12px; }

.modal.modal--md .modal__desc { margin-bottom: 40px; }

.modal.modal--md .modal__desc p b { color: #232933; }

.modal.modal--md .modal-footer { position: absolute; background: #FFF; padding: 32px 6px; width: 100%; bottom: 0; left: 0; }

.modal.modal--md .modal-body { height: 100%; overflow: auto; padding-left: 32px; padding-right: 32px; padding-bottom: 80px; }

.modal.modal--md .modal-dialog { border-radius: 0; display: flex; align-items: center; justify-content: center; width: 100%; height: 100vh; max-width: 100%; padding: 0; margin: 0; background: transparent; box-shadow: none; }

.modal.modal--md .modal-content { position: relative; border-radius: 0; margin: 0; height: 90vh; }

@media (max-width: 768px) { .modal.modal--md .modal__img { margin-bottom: 60px; }
  .modal.modal--md .modal-body { padding-left: 16px; padding-right: 16p; }
  .modal.modal--md .modal-dialog { justify-items: flex-end; }
  .modal.modal--md .close { top: 6px; right: 20px; } }

.modal.modal-subscribe.show { display: flex !important; width: 100%; height: 100%; align-items: center; justify-content: center; }

.modal.modal-subscribe .modal-dialog { width: 100%; position: relative; }

.modal.modal-subscribe .modal-dialog .modal-content { padding: 60px; }

.modal.modal-subscribe .modal-dialog .modal-content .modal-body img { margin-bottom: 30px; }

.modal.modal--full:not(.show) { opacity: 0; }

.modal.modal--full:not(.show) .modal-dialog { transform: translate(0, 100%); }

.modal.modal--full .close { top: 32px; right: 40px; }

.modal.modal--full .close .svg path { fill: #4B61DD; }

.modal.modal--full .close span { position: relative; top: 1px; display: inline-block; margin-left: 4px; vertical-align: middle; color: #4B61DD; }

.modal.modal--full .close img { display: inline-block; vertical-align: middle; }

.modal.modal--full ul.list-check h4 { margin-bottom: 4px; font-size: 20px; line-height: 28px; }

.modal.modal--full ul.list-check li { padding-bottom: 24px; }

.modal.modal--full ul.list-check li::before { top: 3px; }

.modal.modal--full .modal__icon { width: 40px; margin: 0 auto 8px; }

.modal.modal--full .modal__icon img { display: block; width: 100%; height: auto; }

.modal.modal--full .modal__img { position: relative; padding: 32px 0; }

.modal.modal--full .modal__img img { display: block; width: 100%; height: auto; }

.modal.modal--full .modal__img small { display: block; position: absolute; padding-top: 16px; top: 100%; left: 0; font-size: 12px; line-height: 16px; }

.modal.modal--full .modal__vid { position: relative; padding: 32px 0; }

.modal.modal--full .modal__vid img { display: block; width: 100%; height: auto; }

.modal.modal--full .modal__vid small { display: block; position: absolute; padding-top: 16px; bottom: 0; left: 0; font-size: 12px; line-height: 16px; }

.modal.modal--full .modal__vid .btn-play { z-index: 2; display: block; width: 68px; height: 68px; border-radius: 34rem; background-color: #4B61DD; background-image: url(../images/ic-play-white.svg); background-repeat: no-repeat; background-position: center; position: absolute; bottom: calc(50% - 34px); right: calc(50% - 34px); }

.modal.modal--full .modal__vid .btn-play:hover { box-shadow: 0 0 0 rgba(0, 95, 191, 0.4); animation: primary 2s infinite; }

@keyframes primary { 0% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0.4); }
  70% { box-shadow: 0 0 0 14px rgba(0, 95, 191, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0); } }

.modal.modal--full .modal__title { margin-bottom: 12px; }

.modal.modal--full .modal__desc { margin-bottom: 40px; }

.modal.modal--full .modal__desc p b { color: #2B2A35; }

.modal.modal--full .modal-footer { position: absolute; background: #FFF; padding: 32px 6px; width: 100%; bottom: 0; left: 0; }

.modal.modal--full .modal-body { height: 100%; overflow: auto; padding-left: 32px; padding-right: 32px; padding-bottom: 80px; }

.modal.modal--full .modal-dialog { border-radius: 0; display: flex; align-items: flex-end; width: 100%; height: 100vh; max-width: 100%; padding: 0; margin: 0; background: transparent; box-shadow: none; }

.modal.modal--full .modal-content { position: relative; width: 100%; border-radius: 0; margin: 0; height: 90vh; padding: 64px 16px 20px 6px; }

@media (max-width: 768px) { .modal.modal--full .modal__img, .modal.modal--full .modal__vid { margin-bottom: 60px; }
  .modal.modal--full .modal__vid .btn-play { width: 48px; height: 48px; bottom: calc(50% - 24px); right: calc(50% - 24px); box-shadow: 0 0 0 rgba(0, 95, 191, 0.4); animation: primary 2s infinite; }
  @keyframes primary { 0% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0.4); }
    70% { box-shadow: 0 0 0 14px rgba(0, 95, 191, 0); }
    100% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0); } }
  .modal.modal--full .modal-body { padding-left: 16px; padding-right: 16p; }
  .modal.modal--full .modal-content { padding-left: 0; padding-right: 0; }
  .modal.modal--full .close { top: 16px; right: 16px; }
  .modal.modal--full .close span { display: none; } }

.modal.modal--referral.show { display: flex !important; justify-content: center; }

.modal.modal--referral.show .modal-content { padding: 0 !important; }

.fade { transition: opacity .15s linear; }

.load-more { display: none; }

.link-more { color: #4B61DD; position: relative; font-size: 16px; margin-right: 26px; }

.link-more:after { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-arrow-right.svg) no-repeat center; background-size: contain; position: absolute; right: -27px; top: calc(50% - 10px); }

.link-more:hover { color: #4B61DD; text-decoration: underline; }

.link-more:hover:after { animation-duration: 2s; animation-iteration-count: infinite; animation-name: bounce2; animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

@media (max-width: 768px) { .link-more { padding: 8px 0; display: inline-block; } }

.link-more-white { color: #FFF; position: relative; font-size: 16px; }

.link-more-white:after { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-arrow-right-w.svg) no-repeat center; background-size: contain; position: absolute; right: -27px; top: calc(50% - 10px); }

.link-more-white:hover { color: #FFF; text-decoration: underline; }

.link-more-white:hover:after { animation-duration: 2s; animation-iteration-count: infinite; animation-name: bounce2; animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

@media (max-width: 768px) { .link-more-white { padding: 8px 0; display: inline-block; } }

.link-external { color: #4B61DD; position: relative; display: inline-flex; align-items: center; }

.link-external:after { content: ""; display: inline-block; vertical-align: middle; width: 20px; height: 20px; background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10.8346 9.16675L17.668 2.33341M18.3346 5.66675V1.66675L14.3346 1.66675M9.16797 1.66675L7.5013 1.66675C3.33464 1.66675 1.66797 3.33341 1.66797 7.50008L1.66797 12.5001C1.66797 16.6667 3.33464 18.3334 7.5013 18.3334H12.5013C16.668 18.3334 18.3346 16.6667 18.3346 12.5001V10.8334" stroke="%234B61DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') center/contain no-repeat; margin-left: 8px; }

.link-external:hover { text-decoration: underline; }

.header--landing { position: absolute; top: 0; left: 0; width: 100%; background: transparent; padding: 30px 0; }

.section-head { padding-bottom: 48px; }

.section-head.action { padding-top: 40px; }

.section-head.action .section-head__action a { margin-right: 16px; }

.section-head.action .section-head__action a:last-child { margin-right: 0; }

@media (max-width: 991px) { .section-head.action .section-head__action a { margin-right: 0; margin-bottom: 12px; width: 100%; }
  .section-head.action .section-head__action a:last-child { margin-bottom: 0; } }

.section-head:not(.align-left) { text-align: center; }

.section-head .section-head__title { font-size: 32px; line-height: 48px; margin-bottom: 16px; }

.section-head .section-head__title:last-child { margin-bottom: 0; }

.section-head .section-head__content { margin-bottom: 16px; }

.section-head .section-head__content:last-child { margin-bottom: 0; }

.section-head .section-head__action { position: relative; }

@media (max-width: 991px) { .section-head .section-head__title { font-size: 24px; line-height: 32px; } }

ul.custom-list-1 { display: block; width: 100%; }

ul.custom-list-1 > li { position: relative; display: block; padding-left: 36px; margin-bottom: 16px; }

ul.custom-list-1 > li:before { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-check.svg) no-repeat center; background-size: contain; position: absolute; left: 0; top: 2px; }

ul.custom-list { display: block; width: 100%; }

ul.custom-list.two-col { display: flex; flex-wrap: wrap; }

ul.custom-list.two-col > li { order: 2; width: 50%; }

@media (max-width: 479px) { ul.custom-list.two-col > li { width: 100%; } }

ul.custom-list > li { position: relative; display: block; padding-left: 32px; margin-bottom: 16px; }

ul.custom-list > li h4, ul.custom-list > li .title { display: block; font-size: 1rem; line-height: 1.5rem; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 4px; }

ul.custom-list > li:before { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-check.svg) no-repeat center; background-size: contain; position: absolute; left: 1px; top: 3px; }

ul.custom-list.dark > li { margin-bottom: 28px; }

ul.custom-list.dark > li h4 { font-size: 1rem; line-height: 1.5rem; margin-bottom: 4px; }

ul.custom-list.dark > li::before { background: url(../images/ic-check-dark.svg) no-repeat center; background-size: contain; }

ul.custom-list.white > li { margin-bottom: 28px; }

ul.custom-list.white > li h4 { font-size: 1rem; line-height: 1.5rem; margin-bottom: 4px; }

ul.custom-list.white > li::before { background: url(../images/ic-check-white.svg) no-repeat center; background-size: contain; }

ul.custom-list.alt > li::before { background: url(../images/ic-check-alt.svg) no-repeat center; background-size: contain; }

ul.custom-list.blue > li::before { background: url(../images/ic-check-blue.svg) no-repeat center; background-size: contain; }

ul.custom-list.red > li::before { background: url(../images/ic-check-red.svg) no-repeat center; background-size: contain; }

ul.custom-list.red-alt > li::before { background: url(../images/ic-check2.svg) no-repeat center; background-size: contain; }

ul.custom-list.green-alt > li::before { background: url(../images/ic-check1.svg) no-repeat center; background-size: contain; }

ul.custom-list.qmark > li::before { top: 4px; background: url(../images/ic-qmark.svg) no-repeat center; background-size: contain; }

ul.custom-list.cross > li::before { top: 4px; background: url(../images/ic-cross.svg) no-repeat center; background-size: contain; }

ul.custom-list.plus > li::before { top: 4px; background: url(../images/ic-plus-blue.svg) no-repeat center; background-size: contain; }

img[data-src] { opacity: 1; transition: opacity .25s ease-in-out; will-change: opacity; }

img[data-src][src] { opacity: 1; }

.badge { display: inline-block; vertical-align: middle; margin: 0 8px; font-size: 14px; line-height: 20px; padding: 2px 8px; border-radius: 4px; background: #E2E2E2; font-family: "Inter", sans-serif; font-weight: 500; }

.badge--new { background: #FFB700; color: #2B2A35; padding: 3px 9px 1px; }

.badge--new:before { content: ""; display: inline-block; vertical-align: middle; width: 12px; height: 12px; background-image: url(../images/ic-star.png); background-repeat: no-repeat; background-size: contain; background-position: center; margin: 0 6px 2px 0; }

.badge--blue { background: #009BDE; color: #FFF; }

.badge--yellow { background: #F4B94B; color: #FFF; }

.badge--green { background: #14B8A6; color: #FFF; }

.pill { display: inline-flex; background: #E2E2E2; color: #FFF; font-family: "Inter", sans-serif; font-weight: 400; font-size: 14px; line-height: 20px; color: #FFF; padding: 2px 6px; border-radius: 12px; }

.pill.blue { background: #009BDE; }

.pill.red { background: #EF4444; }

.slider-xs .owl-dot { background: #eee !important; }

.slider-xs .owl-dot.active { background: #009BDD !important; }

.hidden-sm { display: flex !important; }

@media (max-width: 991px) { .hidden-sm { display: none !important; } }

.hidden-sm-b { display: block !important; }

@media (max-width: 991px) { .hidden-sm-b { display: none !important; } }

.hidden-sm-ib { display: inline-block !important; }

@media (max-width: 991px) { .hidden-sm-ib { display: none !important; } }

.show-sm { display: none !important; }

@media (max-width: 991px) { .show-sm { display: block !important; } }

.show-sm-ib { display: none !important; }

@media (max-width: 991px) { .show-sm-ib { display: inline-block !important; } }

.show-sm-f { display: none !important; }

@media (max-width: 991px) { .show-sm-f { display: flex !important; } }

.fade { opacity: 0; transition: opacity 0.15s linear; }

.fade.show { opacity: 1; }

.collapse { display: none; }

.collapse.show { display: block; }

.tab-content > .tab-pane { display: none; }

.tab-content > .active { display: block; }

.pt-30 { padding-top: 30; }

.ic-wa { z-index: 99999; display: flex; align-items: center; position: fixed; bottom: 13px; right: 23px; pointer-events: none; }

.ic-wa.show { pointer-events: auto; }

.ic-wa img { z-index: 2; position: relative; display: block; height: 73px; width: 73px; min-width: 73px; }

.ic-wa span { z-index: 1; position: relative; width: 100%; height: 30px; background-color: #25d366; font-size: 12px; line-height: 14px; margin-left: -10px; border-radius: 0 20px 20px 0; color: #FFF; transition: all .3s ease; opacity: 0; font-size: 0; padding: 8px 0; max-width: 0; }

.ic-wa:hover { color: #FFF; text-decoration: none !important; }

.ic-wa:hover span { font-size: 12px; padding: 8px 12px; opacity: 1; max-width: 110px; text-decoration: none !important; }

@media (max-width: 768px) { .ic-wa--alt { right: 23px; bottom: 13px; }
  .ic-wa:hover span { display: none; opacity: 0; } }

.bg-white { background: #FFF !important; }

.bg-snow { background: #F2F4F7; }

@media (max-width: 768px) { .bg-snow { background: #F2F4F7; } }

.bg-dark { background: #10253E; color: #FFF; }

.bg-half-snow { background-color: #F2F4F7; background-image: linear-gradient(#F2F4F7 50%, #FFF 0%); }

.mt-40 { margin-top: 40px; }

ol.list-number li { list-style-type: decimal; margin-bottom: 20px; }

.cta-waba { pointer-events: none; }

.cta-waba.ic-wa { display: none; }

.cta-waba.show { pointer-events: auto; }

.cta-waba.show.ic-wa { display: inline-flex; }

.row-reverse { flex-direction: row-reverse; }

.bg-gray { background: #F2F4F7; }

.link-show { color: #1357FF; position: relative; }

.link-show span { position: relative; }

.link-show span:after { content: ""; display: block; width: 24px; height: 24px; background: url(../images/ic-chev-down.svg) no-repeat center; background-size: contain; position: absolute; right: -32px; top: calc(50% - 12px); }

.link-show:hover { color: #1357FF; text-decoration: underline; }

.link-close { color: #1357FF; position: relative; }

.link-close span { position: relative; }

.link-close span:after { content: ""; display: block; width: 24px; height: 24px; background: url(../images/ic-chev-up.svg) no-repeat center; background-size: contain; position: absolute; right: -32px; top: calc(50% - 12px); }

.link-close:hover { color: #1357FF; text-decoration: underline; }

#masking { position: fixed; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; border: none; margin: 0; padding: 0; overflow: hidden; z-index: 999999; }

.tooltip { position: absolute; z-index: 2; display: block; margin: 0; font-style: normal; font-weight: 400; line-height: 1.5; text-align: left; text-align: start; text-decoration: none; text-shadow: none; text-transform: none; letter-spacing: normal; word-break: normal; word-spacing: normal; white-space: normal; line-break: auto; font-size: 0.875rem; word-wrap: break-word; opacity: 0; }

.tooltip.show { opacity: 0.9; }

.tooltip .arrow { position: absolute; display: block; width: 0.8rem; height: 0.4rem; }

.tooltip .arrow::before { position: absolute; content: ""; border-color: transparent; border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] { padding: 0.4rem 0; }

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow { bottom: 0; }

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before { top: 0; border-width: 0.4rem 0.4rem 0; border-top-color: #0055A2; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] { padding: 0 0.4rem; }

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow { left: 0; width: 0.4rem; height: 0.8rem; }

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before { right: 0; border-width: 0.4rem 0.4rem 0.4rem 0; border-right-color: #0055A2; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] { padding: 0.4rem 0; }

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow { top: 0; }

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before { bottom: 0; border-width: 0 0.4rem 0.4rem; border-bottom-color: #0055A2; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] { padding: 0 0.4rem; }

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow { right: 0; width: 0.4rem; height: 0.8rem; }

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before { left: 0; border-width: 0.4rem 0 0.4rem 0.4rem; border-left-color: #0055A2; }

.tooltip-inner { max-width: 200px; padding: 0.25rem 0.5rem; color: #fff; text-align: center; background-color: #0055A2; border-radius: 0.25rem; }

input[type="email"], #email { text-transform: lowercase; }

.modal-inquiry .close { z-index: 2; }

.modal-inquiry .modal-dialog { margin: 0 auto; max-width: 1224px; width: 100%; }

.modal-inquiry .modal-content { padding: 0; overflow: hidden; }

.inq-sidebar { padding: 64px 40px; }

.inq-sidebar__title { font-size: 24px; line-height: 32px; margin-bottom: 40px; text-align: center; font-family: "Inter", sans-serif; font-weight: 600; }

.inq-sidebar__testimonial { background: #FFF; padding: 40px; border-radius: 6px; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); }

.inq-sidebar__quote { margin: 0 0 16px 0; font-size: 18px; line-height: 28px; font-weight: 600; }

.inq-sidebar__icon { display: inline-block; padding-right: 24px; margin-right: 24px; border-right: 1px solid #E2E2E2; vertical-align: middle; }

.inq-sidebar__meta { display: inline-block; vertical-align: middle; }

.inq-sidebar__clients { margin: 40px 0; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; }

.inq-sidebar__clients li { flex: 0 0 16.666667%; max-width: 16.666667%; display: flex; width: 114px; height: 100px; margin: 10px; align-items: center; justify-content: center; }

@media (max-width: 991px) { .inq-sidebar { display: none; } }

.inq-form { padding: 64px 40px; }

.inq-form__title { text-align: center; font-size: 32px; line-height: 40px; margin-bottom: 40px; font-family: "Inter", sans-serif; font-weight: 600; }

.inq-form .form-meeting__box { padding: 0; }

@media (max-width: 991px) { .inq-form { padding: 30px 16px; }
  .inq-form__title { font-size: 21px; line-height: 28px; margin-bottom: 20px; } }

.pb-0 { padding-bottom: 0; }

.alert { position: relative; padding: 12px 16px; margin-bottom: 16px; border: 1px solid transparent; border-radius: 4px; }

.alert-danger { color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; }

.alert.hide { display: none; }

.btn-play--white { position: absolute; top: calc(50% - 50px); right: calc(50% - 50px); }

.btn-play--white .bg { z-index: 2; display: block; width: 100px; height: 100px; border-radius: 50px; background-image: url(../images/ic-play-blue.svg); background-size: 22px; background-color: #FFF; background-repeat: no-repeat; background-position: 53% 33%; position: relative; box-shadow: 0 0 0 white; animation: pulseBig 2s infinite; }

.btn-play--white span { z-index: 4; display: block; width: 100%; font-size: 10px; line-height: 16px; color: #2B2A35; position: absolute; bottom: 25%; left: 0; text-align: center; }

.btn-play--blue { position: absolute; top: 0; right: 0; width: 100%; height: 100%; }

.btn-play--blue .bg { z-index: 2; display: block; width: 100px; height: 100px; border-radius: 50px; background-image: url(../images/ic-play-white.svg); background-size: 22px; background-color: #4B61DD; background-repeat: no-repeat; background-position: 53% 33%; position: absolute; top: calc(50% - 50px); left: calc(50% - 50px); box-shadow: 0 0 0 #4b61dd; animation: pulseBlue 2s infinite; }

.btn-play--blue span { z-index: 4; display: block; width: 100%; font-size: 10px; line-height: 16px; color: #FFF; position: absolute; bottom: 25%; left: 0; text-align: center; }

#scroll-top { display: inline-block; background-color: #4B61DD; width: 50px; height: 50px; text-align: center; border-radius: 50px; position: fixed; bottom: 98px; right: 26px; transition: background-color .1s, opacity .2s, visibility .2s; opacity: 0; visibility: hidden; z-index: 7; text-align: center; }

#scroll-top::after { content: ''; width: 40px; height: 40px; display: inline-block; vertical-align: middle; background: url("../images/ic-chev-up-white.svg") no-repeat center; background-size: contain; margin: 10% auto; }

#scroll-top:hover { cursor: pointer; background-color: #2740ce; }

#scroll-top.alt { bottom: 30px; }

#scroll-top:active { background-color: #2740ce; }

#scroll-top.show { opacity: 1; visibility: visible; }

@media (max-width: 991px) { #scroll-top { bottom: 90px; } }

.padding-sm { padding: 32px 0 !important; }

.media.media--video { position: relative; }

.nav-tabs.product-featured__tabs { display: flex; position: relative; justify-content: center; align-items: flex-end; max-width: 100%; overflow-y: auto; }

.nav-tabs.product-featured__tabs::before { content: ""; z-index: 1; display: block; width: 100%; height: 1px; position: absolute; bottom: 0; left: 0; background: #E2E2E2; }

.nav-tabs.product-featured__tabs .nav-link { display: block; color: #B4B4B4; text-decoration: none; background: #F9FAFB; padding: 8px 24px; border: 1px solid #E2E2E2; border-radius: 0; }

.nav-tabs.product-featured__tabs .nav-link .icon, .nav-tabs.product-featured__tabs .nav-link span { position: relative; display: inline-block; vertical-align: middle; pointer-events: none; }

.nav-tabs.product-featured__tabs .nav-link .icon { top: -1px; margin-right: 0.3rem; pointer-events: none; }

.nav-tabs.product-featured__tabs .nav-link .icon img { display: block; height: 1.25rem; width: auto; opacity: 1; transition: all .4s ease; pointer-events: none; }

.nav-tabs.product-featured__tabs .nav-link .icon img:nth-child(1) { position: absolute; top: 0; left: 0; opacity: 0; }

.nav-tabs.product-featured__tabs .nav-link .logo img { display: block; height: 40px; width: auto; opacity: 0.5; filter: grayscale(100%); transition: all .4s ease; pointer-events: none; }

.nav-tabs.product-featured__tabs .nav-link:hover { color: #232933; text-decoration: none; background: #FFFFFF; }

.nav-tabs.product-featured__tabs .nav-link:hover .icon img:nth-child(1) { opacity: 1; }

.nav-tabs.product-featured__tabs .nav-link:hover .logo img { opacity: 1; filter: grayscale(0); }

.nav-tabs.product-featured__tabs .nav-link.active { font-family: "Inter", sans-serif; font-weight: 500; background: #FFFFFF; color: #232933; padding-top: 12px; border-radius: 0.25rem 0.25rem 0 0; border-bottom-color: #FFFFFF; pointer-events: none; }

.nav-tabs.product-featured__tabs .nav-link.active .icon img:nth-child(1) { opacity: 1; }

.nav-tabs.product-featured__tabs .nav-link.active .logo img { opacity: 1; filter: grayscale(0); }

.nav-tabs.product-featured__tabs .nav-item { z-index: 2; display: block; position: relative; margin-left: -1px; }

@media (max-width: 991px) { .nav-container { z-index: 20; padding-top: 100px; position: relative; border: 0; background-color: #FFF; }
  .nav-tabs { display: block; width: 100%; position: absolute; top: 24px; left: 0; overflow: visible; padding: 0 16px; padding-top: 56px; }
  .nav-tabs:before { display: none; }
  .nav-tabs .nav-item.active { position: absolute; top: 0; left: 16px; width: calc(100% - 32px); }
  .nav-tabs .nav-item.active:after { content: ""; display: block; z-index: 2; position: absolute; width: 20px; height: 20px; top: calc(50% - 10px); right: 1rem; background-image: url(../images/ic-chevron-down.svg); background-position: center; background-size: contain; background-repeat: no-repeat; }
  .nav-tabs .nav-link { z-index: 1; position: relative; width: 100%; height: 52px; top: 1px; padding: 0; border: 0; opacity: 0; visibility: hidden; margin: 0 0 -1px; padding: 0 1rem; border-radius: 0; transition: all .4s ease; }
  .nav-tabs .nav-link .logo { position: relative; top: -2px; }
  .nav-tabs .nav-link .logo img { height: 32px; width: auto; }
  .nav-tabs .nav-link.active { z-index: 2; position: relative; padding: 12px 1rem; border: 1px solid #E2E2E2; max-height: 52px; opacity: 1; visibility: visible; pointer-events: initial; border-radius: 0.25rem; }
  .nav-tabs .nav-link.active .logo img { height: 32px; width: auto; }
  .nav-tabs.show .nav-link { opacity: 1; visibility: visible; max-height: 52px; padding: 12px 1rem; border: 1px solid #E2E2E2; } }

.breadcrumb { padding: 16px 0; border-top: 1px solid #D0D6DE; }

.breadcrumb__wrap { font-size: 12px; line-height: 16px; }

.breadcrumb__wrap a { color: #4B61DD; text-decoration: underline; }

.breadcrumb__wrap a:hover { color: #1f33a3; }

.breadcrumb__wrap a span.home { display: inline-block; vertical-align: middle; width: 20px; height: 20px; font-size: 0; line-height: 0; background: url(../images/logomark-mekari-jurnal.svg) no-repeat center; background-size: contain; }

.pricing-clients { background: #F2F4F7; padding-top: 80px; padding-bottom: 58px; }

.pricing-clients__title, .pricing-clients h2 { font-family: "Inter", sans-serif; font-weight: 500; text-align: center; font-size: 32px; line-height: 48px; color: #2B2A35; margin: 0 0 24px; }

@media (max-width: 768px) { .pricing-clients__title, .pricing-clients h2 { font-size: 1.5rem; line-height: 2rem; } }

.pricing-clients.bg-white .clients, .pricing-clients.white .clients { background: #FFF; }

.pricing-clients .clients { display: flex; flex-wrap: wrap; margin-left: -16px; margin-right: -16px; margin-bottom: -32px; justify-content: center; }

.pricing-clients .clients .item { display: flex; width: 100%; max-width: 16.666667%; flex: 0 0 16.666667%; align-items: center; justify-content: center; padding: 0 16px; margin-bottom: 48px; }

.pricing-clients .clients .item.show { display: flex !important; }

.pricing-clients .clients .item img { display: block; max-width: 100%; max-height: 80px; width: auto; height: auto !important; }

.pricing-clients .clients--alt { justify-content: center; }

.pricing-clients .clients--alt .item { max-width: calc(16.666667% * 2); flex: 0 0 calc(16.666667% * 2); padding: 0; display: flex; justify-content: space-around; text-align: center; margin-bottom: 0; }

.pricing-clients .clients--alt .item img { padding: 0 21px; display: inline-block; margin-bottom: 48px; }

.pricing-clients .clients-more { text-align: center; margin-bottom: 24px; }

@media (max-width: 991px) { .pricing-clients h2 { font-size: 26px; line-height: 32px; }
  .pricing-clients .clients .item { max-width: 33.333%; flex: 33.333%; padding: 0 12px; }
  .pricing-clients .clients .item img { height: 70px; }
  .pricing-clients .clients--alt { margin-left: 0; padding-bottom: 70px; }
  .pricing-clients .clients--alt .item { max-width: 100%; flex: 100%; padding: 0; display: block; }
  .pricing-clients .clients--alt .item img { height: auto; max-width: 100%; padding: 0 10px; margin-bottom: 16px; }
  .pricing-clients.promo { padding-top: 65px; padding-left: 8px; padding-right: 8px; }
  .pricing-clients .slider-clients.owl-carousel .owl-dots { display: block; width: 100%; text-align: center; }
  .pricing-clients .slider-clients.owl-carousel .owl-dots .owl-dot { display: inline-block; margin: 0 6px; width: 30px; height: 5px; border-radius: 5px; background: #e3e3e8; }
  .pricing-clients .slider-clients.owl-carousel .owl-dots .owl-dot.active { background: #005FBF; } }

.section-center { text-align: center; }

.section__subtitle, .section__meta { font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 24px; }

@media (max-width: 991px) { .section__subtitle br, .section__meta br { display: none; } }

.section__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 32px; line-height: 48px; margin-bottom: 8px; }

@media (max-width: 991px) { .section__title { font-size: 28px; line-height: 36px; }
  .section__title br { display: none; } }

.section__desc { margin-bottom: 24px; }

.section__desc a { color: #4B61DD; text-decoration: underline; }

.section__desc a:hover { color: #1f33a3; text-decoration: none; }

@media (max-width: 991px) { .section__desc br { display: none; } }

.section__action a { margin-right: 16px; }

.section__action a:last-child { margin-right: 0; }

@media (max-width: 991px) { .section__action { margin-bottom: 24px; }
  .section__action a { width: 100%; display: block; margin-right: 0; margin-bottom: 16px; }
  .section__action a:last-child { margin-bottom: 0; }
  .section__action a.link-more { display: inline; } }

ul.disc { list-style: disc; padding-left: 16px; }

.warning { padding: 12px 16px 12px 52px; background: #EAECFB; border-radius: 6px; font-size: 14px; line-height: 24px; position: relative; }

.warning::before { content: ''; width: 24px; height: 24px; background: url(../images/ic-info-blue.svg) no-repeat center; background-size: contain; position: absolute; left: 16px; top: 10px; }

.warning a { color: #4B61DD; text-decoration: underline; }

.warning a:hover { color: #1f33a3; text-decoration: none; }

.flatpickr-calendar .dropdown.bootstrap-select { position: inherit; }

.flatpickr-calendar .dropdown.bootstrap-select button { border: none; font-size: 16px; line-height: 28px; }

.flatpickr-calendar .dropdown.bootstrap-select button:after { top: 1px; }

.mpay-footer-top { text-align: center; background: #FFF; padding-top: 64px; padding-bottom: 64px; }

.mpay-footer-top__logomark { margin-bottom: 16px; }

.mpay-footer-top__title { margin-bottom: 20px; }

.mpay-footer-top__desc { margin: 0 auto 32px; max-width: 600px; }

.mpay-footer-top__buttons .btn { margin-left: 6px; margin-right: 6px; }

@media (max-width: 991px) { .mpay-footer-top { padding: 32px 8px; }
  .mpay-footer-top__buttons .btn { margin-left: 0; margin-right: 0; } }

.tbs { z-index: 20; display: block; background: linear-gradient(180deg, #40c3ff 50%, #0080d9 100%); color: #FFF; position: fixed; width: 100%; top: 0; left: 0; transition: all .4s ease; opacity: 0; }

.tbs-bot { display: block; background: url(../images/sticky-speakers.svg) no-repeat; background-position: center right; background-size: contain; }

.tbs-bot .row { min-height: 120px; }

.tbs.is-ready { opacity: 1; }

.tbs__wrap { padding: 4px 0; min-height: 36px; margin: 0 auto; text-align: center; align-items: center; display: flex; width: 100%; }

.tbs__img { display: inline-block; text-align: left; }

.tbs__img img { width: 100%; height: auto; margin-top: -5px; }

.tbs__content { display: block; text-align: center; color: #FFF; }

.tbs__content span { display: inline-block; vertical-align: middle; }

.tbs__content span.large { font-size: 18px; line-height: 1.5em; font-family: "Inter", sans-serif; font-weight: 600; }

.tbs__cta { display: block; margin-left: auto; }

.tbs__cta .link { text-decoration: underline; font-size: 13px; line-height: 1.5em; }

.tbs__cta .link:hover { color: #e6e5e5; }

.tbs__cta .btn-dummy { color: #fff; position: relative; }

.tbs__cta .btn-dummy:after { content: ""; display: block; width: 24px; height: 20px; background: url(../images/ic-arrow-right-long-white.svg) no-repeat center; background-size: contain; position: absolute; right: -32px; top: calc(50% - 10px); }

.tbs__cta .btn-dummy:hover { color: #fff; text-decoration: underline; }

.tbs__cta .btn-dummy:hover:after { animation-duration: 2s; animation-iteration-count: infinite; animation-name: bounce2; animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.tbs__close { display: flex; align-items: center; justify-content: center; position: absolute; width: 64px; height: 30px; top: 0; right: 0; }

.tbs__close span { display: flex; height: 16px; width: 16px; border-radius: 8px; align-items: center; justify-content: center; background: #FFF; color: #005FBF; }

.tbs.fixed { top: 57px; opacity: 1; }

.tbs.fixed.show-top { top: 97px; }

@media (max-width: 991px) { .tbs { top: 0; }
  .tbs-bot { display: none; }
  .tbs > .container > .row { min-height: 50px; }
  .tbs__wrap { padding-left: 0; padding-right: 0; }
  .tbs__content span { display: block; }
  .tbs__content span.meta { font-size: 12px; line-height: 16px; display: block; }
  .tbs__content span.large { margin-left: 0; font-size: 14px; line-height: 1.25rem; }
  .tbs__cta { min-width: 200px; }
  .tbs__close { align-items: flex-start; justify-content: flex-end; width: 40px; height: 40px; padding: 12px; }
  .tbs__close span { height: 14px; width: 14px; font-size: 18px; }
  .tbs.fixed { top: 56px; }
  .tbs.fixed.show-top { top: 56px; } }

@media (max-width: 768px) { .tbs__wrap { flex-wrap: wrap; }
  .tbs__content { text-align: center; width: 100%; }
  .tbs__cta { text-align: center; max-width: 100%; width: 100%; } }

.top-banner--alt .tb__close { position: fixed; right: 0; top: 8px; padding: 8px; z-index: 4; cursor: pointer; display: none; }

.top-banner--alt .tb__close span { font-size: 24px; line-height: 24px; color: #000; border-radius: 8px; display: block; width: 16px; height: 16px; text-align: center; }

@media (max-width: 991px) { .top-banner--alt .tb__close { top: 6px; right: 2px; } }

@media (max-width: 479px) { .top-banner--alt .tb__close { top: 10px; right: -2px; } }

.top-banner-sticky { z-index: 879; position: absolute; display: none; text-decoration: none; color: #232933; width: 100%; background: #EDFAFF; opacity: 0; transition: opacity linear .2s; }

.top-banner-sticky:hover { color: #232933; text-decoration: none; }

.top-banner-sticky .tb__logo { display: none; width: 100%; max-width: 13%; -webkit-flex: 0 0 13%; -ms-flex: 0 0 13%; flex: 0 0 13%; }

.top-banner-sticky .tb__logo img { display: block; height: 24px; width: auto; }

.top-banner-sticky .tb__text { display: block; width: 100%; max-width: 70%; -webkit-flex: 0 0 70%; -ms-flex: 0 0 70%; flex: 0 0 70%; text-align: left; }

.top-banner-sticky .tb__text span.sub, .top-banner-sticky .tb__text span.sub-sm { display: none; }

.top-banner-sticky .tb__text span.title { display: block; font-size: 15px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 400; }

.top-banner-sticky .tb__text span.title b { font-family: "Inter", sans-serif; font-weight: 400; }

.top-banner-sticky .tb__text span.title-sm { display: none; font-size: 28px; line-height: 1.5em; font-family: "Inter", sans-serif; font-weight: 600; }

.top-banner-sticky .tb__action { display: block; width: 100%; max-width: 25%; -webkit-flex: 0 0 25%; -ms-flex: 0 0 25%; flex: 0 0 25%; text-align: right; margin-left: auto; }

.top-banner-sticky .tb__action span.link { display: inline-block; text-decoration: underline; color: #232933; position: relative; font-size: 14px; line-height: 20px; font-family: "Inter", sans-serif; font-weight: 600; }

.top-banner-sticky .tb__action span.link:hover { color: #232933; text-decoration: underline; }

.top-banner-sticky .tb__action span.link:after { -webkit-animation-duration: 2s; animation-duration: 2s; -webkit-animation-iteration-count: infinite; animation-iteration-count: infinite; -webkit-animation-name: bounce2; animation-name: bounce2; -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.top-banner-sticky .tb__wrap { display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-align-items: center; -ms-flex-align: center; align-items: center; min-height: 64px; }

.top-banner-sticky .tb__close { position: absolute; right: 12px; top: 0; padding: 8px; display: none; }

.top-banner-sticky .tb__close span { font-size: 16px; line-height: 16px; background: #FFF; color: #000; border-radius: 8px; display: block; width: 16px; height: 16px; text-align: center; }

@media (max-width: 991px) { .top-banner-sticky { opacity: 1; }
  .top-banner-sticky .tb__logo { display: none; }
  .top-banner-sticky .tb__action { display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; max-width: 100%; width: 100%; -webkit-flex: 0 0 100%; -ms-flex: 0 0 100%; flex: 0 0 100%; text-align: center; }
  .top-banner-sticky .tb__action span.link { font-size: 14px; line-height: 20px; }
  .top-banner-sticky .tb__text { max-width: 100%; -webkit-flex: 0 0 100%; -ms-flex: 0 0 100%; flex: 0 0 100%; }
  .top-banner-sticky .tb__text span.sub { display: none; font-size: 10px; line-height: 12px; }
  .top-banner-sticky .tb__text span.sub-sm { display: block; font-size: 12px; line-height: 16px; }
  .top-banner-sticky .tb__text span.title { display: none; }
  .top-banner-sticky .tb__text span.title br { display: none; }
  .top-banner-sticky .tb__text span.title-sm { display: block; font-size: 13px; line-height: 20px; padding-right: 20px; text-align: center; font-family: "Inter", sans-serif; font-weight: 400; }
  .top-banner-sticky .tb__close { right: 0; top: calc( 50% - 15px); }
  .top-banner-sticky .tb__close span { font-size: 16px; line-height: 14px; background: #FFF; color: #000; border-radius: 7px; display: block; width: 14px; height: 14px; text-align: center; }
  .top-banner-sticky .tb__wrap { -webkit-flex-wrap: wrap; -ms-flex-wrap: wrap; flex-wrap: wrap; height: 48px; padding: 4px 0; } }

@media (max-width: 479px) { .top-banner-sticky .tb__action span.link { font-size: 12px; line-height: 20px; } }

.top-banner-bot { display: block; text-decoration: none; color: #232933; width: 100%; background: #EDFAFF; position: relative; margin-top: 0; margin-bottom: 0; }

.top-banner-bot:hover { color: #232933; text-decoration: none; }

.top-banner-bot .tb__logo { display: none; width: 100%; max-width: 16.666667%; -webkit-flex: 0 0 16.666667%; -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; }

.top-banner-bot .tb__logo img { display: block; height: 64px; width: auto; }

.top-banner-bot .tb__text { display: block; width: 100%; max-width: 65%; -webkit-flex: 0 0 65%; -ms-flex: 0 0 65%; flex: 0 0 65%; text-align: left; padding-left: 0; padding-right: 0; margin-left: 64px; }

.top-banner-bot .tb__text span.sub { display: block; font-size: 18px; line-height: 1.5em; font-family: "Inter", sans-serif; font-weight: 500; }

.top-banner-bot .tb__text span.sub-sm { display: none; }

.top-banner-bot .tb__text span.title { display: block; font-size: 21px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 400; margin: 0 0 12px; }

.top-banner-bot .tb__text span.title b { font-family: "Inter", sans-serif; font-weight: 600; }

.top-banner-bot .tb__action span.link { display: inline-block; text-decoration: underline; color: #232933; position: relative; padding-right: 24px; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

.top-banner-bot .tb__action span.link:hover { color: #232933; text-decoration: underline; }

.top-banner-bot .tb__action span.link:after { -webkit-animation-duration: 2s; animation-duration: 2s; -webkit-animation-iteration-count: infinite; animation-iteration-count: infinite; -webkit-animation-name: bounce2; animation-name: bounce2; -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.top-banner-bot .tb__img { z-index: 1; display: block; position: absolute; width: 548px; height: 100%; top: 0; left: 50%; background-size: auto 100%; background-repeat: no-repeat; background-position: right bottom; }

.top-banner-bot .tb__wrap { z-index: 2; position: relative; padding-top: 16px; padding-bottom: 16px; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-align-items: center; -ms-flex-align: center; align-items: center; min-height: 144px; }

@media (max-width: 1024px) { .top-banner-bot { z-index: 101; } }

@media (max-width: 991px) { .top-banner-bot { background: linear-gradient(90.02deg, #3A00A9 -8.78%, #7B3CEF 26.34%, #3A00A9 64.86%, #7B3CEF 99.98%); margin-bottom: 0; display: none; z-index: 101; }
  .top-banner-bot .tb__logo, .top-banner-bot .tb__img { display: none; }
  .top-banner-bot .tb__text { padding-left: 0; padding-right: 0; width: 100%; max-width: 100%; -webkit-flex: 0 0 100%; -ms-flex: 0 0 100%; flex: 0 0 100%; }
  .top-banner-bot .tb__text span.sub { display: none; font-size: 12px; line-height: 1.5em; }
  .top-banner-bot .tb__text span.sub-sm { display: block; font-size: 16px; line-height: 1.5em; }
  .top-banner-bot .tb__text span.title { font-size: 18px; line-height: 1.5em; margin-bottom: 8px; }
  .top-banner-bot .tb__text span.link { font-size: 14px; line-height: 1.5em; }
  .top-banner-bot .tb__wrap { padding-top: 16px; padding-bottom: 16px; } }

.top-banner--alt .top-banner-sticky { background: #EDFAFF; z-index: 3; }

.top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__logo { width: 180px; height: 64px; flex: 0 0 180px; background-size: contain; max-width: 180px; }

.top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text { max-width: calc(100% - (180px + 265px)); flex: 0 0 calc(100% - (180px + 265px)); }

.top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.meta > img { max-height: 20px; width: auto; }

.top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.title { font-size: 16px; line-height: 24px; }

.top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.title > img { max-width: 112px; }

.top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__action { width: auto; flex: 0 0 auto; }

.top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__action span.link { display: inline-flex; align-items: center; font-size: 16px; line-height: 24px; }

.top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__logo { width: 190px; flex: 0 0 190px; max-width: 100%; height: 64px; }

.top-banner--alt .top-banner-sticky.img-md.mcon .tb__text { max-width: calc(100% - (190px + 265px)); flex: 0 0 calc(100% - (190px + 265px)); }

.top-banner--alt .top-banner-sticky.img-md.mcon .tb__text span.title { font-size: 20px; line-height: 28px; color: #FFF; }

.top-banner--alt .top-banner-sticky.img-md.mcon .tb__text span.meta { color: #FFF; font-size: 16px; line-height: 24px; }

.top-banner--alt .top-banner-sticky.img-md.mcon .tb__action { width: auto; flex: 0 0 auto; }

.top-banner--alt .top-banner-sticky.img-md.mcon .tb__action span.link { display: inline-block; color: #232933; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; padding: 8px 16px; background-color: #ffed51; border-radius: 4px; }

.top-banner--alt .top-banner-sticky.img-md.mcon .tb__action span.link::after { content: ""; display: inline-block; vertical-align: middle; width: 20px; height: 20px; background: url(../images/ic-arrow-right.svg) no-repeat center; background-size: contain; position: relative; top: -2px; margin-left: 8px; filter: grayscale(100%) brightness(0.5); animation-duration: 2s; animation-iteration-count: infinite; animation-name: bounce2; animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.top-banner--alt .top-banner-sticky.img-md.mcon .tb__action span.link:hover { text-decoration: none; }

.top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__logo { height: 100%; }

.top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__text { max-width: calc(100% - (180px + 265px)); flex: 0 0 calc(100% - (180px + 265px)); }

.top-banner--alt .top-banner-sticky .tb__wrap { display: -webkit-flex; display: -ms-flexbox; display: flex; gap: 32px; flex-wrap: wrap; justify-content: space-between; }

.top-banner--alt .top-banner-sticky .tb__wrap .tb__logo { display: block; width: 100%; max-width: 90px; flex: 0 0 100%; background-repeat: no-repeat; background-size: contain; background-position: center; }

.top-banner--alt .top-banner-sticky .tb__wrap .tb__text { padding: 6px 0; text-align: left; width: 100%; flex: 0 0 100%; max-width: calc(100% - (90px + 250px + 64px)); }

.top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.title { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; }

.top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.title br { display: none; }

.top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.meta-sm, .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.action { display: none; }

.top-banner--alt .top-banner-sticky .tb__wrap .tb__action { max-width: 250px; width: 100%; flex: 0 0 100%; display: flex; justify-content: flex-end; }

.top-banner--alt .top-banner-sticky .tb__wrap .tb__action span.link { font-family: "Inter", sans-serif; font-weight: 400; text-decoration: none; color: #4B61DD; position: relative; font-size: 16px; line-height: 24px; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-align-items: center; -ms-flex-align: center; align-items: center; gap: 4px; }

.top-banner--alt .top-banner-sticky .tb__wrap .tb__action span.link:after { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-arrow-right.svg) no-repeat center; background-size: contain; }

.top-banner--alt .top-banner-sticky .tb__wrap .tb__action span.link:hover { color: #4B61DD; text-decoration: underline; }

.top-banner--alt .top-banner-sticky .tb__wrap .tb__action span.link:hover:after { -webkit-animation-duration: 2s; animation-duration: 2s; -webkit-animation-iteration-count: infinite; animation-iteration-count: infinite; -webkit-animation-name: bounce2; animation-name: bounce2; -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.top-banner--alt .top-banner-bot { background: #EDFAFF; }

.top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__text { max-width: calc(100% - (300px + 225px)); }

.top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__text span.title { font-size: 20px; line-height: 28px; }

.top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__img { max-width: 300px; width: 100%; padding: 0; margin-right: 57px; }

.top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__img img { height: 100%; width: auto; max-width: 300px; max-height: 144px; }

.top-banner--alt .top-banner-bot.img-md.mcon::before { content: ""; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; background-image: url(../images/banner-tbmcon-l.webp); background-size: auto 100%; background-position: left top; background-repeat: no-repeat; }

.top-banner--alt .top-banner-bot.img-md.mcon::after { content: ""; display: block; width: 100%; height: 100%; position: absolute; top: 0; right: 0; background-image: url(../images/banner-tbmcon-r.webp); background-size: auto 100%; background-position: right top; background-repeat: no-repeat; }

.top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.title { color: #fff; font-size: 22px; line-height: 150%; }

.top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.meta, .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.meta-sm { color: #FFF; }

.top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.action span.link { display: inline-block; color: #232933; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; padding: 8px 16px; background-color: #ffed51; border-radius: 4px; }

.top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.action span.link::after { content: ""; display: inline-block; vertical-align: middle; width: 20px; height: 20px; background: url(../images/ic-arrow-right.svg) no-repeat center; background-size: contain; position: relative; top: -2px; margin-left: 8px; filter: grayscale(100%) brightness(0.5); animation-duration: 2s; animation-iteration-count: infinite; animation-name: bounce2; animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__action span.link { display: inline-block; color: #232933; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; padding: 8px 16px; background-color: #ffed51; border-radius: 4px; }

.top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__action span.link::after { content: ""; display: inline-block; vertical-align: middle; width: 20px; height: 20px; background: url(../images/ic-arrow-right.svg) no-repeat center; background-size: contain; position: relative; top: -2px; margin-left: 8px; filter: grayscale(100%) brightness(0.5); animation-duration: 2s; animation-iteration-count: infinite; animation-name: bounce2; animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__action span.link:hover { text-decoration: none; }

.top-banner--alt .top-banner-bot.ebook .tb__wrap .tb__img { width: 180px; padding-top: 0; padding-bottom: 0; min-height: 144px; display: flex; align-items: flex-end; }

.top-banner--alt .top-banner-bot.ebook .tb__wrap .tb__text { margin-left: 57px; flex: 0 0 calc(100% - (245px + 225px)); max-width: calc(100% - (245px + 225px)); }

.top-banner--alt .top-banner-bot.ebook .tb__wrap .tb__text span.title { font-size: 20px; line-height: 28px; }

.top-banner--alt .top-banner-bot.ebook .tb__wrap .tb__text span.title br { display: unset; }

.top-banner--alt .top-banner-bot.ebook .tb__wrap .tb__action { min-width: 245px; }

.top-banner--alt .top-banner-bot .tb__wrap { padding-top: 0; padding-bottom: 0; position: relative; }

.top-banner--alt .top-banner-bot .tb__wrap::before { content: ''; display: none; width: 173px; height: 100%; background: url(../images/ornament-bg-banner.png) no-repeat; background-position: left center; background-size: contain; position: absolute; left: -30px; top: 0; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__img { position: relative; width: auto; height: 100%; padding-top: 3.6px; padding-bottom: 3.6px; top: unset; left: unset; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__text { text-align: left; margin: 0 auto; padding: 12px 0; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__text span.title { font-size: 32px; line-height: 48px; margin-bottom: 0; font-family: "Inter", sans-serif; font-weight: 600; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__text span.meta { font-size: 16px; line-height: 24px; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__text span.meta p { font-size: 16px; line-height: 24px; margin-bottom: 0; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__text span.meta small { font-size: 14px; line-height: 20px; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__text span.link { font-family: "Inter", sans-serif; font-weight: 400; text-decoration: none; color: #4B61DD; position: relative; font-size: 16px; line-height: 24px; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-align-items: center; -ms-flex-align: center; align-items: center; gap: 4px; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__text span.link:after { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-arrow-right.svg) no-repeat center; background-size: contain; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__text span.link:hover { color: #4B61DD; text-decoration: underline; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__text span.link:hover:after { -webkit-animation-duration: 2s; animation-duration: 2s; -webkit-animation-iteration-count: infinite; animation-iteration-count: infinite; -webkit-animation-name: bounce2; animation-name: bounce2; -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.top-banner--alt .top-banner-bot .tb__wrap .tb__text span.title-sm, .top-banner--alt .top-banner-bot .tb__wrap .tb__text span.meta-sm, .top-banner--alt .top-banner-bot .tb__wrap .tb__text span.action { display: none; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__action { min-width: 200px; -webkit-flex: 0 0 200px; -ms-flex: 0 0 200px; flex: 0 0 200px; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__action span.link { font-family: "Inter", sans-serif; font-weight: 400; text-decoration: none; color: #4B61DD; position: relative; font-size: 16px; line-height: 24px; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-align-items: center; -ms-flex-align: center; align-items: center; gap: 4px; padding-right: 0; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__action span.link:after { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-arrow-right.svg) no-repeat center; background-size: contain; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__action span.link:hover { color: #4B61DD; text-decoration: underline; }

.top-banner--alt .top-banner-bot .tb__wrap .tb__action span.link:hover:after { -webkit-animation-duration: 2s; animation-duration: 2s; -webkit-animation-iteration-count: infinite; animation-iteration-count: infinite; -webkit-animation-name: bounce2; animation-name: bounce2; -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.top-banner--alt .top-banner-bot .tb__close { position: absolute; display: none; }

@media screen and (max-width: 1200px) { .top-banner--alt .top-banner-sticky.img-md .tb__wrap { justify-content: space-between; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__logo { display: none; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text { max-width: calc(100% - 355px); flex: 0 0 calc(100% - 355px); }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.title { font-size: 16px; line-height: 24px; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.title > img { max-width: 112px; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__action .link { justify-content: flex-end; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__text span.title { font-size: 18px; line-height: 26px; }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__logo { display: none; }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__text span.title { font-size: 18px; line-height: 26px; }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__action { max-width: 30%; flex: 0 0 30%; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap { display: flex; flex-wrap: wrap; justify-content: space-between; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__text { max-width: calc(100% - 225px); margin: 0; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__text span.title { font-size: 20px; line-height: 28px; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__img { display: none; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__action { display: flex; justify-content: flex-end; }
  .top-banner--alt .top-banner-bot.ebook .tb__wrap .tb__text span.title br { display: none; } }

@media screen and (max-width: 1024px) { .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text { max-width: 70%; flex: 0 0 70%; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__action { max-width: auto; flex: 0 0 auto; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__action { max-width: auto; flex: 0 0 auto; }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__text { margin-left: 24px; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__logo { display: none; }
  .top-banner--alt .top-banner-bot { z-index: 101; }
  .top-banner--alt .top-banner-bot.img-md.mcon::before { background-image: url(../images/banner-tbmcon-r-sm.webp); }
  .top-banner--alt .top-banner-bot.img-md.mcon::after { background-image: url(../images/banner-tbmcon-l-sm.webp); }
  .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text { text-align: center; max-width: 100%; flex: 0 0 100%; width: 100%; }
  .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.title { font-size: 32px; line-height: 48px; }
  .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.meta { display: none; }
  .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.meta-sm, .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.action { display: block; }
  .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.action { margin-top: 8px; }
  .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__action { display: none; }
  .top-banner--alt .top-banner-bot .tb__wrap { max-width: 100%; }
  .top-banner--alt .top-banner-bot .tb__text { max-width: 55%; -webkit-flex: 0 0 55%; -ms-flex: 0 0 55%; flex: 0 0 55%; } }

@media screen and (max-width: 991px) { .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text { padding-left: 0; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__action { max-width: auto; flex: 0 0 auto; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__text span.title-sm, .top-banner--alt .top-banner-sticky.img-md.mcon .tb__text span.meta-sm { color: #FFF; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__action { max-width: auto; flex: 0 0 auto; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__action span.link { padding: 0; background: transparent; color: #fff; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__action span.link::after { filter: invert(100%) brightness(1000%); }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__logo { display: none; }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__text { padding-left: 0; margin-left: 0; }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__text span.title-sm { font-size: 16px; line-height: 24px; text-align: left; }
  .top-banner--alt .top-banner-sticky .tb__wrap { max-width: 100%; flex: 0 0 100%; flex-wrap: wrap; gap: 8px; padding: 0 16px; height: auto; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.title, .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.meta { display: none; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.title br, .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.meta br { display: none; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.title-sm { display: -webkit-flex; display: -ms-flexbox; display: flex; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.meta-sm { display: -webkit-flex; display: -ms-flexbox; display: flex; font-size: 14px; line-height: 20px; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__action { display: -webkit-flex; display: -ms-flexbox; display: flex; margin-left: auto; width: auto; -webkit-flex: unset; -ms-flex: unset; flex: unset; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__action span.link { padding-right: 24px; }
  .top-banner--alt .top-banner-sticky .tb__close { display: -webkit-flex; display: -ms-flexbox; display: none; }
  .top-banner--alt .top-banner-bot { display: -webkit-flex; display: -ms-flexbox; display: flex; z-index: 101; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__text { flex: 0 0 100%; padding-left: 24px; padding-right: 24px; max-width: 100%; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__text span.meta { display: none; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__text span.meta-sm { display: block; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__action { display: none; }
  .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.title { font-size: 20px; line-height: 28px; }
  .top-banner--alt .top-banner-bot.ebook .tb__wrap .tb__text { max-width: calc(100% - (180px + 155px)); flex: 0 0 calc(100% - (180px + 155px)); }
  .top-banner--alt .top-banner-bot .tb__wrap { -webkit-flex-wrap: wrap; -ms-flex-wrap: wrap; flex-wrap: wrap; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__img { display: -webkit-flex; display: -ms-flexbox; display: flex; margin-right: 32px; -webkit-flex: 0 0 165px; -ms-flex: 0 0 165px; flex: 0 0 165px; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__img img { object-fit: contain; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__text { -webkit-flex: 0 0 calc(100% - 200px); -ms-flex: 0 0 calc(100% - 200px); flex: 0 0 calc(100% - 200px); max-width: calc(100% - 200px); padding-right: 24px; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__text span.action { display: -webkit-flex; display: -ms-flexbox; display: flex; margin-top: 8px; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__action { display: none; } }

@media screen and (max-width: 906px) { .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__text { flex: 0 0 calc(100% - (180px + 180px)); max-width: calc(100% - (180px + 180px)); }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__text span.title-sm { font-size: 16px; line-height: 24px; }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__action { max-width: 35%; flex: 0 0 35%; } }

@media screen and (max-width: 790px) { .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__text span.title-sm { font-size: 16px; line-height: 24px; }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__action span.link { font-size: 14px; line-height: 20px; } }

@media screen and (max-width: 768px) { .top-banner--alt .top-banner-sticky { top: -76px; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text { max-width: auto; flex: 0 0 auto; width: auto; text-align: left; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.title-sm, .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.meta-sm { text-align: left; justify-content: flex-start; flex: 0 0 100%; width: 100%; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.action { display: none; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__action { max-width: auto; flex: 0 0 auto; display: flex; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__text { max-width: auto; flex: 0 0 auto; width: auto; text-align: left; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__text span.title-sm, .top-banner--alt .top-banner-sticky.img-md.mcon .tb__text span.meta-sm { text-align: left; justify-content: flex-start; flex: 0 0 100%; width: 100%; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__text span.action { display: none; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__action { max-width: auto; flex: 0 0 auto; display: flex; }
  .top-banner--alt .top-banner-sticky.ebook .tb__wrap .tb__text span.title-sm { text-align: center; font-size: 16px; line-height: 24px; }
  .top-banner--alt .top-banner-sticky .tb__wrap { height: 100%; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__logo { display: none; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text { max-width: 100%; -webkit-flex: 0 0 100%; -ms-flex: 0 0 100%; flex: 0 0 100%; text-align: left; padding-left: 0; -webkit-align-items: center; -ms-flex-align: center; align-items: center; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.title { display: none; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.title b { font-family: "Inter", sans-serif; font-weight: 600; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.title-sm { display: -webkit-flex; display: -ms-flexbox; display: flex; font-size: 16px; line-height: 24px; text-align: center; font-family: "Inter", sans-serif; font-weight: 600; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.title-sm b { font-family: "Inter", sans-serif; font-weight: 600; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.meta-sm { text-align: center; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.action { display: -webkit-flex; display: -ms-flexbox; display: flex; margin-top: 5px; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.action .link { font-family: "Inter", sans-serif; font-weight: 400; text-decoration: none; color: #4B61DD; position: relative; font-size: 16px; line-height: 24px; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-align-items: center; -ms-flex-align: center; align-items: center; gap: 4px; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.action .link:after { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-arrow-right.svg) no-repeat center; background-size: contain; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.action .link:hover { color: #4B61DD; text-decoration: underline; }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__text span.action .link:hover:after { -webkit-animation-duration: 2s; animation-duration: 2s; -webkit-animation-iteration-count: infinite; animation-iteration-count: infinite; -webkit-animation-name: bounce2; animation-name: bounce2; -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }
  .top-banner--alt .top-banner-sticky .tb__wrap .tb__action { display: none; }
  .top-banner--alt .top-banner-sticky .tb__close { top: 0; display: none; }
  .top-banner--alt .top-banner-bot { z-index: 101; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__text { max-width: 100%; padding: 0; }
  .top-banner--alt .top-banner-bot.img-md .tb__wrap .tb__text span.meta-sm { font-size: 14px; line-height: 20px; }
  .top-banner--alt .top-banner-bot .tb__wrap::before, .top-banner--alt .top-banner-bot .tb__wrap .tb__img { display: none; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__text { padding: 0; -webkit-flex: 0 0 100%; -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; text-align: center; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column; -webkit-align-items: center; -ms-flex-align: center; align-items: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__text span.meta { display: none; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__text span.meta-sm, .top-banner--alt .top-banner-bot .tb__wrap .tb__text span.action { display: -webkit-flex; display: -ms-flexbox; display: flex; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__text span.title { font-size: 20px; line-height: 28px; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__text span.title b { font-family: "Inter", sans-serif; font-weight: 600; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__text span.title-sm b { font-family: "Inter", sans-serif; font-weight: 600; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__text span.meta-sm small { font-size: 14px; line-height: 20px; }
  .top-banner--alt .top-banner-bot .tb__wrap .tb__action { display: none; }
  .top-banner--alt .top-banner-bot .tb__close { top: -7px; display: none; right: 0; } }

@media screen and (max-width: 600px) { .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text { flex: 0 0 100%; width: 100%; max-width: 100%; justify-content: center; text-align: center; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.title-sm, .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.meta-sm { justify-content: center; text-align: center; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.action { display: flex; flex: 0 0 100%; width: 100%; justify-content: center; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__action { display: none; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__text { flex: 0 0 100%; width: 100%; max-width: 100%; justify-content: center; text-align: center; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__text span.title-sm, .top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__text span.meta-sm { justify-content: center; text-align: center; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__text span.action { display: flex; flex: 0 0 100%; width: 100%; justify-content: center; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__text span.action span.link { color: #FFF; font-family: "Inter", sans-serif; font-weight: 600; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__text span.action span.link::after { filter: invert(100%) brightness(1000%); }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__action { display: none; }
  .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text span.title { font-size: 18px; line-height: 26px; } }

@media screen and (max-width: 395px) { .top-banner--alt .top-banner-sticky.ebook .tb__wrap { min-height: 101px; }
  .top-banner--alt .top-banner-bot.img-md.mcon .tb__wrap .tb__text { padding: 12px 0; } }

@media screen and (max-width: 375px) { .top-banner--alt .top-banner-sticky.img-md .tb__wrap { min-height: 101px; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.title-sm { font-size: 14px; line-height: 20px; font-family: "Inter", sans-serif; font-weight: 600; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.meta-sm { line-height: 20px; font-size: 14px; }
  .top-banner--alt .top-banner-sticky.img-md .tb__wrap .tb__text span.action .link { font-size: 14px; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__text span.title-sm { font-size: 14px; line-height: 20px; font-family: "Inter", sans-serif; font-weight: 600; }
  .top-banner--alt .top-banner-sticky.img-md.mcon .tb__wrap .tb__text span.meta-sm { line-height: 20px; font-size: 14px; } }

body.has-tb.tbs-show .top-banner-sticky { height: auto; opacity: 1; display: block; position: relative; transition: opacity linear .2s; }

@media (max-width: 991px) { body.has-tb.has-tb--alt header .header__left { top: 0; }
  body.has-tb.has-tb--alt.tbs-show.tbs-show-top header.fixed { top: 0; }
  body.has-tb.has-tb--alt.tbs-show .top-banner-sticky { background: #EDFAFF; top: 0; -webkit-transform: translateY(0); transform: translateY(0); } }

@media (max-width: 991px) { body.has-tb header .header__left { top: 48px; }
  body.has-tb.tbs-show.tbs-show-top header.fixed { top: 0; }
  body.has-tb.tbs-show.menu-open header.fixed { -webkit-transform: translateY(0) !important; transform: translateY(0) !important; }
  body.has-tb.menu-open .top-banner-sticky { visibility: hidden; }
  body.has-tb.menu-open header { z-index: 999; }
  body.has-tb.menu-open header .header__left { top: 0; transition: top linear .3s; }
  body.has-tb.menu-open.tbs-show header.fixed { -webkit-transform: translateY(0); transform: translateY(0); transition: all linear .3s; } }

body.has-tb .masthead { padding-top: 0; }

body.has-tb .masthead-f { margin-top: 0; }

body.has-tb.tbs-show .top-banner-sticky { opacity: 1; display: block; z-index: 101; position: relative; transition: all lienar .2s; }

body.has-tb.tbs-show header { top: 0; }

body.has-tb.tbs-show header .header__top { transition: all .4s ease; opacity: 1; visibility: visible; }

@media screen and (max-width: 1199px) { body.has-tb.has-tb--alt.menu-open .header-inline .top-banner .top-banner-sticky { display: none; } }

@media (max-width: 991px) { body.has-tb.has-tb--alt header .header__left { top: 0; }
  body.has-tb.has-tb--alt.tbs-show.tbs-show-top header.fixed { top: 0; }
  body.has-tb.has-tb--alt.tbs-show .top-banner-sticky { background: #EDFAFF; transform: translateY(0); } }

@media (max-width: 991px) { body.has-tb header .header__left { top: 48px; }
  body.has-tb.tbs-show.tbs-show-top header.fixed { top: 0; }
  body.has-tb.menu-open .top-banner-sticky { visibility: hidden; }
  body.has-tb.menu-open header { z-index: 999999; }
  body.has-tb.menu-open header .header__left { top: 0; transition: top linear .3s; } }

body.has-tbs-top.single .header { top: 64px; }

body.has-tbs-top .tbs { top: 0; }

body.has-tbs-top .tbs.fixed.show-top { top: 0; }

body.has-tbs-top .header.fixed .header__top { max-height: 0; padding-top: 0; padding-bottom: 0; opacity: 0; transition: all .4s ease; }

body.has-tbs-top .header.fixed.show-top { top: 36px; }

body.has-tbs-top .header.fixed.show-top .header__top { max-height: 40px; padding-top: 8px; padding-bottom: 8px; opacity: 1; }

@media (max-width: 991px) { body.has-tbs-top.single .tbs { top: 0; }
  body.has-tbs-top.single .header .header__left { top: 48px; }
  body.has-tbs-top .tbs { top: 0; }
  body.has-tbs-top .tbs.fixed { top: 0; }
  body.has-tbs-top .header { top: 126px !important; }
  body.has-tbs-top .header .header__left { top: 48px; transition: all .4s ease; }
  body.has-tbs-top.menu-open .header { top: 0 !important; }
  body.has-tbs-top.menu-open .header .header__left { top: 0 !important; }
  body.has-tbs-top.menu-open .tbs { display: none; }
  body.has-tbs-top.tbs-top .header .header__left { top: 48px; } }

.swiper .swiper-wrapper { padding-bottom: 32px; }

.swiper .swiper-pagination { line-height: 0; bottom: 0; }

.swiper .swiper-pagination .swiper-pagination-bullet { width: 32px; height: 6px; border-radius: 3px; margin: 2px 6px; background: #8B95A5; opacity: 1; }

.swiper .swiper-pagination .swiper-pagination-bullet-active { background: #005FBF; }

.swiper .swiper-pagination-vertical .swiper-pagination-bullet { width: 8px; height: 32px; border-radius: 4px; margin: 12px 0; background: #8B95A5; opacity: 1; }

.swiper .swiper-pagination-vertical .swiper-pagination-bullet-active { background: #005FBF; }

.header-inline { position: sticky; top: 0; left: 0; width: 100%; z-index: 105; background-color: #FFF; border-bottom: 0; transition: border-bottom linear .2s; }

.header-inline__wrapper { display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; padding: 8px 0; transition: padding linear .2s; flex: 0 0 100%; width: 100%; }

.header-inline__logo { flex: 0 0 100%; width: 100%; max-width: 106px; position: relative; padding: 0; }

.header-inline__logo .logo img { height: 32px; width: auto; object-fit: contain; }

.header-inline__logo .back { display: none; }

.header-inline__right { display: flex; align-items: center; flex-wrap: wrap; justify-content: flex-end; gap: 16px; padding: 0; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click { max-width: 86px; width: 100%; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item a.wpml-ls-item-toggle { color: #626B79; font-size: 14px; line-height: 20px; padding-right: calc(10px + 1.2em); }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item a.wpml-ls-item-toggle:hover { color: #626B79; border-radius: 8px; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item a.wpml-ls-item-toggle > img { margin-right: 4px; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu { background: #fff; padding: 4px; border-radius: 12px; overflow: hidden; border: 1px solid #D0D6DD; width: 115px; text-align: center; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item > a.wpml-ls-link { color: #232933; padding: 16px; border-radius: 8px; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item > a.wpml-ls-link:hover { background: #E0EEFF; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item > a.wpml-ls-link img { display: none; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item-id > a::before { content: "Indonesia"; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item-id > a > span { display: none; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item-en > a::before { content: "English"; }

.header-inline__right .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item-en > a > span { display: none; }

.header-inline__cta { display: none; }

.header-inline__cta-sm { display: flex; }

.header-inline__cta-sm .btn, .header-inline__cta-sm .link { margin-bottom: 0; }

.header-inline__cta-sm .btn { padding: 3px 12px; font-size: 14px; line-height: 20px; }

.header-inline__cta-mobile { display: flex; flex: 0 0 100%; width: 100%; flex-wrap: wrap; gap: 16px; padding: 16px; align-items: center; position: absolute; bottom: 0; left: 0; }

.header-inline__cta-mobile a, .header-inline__cta-mobile a.btn, .header-inline__cta-mobile a.link, .header-inline__cta-mobile a.more-link, .header-inline__cta-mobile .wpml-custom { max-width: calc((100%/2) - 16px); flex: 0 0 100%; width: 100%; margin-bottom: 0; font-size: 14px; line-height: 20px; }

.header-inline__cta-mobile a.btn-block, .header-inline__cta-mobile a.btn.btn-block, .header-inline__cta-mobile a.link.btn-block, .header-inline__cta-mobile a.more-link.btn-block, .header-inline__cta-mobile .wpml-custom.btn-block { max-width: 100%; }

.header-inline__cta-mobile a.btn { padding: 7px 14px; }

.header-inline__cta-mobile a.link, .header-inline__cta-mobile a.more-link { text-align: right; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click { max-width: 190px; width: 100%; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item a.wpml-ls-item-toggle { color: #626B79; font-size: 14px; line-height: 20px; width: 100%; max-width: max-content; padding-right: calc(10px + 1.2em); }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item a.wpml-ls-item-toggle:hover { color: #626B79; border-radius: 8px; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item a.wpml-ls-item-toggle > img { margin-right: 4px; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu { background: #fff; padding: 4px; border-radius: 12px; overflow: hidden; border: 1px solid #D0D6DD; width: 115px; text-align: center; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item > a.wpml-ls-link { color: #232933; padding: 16px; border-radius: 8px; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item > a.wpml-ls-link:hover { background: #E0EEFF; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item > a.wpml-ls-link img { display: none; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item-id > a::before { content: "Indonesia"; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item-id > a > span { display: none; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item-en > a::before { content: "English"; }

.header-inline__cta-mobile .wpml-custom .wpml-ls-legacy-dropdown-click ul li.wpml-ls-item ul.wpml-ls-sub-menu li.wpml-ls-item-en > a > span { display: none; }

.header-inline__burger { display: inline-flex; justify-content: center; align-items: center; }

.header-inline__burger .burger-menu { display: inline-flex; cursor: pointer; width: 24px; height: 24px; border-radius: 2px; cursor: pointer; position: relative; }

.header-inline__burger .burger-menu span { position: absolute; top: 4px; right: 0; width: 100%; height: 3px; background: #009bde; transform: rotate(0deg); opacity: 1; transition: transform linear .2s, opacity linear .2s; }

.header-inline__burger .burger-menu span:nth-child(2) { width: 75%; top: 12px; }

.header-inline__burger .burger-menu span:nth-child(3) { top: 19px; }

.header-inline__dropdown { position: relative; display: flex; flex-wrap: wrap; align-items: flex-start; justify-content: space-between; width: 100%; transform: translateX(-100%); transition: transform linear .2s, height linear .2s; }

body { overflow: unset; }

body.menu-open { overflow: hidden; }

body.menu-open .header-inline { height: 100vh; transition: height linear .15s; }

body.menu-open .header-inline__burger .burger-menu span:nth-child(1) { top: 10px; transform: rotate(135deg); transition: transform linear .2s; }

body.menu-open .header-inline__burger .burger-menu span:nth-child(2) { top: 10px; opacity: 0; transition: opacity linear .2s; }

body.menu-open .header-inline__burger .burger-menu span:nth-child(3) { top: 10px; transform: rotate(45deg); transition: transform linear .2s; }

body.menu-open .header-inline__dropdown { transform: translateX(0); transition: transform linear .10s, height linear .2s; }

body.header-fixed .header-inline { border-bottom: 1px solid #E2E2E2; transition: border-bottom linear .2s; }

@media screen and (max-width: 1199px) { .header-inline__main { display: none; }
  .header-inline__dropdown { position: absolute; }
  body.menu-open.submenu-open .header-inline__dropdown { position: fixed; top: 0; left: 0; height: 100%; transition: top lienar .2s; } }

@media screen and (max-width: 990px) { body.menu-open .header-inline__dropdown { height: calc(100dvh - 57px); } }

@media screen and (min-width: 438px) { .header-inline__cta-mobile a.btn { font-size: 16px; line-height: 24px; padding: 7px 24px; } }

@media screen and (min-width: 991px) { .header-inline__wrapper { transition: padding linear .2s; padding: 0; }
  .header-inline__right { padding: 16px 0; }
  .header-inline__logo { padding: 16px 0; }
  .header-inline__logo .logo img { height: 40px; }
  .header-inline__cta { display: flex; flex-wrap: wrap; justify-content: flex-end; align-items: center; gap: 16px; }
  .header-inline__cta a.link, .header-inline__cta a.more-link { font-size: 14px; line-height: 20px; color: #4B61DD; font-family: "Inter", sans-serif; font-weight: 600; transition: all linear .2s; }
  .header-inline__cta a.link:hover, .header-inline__cta a.more-link:hover { color: #2740ce; transition: all linear .2s; }
  .header-inline__cta a.link.seperator, .header-inline__cta a.more-link.seperator { padding-right: 16px; border-right: 1px solid #D0D6DD; }
  .header-inline__cta-sm, .header-inline__cta-mobile { display: none; }
  .header-inline__cta-mobile a, .header-inline__cta-mobile a.btn, .header-inline__cta-mobile a.link, .header-inline__cta-mobile a.more-link, .header-inline__cta-mobile .wpml-custom { width: max-content; flex: 0 0 max-content; }
  .header-inline__dropdown { transform: translateX(-100%); transition: transform linear .2s; }
  body { overflow: unset; }
  body.header-fixed .header-inline__wrapper { padding: 0; transition: padding linear .2s, border-bottom linear .2s; }
  body.menu-open { overflow: hidden; } }

@media screen and (min-width: 1200px) { .header-inline { height: auto; }
  .header-inline__dropdown { transform: translateX(0); }
  .header-inline__left { display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap; gap: 40px; width: 100%; max-width: calc(100% - (484px + 40px)); }
  .header-inline__cta-mobile { display: none; }
  .header-inline__cta-mobile a.link { font-size: 14px; line-height: 20px; color: #4B61DD; font-family: "Inter", sans-serif; font-weight: 600; transition: all linear .2s; }
  .header-inline__main { flex: 0 0 100%; width: 100%; max-width: calc(100% - (106px + 40px)); display: flex; }
  .header-inline__burger { display: none; }
  body.menu-open { overflow: unset; }
  body.menu-open .header-inline { height: auto; } }

/* ------------------------------------------------------------------------------------------------ */
/* Form */
/* ------------------------------------------------------------------------------------------------ */
.field_group { margin: 0 0 20px; }

.field_group label { display: block; font-size: 14px; font-weight: 500; letter-spacing: 0.2px; line-height: 20px; margin: 0 0 5px; }

.field_group label.error, .field_group .validation_message { color: #D74022; font-weight: 400; padding: 4px 0; font-size: 12px; letter-spacing: 0.2px; line-height: 16px; }

.field_group.gfield_error .field { border-color: #009BDE; }

.field_group .gfield_required { color: #009BDE; font-weight: 400; }

.field { display: block; width: 100%; height: 36px; border: 1px solid #E2E2E2; border-radius: 4px; background: #FFF; padding: 8px 12px; font-size: 14px; }

.field:focus { outline: none; }

.field.error { border-color: #009BDE; }

.form-group { margin-bottom: 12px; position: relative; }

.form-group > label { font-size: 15px; line-height: 26px; margin-bottom: 5px; display: block; font-family: "Inter", sans-serif; font-weight: 500; }

.form-group > small { font-size: 12px; line-height: 16px; color: #777; }

.form-group .form-check label { display: inline-block !important; font-family: "Inter", sans-serif; font-weight: 400; }

.form-group:last-child { margin-bottom: 0; }

.form-group.error .form-control { border-color: #C0392B; }

.form-group.error small.error { color: #C0392B; }

.form-group.error .dropdown.bootstrap-select button.select-control { border-color: #C0392B; }

.form-group.success { position: relative; }

.form-group.success .form-control { border-color: #179E63; }

.form-group.success::after { position: absolute; content: ''; width: 20px; height: 20px; background: url("../images/ic-check.svg") no-repeat center; background-size: contain; display: inline-block; right: 10px; bottom: 11px; }

.form-group.success .dropdown.bootstrap-select button.select-control { border-color: #179E63; }

.form-group.date::after { position: absolute; content: ''; width: 18px; height: 18px; background: url("../images/ic-calendar.svg") no-repeat center; background-size: contain; display: inline-block; right: 10px; top: 43px; }

.form-group:has(input[type="checkbox"]).success::after, .form-group:has(button[type="submit"]).success::after { display: none; }

.form-group.form-hidden.success::after { display: none; }

.form-action { position: relative; }

.form-control { background: #FFFFFF; border: 1px solid #E2E2E2; border-radius: 4px; padding: 8px 16px; width: 100%; }

.form-control:hover, .form-control:focus, .form-control:active:focus { outline: 0; border: 1px solid #4B61DD; }

.form-control.disabled { background: #e9ecef; }

.radio { margin: 12px 0 !important; position: relative; display: block; cursor: pointer; }

.radio_con { font-weight: 400; padding-left: 30px; }

.radio_con:before, .radio_con:after { content: ""; position: absolute; top: 0; left: 0; width: 20px; height: 20px; border-radius: 50%; border: 1px solid #e2e2e2; background: #fff; }

.radio_con:after { width: 8px; height: 8px; top: 6px; left: 6px; border: none; }

.radio input[type="radio"] { position: absolute; top: 0; width: 0; visibility: hidden; }

.radio input[type="radio"]:checked + div:before { background: #0060BF; }

.radio.with-input .radio_con:before { top: 8px; }

.radio.with-input .radio_con:after { top: 14px; }

.radio + .radio { margin-top: 4px; }

.dropdown.bootstrap-select { position: relative; }

.dropdown.bootstrap-select select { position: absolute; bottom: 0; left: 50%; display: block; width: 0.5px; height: 100%; padding: 0; opacity: 0; border: none; visibility: hidden; }

.dropdown.bootstrap-select .btn, .dropdown.bootstrap-select button { cursor: pointer; border: 1px solid #E2E2E2; border-radius: 4px; font-size: 16px; letter-spacing: -0.04px; line-height: 42px; width: 100%; text-align: left; padding: 0 23px 0 12px; outline: none !important; background: #FFF; position: relative; }

.dropdown.bootstrap-select .btn::after, .dropdown.bootstrap-select button::after { content: ''; width: 20px; height: 20px; background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M5 7.5L10 12.5L15 7.5" stroke="%23626B79" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'); background-repeat: no-repeat; background-size: contain; position: absolute; right: 10px; top: calc(50% - 10px); }

.dropdown.bootstrap-select div.dropdown-menu { position: absolute; top: 100%; left: 0; z-index: 1000; display: none; text-align: left; background-color: #fff; background-clip: padding-box; border: 1px solid #AFAFAF; border-radius: 4px; margin: 2px 0 0; width: 100%; min-width: 100px; box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.25); }

.dropdown.bootstrap-select div.dropdown-menu a { display: block; padding: 10px 12px; color: #485460; font-weight: 400; cursor: pointer; font-size: 14px; line-height: 20px; }

.dropdown.bootstrap-select div.dropdown-menu li { position: relative; }

.dropdown.bootstrap-select div.dropdown-menu li .fa { display: none; }

.dropdown.bootstrap-select div.dropdown-menu li:hover a { background: rgba(0, 0, 0, 0.1); text-decoration: none; }

.dropdown.bootstrap-select div.dropdown-menu li.selected a, .dropdown.bootstrap-select div.dropdown-menu li.active a { background: rgba(0, 0, 0, 0.1); }

.dropdown.bootstrap-select div.dropdown-menu.show { display: block; }

.dropdown.bootstrap-select.show .bttn { border-color: #1565BE; }

.bs-searchbox { padding: 10px; background: #eee; }

.bs-searchbox .form-control { width: 100%; height: 40px; border: 1px solid #000; padding: 6px 12px; }

.bs-placeholder .filter-option-inner-inner { color: #777; }

li.no-results { padding: 10px; }

.validation_error { display: none; }

.gfield_radio > li { margin: 12px 0 !important; position: relative; display: block; padding-left: 30px; }

.gfield_radio > li label, .gfield_radio > li div.con { font-weight: 400; cursor: pointer; }

.gfield_radio > li label:before, .gfield_radio > li label:after, .gfield_radio > li div.con:before, .gfield_radio > li div.con:after { content: ""; position: absolute; top: 0; left: 0; width: 20px; height: 20px; border-radius: 50%; border: 1px solid #e2e2e2; background: #fff; }

.gfield_radio > li label:after, .gfield_radio > li div.con:after { width: 8px; height: 8px; top: 6px; left: 6px; border: none; }

.gfield_radio > li input[type="radio"] { position: absolute; top: 0; width: 0; visibility: hidden; }

.gfield_radio > li input[type="radio"]:checked + label:before { background: #0060BF; }

.gfield_radio > li.with-input .con:before { top: 8px; }

.gfield_radio > li.with-input .con:after { top: 14px; }

.gfield_radio > li + .radio { margin-top: 4px; }

.bs .gform_wrapper form { display: flex; align-items: flex-end; }

@media (max-width: 768px) { .bs .gform_wrapper form { flex-wrap: wrap; } }

.bs .gform_wrapper ul li.gfield { margin: 0 !important; margin-top: 0 !important; }

.bs .gform_wrapper ul li.gfield.gfield_error { background: none; border: none; padding: 0; }

.bs .gform_wrapper .field_description_below .gfield_description { padding: 0; color: #009BDE; font-weight: 400; position: absolute; }

.bs .gform_wrapper .field { height: 38px !important; }

.bs .gform_wrapper .gform_footer { padding: 0 !important; margin: 0 !important; width: 150px; max-width: 150px; }

.bs .gform_wrapper .gform_footer .gform_button { width: 100% !important; margin: 0 !important; line-height: 38px !important; }

.bs .gform_wrapper .top_label input.medium, .bs .gform_wrapper .top_label select.medium { width: 100% !important; }

.bs .gform_wrapper .field_group { margin-bottom: 0; }

.custom-control { position: relative; display: block; min-height: 1.5rem; padding-left: 1.5rem; }

.custom-control-inline { display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; margin-right: 1rem; }

.custom-control-input { position: absolute; z-index: -1; opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before { color: #fff; background-color: #007bff; }

.custom-control-input:active ~ .custom-control-label::before { color: #fff; background-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label { color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before { background-color: #e9ecef; }

.custom-control-label { margin-bottom: 0; }

.custom-control-label::before { position: absolute; top: 0.25rem; left: 0; display: block; width: 1rem; height: 1rem; pointer-events: none; content: ""; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; background-color: #FFF; border: 1px solid #E2E2E2; }

.custom-control-label::after { position: absolute; top: 0.25rem; left: 0; display: block; width: 1rem; height: 1rem; content: ""; background-repeat: no-repeat; background-position: center center; background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before { border-radius: 2px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before { background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before { background-color: #005FBF; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before { background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before { border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before { background-color: #005FBF; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(0, 123, 255, 0.5); }

.date_field { position: relative; }

.datepicker.datepicker-dropdown { position: absolute; background: #FFF; border: 1px solid #777; padding: 10px; }

.btn-group.btn-group-toggle { background-color: #FFF; border-radius: 1.25rem; border: 3px solid #FFF; display: flex; }

.btn-group.btn-group-toggle input { position: absolute; opacity: 0; visibility: hidden; z-index: -10; }

.btn-group.btn-group-toggle .btn { display: block; flex-grow: 1; flex-basis: 1; font-size: 0.75rem; line-height: 1.429em; font-family: "Inter", sans-serif; font-weight: 500; margin: 0; border: 1.5px solid transparent; border-radius: 1rem; color: #545465; background: #FFFFFF; cursor: pointer; }

.btn-group.btn-group-toggle .btn:not(:disabled):not(.disabled):active, .btn-group.btn-group-toggle .btn.active { border-color: #005FBF; background-color: #005FBF; color: #FFF; }

.btn-group.btn-group--custom { margin-bottom: -1rem; }

.btn-group.btn-group--custom .row { margin: 0 -0.5rem; }

.btn-group.btn-group--custom .row > .col, .btn-group.btn-group--custom .row > [class*="col-"] { padding-left: 0.5rem; padding-right: 0.5rem; }

.btn-group.btn-group--custom input { position: absolute; opacity: 0; visibility: hidden; z-index: -10; }

.btn-group.btn-group--custom .btn { display: block; position: relative; width: 100%; border: 1px solid #E2E2E2; border-radius: 0.25rem; padding: 1.5rem; font-size: 0.75rem; line-height: 1.5em; margin: 0 0 1rem; cursor: pointer; }

.btn-group.btn-group--custom .btn .icon { display: block; height: 2rem; width: 2rem; object-fit: contain; margin: 0 auto 0.5rem; }

.btn-group.btn-group--custom .btn:hover { color: #232933; border-color: #005FBF; }

.btn-group.btn-group--custom .btn.active { border-color: #005FBF; }

.btn-group.btn-group--custom .btn.active:after { content: ""; z-index: 1; display: block; width: 1.25rem; height: 1.25rem; background-image: url(../images/ic-check-blue.svg); background-color: #FFF; background-position: center; background-size: contain; background-repeat: no-repeat; position: absolute; top: calc(-0.75rem + 1px); right: calc(-0.75rem + 1px); border-radius: 1.25rem; border: 2px solid #FFF; }

.custom-form .field_group:not(:last-child) { margin-bottom: 1.5rem; }

.custom-form .field_group > label { display: block; color: #2B2A35; font-size: 1rem; line-height: 1.5em; font-family: "Inter", sans-serif; font-weight: 500; margin: 0 0 0.25rem; }

.custom-form .field { display: block; width: 100%; height: 40px; padding: 6px 12px; font-size: 16px; font-weight: normal; line-height: 24px; color: #232933; background-color: #FFF; border: 1px solid #E2E2E2; border-radius: 3px; transition: border-color 0.3s ease-in-out; }

.custom-form .field:focus { border-color: #005FBF; outline: 0; }

.custom-form .field::placeholder { color: #AAA; opacity: 1; }

.custom-form .field:disabled, .custom-form .field[readonly] { background-color: #e9ecef; opacity: 1; }

.custom-form .multiple { display: block; width: 100%; height: auto; padding: 6px 12px; font-weight: normal; color: #232933; background-color: #FFF; border: 1px solid #E2E2E2; border-radius: 3px; transition: border-color 0.3s ease-in-out; }

.custom-form .multiple:focus { border-color: #005FBF; outline: 0; }

.custom-form .multiple::placeholder { color: #AAA; opacity: 1; }

.custom-form .multiple:disabled, .custom-form .multiple[readonly] { background-color: #e9ecef; opacity: 1; }

.custom-form textarea.field { height: auto; }

.custom-form .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) { width: 100%; }

.form-hidden { display: none; position: absolute; visibility: hidden; }

.form-row small { display: none; }

.form-row .error .form-control { border-color: #C02A34; }

.form-row .error small { display: inline-block; color: #C02A34; font-size: 10px; line-height: 18px; }

input:-internal-autofill-selected { background: #FFF !important; }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active { -webkit-box-shadow: 0 0 0 60px white inset !important; }

.captcha-wrap { display: flex; align-items: center; margin: 24px 0; }

.captcha-field { padding: 8px 16px; display: inline-block; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box; max-width: 150px; height: 36px; }

.captcha-canvas { /*prevent interaction with the canvas */ pointer-events: none; margin-right: 8px; display: flex; align-items: center; }

.captcha-canvas .captcha { background: #f6f6f7; border-radius: 6px; }

.captcha-refresh { width: 20px; height: 20px; background: url(../images/ic-refresh.png) no-repeat center; background-size: contain; display: inline-block; cursor: pointer; opacity: .8; margin-right: 24px; }

.captcha-refresh:hover { opacity: 1; }

/* ------------------------------------------------------------------------------------------------ */
/* Content */
/* ------------------------------------------------------------------------------------------------ */
.masthead__meta, .masthead__subtitle { font-size: 16px; line-height: 24px; display: flex; margin-bottom: 8px; color: #232933; font-family: "Inter", sans-serif; font-weight: 600; }

.masthead__meta .pill, .masthead__subtitle .pill { margin-left: 4px; }

.masthead__title { font-family: "Inter", sans-serif; font-weight: 700; color: #2B2A35; margin-bottom: 16px; font-size: 40px; line-height: 56px; color: #2B2A35; }

@media (max-width: 768px) { .masthead__title { font-size: 28px; line-height: 36px; } }

.masthead__content { padding: 64px 0; }

.masthead__content.no-align { text-align: unset !important; }

.masthead__content.no-align p { max-width: 100% !important; }

.masthead__action { margin-left: -8px; margin-right: -8px; }

.masthead__action .btn { margin-left: 8px; margin-right: 8px; margin-bottom: 16px; }

.masthead__form { background: #FFFFFF; box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05); padding: 32px; border-radius: 8px; }

@media (max-width: 991px) { .masthead--home .masthead__wrap { padding-top: 4rem !important; }
  .masthead--home .masthead__content { padding: 0 12px 0 12px !important; } }

.masthead--home, .masthead--fitur-alt { position: relative; }

.masthead--home .masthead__title, .masthead--fitur-alt .masthead__title { margin-left: auto; margin-right: auto; max-width: 83.333333%; }

@media (max-width: 768px) { .masthead--home .masthead__title, .masthead--fitur-alt .masthead__title { max-width: 100%; } }

.masthead--home .masthead__content, .masthead--fitur-alt .masthead__content { text-align: center; padding-bottom: 48px; }

.masthead--home .masthead__content p, .masthead--fitur-alt .masthead__content p { max-width: 600px; margin: 0 auto 40px; }

.masthead--home .masthead__action .btn, .masthead--fitur-alt .masthead__action .btn { margin-left: 8px; margin-right: 8px; margin-bottom: 16px; }

@media (max-width: 768px) { .masthead--home .masthead__action .btn, .masthead--fitur-alt .masthead__action .btn { width: 100%; margin-left: 0; margin-right: 0; } }

.masthead--home.animate .masthead__title, .masthead--home.animate .masthead__content p, .masthead--home.animate .masthead__action, .masthead--fitur-alt.animate .masthead__title, .masthead--fitur-alt.animate .masthead__content p, .masthead--fitur-alt.animate .masthead__action { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.masthead--home.animate .masthead__content p, .masthead--fitur-alt.animate .masthead__content p { transition-delay: .25s; }

.masthead--home.animate .masthead__action, .masthead--fitur-alt.animate .masthead__action { transition-delay: .2s; }

.masthead--home.animate-in .masthead__title, .masthead--home.animate-in .masthead__content p, .masthead--home.animate-in .masthead__action, .masthead--fitur-alt.animate-in .masthead__title, .masthead--fitur-alt.animate-in .masthead__content p, .masthead--fitur-alt.animate-in .masthead__action { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.masthead--fitur-alt .masthead__content { padding-bottom: 80px; }

.masthead--fitur-alt .masthead__content p:last-child { margin-bottom: 0; }

.masthead--alt .masthead__media { position: relative; }

.masthead--alt .masthead__media.show-sm { margin-bottom: 16px; }

.masthead--alt .masthead__media .media { display: block; position: relative; width: 100%; }

.masthead--alt .masthead__media .media--video { position: relative; }

.masthead--alt .masthead__media .media--img img { display: block; width: 100%; height: auto; }

.masthead--alt .masthead__media .media__cover { display: block; width: 100%; height: 100%; }

.masthead--alt .masthead__media .media__play { display: block; width: 80px; height: 80px; border-radius: 40px; background: url(../images/ic-play-circle-alt.svg) no-repeat center; background-size: contain; position: absolute; top: calc(50% - 40px); left: calc(50% - 40px); box-shadow: 0 0 0 rgba(0, 0, 0, 0.4); animation: pulse 2s infinite; }

.masthead--alt .masthead__wrap { padding-top: 1.875rem; padding-bottom: 1.875rem; }

.masthead--alt .masthead__content { padding-top: 0; padding-bottom: 0; }

.masthead--alt .masthead__desc { margin-bottom: 24px; }

.masthead--alt .owl-dots .owl-dot { background: #E2E2E2; width: 32px; height: 4px; border-radius: 2px; }

.masthead--alt .owl-dots .owl-dot.active { background: #009BDD; }

@media (max-width: 991px) { .masthead--alt .owl-dots { position: absolute; width: 100%; bottom: 0; left: 0; } }

.masthead--seo { position: relative; }

.masthead--seo .masthead__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 48px; line-height: 56px; color: #2B2A35; margin: 0 0 24px; margin-left: auto; margin-right: auto; max-width: 83.333333%; }

@media (max-width: 768px) { .masthead--seo .masthead__title { max-width: 100%; font-size: 2rem; line-height: 2.5rem; } }

.masthead--seo .masthead__content { text-align: center; padding-bottom: 48px; }

.masthead--seo .masthead__content p { max-width: 600px; margin: 0 auto 40px; }

.masthead--seo .masthead__meta { display: inline-flex; }

.masthead--seo .masthead__desc { margin-bottom: 24px; }

.masthead--seo .masthead__action .btn { margin-left: 8px; margin-right: 8px; margin-bottom: 16px; }

@media (max-width: 768px) { .masthead--seo .masthead__action .btn { width: 100%; margin-left: 0; margin-right: 0; } }

.masthead--seo.animate .masthead__title, .masthead--seo.animate .masthead__content p, .masthead--seo.animate .masthead__action { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.masthead--seo.animate .masthead__content p { transition-delay: .2s; }

.masthead--seo.animate .masthead__action { transition-delay: .2s; }

.masthead--seo.animate-in .masthead__title, .masthead--seo.animate-in .masthead__content p, .masthead--seo.animate-in .masthead__action { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.masthead--seo .masthead__content { padding-bottom: 80px; }

.masthead--seo .masthead__content p:last-child { margin-bottom: 0; }

.masthead--m-overview .masthead__left, .masthead--m-integration .masthead__left, .masthead--fitur .masthead__left, .masthead--solusi .masthead__left, .masthead--pricing-alt .masthead__left { padding-top: 36px; padding-bottom: 36px; }

.masthead--m-overview .masthead__content, .masthead--m-integration .masthead__content, .masthead--fitur .masthead__content, .masthead--solusi .masthead__content, .masthead--pricing-alt .masthead__content { padding-top: 50px; padding-bottom: 90px; }

.masthead--m-overview .masthead__desc, .masthead--m-integration .masthead__desc, .masthead--fitur .masthead__desc, .masthead--solusi .masthead__desc, .masthead--pricing-alt .masthead__desc { margin-bottom: 24px; }

.masthead--m-overview .masthead__desc p:last-child, .masthead--m-integration .masthead__desc p:last-child, .masthead--fitur .masthead__desc p:last-child, .masthead--solusi .masthead__desc p:last-child, .masthead--pricing-alt .masthead__desc p:last-child { margin-bottom: 0; }

.masthead--m-overview .masthead__action + .masthead__media, .masthead--m-integration .masthead__action + .masthead__media, .masthead--fitur .masthead__action + .masthead__media, .masthead--solusi .masthead__action + .masthead__media, .masthead--pricing-alt .masthead__action + .masthead__media { padding-top: 32px; }

.masthead--m-overview .masthead__media, .masthead--m-integration .masthead__media, .masthead--fitur .masthead__media, .masthead--solusi .masthead__media, .masthead--pricing-alt .masthead__media { position: relative; }

.masthead--m-overview .masthead__media .media, .masthead--m-integration .masthead__media .media, .masthead--fitur .masthead__media .media, .masthead--solusi .masthead__media .media, .masthead--pricing-alt .masthead__media .media { display: block; position: relative; width: 100%; }

.masthead--m-overview .masthead__media .media--video, .masthead--m-integration .masthead__media .media--video, .masthead--fitur .masthead__media .media--video, .masthead--solusi .masthead__media .media--video, .masthead--pricing-alt .masthead__media .media--video { padding-top: 83.333333%; position: relative; }

.masthead--m-overview .masthead__media .media--img img, .masthead--m-integration .masthead__media .media--img img, .masthead--fitur .masthead__media .media--img img, .masthead--solusi .masthead__media .media--img img, .masthead--pricing-alt .masthead__media .media--img img { display: block; width: 100%; height: auto; }

.masthead--m-overview .masthead__media .media__cover, .masthead--m-integration .masthead__media .media__cover, .masthead--fitur .masthead__media .media__cover, .masthead--solusi .masthead__media .media__cover, .masthead--pricing-alt .masthead__media .media__cover { position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; object-fit: cover; }

.masthead--m-overview .masthead__media .media__play, .masthead--m-integration .masthead__media .media__play, .masthead--fitur .masthead__media .media__play, .masthead--solusi .masthead__media .media__play, .masthead--pricing-alt .masthead__media .media__play { display: block; width: 80px; height: 80px; border-radius: 40px; background: url(../images/ic-play-circle-alt.svg) no-repeat center; background-size: contain; position: absolute; top: calc(50% - 40px); left: calc(50% - 40px); box-shadow: 0 0 0 rgba(0, 0, 0, 0.4); animation: pulse 2s infinite; }

.masthead--m-overview .masthead__full, .masthead--m-integration .masthead__full, .masthead--fitur .masthead__full, .masthead--solusi .masthead__full, .masthead--pricing-alt .masthead__full { padding-top: 30px; padding-bottom: 40px; }

.masthead--m-overview .masthead__full .masthead__media .media--video, .masthead--m-integration .masthead__full .masthead__media .media--video, .masthead--fitur .masthead__full .masthead__media .media--video, .masthead--solusi .masthead__full .masthead__media .media--video, .masthead--pricing-alt .masthead__full .masthead__media .media--video { padding-top: 40.849673%; }

.masthead--m-customers .masthead__meta { justify-content: center; }

.masthead--m-integration .masthead__content { padding-bottom: 50px; }

.masthead--m-customers .masthead__subtitle, .masthead--m-customers .masthead__meta, .masthead--solusi-center .masthead__subtitle, .masthead--solusi-center .masthead__meta, .masthead--pricing .masthead__subtitle, .masthead--pricing .masthead__meta { text-align: center; justify-content: center; }

.masthead--m-customers .masthead__title, .masthead--solusi-center .masthead__title, .masthead--pricing .masthead__title { margin: 0; }

.masthead--m-customers .masthead__title.small, .masthead--solusi-center .masthead__title.small, .masthead--pricing .masthead__title.small { font-size: 40px; line-height: 56px; margin-bottom: 8px; }

@media (max-width: 768px) { .masthead--m-customers .masthead__title.small, .masthead--solusi-center .masthead__title.small, .masthead--pricing .masthead__title.small { font-size: 2rem; line-height: 2.5rem; } }

.masthead--m-customers .masthead__content, .masthead--solusi-center .masthead__content, .masthead--pricing .masthead__content { text-align: center; padding-bottom: 48px; }

.masthead--m-customers .masthead__media, .masthead--solusi-center .masthead__media, .masthead--pricing .masthead__media { padding-top: 48px; position: relative; }

.masthead--m-customers .masthead__media .media, .masthead--solusi-center .masthead__media .media, .masthead--pricing .masthead__media .media { display: block; position: relative; width: 100%; }

.masthead--m-customers .masthead__media .media--img img, .masthead--solusi-center .masthead__media .media--img img, .masthead--pricing .masthead__media .media--img img { display: block; width: 100%; height: auto; }

.masthead--m-customers .masthead__media .media--logo, .masthead--solusi-center .masthead__media .media--logo, .masthead--pricing .masthead__media .media--logo { display: flex; justify-content: center; margin-left: -8px; margin-right: -8px; }

.masthead--m-customers .masthead__media .media .logo-col, .masthead--solusi-center .masthead__media .media .logo-col, .masthead--pricing .masthead__media .media .logo-col { position: relative; display: block; width: 100%; max-width: 14.285714%; flex: 0 0 14.285714%; padding: 0 8px; }

.masthead--m-customers .masthead__media .media .logo-col:nth-child(1), .masthead--solusi-center .masthead__media .media .logo-col:nth-child(1), .masthead--pricing .masthead__media .media .logo-col:nth-child(1) { margin-top: 9%; }

.masthead--m-customers .masthead__media .media .logo-col:nth-child(2), .masthead--solusi-center .masthead__media .media .logo-col:nth-child(2), .masthead--pricing .masthead__media .media .logo-col:nth-child(2) { margin-top: 14%; }

.masthead--m-customers .masthead__media .media .logo-col:nth-child(3), .masthead--solusi-center .masthead__media .media .logo-col:nth-child(3), .masthead--pricing .masthead__media .media .logo-col:nth-child(3) { margin-top: 7%; }

.masthead--m-customers .masthead__media .media .logo-col:nth-child(5), .masthead--solusi-center .masthead__media .media .logo-col:nth-child(5), .masthead--pricing .masthead__media .media .logo-col:nth-child(5) { margin-top: 14%; }

.masthead--m-customers .masthead__media .media .logo-col:nth-child(6), .masthead--solusi-center .masthead__media .media .logo-col:nth-child(6), .masthead--pricing .masthead__media .media .logo-col:nth-child(6) { margin-top: 7%; }

.masthead--m-customers .masthead__media .media .logo-col:nth-child(7), .masthead--solusi-center .masthead__media .media .logo-col:nth-child(7), .masthead--pricing .masthead__media .media .logo-col:nth-child(7) { margin-top: 14%; }

.masthead--m-customers .masthead__media .media .logo-col .item, .masthead--solusi-center .masthead__media .media .logo-col .item, .masthead--pricing .masthead__media .media .logo-col .item { position: relative; width: 100%; margin-bottom: 16px; overflow: hidden; border-radius: 4px; box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3); box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }

.masthead--m-customers .masthead__media .media .logo-col img, .masthead--solusi-center .masthead__media .media .logo-col img, .masthead--pricing .masthead__media .media .logo-col img { display: block; width: 100%; height: auto; margin: 0; }

.masthead--fitur .masthead__content, .masthead--pricing-alt .masthead__content { padding-bottom: 30px; }

.masthead--promo { background: radial-gradient(73.27% 70.82% at 50% 65.13%, #81B8D4 0%, #007DE5 37.44%, #004DC2 100%); color: #FFF; }

.masthead--promo .masthead__left { text-align: left; padding-right: 30px; }

@media (max-width: 991px) { .masthead--promo .masthead__left { padding-right: 12px; } }

.masthead--promo .masthead__title { color: #FFF; position: relative; }

.masthead--promo .masthead__hero { margin-bottom: 15px; }

.masthead--promo .masthead__content { text-align: center; }

.masthead--promo .masthead__desc { margin-bottom: 24px; }

.masthead--promo .masthead__action .btn:last-child { margin-left: 12px; }

@media (max-width: 991px) { .masthead--promo .masthead__action .btn:last-child { margin-left: 0; } }

.masthead--promo .masthead__logo { margin-bottom: 48px; }

.masthead--promo .masthead__form { background: #FFF; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); border-radius: 8px; padding: 40px; text-align: left; color: #232933; }

.masthead--promo .masthead__form .form-group > label { font-family: "Inter", sans-serif; font-weight: 500; font-size: 16px; line-height: 24px; color: #232933; }

@media (max-width: 768px) { .masthead--promo .masthead__form { padding: 40px 16px; } }

.masthead--promo-alt .masthead__content { padding: 0 0 35px; }

.masthead--promo-alt .masthead__title { font-size: 2rem; line-height: 3rem; }

.masthead--promo-alt .masthead__desc { margin-bottom: 2rem; }

@media (max-width: 768px) { .masthead--landing .masthead__hero { display: none; } }

@media (max-width: 991px) { .masthead .masthead__wrap { padding: 1.875rem 8px; }
  .masthead .masthead__content { padding: 0 12px 40px 12px; }
  .masthead .masthead__meta, .masthead .masthead__subtitle { font-size: 16px; line-height: 24px; display: flex; margin-bottom: 0; justify-content: space-between; align-items: start; font-family: "Inter", sans-serif; font-weight: 600; }
  .masthead .masthead__meta > span.badge, .masthead .masthead__subtitle > span.badge { width: 156px; }
  .masthead .masthead__meta div .span.badge, .masthead .masthead__subtitle div .span.badge { width: auto; }
  .masthead .masthead__title { font-size: 32px; line-height: 40px; }
  .masthead .masthead__action { margin-left: 0; margin-right: 0; }
  .masthead .masthead__action a { margin-left: 0; margin-right: 0; }
  .masthead .masthead__action a.first, .masthead .masthead__action a:first-child { margin-bottom: 16px; }
  .masthead .masthead__media { margin-top: 25px; }
  .masthead .masthead__form { margin-top: 40px; padding: 24px; }
  .masthead--m-overview .masthead__left, .masthead--m-integration .masthead__left, .masthead--fitur .masthead__left { padding-top: 18px; padding-bottom: 32px; }
  .masthead--m-overview .masthead__content, .masthead--m-integration .masthead__content, .masthead--fitur .masthead__content { padding-top: 25px; padding-bottom: 60px; }
  .masthead--m-overview .masthead__action, .masthead--m-integration .masthead__action, .masthead--fitur .masthead__action { padding-top: 0; }
  .masthead--m-overview .masthead__media .media--img, .masthead--m-integration .masthead__media .media--img, .masthead--fitur .masthead__media .media--img { padding-left: 0; }
  .masthead--m-integration .masthead__content { padding-bottom: 30px; }
  .masthead--m-customers .masthead__meta { justify-content: center !important; }
  .masthead--m-customers .masthead__content { text-align: center; }
  .masthead--m-customers .masthead__media { padding-top: 48px; }
  .masthead--fitur .masthead__content { padding-bottom: 20px; } }

.masthead--promo-alt { background: conic-gradient(from 62.36deg at 64.53% 47.25%, #0050C4 0deg, #008CF2 360deg); color: #FFF; padding: 5rem 0 24px 0; }

.masthead--promo-alt .masthead__content { padding-bottom: 0; }

.masthead--promo-alt .masthead__title { color: #FFF; font-size: 3rem; line-height: 3.5rem; }

.masthead--promo-alt .masthead__desc { margin: 0 0 40px 0; }

.masthead--promo-alt .masthead__action { margin-bottom: 48px; }

.masthead--promo-alt .masthead__action .btn-white { color: #009BDE; border-color: #FFF; }

.masthead--promo-alt .masthead__action .btn-white:hover { background: #e6e5e5; }

.masthead--promo-alt .masthead__action .btn-white-border:hover { color: #009BDE !important; }

.masthead--promo-alt .masthead__logo { margin-bottom: 48px; }

@media (max-width: 991px) { .masthead--promo-alt { background: conic-gradient(from 62.36deg at 64.53% 47.25%, #0050C4 0deg, #008CF2 360deg); padding: 5rem 0 24px 0; }
  .masthead--promo-alt .masthead__logo { margin-bottom: 40px; }
  .masthead--promo-alt .masthead__logo img { width: 130px; }
  .masthead--promo-alt .masthead__wrap { padding-top: 0; padding-bottom: 0; }
  .masthead--promo-alt .masthead__content { padding-top: 0; }
  .masthead--promo-alt .masthead__title { font-size: 28px; line-height: 36px; margin-bottom: 40px; }
  .masthead--promo-alt .masthead__title br { display: none; }
  .masthead--promo-alt .masthead__desc { margin-left: 0; margin-right: 0; margin-bottom: 12px; display: none; }
  .masthead--promo-alt .masthead__desc br { display: none; }
  .masthead--promo-alt .masthead__action { margin-bottom: 40px; padding: 0 16px; }
  .masthead--promo-alt .masthead__action a { display: block; width: 100%; margin-bottom: 16px; }
  .masthead--promo-alt .masthead__action a:last-child { margin-bottom: 0; }
  .masthead--promo-alt .masthead__img img { height: auto; } }

.masthead--industry .masthead__content { padding-top: 32px; padding-bottom: 32px; }

.masthead--industry .masthead__desc { margin-bottom: 24px; }

.featured__images { position: relative; max-width: 900px; margin: 0 auto 48px; }

.featured__images .item { position: relative; display: block; position: relative; width: 100%; padding-top: 62%; margin: 0 auto; }

.featured__images .item img, .featured__images .item video { position: absolute; width: 100%; height: 100%; display: block; top: 0; left: 0; object-fit: contain; }

@media (max-width: 991px) { .featured__images { margin-bottom: 12px; }
  .featured__images .item { width: calc(100% - 72px); } }

.featured__thumbs { position: relative; }

.featured__thumbs .owl-nav { display: none; }

.featured__thumbs .nav { display: flex; justify-content: center; align-items: stretch; margin-left: -12px; margin-right: -12px; }

.featured__thumbs .item { display: block; width: 100%; max-width: 25%; flex: 0 0 25%; padding-left: 12px; padding-right: 12px; }

.featured__thumbs .item__title { font-size: 16px; line-height: 24px; margin-bottom: 8px; color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; }

@media (max-width: 991px) { .featured__thumbs { margin-left: 20px; margin-right: 20px; padding-left: 15px; padding-right: 15px; align-items: center; }
  .featured__thumbs .owl-nav { display: block; }
  .featured__thumbs .item { max-width: 100%; flex: 0 0 100%; }
  .featured__thumbs .item:not(.active) { display: none; } }

.featured__thumbs .nav-link { display: flex; position: relative; height: 100%; flex-direction: column; position: relative; padding: 20px; border: 1px solid #E2E2E2; border-radius: 4px; cursor: pointer; min-height: 144px; box-shadow: 0px 0px 0px rgba(0, 0, 0, 0); transition: all .3s ease-in-out; }

.featured__thumbs .nav-link:hover { box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }

.featured__thumbs .nav-link::after { content: ""; display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; border-radius: 4px; border: 2px solid #009BDE; opacity: 0; transition: all .4s ease; }

.featured__thumbs .nav-link.active:after { opacity: 1; }

.featured__slider { position: relative; max-width: 1100px; margin: 0 auto 48px; }

.featured__slider .owl-stage-outer { overflow: visible; }

.featured__slider .owl-stage-outer .owl-item { opacity: 1; transform: scale(1); transition: all .75s ease-in-out; }

.featured__slider .owl-stage-outer .owl-item:not(.active) { opacity: 0; transform: scale(0.5); }

.featured__slider .item { position: relative; display: block; position: relative; width: 100%; padding-top: 62%; margin: 0 auto; }

.featured__slider .item img, .featured__slider .item video { position: absolute; width: 100%; height: 100%; display: block; top: 0; left: 0; object-fit: contain; }

@media (max-width: 768px) { .featured__slider .item { width: 100%; height: 62%; } }

@media (max-width: 375px) { .featured__slider .item { padding-top: 40%; } }

.featured__tab { position: relative; max-width: 1224px; margin: 0 auto -16px; }

.featured__tab .owl-item { padding: 0 12px; }

.featured__tab .owl-item:first-child { padding-left: 0; }

.featured__tab .owl-item:last-child { padding-right: 0; }

.featured__tab .item { position: relative; padding: 20px; border: 1px solid #E2E2E2; border-radius: 4px; cursor: pointer; min-height: 144px; margin-bottom: 16px; box-shadow: 0px 0px 0px rgba(0, 0, 0, 0); transition: all .3s ease-in-out; }

.featured__tab .item:hover { box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }

.featured__tab .item .progress { display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; opacity: 0; }

.featured__tab .item__title { font-family: "Inter", sans-serif; font-weight: 500; display: inline-block; font-size: 16px; line-height: 24px; margin-bottom: 8px; color: #2B2A35; }

.featured__tab .current .item { box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }

.featured__tab .current .item .progress { opacity: 1; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }

.featured__tab .current .item .progress path, .featured__tab .current .item .progress rect { stroke-dasharray: 1000; animation: dash 9s linear; }

.featured--home { padding-bottom: 80px; min-height: 50vh; }

.featured--home .featured__slider { max-width: 900px; }

.featured--home.animate .featured__wrap { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); transition-delay: .75s; }

.featured--home.animate-in .featured__wrap { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.featured--fitur { padding-bottom: 80px; }

.featured--fitur .featured__images { max-width: 808px; }

.featured--fitur .featured__thumbs .item { max-width: 33.333333%; flex: 0 0 33.333333%; }

.featured--fitur .featured__thumbs .item__action { position: relative; z-index: 2; }

@media (max-width: 768px) { .featured--fitur .featured__thumbs .item { max-width: 100%; flex: 0 0 100%; } }

.featured--fitur.animate .featured__wrap { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); transition-delay: .75s; }

.featured--fitur.animate-in .featured__wrap { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.featured--solusi { padding-top: 80px; padding-bottom: 80px; }

.featured--solusi .section-head { padding-bottom: 32px; }

.featured--solusi .featured__slider { max-width: 808px; }

.featured--solusi .featured__tab .item { min-height: 170px; }

.featured--solusi.animate .featured__wrap { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); transition-delay: .75s; }

.featured--solusi.animate-in .featured__wrap { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 1200px) { .featured__slider .item { width: 80%; margin-left: auto; margin-right: auto; }
  .featured__tab { padding: 0 24px; }
  .featured__tab .owl-item { padding: 0 12px; }
  .featured__tab .owl-item:first-child { padding-left: 12px; }
  .featured__tab .owl-item:last-child { padding-right: 12px; }
  .featured .owl-nav .owl-prev { left: -20px; }
  .featured .owl-nav .owl-next { right: -20px; } }

@media (max-width: 375px) { .featured--home, .featured--fitur { padding-bottom: 60px; } }

.reason__slider__wrap { display: flex; height: 100%; align-items: center; justify-content: center; }

.reason__slider .item { display: block; width: 100%; max-width: 624px; position: relative; }

.reason__slider .item .img { width: 100%; height: auto; transform: scale(1); opacity: 1; transition: all .4s ease-in-out; }

.reason__title { margin-bottom: 32px; }

.reason__tab { position: relative; }

.reason__tab ul { position: relative; }

.reason__tab .item { position: relative; display: block; width: 100%; padding-left: 46px; margin-bottom: 32px; opacity: .5; font-family: "Inter", sans-serif; font-weight: 400; transition: all .4s linear; }

.reason__tab .item:before, .reason__tab .item:after { z-index: 2; content: ""; display: block; width: 8px; height: 8px; position: absolute; top: 8px; left: 8px; border-radius: 4px; background: #232933; transform: none; transition: transform .1s ease-in-out, background .2s linear; }

.reason__tab .item:before { z-index: 1; background: #005FBF; width: 20px; height: 20px; border-radius: 10px; top: 2px; left: 2px; opacity: .2; transform: scale(0); }

.reason__tab .item:hover, .reason__tab .item.active { opacity: 1; font-family: "Inter", sans-serif; font-weight: 500; }

.reason__tab .item:hover:before, .reason__tab .item.active:before { transform: scale(1); }

.reason__tab .item:hover:after, .reason__tab .item.active:after { background: #4B61DD; }

.reason__cta { margin-left: -12px; margin-right: -12px; }

.reason__cta .btn { margin: 0 12px; }

.reason--home { padding-top: 80px; padding-bottom: 80px; }

.reason--home.animate .reason__slider { transform: translateX(-100px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); transition-delay: .2s; }

.reason--home.animate .reason__title, .reason--home.animate .reason__tab .item, .reason--home.animate .reason__cta { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.reason--home.animate .reason__title { transition-delay: 1s; }

.reason--home.animate .reason__tab .item:nth-child(1) { transition-delay: 1.25s; }

.reason--home.animate .reason__tab .item:nth-child(2) { transition-delay: 1.5s; }

.reason--home.animate .reason__tab .item:nth-child(3) { transition-delay: 1.75s; }

.reason--home.animate .reason__tab .item:nth-child(4) { transition-delay: 2s; }

.reason--home.animate .reason__tab .item:nth-child(5) { transition-delay: 2.25s; }

.reason--home.animate .reason__cta { transition-delay: 2.5s; }

.reason--home.animate-in .reason__slider, .reason--home.animate-in .reason__title, .reason--home.animate-in .reason__tab .item, .reason--home.animate-in .reason__cta { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 991px) { .reason__cta { margin-left: 0; margin-right: 0; text-align: center; }
  .reason__cta .btn { min-width: 100%; margin: 0; }
  .reason__cta .btn:first-child { margin-bottom: 16px; } }

@media (max-width: 375px) { .reason__slider .item { padding: 0; margin-bottom: 24px; }
  .reason--home { padding: 60px 0; } }

.feature__title { max-width: 600px; margin: 0 auto 16px; text-align: center; }

.feature__content { max-width: 600px; margin: 0 auto 48px; text-align: center; }

.feature__action { display: inline-block; }

.feature .item { margin-bottom: 48px; }

.feature .item__img { position: relative; max-height: 56px; margin-bottom: 8px; }

.feature .item__img .img { display: block; height: 100%; width: auto; }

@media (max-width: 768px) { .feature .item__img { vertical-align: top; display: inline-block; width: 56px; margin-right: 8px; } }

@media (max-width: 768px) { .feature .item__meta { vertical-align: top; display: inline-block; width: calc(100% - 68px); } }

.feature .item__title { font-size: 16px; line-height: 24px; margin: 0 0 12px; }

.feature--home { padding-top: 80px; padding-bottom: 32px; }

.feature--home.animate .feature__title, .feature--home.animate .feature__content p, .feature--home.animate .feature__action, .feature--home.animate .col-lg-3 { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.feature--home.animate-in .feature__title, .feature--home.animate-in .feature__content p, .feature--home.animate-in .feature__action, .feature--home.animate-in .col-lg-3 { transform: translateX(0px) translateY(0) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.feature--home.animate-in .feature__content p { transition-delay: .25s; }

.feature--home.animate-in .feature__action { transition-delay: .5s; }

.feature--home.animate-in .col-lg-3:nth-child(1) { transition-delay: .75s; }

.feature--home.animate-in .col-lg-3:nth-child(2) { transition-delay: 1s; }

.feature--home.animate-in .col-lg-3:nth-child(3) { transition-delay: 1.25s; }

.feature--home.animate-in .col-lg-3:nth-child(4) { transition-delay: 1.5s; }

.feature--home.animate-in .col-lg-3:nth-child(5) { transition-delay: 1.75s; }

.feature--home.animate-in .col-lg-3:nth-child(6) { transition-delay: 2s; }

.feature--home.animate-in .col-lg-3:nth-child(7) { transition-delay: 2.25s; }

.feature--home.animate-in .col-lg-3:nth-child(8) { transition-delay: 2.5s; }

.feature--landing { padding-bottom: 64px; }

.feature--landing .feature__title { max-width: 808px; font-size: 2rem; line-height: 2.5rem; margin-bottom: 3.375rem; }

@media (max-width: 375px) { .feature--home { padding: 40px 0; } }

.marquee-container:after { content: ""; display: block; width: 100%; clear: both; }

.our-customer__title, .case-studies__title { max-width: 650px; margin: 0 auto 16px; text-align: center; }

.our-customer__content, .case-studies__content { max-width: 600px; margin: 0 auto 48px; text-align: center; }

.our-customer__list, .case-studies__list { display: flex; flex-wrap: wrap; margin-bottom: 32px; }

.our-customer__list .item, .case-studies__list .item { display: flex; align-items: center; justify-content: center; width: 100%; max-width: 16.666667%; flex: 0 0 16.666667%; min-height: 64px; padding: 6px; }

.our-customer__list .item img, .case-studies__list .item img { display: block; max-width: 100%; height: auto; }

@media (max-width: 768px) { .our-customer__list .item:nth-child(n+10), .case-studies__list .item:nth-child(n+10) { display: none; } }

.our-customer__slider, .case-studies__slider { margin-bottom: 32px; opacity: 1; transition: all .3s ease-in-out; white-space: nowrap; }

.our-customer__slider .item, .case-studies__slider .item { display: inline-block; vertical-align: middle; position: relative; padding: 0 24px; }

.our-customer__slider .item img, .case-studies__slider .item img { display: block; width: auto; height: auto; max-height: 56px; margin: 0 auto; }

.our-customer__slider .owl-stage-outer, .case-studies__slider .owl-stage-outer { overflow: visible; }

.our-customer__action, .case-studies__action { text-align: center; margin-bottom: 64px; }

.our-customer__action a, .case-studies__action a { margin-right: 16px; }

.our-customer__action a:last-child, .case-studies__action a:last-child { margin-right: 0; }

@media (max-width: 991px) { .our-customer__action a, .case-studies__action a { margin-right: 0; margin-bottom: 16px; width: 100%; }
  .our-customer__action a:last-child, .case-studies__action a:last-child { margin-bottom: 0; } }

.our-customer__cta, .case-studies__cta { text-align: center; margin-bottom: 64px; }

.our-customer__cta a, .case-studies__cta a { margin-right: 16px; }

.our-customer__cta a:last-child, .case-studies__cta a:last-child { margin-right: 0; }

@media (max-width: 991px) { .our-customer__cta a, .case-studies__cta a { margin-right: 0; margin-bottom: 16px; width: 100%; }
  .our-customer__cta a:last-child, .case-studies__cta a:last-child { margin-bottom: 0; } }

.our-customer__toolbar, .case-studies__toolbar { padding-top: 10px; padding-bottom: 56px; position: relative; z-index: 2; }

.our-customer__toolbar .dropdown.bootstrap-select, .case-studies__toolbar .dropdown.bootstrap-select { width: auto; display: inline-block; }

.our-customer__toolbar .dropdown.bootstrap-select .bttn, .case-studies__toolbar .dropdown.bootstrap-select .bttn { width: auto; min-width: 100px; padding: 0 30px 0 12px; font-size: 14px; line-height: 34px; }

.our-customer__toolbar .dropdown.bootstrap-select .bttn:after, .case-studies__toolbar .dropdown.bootstrap-select .bttn:after { top: 0; line-height: 36px; }

.our-customer__toolbar .dropdown.bootstrap-select .dropdown-menu, .case-studies__toolbar .dropdown.bootstrap-select .dropdown-menu { min-width: 220px !important; }

.our-customer__toolbar .dropdown.bootstrap-select .dropdown-menu .inner, .case-studies__toolbar .dropdown.bootstrap-select .dropdown-menu .inner { overflow-x: hidden; }

.our-customer__toolbar .slider-category, .case-studies__toolbar .slider-category { position: relative; overflow: hidden; }

.our-customer__toolbar .slider-category__nav, .case-studies__toolbar .slider-category__nav { z-index: 2; position: absolute; right: 0; top: 0; display: flex; width: 100%; height: 0; }

.our-customer__toolbar .slider-category__wrap, .case-studies__toolbar .slider-category__wrap { z-index: 1; position: relative; white-space: nowrap; display: flex; width: fit-content; }

.our-customer__toolbar .slider-category .item-nav, .case-studies__toolbar .slider-category .item-nav { width: 60px; height: 36px; background: #FFF; background: linear-gradient(90deg, white 70%, rgba(255, 255, 255, 0) 100%); padding: 3px 0; cursor: pointer; }

.our-customer__toolbar .slider-category .item-nav .svg, .case-studies__toolbar .slider-category .item-nav .svg { display: block; }

.our-customer__toolbar .slider-category .item-nav .svg path, .case-studies__toolbar .slider-category .item-nav .svg path { fill: #232933; transition: all .3s ease-in-out; }

.our-customer__toolbar .slider-category .item-nav.prev, .case-studies__toolbar .slider-category .item-nav.prev { display: none; }

.our-customer__toolbar .slider-category .item-nav.next, .case-studies__toolbar .slider-category .item-nav.next { margin-left: auto; background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 40%); }

.our-customer__toolbar .slider-category .item-nav.next .svg, .case-studies__toolbar .slider-category .item-nav.next .svg { margin-left: auto; }

.our-customer__toolbar .slider-category .item-nav:hover .svg path, .case-studies__toolbar .slider-category .item-nav:hover .svg path { fill: #2B2A35; }

.our-customer__toolbar .slider-category .item, .case-studies__toolbar .slider-category .item { display: inline-block; margin: 0 4px; padding: 6px 8px; border-radius: 4px; }

.our-customer__toolbar .slider-category .item__link, .case-studies__toolbar .slider-category .item__link { color: #232933; }

.our-customer__toolbar .slider-category .item__link:hover, .case-studies__toolbar .slider-category .item__link:hover { color: #4B61DD; }

.our-customer__toolbar .slider-category .item.active, .case-studies__toolbar .slider-category .item.active { background: #F2F4F7; }

.our-customer__toolbar .slider-category .item.active .item__link, .case-studies__toolbar .slider-category .item.active .item__link { color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; }

.our-customer__posts, .case-studies__posts { position: relative; }

.our-customer .slider .owl-item > div, .case-studies .slider .owl-item > div { max-width: 100%; }

.our-customer .post, .case-studies .post { margin-bottom: 40px; }

.our-customer .post__img, .case-studies .post__img { position: relative; cursor: pointer; }

.our-customer .post__img .play, .case-studies .post__img .play { position: absolute; z-index: 2; top: calc(50% - 24px); left: calc(50% - 24px); }

.our-customer .post__img .play .icon, .case-studies .post__img .play .icon { width: 48px; height: 48px; display: block; }

.our-customer .post__img .play:after, .case-studies .post__img .play:after { content: ""; display: block; width: 64px; height: 64px; border-radius: 32px; background: #4B61DD; opacity: .2; position: absolute; top: calc(50% - 32px); left: calc(50% - 32px); transform: scale(0.5); transition: all .5s ease-in-out; }

.our-customer .post__img .block-img, .case-studies .post__img .block-img { position: relative; display: block; width: 100%; padding-top: 56.25%; overflow: hidden; border-radius: 4px 4px 0 0; }

.our-customer .post__img .block-img img, .case-studies .post__img .block-img img { position: absolute; display: block; width: 100%; height: 100%; top: 0; left: 0; object-fit: cover; z-index: 1; }

.our-customer .post__img:hover .play:after, .case-studies .post__img:hover .play:after { transform: scale(1); }

.our-customer .post__content, .case-studies .post__content { padding: 24px; border: 1px solid #E2E2E2; border-top: 0; border-radius: 0 0 4px 4px; }

.our-customer .post__title, .case-studies .post__title { font-size: 16px; line-height: 24px; margin-bottom: 4px; }

.our-customer .post__title a, .case-studies .post__title a { color: #2B2A35; }

.our-customer .post__title:hover a, .case-studies .post__title:hover a { color: #4B61DD; }

.our-customer .post__category, .case-studies .post__category { display: block; margin-bottom: 4px; }

.our-customer .post__category a, .case-studies .post__category a { color: #232933; }

.our-customer .post__category:hover a, .case-studies .post__category:hover a { color: #232933; text-decoration: underline; }

.our-customer .post p, .case-studies .post p { display: block; padding-top: 8px; margin-bottom: 16px; }

.our-customer__cta, .case-studies__cta { text-align: center; }

.our-customer--home, .case-studies--home { position: relative; background: #F8FAFC; margin-top: 6vw; padding-bottom: 80px; }

.our-customer--home:before, .case-studies--home:before { content: ""; display: block; width: 100%; padding-top: 10vw; background: url(../images/bg-shape-02.svg) no-repeat top center; background-size: 100% auto; position: absolute; top: -6vw; left: 0; right: 0; z-index: -1; }

.our-customer--m-overview, .case-studies--m-overview { padding-top: 80px; padding-bottom: 80px; }

.our-customer--m-overview .our-customer__slider, .case-studies--m-overview .our-customer__slider { margin-bottom: 0; }

.our-customer--pricing, .case-studies--pricing { padding-top: 80px; padding-bottom: 80px; }

.our-customer--pricing .our-customer__slider, .case-studies--pricing .our-customer__slider { margin-top: 48px; margin-bottom: 0; }

.our-customer--solusi, .case-studies--solusi { padding-top: 80px; padding-bottom: 80px; }

.our-customer--solusi .our-customer__title, .case-studies--solusi .our-customer__title { margin-bottom: 32px; }

.our-customer--solusi .our-customer__slider, .case-studies--solusi .our-customer__slider { margin-bottom: 40px; }

.our-customer.animate .our-customer__title, .our-customer.animate .our-customer__content, .our-customer.animate .our-customer__slider, .our-customer.animate .our-customer__cta, .our-customer.animate .case-studies__toolbar, .our-customer.animate .our-customer__posts .col-md-4, .case-studies.animate .our-customer__title, .case-studies.animate .our-customer__content, .case-studies.animate .our-customer__slider, .case-studies.animate .our-customer__cta, .case-studies.animate .case-studies__toolbar, .case-studies.animate .our-customer__posts .col-md-4 { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.our-customer.animate-in .our-customer__title, .our-customer.animate-in .our-customer__content, .our-customer.animate-in .our-customer__slider, .our-customer.animate-in .our-customer__cta, .our-customer.animate-in .case-studies__toolbar, .our-customer.animate-in .our-customer__posts .col-md-4, .case-studies.animate-in .our-customer__title, .case-studies.animate-in .our-customer__content, .case-studies.animate-in .our-customer__slider, .case-studies.animate-in .our-customer__cta, .case-studies.animate-in .case-studies__toolbar, .case-studies.animate-in .our-customer__posts .col-md-4 { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.our-customer.animate-in .our-customer__content, .case-studies.animate-in .our-customer__content { transition-delay: .25s; }

.our-customer.animate-in .our-customer__slider, .our-customer.animate-in .our-customer__cta, .case-studies.animate-in .our-customer__slider, .case-studies.animate-in .our-customer__cta { transition-delay: .5s; }

.our-customer.animate-in .our-customer__posts .col-md-4:nth-child(1), .case-studies.animate-in .our-customer__posts .col-md-4:nth-child(1) { transition-delay: .75s; }

.our-customer.animate-in .our-customer__posts .col-md-4:nth-child(2), .case-studies.animate-in .our-customer__posts .col-md-4:nth-child(2) { transition-delay: 1s; }

.our-customer.animate-in .our-customer__posts .col-md-4:nth-child(3), .case-studies.animate-in .our-customer__posts .col-md-4:nth-child(3) { transition-delay: 1.25s; }

@media (max-width: 375px) { .our-customer__toolbar .right, .case-studies__toolbar .right { padding-top: 16px; }
  .our-customer--home, .case-studies--home { padding-top: 40px; padding-bottom: 60px; }
  .our-customer--m-overview, .our-customer--pricing, .case-studies--m-overview, .case-studies--pricing { padding-top: 60px; padding-bottom: 60px; } }

.case-studies { padding-bottom: 80px; }

.case-studies .post { margin-bottom: 48px; }

.case-studies .post.animate { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.case-studies .post.animate-in { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.case-studies--related { padding: 80px 0; background: #F8FAFC; }

.case-studies--related .post { margin-bottom: 0; }

.case-studies--related .section-head { position: relative; padding-bottom: 24px; text-align: left; }

.case-studies--related .section-head__title { font-size: 24px; line-height: 32px; margin: 0; padding-right: 220px; }

.case-studies--related .section-head__action { position: absolute; top: 0; right: 30px; }

@media (max-width: 375px) { .case-studies { padding-bottom: 60px; }
  .case-studies .post { margin-bottom: 32px; } }

.get-started { position: relative; text-align: center; padding: 80px 0; background: #002A38; }

.get-started:before { z-index: 1; content: ""; display: block; width: 100%; height: 100%; background: url(../images/patterns-01.png) repeat center; position: absolute; top: 0; left: 0; opacity: .2; }

.get-started__wrap { position: relative; z-index: 2; }

.get-started__title { margin-bottom: 8px; color: #FFF; }

.get-started__content { max-width: 600px; margin: 0 auto 32px; color: #FFF; }

.get-started.animate { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.get-started.animate .get-started__title, .get-started.animate .get-started__content, .get-started.animate .get-started__action { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.get-started.animate-in { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.get-started.animate-in .get-started__title, .get-started.animate-in .get-started__content, .get-started.animate-in .get-started__action { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.get-started.animate-in .get-started__title { transition-delay: .25s; }

.get-started.animate-in .get-started__content { transition-delay: .5s; }

.get-started.animate-in .get-started__action { transition-delay: .75s; }

@media (max-width: 375px) { .get-started { padding: 40px 0; } }

section.desc { padding: 64px 0 32px 0; }

section.desc .container .row { flex-direction: row-reverse; }

@media (max-width: 768px) { section.desc .container .row { flex-direction: normal; } }

section.desc .section-head { text-align: left; }

section.desc .section-head__title { line-height: 40px; }

@media (max-width: 768px) { section.desc .section-head__title { line-height: 30px; } }

section.desc .section-head__action a { margin-right: 16px; }

@media (max-width: 768px) { section.desc .section-head__action a { margin-right: 0; margin-bottom: 16px; width: 100%; } }

.whatis { background: #F8FAFC; position: relative; }

.whatis:before { content: ""; display: block; width: 100%; padding-top: 10vw; background: url(../images/bg-shape-02.svg) no-repeat top center; background-size: 100% auto; position: absolute; top: -6vw; left: 0; right: 0; z-index: -1; }

.whatis__wrap { padding-top: 80px; padding-bottom: 48px; }

.whatis .item { border-radius: 4px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); padding: 32px 0 0; margin-bottom: 32px; background: #FFF; }

@media (max-width: 768px) { .whatis .item { box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); } }

.whatis .item__icon { margin: 0 40px 8px; }

.whatis .item__icon img, .whatis .item__icon .svg { display: block; height: 48px; width: auto; }

.whatis .item__title { font-size: 20px; line-height: 32px; margin: 0 40px 8px; }

.whatis .item__content { margin: 0 40px 32px; }

.whatis .item__img { position: relative; }

.whatis .item__img .img { position: relative; width: 100%; }

.whatis .item__img .img img { width: 100%; height: auto; }

.whatis.animate .section-head__title, .whatis.animate .section-head__content, .whatis.animate .section-head__action, .whatis.animate .item { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.whatis.animate .section-head__content { transition-delay: .25s; }

.whatis.animate .section-head__action { transition-delay: .5s; }

.whatis.animate .item { transition-delay: .25s; }

.whatis.animate-in .section-head__title, .whatis.animate-in .section-head__content, .whatis.animate-in .section-head__action, .whatis.animate-in .item { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 375px) { .whatis__wrap { padding-top: 40px; padding-bottom: 60px; }
  .whatis .item { padding-top: 24px; }
  .whatis .item__icon { margin: 0 20px 8px; }
  .whatis .item__title { margin: 0 20px 8px; }
  .whatis .item__content { margin: 0 20px 24px; }
  .whatis .small-banner__img { display: none; } }

.whymust { background: #F8FAFC; position: relative; }

.whymust * { z-index: 2; }

.whymust:after { content: ""; display: block; width: 100%; padding-top: 10vw; background: url(../images/bg-shape-01.svg) no-repeat top center; background-size: 100% auto; position: absolute; bottom: 0; left: 0; right: 0; z-index: 1; }

.whymust__wrap { padding-top: 80px; padding-bottom: 80px; }

.whymust.animate .section-head__title, .whymust.animate .section-head__content, .whymust.animate .section-head__action, .whymust.animate .table-wm__container { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.whymust.animate .section-head__content { transition-delay: .25s; }

.whymust.animate .section-head__action { transition-delay: .5s; }

.whymust.animate .table-wm__container { transition-delay: .75s; }

.whymust.animate-in .section-head__title, .whymust.animate-in .section-head__content, .whymust.animate-in .section-head__action, .whymust.animate-in .table-wm__container { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 375px) { .whymust__wrap { padding-top: 40px; padding-bottom: 60px; } }

.table-wm { position: relative; display: block; }

.table-wm__row { display: flex; border-radius: 4px; align-items: center; min-height: 80px; }

.table-wm__head { display: block; position: relative; width: 100%; max-width: 34.666667%; flex: 0 0 34.666667%; padding: 16px 24px; font-family: "Inter", sans-serif; font-weight: 500; }

.table-wm__left { display: block; position: relative; width: 100%; max-width: 32%; flex: 0 0 32%; padding: 16px 24px; }

.table-wm__right { display: block; position: relative; width: 100%; max-width: 33.333333%; flex: 0 0 33.333333%; padding: 16px 24px; }

.table-wm__thead { display: block; padding: 0 8px; }

.table-wm__thead th, .table-wm__thead td { padding: 10px 24px; text-align: left; font-size: 14px; line-height: 20px; font-weight: 500; font-family: "Inter", sans-serif; font-weight: 500; }

.table-wm__tbody { display: block; padding: 8px; border-radius: 4px; background: #F2F4F7; }

.table-wm__tbody .table-wm__row:nth-child(odd) { background: #E6EBF1; }

.table-wm__tbody .table-wm__right span { display: block; position: relative; padding-left: 34px; }

.table-wm__tbody .table-wm__right .icon { display: block; position: absolute; left: 0; top: 2px; width: 20px; height: 20px; }

@media (max-width: 375px) { .table-wm__row { flex-wrap: wrap; padding-top: 8px; padding-bottom: 8px; }
  .table-wm__head { width: 100%; max-width: 100%; flex: 0 0 100%; text-align: center; padding: 10px 16px 0; }
  .table-wm__left { max-width: 40%; flex: 0 0 40%; padding: 10px 16px; }
  .table-wm__right { max-width: 60%; flex: 0 0 60%; padding: 10px 16px; }
  .table-wm__thead th, .table-wm__thead td { text-align: center; }
  .table-wm__thead th.table-wm__head { padding-bottom: 0; }
  .table-wm__thead th.table-wm__left { padding-right: 0; }
  .table-wm__thead th, .table-wm__thead td { padding: 10px 16px; }
  .table-wm__thead .table-wm__row { align-items: flex-end; }
  .table-wm__tbody .table-wm__row { align-items: flex-start; }
  .table-wm__tbody .table-wm__right span { padding-left: 20px; }
  .table-wm__tbody .table-wm__right .icon { width: 14px; height: 14px; top: 3px; } }

.accordion { position: relative; margin-bottom: 32px; }

.accordion .card { position: relative; margin-bottom: 4px; }

.accordion .card__header { position: relative; display: block; padding: 20px 56px 20px 20px; cursor: pointer; border-bottom: 1px solid #E2E2E2; transition: all .3s ease-in-out; }

.accordion .card__header:after { content: ""; display: block; width: 24px; height: 24px; background: url(../images/ic-chevron-down.svg) no-repeat center; background-size: contain; position: absolute; top: 20px; right: 20px; transform: rotate(180deg); transition: all .5s ease-in-out; }

.accordion .card__header.collapsed:after { transform: none; }

.accordion .card__title { font-size: 16px; line-height: 24px; display: block; font-family: "Inter", sans-serif; font-weight: 500; margin: 0; padding: 0; }

.accordion .card__body { background: #FFF; border-radius: 4px; box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75); padding: 24px 20px; margin-top: 12px; }

.accordion .card__body .img-sm { display: none; margin-top: 24px; }

@media (max-width: 768px) { .accordion .card__body .img-sm { text-align: center; display: inline-block; width: 100%; height: auto; } }

.accordion .card__action a { margin-right: 14px; }

.accordion .card__action a:last-child { margin-right: 0; }

@media (max-width: 991px) { .accordion .card__action { margin-right: 0; } }

.accordion--faq .card { margin-bottom: 16px; background: #FFF; border-radius: 4px; overflow: hidden; border: 1px solid #E2E2E2; transition: all .5s ease-in-out; }

.accordion--faq .card.open { margin-bottom: 24px; box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }

.accordion--faq .card__header { padding: 28px 80px 12px 24px; border: 0; }

.accordion--faq .card__header:after { top: 28px; right: 16px; background: url(../images/ic-cancel.svg) no-repeat center; background-size: contain; transform: rotate(90deg); }

.accordion--faq .card__header.collapsed { padding-bottom: 28px; }

.accordion--faq .card__header.collapsed .card__title { font-family: "Inter", sans-serif; font-weight: 400; }

.accordion--faq .card__header.collapsed:after { content: ""; background: url(../images/ic-chevron-down.svg) no-repeat center; background-size: contain; transform: rotate(-90deg); }

.accordion--faq .card__body { border-radius: 0; box-shadow: none; padding: 0 80px 52px 24px; margin: 0; }

.accordion--faq .card__body .img-sm { display: none; margin-top: 24px; }

@media (max-width: 768px) { .accordion--faq .card__body .img-sm { text-align: center; display: inline-block; width: 100%; height: auto; } }

.accordion--sm { padding: 0 16px; }

.accordion--sm .card { border-bottom: 1px solid #E2E2E2; }

.accordion--sm .card:first-child { border-top: 1px solid #E2E2E2; }

.accordion--sm .card__header { border-bottom: 0; padding: 12px 30px 12px 12px; }

.accordion--sm .card__header::after { top: calc(50% - 12px); right: 0; }

.accordion--sm .card__header .icon { width: 40px; height: 40px; margin-right: 12px; display: inline-block; vertical-align: middle; }

.accordion--sm .card__title { display: inline-block; width: calc(100% - 57px); vertical-align: middle; }

.accordion--sm .card__body { padding: 0; box-shadow: none; }

.accordion--sm .card__body .post .post__content { padding-left: 0; margin-bottom: 24px; }

.howjurnal__wrap { padding-top: 80px; padding-bottom: 80px; }

.howjurnal__wrap .row { align-items: center; }

@media (max-width: 768px) { .howjurnal__wrap .reverse-sm { flex-direction: column-reverse; } }

.howjurnal__wrap .section-head__action a { margin-right: 15px; }

.howjurnal__wrap .section-head__action a:last-child { margin-right: 0; }

@media (max-width: 768px) { .howjurnal__wrap .section-head__action a { display: block; width: 100%; margin-bottom: 12px; } }

.howjurnal--home .howjurnal__wrap { padding-top: 0; }

.howjurnal__img { transition: all .5s ease-in-out; }

.howjurnal__img img { display: block; width: 100%; height: auto; }

@media (max-width: 768px) { .howjurnal__img { display: none; } }

.howjurnal .accordion .card { position: relative; background: #FFF; border-radius: 4px; border: 2px solid transparent; }

.howjurnal .accordion .card .progress { display: none; }

.howjurnal .accordion .card__body { position: relative; box-shadow: none; background: transparent; padding-top: 0; margin-top: 0; }

.howjurnal .accordion .card.open { border-color: #009BDE; box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75); margin-top: 12px; }

.howjurnal .accordion .card.open .card__header { border-color: transparent; padding-bottom: 12px; }

.howjurnal .accordion .card.open .progress { opacity: 1; }

.howjurnal .accordion .card.open .progress rect { stroke-dasharray: 1500; animation: dash2 8s linear; }

.howjurnal.animate .section-head__title, .howjurnal.animate .section-head__content, .howjurnal.animate .section-head__action, .howjurnal.animate .accordion, .howjurnal.animate .howjurnal__img { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.howjurnal.animate .section-head__content { transition-delay: .25s; }

.howjurnal.animate .section-head__action { transition-delay: .5s; }

.howjurnal.animate .accordion { transition-delay: .75s; }

.howjurnal.animate .howjurnal__img { transition-delay: 1s; }

.howjurnal.animate-in .section-head__title, .howjurnal.animate-in .section-head__content, .howjurnal.animate-in .section-head__action, .howjurnal.animate-in .accordion, .howjurnal.animate-in .howjurnal__img { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 375px) { .howjurnal__wrap { padding-top: 40px; padding-bottom: 60px; } }

.integration-posts { padding-top: 80px; padding-bottom: 80px; }

.integration-posts .section-head { padding-bottom: 40px; }

.integration-posts .section-head__title { margin-bottom: 0; }

.integration-posts .post { display: block; color: #232933; transition: all .3s ease-in-out; }

.integration-posts .post__img { position: relative; }

.integration-posts .post__img .icon { display: block; height: 48px; width: auto; margin-bottom: 8px; }

.integration-posts .post__img .block-img { width: 100%; padding-top: 52.78%; position: relative; }

.integration-posts .post__img .block-img img { width: 100%; height: 100%; display: block; object-fit: cover; position: absolute; top: 0; left: 0; }

.integration-posts .post__title { font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 16px; color: #2B2A35; }

.integration-posts .post__title a { color: #2B2A35; }

.integration-posts .post__title a:hover { color: #4B61DD; }

.integration-posts .post-featured { padding-bottom: 80px; }

.integration-posts .post-partners { padding-bottom: 56px; }

.integration-posts .post-partners__title { font-size: 24px; line-height: 32px; margin-bottom: 24px; }

.integration-posts .post-partners .post { margin-bottom: 24px; }

.integration-posts .post-partners .post__img { border-radius: 4px 4px 0 0; overflow: hidden; }

.integration-posts .post-partners .post__content { border: 1px solid #E2E2E2; border-top: 0; border-radius: 4px; padding: 12px 16px; }

.integration-posts .post-partners .post__title { margin-bottom: 4px; }

.integration-posts .post-partners .post:hover { box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); }

.integration-posts .integration__cta { padding-top: 48px; }

.integration-posts .integration__cta .cta__title { font-size: 24px; line-height: 32px; margin-bottom: 8px; }

.integration-posts .integration__cta.animate { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.integration-posts .integration__cta.animate-in { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.integration-posts .animate .section-head__title, .integration-posts .animate .post-featured .post, .integration-posts .animate .post-partners__title, .integration-posts .animate .post-partners .post { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.integration-posts .animate-in .section-head__title, .integration-posts .animate-in .post-featured .post, .integration-posts .animate-in .post-partners__title, .integration-posts .animate-in .post-partners .post { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 375px) { .integration-posts { padding-top: 40px; padding-bottom: 60px; }
  .integration-posts .post-featured { padding-bottom: 60px; }
  .integration-posts .post-featured .post { text-align: center; }
  .integration-posts .post-featured .post .icon { margin-left: auto; margin-right: auto; }
  .integration-posts .post-featured .post__content { padding: 0 24px; }
  .integration-posts .post-partners { padding-bottom: 36px; }
  .integration-posts .integration__cta { padding-top: 32px; }
  .integration-posts .integration__cta .cta__title { font-size: 18px; line-height: 26px; } }

.categories__title { font-size: 14px; line-height: 20px; color: #777; font-family: "Inter", sans-serif; font-weight: 500; text-transform: uppercase; margin-bottom: 8px; }

.categories__list { margin-bottom: 32px; }

.categories__list li { display: block; width: 100%; padding: 8px 0; font-family: "Inter", sans-serif; font-weight: 500; }

.categories__list li span, .categories__list li a { color: #2B2A35; cursor: pointer; display: inline-block; text-decoration: none; }

.categories__list li span:after, .categories__list li a:after { content: ""; display: inline-block; border-style: solid; height: 0; width: 0; border-color: transparent transparent transparent #1357FF; border-width: 4.5px 0 4.5px 4px; margin-left: 11px; transform: scale(0) translateX(-20px); transition: all .3s ease-in-out; }

.categories__list li span:hover, .categories__list li a:hover { color: #1357FF; }

.categories__list li span.active, .categories__list li a.active { color: #1357FF; text-decoration: none; }

.categories__list li span.active:after, .categories__list li a.active:after { transform: scale(1) translateX(0); }

.categories.animate .categories__title, .categories.animate .categories__list li { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.categories.animate .categories__list li:nth-child(1) { transition-delay: .25s; }

.categories.animate .categories__list li:nth-child(2) { transition-delay: .5s; }

.categories.animate .categories__list li:nth-child(3) { transition-delay: .75s; }

.categories.animate .categories__list li:nth-child(4) { transition-delay: 1s; }

.categories.animate .categories__list li:nth-child(5) { transition-delay: 1.25s; }

.categories.animate .categories__list li:nth-child(6) { transition-delay: 1.5s; }

.categories.animate .categories__list li:nth-child(7) { transition-delay: 1.75s; }

.categories.animate-in .categories__title, .categories.animate-in .categories__list li { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 768px) { .categories { text-align: center; }
  .categories__list li { display: inline-block; width: auto; padding: 8px 12px; }
  .categories__list li span:after, .categories__list li a:after { display: none; } }

.ukm { padding: 80px 0; background: #F8FAFC; }

.ukm--fitur { background: #FFF; }

.ukm--fitur .testimonial-big { margin-bottom: 0; }

.ukm--fitur .testimonial-big__wrap { box-shadow: none; padding-bottom: 30px; }

.ukm--solusi { background: #F9FBFD; }

.ukm--solusi .testimonial-big { margin-bottom: 0; }

.ukm--solusi .testimonial-big__wrap { box-shadow: none; padding-bottom: 30px; background: #F9FBFD; }

.ukm--solusi.top { background: #FFF; }

.ukm--solusi.top .testimonial-big { padding-top: 0; }

.ukm--solusi.top .testimonial-big__wrap { background: #FFF; }

.ukm--solusi.top .testimonial-big__wrap .block-img { padding-top: 100%; }

.ukm.animate .section-head__title, .ukm.animate .section-head__content, .ukm.animate .section-head__action, .ukm.animate .testimonial-big__img, .ukm.animate .testimonial-big__content, .ukm.animate .testimonial__container .col-lg-6 { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.ukm.animate .section-head__content { transition-delay: .25s; }

.ukm.animate .section-head__action { transition-delay: .5s; }

.ukm.animate .testimonial-big__img { transition-delay: .75s; }

.ukm.animate .testimonial-big__content { transition-delay: 1s; }

.ukm.animate .testimonial__container .col-lg-6:nth-child(1) { transition-delay: 1.25s; }

.ukm.animate .testimonial__container .col-lg-6:nth-child(2) { transition-delay: 1.5s; }

.ukm.animate-in .section-head__title, .ukm.animate-in .section-head__content, .ukm.animate-in .section-head__action, .ukm.animate-in .testimonial-big__img, .ukm.animate-in .testimonial-big__content, .ukm.animate-in .testimonial__container .col-lg-6 { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 375px) { .ukm { padding: 40px 0 26px; }
  .ukm .section-head { padding-bottom: 24px; } }

.testimonial-big { padding-top: 80px; margin-bottom: 32px; }

.testimonial-big__wrap { display: flex; background: #FFF; box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.4); border-radius: 4px; padding: 60px 60px 48px 80px; }

.testimonial-big__img { position: relative; display: block; width: 100%; max-width: 36.163%; flex: 0 0 36.163%; }

.testimonial-big__img .block-img { position: relative; display: block; width: 100%; padding-top: 81.632%; }

.testimonial-big__img .img { position: relative; z-index: 1; display: block; width: 100%; height: auto; position: absolute; left: 0; bottom: 0; }

.testimonial-big__img .play { display: block; position: absolute; right: -10%; bottom: -5%; z-index: 2; width: 28.5%; border-radius: 50%; box-shadow: 0 0 0 rgba(0, 95, 191, 0.8); animation: pulse2 3s infinite; }

.testimonial-big__img .play .svg, .testimonial-big__img .play img { width: 100%; height: auto; display: block; }

.testimonial-big__content { padding-left: 90px; }

.testimonial-big__category { font-size: 12px; line-height: 20px; font-family: "Inter", sans-serif; font-weight: 500; text-transform: uppercase; position: relative; padding: 2px 0 2px 28px; margin-bottom: 28px; }

.testimonial-big__category .icon { display: inline-block; height: 24px; width: 24px; position: absolute; left: 0; top: 0; }

.testimonial-big__blockquote { display: block; margin: 0 0 24px; font-size: 20px; line-height: 32px; color: #2B2A35; }

.testimonial-big__footer { position: relative; display: flex; align-items: center; padding-bottom: 24px; }

.testimonial-big__footer img { display: block; max-height: 56px; width: auto; }

.testimonial-big__author { padding: 16px 24px; font-size: 14px; line-height: 20px; }

.testimonial-big__author:not(:first-child) { border-left: 1px solid #E2E2E2; margin-left: 24px; }

.testimonial-big__author span { display: block; }

.testimonial-big__author span + span { margin-top: 4px; }

.testimonial-big__author .author__name { text-transform: uppercase; color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; }

@media (max-width: 768px) { .testimonial-big { padding-top: 40px; }
  .testimonial-big__wrap { padding: 30px 30px 24px 40px; }
  .testimonial-big__content { padding-left: 45px; } }

@media (max-width: 479px) { .testimonial-big__wrap { flex-wrap: wrap; padding: 24px; }
  .testimonial-big__img { max-width: 80%; flex: 0 0 80%; margin-left: auto; margin-right: auto; margin-bottom: 32px; }
  .testimonial-big__content { padding-left: 0; }
  .testimonial-big__blockquote { font-size: 18px; line-height: 26px; }
  .testimonial-big__footer { flex-wrap: wrap; }
  .testimonial-big__author { padding: 0; width: 100%; }
  .testimonial-big__author:not(:first-child) { border-left: 0; margin-top: 24px; margin-left: 0; } }

.testimonial { padding: 0 32px; }

.testimonial__wrap { background: #FFF; border-radius: 4px; box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.4); padding: 32px 0; margin-bottom: 32px; }

.testimonial__wrap .owl-dots { margin: 0; padding: 0 32px; text-align: left; line-height: 8px; }

.testimonial__header { display: flex; padding-bottom: 32px; }

.testimonial__user { width: 100%; max-width: 96px; flex: 0 0 96px; }

.testimonial__user img { display: block; height: 105px; width: auto; }

.testimonial__author { width: 100%; max-width: calc(65% - 96px); flex: 0 0 calc(65% - 96px); font-size: 14px; line-height: 20px; padding-left: 24px; padding-top: 20px; }

.testimonial__author span { display: block; }

.testimonial__author .author__name { text-transform: uppercase; color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; }

.testimonial__author .author__title { margin-top: 4px; }

.testimonial__author .author__logo { max-height: 48px; width: auto !important; margin-top: 14px; }

.testimonial__category { font-size: 12px; line-height: 20px; font-family: "Inter", sans-serif; font-weight: 500; text-transform: uppercase; position: relative; padding: 2px 0 2px 28px; margin-top: 18px; margin-left: auto; margin-bottom: 28px; width: 100%; max-width: 35%; flex: 0 0 35%; }

.testimonial__category .icon { display: inline-block; height: 24px; width: 24px !important; position: absolute; left: 0; top: 0; }

.testimonial__blockquote { margin: 0 0 40px; font-size: 20px; line-height: 32px; }

.testimonial__blockquote:not(:last-child) { margin-bottom: 24px; }

.testimonial--landing { text-align: left; }

.testimonial--landing .small-banner__box { padding: 3rem; }

@media (max-width: 375px) { .testimonial--landing .small-banner__box { padding: 30px 16px; } }

.testimonial--landing .testimonial__data { display: flex; align-items: center; }

.testimonial--landing .testimonial__data .testimonial__img { width: 40px; height: 40px; margin-right: 20px; }

.testimonial--landing .testimonial__data .testimonial__img img { width: 40px; height: 40px; border-radius: 50%; }

.testimonial--landing .testimonial__data .testimonial_name { font-family: "Inter", sans-serif; font-weight: 500; }

.testimonial--landing .testimonial__box blockquote { margin-left: 0; margin-right: 0; font-family: "Inter", sans-serif; font-weight: 500; font-size: 32px; line-height: 40px; }

@media (max-width: 375px) { .testimonial--landing .testimonial__box blockquote { font-size: 16px; line-height: 24px; } }

.testimonial--thumbs { padding: 20px 0; }

.testimonial--thumbs ul.nav.nav-tabs { text-align: center; }

.testimonial--thumbs ul.nav.nav-tabs li.nav-item.item { display: inline-block; margin: 0 30px; cursor: pointer; }

.testimonial--thumbs ul.nav.nav-tabs li.nav-item.item a.nav-link img { opacity: .5; filter: invert(0.5); }

.testimonial--thumbs ul.nav.nav-tabs li.nav-item.item.active a.nav-link.active img { opacity: 1; filter: invert(0); }

@media (max-width: 375px) { .testimonial { padding: 24px; }
  .testimonial__header { flex-wrap: wrap; }
  .testimonial__author { max-width: calc(100% - 96px); flex: 0 0 calc(100% - 96px); }
  .testimonial__category { width: 100%; max-width: 100%; flex: 0 0 100%; margin-left: 0; margin-top: 16px; margin-bottom: 0; }
  .testimonial__blockquote { font-size: 18px; line-height: 26px; } }

.blog { padding: 80px 0; }

.blog .section-head__title { margin-bottom: 0; }

.blog__video { position: relative; margin-bottom: 48px; }

.blog__video .video__cover { position: relative; width: 100%; padding-top: 45%; }

.blog__video .video__cover img { display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; object-fit: cover; }

.blog__video .video__play { display: block; width: 80px; height: 80px; border-radius: 40px; background: url(../images/ic-play-circle.svg) no-repeat center; background-size: contain; position: absolute; top: calc(50% - 40px); left: calc(50% - 40px); box-shadow: 0 0 0 rgba(0, 95, 191, 0.8); animation: pulse2 3s infinite; }

.blog .post { position: relative; padding-bottom: 48px; padding-left: 16px; padding-right: 16px; }

.blog .post__img { position: relative; z-index: 2; }

.blog .post__img .block-img { position: relative; display: block; width: 100%; padding-top: 66.666667%; overflow: hidden; border-radius: 4px; margin-bottom: 20px; }

.blog .post__img .block-img img { display: block; width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0; }

.blog .post__title { position: relative; z-index: 2; font-size: 20px; line-height: 32px; margin-bottom: 12px; font-family: "Inter", sans-serif; font-weight: 500; padding-left: 30px; }

.blog .post__title .icon { display: block; position: absolute; top: 3px; left: 0; }

.blog .post__content { position: relative; z-index: 2; color: #2B2A35; margin-bottom: 20px; }

.blog .post__meta { margin-bottom: 12px; }

.blog .post__meta span { display: inline-block; }

.blog .post__meta .name { font-family: "Inter", sans-serif; font-weight: 500; color: #2B2A35; margin-right: 6px; font-size: 14px; line-height: 20px; }

.blog .post__meta .name:after { content: ","; }

.blog .post__logo img { max-height: 56px; width: auto; display: block; }

.blog .post__footer { position: relative; z-index: 2; }

.blog .post .link-more { position: absolute; z-index: 4; left: 16px; bottom: 20px; opacity: 0; transition: all .3s ease-in-out; }

.blog .post:before { content: ""; z-index: 1; display: block; width: calc(100% + 32px); height: calc(100% + 48px); position: absolute; top: -24px; left: -16px; background: #FFF; box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25); border-radius: 4px; opacity: 0; transform: scale(0.9); transition: all .3s ease-in-out; }

.blog .post:hover { z-index: 3; }

.blog .post:hover .link-more { bottom: 0; opacity: 1; }

.blog .post:hover:before { opacity: 1; transform: none; }

.blog .post.animate { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.blog .post.animate-in { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.blog.animate .section-head__title, .blog.animate .section-head__content, .blog.animate .section-head__action, .blog.animate .blog__video { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.blog.animate .section-head__content { transition-delay: .25s; }

.blog.animate .section-head__action { transition-delay: .5s; }

.blog.animate .blog__video { transition-delay: .75s; }

.blog.animate-in .section-head__title, .blog.animate-in .section-head__content, .blog.animate-in .section-head__action, .blog.animate-in .blog__video { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 375px) { .blog { padding: 40px 0; }
  .blog .section-head__title:last-child { margin-bottom: 16px; } }

.allinone, .advantage { padding: 80px 0; }

.allinone .post-featured, .advantage .post-featured { position: relative; }

.allinone--landing .post, .advantage--landing .post { text-align: center; }

.allinone--landing .post__img .icon, .advantage--landing .post__img .icon { display: inline-block !important; }

.allinone--landing .post__content, .advantage--landing .post__content { padding-right: 0 !important; }

.allinone .post, .advantage .post { display: block; color: #232933; transition: all .3s ease-in-out; }

@media (max-width: 768px) { .allinone .post, .advantage .post { margin-bottom: 40px; text-align: left; } }

.allinone .post__img, .advantage .post__img { position: relative; }

.allinone .post__img .icon, .advantage .post__img .icon { display: block; height: 48px; width: auto; margin-bottom: 8px; }

.allinone .post__title, .advantage .post__title { font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 16px; color: #2B2A35; }

.allinone .post__title a, .advantage .post__title a { color: #2B2A35; }

.allinone .post__title a:hover, .advantage .post__title a:hover { color: #4B61DD; }

.allinone .post__content, .advantage .post__content { padding-right: 40px; }

.allinone--form-alt .post__img, .advantage--form-alt .post__img { display: inline-block; width: 40px; margin-right: 16px; vertical-align: top; }

.allinone--form-alt .post__content, .advantage--form-alt .post__content { display: inline-block; width: calc(100% - 61px); }

.allinone.animate .section-head__title, .allinone.animate .section-head__content, .allinone.animate .post-featured .row .col-md-4, .advantage.animate .section-head__title, .advantage.animate .section-head__content, .advantage.animate .post-featured .row .col-md-4 { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.allinone.animate .post-featured .row .col-md-4:nth-child(1), .advantage.animate .post-featured .row .col-md-4:nth-child(1) { transition-delay: .25s; }

.allinone.animate .post-featured .row .col-md-4:nth-child(2), .advantage.animate .post-featured .row .col-md-4:nth-child(2) { transition-delay: .5s; }

.allinone.animate .post-featured .row .col-md-4:nth-child(3), .advantage.animate .post-featured .row .col-md-4:nth-child(3) { transition-delay: .75s; }

.allinone.animate-in .section-head__title, .allinone.animate-in .section-head__content, .allinone.animate-in .post-featured .row .col-md-4, .advantage.animate-in .section-head__title, .advantage.animate-in .section-head__content, .advantage.animate-in .post-featured .row .col-md-4 { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 768px) { .allinone .post__content, .advantage .post__content { padding-right: 0; } }

@media (max-width: 375px) { .allinone, .advantage { padding: 40px 0 40px; } }

.features { padding: 80px 0 32px; }

.features .post { display: block; color: #232933; transition: all .3s ease-in-out; margin-bottom: 48px; }

.features .post__img { display: block; position: relative; margin-bottom: 16px; }

.features .post__img .block-img { width: 100%; padding-top: 60.2%; position: relative; border: 1px solid #E2E2E2; border-radius: 6px; box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.25); overflow: hidden; }

.features .post__img .block-img img { width: 100%; height: 100%; display: block; object-fit: cover; position: absolute; top: 0; left: 0; }

.features .post__title { font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 8px; color: #2B2A35; }

.features .post__title a { color: #2B2A35; }

.features .post__title a:hover { color: #4B61DD; }

.features .post__content { padding-left: 32px; position: relative; }

.features .post__content .icon { position: absolute; top: 0; left: 0; width: 24px; height: auto; }

.features .post.animate { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.features .post.animate-in { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.features.animate .section-head__subtitle, .features.animate .section-head__title, .features.animate .section-head__desc { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.features.animate-in .section-head__subtitle, .features.animate-in .section-head__title, .features.animate-in .section-head__desc { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 375px) { .features { padding: 40px 0 24px; } }

.fitur-blocks .fitur-block:nth-child(even) .row { flex-direction: row-reverse; }

.fitur-blocks--solusi { padding-top: 80px; }

.fitur-blocks--solusi .section-head { padding-bottom: 40px; }

.fitur-blocks--alt .fitur-block { padding: 128px 0 0; }

.fitur-blocks--alt .fitur-block:last-child { padding-bottom: 64px; }

@media (max-width: 768px) { .fitur-blocks--alt .fitur-block { padding: 64px 0; } }

.fitur-blocks.animate .section-head__title, .fitur-blocks.animate .section-head__content, .fitur-blocks.animate .section-head__desc { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.fitur-blocks.animate-in .section-head__title, .fitur-blocks.animate-in .section-head__content, .fitur-blocks.animate-in .section-head__desc { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.fitur-block { padding: 128px 0 0; }

.fitur-block:last-child { padding-bottom: 64px; }

.fitur-block .row { align-items: center; }

.fitur-block__img { position: relative; }

.fitur-block__img .block-img { position: relative; width: 100%; padding-top: 83.333333%; }

.fitur-block__img .block-img img { display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; object-fit: cover; }

.fitur-block__title { font-size: 32px; line-height: 48px; margin-bottom: 16px; }

.fitur-block__content p { display: block; margin-bottom: 32px; }

.fitur-block__action a { margin-right: 12px; }

.fitur-block__action a:last-child { margin-right: 0; }

.fitur-block__list { position: relative; margin-bottom: 32px; }

.fitur-block__list li { position: relative; display: block; padding-left: 36px; margin-bottom: 16px; }

.fitur-block__list li:before { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-check.svg) no-repeat center; background-size: contain; position: absolute; top: 0; left: 0; }

.fitur-block__list li:last-child { margin-bottom: 0; }

.fitur-block__list .feature__action a { margin-right: 12px; margin-bottom: 12px; }

@media (max-width: 375px) { .fitur-block__list .feature__action a { margin-right: 0; } }

.fitur-block.animate .fitur-block__img, .fitur-block.animate .fitur-block__title, .fitur-block.animate .fitur-block__content p, .fitur-block.animate .fitur-block__content li, .fitur-block.animate .feature__action, .fitur-block.animate .btn { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.fitur-block.animate .fitur-block__img { transition-delay: .25s; }

.fitur-block.animate .fitur-block__title { transition-delay: .5s; }

.fitur-block.animate .fitur-block__content p { transition-delay: .75s; }

.fitur-block.animate .fitur-block__content li { transition-delay: 1s; }

.fitur-block.animate .feature__action, .fitur-block.animate .btn { transition-delay: 1.25s; }

.fitur-block.animate-in .fitur-block__img, .fitur-block.animate-in .fitur-block__title, .fitur-block.animate-in .fitur-block__content p, .fitur-block.animate-in .fitur-block__content li, .fitur-block.animate-in .feature__action, .fitur-block.animate-in .btn { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 375px) { .fitur-block { padding: 48px 0; }
  .fitur-block__img { margin-bottom: 16px; } }

.coa { padding: 80px 0 64px; }

.coa__img { position: relative; }

.coa__img .block-img { position: relative; width: 100%; padding-top: 62%; }

.coa__img .block-img img { display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; object-fit: cover; }

.coa.animate .section-head__title, .coa.animate .section-head__content, .coa.animate .coa__img { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.coa.animate .section-head__content { transition-delay: .25s; }

.coa.animate .coa__img { transition-delay: .5s; }

.coa.animate-in .section-head__title, .coa.animate-in .section-head__content, .coa.animate-in .coa__img { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.benefits { padding: 80px 0 64px; background: #F9FBFD; }

.benefits__list { position: relative; margin-bottom: 24px; column-count: 2; }

.benefits__list:last-child { margin-bottom: 0; }

.benefits__list.alt { column-count: 1; padding-left: 24px; }

.benefits__list li { position: relative; display: block; padding-left: 36px; margin-bottom: 16px; padding-right: 24px; }

.benefits__list li:last-child { margin-bottom: 0; }

.benefits__list li:before { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-check.svg) no-repeat center; background-size: contain; position: absolute; top: 0; left: 0; }

.benefits.animate .section-head__title, .benefits.animate .section-head__content, .benefits.animate .benefits--promo_wrap, .benefits.animate .benefits__list { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.benefits.animate .section-head__content { transition-delay: .25s; }

.benefits.animate .benefits__list { transition-delay: .5s; }

.benefits.animate-in .section-head__title, .benefits.animate-in .section-head__content, .benefits.animate-in .benefits--promo_wrap, .benefits.animate-in .benefits__list { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.benefits--pricing .benefits__list { column-count: 1; display: flex; flex-wrap: wrap; margin: 0; }

.benefits--pricing .benefits__list li { display: block; width: 100%; max-width: 33.333333%; flex: 0 0 33.333333%; }

@media (max-width: 375px) { .benefits { padding: 40px 0 24px; }
  .benefits .benefits__list { column-count: 1; display: flex; flex-wrap: wrap; margin: 0; }
  .benefits .benefits__list li { display: block; width: 100%; max-width: 100%; flex: 0 0 100%; }
  .benefits .benefits__list.alt li { max-width: 100%; flex: 0 0 100%; } }

.benefits--promo { background: #FFF; }

.benefits--promo.lg { padding-top: 0px; }

.benefits--promo.sm { padding-top: 24px; }

.benefits--promo.sm .benefits--promo_slider .item { display: flex; padding: 16px; align-items: center; }

.benefits--promo.sm .benefits--promo_slider .item_img { min-width: 80px; margin-right: 16px; }

.benefits--promo.sm .benefits--promo_slider .item_img img { margin-bottom: 0; }

@media (max-width: 768px) { .benefits--promo.sm .benefits--promo_slider .item { display: block; }
  .benefits--promo.sm .benefits--promo_slider .item_img { display: block; margin: 0 auto 8px; }
  .benefits--promo.sm .benefits--promo_slider .item_meta { display: block; } }

.benefits--promo_wrap { background: white; }

@media (max-width: 768px) { .benefits--promo_wrap { background: #f2f4f7; } }

.benefits--promo_list { padding: 56px 0 64px 0; position: relative; }

.benefits--promo_list.blue::before { content: ''; width: 80%; position: absolute; height: 100%; top: 0; left: calc(50% - (80% / 2)); background: radial-gradient(103.57% 220.66% at 45.46% 48.06%, #81B8D4 0%, #007DE5 39.03%, #004DC2 100%); border-radius: 24px; z-index: 1; }

@media (max-width: 768px) { .benefits--promo_list.blue::before { width: calc(100% + 24px); left: -12px; border-radius: 0; } }

.benefits--promo_list.green::before { content: ''; width: 80%; position: absolute; height: 100%; top: 0; left: calc(50% - (80% / 2)); background: radial-gradient(103.57% 220.66% at 45.46% 48.06%, #A8CE4D 0%, #83C838 39.03%, #499C21 100%); border-radius: 24px; z-index: 1; }

@media (max-width: 768px) { .benefits--promo_list.green::before { width: calc(100% + 24px); left: -12px; border-radius: 0; } }

.benefits--promo_slider { display: flex; position: relative; z-index: 2; }

.benefits--promo_slider .item { width: calc(100% / 3); background: #FFF; border-radius: 8px; margin: 5px 12px; padding: 32px; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); }

.benefits--promo_slider .item img { margin-bottom: 16px; }

.benefits--promo_slider .item h4 { font-size: 20px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 400; }

.benefits--promo_slider .item p { font-size: 16px; line-height: 24px; color: #545465; }

.benefits--promo_slider .item:first-child { margin-left: 0; }

.benefits--promo_slider .item:last-child { margin-right: 0; }

@media (max-width: 768px) { .benefits--promo_slider .item { width: 100%; display: block; border: 1px solid rgba(119, 119, 119, 0.1); box-shadow: none; text-align: center; }
  .benefits--promo_slider .item img { width: 100px; margin: 0 auto 16px; } }

.benefits--promo_badge { width: 100%; left: 0; top: -24px; position: absolute; text-align: center; z-index: 2; }

.benefits--promo_badge span { border-radius: 8px; color: #FFF; padding: 8px 24px; display: inline-block; font-size: 20px; line-height: 32px; background: #FFFFFF; border: 1px solid #F2F4F7; box-shadow: 0px 12px 12px rgba(0, 95, 191, 0.08), 0px 16px 24px rgba(0, 95, 191, 0.08); border-radius: 100px; color: #4B61DD; font-family: "Inter", sans-serif; font-weight: 500; }

@media (max-width: 768px) { .benefits--promo_badge span { font-size: 16px; line-height: 20px; } }

.faq-block { padding: 64px 0; background: #F9FBFD; }

.faq-block .card__body ul { padding-left: 20px; list-style: disc; }

.faq-block .card__body ul li { margin-bottom: 4px; }

.faq-block .section-head__action { padding-top: 16px; }

.faq-block .section-head__action a { margin-right: 16px; }

.faq-block .section-head__action a:last-child { margin-right: 0; }

.faq-block--pricing { background: transparent; }

.faq-block.animate .section-head__title, .faq-block.animate .section-head__content, .faq-block.animate .section-head__action, .faq-block.animate .accordion--faq { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.faq-block.animate .section-head__content { transition-delay: .25s; }

.faq-block.animate .section-head__action { transition-delay: .5s; }

.faq-block.animate .accordion--faq { transition-delay: .75s; }

.faq-block.animate-in .section-head__title, .faq-block.animate-in .section-head__content, .faq-block.animate-in .section-head__action, .faq-block.animate-in .accordion--faq { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 991px) { .faq-block { padding: 48px 8px; }
  .faq-block .section-head__action a { margin-right: 0; margin-bottom: 16px; }
  .faq-block .section-head__action a:last-child { margin-bottom: 0; } }

.sidebar__wrap { position: relative; }

.sidebar__data { padding: 24px 0; border-bottom: 1px solid rgba(255, 255, 255, 0.2); display: flex; flex-wrap: wrap; }

.sidebar__data h4, .sidebar__data span { display: block; width: 100%; max-width: 50%; flex: 0 0 50%; margin: 0; }

.sidebar__data span { margin-left: auto; text-align: right; }

.sidebar__data p { display: block; width: 100%; margin: 8px 0 0; }

.cs-detail { padding-bottom: 80px; }

.cs-detail .block-append { background: #F9FBFD; border-radius: 4px; padding: 30px 40px; margin-bottom: 64px; }

.cs-detail .block-append h3 { position: relative; font-size: 32px; line-height: 48px; margin: 0; }

.cs-detail .block-append h3 small { color: #4B61DD; display: inline-block; vertical-align: top; font-size: 20px; line-height: 32px; margin: 0 4px; position: relative; top: -2px; }

.cs-detail .block-append p { font-size: 14px; line-height: 20px; }

.cs-detail .block-append .row { margin: 0 -30px; }

.cs-detail .block-append .item { padding: 10px 30px; position: relative; }

.cs-detail .block-append .item:not(:last-child):after { content: ""; display: block; height: calc(100% - 28px); width: 1px; background: #E2E2E2; position: absolute; right: 0; top: 14px; }

.cs-detail article { color: #232933; }

.cs-detail article .text-category { display: block; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 400; margin-bottom: 4px; }

.cs-detail article h1 { display: block; font-size: 48px; line-height: 56px; font-family: "Inter", sans-serif; font-weight: 600; color: #2B2A35; margin-bottom: 16px; }

.cs-detail article h2 { display: block; font-size: 32px; line-height: 48px; font-family: "Inter", sans-serif; font-weight: 500; color: #2B2A35; margin-bottom: 16px; }

.cs-detail article h3 { display: block; font-size: 20px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; color: #2B2A35; margin-bottom: 8px; }

.cs-detail article p { margin-bottom: 40px; }

.cs-detail article p:last-child { margin-bottom: 0; }

.cs-detail__sidebar .sidebar { margin-top: -200px; }

.cs-detail__sidebar .sidebar__wrap { background: #009BDE; border-radius: 8px; padding: 0 24px; color: #FFF; }

.cs-detail__sidebar .sidebar__data span { font-family: "Inter", sans-serif; font-weight: 500; }

.cs-detail.animate { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.cs-detail.animate-in { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 768px) { .cs-detail__sidebar .sidebar { margin-top: 48px; } }

.pricing, .pricing-alt { padding-bottom: 24px; }

.pricing .section-head, .pricing-alt .section-head { padding: 40px 0; }

.pricing .section-head__title, .pricing-alt .section-head__title { font-size: 24px; line-height: 32px; margin-bottom: 0; }

.pricing__wrap .row, .pricing-alt__wrap .row { flex-direction: row-reverse; }

.pricing__switcher, .pricing-alt__switcher { background: #F2F4F7; border-radius: 100px; display: inline-block; padding: 8px 4px; margin-bottom: 3rem; }

.pricing__switcher a, .pricing-alt__switcher a { font-size: 1rem; line-height: 1.5rem; padding: 8px 29px; color: #545465; }

.pricing__switcher a:hover, .pricing-alt__switcher a:hover { text-decoration: none; }

.pricing__switcher a.active, .pricing-alt__switcher a.active { background: #FFF; color: #4B61DD; border-radius: 100px; box-shadow: 0px 4px 12px rgba(50, 50, 71, 0.08), 0px 16px 24px rgba(50, 50, 71, 0.08); }

.pricing__switcher a.active .item__head .item__desc .big, .pricing-alt__switcher a.active .item__head .item__desc .big { font-size: 24px; line-height: 32px; }

.pricing__cta, .pricing-alt__cta { padding-top: 24px; text-align: center; }

.pricing .col-md-6, .pricing-alt .col-md-6 { padding-bottom: 24px; }

.pricing .item, .pricing-alt .item { background: #FFF; border: 1px solid #E2E2E2; border-radius: 4px; display: flex; flex-direction: column; height: 100%; }

.pricing .item__head, .pricing-alt .item__head { position: relative; width: 100%; padding: 32px 24px; background: #F2F4F7; border-bottom: 1px solid #E2E2E2; display: flex; flex-direction: column; }

.pricing .item__head span.big, .pricing-alt .item__head span.big { display: block; color: #2B2A35; font-size: 28px; line-height: 48px; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 12px; }

.pricing .item__title, .pricing-alt .item__title { display: block; position: relative; width: 100%; font-size: 1.5rem; line-height: 2rem; padding-bottom: 24px; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing .item__title span.badge, .pricing-alt .item__title span.badge { display: inline-block; position: relative; top: -1px; background: #FFF; border-radius: 4px; color: #4B61DD; font-size: 0.75rem; line-height: 1.25rem; padding: 0 8px; margin: 0 0 0 7px; vertical-align: middle; }

.pricing .item__title:after, .pricing-alt .item__title:after { content: ""; display: block; width: 100%; border-top: 1px solid #E2E2E2; position: absolute; bottom: 0; left: 0; }

.pricing .item__desc, .pricing-alt .item__desc { width: 100%; min-height: 132px; }

.pricing .item__desc.tx-md, .pricing-alt .item__desc.tx-md { color: #2B2A35; font-size: 24px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing .item__cta, .pricing-alt .item__cta { width: 100%; margin-top: auto; padding-top: 32px; }

.pricing .item__cta .btn, .pricing-alt .item__cta .btn { width: 100%; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing .item__cta .btn + .btn, .pricing-alt .item__cta .btn + .btn { margin-top: 12px; }

.pricing .item__body, .pricing-alt .item__body { width: 100%; padding: 32px 24px; }

.pricing .item__body .bonus, .pricing-alt .item__body .bonus { border-radius: 4px; padding: 12px; margin-bottom: 32px; }

@media (max-width: 768px) { .pricing .item__body, .pricing-alt .item__body { display: none; }
  .pricing .item__body.show, .pricing-alt .item__body.show { display: block; } }

.pricing .item__mobile, .pricing-alt .item__mobile { display: none; text-align: center; padding: 20px 45px 36px; }

.pricing .item__mobile::after, .pricing-alt .item__mobile::after { content: ''; display: inline-block; width: 24px; height: 24px; position: absolute; bottom: 40px; left: calc(50% - 12px); background: url(../images/ic-arrow-down-blue.svg) no-repeat center; }

@media (max-width: 768px) { .pricing .item__mobile, .pricing-alt .item__mobile { display: block; }
  .pricing .item__mobile.hide, .pricing-alt .item__mobile.hide { display: none; } }

.pricing .item .pricing-list, .pricing-alt .item .pricing-list { padding-bottom: 20px; }

.pricing .item .pricing-list__title, .pricing-alt .item .pricing-list__title { font-size: 0.875rem; line-height: 1.25rem; display: block; font-family: "Inter", sans-serif; font-weight: 500; text-transform: uppercase; margin-bottom: 8px; }

.pricing .item .pricing-list__label, .pricing-alt .item .pricing-list__label { font-size: 1rem; line-height: 1.5rem; display: block; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 1.5rem; }

.pricing .item .pricing-list__item, .pricing-alt .item .pricing-list__item { display: block; position: relative; margin-bottom: 12px; padding-left: 32px; }

.pricing .item .pricing-list__item:before, .pricing-alt .item .pricing-list__item:before { content: ""; display: block; position: absolute; width: 16px; height: 16px; left: 0; top: 4px; background: transparent url(../images/ic-check.svg) no-repeat center; background-size: contain; }

.pricing .item .pricing-list__item .tooltips, .pricing-alt .item .pricing-list__item .tooltips { position: absolute; right: -15px; top: 1px; }

.pricing .item.active, .pricing-alt .item.active { border: 2px solid #4B61DD; box-shadow: 0px 4px 16px rgba(180, 188, 208, 0.75); }

.pricing .item.active .item__head, .pricing-alt .item.active .item__head { background: #4B61DD; color: #FFF; }

.pricing .item.active .item__head span.big, .pricing-alt .item.active .item__head span.big { color: #FFF; font-size: 24px; line-height: 32px; }

.pricing .item.active .item__desc.tx-md, .pricing-alt .item.active .item__desc.tx-md { color: #FFF; }

.pricing .item.active .item__title, .pricing-alt .item.active .item__title { color: #FFF; }

.pricing .item.active .item__cta .btn-white:hover, .pricing-alt .item.active .item__cta .btn-white:hover { border-color: #FFF; }

.pricing .item.active .item__cta .btn-white-border, .pricing-alt .item.active .item__cta .btn-white-border { background: transparent; color: #FFF; }

.pricing .item.active .item__cta .btn-white-border:hover, .pricing-alt .item.active .item__cta .btn-white-border:hover { color: #4B61DD; background: #FFF; }

.pricing.animate, .pricing-alt.animate { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.pricing.animate-in, .pricing-alt.animate-in { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

.pricing-alt .item__head { background: #FFF; display: block; height: auto; border: 0; padding-bottom: 0; }

.pricing-alt .item__icon { display: block; margin-bottom: 12px; }

.pricing-alt .item__icon img { display: block; height: 40px; width: auto; }

.pricing-alt .item__title { font-size: 20px; line-height: 32px; min-height: 88px; }

.pricing-alt .item__desc span.big { font-size: 20px; line-height: 32px; }

.pricing-alt .item__body { display: flex; flex-direction: column; height: 100%; }

.pricing-alt .item__body .item__cta { width: 100%; }

.pricing-ao .section-head { padding: 40px 0; }

.pricing-ao .section-head__title { font-size: 24px; line-height: 32px; margin-bottom: 0; }

.pricing-ao .item { background: #FFF; border: 1px solid #E2E2E2; padding: 32px 0; border-radius: 4px; }

.pricing-ao .item__left { padding: 0 32px; }

.pricing-ao .item__right { padding: 32px 32px 0 0; }

.pricing-ao .item__icon img { display: block; height: 40px; width: auto; margin: 0 0 12px; }

.pricing-ao .item__title { font-size: 20px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 24px; }

.pricing-ao .item__desc { color: #2B2A35; }

.pricing-ao .item small { display: block; font-size: 12px; line-height: 18px; color: #777; margin-bottom: 0; }

.pricing-ao .item span.big { display: block; margin-bottom: 16px; color: #2B2A35; font-size: 24px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-ao .item .pricing-list { color: #232933; }

.pricing-ao .item .pricing-list__title { font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; text-transform: uppercase; margin-bottom: 12px; }

.pricing-ao .item .pricing-list__item { display: block; position: relative; margin-bottom: 12px; padding-left: 32px; }

.pricing-ao .item .pricing-list__item:last-child { margin-bottom: 0; }

.pricing-ao .item .pricing-list__item:before { content: ""; display: block; position: absolute; width: 16px; height: 16px; left: 0; top: 4px; background: transparent url(../images/ic-check.svg) no-repeat center; background-size: contain; }

.pricing-ao__cta { text-align: right; }

.pricing-detail { display: block; width: 100%; border-color: #E2E2E2; border-style: solid; border-width: 1px 0 1px 1px; }

.pricing-detail h3 { display: block; margin-bottom: 12px; font-size: 24px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-detail h4 { display: block; margin-bottom: 0; color: #2B2A35; font-size: 20px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-detail span.check { display: inline-block; width: 16px; height: 16px; background: transparent url(../images/ic-check.svg) no-repeat center; background-size: contain; line-height: 0; margin: 4px; }

.pricing-detail .pd-tr, .pricing-detail .pd-title, .pricing-detail .pd-cta { display: flex; position: relative; width: 100%; }

.pricing-detail .pd-th, .pricing-detail .pd-td { width: 100%; max-width: 18%; flex: 0 0 18%; border-color: #E2E2E2; border-style: solid; text-align: center; }

.pricing-detail .pd-th:first-child, .pricing-detail .pd-td:first-child { max-width: 28%; flex: 0 0 28%; text-align: left; }

.pricing-detail .pd-th { padding: 48px 24px 36px; border-width: 0 1px 1px 0; }

.pricing-detail .pd-td { padding: 12px 24px; border-width: 0 1px 0 0; display: flex; align-items: center; justify-content: center; }

.pricing-detail .pd-td:first-child { justify-content: flex-start; }

.pricing-detail .pd-td.sr { padding-top: 20px; }

.pricing-detail .pd-cta .pd-td { padding: 24px; }

.pricing-detail__head { display: block; width: 100%; position: -webkit-sticky; position: sticky; z-index: 2; top: 0; background: #FFF; }

.pricing-detail__body { position: relative; z-index: 1; overflow: auto; width: auto; }

.pricing-detail__body div.pd-tr:nth-of-type(odd) { background: #FBFBFB; background: #efefef; }

.pricing-detail__footer { display: block; width: 100%; position: -webkit-sticky; position: sticky; z-index: 2; bottom: 0; background: #FFF; }

.pricing-detail__footer .btn { padding-left: 10px; padding-right: 10px; }

.pricing-detail-sm { padding: 12px 12px 0; }

.pricing-detail-sm .nav { display: flex; border-right: 1px solid #E2E2E2; }

.pricing-detail-sm .nav-item { display: block; width: 100%; max-width: 25%; flex: 0 0 25%; }

.pricing-detail-sm .nav-item .nav-link { display: block; text-align: center; padding: 12px 24px; border-width: 1px 0 1px 1px; border-style: solid; border-color: #E2E2E2; color: #2B2A35; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-detail-sm .nav-item .nav-link.active { background: #f2f2f2; }

.pricing-detail-sm .pdsm__head { display: flex; width: calc(100% - 48px); margin: 0 auto; }

.pricing-detail-sm .pdsm__body { padding-top: 24px; }

.pricing-detail-sm .pdsm__content h4 { color: #2B2A35; font-size: 18px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-detail-sm .pdsm__cta { display: block; width: 100%; position: -webkit-sticky; position: sticky; z-index: 2; bottom: 0; padding: 16px; text-align: center; background: #FFF; border-top: 1px solid #E2E2E2; }

.pricing-detail-sm .pdsm__list { display: block; width: 100%; margin-bottom: 24px; }

.pricing-detail-sm .pdsm__list li { display: block; width: 100%; padding: 8px 12px; margin: 0; }

.pricing-detail-sm .pdsm__list li:nth-child(odd) { background: #FBFBFB; }

@media (max-width: 375px) { .pricing-detail-sm .nav { border: 1px solid #E2E2E2; position: relative; padding-top: 49px; }
  .pricing-detail-sm .nav:after { content: ""; display: block; width: 28px; height: 28px; background: url(../images/ic-dropdown.svg) no-repeat center; background-size: contain; position: absolute; right: 16px; top: 10px; }
  .pricing-detail-sm .nav-item { flex: 0 0 100%; max-width: 100%; }
  .pricing-detail-sm .nav-item .nav-link { border-top: 0; max-height: 0; opacity: 0; visibility: hidden; padding: 0 24px; border: 0; transition: all .4s ease-in-out; text-align: left; }
  .pricing-detail-sm .nav-item .nav-link.active { position: absolute; top: 0; left: 0; width: 100%; max-height: 50px; visibility: visible; padding: 12px 24px; opacity: 1; padding-right: 30px; background: #f2f2f2; }
  .pricing-detail-sm .nav.expand .nav-item .nav-link.active { border-bottom: 1px solid #E2E2E2; }
  .pricing-detail-sm .nav.expand .nav-item .nav-link:not(.active) { max-height: 50px; opacity: 1; padding: 12px 24px; border-bottom: 1px solid #E2E2E2; visibility: visible; }
  .pricing-detail-sm .pdsm__head { width: 100%; flex-wrap: wrap; } }

.modal { width: 100%; }

.modal--referral .modal-dialog { max-width: 1224px; padding: 0; }

.modal--referral .modal-close { width: 40px; height: 40px; z-index: 5; }

.modal--referral .modal-body { padding: 3rem 6.5rem 5rem; }

.modal--referral .modal-body h2, .modal--referral .modal-body h3 { font-size: 2rem; line-height: 3rem; margin-bottom: 1rem; }

.modal--referral .modal-body ol { padding-bottom: 1.25rem; }

.modal--referral .modal-body ol li { font-size: 1rem; line-height: 1.5rem; color: #545465; margin-bottom: 1.25rem; margin-left: 1rem; padding-left: 1rem; }

@media (max-width: 768px) { .modal--referral .modal-body { padding: 16px 7px; }
  .modal--referral .modal-body h2, .modal--referral .modal-body h3 { font-size: 1.5rem; line-height: 2rem; } }

.modal--pricing .modal-dialog { max-width: 1280px; border-radius: 4px; box-shadow: none; padding: 80px 28px; margin: 60px auto; }

.modal--pricing .modal-header { text-align: center; }

@media (max-width: 991px) { .modal--pricing .modal-dialog { width: calc(100% - 24px); max-width: 100%; margin: 30px 12px; padding: 32px 16px; } }

.modal--pricing .pricing-detail { display: block; }

.modal--pricing .pricing-detail-sm { display: none; }

@media (max-width: 768px) { .modal--pricing .pricing-detail { display: none; }
  .modal--pricing .pricing-detail-sm { display: block; } }

.suit__wrap { padding-top: 80px; padding-bottom: 80px; }

.suit__list { display: flex; flex-wrap: wrap; }

.suit__list .icon { position: absolute; display: block; width: 32px; height: auto; left: 0; top: 0; }

.suit__list li { position: relative; display: block; min-height: 48px; width: 100%; margin-bottom: 24px; padding-left: 44px; }

.suit__list li:last-child { margin-bottom: 0; }

.suit__label { font-size: 12px; line-height: 18px; color: #B4B4B4; margin-bottom: 16px; }

.suit__card { padding: 24px; border: 2px solid transparent; border-radius: 4px; box-shadow: none; }

.suit__card.hightlight { border: 2px solid #005FBF; box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.75); }

.suit__card.hightlight .suit__label { color: #4B61DD; }

.modal-download .modal-content { box-shadow: none !important; }

.timeline { position: relative; display: block; }

.timeline__wrap { padding-top: 60px; padding-bottom: 60px; }

.timeline__img { padding-left: 24px; z-index: 2; }

.timeline__img img { display: block; width: 100%; height: auto; }

.timeline__img.fixed { position: fixed; }

.timeline__list.alt > li { padding-bottom: 200px; }

.timeline__list > li { position: relative; padding-left: 32px; padding-bottom: 120px; opacity: .75; transition: all .3s ease-in-out; }

.timeline__list > li .img { display: none; padding: 0 24px; text-align: center; }

.timeline__list > li .img img { max-width: 100%; display: block; margin: 0 auto 16px; }

.timeline__list > li h3 { font-size: 20px; line-height: 32px; margin-bottom: 8px; }

.timeline__list > li:before { content: ''; display: block; position: absolute; width: 16px; height: 16px; background: #E2E2E2; border: 4px solid transparent; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); top: 10px; left: -7px; z-index: 2; border-radius: 50%; }

.timeline__list > li:after { content: ''; display: block; position: absolute; width: 1px; height: 100%; background: #E2E2E2; top: 25px; left: 0; z-index: 1; }

.timeline__list > li:last-child { padding-bottom: 0 !important; }

.timeline__list > li:last-child::after { display: none; }

.timeline__list > li.active { opacity: 1; }

.timeline__list > li.active:before { background: #4B61DD; border: 4px solid #FFF; }

.timeline--promo .timeline__list > li { padding-bottom: 48px; }

.timeline--promo .timeline__list > li:hover { cursor: pointer; }

@media (max-width: 479px) { .timeline__wrap { padding-top: 40px; padding-bottom: 60px; }
  .timeline__img { display: none; }
  .timeline__list li { padding: 0; opacity: 1; text-align: left; }
  .timeline__list li .img { display: block; margin-bottom: 32px; }
  .timeline__list li:before { display: none; }
  .timeline__list .owl-dots { text-align: left; }
  .timeline__list .owl-dots .owl-dot { background: #E2E2E2; width: 32px; height: 4px; border-radius: 2px; } }

.form-block { padding: 5rem 0; background: #F2F2F3; font-size: 14px; line-height: 1.5em; }

.form-block:first-child { padding-top: 10rem; }

.form-block__wrap { padding: 2rem; background: #FFF; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); border-radius: 4px; }

.form-block .form-group { margin-bottom: 1.5rem; }

.form-block .form-group:last-child { margin-bottom: 0; }

.form-block .form-action { text-align: center; }

.form-block .form-action:not(:last-child) { margin-bottom: 1.5rem; }

.form-block .form-action .btn { display: block; width: 100%; }

.form-block .form-action .btn + .btn { margin-top: 12px; }

.form-block label:not(.custom-control-label) { display: block; color: #252525; font-size: 14px; line-height: 1.25em; font-family: "Inter", sans-serif; font-weight: 500; margin: 0 0 .2em; }

.form-block .form-control { display: block; font-size: 14px; width: 100%; border: 1px solid #E2E2E2; height: 38px; padding: 6px 12px; border-radius: 4px; }

.form-block .form-control:focus { border-color: #005FBF; }

.form-block textarea.form-control { height: auto; resize: none; }

.form-block .dropdown.bootstrap-select .bttn { font-size: 14px; line-height: 36px; padding-left: 12px; }

.form-block .dropdown.bootstrap-select .bttn:after { top: 0; line-height: 38px; }

.form-block .dropdown.bootstrap-select div.dropdown-menu a { padding: 8px 12px; }

.form-block .custom-control + .custom-control { margin-top: .2rem; }

.form-block a { color: #0060BF; text-decoration: none; }

.form-block a:hover { text-decoration: underline; }

.small-banner .slider { height: 100%; }

.small-banner__wrap { padding: 2rem 0; }

.small-banner__wrap.pb { padding-top: 0; padding-bottom: 80px; }

.small-banner__box { display: block; position: relative; padding: 3rem 35% 3rem 3rem; border-radius: 8px; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); background: #FFFFFF; }

.small-banner__box.center { padding: 3rem; text-align: center; }

@media (max-width: 375px) { .small-banner__box.center { padding: 1.2rem; } }

.small-banner__box--alt { padding: 3rem; }

@media (max-width: 768px) { .small-banner__box { padding: 2rem; margin-bottom: 40px; } }

.small-banner__title h3, .small-banner__title h2, .small-banner__title span { display: block; font-size: 24px; line-height: 1.5em; font-family: "Inter", sans-serif; font-weight: 500; margin: 0 0 1rem; }

.small-banner__action { margin-top: 32px; }

.small-banner__action a:first-child, .small-banner__action .btn:first-child { margin-right: 16px; }

@media (max-width: 768px) { .small-banner__action { text-align: center; }
  .small-banner__action .btn { display: block; width: 100%; }
  .small-banner__action a:first-child, .small-banner__action .btn:first-child { margin-right: 0; margin-bottom: 16px; } }

.small-banner__img { position: absolute; right: 0; bottom: 0; padding-top: 10px; padding-right: 0; height: 100%; }

.small-banner__img img { display: inline-block; height: 100%; width: auto; }

.small-banner__img--alt { position: relative; padding-left: 6px; margin-right: -12px; }

.small-banner__img--alt img { margin-top: -50%; display: block; max-width: 100%; width: auto; height: auto; }

@media (max-width: 768px) { .small-banner__img { position: relative; max-width: 100%; padding: 0; margin: 24px 0 10px; text-align: center; height: auto; }
  .small-banner__img img { height: auto; }
  .small-banner__img--alt img { margin-top: 0; } }

.small-banner .slider { position: relative; }

.small-banner .owl-item { padding: 10px 15px 30px 15px; }

@media (max-width: 768px) { .small-banner .owl-item { padding: 10px 10px 30px 10px; } }

.small-banner .owl-dots { position: absolute; bottom: 30px; left: 70px; }

.small-banner .owl-dots button { background: #E2E2E2; }

.small-banner .owl-dots button.active { background: #009BDE; }

.small-banner--promo .small-banner__box { background: #F2F4F7; box-shadow: 0px 12px 12px rgba(50, 50, 71, 0.08), 0px 16px 24px rgba(50, 50, 71, 0.08); border-radius: 8px; }

.slider--promo { padding: 64px 0; }

.slider--promo .item a { display: inline-block; width: 100%; height: 100%; position: relative; }

.slider--promo .item a label { position: absolute; top: 0; left: 0; color: transparent; }

.slider--promo .item a img { max-height: 480px; }

.slider--promo .owl-dot { background: #E2E2E2 !important; }

.slider--promo .owl-dot.active { background: #005FBF !important; }

.banner-cta { padding-bottom: 80px; }

.banner-cta__box { background: #F2F4F7; border-radius: 8px; }

.banner-cta__box .col-12 { padding: 44px 48px; }

.banner-cta__box .col-12:last-child { padding: 0; }

.banner-cta__desc { margin-bottom: 12px; }

.banner-cta__action a { position: relative; display: inline-block; width: auto; height: auto; }

.banner-cta__action a label { position: absolute; left: 0; top: 0; color: transparent; }

.banner-cta__action a:hover { cursor: pointer; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); }

.banner-cta__img img { width: 100%; object-fit: cover; }

@media (max-width: 768px) { .banner-cta__box .col-12 { padding: 20px 22px; } }

.custom-form { height: 100vh; overflow-x: hidden; overflow-y: auto; background: #F2F4F7; }

.custom-form .container-full { height: 100%; }

.custom-form .container-full > .row { height: 100%; }

.custom-form__wrapper { flex-direction: row-reverse; }

.custom-form__meta { padding: 40px 100px 24px; height: 100%; }

.custom-form__meta-title, .custom-form__meta-desc { display: block; }

@media (max-width: 768px) { .custom-form__meta-title, .custom-form__meta-desc { display: none; } }

.custom-form__meta-title .custom-list, .custom-form__meta-desc .custom-list { position: relative; margin-bottom: 32px; }

.custom-form__meta-title .custom-list li, .custom-form__meta-desc .custom-list li { position: relative; display: inline-block; padding-left: 36px; margin-bottom: 16px; margin-right: 16px; }

.custom-form__meta-title .custom-list li:before, .custom-form__meta-desc .custom-list li:before { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-check.svg) no-repeat center; background-size: contain; position: absolute; top: 0; left: 0; }

.custom-form__meta-title .custom-list li:last-child, .custom-form__meta-desc .custom-list li:last-child { margin-bottom: 0; }

.custom-form__meta-logo { margin-bottom: 64px; display: block; }

@media (max-width: 768px) { .custom-form__meta-logo { display: none; } }

.custom-form__meta-illustration { text-align: center; margin-bottom: 64px; }

.custom-form__meta-testimonials { margin-top: 40px; margin-bottom: 64px; }

@media (max-width: 768px) { .custom-form__meta-testimonials { margin-top: 0; margin-bottom: 30px; } }

.custom-form__meta .testimonial__wrap { background: transparent; border-radius: 0; box-shadow: none; padding-bottom: 0; margin-bottom: 0; }

.custom-form__meta .testimonial__wrap .testimonial-big__content { padding: 0; }

.custom-form__meta .testimonial__wrap .testimonial-big__content .testimonial-big__blockquote { font-family: "Inter", sans-serif; font-weight: 500; }

.custom-form__meta .testimonial__wrap .testimonial-big__content .testimonial-big__footer { padding-bottom: 0; }

.custom-form__meta .testimonial__wrap .testimonial-big__content .testimonial-big__footer img { width: auto !important; }

.custom-form__meta .testimonial__wrap .owl-dots { padding: 0; margin: 24px 0 0 0; }

.custom-form__meta .testimonial__wrap .owl-dots button.owl-dot { background: #E2E2E2; }

.custom-form__meta .testimonial__wrap .owl-dots button.owl-dot.active { background: #009BDE; }

.custom-form__meta .nav-tabs { margin-top: 8px; margin-bottom: 64px; }

.custom-form__meta .nav-tabs .nav-item { display: inline-block; width: 48px; height: 48px; }

.custom-form__meta .nav-tabs .nav-item .nav-link { opacity: .5; }

.custom-form__meta .nav-tabs .nav-item .nav-link.active { opacity: 1; }

.custom-form__meta-footer label { font-family: "Inter", sans-serif; font-weight: 500; display: block; font-size: 14px; line-height: 17px; margin-bottom: 15px; }

.custom-form__meta-icon { margin-bottom: 48px; }

.custom-form__meta-icon a { margin-right: 25px; }

@media (max-width: 768px) { .custom-form__meta { padding: 0px 16px 30px; } }

.custom-form__form { padding: 64px 100px 24px; }

.custom-form__form-logo { display: none; margin-bottom: 50px; }

@media (max-width: 768px) { .custom-form__form-logo { display: block; margin-bottom: 30px; }
  .custom-form__form-logo img { max-height: 40px; width: auto; } }

.custom-form__form-title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 2rem; line-height: 2.5rem; margin-bottom: 1rem; display: none; }

@media (max-width: 768px) { .custom-form__form-title { display: block; } }

.custom-form__form-subtitle { font-size: 1rem; line-height: 1.5rem; margin-bottom: 4px; }

.custom-form__form-desc { margin-bottom: 1.5rem; display: none; }

.custom-form__form-desc .custom-list { position: relative; margin-bottom: 32px; }

.custom-form__form-desc .custom-list li { position: relative; display: inline-block; padding-left: 36px; margin-bottom: 16px; margin-right: 16px; }

.custom-form__form-desc .custom-list li:before { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-check.svg) no-repeat center; background-size: contain; position: absolute; top: 0; left: 0; }

.custom-form__form-desc .custom-list li:last-child { margin-bottom: 0; }

@media (max-width: 768px) { .custom-form__form-desc { display: block; } }

@media (max-width: 768px) { .custom-form__form { padding: 40px 16px 30px; } }

.custom-form__box { background: #FFFFFF; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); border-radius: 4px; max-width: 496px; padding: 40px; }

@media (max-width: 768px) { .custom-form__box { padding: 20px; } }

.tf { text-align: center; position: relative; z-index: 1; background: #F8FAFC; }

.tf.bg { background: #F8FAFC; }

.tf.blue { background: #143A72; }

.tf-title { padding: 80px 0 48px; }

@media (max-width: 768px) { .tf-title { padding: 40px 0; } }

.tf h3, .tf .tf-cta__title { margin: 0 auto 64px; font-size: 32px; line-height: 48px; margin-bottom: 16px; }

@media (max-width: 375px) { .tf h3, .tf .tf-cta__title { font-size: 21px; line-height: 37px; } }

.tf_img { width: 80px; background-color: #F2F2F2; background-repeat: no-repeat; display: block; margin: 0 auto 16px; }

.tf h3, .tf .tf-cta__title { font-weight: 500; margin: 0 0 8px; }

.tf_item { padding: 48px 60px 64px; }

@media (max-width: 768px) { .tf_item { padding: 48px 15px 34px; } }

.tf_item.is-highlight { background: #002A38; color: #FFF; }

.tf_item.is-highlight h3, .tf_item.is-highlight .tf-cta__title { color: #FFF; }

@media (max-width: 479px) { .tf_item.is-highlight { border-radius: 0; } }

.tf .btn-white { min-width: 180px; max-width: 100%; border: none; }

.tf .btn-white:hover { background: #102f5c; }

.tf--alt { background: #F2F4F7; }

.tf--alt .tf_item.is-highlight { background: #232933; }

.tf--alt .tf_item h2, .tf--alt .tf_item h3, .tf--alt .tf_item .tf-cta__title { font-size: 20px; line-height: 28px; margin-bottom: 12px; }

.tf--alt .tf_item img { height: 72px; width: auto; }

.testimonial__label { font-size: 16px; line-height: 24px; margin-bottom: 32px; display: inline-block; }

.testimonial--promo { background: #F2F4F7; padding: 80px 0; }

.testimonial--promo .testimonial__wrap { background: transparent; margin-bottom: 0; box-shadow: none; padding: 0; }

.testimonial--promo .testimonial__wrap .testimonial-big__content { padding: 0; }

.testimonial--promo .testimonial__wrap .testimonial-big__blockquote { font-family: "Inter", sans-serif; font-weight: 500; font-size: 32px; line-height: 40px; }

.testimonial--promo .testimonial__wrap .testimonial-big__footer { padding-bottom: 0; }

.in-app .btn { padding: 8px 16px; }

.in-app .btn-blue { font-family: "Inter", sans-serif; font-weight: 500; font-size: 14px; line-height: 20px; letter-spacing: 0.2px; }

.in-app .btn-grey { background: #FBFBFB; border: 1px solid #E2E2E2; border-radius: 4px; font-size: 14px; line-height: 20px; letter-spacing: 0.2px; color: #777777; }

.in-app .btn-grey:hover { background: #e2e1e1; }

.in-app .btn-yellow { background: #F8E51E; border: 1px solid #F8E51E; border-radius: 4px; font-size: 14px; line-height: 20px; letter-spacing: 0.2px; color: #293B85; }

.in-app .btn-yellow:hover { background: #dcca07; }

.in-app .btn-blue-border { font-size: 14px; line-height: 20px; }

.in-app hr.separator { margin: 0; border-top: 1px solid #E2E2E2; }

.web-wrapper.in-app { overflow: hidden; padding: 24px 24px 0; background: #F2F4F7; }

.web-wrapper.in-app main { border: 1px solid #dcdee1; border-radius: 4px 4px 0 0; background: #FFF; }

.web-wrapper.in-app.opt { padding: 0; background: #fff; }

.web-wrapper.in-app.opt main { border: none; border-radius: 0; background: #FFF; }

.masthead.in-app { padding-top: 56px; }

.masthead.in-app .masthead__content { padding-top: 0; }

.masthead.in-app .masthead__logo { margin-bottom: 24px; display: inline-block; }

.masthead.in-app .masthead__title { font-family: "Inter", sans-serif; font-weight: 500; font-size: 40px; line-height: 47px; letter-spacing: 0.2px; color: #212121; margin-bottom: 24px; }

.masthead.in-app p { font-family: "Inter", sans-serif; font-weight: 400; font-size: 14px; line-height: 20px; letter-spacing: 0.2px; color: #777777; }

@media (max-width: 768px) { .masthead.in-app .masthead__action .btn { margin-left: 0; margin-bottom: 16px; } }

.advantage.in-app { padding-top: 0; padding-bottom: 0; }

.advantage.in-app .section-head__title { font-family: "Inter", sans-serif; font-weight: 500; font-size: 24px; line-height: 32px; letter-spacing: 0.2px; color: #000000; }

.advantage.in-app .post__img { margin-bottom: 16px; }

.advantage.in-app .post__content { padding-right: 80px; }

.advantage.in-app .post__content p { font-size: 14px; line-height: 20px; letter-spacing: 0.2px; color: #777777; }

.advantage.in-app .allinone__wrap { padding: 80px 0; border-bottom: 1px solid #E2E2E2; }

.advantage.in-app .post.text-center .post__content { padding-right: 0; }

.advantage.in-app .post.text-center .post__img.lg { display: inline-block; }

.advantage.in-app .post.text-center .post__img.lg img { width: 80px; height: 80px; }

.timeline.in-app .timeline__wrap { border-bottom: 1px solid #E2E2E2; }

.timeline.in-app .section-head__title { font-family: "Inter", sans-serif; font-weight: 500; font-size: 24px; line-height: 32px; letter-spacing: 0.2px; color: #000000; }

.timeline.in-app .timeline__list h3 { font-family: "Inter", sans-serif; font-weight: 500; font-size: 16px; line-height: 24px; letter-spacing: 0.2px; color: #212121; }

.timeline.in-app .timeline__list > li { padding: 24px; margin-left: 32px; margin-bottom: 48px; }

.timeline.in-app .timeline__list > li::before { top: 40px; left: -35px; }

.timeline.in-app .timeline__list > li::after { height: 130%; top: 60px; left: -27px; }

.timeline.in-app .timeline__list li.active { background: #F2F4F7; border-radius: 16px; }

@media (max-width: 479px) { .timeline.in-app .timeline__img { display: none; }
  .timeline.in-app .timeline__list li.active { background: transparent; }
  .timeline.in-app .timeline__list li.active h3 { text-align: center; } }

.tf.in-app { border-radius: 0 0 4px 4px; overflow: hidden; }

.tf.in-app .tf_item.is-highlight { padding: 60px 0; background: #002A38; }

@media (max-width: 768px) { .tf.in-app .tf_item.is-highlight { padding-left: 12px; padding-right: 12px; } }

.tf.in-app h3 { color: #fff; font-size: 24px; line-height: 32px; text-align: center; letter-spacing: 0.2px; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 8px; }

.tf.in-app p { font-family: "Inter", sans-serif; font-weight: 400; font-size: 16px; line-height: 24px; text-align: center; letter-spacing: 0.2px; color: #FFFFFF; margin-bottom: 24px; }

.copyright.in-app { padding: 24px 12px; }

.copyright.in-app p { font-family: "Inter", sans-serif; font-weight: 400; font-size: 12px; line-height: 16px; text-align: center; letter-spacing: 0.2px; color: #777777; }

.testi { background: linear-gradient(90deg, #fff 26%, #F2F4F7 26%); }

.testi .testimonial-big { padding-top: 0; margin: 0; }

.testi .testimonial-big__wrap { padding: 64px 0; background: transparent; box-shadow: none; }

.testi .testimonial-big__wrap .testimonial-big__img .block-img { padding: 0; }

.testi .testimonial-big__wrap .testimonial-big__img .block-img img { position: relative; }

.testi .testimonial-big__content { padding-left: 128px; display: flex; flex-direction: column; justify-content: center; }

.testi .testimonial-big__content .testimonial-big__title { font-size: 24px; line-height: 32px; margin-bottom: 24px; }

.testi .testimonial-big__content .testimonial-big__blockquote { margin-bottom: 27px; }

.testi .testimonial-big__content .testimonial-big__blockquote p { font-size: 20px; line-height: 28px; }

@media (max-width: 768px) { .testi { background: #F2F4F7; }
  .testi .testimonial-big__content { padding-left: 0; } }

/* Contact */
.contact-cards { padding: 0 0 46px; }

.contact-cards .item { margin-bottom: 24px; }

.contact-cards .item__wrap { display: flex; height: 100%; flex-direction: column; padding: 2rem 2rem 1.5rem; text-align: center; background: #F2F4F7; border-radius: 0.25rem; }

.contact-cards .item__img { margin: 0 0 0.75rem; }

.contact-cards .item__img img { display: block; width: auto; height: 5rem; margin: 0 auto; }

.contact-cards .item__title { margin: 0 0 1rem; }

@media (max-width: 768px) { .contact-cards .item__title img { display: none !important; } }

.contact-cards .item__desc { margin: 0 0 2rem; }

.contact-cards .item__desc ul > li { margin: 0 0 0.5rem; }

.contact-cards .item__desc ul > li:last-child { margin: 0; }

.contact-cards .item__action .btn + .btn { margin-top: 1rem; display: block; }

.contact-cards .item__action .btn-link { color: #005FBF; }

.contact-cards .item__action .btn-link:hover { text-decoration: underline; }

.contact-cards .item--sm { background: #F2F4F7; border-radius: 4px; margin-bottom: 12px; }

.contact-cards .item--sm .item__wrap { padding: 8px 12px; flex-direction: row; align-items: center; }

.contact-cards .item--sm .item__img { width: 40px; height: 40px; margin-bottom: 0; margin-right: 16px; }

.contact-cards .item--sm .item__img img { width: 40px; height: 40px; }

.contact-cards .item--sm .item__title { margin-bottom: 0; font-size: 16px; line-height: 24px; }

.form-meeting { background: #F2F4F7; padding: 5rem 0; }

.form-meeting__title { margin: 0 0 1rem; }

.form-meeting__desc { margin: 0 0 3rem; }

.form-meeting__img { display: block; margin: 0 0 1.5rem; }

.form-meeting__box { padding: 2.5rem; background: #FFF; border-radius: 0.25rem; }

@media (max-width: 991px) { .form-meeting__box { overflow-x: auto; } }

.form-meeting--landing .form-meeting__title { font-size: 2rem; line-height: 2.5rem; margin-bottom: 2.5rem; }

.form-meeting--landing .form-meeting__box { box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); }

.tf_item.is-highlight h2 { color: #FFF; }

.form-meeting { background: #F2F4F7; padding: 5rem; }

.form-meeting__title { margin: 0 0 1rem; }

.form-meeting__desc { margin: 0 0 3rem; }

.form-meeting__img { display: block; margin: 0 0 1.5rem; }

.form-meeting__box { padding: 2.5rem; background: #FFF; border-radius: 0.25rem; }

.footer--landing { padding: 24px 0; background: #F3F3F3; }

.footer--landing-alt { background: #FFF; }

.footer--landing .text-right b { margin-right: 20px; }

.footer--landing .text-right span a { margin-right: 16px; }

.footer--landing .text-right span a:last-child { margin-right: 0; }

@media (max-width: 375px) { .footer--landing .container .row .col-md-6 { text-align: center; margin-bottom: 16px; }
  .footer--landing .container .row .col-md-6 b { display: block; margin-bottom: 12px; } }

.tf--landing { background: url("../images/pattern-cta.svg"); color: #FFF; }

.tf--landing h1, .tf--landing h2, .tf--landing h3, .tf--landing h4, .tf--landing h5, .tf--landing h6 { color: #FFF; }

.block-clients { padding-top: 0; padding-bottom: 5rem; background: #F2F4F7; }

.block-clients__title { text-align: center; font-size: 1.25rem; line-height: 2rem; }

.block-clients__desc { text-align: center; max-width: 600px; margin: 0 auto 3rem; }

.block-clients__desc p { margin-bottom: 1rem; }

.block-clients ul.clients { display: flex; flex-wrap: wrap; padding: 0; margin: 0; }

.block-clients ul.clients li { display: flex; position: relative; align-items: center; justify-content: center; flex-direction: column; width: 100%; max-width: 16.666667%; flex: 0 0 16.666667%; margin: 24px 0; }

.block-clients ul.clients li img { height: 60px; width: auto; }

.block-clients ul.clients li .img--2 { display: none; }

.block-clients--pricing { background: #F2F4F7; padding-top: 56px; padding-bottom: 56px; }

.slider-section.owl-carousel [class^="col"] { max-width: 100%; }

.slider-section.owl-carousel .owl-dots .owl-dot { width: 40px; height: 5px; border-radius: 15px; background: #eee; }

.slider-section.owl-carousel .owl-dots .owl-dot.active { background: #4B61DD; }

.crossell-form { margin-top: 100px; background: #FFF; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); padding: 32px; border-radius: 8px; }

.crossell-socproof { padding-bottom: 64px; padding-top: 64px; }

.crossell-socproof__title { text-align: center; font-size: 24px; line-height: 32px; margin-bottom: 52px; }

.crossell-socproof__desc { text-align: center; margin-bottom: 48px; }

.crossell-socproof .item { margin-bottom: 20px; }

.crossell-socproof .item__wrap { padding-left: 16px; }

.crossell-socproof .item__number { position: relative; }

.crossell-socproof .item__number::before { content: ""; display: block; width: 2px; height: 32px; position: absolute; top: calc(50% - 16px); left: -16px; background-color: #C02A34; }

.crossell-socproof .item__number span { color: #C02A34; font-size: 32px; line-height: 48px; font-family: "Inter", sans-serif; font-weight: 500; display: block; margin-bottom: 0; }

.crossell-socproof .item__desc { max-width: 85%; }

@media (max-width: 991px) { .crossell-socproof { padding-top: 32px; padding-bottom: 32px; } }

.crossell-clients { padding-top: 80px; padding-bottom: 64px; }

.crossell-clients h2 { text-align: center; font-size: 24px; line-height: 32px; margin-bottom: 32px; }

.crossell-clients .clients { display: flex; flex-wrap: wrap; margin-left: -16px; margin-right: -16px; margin-bottom: -32px; }

.crossell-clients .clients .item { display: flex; width: 100%; height: 60px; max-width: 16.666667%; flex: 0 0 16.666667%; align-items: center; justify-content: center; padding: 0 16px; margin-bottom: 24px; }

.crossell-clients .clients .item.show { display: flex !important; }

.crossell-clients .clients .item img { display: block; max-width: 100%; max-height: 60px; width: auto; height: auto; }

.crossell-clients .clients-more { padding-top: 36px; text-align: center; }

@media (max-width: 991px) { .crossell-clients { padding-top: 32px; }
  .crossell-clients .clients .item { max-width: 33.333%; flex: 33.333%; }
  .crossell-clients .clients .item img { height: 32px; } }

.howjurnal.howjurnal--xsell .section-head__title { font-size: 24px; line-height: 32px; }

.howjurnal.howjurnal--xsell .accordion .card.open { border-color: #C02A34; }

.feature-block.row-reverse .row { flex-direction: row-reverse; }

.feature-block__wrap { padding-top: 5rem; padding-bottom: 5rem; }

.feature-block .section-head { text-align: center; margin-bottom: 3rem; }

.feature-block .post { position: relative; }

.feature-block .post__img { margin-bottom: 0.5rem; }

.feature-block .post__img .icon { display: block; width: auto; height: 3.5rem; }

.feature-block .post__content { padding-right: 2.5rem; }

.feature-block .post__content.np { padding-right: 0; }

.feature-block .post__title { display: block; color: #232933; font-size: 1rem; line-height: 1.5em; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 0.75rem; }

.feature-block.title-only .feature-block__wrap { padding-top: 64px; padding-bottom: 0; }

@media (max-width: 768px) { .feature-block.title-only .feature-block__wrap { padding-top: 0; padding-bottom: 0; } }

.feature-block.title-only .section-head { margin-bottom: 0; }

.feature-block.title-only.promo { background: linear-gradient(267.45deg, #0158CA 7.3%, #0169D7 42.37%, #016DDB 81.39%); }

.feature-block.title-only.promo .section-head { padding-bottom: 48px; }

.feature-block.title-only.promo .section-head__title { color: #FFF; font-size: 28px; line-height: 36px; }

@media (max-width: 768px) { .feature-block.title-only.promo { background: linear-gradient(267.45deg, #0159CB 7.3%, #0169D8 49.9%, #016EDB 81.39%); }
  .feature-block.title-only.promo .section-head { padding-bottom: 20px; }
  .feature-block.title-only.promo .section-head__title { font-size: 26px; line-height: 32px; } }

@media (max-width: 991px) { .feature-block .post__content { padding-right: 0; } }

.page-tabs { position: relative; }

.page-tabs__header { padding-top: 4rem; }

.page-tabs__title { display: block; width: 100%; text-align: center; color: #212121; max-width: 600px; margin: 0 auto 28px; }

.page-tabs__desc { max-width: 600px; margin: 0 auto 64px; }

@media (max-width: 991px) { .page-tabs__desc { margin: 0 auto 40px; } }

.page-tabs .nav-tabs .nav-link { display: block; border: 1px solid #E2E2E2; border-radius: 0.25rem; padding: 1.5rem; text-align: center; color: #212121; background: #FFFFFF; transition: all .3s ease; height: 100%; }

.page-tabs .nav-tabs .nav-link .icon { display: block; height: 48px; width: 48px; margin: 0 auto 12px; }

.page-tabs .nav-tabs .nav-link span { display: block; font-size: 0.875rem; line-height: 1.5em; color: #212121; }

.page-tabs .nav-tabs .nav-link h3 { display: block; font-size: 1.5rem; line-height: 1.333em; margin: 0; color: #212121; }

.page-tabs .nav-tabs .nav-link:hover { text-decoration: none; background: #F2F4F7; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }

.page-tabs .nav-tabs .nav-link.active { text-decoration: none; background: #F2F4F7; border-color: #4B61DD; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }

.page-tabs .nav-tabs .nav-link.active .icon path { fill: #005FBF; }

@media (min-width: 1600px) { .page-tabs .container.container--nav-tabs { max-width: 1600px; } }

@media (max-width: 768px) { .page-tabs--alt .page-tabs__header { padding: 4rem 0 6rem 0; } }

@media (max-width: 991px) { .page-tabs--alt .page-tabs__title { padding-top: 30px; } }

.page-tabs--alt .page-tabs__body .mobile-section { display: none; }

@media (max-width: 768px) { .page-tabs--alt .page-tabs__body .mobile-section { display: block; }
  .page-tabs--alt .page-tabs__body .mobile-section .img { margin-bottom: 16px; max-height: 48px; width: auto; }
  .page-tabs--alt .page-tabs__body .mobile-section h3 { margin-bottom: 12px; }
  .page-tabs--alt .page-tabs__body .mobile-section h4 { margin-bottom: 12px; } }

.page-tabs--alt .page-tabs__dropdown { position: relative; }

@media (max-width: 768px) { .page-tabs--alt .page-tabs__dropdown { margin-top: -10px; } }

.page-tabs--alt .page-tabs__dropdown .selected { display: none; width: 100%; height: 48px; position: absolute; left: 0; top: 0; padding: 10px 35px 10px 15px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; color: #2B2A35; font-size: 16px; line-height: 1.85rem; font-family: "Inter", sans-serif; font-weight: 500; }

.page-tabs--alt .page-tabs__dropdown .selected::after { content: ''; background: url(../images/arrow-down.svg) no-repeat center; width: 24px; height: 24px; display: inline-block; position: absolute; bottom: 12px; right: 10px; }

@media (max-width: 768px) { .page-tabs--alt .page-tabs__dropdown .selected { height: 48px; width: 100%; background: #FFFFFF; border: 1px solid #E2E2E2; border-radius: 4px; display: block; } }

.page-tabs--alt .page-tabs__dropdown.active .nav-tabs { height: auto; left: 12px; background: #FFF; padding: 0; }

.page-tabs--alt .page-tabs__dropdown.active .nav-tabs .nav-item { padding: 0; }

.page-tabs--alt .page-tabs__dropdown.active .nav-tabs .nav-item .nav-link { opacity: 1; visibility: visible; max-height: 100%; }

.page-tabs--alt .page-tabs__dropdown.active .nav-tabs .nav-item .nav-link.active { max-height: 100%; }

.page-tabs--alt .nav-tabs .nav-item__title { display: flex; align-items: center; text-align: left; margin-bottom: 0.75rem; }

@media (max-width: 991px) { .page-tabs--alt .nav-tabs .nav-item__title { margin-bottom: 0; } }

.page-tabs--alt .nav-tabs .nav-item__title h3 { font-size: 16px; line-height: 24px; }

@media (max-width: 768px) { .page-tabs--alt .nav-tabs .nav-item__title h3 { font-size: 1rem; line-height: 1.5rem; } }

.page-tabs--alt .nav-tabs .nav-item__title .ic { max-width: 40px; min-width: 40px; margin-right: 1rem; }

.page-tabs--alt .nav-tabs .nav-item .nav-link { background: #FFF; border: 0; position: relative; padding: 2rem; text-align: left; }

.page-tabs--alt .nav-tabs .nav-item .nav-link:hover, .page-tabs--alt .nav-tabs .nav-item .nav-link.active { box-shadow: none; background: #F2F4F7; border-radius: 8px 8px 0 0; }

.page-tabs--alt .nav-tabs .nav-item .nav-link:hover::after, .page-tabs--alt .nav-tabs .nav-item .nav-link.active::after { content: ''; background: url(../images/arrow-down.svg) no-repeat center; width: 24px; height: 24px; display: inline-block; position: absolute; bottom: 0.75rem; left: calc(50% - 12px); }

@media (max-width: 768px) { .page-tabs--alt .nav-tabs .nav-item .nav-link:hover::after, .page-tabs--alt .nav-tabs .nav-item .nav-link.active::after { display: none; } }

@media (max-width: 768px) { .page-tabs--alt .nav-tabs .nav-item .nav-link { border-radius: 0 !important; border: 1px solid #E2E2E2; padding: 12px; height: auto; }
  .page-tabs--alt .nav-tabs .nav-item .nav-link .ic { display: none; } }

.page-tabs--alt .nav-tabs.slider-tabs .owl-stage-outer { overflow: visible; }

.page-tabs--alt .nav-tabs.slider-tabs .owl-stage { display: flex; flex-wrap: nowrap; }

.page-tabs--alt .nav-tabs.slider-tabs .owl-stage::after { display: none; }

.page-tabs--alt .nav-tabs.slider-tabs .owl-item { position: relative; width: 100% !important; padding: 0 12px; }

.page-tabs--alt .nav-tabs.slider-tabs .nav-item { display: flex; height: 100%; }

@media (max-width: 991px) { .page-tabs--alt .nav-tabs.slider-tabs { width: 100%; }
  .page-tabs--alt .nav-tabs.slider-tabs .nav-item { width: 100%; display: block; } }

.page-tabs--alt .page-tabs__section-head { padding-top: 80px; padding-bottom: 48px; }

.page-tabs--alt .page-tabs__title { margin-bottom: 16px; text-align: center; }

.page-tabs--alt .page-tabs__desc { text-align: center; }

.page-tabs--alt .page-tabs__body { background: #F2F4F7; }

.page-tabs--alt .page-tabs__wrap.sticky-me { z-index: 20; }

.page-tabs--alt .page-tabs__wrap.sticky-me .page-tabs__header { transition: all .4s ease; padding-top: 0; }

.page-tabs--alt .page-tabs__wrap.sticky-me .nav-tabs .nav-item__title { transition: all .4s ease; }

.page-tabs--alt .page-tabs__wrap.sticky-me .nav-tabs .nav-item__title .ic { transition: all .4s ease; }

.page-tabs--alt .page-tabs__wrap.sticky-me .nav-tabs .nav-item .nav-link { transition: all .4s ease; }

.page-tabs--alt .page-tabs__wrap.sticky-me.stick { background: #FFF; bottom: auto !important; border-bottom: 1px solid #E2E2E2; }

.page-tabs--alt .page-tabs__wrap.sticky-me.stick .page-tabs__header { padding-top: 56px; }

.page-tabs--alt .page-tabs__wrap.sticky-me.stick .nav-tabs .nav-item__title { margin: 0; }

.page-tabs--alt .page-tabs__wrap.sticky-me.stick .nav-tabs .nav-item__title .ic { height: 30px; width: 30px; min-width: 30px; margin-right: 12px; }

.page-tabs--alt .page-tabs__wrap.sticky-me.stick .nav-tabs .nav-item__title h3 { font-size: 16px; line-height: 24px; }

.page-tabs--alt .page-tabs__wrap.sticky-me.stick .nav-tabs .nav-item .nav-link { display: flex; align-items: center; border-radius: 0; padding-top: 12px; padding-bottom: 12px; }

.page-tabs--alt .page-tabs__wrap.sticky-me.stick .nav-tabs .nav-item .nav-link p { display: none; }

.page-tabs--alt .page-tabs__wrap.sticky-me.stick .nav-tabs .nav-item .nav-link::after { display: none; }

.page-tabs--alt .page-tabs__wrap.sticky-me.show-top.stick .page-tabs__header { padding-top: 97px; }

@media (max-width: 768px) { .page-tabs--alt { background: #F2F4F7; }
  .page-tabs--alt .nav-tabs { position: absolute; z-index: 2; height: 0; overflow: hidden; top: 49px; left: 0; }
  .page-tabs--alt .nav-tabs .nav-link .nav-item__title h3 { margin-bottom: 0; }
  .page-tabs--alt .nav-tabs .nav-link p { display: none; } }

.page-tabs--red .page-tabs__header { padding-top: 0; }

.page-tabs--red .page-tabs__header .nav-tabs .nav-item .nav-link { padding: 16px 24px; border: 1px solid #F2F4F7; border-bottom-width: 0; background: transparent; border-radius: 8px 8px 0 0; box-shadow: none; cursor: pointer; }

.page-tabs--red .page-tabs__header .nav-tabs .nav-item .nav-link .nav-item__title { text-align: left; cursor: pointer; display: flex; align-items: center; }

.page-tabs--red .page-tabs__header .nav-tabs .nav-item .nav-link .nav-item__title .ic.svg { width: 40px; height: auto; margin-right: 16px; filter: brightness(0) invert(1); }

.page-tabs--red .page-tabs__header .nav-tabs .nav-item .nav-link .nav-item__title label { font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 0; color: #FFF; }

.page-tabs--red .page-tabs__header .nav-tabs .nav-item .nav-link.active, .page-tabs--red .page-tabs__header .nav-tabs .nav-item .nav-link:hover { background: #FFF; cursor: pointer; }

.page-tabs--red .page-tabs__header .nav-tabs .nav-item .nav-link.active .nav-item__title .ic.svg, .page-tabs--red .page-tabs__header .nav-tabs .nav-item .nav-link:hover .nav-item__title .ic.svg { width: 40px; margin-right: 16px; filter: brightness(1) invert(0); }

.page-tabs--red .page-tabs__header .nav-tabs .nav-item .nav-link.active .nav-item__title label, .page-tabs--red .page-tabs__header .nav-tabs .nav-item .nav-link:hover .nav-item__title label { color: #009BDE; }

.page-tabs--red .page-tabs__header .page-tabs__dropdown { position: relative; }

@media (max-width: 768px) { .page-tabs--red .page-tabs__header .page-tabs__dropdown { margin-top: -10px; } }

.page-tabs--red .page-tabs__header .page-tabs__dropdown .selected { display: none; width: 100%; height: 48px; position: absolute; left: 0; top: 0; padding: 10px 35px 10px 15px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; color: #2B2A35; font-size: 16px; line-height: 1.85rem; font-family: "Inter", sans-serif; font-weight: 500; }

.page-tabs--red .page-tabs__header .page-tabs__dropdown .selected::after { content: ''; background: url(../images/arrow-down.svg) no-repeat center; width: 24px; height: 24px; display: inline-block; position: absolute; bottom: 12px; right: 10px; }

@media (max-width: 768px) { .page-tabs--red .page-tabs__header .page-tabs__dropdown .selected { height: 48px; width: 100%; background: #FFFFFF; border: 1px solid #E2E2E2; border-radius: 4px; display: block; } }

.page-tabs--red .page-tabs__header .page-tabs__dropdown.active .nav-tabs { overflow-x: hidden; overflow-y: scroll; height: auto !important; border: 1px solid #E2E2E2; margin: 0; padding-right: 0; }

.page-tabs--red .page-tabs__header .page-tabs__dropdown.active .nav-tabs .nav-item { background: #FFF; margin: 0; border: 0; padding: 0; }

.page-tabs--red .page-tabs__header .page-tabs__dropdown.active .nav-tabs .nav-item .nav-link .nav-item__title { text-align: left; }

.page-tabs--red .page-tabs__header .page-tabs__dropdown.active .nav-tabs .nav-item .nav-link .nav-item__title label { color: #2B2A35; }

.page-tabs--red .page-tabs__body { background: #fff; }

.page-tabs--red .page-tabs__wrap.sticky-me.stick { z-index: 2; background: linear-gradient(267.45deg, #039DDF 7.3%, #16A6E5 42.37%, #19A8E6 81.39%); box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16); }

.page-tabs--red .page-tabs__wrap.sticky-me.stick .page-tabs__header .page-tabs__dropdown .nav-tabs .nav-item .nav-link { border-radius: 0; border-top-width: 0; padding: 8px 24px; }

@media (max-width: 768px) { .page-tabs--red .page-tabs__wrap { height: 75px; width: 100% !important; }
  .page-tabs--red .page-tabs__wrap .page-tabs__header .page-tabs__dropdown { margin-top: 7px; }
  .page-tabs--red .page-tabs__wrap .page-tabs__header .page-tabs__dropdown .nav-tabs { position: absolute; z-index: 2; height: 0; overflow: hidden; top: 49px; left: 0; }
  .page-tabs--red .page-tabs__wrap .page-tabs__header .page-tabs__dropdown .nav-tabs .nav-link .nav-item__title h3 { margin-bottom: 0; }
  .page-tabs--red .page-tabs__wrap .page-tabs__header .page-tabs__dropdown .nav-tabs .nav-link .nav-item__title .ic.svg { filter: unset !important; }
  .page-tabs--red .page-tabs__wrap .page-tabs__header .page-tabs__dropdown .nav-tabs .nav-link p { display: none; }
  .page-tabs--red .page-tabs__body { background: #F2F4F7; } }

@media (max-width: 768px) { .page-tabs--red .page-tabs__wrap { height: 65px; width: 100% !important; }
  .page-tabs--red .page-tabs__wrap .page-tabs__header .page-tabs__dropdown { margin-top: 7px; }
  .page-tabs--red .page-tabs__wrap .page-tabs__header .page-tabs__dropdown .nav-tabs { position: absolute; z-index: 2; height: 0; overflow: hidden; top: 49px; left: 0; }
  .page-tabs--red .page-tabs__wrap .page-tabs__header .page-tabs__dropdown .nav-tabs .nav-link .nav-item__title h3 { margin-bottom: 0; }
  .page-tabs--red .page-tabs__wrap .page-tabs__header .page-tabs__dropdown .nav-tabs .nav-link .nav-item__title .ic.svg { filter: unset !important; }
  .page-tabs--red .page-tabs__wrap .page-tabs__header .page-tabs__dropdown .nav-tabs .nav-link p { display: none; }
  .page-tabs--red .page-tabs__body { background: #F2F4F7; } }

@media (max-width: 991px) { .page-tabs--accr .page-tabs__wrap { display: none; }
  .page-tabs--accr .tab-content--accr { padding: 0 16px 64px 16px; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr { display: block; opacity: 1; padding: 20px 0; background: #FFF; border-radius: 4px; margin-bottom: 2px; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr .mobile-section { position: relative; padding-left: 12px; padding-right: 12px; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr .mobile-section img { display: none; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr .mobile-section h3 { font-size: 18px; line-height: 28px; padding-right: 20px; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr .mobile-section::after { content: ''; display: inline-block; position: absolute; top: 5px; right: 10px; width: 20px; height: 20px; background: url("../images/ic-chevron-down.svg") no-repeat center; background-size: contain; translate: all linear .3s; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr .mobile-section p, .page-tabs--accr .tab-content--accr .tab-pane--accr .fitur-blocks, .page-tabs--accr .tab-content--accr .tab-pane--accr .testimonial-industry, .page-tabs--accr .tab-content--accr .tab-pane--accr .block-cta { display: none; background: #FFF; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr .testimonial-industry { padding-bottom: 30px; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr .testimonial-industry .item__box { background: #F2F4F7; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr .block-cta .container { padding-top: 20px; padding-bottom: 20px; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr .fitur-blocks--industry .fitur-block { padding: 24px 12px; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr.active .mobile-section::after { transform: rotateZ(180deg); translate: all linear .3s; }
  .page-tabs--accr .tab-content--accr .tab-pane--accr.active .mobile-section p, .page-tabs--accr .tab-content--accr .tab-pane--accr.active .fitur-blocks, .page-tabs--accr .tab-content--accr .tab-pane--accr.active .testimonial-industry, .page-tabs--accr .tab-content--accr .tab-pane--accr.active .block-cta { display: block; background: #FFF; } }

.promo-banner { padding: 67px 0 0 0; }

.promo-banner__wrap { background: #FFF; padding-bottom: 64px; border-bottom: 1px solid #E3E3E8; }

.promo-banner__wrap .post { color: #2B2A35; margin-bottom: 48px; }

.promo-banner__wrap .post__img { margin-bottom: 12px; }

.promo-banner__wrap .post__img img { width: 80px !important; height: auto !important; }

.promo-banner__wrap .post__title { margin-bottom: 16px; display: block; font-size: 20px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 600; }

.promo-banner__title { margin-bottom: 48px; font-size: 28px; line-height: 36px; }

.promo-banner__content { padding-left: 48px; padding-right: 48px; }

.promo-banner__img { margin-bottom: 64px; }

.promo-banner__img img { object-fit: cover; width: 100%; height: auto; }

.promo-banner__action { padding-left: 16px; padding-right: 16px; }

.promo-banner__action a { display: inline-block; margin-right: 16px; }

.promo-banner__action a:last-child { margin-right: 0; }

@media (max-width: 991px) { .promo-banner { padding: 24px 0; }
  .promo-banner__wrap { border-radius: 0 0 6px 6px; border-bottom: 0; padding-bottom: 48px; }
  .promo-banner__wrap .post { margin-bottom: 30px; }
  .promo-banner__wrap .post__img { display: inline-block; width: 56px; height: 56px; margin-right: 16px; vertical-align: top; }
  .promo-banner__wrap .post__img img { width: 56px !important; height: auto !important; }
  .promo-banner__wrap .post__content { display: inline-block; width: calc(100% - 77px); vertical-align: top; }
  .promo-banner__wrap .post__content p { display: none; }
  .promo-banner__wrap .post__title { margin: 8px 0; font-size: 16px; line-height: 23px; }
  .promo-banner__content { padding-left: 16px; padding-right: 16px; padding-top: 40px; }
  .promo-banner__title { font-size: 28px; line-height: 36px; margin-bottom: 37px; }
  .promo-banner__action { margin-top: 10px; }
  .promo-banner__action a { display: block; width: 100%; margin-bottom: 16px; margin-right: 0; }
  .promo-banner__action a:last-child { margin-bottom: 0; }
  .promo-banner__action.alt { margin-top: 28px; }
  .promo-banner__img { margin-bottom: 0; }
  .promo-banner__img img { height: auto; } }

.promo-benefit { padding: 64px 0 80px 0; }

.promo-benefit__content { padding-right: 60px; }

.promo-benefit__subtitle, .promo-benefit__meta { font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

.promo-benefit__title { margin-bottom: 28px; }

.promo-benefit__action { margin-top: 30px; }

.promo-benefit__action a { margin-right: 16px; }

.promo-benefit__action a:last-child { margin-right: 0; }

.promo-benefit__label { font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 12px; }

.promo-benefit__img img { object-fit: contain; }

.promo-benefit__img .video video { width: 100%; height: auto; pointer-events: none; }

@media (max-width: 991px) { .promo-benefit { padding: 24px 0; }
  .promo-benefit__wrap { color: #232933; }
  .promo-benefit__wrap .row { flex-direction: column-reverse; }
  .promo-benefit__content { padding-right: 0; }
  .promo-benefit__content .custom-list { margin-bottom: 36px; }
  .promo-benefit__subtitle, .promo-benefit__meta { text-align: center; }
  .promo-benefit__title { font-size: 26px; line-height: 32px; text-align: center; }
  .promo-benefit__action a { width: 100%; display: block; margin-bottom: 16px; margin-right: 0; }
  .promo-benefit__action a:last-child { margin-bottom: 0; }
  .promo-benefit__img { margin-bottom: 30px; display: none; }
  .promo-benefit__img img { height: auto; } }

.promo-steps { padding-top: 80px; padding-bottom: 40px; }

.promo-steps__wrap .section-head__title { text-align: center; margin-bottom: 64px; color: #232933 !important; }

.promo-steps__wrap .post { text-align: center; }

.promo-steps__wrap .post__img img { width: 160px; height: auto; margin-bottom: 16px; }

.promo-steps__wrap .post__title { font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 12px; display: block; font-size: 1.25rem; line-height: 2rem; }

.promo-steps__wrap .post__content { padding-right: 0; }

.promo-steps__wrap .with-arrow .post { position: relative; }

.promo-steps__wrap .with-arrow .post::after { background: url("../images/arrow-dash-dark.svg") no-repeat center; position: absolute; display: inline-block; top: 25%; right: -25%; content: ''; width: 160px; height: 11px; }

.promo-steps__wrap .with-arrow:last-child .post::after { display: none; }

@media (max-width: 991px) { .promo-steps { padding: 24px 0 14px 0; }
  .promo-steps__wrap .post { display: block; text-align: left; padding: 0 24px; }
  .promo-steps__wrap .post__img { display: inline-block; vertical-align: top; width: 56px; margin-right: 16px; }
  .promo-steps__wrap .post__img img { width: 100px; height: auto; }
  .promo-steps__wrap .post__content { display: inline-block; vertical-align: top; width: calc(100% - 77px); }
  .promo-steps__wrap .post__content p { opacity: 0; height: 0; transition: height .5s linear; }
  .promo-steps__wrap .post__title { font-size: 16px; line-height: 23px; margin: 8px 0; position: relative; }
  .promo-steps__wrap .post__title::after { content: ''; display: inline-block; position: absolute; right: -30px; top: 0; width: 25px; height: 25px; background: url("../images/arrow-down.svg") no-repeat center; background-size: contain; transition: .3s linear; }
  .promo-steps__wrap .post.show .post__content p { opacity: 1; height: auto; transition: height .5s linear; }
  .promo-steps__wrap .post.show .post__title::after { transform: rotate(180deg); transition: .3s linear; }
  .promo-steps__wrap .section-head__title { font-size: 28px !important; line-height: 36px !important; margin-bottom: 40px !important; }
  .promo-steps .with-arrow .post { position: relative; padding: 0 24px 56px 24px; margin-bottom: 0; }
  .promo-steps .with-arrow .post::before { content: ''; display: inline-block; position: absolute; left: 50px; top: 86px; height: calc(100% - 125px); width: 2px; border-right: 2px dotted #9090A2; }
  .promo-steps .with-arrow .post::after { content: ''; display: inline-block; position: absolute; width: 30px; height: 20px; bottom: 25px; top: unset; left: 36px; background: url("../images/arrow-down.svg") no-repeat center; background-size: contain; }
  .promo-steps .with-arrow:last-child .post::before, .promo-steps .with-arrow:last-child .post::after { display: none; } }

.section-tabs__header .nav-tabs { background: #F2F4F7; border-radius: 100px; display: inline-flex; padding: 4px; overflow: hidden; }

.section-tabs__header .nav-tabs .nav-item { cursor: pointer; border: 0; }

.section-tabs__header .nav-tabs .nav-item .nav-link { padding: 8px 20px !important; height: auto; position: relative; border: 0; cursor: pointer; background: transparent; display: inline-block; }

.section-tabs__header .nav-tabs .nav-item .nav-link.active { background: #4B61DD; border-radius: 100px; box-shadow: none; }

.section-tabs__header .nav-tabs .nav-item .nav-link.active .nav-item__title label { color: #FFF; margin-bottom: 0; }

.section-tabs__header .nav-tabs .nav-item .nav-link.active:hover { background: #4B61DD; box-shadow: none; }

.section-tabs__header .nav-tabs .nav-item .nav-link.active:hover .nav-item__title label { color: #FFF; }

.section-tabs__header .nav-tabs .nav-item .nav-link.alt.active { background: #FFF; color: #e1eefc; border-radius: 100px; box-shadow: none; border: 1px solid #4B61DD; }

.section-tabs__header .nav-tabs .nav-item .nav-link.alt.active .nav-item__title label { color: #4B61DD; margin-bottom: 0; }

.section-tabs__header .nav-tabs .nav-item .nav-link.alt.active:hover { background: #FFF; color: #e1eefc; border-radius: 100px; box-shadow: none; border: 1px solid #4B61DD; }

.section-tabs__header .nav-tabs .nav-item .nav-link.alt.active:hover .nav-item__title label { color: #4B61DD; margin-bottom: 0; }

.section-tabs__header .nav-tabs .nav-item .nav-link:hover { background: transparent; cursor: pointer; box-shadow: none; }

.section-tabs__header .nav-tabs .nav-item .nav-link:hover .nav-item__title label { color: #232933; }

.section-tabs__header .nav-tabs .nav-item .nav-link .nav-item__title { margin-bottom: 0; cursor: pointer; font-family: "Inter", sans-serif; font-weight: 600; }

.section-tabs__header .nav-tabs .nav-item .nav-link .nav-item__title label { color: #9090A2; margin-bottom: 0; font-family: "Inter", sans-serif; font-weight: 600; }

.section-tabs__body { padding-top: 40px; }

@media (max-width: 768px) { .section-tabs__header { position: relative; text-align: center; }
  .section-tabs__header .nav-tabs { position: relative; height: auto; top: inherit; left: inherit; background: #FFF; } }

@media (max-width: 991px) { .promo-form .form-meeting__title { font-size: 24px; line-height: 32px; margin-bottom: 24px; }
  .promo-form .form-meeting__desc { display: none; } }

.download-cta.mt-70, .cta-block.mt-70 { margin-top: 70px; }

@media (max-width: 479px) { .download-cta.mt-70, .cta-block.mt-70 { margin-top: 20px; } }

.download-cta__box, .cta-block__box { background: #F9FAFB; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); border-radius: 8px; }

.download-cta__meta, .cta-block__meta { padding: 40px 40px 40px 0; }

.download-cta__img img, .cta-block__img img { margin-top: -20%; display: block; max-width: 100%; width: auto; height: auto; }

.download-cta__desc, .cta-block__desc { margin-bottom: 24px; }

.download-cta.download-cta--enterprise, .cta-block.download-cta--enterprise { padding-top: 80px; padding-bottom: 64px; }

.download-cta.download-cta--enterprise .download-cta__img, .cta-block.download-cta--enterprise .download-cta__img { padding-left: 6px; margin-right: -12px; }

.download-cta.download-cta--enterprise .download-cta__img img, .cta-block.download-cta--enterprise .download-cta__img img { margin-top: -20%; }

@media (max-width: 768px) { .download-cta__box, .cta-block__box { padding: 24px 24px 16px; border-radius: 8px; }
  .download-cta__meta, .cta-block__meta { padding: 16px 0 0 0; text-align: center; }
  .download-cta__title, .cta-block__title { font-size: 26px; line-height: 32px; }
  .download-cta__img img, .cta-block__img img { margin-top: 0; margin-left: auto; margin-right: auto; }
  .download-cta.download-cta--enterprise, .cta-block.download-cta--enterprise { padding-top: 80px; padding-bottom: 64px; }
  .download-cta.download-cta--enterprise .download-cta__img, .cta-block.download-cta--enterprise .download-cta__img { padding-left: 0; margin-right: 0; }
  .download-cta.download-cta--enterprise .download-cta__img img, .cta-block.download-cta--enterprise .download-cta__img img { margin-top: 0; max-width: 200px; } }

.cta-block { padding: 40px 0 80px 0; }

.cta-block__box { background: #FFF; }

.cta-block__meta { padding: 48px; }

.cta-block__title { font-size: 32px; line-height: 40px; }

.cta-block__action a { margin-right: 16px; }

.cta-block__action a:last-child { margin-right: 0; }

@media (max-width: 991px) { .cta-block { padding: 0 0 80px 0; }
  .cta-block__title { font-size: 28px; line-height: 36px; }
  .cta-block__action a { margin-right: 0; margin-bottom: 16px; }
  .cta-block__action a:last-child { margin-bottom: 0; }
  .cta-block__meta { padding: 0; } }

.pricing-testi { padding: 48px 0; position: relative; }

.pricing-testi.promo { padding-bottom: 80px; }

.pricing-testi:before { content: ""; display: block; width: 78vw; height: 100%; position: absolute; top: 0; left: 0; background: #F2F4F7; }

.pricing-testi__sub { display: block; color: #545465; margin: 0 0 8px; }

.pricing-testi .more { display: inline-block; color: #334AC0; transition: all .4s ease; }

.pricing-testi .more img, .pricing-testi .more svg { display: inline-block; vertical-align: middle; width: 1.5rem; height: 0.5rem; margin-left: 0.5rem; }

.pricing-testi .more:hover { color: #334AC0; text-decoration: underline; }

.pricing-testi .list-item { display: block; }

.pricing-testi .list-item:not(:first-child) { margin-top: 3rem; }

.pricing-testi .list-item .item { position: relative; padding-left: 64px; margin-bottom: 40px; }

.pricing-testi .list-item .item .icon { position: absolute; top: 0; left: 0; object-fit: contain; object-position: 50% 50%; }

.pricing-testi .list-item .item span { display: block; color: #232933; }

.pricing-testi .list-item .item .number { font-size: 3rem; line-height: 1.167em; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-testi .owl-dots { text-align: left; padding: 0 42px; bottom: 3rem; }

.pricing-testi .owl-dot { display: inline-block; background: transparent !important; }

.pricing-testi .owl-dot span { background-color: #FFF; opacity: 0.3; }

.pricing-testi .owl-dot.active span { opacity: 1; background-color: #FFF; }

.pricing-testi .testi { background: #10253E; margin-left: 6px; margin-right: 6px; border-radius: 0.5rem; }

.pricing-testi .testi .logo { margin-bottom: 2rem; }

.pricing-testi .testi .logo img { display: block; height: 2rem; width: auto; }

.pricing-testi .testi .quote p { font-size: 1.5rem; line-height: 1.333em; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 1.5rem; }

.pricing-testi .testi .quote span { display: block; font-size: 0.875rem; line-height: 1.429em; margin-bottom: 4px; }

.pricing-testi .testi .quote .name { font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-testi .testi .more { display: block; position: absolute; bottom: 2rem; right: 2.5rem; color: #F5D4CF; }

.pricing-testi .testi .more svg path { fill: #F5D4CF; }

.pricing-testi .testi__img { position: relative; width: 100%; padding-top: 42%; }

.pricing-testi .testi__img .btn-play { z-index: 2; display: block; width: 3.75rem; height: 3.75rem; border-radius: 3.75rem; background-image: url(../images/ic-play.svg); background-repeat: no-repeat; background-position: center; position: absolute; bottom: 1.5rem; right: 1.5rem; }

.pricing-testi .testi__img img { z-index: 1; border-radius: 0.5rem 0.5rem 0 0; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; object-fit: cover; object-position: 50% 50%; }

.pricing-testi .testi__body { position: relative; padding: 2rem 2.5rem 2rem 2.5rem; border-radius: 0 0 0.5rem 0.5rem; }

.pricing-testi .testi__body:before { content: ""; display: block; width: 100%; height: 2px; position: absolute; top: 0; left: 0; opacity: 0; background: #FFF; }

.pricing-testi .owl-item .testi__body { padding-bottom: 5rem; }

.pricing-testi:not([class*="theme-"]) .testi__img .btn-play { background-color: #005FBF; box-shadow: 0 0 0 rgba(0, 95, 191, 0.4); animation: primary 2s infinite; }

@keyframes primary { 0% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0.4); }
  70% { box-shadow: 0 0 0 14px rgba(0, 95, 191, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0); } }

.pricing-testi:not([class*="theme-"]) .testi .more { color: #A68AF1; }

.pricing-testi:not([class*="theme-"]) .testi .more svg path { fill: #A68AF1; }

.pricing-testi--alt { background: #F2F4F7; padding: 0; }

.pricing-testi--alt:before { display: none; }

.pricing-testi--alt .pricing-testi__wrap { position: relative; max-width: 83.333%; padding: 32px 0; margin: 0 auto; }

.pricing-testi--alt .pricing-testi__wrap:before { content: ""; display: block; width: 90%; height: 100%; position: absolute; top: 0; right: 0; background: #10253E; }

.pricing-testi--alt .slider-testi-alt { padding-left: 56px; }

.pricing-testi--alt .product-testi__block-img, .pricing-testi--alt .block-testi__block-img { position: relative; width: 100%; padding-top: 100%; }

.pricing-testi--alt .product-testi__block-img img, .pricing-testi--alt .block-testi__block-img img { display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; object-fit: cover; object-position: 50% 50%; border-radius: 8px; }

.pricing-testi--alt .product-testi__block-img .btn-play, .pricing-testi--alt .block-testi__block-img .btn-play { z-index: 2; display: block; width: 3.75rem; height: 3.75rem; border-radius: 3.75rem; background-color: #4B61DD; background-image: url(../images/ic-play-white.svg); background-repeat: no-repeat; background-position: center; position: absolute; bottom: 1.5rem; right: 1.5rem; box-shadow: 0 0 0 rgba(0, 95, 191, 0.4); animation: primaryPulse 2s infinite; }

@keyframes primaryPulse { 0% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0.4); }
  70% { box-shadow: 0 0 0 14px rgba(0, 95, 191, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 95, 191, 0); } }

.pricing-testi--alt .owl-item .testi__body { padding-bottom: 1.5rem; }

.pricing-testi--alt .owl-dots { bottom: 8px; padding: 0; left: 8px; }

.pricing-testi--alt .owl-dot { outline: none !important; }

.pricing-testi--alt .owl-dot span { background: #e2e2e2; opacity: 1; display: block; width: 8px; height: 8px; border-radius: 4px; margin: 0 6px; }

.pricing-testi--alt .owl-dot.active span { background: #4B61DD; border-color: #4B61DD; }

.pricing-testi--alt .testi .quote { max-width: 90%; color: #FFF; }

.pricing-testi--alt .testi .quote p { font-size: 1.5rem; line-height: 1.3333em; margin-bottom: 40px; }

.pricing-testi--alt .testi .more { position: relative; bottom: auto; right: auto; margin-top: 32px; }

.pricing-testi--alt .testi__body { padding: 0 0 32px 0; }

.pricing-testi--alt .testi__body:before { display: none; }

@media (max-width: 991px) { .pricing-testi--alt { padding-top: 0; }
  .pricing-testi--alt.promo { padding-top: 0; padding-bottom: 0; }
  .pricing-testi--alt::before { display: none; }
  .pricing-testi--alt .product-testi__block-img img { border-radius: 8px 8px 0 0; }
  .pricing-testi--alt .pricing-testi__wrap { max-width: 100%; padding: 0; }
  .pricing-testi--alt .pricing-testi__wrap::before { display: none; }
  .pricing-testi--alt .slider-testi-alt { padding-left: 0; }
  .pricing-testi--alt .testi { margin: 0; height: 100%; border-radius: 0 0 8px 8px; padding: 2rem 1.5rem 2rem 1.5rem; }
  .pricing-testi--alt .testi .quote { max-width: 100%; }
  .pricing-testi--alt .owl-dots { position: relative; text-align: center; left: unset; bottom: unset; background: #10253E; margin: 0; padding: 20px 0; }
  .pricing-testi--alt .owl-dots .owl-dot span { background: rgba(255, 255, 255, 0.7); }
  .pricing-testi--alt .owl-dots .owl-dot.active span { background: #FFF; }
  .pricing-testi--alt .owl-stage { display: flex !important; align-items: stretch !important; }
  .pricing-testi--alt .owl-stage .slider-item { height: 100%; } }

@media (max-width: 768px) { .pricing-testi--alt .col-lg-4, .pricing-testi--alt .col-lg-8 { padding: 0; }
  .pricing-testi--alt .testi { border-radius: 0; }
  .pricing-testi--alt .product-testi__block-img img { border-radius: 0; } }

.pricing-socproof { padding-bottom: 64px; padding-top: 64px; background: #F2F4F7; }

.pricing-socproof .item { margin-bottom: 20px; }

.pricing-socproof .item__wrap { padding-left: 16px; }

.pricing-socproof .item__number { position: relative; }

.pricing-socproof .item__number::before { content: ""; display: block; width: 2px; height: 32px; position: absolute; top: calc(50% - 16px); left: -16px; background-color: #005FBF; }

.pricing-socproof .item__number span { color: #005FBF; font-size: 32px; line-height: 48px; font-family: "Inter", sans-serif; font-weight: 500; display: block; margin-bottom: 0; }

.pricing-socproof .item__desc { max-width: 85%; }

@media (max-width: 991px) { .pricing-socproof { padding-top: 48px; } }

.promo-blue { background: radial-gradient(58.93% 675.17% at 52.51% 65.54%, #008CF2 0%, #004DC2 100%); color: #FFF; padding: 40px 0; }

.promo-blue__title { color: #FFF; }

@media (max-width: 768px) { .promo-blue { text-align: center; }
  .promo-blue__title { font-size: 26px; line-height: 32px; }
  .promo-blue__img { margin-top: 30px; } }

@media (max-width: 768px) { .promo-form .form-meeting__title { font-size: 26px; line-height: 32px; } }

.pricing-main { padding-bottom: 0; }

.pricing-main__nav { text-align: center; padding-bottom: 40px; }

.pricing-main__nav .nav { display: inline-block; background: #EDF0F2; border-radius: 24px; padding: 1.5px; }

.pricing-main__nav .nav li { display: inline-block; width: auto; position: relative; }

.pricing-main__nav .nav-item { display: inline-block; font-size: 16px; line-height: 24px; padding: 12px 24px; font-family: "Inter", sans-serif; font-weight: 600; color: #8B95A5; opacity: 0.6; border-radius: 100px; transition: all .4s ease; border: 1.5px solid transparent; }

.pricing-main__nav .nav-item.active { background-color: #FFF; opacity: 1; color: #4B61DD; border: 1.5px solid #4B61DD; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); }

.pricing-main .pricing-cards { padding-bottom: 64px; }

.pricing-main .tnc { position: absolute; top: 100%; right: 12px; display: block; padding-top: 16px; text-align: right; }

.pricing-main .card__wrap { display: flex; flex-direction: column; height: 100%; }

.pricing-main .card__head { padding: 20px; border: 1px solid #E2E2E2; background: #F2F4F7; border-radius: 4px 4px 0 0; min-height: 274px; display: flex; flex-direction: column; }

.pricing-main .card__head.alt { min-height: unset; }

.pricing-main .card__head h3 { font-size: 20px; line-height: 28px; margin-bottom: 8px; }

.pricing-main .card__head h3 .badge { display: inline-block; font-size: 12px; line-height: 20px; padding: 4px 8px; vertical-align: middle; margin-left: 15px; margin-right: 0; color: #FFF; background: #009BDE; position: relative; top: -2px; }

.pricing-main .card__head p { font-size: 14px; line-height: 20px; margin-bottom: 24px; color: #232933; }

.pricing-main .card__head .price { display: flex; flex-direction: column; justify-content: center; margin-top: auto; min-height: 75px; margin-bottom: 24px; }

.pricing-main .card__head .price .sf { display: block; min-height: 20px; margin-bottom: 4px; font-size: 14px; line-height: 20px; }

.pricing-main .card__head .price .pr { display: block; color: #2B2A35; font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; }

.pricing-main .card__head .price .pr small { display: inline-block; color: #626B79; font-size: 14px; line-height: 20px; font-family: "Inter", sans-serif; font-weight: 400; margin-left: 6px; }

.pricing-main .card__head .price span.badge { color: #4B61DD; font-size: 14px; line-height: 20px; font-weight: 400; border: 1px solid #4B61DD; background: #FFF; padding: 4px 12px; border-radius: 14px; margin: 0 0 0 8px; text-decoration: none; }

.pricing-main .card__head .price span.label { font-size: 14px; line-height: 20px; margin-bottom: 0; display: block; color: #626B79; text-decoration: line-through; }

.pricing-main .card__head .price span.tc { font-size: 14px; line-height: 20px; color: #626B79; }

.pricing-main .card__toggle { position: relative; padding: 16px 40px 16px 20px; color: #009BDE; cursor: pointer; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-main .card__toggle:hover { color: #0077ab; }

.pricing-main .card__toggle::after { content: ""; display: block; width: 24px; height: 24px; background: url("../images/ic-chevron-up-cyan.svg") no-repeat center; background-size: contain; position: absolute; top: calc(50% - 12px); right: 16px; transform: rotate(0); transition: all .3s ease; }

.pricing-main .card__toggle.collapsed::after { transform: rotate(180deg); }

.pricing-main .card__collapse-wrap { padding: 8px 20px 40px; }

.pricing-main .card__main { display: flex; flex-direction: column; height: 100%; padding: 0; border: 1px solid #E2E2E2; border-top: 0; background: #FFF; border-radius: 0 0 4px 4px; }

.pricing-main .card__main span.cat { display: block; font-size: 14px; line-height: 20px; color: #2B2A35; margin-bottom: 4px; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-main .card__main .card__collapse-wrap span.cat { font-size: 16px; line-height: 28px; text-decoration: underline; }

.pricing-main .card__main ul.custom-list:not(:last-child) { margin-bottom: 24px; }

.pricing-main .card__main ul.custom-list > li { padding-left: 32px; margin-bottom: 8px; }

.pricing-main .card__main ul.custom-list > li:last-child { margin-bottom: 0; }

.pricing-main .card__main .card__bonus { padding: 16px 12px; }

.pricing-main .card__main .card__bonus span.cat { margin-bottom: 16px; font-size: 16px; line-height: 24px; }

.pricing-main .card__main .card__bonus .box { padding: 12px; background-color: #FFF5DC; border-radius: 4px; }

.pricing-main .card--highlight .card__wrap { box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); }

.pricing-main .card--highlight .card__head { border-color: #009BDE; background: #EDFAFF; }

.pricing-main .card--highlight .card__main { border-color: #009BDE; }

.pricing-main .cta-all-feature { padding-top: 48px; }

@media (max-width: 991px) { .pricing-main { padding-bottom: 0; }
  .pricing-main .pricing-cards { padding-bottom: 32px; }
  .pricing-main__nav { padding-bottom: 32px; position: relative; }
  .pricing-main__nav .nav { position: relative; width: auto; top: 0; }
  .pricing-main__nav .nav .nav-item { position: relative; width: auto; left: 0; }
  .pricing-main__nav .nav .nav-item.active::after { display: none; }
  .pricing-main .card { margin-bottom: 24px; } }

@media (max-width: 768px) { .pricing-main .pricing-cards { flex-direction: column-reverse; } }

.recom-addon { padding-top: 64px; padding-bottom: 64px; }

.recom-addon h2 { margin-bottom: 32px; text-align: center; }

.recom-addon .item { margin-bottom: 24px; }

.recom-addon .item__title { margin-bottom: 16px; transition: all .3s ease; }

.recom-addon .item__title .icon { display: inline-block; width: 24px; height: 24px; vertical-align: middle; margin-right: 12px; }

.recom-addon .item__content { margin-bottom: 16px; }

.recom-addon .item__wrap { border: 1px solid #E2E2E2; border-radius: 4px; padding: 20px; position: relative; }

.recom-addon .item__wrap[data-toggle] { cursor: pointer; box-shadow: 0px 0px 0px rgba(119, 119, 119, 0.1), 0px 0px 0px rgba(119, 119, 119, 0.2); transition: all .3s ease; }

.recom-addon .item__wrap[data-toggle] .item__title { position: relative; }

.recom-addon .item__wrap[data-toggle] .item__title:after { content: ""; display: block; width: 24px; height: 24px; position: absolute; right: 8px; opacity: 0; top: 4px; background: url(../images/ic-arrow-down-blue.svg); transform: rotate(-90deg); transition: all .3s ease; }

.recom-addon .item__wrap[data-toggle]:before { content: ""; display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; border-radius: 4px; border: 2px solid transparent; transition: all .3s ease; }

.recom-addon .item__wrap[data-toggle]:hover { box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); border: 1px solid #005FBF; }

.recom-addon .item__wrap[data-toggle]:hover .item__title { color: #005FBF; }

.recom-addon .item__wrap[data-toggle]:hover .item__title:after { right: 0; opacity: 1; }

.recom-addon .item:first-child:last-child { margin-left: auto; margin-right: auto; }

@media (max-width: 991px) { .recom-addon { padding-top: 48px; padding-bottom: 48px; }
  .recom-addon h2 { margin-bottom: 24px; } }

@media (max-width: 768px) { .recom-addon { padding-left: 12px; padding-right: 12px; } }

.modal-add-ons .item { margin-bottom: 24px; }

.modal-add-ons .item__title { font-size: 18px; line-height: 28px; margin-bottom: 20px; font-family: "Inter", sans-serif; font-weight: 500; }

.modal-add-ons .item__desc { margin-bottom: 24px; }

.modal-add-ons .item__price { display: block; }

.modal-add-ons .item__price:not(:last-child) { margin-bottom: 24px; }

.modal-add-ons .item__price .sf { display: block; min-height: 20px; margin-bottom: 4px; font-size: 14px; line-height: 20px; }

.modal-add-ons .item__price .pr { display: block; color: #2B2A35; font-size: 24px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; }

.modal-add-ons .item__price .pr small { display: inline-block; color: #545465; font-size: 14px; line-height: 20px; font-family: "Inter", sans-serif; font-weight: 400; margin-left: 6px; }

.modal-add-ons .item__list { margin-bottom: 28px; }

.modal-add-ons .item__list span.cat { display: block; color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 8px; }

.modal-add-ons .item__wrap { padding: 24px 20px; height: 100%; border: 1px solid #E2E2E2; border-radius: 4px; }

.recom-bundle { padding-top: 64px; padding-bottom: 64px; }

.recom-bundle h2 { text-align: center; margin-bottom: 48px; }

.recom-bundle__nav .item { position: relative; padding: 20px 60px 20px 32px; background: #FFF; border: 1px solid #E2E2E2; border-radius: 4px; margin-bottom: 12px; transition: all .3s ease; }

.recom-bundle__nav .item[data-toggle] { cursor: pointer; }

.recom-bundle__nav .item:after { content: ""; display: block; width: 24px; height: 24px; background: url("../images/ic-chevron-right-round.svg") no-repeat center; background-size: contain; position: absolute; top: calc(50% - 12px); right: 30px; opacity: 0; transition: all .3s ease; }

.recom-bundle__nav .item__logo { position: relative; margin-bottom: 12px; }

.recom-bundle__nav .item__logo img { display: block; height: 26px; width: auto; }

.recom-bundle__nav .item.active { border-color: #005FBF; background: #F9E2DE80; }

.recom-bundle__nav .item.active:after { opacity: 1; right: 24px; }

.recom-bundle__nav .item:not(.active):hover { background: #F2F4F7; }

.recom-bundle .tab-pane { padding: 32px; border: 1px solid #E2E2E2; border-radius: 4px; }

.recom-bundle .tab-pane__sm { display: none; }

.recom-bundle .tab-pane h3 { margin-bottom: 16px; }

.recom-bundle .tab-pane span.cat { color: #2B2A35; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; display: block; margin-bottom: 8px; }

.recom-bundle .tab-pane ul.custom-list > li { margin-bottom: 8px; }

.recom-bundle .tab-pane ul.custom-list > li:last-child { margin-bottom: 0; }

.recom-bundle .tab-pane .price:not(:last-child) { margin-bottom: 24px; }

.recom-bundle .tab-pane .price .ps { font-size: 14px; line-height: 20px; color: #777; }

.recom-bundle .tab-pane .price .pn { font-size: 28px; line-height: 48px; font-family: "Inter", sans-serif; font-weight: 500; color: #2B2A35; }

.recom-bundle .tab-pane .price .pt { font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; color: #2B2A35; }

.recom-bundle .tab-pane .tab-action { padding-top: 32px; }

@media (max-width: 991px) { .recom-bundle { padding-top: 48px; padding-bottom: 48px; }
  .recom-bundle h2 { margin-bottom: 24px; }
  .recom-bundle__nav { display: none; }
  .recom-bundle .tab-pane { margin-bottom: 24px; display: block; }
  .recom-bundle .tab-pane__sm { display: block; }
  .recom-bundle .tab-pane__sm .item__logo { margin-bottom: 12px; }
  .recom-bundle .tab-pane__sm .item__desc { margin-bottom: 24px; }
  .recom-bundle .tab-pane .hide-sm { display: none; }
  .recom-bundle .tab-pane:last-child { margin-bottom: 0; } }

.recom-bundle .tnc { display: block; padding-top: 16px; font-size: 14px; line-height: 20px; color: #545465; }

.recom-bundle .bundle { display: flex; }

.recom-bundle .bundle__logo img { display: block; height: 48px; width: auto; }

.recom-bundle .bundle__price { margin-top: auto; margin-bottom: 12px; }

.recom-bundle .bundle__price .sf { display: block; margin-block-end: 8px; }

.recom-bundle .bundle__price .pr { display: block; font-size: 40px; line-height: 48px; letter-spacing: -0.5px; font-family: "Inter", sans-serif; font-weight: 500; }

.recom-bundle .bundle__price .pr small { display: inline-block; font-size: 18px; line-height: 28px; margin-left: 4px; color: #545465; }

.recom-bundle .bundle__price .pr--alt { font-size: 30px; line-height: 48px; }

.recom-bundle .bundle__action .btn { width: 100%; }

.recom-bundle .bundle span.cat { display: block; font-size: 18px; line-height: 28px; color: #2B2A35; margin-bottom: 12px; font-family: "Inter", sans-serif; font-weight: 500; }

.recom-bundle .bundle__head { padding: 32px; background: #E5EFF8; border: 1px solid #005FBF; border-radius: 4px 0 0 4px; width: 100%; max-width: 38%; flex: 0 0 38%; display: flex; flex-direction: column; }

.recom-bundle .bundle__head.theme--klikpajak { background: #FBF3DD; border-color: #F96D01; }

.recom-bundle .bundle__main { padding: 32px; background: #FFF; border: 1px solid #E2E2E2; border-left: 0; border-radius: 0 4px 4px 0; width: 100%; max-width: 62%; flex: 0 0 62%; }

@media (max-width: 991px) { .recom-bundle .bundle { flex-wrap: wrap; }
  .recom-bundle .bundle__logo img { height: 32px; width: auto; }
  .recom-bundle .bundle__price { padding-top: 32px; }
  .recom-bundle .bundle__price .sf { margin-bottom: 4px; }
  .recom-bundle .bundle__price .pr { font-size: 28px; line-height: 36px; }
  .recom-bundle .bundle__price .pr small { font-size: 16px; line-height: 24px; }
  .recom-bundle .bundle__head { max-width: 100%; flex: 0 0 100%; padding: 24px 24px 16px; border-radius: 4px 4px 0 0; }
  .recom-bundle .bundle__main { max-width: 100%; flex: 0 0 100%; padding: 24px; border-radius: 0 0 4px 4px; border-left: 1px solid #E2E2E2; border-top: 0; } }

@media (max-width: 768px) { .recom-bundle { padding-left: 12px; padding-right: 12px; } }

.our-customer.pr-new { padding: 64px 0; }

.our-customer.pr-new .our-customer__title { max-width: 100%; }

@media (max-width: 991px) { .our-customer.pr-new { padding: 48px 8px; } }

.block-cta { text-align: center; position: relative; }

.block-cta .container { padding-top: 64px; padding-bottom: 64px; }

.block-cta__icon { margin-bottom: 8px; }

.block-cta__icon img { display: block; height: 72px; width: auto; margin: 0 auto; }

.block-cta__title { font-size: 40px; line-height: 48px; margin-bottom: 20px; }

.block-cta__title.small { font-size: 24px; line-height: 32px; }

.block-cta__desc { max-width: 600px; margin: 0 auto 32px; }

.block-cta__buttons a { margin-right: 16px; }

.block-cta__buttons a:last-child { margin-right: 0; }

.block-cta hr.separator { max-width: 1224px; margin: 0 auto; }

.block-cta--trial { background-color: #002A38; background-image: url("../images/pattern-cta.svg"); color: #FFF; }

.block-cta--trial .block-cta__title { color: #FFF; }

.block-cta--trial .block-cta__desc { color: #FFF; }

.block-cta.bg-snow { background: #F2F4F7; }

.block-cta--duo .col-lg-6 { position: relative; padding-left: 7%; padding-right: 7%; }

.block-cta--duo .col-lg-6::after { content: ''; display: inline-block; height: 80%; width: 1px; position: absolute; background: #e2e2e2; top: 10%; right: 0; }

.block-cta--duo .col-lg-6:last-child::after { display: none; }

@media (max-width: 991px) { .block-cta__title, .block-cta__title.small { font-size: 24px; line-height: 32px; }
  .block-cta .container { padding-top: 48px; padding-bottom: 48px; padding-left: 8px; padding-right: 8px; }
  .block-cta--duo .col-lg-6 { padding-bottom: 40px; }
  .block-cta--duo .col-lg-6::after { width: 80%; height: 1px; bottom: 0; left: 10%; top: unset; right: unset; }
  .block-cta--duo .col-lg-6:last-child { padding-top: 40px; padding-bottom: 0; }
  .block-cta__actions a { margin-right: 0; margin-bottom: 16px; width: 100%; }
  .block-cta__actions a:last-child { margin-bottom: 0; } }

.block-banner { padding-top: 24px; padding-bottom: 24px; }

.block-banner__title { font-size: 18px; line-height: 28px; margin-bottom: 24px; }

.block-banner__box { position: relative; padding: 60px 48px; background-color: #F8FAFC; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); border-radius: 8px; }

.block-banner__content { max-width: 60%; }

.block-banner__img { position: absolute; right: 0; bottom: 0; height: 100%; width: 40%; }

.block-banner__img img { position: absolute; width: 100%; height: 100%; object-fit: contain; object-position: bottom center; }

@media (max-width: 991px) { .block-banner { padding-left: 12px; padding-right: 12px; }
  .block-banner__box { padding: 24px; }
  .block-banner__content { max-width: 100%; }
  .block-banner__buttons .link-more { padding-top: 0; padding-bottom: 0; }
  .block-banner__img { display: none; } }

.faq-block.pr-new { background-color: #FFF; padding-left: 12px; padding-right: 12px; }

.sb { position: relative; display: block; top: 0; left: 0; width: 100%; z-index: 200; background: #EEF0FF; }

.sb__wrap { max-width: 800px; margin: 0 auto; padding: 16px; display: flex; justify-content: space-between; align-items: center; }

.sb__close { z-index: 1; position: absolute; right: 16px; top: calc(50% - 10px); color: #525151; font-family: Arial,sans-serif; font-size: 20px; line-height: 20px; font-weight: 400; height: 20px; width: 20px; border-radius: 50%; background-color: rgba(255, 255, 255, 0.6); outline: none; padding: 0; text-align: center; transform: none; transition: transform .2s ease-in-out,background-color .2s ease-in-out; cursor: pointer; }

.sb__close:hover { background: #FFF; transform: scale(1.1); }

.sb__content { text-align: left; color: #FFF; }

.sb__content h4 { font-size: 20px; line-height: 32px; margin: 0 0 4px; }

@media (max-width: 768px) { .sb__content h4 { font-size: 14px; line-height: 20px; } }

.sb__content p { font-size: 14px; line-height: 20px; color: #2B2A35; }

@media (max-width: 768px) { .sb__content p { font-size: 11px; line-height: 20px; } }

.sb__content p a { color: #1357FF; font-weight: normal; text-decoration: underline; }

.sb__content .badge { font-size: 12px; line-height: 16px; background: #706FD3; border-radius: 4px; color: #FFF; text-transform: uppercase; padding: 5px 8px; margin-right: 8px; font-family: "Inter", sans-serif; font-weight: 600; }

.sb__cta { margin-left: 48px; }

.sb__cta .btn { font-size: 14px; line-height: 34px; border-radius: 3px; box-shadow: none; background: #FFFFFF; border-color: #FFFFFF; color: #143A72; }

.sb__cta .btn:hover { background: #FFFFFF; border-color: #FFFFFF; color: #143A72; opacity: .85; }

.sb.custom-cs { background: radial-gradient(58.93% 675.17% at 52.51% 65.54%, #008CF2 0%, #004DC2 100%); }

.sb.custom-cs .sb__wrap { max-width: 1000px; padding-top: 12px; padding-bottom: 12px; }

.sb.custom-cs .sb__close { color: #005FBF; }

.sb.custom-cs .sb__content p { color: #FFF; font-size: 20px; line-height: 30px; }

.sb.custom-cs .sb__content span { display: inline-block; vertical-align: middle; }

.sb.custom-cs .sb__content span.large { font-size: 32px; line-height: 40px; font-weight: 900; margin-left: 24px; }

.sb.custom-cs .sb__cta .btn { color: #005FBF; }

.sb.custom-cs .sb__cta .btn:hover { opacity: 1; background: transparent; color: #FFF; }

@media (max-width: 768px) { .sb { position: fixed; top: auto; bottom: 0; z-index: 2; }
  .sb__wrap { flex-wrap: wrap; }
  .sb__close { right: 10px; top: 10px; width: 18px; height: 18px; line-height: 18px; background: #FFF; }
  .sb__content, .sb__cta { width: 100%; text-align: center; }
  .sb__cta { text-align: center; margin: 12px 0 0; } }

.masthead--industry .masthead__content { padding-top: 32px; padding-bottom: 32px; }

.masthead--industry .masthead__desc { margin-bottom: 24px; }

@media (max-width: 991px) { .masthead--industry { padding-left: 12px; padding-right: 12px; }
  .masthead--industry .masthead__content { padding-bottom: 0; padding-left: 0; padding-right: 0; }
  .masthead--industry .masthead__title { font-size: 28px; line-height: 36px; }
  .masthead--industry .masthead__desc { margin-bottom: 32px; }
  .masthead--industry .masthead__media { padding-top: 32px; display: none; } }

.industry-clients__title { font-size: 24px; line-height: 32px; text-align: center; max-width: 648px; margin: 0 auto 32px; }

.industry-clients__list { display: flex; justify-content: center; flex-wrap: wrap; }

.industry-clients__list .item { display: flex; align-items: center; justify-content: center; padding: 4px 12px; height: 64px; width: 100%; max-width: 12.5%; flex: 0 0 12.5%; }

.industry-clients__list .item img { display: block; max-height: 56px; max-width: 100%; height: auto; width: auto; }

.industry-clients__action { padding-top: 40px; text-align: center; }

.industry-clients__wrap { padding-top: 80px; padding-bottom: 80px; }

@media (max-width: 991px) { .industry-clients { padding-left: 12px; padding-right: 12px; }
  .industry-clients__title { font-size: 20px; line-height: 28px; }
  .industry-clients__wrap { padding-top: 64px; padding-bottom: 64px; }
  .industry-clients__list .item { max-width: 25%; flex: 0 0 25%; } }

.page-tabs--industry { padding-top: 80px; }

@media (max-width: 991px) { .page-tabs--industry { padding-top: 24px; padding-left: 8px; padding-right: 8px; }
  .page-tabs--industry .page-tabs__header { padding: 0 12px 48px; margin-bottom: 24px; }
  .page-tabs--industry .mobile-section { padding-left: 12px; padding-right: 12px; }
  .page-tabs--industry .page-tabs__dropdown { margin: 0; } }

.fitur-blocks--industry { padding-top: 64px; }

.fitur-blocks--industry .section-head { padding-bottom: 0; }

.fitur-blocks--industry .section-head__content { margin-bottom: 40px; }

.fitur-blocks--industry .section-head__action .btn { margin-left: 8px; margin-right: 8px; margin-bottom: 16px; }

.fitur-blocks--industry .fitur-block { padding-top: 64px; padding-bottom: 64px; }

.fitur-blocks--industry .fitur-block ul.custom-list > li { margin-bottom: 12px; }

.fitur-blocks--industry .fitur-block__action { padding-top: 24px; }

@media (max-width: 991px) { .fitur-blocks--industry .section-head { padding-left: 12px; padding-right: 12px; }
  .fitur-blocks--industry .section-head .btn { margin-left: 0; margin-right: 0; }
  .fitur-blocks--industry .section-head__title { font-size: 24px; line-height: 32px; }
  .fitur-blocks--industry .fitur-block { padding: 48px 12px; }
  .fitur-blocks--industry .fitur-block__title { font-size: 24px; line-height: 32px; } }

.testimonial-industry { background: #F2F4F7; }

.testimonial-industry__wrap { padding-bottom: 48px; }

.testimonial-industry .item__quote { font-size: 24px; line-height: 32px; margin-bottom: 16px; font-family: "Inter", sans-serif; font-weight: 500; }

.testimonial-industry .item__author .author-logo { display: inline-block; vertical-align: middle; padding-right: 24px; }

.testimonial-industry .item__author .author-logo img { height: 32px; width: auto; display: block; }

.testimonial-industry .item__author .auhtor-content { display: inline-block; vertical-align: middle; padding: 12px 24px; border-left: 1px solid #E2E2E2; }

.testimonial-industry .item__author .auhtor-content span { display: block; }

.testimonial-industry .item__author .auhtor-content .name { font-family: "Inter", sans-serif; font-weight: 500; }

.testimonial-industry .item__img { position: absolute; top: 0; left: 12px; width: 20%; padding: 36px 0; }

.testimonial-industry .item__img .img { display: block; width: 100%; padding-top: 100%; position: relative; }

.testimonial-industry .item__img .img img { display: block; width: 100%; height: 100%; border-radius: 8px; position: absolute; top: 0; left: 0; object-fit: cover; object-position: center; }

.testimonial-industry .item__box { margin-left: 10%; background: #FFF; padding: 48px 40px 48px 14%; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); border-radius: 6px; }

@media (max-width: 991px) { .testimonial-industry { padding-left: 24px; padding-right: 24px; padding-bottom: 64px; }
  .testimonial-industry__wrap { padding-bottom: 0; }
  .testimonial-industry .item__box { margin-left: 0; margin-top: 50%; padding: 24px; }
  .testimonial-industry .item__img { position: relative; left: 0; width: 100%; margin-top: -50%; margin-bottom: 32px; text-align: center; padding: 0; }
  .testimonial-industry .item__img .img { width: 208px; height: 208px; padding-top: 0; display: inline-block; }
  .testimonial-industry .item__quote { font-size: 20px; line-height: 30px; margin-bottom: 32px; }
  .testimonial-industry .item__author .author-logo { display: block; margin-bottom: 16px; }
  .testimonial-industry .item__author .auhtor-content { display: block; padding: 0; border-left: 0; } }

.advantage--industry .section-head__content { margin-bottom: 40px; }

@media (max-width: 991px) { .advantage--industry { padding: 64px 12px 32px; }
  .advantage--industry .section-head { padding-bottom: 32px; }
  .advantage--industry .post { margin-bottom: 32px; } }

.block-addons { background: #011C40; margin-bottom: 80px; }

.block-addons__title { color: #FFF; text-align: center; }

.block-addons__buttons { text-align: center; margin-bottom: 56px; }

.block-addons .package { background: #FFF; padding: 32px 24px; display: flex; flex-direction: column; border-radius: 6px; height: 100%; position: relative; }

.block-addons .package__title { font-size: 32px; line-height: 40px; margin-bottom: 32px; font-family: "Inter", sans-serif; font-weight: 500; }

.block-addons .package__price { margin-bottom: 24px; }

.block-addons .package__price b { display: inline-block; font-size: 24px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; }

.block-addons .package__price small { font-size: 16px; line-height: 24px; display: inline-block; margin-left: 4px; }

.block-addons .package__desc { margin-bottom: 60px; max-height: 250px; }

.block-addons .package__action { position: absolute; padding: 32px 24px; left: 0; bottom: 0; width: 100%; }

.block-addons .items-addons { position: relative; padding-left: 48px; margin-left: -8px; margin-right: -8px; }

.block-addons .items-addons::before { content: "+"; display: block; width: 30px; height: 48px; position: absolute; top: calc(50% - 24px); left: 9px; font-size: 40px; line-height: 48px; color: #FFF; font-weight: 500; }

.block-addons .items-addons .col-md-4 { padding-left: 8px; padding-right: 8px; }

.block-addons .items-addons .item { background: #FFF; padding: 32px 24px; display: flex; flex-direction: column; border-radius: 6px; height: 100%; position: relative; }

.block-addons .items-addons .item__icon { margin-bottom: 8px; }

.block-addons .items-addons .item__icon img { display: block; height: 40px; width: auto; }

.block-addons .items-addons .item__title { font-size: 18px; line-height: 28px; margin-bottom: 24px; font-family: "Inter", sans-serif; font-weight: 500; }

.block-addons .items-addons .item__price { margin-bottom: 24px; }

.block-addons .items-addons .item__price span { display: block; }

.block-addons .items-addons .item__price b { display: inline-block; font-size: 18px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; }

.block-addons .items-addons .item__price small { font-size: 16px; line-height: 24px; display: inline-block; margin-left: 4px; }

.block-addons .items-addons .item__desc { margin-bottom: 60px; max-height: 250px; }

.block-addons .items-addons .item__action { position: absolute; padding: 32px 24px; left: 0; bottom: 0; width: 100%; }

.block-addons__wrap { padding-top: 80px; padding-bottom: 80px; }

@media (max-width: 991px) { .block-addons__wrap { padding: 64px 24px; }
  .block-addons .items-addons { padding-left: 0; padding-top: 64px; }
  .block-addons .items-addons::before { top: 6px; left: calc(50% - 16px); }
  .block-addons .items-addons .col-md-4 { margin-bottom: 16px; }
  .block-addons .items-addons .item { display: block; position: relative; }
  .block-addons .items-addons .item::after { content: ''; display: inline-block; position: absolute; width: 18px; height: 18px; background: url("../images/ic-chevron-down.svg") no-repeat center; background-size: contain; top: 35px; right: 15px; trasition: all linear .3s; }
  .block-addons .items-addons .item__icon { display: inline-block; vertical-align: middle; width: 32px; height: 32px; margin-right: 16px; }
  .block-addons .items-addons .item__icon img { width: 32px; height: 32px; }
  .block-addons .items-addons .item__title { display: inline-block; vertical-align: middle; font-size: 18px; line-height: 28px; margin-bottom: 8px; }
  .block-addons .items-addons .item__price, .block-addons .items-addons .item__desc { padding-left: 52px; }
  .block-addons .items-addons .item__price { margin-bottom: 0; }
  .block-addons .items-addons .item__desc { display: none; margin-top: 16px; }
  .block-addons .items-addons .item.show::after { transform: rotateZ(180deg); trasition: all linear .3s; }
  .block-addons .items-addons .item.show .item__desc { display: block; } }

.block-cs { padding-bottom: 80px; }

.block-cs .item { border-radius: 8px; overflow: hidden; }

.block-cs .item__wrap { height: 100%; }

.block-cs .item__left { background: #011C40; padding: 40px; color: #FFF; }

.block-cs .item__left .author { margin-bottom: 24px; }

.block-cs .item__left .author__img { display: inline-block; vertical-align: middle; }

.block-cs .item__left .author__img img { display: block; width: 60px; height: 60px; object-fit: cover; object-position: center; border-radius: 60px; background-color: #6CAEBE; }

.block-cs .item__left .author__content { display: inline-block; vertical-align: middle; padding-left: 20px; }

.block-cs .item__left .author__content span { display: block; font-size: 14px; line-height: 20px; }

.block-cs .item__left .quote { font-size: 24px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; }

.block-cs .item__left.alt { background: #005FBF; }

.block-cs .item__right { background-color: #F2F4F7; padding: 40px 32px; }

.block-cs .item__right .number { color: #048444; font-size: 64px; line-height: 72px; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 12px; }

.block-cs .item__right .desc { font-size: 24px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 32px; }

.block-cs .slider-cs-dot { margin: 0; padding: 32px 0 24px; display: flex; flex-wrap: wrap; justify-content: center; }

.block-cs .slider-cs-dot .owl-dot { width: auto; height: 40px; margin: 0 24px; background-color: transparent; cursor: pointer; }

.block-cs .slider-cs-dot .owl-dot img { display: block; height: 40px; width: auto; opacity: 0.4; margin-bottom: 20px; transition: all .4s ease; }

.block-cs .slider-cs-dot .owl-dot .bar { display: block; position: relative; width: 100%; height: 4px; border-radius: 2px; background-color: #EDF6FB; overflow: hidden; opacity: 0; transition: all .4s ease; }

.block-cs .slider-cs-dot .owl-dot .bar span { display: block; position: absolute; width: 100%; height: 100%; background-color: #4B61DD; }

.block-cs .slider-cs-dot .owl-dot.active { background-color: transparent; }

.block-cs .slider-cs-dot .owl-dot.active img { filter: initial; opacity: 1; }

.block-cs .slider-cs-dot .owl-dot.active .bar { opacity: 1; }

.block-cs .slider-cs-dot .owl-dot.active .bar span { animation: progressBar 8s linear; }

.block-cs__buttons { padding-top: 48px; }

.block-cs .section-head { padding: 80px 0 64px; }

.block-cs .slider-cs.no-slider { display: block; }

.block-cs .slider-cs.no-slider + .slider-cs-dot .owl-dot img { opacity: 1; }

@media (max-width: 991px) { .block-cs { padding-left: 12px; padding-right: 12px; padding-bottom: 64px; }
  .block-cs .item__left { padding: 24px 32px; }
  .block-cs .item__left .quote { font-size: 18px; line-height: 28px; }
  .block-cs .item__right { padding: 24px 32px; }
  .block-cs .item__right .number { font-size: 48px; line-height: 1.25em; }
  .block-cs .item__right .desc { font-size: 18px; line-height: 28px; margin-bottom: 24px; }
  .block-cs .slider-cs-dot { display: none; }
  .block-cs .section-head { padding: 60px 0; }
  .block-cs .section-head__title { font-size: 24px; line-height: 32px; } }

.home-why { background: #F2F4F7; }

.home-why--center .home-why__desc { margin-bottom: 48px; }

.home-why__sub { font-size: 14px; line-height: 20px; margin-bottom: 12px; color: #626B79; }

.home-why__title { font-size: 40px; line-height: 48px; margin-bottom: 28px; }

.home-why__desc { margin-bottom: 48px; }

.home-why__img img { display: block; width: 100%; height: auto; }

.home-why .item { height: 100%; }

.home-why .item__img { position: relative; margin-bottom: 12px; }

.home-why .item__img .img { display: inline-block; padding: 18px; background: #EDF6FB; border-radius: 16px; }

.home-why .item__img img { display: block; height: 44px; width: auto; }

.home-why .item__title { font-size: 18px; line-height: 28px; margin-bottom: 12px; }

.home-why .item__wrap { padding: 32px; background: #fff; border-radius: 8px; display: block; }

.home-why .item__desc { color: #2B2A35; }

.home-why__wrap { padding-top: 80px; padding-bottom: 80px; }

@media (max-width: 991px) { .home-why__img { margin-bottom: 16px; }
  .home-why__img img { max-width: 80%; }
  .home-why__title { font-size: 24px; line-height: 32px; margin-bottom: 16px; }
  .home-why__desc { margin-bottom: 32px; }
  .home-why .item__img .img { padding: 12px; }
  .home-why .item__img .img img { height: 32px; width: auto; }
  .home-why .item__wrap { padding: 24px; margin-bottom: 18px; }
  .home-why__wrap { padding-top: 64px; padding-bottom: 64px; padding-left: 20px; padding-right: 20px; } }

.features-accr .accordion--section .card { padding-top: 80px; }

.features-accr .accordion--section .card:nth-child(even) { background: #F2F4F7; }

.features-accr .accordion--section .card:nth-child(even) .section-tabs__header .nav-tabs { background: #FFF; }

.features-accr .accordion--section .card .card__header { border: 0; text-align: center; }

.features-accr .accordion--section .card .card__header .card__title { font-size: 40px; line-height: 48px; margin-bottom: 28px; }

.features-accr .accordion--section .card .card__header .card__desc { margin-bottom: 32px; }

.features-accr .accordion--section .card .card__header::after { display: none; }

.features-accr .accordion--section .card .collapse { display: block !important; }

@media (max-width: 991px) { .features-accr .accordion--section { padding-left: 8px; padding-right: 8px; }
  .features-accr .accordion--section .card { padding-top: 40px; padding-bottom: 14px; }
  .features-accr .accordion--section .card:nth-child(even) { background: #F2F4F7; }
  .features-accr .accordion--section .card:nth-child(even) .section-tabs__header .nav-tabs { background: #FFF !important; }
  .features-accr .accordion--section .card .card__header { position: relative; }
  .features-accr .accordion--section .card .card__header .card__title { font-size: 28px; line-height: 36px; margin-bottom: 24px; }
  .features-accr .accordion--section .card .card__header .card__toggle { margin-bottom: 40px; }
  .features-accr .accordion--section .card .card__header .card__toggle a.link-show { display: show; }
  .features-accr .accordion--section .card .card__header .card__toggle a.link-close { display: none; }
  .features-accr .accordion--section .card .card__header .card__desc { display: none; }
  .features-accr .accordion--section .card .collapse { display: none !important; }
  .features-accr .accordion--section .card .collapse .section-tabs ul.nav-tabs { width: auto; background: #F2F4F7; }
  .features-accr .accordion--section .card .collapse .section-tabs ul.nav-tabs .nav-link { opacity: 1; visibility: visible; max-height: auto !important; }
  .features-accr .accordion--section .card .collapse .section-tabs .tab-content { padding-left: 12px; padding-right: 12px; }
  .features-accr .accordion--section .card .collapse .promo-benefit__wrap { margin-bottom: 30px; }
  .features-accr .accordion--section .card .collapse .promo-benefit__title { font-size: 24px; line-height: 32px; text-align: left; }
  .features-accr .accordion--section .card .collapse .promo-benefit__title br { display: none; }
  .features-accr .accordion--section .card .collapse .testimonial-small__wrap { padding: 15px 0 60px; }
  .features-accr .accordion--section .card .collapse .block-cta-alt .container { padding-top: 0; padding-bottom: 30px; }
  .features-accr .accordion--section .card .collapse .block-cta-alt .container .block-cta-alt__title.small { font-size: 24px; line-height: 32px; }
  .features-accr .accordion--section .card .collapse .promo-benefit__wrap .row { flex-direction: row-reverse; }
  .features-accr .accordion--section .card .collapse .testimonial-small .item__img { position: absolute; width: 208px; height: 208px; left: calc(50% - 104px); top: 0; }
  .features-accr .accordion--section .card .collapse .testimonial-small .item__box { padding: 140px 24px 24px 24px; margin-top: 104px; }
  .features-accr .accordion--section .card.open .card__header .card__desc { display: block; }
  .features-accr .accordion--section .card.open .card__header .card__toggle a.link-show { display: none; }
  .features-accr .accordion--section .card.open .card__header .card__toggle a.link-close { display: block; }
  .features-accr .accordion--section .card.open .collapse { display: block !important; } }

.testimonial-small__wrap { padding: 80px 0; }

.testimonial-small .item__quote { font-size: 24px; line-height: 32px; margin-bottom: 16px; font-family: "Inter", sans-serif; font-weight: 500; }

.testimonial-small .item__author .author-logo { display: inline-block; vertical-align: middle; padding-right: 24px; }

.testimonial-small .item__author .author-logo img { height: 32px; width: auto; display: block; }

.testimonial-small .item__author .auhtor-content { display: inline-block; vertical-align: middle; padding: 12px 24px; border-left: 1px solid #E2E2E2; }

.testimonial-small .item__author .auhtor-content span { display: block; }

.testimonial-small .item__author .auhtor-content .name { font-family: "Inter", sans-serif; font-weight: 500; }

.testimonial-small .item__img { position: absolute; top: 0; left: 12px; width: 20%; padding: 36px 0; }

.testimonial-small .item__img .img { display: block; width: 100%; padding-top: 100%; position: relative; }

.testimonial-small .item__img .img img { display: block; width: 100%; height: 100%; border-radius: 8px; position: absolute; top: 0; left: 0; object-fit: cover; object-position: center; }

.testimonial-small .item__box { margin-left: 10%; background: #10253E; color: #FFF; padding: 48px 40px 48px 14%; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); border-radius: 6px; }

@media (max-width: 479px) { .testimonial-small { padding-left: 12px; padding-right: 12px; }
  .testimonial-small .item__box { margin-left: 0; padding: 24px; }
  .testimonial-small .item__img { position: relative; left: 0; padding: 0; width: 100%; margin-bottom: 32px; }
  .testimonial-small .item__quote { font-size: 20px; line-height: 30px; margin-bottom: 32px; }
  .testimonial-small .item__author .author-logo { display: block; margin-bottom: 16px; }
  .testimonial-small .item__author .auhtor-content { display: block; padding: 0; border-left: 0; } }

.block-cta-alt { text-align: center; position: relative; }

.block-cta-alt .container { padding-top: 60px; padding-bottom: 60px; }

.block-cta-alt__icon { margin-bottom: 8px; }

.block-cta-alt__icon img { display: block; height: 72px; width: auto; margin: 0 auto; }

.block-cta-alt__title { font-size: 40px; line-height: 48px; margin-bottom: 20px; }

.block-cta-alt__title.small { font-size: 24px; line-height: 32px; }

.block-cta-alt__desc { max-width: 600px; margin: 0 auto 32px; }

.block-cta-alt hr.separator { max-width: 1224px; margin: 0 auto; }

.block-cta-alt--trial { background-color: #002A38; background-image: url("../images/pattern-cta.svg"); color: #FFF; }

.block-cta-alt--trial .block-cta-alt__title { color: #FFF; }

.block-cta-alt--trial .block-cta-alt__desc { color: #FFF; }

.block-cta-alt--simple .container { padding-top: 0; }

.block-cta-alt--simple .block-cta-alt__buttons { padding-top: 20px; }

.block-cta-alt--simple .block-cta-alt__buttons a { margin-right: 16px; margin-bottom: 0; }

.block-cta-alt--simple .block-cta-alt__buttons a:last-child { margin-right: 0; }

.block-cta-alt--simple .block-cta-alt__title { position: relative; padding-top: 32px; }

.block-cta-alt--simple .block-cta-alt__title::before { content: ''; display: inline-block; width: 48px; height: 6px; background: #4B61DD; top: 0; left: calc(50% - 24px); position: absolute; }

.block-cta-alt.bg-snow { background: #F2F4F7; }

@media (max-width: 991px) { .block-cta-alt .container { padding-top: 64px; padding-bottom: 64px; padding-left: 24px; padding-right: 24px; }
  .block-cta-alt--simple .block-cta-alt__buttons a { margin-right: 0; margin-bottom: 16px; }
  .block-cta-alt--simple .block-cta-alt__buttons a:last-child { margin-right: 0; margin-bottom: 16px; } }

.tf-block { overflow: hidden; width: 100%; }

.tf-block__container { position: relative; }

.tf-block__wrap { display: flex !important; position: relative; left: 0; transition: all .3s ease; }

.tf-block__wrap--alt { position: relative; padding: 4px; background: #FFFFFF; box-shadow: 0px 23px 17px rgba(50, 50, 71, 0.1); border-radius: 4px; gap: 6px; }

.tf-block__wrap--alt .owl-nav { position: absolute !important; }

.tf-block__wrap--alt .owl-nav .owl-prev span, .tf-block__wrap--alt .owl-nav .owl-next span { width: 44px !important; height: 44px !important; }

.tf-block__wrap--alt .owl-dots { padding-top: 60px !important; bottom: unset !important; }

.tf-block__wrap--alt.duo .item { flex: 0 0 calc((100%/2) - 4px); width: calc((100%/2) - 4px); }

.tf-block__wrap--alt .item { flex: 0 0 calc((100%/3) - 4px); width: calc((100%/3) - 4px); }

@media (max-width: 991px) { .tf-block__wrap--alt { gap: 0; }
  .tf-block__wrap--alt .item { flex: 0 0 100%; width: 100%; } }

.tf-block__wrap--alt.old.duo .item { width: 100%; flex: 0 0 100%; }

.tf-block__scrollwrap { margin-top: 62px; background: #E2E2E2; border-radius: 2px; }

.tf-block__scrollbar { height: 4px; background: #005FBF; border-radius: 2px; cursor: pointer; }

.tf-block .item { display: block; }

.tf-block .item:last-child { margin-right: 0 !important; }

.tf-block .item__img { display: block; width: 40px !important; height: 40px; object-fit: contain; object-position: 50% 50%; margin-bottom: 8px; }

.tf-block .item__title { color: #FFF; font-size: 20px; line-height: 28px; margin-bottom: 8px; min-height: 56px; }

.tf-block .item__head { display: block; padding: 16px 20px; border-radius: 4px 4px 0 0; color: #FFF; min-height: 160px; }

.tf-block .item__desc { display: block; min-height: 48px; }

.tf-block .item__body { display: block; padding: 16px 20px; border-radius: 0 0 4px 4px; color: #2B2A35; background: #FFFFFF; min-height: 320px; }

.tf-block .item--alt .item__body { background: #F2F4F7; }

.tf-block--alt { padding-bottom: 80px; }

.tf-block--alt .item__title { min-height: auto; }

.tf-block--alt .owl-carousel .owl-stage-outer { overflow: hidden !important; }

.tf-block--alt .owl-carousel .owl-nav { top: unset !important; }

.tf-block--alt .owl-carousel .owl-nav .owl-prev span.prev img { transform: scaleX(-1) rotateZ(180deg); }

.tf-block .owl-carousel .owl-stage-outer { overflow: visible; }

.tf-block .owl-nav { position: relative; padding-top: 40px; display: flex; width: 100%; height: auto; justify-content: flex-end; }

.tf-block .owl-nav .owl-prev, .tf-block .owl-nav .owl-next { display: block; position: relative; width: 48px; height: 48px; top: 0; }

.tf-block .owl-nav .owl-prev:focus, .tf-block .owl-nav .owl-next:focus { outline: none; }

.tf-block .owl-nav .owl-prev.disabled span, .tf-block .owl-nav .owl-next.disabled span { background: transparent; border-color: #b4b4b4; }

.tf-block .owl-nav .owl-prev.disabled span img, .tf-block .owl-nav .owl-next.disabled span img { filter: grayscale(100%); opacity: 0.5; }

.tf-block .owl-nav .owl-prev:first-child, .tf-block .owl-nav .owl-next:first-child { margin-right: 10px; }

.tf-block .owl-nav .owl-prev:last-child, .tf-block .owl-nav .owl-next:last-child { margin-left: 10px; }

.tf-block .owl-nav .owl-prev span, .tf-block .owl-nav .owl-next span { display: block; font-size: 20px; line-height: 48px; width: 48px; height: 48px; border-radius: 24px; border: 1px solid #4B61DD; background: #FFF; padding: 12px; }

.tf-block .owl-nav .owl-prev span img, .tf-block .owl-nav .owl-next span img { display: block; position: relative; }

.tf-block .owl-nav .owl-prev span.prev img, .tf-block .owl-nav .owl-next span.prev img { transform: scaleX(-1); }

.tf-block .owl-dots { position: absolute; z-index: 2; left: 0; bottom: 22px; display: flex; width: calc(100% - 270px); }

.tf-block .owl-dot { margin: 0 4px; display: inline-block; vertical-align: middle; }

.tf-block .owl-dot:hover, .tf-block .owl-dot:focus { outline: none; }

.tf-block .owl-dot { width: 32px !important; }

.tf-block .owl-dot span { display: block; width: 32px !important; height: 4px; background: #E2E2E2; border-radius: 2px; }

.tf-block .owl-dot:hover, .tf-block .owl-dot:focus { outline: none; }

.tf-block .owl-dot.active span { background: #005FBF; }

@media (max-width: 768px) { .tf-block .tf-block__wrap { display: block; max-width: 90%; }
  .tf-block .tf-block__wrap--alt { max-width: 100%; }
  .tf-block .tf-block__wrap--alt .owl-dots { position: absolute !important; padding-top: 30px !important; }
  .tf-block .tf-block__scrollwrap { display: none; }
  .tf-block .owl-dots { position: relative; bottom: auto; margin-top: 32px; width: 100%; text-align: center; }
  .tf-block .owl-dot { margin: 0 4px; display: inline-block; vertical-align: middle; }
  .tf-block .owl-dot:hover, .tf-block .owl-dot:focus { outline: none; }
  .tf-block .owl-dot span { display: block; width: 32px; height: 4px; background: #E2E2E2; border-radius: 2px; }
  .tf-block .owl-dot span:hover, .tf-block .owl-dot span:focus { outline: none; }
  .tf-block .owl-dot.active span { background: #005FBF; } }

.support-block { padding: 5rem 0; }

.support-block .section-head { text-align: center; margin-bottom: 3rem; }

@media (max-width: 991px) { .support-block { padding-left: 8px; padding-right: 8px; }
  .support-block .section-head .section-head__title { font-size: 28px; line-height: 36px; } }

.masthead--multiacc { padding: 0; }

.masthead--multiacc .masthead__logo { margin-bottom: 48px; }

.masthead--multiacc .masthead__content { padding: 0; }

.masthead--multiacc .masthead__desc { max-width: 600px; margin: 0 auto; }

.masthead--multiacc .masthead__wrap { padding-top: 40px; padding-bottom: 64px; }

@media (max-width: 991px) { .masthead--multiacc { padding-left: 12px; padding-right: 12px; } }

.multiacc-cards { padding: 64px 0; }

.multiacc-cards .item__title { display: block; color: #2B2A35; font-size: 20px; line-height: 28px; font-weight: 500; margin: 0 0 8px; }

.multiacc-cards .item__desc { font-size: 14px; line-height: 20px; }

.multiacc-cards .item__desc p { font-size: 14px; line-height: 20px; }

.multiacc-cards .item__price .price { color: #9090A2; }

.multiacc-cards .item__price .price span { text-decoration: line-through; }

.multiacc-cards .item__price .price .badge { color: #4B61DD; font-size: 12px; line-height: 28px; font-weight: 500; border: 1px solid #4B61DD; background: #FFF; padding: 0 16px; border-radius: 14px; margin: 0 0 8px; text-decoration: none; }

.multiacc-cards .item__price .price .label { font-size: 14px; line-height: 20px; margin-bottom: 8px; color: #545465; }

.multiacc-cards .item__price .price-after { font-weight: 500; padding-top: 8px; }

.multiacc-cards .item__price .price-after span { display: inline-block; font-size: 20px; line-height: 32px; }

.multiacc-cards .item__price .price-after small { display: inline-block; margin-left: 4px; font-size: 14px; line-height: 20px; color: #545465; }

.multiacc-cards .item__buttons { padding-top: 24px; }

.multiacc-cards .item__head { padding: 24px 32px; border-radius: 4px 4px 0 0; background: #F2F4F7; border-bottom: 1px solid #E2E2E2; }

.multiacc-cards .item__body { padding: 24px 32px 32px; }

.multiacc-cards .item__wrap { display: flex; flex-direction: column; border: 1px solid #E2E2E2; border-radius: 4px; }

.multiacc-cards .item--blue .item__head { background: #EEF9FB; border-bottom: 1px solid #009BDE; }

.multiacc-cards .item--blue .item__wrap { border: 1px solid #009BDE; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); }

.multiacc-cards__wrap { padding-bottom: 64px; }

@media (max-width: 991px) { .multiacc-cards { padding: 32px 12px; }
  .multiacc-cards .item { margin-bottom: 32px; order: 1; }
  .multiacc-cards .item--blue { order: 0; }
  .multiacc-cards__head { margin-bottom: 26px; } }

.testimonial-multiacc__wrap { padding-bottom: 64px; }

.testimonial-multiacc .item__quote { font-size: 24px; line-height: 32px; margin-bottom: 16px; font-family: "Inter", sans-serif; font-weight: 500; }

.testimonial-multiacc .item__author .author-logo { display: inline-block; vertical-align: middle; padding-right: 24px; }

.testimonial-multiacc .item__author .author-logo img { height: 32px; width: auto; display: block; }

.testimonial-multiacc .item__author .auhtor-content { display: inline-block; vertical-align: middle; padding: 12px 24px; border-left: 1px solid #E2E2E2; }

.testimonial-multiacc .item__author .auhtor-content span { display: block; }

.testimonial-multiacc .item__author .auhtor-content .name { font-family: "Inter", sans-serif; font-weight: 500; }

.testimonial-multiacc .item__img { position: absolute; top: 0; left: 12px; width: 20%; padding: 36px 0; }

.testimonial-multiacc .item__img .img { display: block; width: 100%; padding-top: 100%; position: relative; }

.testimonial-multiacc .item__img .img img { display: block; width: 100%; height: 100%; border-radius: 8px; position: absolute; top: 0; left: 0; object-fit: cover; object-position: center; }

.testimonial-multiacc .item__box { margin-left: 10%; color: #FFF; background: #10253E; padding: 48px 40px 48px 14%; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); border-radius: 6px; }

@media (max-width: 479px) { .testimonial-multiacc { padding-left: 12px; padding-right: 12px; }
  .testimonial-multiacc .item__box { margin-left: 0; padding: 24px; }
  .testimonial-multiacc .item__img { position: relative; left: 0; padding: 0; width: 100%; margin-bottom: 32px; }
  .testimonial-multiacc .item__quote { font-size: 20px; line-height: 30px; margin-bottom: 32px; }
  .testimonial-multiacc .item__author .author-logo { display: block; margin-bottom: 16px; }
  .testimonial-multiacc .item__author .auhtor-content { display: block; padding: 0; border-left: 0; } }

.masthead--pac .masthead__desc { margin-bottom: 24px; }

.masthead--pac .masthead__desc p:last-child { margin-bottom: 0; }

.masthead--pac .masthead__content { padding-top: 30px; padding-bottom: 64px; }

@media (max-width: 991px) { .masthead--pac .masthead__content { padding-bottom: 30px; } }

.advantage--pac .section-head__title { font-size: 40px; line-height: 48px; margin-bottom: 48px; }

@media (max-width: 991px) { .advantage--pac { padding-top: 64px; padding-bottom: 64px; padding-left: 12px; padding-right: 12px; }
  .advantage--pac .section-head__title { font-size: 28px; line-height: 36px; } }

.fya { background: #F2F4F7; }

.fya__title { font-size: 40px; line-height: 48px; margin-bottom: 48px; }

.fya__content ul.custom-list > li:last-child { margin-bottom: 0; }

.fya__button { padding-top: 32px; }

.fya__wrap { padding-top: 80px; padding-bottom: 80px; }

@media (max-width: 991px) { .fya { padding-left: 12px; padding-right: 12px; }
  .fya .row { flex-direction: column-reverse; }
  .fya__title { font-size: 28px; line-height: 36px; }
  .fya__img { margin-bottom: 40px; }
  .fya__wrap { padding-top: 64px; padding-bottom: 64px; } }

.block-clients__pac { background: transparent; padding: 0; }

.block-clients__pac .block-clients__title { font-size: 40px; line-height: 48px; margin-bottom: 48px; }

.block-clients__pac .block-clients__list { display: flex; flex-wrap: wrap; }

.block-clients__pac .block-clients__list .item { width: 100%; max-width: 16.666666%; flex: 0 0 16.666666%; height: 80px; display: flex; align-items: center; justify-content: center; padding: 6px 12px; }

.block-clients__pac .block-clients__list .item img { display: block; width: auto; height: auto; max-width: 100%; max-height: 100%; }

.block-clients__pac .block-clients__wrap { padding-top: 80px; padding-bottom: 64px; }

@media (max-width: 991px) { .block-clients__pac { padding-top: 0 !important; padding-bottom: 0 !important; padding-left: 12px; padding-right: 12px; }
  .block-clients__pac .block-clients__title { font-size: 24px; line-height: 32px; margin-bottom: 40px; }
  .block-clients__pac .block-clients__title br { display: none; }
  .block-clients__pac .block-clients__list .item { max-width: 33.333333%; flex: 0 0 33.333333%; height: 64px; padding: 12px 16px; }
  .block-clients__pac .block-clients__wrap { padding-top: 64px; padding-bottom: 64px; } }

.pca-steps { padding-top: 80px; padding-bottom: 80px; }

.pca-steps__wrap .section-head__title { text-align: center; margin-bottom: 64px; color: #232933 !important; }

.pca-steps__wrap .post { text-align: center; }

.pca-steps__wrap .post__img img { width: 80px; height: auto; margin-bottom: 16px; }

.pca-steps__wrap .post__title { font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 12px; display: block; font-size: 1.25rem; line-height: 2rem; }

.pca-steps__wrap .post__content { padding-right: 0; }

.pca-steps__wrap .with-arrow .post { position: relative; }

.pca-steps__wrap .with-arrow .post::after { background: url("../images/arrow-dash-dark.svg") no-repeat center; position: absolute; display: inline-block; top: 25%; right: -25%; content: ''; width: 160px; height: 11px; }

.pca-steps__wrap .with-arrow:last-child .post::after, .pca-steps__wrap .with-arrow:last-child .post::before { display: none !important; }

.pca-steps.alt .pca-steps__wrap .with-arrow .post::after { right: -35%; }

.pca-steps.alt .pca-steps__wrap .with-arrow .post__title { font-size: 18px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; }

@media (max-width: 991px) { .pca-steps { padding: 24px 12px 14px 12px; }
  .pca-steps .section-head .section-head__title { font-size: 28px; line-height: 36px; }
  .pca-steps__wrap .post { display: block; text-align: left; padding: 0 24px; }
  .pca-steps__wrap .post__img { display: inline-block; vertical-align: top; width: 56px; margin-right: 16px; }
  .pca-steps__wrap .post__img img { width: 100px; height: auto; }
  .pca-steps__wrap .post__content { display: inline-block; vertical-align: top; width: calc(100% - 77px); }
  .pca-steps__wrap .post__content p { opacity: 0; height: 0; transition: height .5s linear; }
  .pca-steps__wrap .post__title { font-size: 16px; line-height: 23px; margin: 8px 0; position: relative; }
  .pca-steps__wrap .post.show .post__content p { opacity: 1; height: auto; transition: height .5s linear; }
  .pca-steps__wrap .section-head__title { font-size: 28px !important; line-height: 36px !important; margin-bottom: 40px !important; }
  .pca-steps .with-arrow .post { position: relative; padding: 0 24px 56px 24px; margin-bottom: 0; }
  .pca-steps .with-arrow .post::before { content: ''; display: inline-block; position: absolute; left: 50px; top: 86px; height: calc(100% - 125px); width: 2px; border-right: 2px dotted #9090A2; }
  .pca-steps .with-arrow .post::after { content: ''; display: inline-block; position: absolute; width: 30px; height: 20px; bottom: 25px; top: unset; left: 36px; background: url("../images/arrow-down.svg") no-repeat center; background-size: contain; }
  .pca-steps .with-arrow:last-child .post::before, .pca-steps .with-arrow:last-child .post::after { display: none; }
  .pca-steps.alt { padding: 64px 12px; }
  .pca-steps.alt .section-head { padding-bottom: 0; }
  .pca-steps.alt .pca-steps__wrap .with-arrow .post { padding-left: 0; padding-right: 0; }
  .pca-steps.alt .pca-steps__wrap .with-arrow .post::before { top: 65px; left: 25px; height: calc(100% - 85px); }
  .pca-steps.alt .pca-steps__wrap .with-arrow .post::after { bottom: 10px; left: 11px; }
  .pca-steps.alt .pca-steps__wrap .with-arrow:last-child .post { padding-bottom: 30px; } }

.pca-cta__title { color: #FFF; font-size: 32px; line-height: 40px; margin-bottom: 20px; }

.pca-cta__desc { color: #FFF; }

.pca-cta__buttons { padding-top: 20px; }

.pca-cta__buttons .btn-white { color: #10253E; border-color: #FFF; opacity: 1; transition: all .3s ease; }

.pca-cta__buttons .btn-white:hover { background: #FFF; opacity: 0.5; }

.pca-cta__buttons a, .pca-cta__buttons .btn { margin-right: 14px; margin-bottom: 0; }

.pca-cta__buttons a:last-child, .pca-cta__buttons .btn:last-child { margin-right: 0; }

.pca-cta__box { background: #10253E; border-radius: 8px; color: #FFF; padding: 40px; text-align: center; }

.pca-cta__wrap { padding-top: 24px; padding-bottom: 24px; }

@media (max-width: 991px) { .pca-cta__title { font-size: 24px; line-height: 32px; margin-bottom: 28px; }
  .pca-cta__buttons { padding-top: 40px; }
  .pca-cta__buttons a, .pca-cta__buttons .btn { margin-right: 0; margin-bottom: 14px; }
  .pca-cta__buttons a:last-child, .pca-cta__buttons .btn:last-child { margin-bottom: 0; }
  .pca-cta__box { padding: 40px 20px 24px; } }

.pca-table-block .pca-table { display: block; width: 100%; border-top: 1px solid #E2E2E2; border-left: 1px solid #E2E2E2; }

.pca-table-block .pca-table span.check { display: inline-block; vertical-align: middle; width: 16px; height: 16px; background: url("../images/ic-check.svg") no-repeat center; background-size: contain; }

.pca-table-block .pca-table span.uncheck { display: inline-block; vertical-align: middle; width: 17px; height: 17px; background: url("../images/ic-cancel-circle.svg") no-repeat center; background-size: contain; }

.pca-table-block .pca-table .head__title { display: block; color: #005FBF; font-size: 24px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 24px; }

.pca-table-block .pca-table .head__price { display: block; margin-bottom: 24px; }

.pca-table-block .pca-table .head__price span { font-size: 28px; line-height: 36px; font-family: "Inter", sans-serif; font-weight: 500; }

.pca-table-block .pca-table .head__price small { font-size: 14px; line-height: 20px; margin-left: 4px; }

.pca-table-block .pca-table .head__buttons a, .pca-table-block .pca-table .head__buttons .btn { margin-bottom: 14px; }

.pca-table-block .pca-table .head__buttons a:last-child, .pca-table-block .pca-table .head__buttons .btn:last-child { margin-bottom: 0; }

.pca-table-block .pca-table .cta-sm { display: none; }

.pca-table-block .pca-table__row { position: relative; display: grid; grid-template-columns: 312px auto auto auto; }

.pca-table-block .pca-table__th { display: flex; align-items: center; width: 100%; padding: 16px; border-right: 1px solid #E2E2E2; border-bottom: 1px solid #E2E2E2; }

.pca-table-block .pca-table__th.has-bg { background-color: #F2F4F7; }

.pca-table-block .pca-table__td { display: flex; justify-content: center; align-items: center; text-align: center; width: 100%; padding: 16px; border-right: 1px solid #E2E2E2; border-bottom: 1px solid #E2E2E2; }

.pca-table-block .pca-table__td.has-bg { background-color: #F2F4F7; }

.pca-table-block__title { font-size: 40px; line-height: 48px; margin-bottom: 48px; text-align: center; }

.pca-table-block__wrap { padding-top: 80px; padding-bottom: 80px; }

.pca-table-block .tnc { display: block; width: 100%; text-align: right; font-size: 14px; line-height: 20px; color: #545465; margin-top: 16px; }

.pca-table-block.alt .pca-table-block__title { margin-bottom: 68px; }

.pca-table-block.alt .pca-table__row { grid-template-columns: 392px auto auto; }

.pca-table-block.alt .pca-table__th.head, .pca-table-block.alt .pca-table__td.head { background: #F2F4F7; }

.pca-table-block.alt .pca-table__th.head.highlight, .pca-table-block.alt .pca-table__td.head.highlight { font-size: 24px; line-height: 32px; color: #005FBF; background: #EDF6FB; margin-top: -10%; padding-top: 24px; padding-bottom: 24px; border: 1.5px solid #005FBF; border-radius: 8px 8px 0 0; }

.pca-table-block.alt .pca-table__th.highlight, .pca-table-block.alt .pca-table__td.highlight { border-left: 1.5px solid #005FBF; border-right: 1.5px solid #005FBF; }

.pca-table-block.alt .pca-table__th.highlight.last, .pca-table-block.alt .pca-table__td.highlight.last { border-bottom: 1.5px solid #005FBF; border-radius: 0 0 8px 8px; }

.pca-table-block.alt .pca-table-block__action { margin-top: 40px; }

@media (max-width: 991px) { .pca-table-block .pca-table .hide-sm { display: none; }
  .pca-table-block .pca-table .cta-sm { display: block; background: #FFF; }
  .pca-table-block .pca-table .cta-sm .btn { margin-bottom: 0 !important; }
  .pca-table-block .pca-table .head__title { font-size: 18px; line-height: 28px; margin-bottom: 12px; }
  .pca-table-block .pca-table .head__price { margin-bottom: 0; }
  .pca-table-block .pca-table .head__price span { font-size: 20px; line-height: 28px; margin-bottom: 0; display: block; }
  .pca-table-block .pca-table .head__price small { display: block; margin: 0; }
  .pca-table-block .pca-table .head__buttons { display: none; }
  .pca-table-block .pca-table__th { padding: 14px; grid-column-start: 1; grid-column-end: 4; background: #F2F4F7; }
  .pca-table-block .pca-table__td { padding: 14px; }
  .pca-table-block .pca-table__td.has-bg { background: #FFF; }
  .pca-table-block .pca-table__row { width: 100%; grid-template-columns: auto auto auto; }
  .pca-table-block.alt .pca-table-block__title { font-size: 28px; line-height: 36px; }
  .pca-table-block.alt .pca-table__row { grid-template-columns: auto auto; }
  .pca-table-block.alt .pca-table__th { grid-column-start: 1; grid-column-end: 3; }
  .pca-table-block.alt .pca-table__th.head, .pca-table-block.alt .pca-table__td.head { background: #F2F4F7; }
  .pca-table-block.alt .pca-table__th.head.highlight, .pca-table-block.alt .pca-table__td.head.highlight { font-size: 18px; line-height: 24px; color: #005FBF; background: #EDF6FB; margin-top: -10%; padding: 14px 24px; border: 1.5px solid #005FBF; border-radius: 8px 8px 0 0; }
  .pca-table-block.alt .pca-table__th.highlight, .pca-table-block.alt .pca-table__td.highlight { border-left: 1px solid #E3E3E8; border-right: 1px solid #E3E3E8; }
  .pca-table-block.alt .pca-table__th.highlight.last, .pca-table-block.alt .pca-table__td.highlight.last { border-bottom: 1px solid #E3E3E8; border-radius: 0; } }

@media (max-width: 991px) { .pca-testi.pricing-testi--alt { padding-left: 24px; padding-right: 24px; padding-bottom: 64px; }
  .pca-testi.pricing-testi--alt .product-testi__block-img { margin: 0 auto; padding-top: 70%; }
  .pca-testi.pricing-testi--alt .product-testi__block-img .btn-play { right: 20%; }
  .pca-testi.pricing-testi--alt .product-testi__block-img img { border-radius: 8px; width: 70%; height: 100%; left: 15%; bottom: 0; top: auto; }
  .pca-testi.pricing-testi--alt .product-testi__block-img::before { content: ""; display: block; width: 100%; height: 50%; background: #10253E; position: absolute; bottom: -1px; left: 0; border-radius: 6px 6px 0 0; }
  .pca-testi.pricing-testi--alt .testi { padding: 24px; }
  .pca-testi.pricing-testi--alt .testi__body { padding: 0; }
  .pca-testi.pricing-testi--alt .owl-dots { border-radius: 0 0 6px 6px; } }

.form-footer { padding-top: 64px; text-align: center; }

.form-footer .img { display: block; margin-bottom: 24px; }

.form-footer .title { display: block; font-size: 24px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 500; margin-bottom: 12px; }

.form-footer p a { color: #1357FF; }

.form-footer p a:hover { text-decoration: underline; }

.masthead-inapp { font-family: "Inter",-apple-system,BlinkMacSystemFont,Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; }

.masthead-inapp__logo { margin-bottom: 40px; }

.masthead-inapp__logo img { display: block; height: 40px; width: auto; }

.masthead-inapp__title { font-size: 40px; line-height: 48px; }

.masthead-inapp__desc { font-size: 14px; line-height: 20px; }

.masthead-inapp__img { padding-top: 40px; }

.masthead-inapp__form { padding: 32px; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); border-radius: 8px; }

.masthead-inapp__form .form-group > label { font-size: 14px; line-height: 20px; }

.masthead-inapp__form .form-control { font-size: 14px; padding-top: 5px; padding-bottom: 5px; }

.masthead-inapp__form .dropdown.bootstrap-select .btn, .masthead-inapp__form .dropdown.bootstrap-select button { font-size: 14px; line-height: 36px; }

.masthead-inapp__form .form-action { padding-top: 24px; }

.masthead-inapp__form .form-action .btn { line-height: 18px; }

.masthead-inapp__wrap { padding-top: 48px; padding-bottom: 64px; }

.block-clients--in-talenta, .block-clients--in-klikpajak { font-family: "Inter",-apple-system,BlinkMacSystemFont,Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; background: transparent; padding: 0; }

.block-clients--in-talenta .block-clients__title, .block-clients--in-klikpajak .block-clients__title { font-size: 24px; line-height: 32px; margin-bottom: 32px; }

.block-clients--in-talenta .block-clients__list, .block-clients--in-klikpajak .block-clients__list { display: flex; flex-wrap: wrap; }

.block-clients--in-talenta .block-clients__list .item, .block-clients--in-klikpajak .block-clients__list .item { width: 100%; max-width: 16.666666%; flex: 0 0 16.666666%; height: 80px; display: flex; align-items: center; justify-content: center; padding: 6px 12px; }

.block-clients--in-talenta .block-clients__list .item img, .block-clients--in-klikpajak .block-clients__list .item img { display: block; width: auto; height: auto; max-width: 100%; max-height: 100%; }

.block-clients--in-talenta .block-clients__buttons, .block-clients--in-klikpajak .block-clients__buttons { padding-top: 24px; text-align: center; }

.block-clients--in-talenta .block-clients__wrap, .block-clients--in-klikpajak .block-clients__wrap { padding-top: 64px; padding-bottom: 64px; }

@media (max-width: 991px) { .block-clients--in-talenta, .block-clients--in-klikpajak { padding-top: 0 !important; padding-bottom: 0 !important; padding-left: 12px; padding-right: 12px; }
  .block-clients--in-talenta .block-clients__title, .block-clients--in-klikpajak .block-clients__title { font-size: 24px; line-height: 32px; margin-bottom: 40px; }
  .block-clients--in-talenta .block-clients__title br, .block-clients--in-klikpajak .block-clients__title br { display: none; }
  .block-clients--in-talenta .block-clients__list .item, .block-clients--in-klikpajak .block-clients__list .item { max-width: 33.333333%; flex: 0 0 33.333333%; height: 64px; padding: 12px 16px; }
  .block-clients--in-talenta .block-clients__wrap, .block-clients--in-klikpajak .block-clients__wrap { padding-top: 64px; padding-bottom: 64px; } }

.advantage--in-talenta { font-family: "Inter",-apple-system,BlinkMacSystemFont,Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; padding: 0; }

.advantage--in-talenta .advantage__title { font-size: 24px; line-height: 32px; margin-bottom: 16px; text-align: center; }

.advantage--in-talenta .advantage__wrap { padding-top: 64px; padding-bottom: 64px; }

.howjurnal--in-talenta, .howjurnal--in-klikpajak { font-family: "Inter",-apple-system,BlinkMacSystemFont,Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; }

.howjurnal--in-talenta .section-head, .howjurnal--in-klikpajak .section-head { margin-bottom: 40px; }

.howjurnal--in-talenta .section-head__title, .howjurnal--in-klikpajak .section-head__title { font-size: 24px; line-height: 32px; margin-bottom: 24px; text-align: center; }

.howjurnal--in-talenta .section-head__buttons, .howjurnal--in-klikpajak .section-head__buttons { text-align: center; }

.howjurnal--in-talenta .accordion .card__title, .howjurnal--in-klikpajak .accordion .card__title { font-size: 14px; line-height: 20px; }

.howjurnal--in-talenta .accordion .card__header, .howjurnal--in-klikpajak .accordion .card__header { padding-top: 16px; padding-bottom: 16px; }

.howjurnal--in-talenta .howjurnal__wrap, .howjurnal--in-klikpajak .howjurnal__wrap { padding-top: 64px; padding-bottom: 64px; }

.pricing-testi--in-talenta, .pricing-testi--in-klikpajak { font-family: "Inter",-apple-system,BlinkMacSystemFont,Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; background: #FFF; margin-bottom: 80px; }

.pricing-testi--in-talenta .testi .quote p, .pricing-testi--in-klikpajak .testi .quote p { color: #FFF; font-size: 20px; line-height: 28px; margin-bottom: 24px; }

.pricing-testi--in-talenta .testi .quote span, .pricing-testi--in-klikpajak .testi .quote span { font-size: 12px; line-height: 16px; }

.pricing-testi--in-talenta .link-more-white, .pricing-testi--in-klikpajak .link-more-white { font-size: 14px; line-height: 20px; }

.pricing-testi--in-talenta .slider-testi-alt, .pricing-testi--in-klikpajak .slider-testi-alt { padding-left: 0; }

.pricing-testi--in-talenta .slider-testi-alt:not(.owl-carousel) .testi__body, .pricing-testi--in-klikpajak .slider-testi-alt:not(.owl-carousel) .testi__body { padding-bottom: 0; }

.pricing-testi--in-talenta .pricing-testi__img-custom, .pricing-testi--in-klikpajak .pricing-testi__img-custom { max-width: 260px; }

.pricing-testi--in-talenta .pricing-testi__wrap, .pricing-testi--in-klikpajak .pricing-testi__wrap { max-width: 100%; }

.pricing-testi--in-talenta .pricing-testi__wrap::before, .pricing-testi--in-klikpajak .pricing-testi__wrap::before { border-radius: 4px; }

.footertop-inapp { background: #143A72; border-radius: 0 0 4px 4px; }

.footertop-inapp__title { font-size: 24px; line-height: 32px; margin-bottom: 16px; color: #FFF; margin-bottom: 24px; }

.footertop-inapp--in-klikpajak { background: #1C1D1E; }

.product-article article { margin-bottom: 80px; }

@media (max-width: 991px) { .product-article article { margin-bottom: 64px; } }

.form-box { background: #FFFFFF; box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05); border-radius: 8px; padding: 64px 54px; }

@media (max-width: 991px) { .form-box { padding: 24px; } }

#tnc ol { counter-reset: item; padding-left: 0; position: relative; }

#tnc ol > li { position: relative; display: block; padding-left: 40px; margin-bottom: 20px; }

#tnc ol > li p { margin-bottom: 12px; }

#tnc ol > li h2 { font-size: 24px; line-height: 36px; }

#tnc ol > li::before { content: counters(item, ".") ". "; counter-increment: item; position: absolute; left: 0; top: 0; font-size: 24px; line-height: 36px; font-family: "Inter", sans-serif; font-weight: 600; }

#tnc ol > li > ol > li { position: relative; padding-left: 50px; margin-bottom: 12px; }

#tnc ol > li > ol > li p { margin-bottom: 12px; }

#tnc ol > li > ol > li h3 { font-size: 18px; line-height: 28px; }

#tnc ol > li > ol > li::before { content: counters(item, ".") ". "; counter-increment: item; position: absolute; left: 0; top: 0; font-size: 18px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; }

#tnc ol > li > ol > li > ol > li { position: relative; padding-left: 55px; margin-bottom: 12px; }

#tnc ol > li > ol > li > ol > li p { margin-bottom: 12px; }

#tnc ol > li > ol > li > ol > li h4, #tnc ol > li > ol > li > ol > li h5 { font-size: 16px; line-height: 24px; }

#tnc ol > li > ol > li > ol > li::before { content: counters(item, ".") ". "; counter-increment: item; position: absolute; left: 0; top: 0; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

#tnc ul { list-style: disc; position: relative; padding-left: 15px; }

#tnc ul li { display: list-item; margin-bottom: 12px; }

#tnc ul li p { margin-bottom: 12px; }

#tnc ul li::before { display: none; }

.device-info { background: #F2F2F3; padding: 100px 20px 35px; }

.device-info__box { padding: 25px; background: #FFF; border-radius: 6px; }

.device-info__img { margin-bottom: 16px; }

.device-info__img img { margin: 0 auto; }

.device-info__desc p { font-size: 24px; line-height: 32; text-align: center; font-family: "Inter", sans-serif; font-weight: 600; }

/* ------------------------------------------------------------------------------------------------ */
/* Content Blog */
/* ------------------------------------------------------------------------------------------------ */
.masthead-blog__wrap { padding-top: 32px; padding-bottom: 40px; }

.masthead-blog__right { position: relative; display: flex; flex-direction: column; }

.masthead-blog__img { display: block; position: relative; padding-top: 50%; border-radius: 4px; overflow: hidden; }

.masthead-blog__img img { display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; object-fit: cover; }

.masthead-blog__subtitle { font-size: 12px; line-height: 26px; letter-spacing: 2px; }

.masthead-blog__subtitle a { color: #545465; }

.masthead-blog__subtitle a:hover { color: #545465; text-decoration: underline; }

.masthead-blog__title { font-size: 32px; line-height: 40px; margin-bottom: 16px; }

.masthead-blog__title a { color: #2B2A35; }

.masthead-blog__title a:hover { color: #4B61DD; }

.masthead-blog__content { margin-bottom: 12px; }

.masthead-blog__content p { margin: 0; }

.masthead-blog__content a { color: #4B61DD; }

.masthead-blog__content a:hover { color: #4B61DD; text-decoration: underline; }

.masthead-blog__meta { margin-top: auto; font-size: 12px; line-height: 26px; letter-spacing: 2px; }

.masthead-blog__meta span { display: inline-block; vertical-align: middle; }

.masthead-blog__meta .author b { display: inline-block; padding-left: 8px; }

.masthead-blog__meta .date { position: relative; }

.masthead-blog__meta .date:before { content: ""; border-radius: 50%; height: 3px; width: 3px; background: #545465; display: inline-block; vertical-align: middle; margin: 0 12px; }

.ec__wrap { padding-top: 32px; padding-bottom: 32px; margin-bottom: 64px; border-width: 1px 0; border-style: solid; border-color: #E2E2E2; }

.ec__right { border-left: 1px solid #E2E2E2; }

.ec__title { font-size: 32px; line-height: 40px; margin: 0; font-family: "Inter", sans-serif; font-weight: 400; }

.ec .item { padding-left: 40px; }

.ec .item__subtitle { font-size: 12px; line-height: 26px; letter-spacing: 2px; text-transform: uppercase; }

.ec .item__subtitle a { color: #2B2A35; }

.ec .item__subtitle a:hover { color: #4B61DD; }

.ec .item__title { font-size: 16px; line-height: 24px; margin: 0 0 16px; }

.ec .item__title a { color: #2B2A35; }

.ec .item__title a:hover { color: #4B61DD; }

.ec .item__content p:last-of-type { margin: 0; }

.ec .item__content a { color: #4B61DD; }

.ec .item__content a:hover { color: #4B61DD; text-decoration: underline; }

.ec .item__meta { padding-top: 12px; font-size: 12px; line-height: 26px; letter-spacing: 2px; }

.ec .item__meta span { display: inline-block; vertical-align: middle; }

.ec .item__meta .author b { display: inline-block; padding-left: 8px; }

.ec .item__meta .date { position: relative; }

.ec .item__meta .date:before { content: ""; border-radius: 50%; height: 3px; width: 3px; background: #545465; display: inline-block; vertical-align: middle; margin: 0 12px; }

.blog-post .post { position: relative; }

.blog-post .post__img { display: block; position: relative; width: 100%; padding-top: 60%; margin-bottom: 24px; }

.blog-post .post__img .img { display: block; position: absolute; width: 100%; height: 100%; object-fit: cover; left: 0; top: 0; }

.blog-post .post__subtitle { font-size: 12px; line-height: 26px; letter-spacing: 2px; text-transform: uppercase; }

.blog-post .post__subtitle a { color: #2B2A35; }

.blog-post .post__subtitle a:hover { color: #4B61DD; }

.blog-post .post__title { font-size: 21px; line-height: 32px; margin: 0 0 16px; }

.blog-post .post__title a { color: #2B2A35; }

.blog-post .post__title:hover a { color: #4B61DD; }

.blog-post .post__content p:last-of-type { margin: 0; }

.blog-post .post__content a { color: #4B61DD; }

.blog-post .post__content a:hover { color: #4B61DD; text-decoration: underline; }

.blog-post .post__meta { padding-top: 32px; font-size: 12px; line-height: 26px; letter-spacing: 2px; }

.blog-post .post__meta span { display: inline-block; vertical-align: middle; }

.blog-post .post__meta .author b { display: inline-block; padding-left: 8px; }

.blog-post .post__meta .date { position: relative; }

.blog-post .post__meta .date:before { content: ""; border-radius: 50%; height: 3px; width: 3px; background: #545465; display: inline-block; vertical-align: middle; margin: 0 12px; }

.blog-post .post.cover .post__img { padding-top: 100%; }

.blog-post .post.cover .post__img .img { object-fit: contain; }

.blog-post--related .post { position: relative; display: flex; align-items: center; padding-bottom: 25px; margin: 0 0 32px; }

.blog-post--related .post:after { content: ""; display: block; width: calc(100% - 24px); height: 1px; background: #E2E2E2; position: absolute; bottom: 0; left: 12px; }

.blog-post--related .post:last-child:after, .blog-post--related .post:nth-last-child(2):after { display: none; }

.blog-post--related .post__col, .blog-post--related .post__col-2 { position: relative; display: block; width: 100%; }

.blog-post--related .post__col { max-width: 104px; flex: 0 0 104px; }

.blog-post--related .post__col-2 { max-width: calc(100% - 104px); flex: 0 0 calc(100% - 104px); padding-left: 24px; }

.blog-post--related .post__img { padding-top: 100%; margin: 0; }

.blog-post--related .post__img .img { border-radius: 4px; }

.blog-post--related .post__subtitle { color: #009BDE; }

.blog-post--related .post__title { font-size: 16px; line-height: 24px; margin: 0 0 8px; }

.new-post__wrap, .publikasi__wrap, .multimedia__wrap, .blog-product__wrap { margin-bottom: 64px; }

.new-post .section-head, .publikasi .section-head, .multimedia .section-head, .blog-product .section-head { position: relative; margin-bottom: 24px; padding: 0; text-align: left; }

.new-post .section-head__title, .publikasi .section-head__title, .multimedia .section-head__title, .blog-product .section-head__title { font-size: 32px; line-height: 48px; margin: 0; padding-right: 220px; }

.new-post .section-head__action, .publikasi .section-head__action, .multimedia .section-head__action, .blog-product .section-head__action { position: absolute; bottom: 0; right: 30px; }

.multimedia .item__video { position: relative; width: 100%; margin-bottom: 16px; }

.multimedia .item__video .play { display: block; position: absolute; z-index: 2; top: calc(50% - 24px); left: calc(50% - 24px); }

.multimedia .item__video .play .icon { width: 48px; height: 48px; display: block; }

.multimedia .item__video .play:after { content: ""; display: block; width: 64px; height: 64px; border-radius: 32px; background: #4B61DD; opacity: .2; position: absolute; top: calc(50% - 32px); left: calc(50% - 32px); transform: scale(0.5); transition: all .5s ease-in-out; }

.multimedia .item__video .block-img { position: relative; z-index: 1; width: 100%; padding-top: 60%; }

.multimedia .item__video .block-img img { display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; object-fit: cover; }

.multimedia .item__video:hover .play:after { transform: scale(1); cursor: pointer; }

.multimedia .item__title { font-size: 20px; line-height: 32px; }

.multimedia .item__title a { color: #2B2A35; }

.multimedia .item__title a:hover { color: #4B61DD; text-decoration: none; }

.blog-product__wrap { margin-bottom: 24px; }

.blog-product .post { margin-bottom: 40px; }

.blog-product .post__meta { padding-bottom: 16px; border-bottom: 1px solid #E2E2E2; }

.newsletter { text-align: center; color: #FFF; }

.newsletter__wrap { background-color: #009BDE; background-image: url(../images/patterns-02.svg); background-size: 69px; padding: 56px 20px 72px 20px; border-radius: 4px; margin-bottom: 64px; }

.newsletter__title { color: #FFFFFF; font-size: 32px; line-height: 48px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 16px; }

.newsletter__content { margin-bottom: 24px; }

.newsletter__form .form-group { position: relative; margin: 0; display: flex; justify-content: center; }

.newsletter__form .form-control { display: block; background: #FFF; border: 0; height: 50px; padding: 6px 15px; width: 100%; max-width: 320px; flex: 0 0 320px; border-radius: 4px 0 0 4px; }

.newsletter__form button[type="submit"] { display: block; line-height: 50px; background: #2B2A35; width: 100%; max-width: 128px; flex: 0 0 128px; padding: 0; color: #FFF; border: 0; border-radius: 0 4px 4px 0; cursor: pointer; transition: all .3s ease; }

.newsletter__form button[type="submit"]:hover { background: #141319; }

.blog-testimonial { margin-bottom: 120px; }

.blog-testimonial__wrap { background: #F8F9FA; box-shadow: 0px 9px 11px -2px #dbdbdb; border-radius: 4px; }

.blog-testimonial .owl-dots { position: absolute; bottom: -58px; left: 0; width: 100%; }

.blog-testimonial .item__left { position: relative; padding: 80px 0 64px 104px; }

.blog-testimonial .item__left:before { content: ""; background: url(../images/icon-comma.svg) no-repeat; background-size: contain; background-position: center; position: absolute; display: block; width: 188px; height: 120px; top: 25px; left: 35px; }

.blog-testimonial .item__title { font-size: 32px; line-height: 40px; font-family: "Inter", sans-serif; font-weight: 600; letter-spacing: -0.5px; color: #2B2A35; margin-bottom: 32px; }

.blog-testimonial .item__title a { color: #2B2A35; }

.blog-testimonial .item__title a:hover { color: #4B61DD; }

.blog-testimonial .item__content { margin-bottom: 24px; }

.blog-testimonial .item__foot .author { display: block; font-family: "Inter", sans-serif; font-weight: 600; }

.blog-testimonial .item__foot .logo { display: block; height: 24px; width: auto; margin-top: 4px; }

.blog-testimonial .item__img { width: 100%; height: 100%; object-fit: cover; border-radius: 0 4px 4px 0; }

.blog-banner__wrap { background: #009BDE; border-radius: 8px; margin-bottom: 64px; }

.blog-banner__left { display: flex; justify-content: center; }

.blog-banner__right { display: flex; justify-content: center; align-items: flex-end; }

.blog-banner__content { max-width: 460px; padding: 92px 12px; }

.blog-banner__title { color: #FFF; font-size: 32px; line-height: 48px; margin-bottom: 24px; }

.blog-single { padding-top: 10px; padding-bottom: 120px; }

.blog-single__article article { padding-bottom: 25px; }

.blog-single__article iframe { display: block; width: 100%; height: auto; margin: 0 0 25px; }

.blog-single__article h2 { font-size: 40px; line-height: 50px; margin: 60px 0 25px; }

.blog-single__article p { font-size: 20px; line-height: 30px; margin: 0 0 25px; color: #2B2A35; }

.blog-single__article img { display: block; width: 100%; height: auto; margin: 0 0 25px; }

.blog-single__ads { margin-bottom: 64px; }

.blog-single__ads img { display: block; width: 100%; height: auto; }

.blog-single__tag { margin-right: -4px; margin-left: -4px; margin-bottom: 30px; }

.blog-single__tag a { display: inline-block; border: 1px solid #E2E2E2; border-radius: 4px; font-family: "Inter", sans-serif; font-weight: 600; color: #2B2A35; padding: 5px 10px; margin: 0 4px 8px; transition: all .3s ease; }

.blog-single__tag a:hover { color: #FFF; border-color: #009BDE; background: #009BDE; }

.blog-single__sidebar .bs-meta { font-size: 12px; line-height: 20px; letter-spacing: 2px; text-transform: uppercase; }

.blog-single__sidebar .bs-author { font-size: 12px; line-height: 20px; letter-spacing: 2px; text-transform: uppercase; font-family: "Inter", sans-serif; font-weight: 600; display: flex; align-items: center; }

.blog-single__sidebar .bs-author__img { position: relative; display: block; width: 60px; height: 60px; border-radius: 30px; overflow: hidden; background: #E2E2E2; }

.blog-single__sidebar .bs-author__img img { display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; object-fit: cover; }

.blog-single__sidebar .bs-author__name:not(:first-child) { padding-left: 24px; }

.blog-single__sidebar .bs-share { font-size: 12px; line-height: 20px; letter-spacing: 2px; text-transform: uppercase; margin-bottom: 24px; }

.blog-single__sidebar .bs-share__title { margin-bottom: 16px; }

.blog-single__sidebar .bs-share__wrap { display: flex; margin-left: -4px; margin-right: -4px; }

.blog-single__sidebar .bs-share__wrap a { display: block; margin: 0 4px; opacity: 1; transition: all .3s ease; }

.blog-single__sidebar .bs-share__wrap a:hover { opacity: .6; }

.blog-single__sidebar .bs-share__wrap img { display: block; width: auto; height: 40px; }

.blog-single__sidebar hr { margin: 16px 0; }

.blog-single__sidebar .blog-post { opacity: 1; transition: all .3s ease; }

.blog-single__sidebar .post__img { margin-bottom: 8px; }

.blog-single__sidebar .post__subtitle { line-height: 20px; }

.blog-single__sidebar .post__title { font-size: 16px; line-height: 24px; }

.blog-single .sidebar__wrap.fixed .blog-post { display: none; }

.ebook-posts:first-child, .ebook-single:first-child { padding-top: 192px; }

.ebook-posts .post, .ebook-single .post { margin-bottom: 48px; }

.ebook-posts .post__img, .ebook-single .post__img { position: relative; width: 100%; padding-top: 128.73%; }

.ebook-posts .post__img img, .ebook-single .post__img img { display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; object-fit: contain; }

.ebook-posts .post__title, .ebook-single .post__title { font-size: 21px; line-height: 26px; margin-bottom: 16px; color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 600; }

.ebook-posts .post__subtitle, .ebook-single .post__subtitle { display: block; font-size: 12px; line-height: 26px; letter-spacing: 2px; text-transform: uppercase; margin-bottom: 8px; }

.ebook-posts .post__content, .ebook-single .post__content { border-bottom: 1px solid #E2E2E2; padding-bottom: 32px; }

.ebook-posts .post__content p, .ebook-single .post__content p { font-size: 15px; line-height: 26px; }

.ebook-posts .post--featured .post__right, .ebook-single .post--featured .post__right { display: flex; flex-direction: column; }

.ebook-posts .post--featured .post__img, .ebook-single .post--featured .post__img { padding-top: 100%; }

.ebook-posts .post--featured .post__title, .ebook-single .post--featured .post__title { font-size: 32px; line-height: 40px; margin-bottom: 24px; }

.ebook-posts .post--featured .post__content, .ebook-single .post--featured .post__content { height: 100%; position: relative; padding-bottom: 52px; }

.ebook-posts .post--featured .post__content p, .ebook-single .post--featured .post__content p { font-size: 16px; line-height: 26px; }

.ebook-posts .post--featured .post__content .link-more, .ebook-single .post--featured .post__content .link-more { position: absolute; bottom: 45px; }

.ebook-posts__more, .ebook-single__more { text-align: center; padding: 32px 16px 60px; }

.ebook-posts__more .btn, .ebook-single__more .btn { line-height: 48px; }

.ebook-single:first-child { padding-top: 152px; }

.ebook-single .post--featured { margin: 0; padding: 80px 0; }

.ebook-single .post--featured .post__content { border-bottom: 0; }

.ebook-single .post--featured .post__action { padding-top: 32px; }

.ebook-single .post--featured .post__action .btn { line-height: 48px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); }

.block-highlight { padding: 100px 0; background: #F8F9FA; }

.block-highlight h2 { color: #2B2A35; font-size: 32px; line-height: 40px; font-family: "Inter", sans-serif; font-weight: 600; letter-spacing: -0.05px; }

.block-highlight .block-img { position: relative; padding-top: 70%; }

.block-highlight .block-img img { display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; object-fit: cover; }

.block-highlight .item h3 { font-size: 21px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 8px; }

.block-highlight .item .img { margin-bottom: 8px; }

.block-highlight .item .img img { display: block; height: 42px; width: auto; }

.block-highlight__top { margin-bottom: 48px; }

.what-inside { padding: 100px 0; }

.what-inside h2 { font-size: 32px; line-height: 40px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 24px; }

.what-inside .img-wrap { max-width: 350px; margin: 0 auto; position: relative; z-index: 3; }

.what-inside .img-wrap:before, .what-inside .img-wrap:after { content: ""; position: absolute; top: -8px; left: -16px; width: 100%; height: 100%; background-color: #FFFFFF; box-shadow: -4px 4px 8px 0 rgba(0, 0, 0, 0.2); z-index: -1; }

.what-inside .img-wrap:after { top: -16px; left: -32px; z-index: -2; }

.what-inside .img-wrap .img { position: relative; width: 100%; padding-top: 140%; }

.what-inside .img-wrap .img img { display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; object-fit: cover; }

.download-ebook__wrap { text-align: center; padding: 100px 0; border-top: 1px solid #e2e2e2; }

.download-ebook h2 { font-size: 32px; line-height: 40px; margin-bottom: 24px; }

.download-ebook p { margin-bottom: 48px; }

.share-ebook__wrap { text-align: center; padding: 100px 0; border-top: 1px solid #e2e2e2; }

/* ------------------------------------------------------------------------------------------------ */
/* Footer */
/* ------------------------------------------------------------------------------------------------ */
.footer_sc1 { background: #F2F4F7; }

.footer_sc11 { padding: 64px 0; }

.footer h3, .footer .footer-menu__title { font-family: "Inter", sans-serif; font-weight: 500; margin: 0 0 8px; }

.footer_menu li:not(:last-child) { margin: 0 0 8px; }

.footer a { color: #232933; }

.footer a:hover { color: #4B61DD; text-decoration: underline; }

.footer a span.tag { display: inline-block; color: #4B61DD; margin-left: 16px; }

.footer hr { margin: 0; }

.footer_sc12 { padding: 25px 0 56px; }

.footer_addr p:nth-last-child(2) { margin: 0; }

.footer_addr_item { margin: 0 0 8px; padding-left: 85px; position: relative; }

.footer_addr_item h3, .footer_addr_item p, .footer_addr_item b, .footer_addr_item .footer-menu__title { margin: 0; }

.footer_addr_item b { font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 26px; display: inline-block; position: absolute; top: 0; left: 0; }

@media (max-width: 991px) { .footer_addr_item { padding-left: 0 !important; }
  .footer_addr_item b { position: relative; display: block; } }

.footer_social a:hover { text-decoration: none; opacity: 0.5; }

.footer_social a:not(:last-child) { margin-right: 10px; }

.footer_lang.bootstrap-select .filter-option { display: inline-block; vertical-align: middle; }

.footer_lang.bootstrap-select .bttn { border: none; font-weight: 400; text-align: right; padding-right: 0; }

.footer_lang.bootstrap-select .bttn:before { content: ""; width: 20px; height: 20px; position: relative; display: inline-block; background: url("../images/icon-lang.svg") center; background-size: cover; vertical-align: middle; margin-right: 10px; }

.footer_lang.bootstrap-select .bttn:after { display: none; }

.footer_lang.bootstrap-select div.dropdown-menu { max-width: 160px; right: 0; left: auto !important; border: none; }

.footer_lang.bootstrap-select div.dropdown-menu a { border: solid #afafaf; border-width: 0 1px; }

.footer_lang.bootstrap-select div.dropdown-menu li:first-child a { border-top-width: 1px; border-radius: 4px 4px 0 0; }

.footer_lang.bootstrap-select div.dropdown-menu li:last-child a { border-bottom-width: 1px; border-radius: 0 0 4px 4px; }

.footer_sc2 { padding: 35px 0; border-top: 1px solid #e2e2e2; }

.footer_sc2 a { color: #4A26C4; }

@media (max-width: 991px) { .footer_sc2 .col-md-8 { text-align: left; } }

@media (max-width: 479px) { .footer_sc2 .col-md-8 { text-align: center; } }

.footer_mekari { display: inline-block; margin-right: 30px; }

.footer_logo { display: block; height: 55px; width: auto; margin-bottom: 24px; }

@media (max-width: 991px) { .footer_logo { height: 50px; } }

.footer_menu { margin-bottom: 16px; }

.footer .cert { margin: 8px -7px 24px; }

.footer .cert .img { display: inline-block; vertical-align: top; max-width: 100px; padding: 0 7px; }

.footer .cert .img img { display: block; }

@media (max-width: 1024px) { .footer .cert .img { margin-bottom: 24px; }
  .footer .cert .img:last-child { margin-bottom: 0; } }

.footer_social { margin-bottom: 24px; }

.footer_social a:not(:last-child) { margin-right: 10px; }

.footer .link-more { margin-right: 36px; }

.footer .wpml-ls-legacy-dropdown .wpml-ls-flag { margin-right: 12px; }

.footer_sc2.footer_sc2_alt { background: #FAFBFA; padding: 24px 0; }

.footer-alt-small { padding-top: 20px; padding-bottom: 20px; background: #E6E9EF; }

.footer-alt-small__logo { display: inline-block; vertical-align: middle; }

.footer-alt-small__logo a { display: inline-block; }

.footer-alt-small__logo img { display: block; height: 55px; width: auto; }

.footer-alt-small .copyright { display: inline-block; vertical-align: middle; padding-left: 56px; font-size: 14px; line-height: 20px; }

.footer-alt-small .link-external { font-size: 14px; line-height: 24px; }

.footer-alt-small .link-external::before { position: relative; top: -2px; }

.footer-alt-small__buttons { text-align: right; }

@media (max-width: 991px) { .footer-alt-small { padding-left: 8px; padding-right: 8px; text-align: center; }
  .footer-alt-small__logo { margin-bottom: 16px; }
  .footer-alt-small .copyright { padding: 0 24px; }
  .footer-alt-small__buttons { padding-top: 16px; text-align: center; } }

/* ------------------------------------------------------------------------------------------------ */
/* responsive */
/* ------------------------------------------------------------------------------------------------ */
@media (max-width: 1600px) and (min-width: 1200px) { .moz .web-wrapper { transform: scale(0.8); transform-origin: 0 0; width: 125%; float: left; }
  .webkit { zoom: 0.8; zoom: 80%; }
  .slist_colimg img, .ss2_colimg img { max-height: 80vh; }
  .modal-backdrop { width: 120%; height: 120%; }
  .modal-open .web-wrapper { transform: none !important; }
  .modal-download iframe { height: 100vh; } }

@media (min-width: 1600px) { .web-wrapper { height: auto !important; } }

@media (max-width: 1200px) { .web-wrapper { height: auto !important; } }

@media (min-width: 1200px) { .hs1 .container { padding-bottom: 30px; }
  .hs2, .ins, .cs2 { padding-bottom: 80px; }
  .hs3 { padding: 80px 0; }
  .pr2 .container { padding: 80px 0; }
  .pr2 + .ss2 { margin-top: 40px; }
  .pr4, .pr1, .in1 { padding-bottom: 80px; }
  .ptb-80, .cmp .container { padding: 80px 0; }
  .s1_vwrap { margin-top: 100px; }
  .slist_item { padding: 80px 0; }
  .ss2 { padding-bottom: 40px; }
  .ss2_item { padding: 40px 0; }
  .ins2 { padding: 30px 0 80px; }
  .css { padding-bottom: 80px; }
  .css_testimony { padding: 80px 0; } }

@media (max-width: 1200px) { .footer_social a:not(:last-child) { margin-right: 5px; } }

@media (min-width: 992px) { .visible-md-down { display: none; }
  .ss2_item:nth-child(even) .ss2_coltext { margin-left: 8.33%; }
  .cs2 .col-lg-4:nth-child(n+4) { margin-top: 32px; }
  .css .col-lg-8 { padding-right: 10%; } }

@media (min-width: 768px) { .visible-xs { display: none; }
  .modal h2 { font-size: 24px; line-height: 1.33; }
  .hs2_colimg { flex: 0 0 59.77%; max-width: 59.77%; }
  .hs2_coltext { flex: 0 0 40%; max-width: 40%; }
  h1.small, article h1 { font-size: 32px; letter-spacing: -0.5px; line-height: 1.25; }
  .in1_item { margin: 0 0 24px; }
  .in1 .col-md-6:nth-child(2n) { transform: translateY(15%); }
  .in1 .col-md-6:nth-child(2n):last-child { margin-bottom: 9.61%; }
  .ins2_item2:nth-child(n+4) { margin-top: 48px; }
  .slist_item:nth-child(even) .slist_colimg { order: 2; }
  .ss2 { padding-bottom: 60px; }
  .ss2 img { max-width: 90%; }
  .ss2_item:nth-child(even) .ss2_colimg { order: 2; text-align: right; }
  .in2_item { margin: 0 0 24px; }
  .in2_items .col-md-6:nth-child(even) { transform: translateY(24px); }
  .footer_addr_item { padding-left: 85px; position: relative; }
  .footer_addr_item h3, .footer_addr_item p { display: inline; }
  .footer_addr_item h3 { position: absolute; top: 0; left: 0; }
  .css .row.justify-content-between:not(:first-child) .css_sidebar { margin-top: -200px; }
  .ebk h3 { font-size: 24px; line-height: 1.33; } }

@media (max-width: 1200px) and (min-width: 992px) { .menu-insight-header-container { margin: 0 -10px; }
  .pr1_item { padding: 10.67% 12.67%; } }

@media (max-width: 992px) and (min-width: 768px) { .footer .col-lg-2 { margin-bottom: 24px; }
  .footer_sc11 { padding-top: 40px; padding-bottom: 16px; }
  .footer_sc12 { padding-bottom: 40px; }
  .footer_sc12 div.col-6:not(.footer_addr) { left: auto; right: 16px; top: -58px; }
  .pr4 h2 { font-size: 28px; }
  .slist_coltext { padding-top: 5%; }
  .cs2 .col-lg-4:nth-child(n+3) { margin-top: 32px; }
  .hero_imgs { max-height: 550px; } }

@media (max-width: 991px) { .burger-menu { display: block; }
  .menu-insight-header-container { margin: -8px 0; }
  .hidden-md-down { display: none; }
  .footer_sc12 .col-6:not(.footer_addr) { position: absolute; left: -36%; top: 32px; display: block; width: auto; }
  .blog_slider .blog_item-img { border-radius: 6px 6px 0 0; }
  .blog_slider .blog_item-text { padding: 32px 16px 24px; }
  .blog_slider .blog_item-cat { padding: 16px 16px 32px; }
  .blog_slider .blog_item-cat:before { display: block; }
  .blog_slider .owl-nav { top: 30%; }
  .blog_related .blog_wrap .col-lg-4:last-child { display: none; }
  .modal:not(.proto) .modal-dialog { max-width: 100%; }
  .masthead--contact .masthead__media { margin-top: 2rem; }
  .masthead--contact .masthead__media img { display: block; width: 100%; height: auto; }
  .masthead__action a { margin-left: 0; }
  .masthead__action a.first { margin-bottom: 16px; }
  .our-customer__list { margin-left: -12px; margin-right: -12px; }
  .our-customer__list .item { max-width: 33.333333%; flex: 0 0 33.333333%; padding: 6px 12px; } }

@media (max-width: 768px) { html, body { font-size: 16px; line-height: 24px; }
  .slider-xs { margin: 0; }
  .slider-xs .tab-pane { display: block; }
  .hidden-xs { display: none; }
  .show-xs { display: block; }
  .block-xs { display: block !important; }
  .not-found h1 { font-size: 160px; }
  .not-found p { font-size: 16px; }
  .hero { padding-bottom: 50px; }
  .hero_action a + a { margin-left: 12px; }
  .hero_imgs { max-height: 260px; }
  .hs1 .container { padding: 50px 12px; }
  .hs1_stat { padding-left: 122px; }
  .hs1_stat p { font-size: 18px; }
  .hs2_item { margin-top: 20px; }
  .hs2_item.active:after { top: -23px; transform: rotate(-90deg); right: calc(50% - 10px); }
  .hs2 .owl-item.active .hs2_item { background: #2AC397; color: #fff; }
  .hs25 { padding: 0 0 50px; }
  .ptd-120 { padding: 50px 0; }
  .ptd-162 { padding-bottom: 50px; }
  .footer_sc11 { padding: 40px 0  20px; }
  .footer_sc12 { padding-bottom: 40px; }
  .footer_sc12 .col-6:not(.footer_addr) { right: 16px; top: -58px; left: auto; padding: 0; width: 165px; max-width: none; }
  .footer_lang.bootstrap-select .bttn { text-align: left; padding: 0; }
  .footer_sc2 { text-align: center; }
  .footer_sc2 span { display: block; margin-top: 8px; }
  .footer_sc2 .text-right { text-align: center; }
  .footer_addr { margin: 0 !important; }
  .footer_addr_item h3 { display: block; }
  .pr1_item { padding: 30px 0; }
  .pr1_action .btn { padding: 0 12px; }
  .pr1 .col-md-6:not(:last-child) { margin: 0; border-bottom: 1px solid #aaa; }
  .pr4 { padding-bottom: 50px; }
  .ins_title { margin: 0 0 32px; }
  .ins_logos img { margin: 5px; max-height: 35px; }
  .ins_img { margin: 0 0 36px; }
  .ins_box { margin: 32px 0; }
  .ins_link { max-width: 48px; }
  .ins_link:not(:last-child) { margin: 0 0 32px; }
  .ins_link:not(:last-child):before { height: 32px; }
  .ins_row { position: relative; }
  .ins_colnav { flex: 0 0 75px; max-width: 75px; }
  .ins_coltext { flex: 0 0 calc(100% - 76px); max-width: calc(100% - 76px); }
  .ins2 { padding: 50px 0; }
  .cmp .container { padding-left: 12px; padding-right: 12px; }
  .cmp .row { margin: 0 30px; }
  .cmp .row .col-md-4 { margin: 0; }
  .cmp_item:not(.active) .cmp_box { margin: 0; }
  .s1 { padding: 120px 0 50px; }
  .s1_vwrap { margin-top: 50px; }
  .s1_vtext { display: block; margin-top: 12px; }
  .s1_video { width: 100%; }
  .ss2_item + .ss2_item { padding-top: 0; }
  .slist_item { padding: 50px 0; }
  .slist_coltext { padding-top: 0; }
  .cs2 { padding-bottom: 60px; }
  .css_hero { margin: 0 -12px; margin: 0 0 24px; }
  .css_testimony { padding: 60px 0; }
  .css_wrap { position: static !important; }
  .sform_box { padding: 32px; }
  .sform h1 { font-size: 18px; }
  .static { padding-top: 120px; }
  .blog_related .blog_wrap .col-lg-4 { margin-bottom: 32px; }
  .blog_related .blog_wrap .col-lg-4:last-child { display: block; margin-bottom: 0; }
  .blog_category { display: block; }
  .blog_category-wrap { display: block; width: 100%; margin: 0; text-align: center; }
  .blog_category-wrap .blog_category-item { display: inline-block; float: none; padding: 4px 10px; }
  .blog_category .blog_search { margin-top: 16px; width: 100%; background: #fff; padding: 5px 0; border: 1px solid #E2E2E2; border-radius: 4px; }
  .blog_category .blog_search .btn-search:before { left: 5px; }
  .blog_category .blog_search .btn-search span { max-width: 0; opacity: 0; }
  .blog_category .blog_search form { display: flex; justify-content: center; }
  .blog_category .blog_search form input { text-align: left; }
  .blog_category .blog_search input { max-width: 100% !important; width: 100% !important; opacity: 1; }
  .blog_search.search-open input { max-width: 120px; }
  .blog_slider .blog_item-img { border-radius: 6px 6px 0 0; }
  .blog_slider .blog_item-text { padding: 0 16px 24px; }
  .blog_slider .owl-nav { top: 18%; }
  .bs-share { flex-wrap: wrap; }
  .bs-share .col-left { max-width: 100%; flex: 0 0 100%; }
  .bs-share .tags { padding: 24px 0 0; }
  .bs-subscription_wrap { max-width: 100%; flex: 0 0 100%; }
  .ebk { padding-top: 120px; }
  .ebk_item:not(:last-child) { margin: 0 0 50px; }
  .modal.proto .modal-dialog { padding: 32px 20px; }
  .select-tab { padding: 0; width: 100%; }
  .bs .gform_wrapper .field_description_below .gfield_description { position: relative; }
  .bs .gform_wrapper .gform_body { width: 100%; max-width: 100%; }
  .bs .gform_wrapper .gform_footer { width: 100%; max-width: 100%; padding-top: 16px !important; }
  .our-customer { padding: 0 12px; }
  .form-meeting { padding: 5rem 0; }
  .form-meeting__img { display: none; }
  .col-reverse-sm { flex-direction: column-reverse; }
  .block-clients { padding-top: 3rem; padding-bottom: 3rem; }
  .block-clients__title { text-align: center; }
  .block-clients__desc { text-align: center; max-width: 600px; margin: 0 auto 1.5rem; }
  .block-clients__desc p { margin-bottom: 1rem; }
  .block-clients ul.clients { display: flex; flex-wrap: wrap; justify-content: center; padding: 0; margin: 0; }
  .block-clients ul.clients li { display: flex; align-items: center; justify-content: center; width: 100%; max-width: 33.333333%; flex: 0 0 33.333333%; margin: 16px 0; padding: 0 16px; }
  .block-clients ul.clients li img { width: auto; height: 32px; }
  .block-clients ul.clients li .img--2 { display: none; }
  .block-clients--pricing { background: #F2F4F7; padding-top: 0; } }

.modal--custom { text-align: center; }

.modal--custom:before { content: ''; display: inline-block; height: 100%; vertical-align: middle; margin-right: -4px; }

.modal--custom .modal-dialog { margin: 0 auto; max-width: 801px; width: 100%; background: #E0EEFF; border-radius: 8px; display: inline-block; vertical-align: middle; text-align: left; }

.modal--custom .modal-dialog .modal-content { background: #E0EEFF; padding: 0; margin: 0; }

.modal--custom .modal-dialog .modal-content .modal-header { padding: 32px 0; text-align: center; }

.modal--custom .modal-dialog .modal-content .modal-body { padding: 0 50px 24px 50px; }

.modal--custom .modal-dialog .modal-content .modal-footer { background: #B4D3F2; padding: 24px 12px 32px; text-align: center; border-radius: 0 0 8px 8px; }

@media (max-width: 991px) { .modal--custom::before { display: none; }
  .modal--custom .modal-dialog .modal-content .modal-body { padding: 0 12px 24px 12px; } }

.mp__subtitle { display: block; width: 100%; text-align: center; margin-bottom: 24px; }

.mp__testimonial { display: block; width: 100%; text-align: center; margin-bottom: 24px; }

.mp__testimonial p { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; }

.mp__box { height: 100%; width: 100%; text-align: center; overflow: hidden; margin-bottom: 24px; }

.mp__box-head { background: #009BDE; color: #FFF; text-align: center; padding: 8px; width: 100%; border-radius: 8px 8px 0 0; font-family: "Inter", sans-serif; font-weight: 600; }

.mp__box-body { background: #FFF; color: #2B2A35; border-radius: 0 0 8px 8px; height: calc(100% - 65px); }

.mp__box-number { display: inline-block; width: 132px; text-align: center; font-family: "Inter", sans-serif; font-weight: 600; font-size: 52px; line-height: 56px; vertical-align: middle; }

.mp__box-desc { display: inline-block; width: calc(100% - 137px); text-align: left; vertical-align: middle; padding: 12px 8px 12px 0; }

.mp__meta { display: inline-block; vertical-align: middle; width: calc(100% - 182px); }

.mp__img { width: 60px; height: 60px; border-radius: 50%; overflow: hidden; margin-right: 16px; display: inline-block; vertical-align: middle; }

.mp__img img { width: auto; height: 100%; object-fit: cover; }

.mp__info { display: inline-block; vertical-align: middle; width: calc(100% - 81px); }

.mp__name { font-family: "Inter", sans-serif; font-weight: 600; }

.mp__client-logo { display: inline-block; vertical-align: middle; width: 170px; padding: 12px 0 12px 12px; border-left: 1px solid #009BDE; }

.mp__client-logo img { height: 60px; width: auto; }

@media (max-width: 991px) { .mp__meta { display: block; width: 100%; margin: 0 auto 18px auto; }
  .mp__client-logo { display: block; text-align: left; width: 100%; padding: 12px 0; border: none; } }

.mp-testi { height: 100%; width: 100%; text-align: center; margin-bottom: 24px; }

.mp-testi__head { display: inline-block; padding: 14px 12px; border: 1px solid #009BDE; border-bottom: 1px solid transparent; border-radius: 8px 8px 0 0; color: #009BDE; text-align: center; background: #FFF; }

.mp-testi__head span { vertical-align: middle; font-family: "Inter", sans-serif; font-weight: 600; }

.mp-testi__head .ico { width: 24px; height: 24px; margin-right: 12px; }

.mp-testi__body { padding: 24px 20px 12px; background: #FFF; border: 1px solid #009BDE; border-radius: 8px; height: calc(100% - 75px); }

.mp-testi__logo { margin-bottom: 24px; }

.mp-testi__logo img { height: 46px; width: auto; }

.mp-testi__content { margin-bottom: 24px; min-height: 170px; }

.mp-testi__meta-img { width: 60px; height: 60px; border-radius: 50%; overflow: hidden; margin: 0 auto 14px auto; }

.mp-testi__meta-img img { width: auto; height: 100%; object-fit: cover; }

.mp-testi__name { font-family: "Inter", sans-serif; font-weight: 600; }

.modal-success .modal-dialog { margin: 0 auto; padding: 64px 0; }

.modal-success .modal-dialog .modal-content { text-align: center; padding: 32px; max-width: 632px; width: 100%; }

@media (max-width: 991px) { .modal-success .modal-dialog .modal-content { padding: 16px; } }

.modal-success .modal-dialog .modal-icon { margin-bottom: 16px; }

.modal-success .modal-dialog .modal-icon img { object-fit: contain; }

.modal-success .modal-dialog .modal-title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 32px; line-height: 48px; margin-bottom: 16px; }

@media (max-width: 991px) { .modal-success .modal-dialog .modal-title { font-size: 28px; line-height: 36px; } }

.modal-success .modal-dialog .modal-desc { font-size: 16px; line-height: 24px; }

.modal-trial { width: 100%; }

.modal-trial__content { padding: 24px; text-align: center; color: #232933; }

.modal-trial__action { padding: 0 24px 24px; text-align: center; }

.modal-trial .modal-close { background: url("../images/ic-cancel-blue.svg") no-repeat; background-size: contain; }

.modal-trial .modal-dialog { display: flex; align-items: center; max-width: 340px; min-height: calc(100vh - 96px); margin: 48px auto; }

.modal-trial .modal-content { border-radius: 8px; padding: 0; }

@media (max-width: 991px) { .modal-trial .modal-content { margin-left: 16px; margin-right: 16px; } }

.partner-map { padding: 80px 12px; }

.partner-map__title { color: #FFF; font-size: 40px; line-height: 48px; margin-bottom: 40px; }

.partner-map__desc { margin-bottom: 64px; }

.partner-map__label { justify-content: center; text-align: center; margin-top: 18px; }

.partner-map__accr .item { padding: 16px 16px 16px 16px; border-bottom: 1px solid rgba(255, 255, 255, 0.15); position: relative; }

.partner-map__accr .item::after { content: ''; display: inline-block; width: 24px; height: 24px; background-size: contain; background: url("../images/ic-arrow-down-w.svg") no-repeat center; top: 16px; right: 16px; position: absolute; }

.partner-map__accr .item.active { background: #FFF; color: #2B2A35; border-bottom: none; border-radius: 4px; }

.partner-map__accr .item.active::after { background: url("../images/ic-chevron-down.svg") no-repeat center; transform: rotateZ(180deg); }

.partner-map__accr .item.active .item__desc { display: block; }

.partner-map__accr .item__title { font-size: 18px; line-height: 28px; padding-right: 40px; font-family: "Inter", sans-serif; font-weight: 600; }

.partner-map__accr .item__desc { display: none; }

@media (max-width: 991px) { .partner-map__title { font-size: 28px; line-height: 36px; margin-bottom: 28px; }
  .partner-map__desc { margin-bottom: 40px; }
  .partner-map br { display: none; } }

.partner-programs { padding: 80px 12px 40px 12px; }

.partner-programs__title { text-align: center; font-size: 40px; line-height: 48px; margin-bottom: 24px; }

.partner-programs .item { padding: 32px 16px; }

.partner-programs .item__icon { margin-bottom: 16px; }

@media (max-width: 991px) { .partner-programs__title { font-size: 28px; line-height: 36px; margin-bottom: 40px; }
  .partner-programs .item { padding-top: 0; padding-bottom: 16px; }
  .partner-programs .item__icon { width: 48px; margin-right: 16px; display: inline-block; vertical-align: middle; }
  .partner-programs .item__icon img { width: 48px; height: auto; }
  .partner-programs .item__title { width: calc(100% - 69px); display: inline-block; vertical-align: middle; }
  .partner-programs br { display: none; } }

.partner-rewards { padding: 64px 12px; }

.partner-rewards__title { margin-bottom: 40px; font-size: 32px; line-height: 40px; text-align: center; }

.partner-rewards__video { display: flex; justify-content: center; position: relative; }

.partner-rewards__video .play-btn { position: absolute; background: url("../images/ic-play-circle-alt.svg") no-repeat center; background-size: contain; height: 80px; width: 80px; top: calc(50% - 40px); left: calc(50% - 40px); border-radius: 50%; box-shadow: 0 0 0 rgba(0, 0, 0, 0.4); animation: pulse 2s infinite; }

@media (max-width: 991px) { .partner-rewards { padding-bottom: 24px; }
  .partner-rewards__title { font-size: 24px; line-height: 32px; margin-bottom: 32px; }
  .partner-rewards__video .play-btn { height: 50px; width: 50px; top: calc(50% - 25px); left: calc(50% - 25px); }
  .partner-rewards br { display: none; } }

.partner-cta { padding: 64px 12px; }

.partner-cta__title { margin-bottom: 40px; font-size: 32px; line-height: 40px; text-align: center; }

.partner-cta__desc { text-align: center; margin-bottom: 40px; }

.partner-cta__action { text-align: center; margin-bottom: 40px; }

@media (max-width: 991px) { .partner-cta { padding: 48px 12px; }
  .partner-cta__title { font-size: 24px; line-height: 32px; margin-bottom: 32px; }
  .partner-cta br { display: none; } }

.partner-marquee__slider .item { display: inline-block; }

.partner-marquee__slider .item img { display: inline-block; padding: 0 10px; }

@media (max-width: 991px) { .partner-marquee__slider .item { margin: 0 8px; max-width: 100vw; }
  .partner-marquee__slider .item img { padding: 0; object-fit: cover; width: calc(50% - 11px); height: auto; }
  .partner-marquee__slider .item img:last-child { margin-left: 16px; }
  .partner-marquee__slider .item img:first-child { height: 263px; width: 100%; padding-bottom: 16px; } }

.masthead--jurnal-pay { background: linear-gradient(180deg, #008CF2 0%, #004DC2 100%); position: relative; padding-top: 0; }

.masthead--jurnal-pay .masthead__logo { display: inline-block; margin-bottom: 44px; }

.masthead--jurnal-pay .masthead__title { color: #FFF; font-size: 32px; line-height: 40px; font-weight: 600; margin-bottom: 16px; }

.masthead--jurnal-pay .masthead__note { font-size: 12px; line-height: 16px; color: #FFF; position: absolute; left: 12px; bottom: 0; }

.masthead--jurnal-pay .masthead__left { min-height: 370px; }

.masthead--jurnal-pay .masthead__content { position: relative; padding: 40px 0 40px; }

.masthead--jurnal-pay .masthead__content .text-xl { font-size: 48px; line-height: 56px; font-weight: 700; color: #FFF; }

.masthead--jurnal-pay .masthead__content .btn-grey { color: #005FBF; }

.masthead--jurnal-pay .masthead__desc { margin-bottom: 40px; }

.masthead--jurnal-pay .masthead__desc p { font-size: 14px; line-height: 24px; color: #FFF; }

.masthead--jurnal-pay .masthead__media { position: absolute; right: 0; bottom: 0; max-width: 45%; }

.masthead--jurnal-pay .masthead__media .play-button { width: 76px; height: 76px; background: url("../images/ic-play-circle-alt.svg") no-repeat center; background-size: contain; border-radius: 50%; position: absolute; left: calc(50% - 38px); top: calc(50% - 38px); box-shadow: 0 0 0 rgba(0, 0, 0, 0.4); animation: pulse 2s infinite; }

@media (max-width: 991px) { .masthead--jurnal-pay .masthead__title { font-size: 24px; line-height: 36px; }
  .masthead--jurnal-pay .masthead__title br { display: none; }
  .masthead--jurnal-pay .masthead__note { position: relative; bottom: auto; left: auto; padding-top: 40px; }
  .masthead--jurnal-pay .masthead__left { min-height: initial; }
  .masthead--jurnal-pay .masthead__content { padding: 40px 12px; }
  .masthead--jurnal-pay .masthead__content .text-xl { font-size: 28px; line-height: 36px; }
  .masthead--jurnal-pay .masthead__media { position: relative; right: auto; bottom: auto; max-width: 100%; } }

.jp-video { padding: 46px 0; }

.jp-video__title { font-size: 24px; line-height: 32px; margin-bottom: 16px; }

.jp-video__video { margin-bottom: 35px; }

.jp-featured__title { font-size: 24px; line-height: 32px; margin-bottom: 16px; }

.jp-featured__desc { max-width: 600px; margin: 0 auto 64px; }

.jp-featured__main { padding-bottom: 64px; }

.jp-featured__main .item { display: flex; margin: 0 0 32px; }

.jp-featured__main .item__img { display: flex; width: 60px; height: 60px; background: #FFF; box-shadow: 0px 4px 4px rgba(50, 50, 71, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.06); border-radius: 10px; align-items: center; justify-content: center; }

.jp-featured__main .item__img img { display: block; width: 32px; height: 32px; object-fit: contain; object-position: center; }

.jp-featured__main .item__content { max-width: calc(100% - 60px); padding-left: 16px; }

.jp-featured__main .item__content .title { display: block; font-size: 14px; line-height: 20px; font-weight: 500; margin-bottom: 4px; }

.jp-featured__main .item__content .text { display: block; font-size: 12px; line-height: 16px; color: #777; }

.jp-featured__main span.line-dashed { position: absolute; z-index: 1; top: 0; left: -330px; width: 100%; height: calc(50% - 50px); border-bottom: 2px dashed #009BDE; border-left: 2px dashed #009BDE; border-radius: 0 0 0 16px; }

.jp-featured__main .box-l { position: relative; background: #F2F4F7; border: 1px solid #E2E2E2; border-radius: 16px; padding: 32px 24px; z-index: 2; }

.jp-featured__main .box-l .logo { margin-bottom: 24px; }

.jp-featured__main .box-l .item { position: relative; z-index: 2; max-width: 320px; }

.jp-featured__main .box-l .item:last-child { margin-bottom: 0; }

.jp-featured__main .box-l span.line { position: absolute; z-index: 1; top: 0; left: 28px; width: calc(100% + 60px); height: calc(100% + 60px); border-bottom: 2px dashed #009BDE; border-left: 2px dashed #009BDE; border-radius: 0 0 0 16px; }

.jp-featured__main .box-r { position: relative; z-index: 3; background: #E8F3F7; border: 1.5px solid #009BDE; border-radius: 16px; padding: 24px 24px 40px; }

.jp-featured__main .box-r .logo { display: block; height: 40px; width: auto; margin: 0 0 20px; }

.jp-featured__main .box-r .item:last-child { margin-bottom: 0; }

.jp-featured__main .box-b { position: relative; z-index: 2; }

.jp-featured__main .box-b span.line { display: block; width: 1px; height: 48px; border-left: 2px dashed #009BDE; position: absolute; left: 50%; bottom: 100%; }

.jp-featured__main .box-b__wrap { position: relative; z-index: 3; background: linear-gradient(180deg, #008CF2 0%, #004DC2 100%); padding: 16px 20px; border-radius: 16px; margin-top: 48px; }

.jp-featured__main .box-b__wrap span.title { color: #FFF; font-size: 16px; line-height: 24px; font-weight: 500; }

.jp-featured__main .box-b__wrap p { color: #FFF; font-size: 12px; line-height: 16px; }

.jp-featured__wrap { padding-top: 64px; padding-bottom: 0; }

.jp-featured__wrap .page-tabs .page-tabs__header { padding: 0 12px 40px 12px; display: flex; justify-content: center; }

.jp-featured__wrap .page-tabs .page-tabs__header .nav-tabs { display: flex; background: #F2F4F7; border-radius: 100px; padding: 4px; }

.jp-featured__wrap .page-tabs .page-tabs__header .nav-tabs .nav-item .nav-link { padding: 12px 24px; border-radius: 100px; background: transparent; border: 1.5px solid transparent; }

.jp-featured__wrap .page-tabs .page-tabs__header .nav-tabs .nav-item .nav-link .nav-item__title label { font-size: 16px; line-height: 24px; font-weight: 600; color: #9090A2; }

.jp-featured__wrap .page-tabs .page-tabs__header .nav-tabs .nav-item .nav-link.active { background: #FFF; border: 1.5px solid #005FBF; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); }

.jp-featured__wrap .page-tabs .page-tabs__header .nav-tabs .nav-item .nav-link.active .nav-item__title label { color: #005FBF; }

.jp-featured__wrap .page-tabs .page-tabs__header .nav-tabs .nav-item .nav-link:hover { box-shadow: none; }

@media (max-width: 991px) { .jp-featured__wrap { padding: 40px 12px; } }

.jp-oppst .item__title { font-size: 24px; line-height: 32px; margin-bottom: 12px; }

.jp-oppst__wrap { padding-top: 64px; padding-bottom: 64px; }

@media (max-width: 991px) { .jp-oppst .item__desc { margin-bottom: 24px; }
  .jp-oppst__wrap { padding: 40px 12px; } }

.jp-benefit { padding: 64px 0; }

.jp-benefit__subtitle { font-size: 12px; line-height: 20px; font-weight: 400; margin-bottom: 10px; }

.jp-benefit__title { font-size: 24px; line-height: 32px; margin-bottom: 16px; }

.jp-benefit__desc { margin-bottom: 40px; }

.jp-benefit__desc p { font-size: 14px; line-height: 24px; }

.jp-benefit .item { background: #FFF; padding: 24px; border-radius: 4px; }

.jp-benefit .item__icon { margin-bottom: 12px; }

.jp-benefit .item__title { display: block; font-size: 14px; line-height: 24px; font-weight: 600; margin-bottom: 12px; }

.jp-features { padding: 64px 0; }

.jp-features__title { font-size: 20px; line-height: 32px; margin-bottom: 16px; }

.jp-features__title.small { font-size: 16px; line-height: 24px; margin-top: 20px; position: relative; }

.jp-features__title.small::before { content: ''; display: inline-block; width: 48px; height: 6px; background: #009BDE; top: -30px; left: calc(50% - 24px); position: absolute; }

.jp-features__desc { margin-bottom: 40px; }

.jp-features__desc p { font-size: 14px; line-height: 24px; }

.jp-features__img { margin-bottom: 40px; }

.jp-features__action a { margin-right: 14px; }

.jp-features__action a:last-child { margin-right: 0; }

.jp-features ul.custom-list li { font-size: 14px; line-height: 24px; }

.jp-cs { padding: 64px 0; }

.jp-cs__title { font-size: 20px; line-height: 32px; margin-bottom: 16px; }

.jp-cs__desc { margin-bottom: 64px; }

.jp-cs__desc p { font-size: 14px; line-height: 24px; }

.jp-cs.block-cs .item__left .quote { font-size: 20px; line-height: 28px; }

.jp-cs.block-cs .item__right { background: #FFF; }

.jp-cs.block-cs .item__right .desc { font-size: 20px; line-height: 28px; }

.masthead--mpay .masthead__desc { margin-bottom: 24px; }

.masthead--mpay .masthead__desc p:last-child { margin-bottom: 0; }

.mpay-stats__title { text-align: center; margin-bottom: 48px; }

.mpay-stats .item__title { color: #009BDE; font-size: 48px; line-height: 56px; font-weight: 600; margin-bottom: 4px; }

.mpay-stats .item__text { font-weight: 600; max-width: 275px; font-size: 18px; line-height: 28px; }

.mpay-stats__wrap { padding-top: 80px; padding-bottom: 80px; }

@media (max-width: 991px) { .mpay-stats__title { text-align: left; }
  .mpay-stats__title br { display: none; }
  .mpay-stats .item { margin-bottom: 40px; }
  .mpay-stats .item__title { font-size: 36px; line-height: 44px; }
  .mpay-stats .item:last-child { margin-bottom: 0; }
  .mpay-stats__wrap { padding-top: 64px; padding-bottom: 64px; padding-left: 24px; padding-right: 24px; } }

.mpay-tabs__sub { font-size: 14px; line-height: 20px; margin-bottom: 12px; text-align: center; }

.mpay-tabs__title { text-align: center; margin-bottom: 28px; }

.mpay-tabs__desc { margin-bottom: 64px; text-align: center; }

.mpay-tabs__wrap { padding-top: 80px; }

.mpay-tabs__nav { margin-bottom: 64px; display: flex; margin-left: -12px; margin-right: -12px; }

.mpay-tabs__nav .nav-item { display: block; width: 100%; max-width: 50%; flex: 0 0 50%; padding-left: 12px; padding-right: 12px; }

.mpay-tabs__nav .nav-link { color: #2B2A35; position: relative; padding: 24px; padding-left: 96px; display: block; border: 1px solid #E3E3E8; border-radius: 4px; }

.mpay-tabs__nav .nav-link .ic { display: block; position: absolute; width: 56px; height: 56px; max-width: 56px; top: 24px; left: 24px; filter: grayscale(1) brightness(0.9); transition: all .3s ease; }

.mpay-tabs__nav .nav-link span.title { font-weight: 500; display: block; margin-bottom: 12px; }

.mpay-tabs__nav .nav-link.active { background: #F2F4F7; border: 1px solid #005FBF; box-shadow: 0px 0px 0px 1px #005fbf; }

.mpay-tabs__nav .nav-link.active .ic { filter: none; }

@media (max-width: 991px) { .mpay-tabs__title br { display: none; }
  .mpay-tabs__wrap { padding: 64px 24px; }
  .mpay-tabs__nav { margin-left: -8px; margin-right: -8px; margin-bottom: 0; }
  .mpay-tabs__nav .nav-item { padding-left: 8px; padding-right: 8px; }
  .mpay-tabs__nav .nav-link { padding: 16px; }
  .mpay-tabs__nav .nav-link .ic { position: relative; margin: 0 auto 10px; top: auto; left: auto; height: 32px; width: auto; }
  .mpay-tabs__nav .nav-link span.title { font-size: 14px; line-height: 20px; margin-bottom: 0; text-align: center; }
  .mpay-tabs__nav .nav-link p { display: none; } }

.mpay-video { padding-bottom: 80px; }

.mpay-video__title { font-size: 24px; line-height: 32px; margin-bottom: 40px; }

.mpay-video .btn-play { position: absolute; top: calc(50% - 50px); right: calc(50% - 50px); padding-left: 0; }

.mpay-video .btn-play::before { display: none; }

.mpay-video .btn-play:hover { border: 0 !important; }

.mpay-video .btn-play .bg { z-index: 2; display: block; width: 100px; height: 100px; border-radius: 50px; background-image: url(../images/ic-play-blue.svg); background-size: 22px; background-color: #FFF; background-repeat: no-repeat; background-position: 53% 33%; position: relative; box-shadow: 0 0 0 white; animation: pulseBig 2s infinite; }

.mpay-video .btn-play span { z-index: 4; display: block; width: 100%; font-size: 14px; line-height: 20px; color: #2B2A35; position: absolute; bottom: 20%; left: 0; text-align: center; }

.mpay-video__cover { width: 100%; padding-top: 58%; position: relative; overflow: hidden; border-radius: 4px; }

.mpay-video__cover img { display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; }

@media (max-width: 991px) { .mpay-video { padding-bottom: 64px; padding-left: 24px; padding-right: 24px; }
  .mpay-video__title { margin-bottom: 16px; } }

.mpay-block.bg-snow { background: #F2F4F7; }

.mpay-block__title { text-align: center; }

.mpay-block__desc { text-align: center; margin-bottom: 16px; }

.mpay-block .item__wrap { padding-top: 30px; padding-bottom: 30px; align-items: center; }

.mpay-block__wrap { padding-top: 80px; padding-bottom: 0; }

@media (max-width: 991px) { .mpay-block .item__img { padding: 24px 0; }
  .mpay-block__wrap { padding: 64px 24px 0; } }

.testimonial-industry--mpay { background: #FFF; }

.testimonial-industry--mpay.bg-snow { background: #F2F4F7; }

.testimonial-industry--mpay .testimonial-industry__wrap { padding: 48px 0; }

@media (max-width: 991px) { .testimonial-industry--mpay .testimonial-industry__wrap { padding: 0; } }

.block-cta-alt--mpay { padding-top: 16px; }

@media (max-width: 991px) { .block-cta-alt--mpay .block-cta-alt__title { padding-top: 0; font-size: 24px; line-height: 32px; }
  .block-cta-alt--mpay .block-cta-alt__title::before { display: none; }
  .block-cta-alt--mpay .container { padding-top: 0; padding-bottom: 64px; } }

.block-cs--mpay { background: #F2F4F7; padding-top: 80px; }

.block-cs--mpay .block-cs__title { text-align: center; margin-bottom: 28px; }

.block-cs--mpay .block-cs__desc { text-align: center; max-width: 600px; margin: 0 auto 64px; }

.block-cs--mpay .item__right { background: #FFF; }

.support-block--mpay .section-head { margin-bottom: 0; }

.mpay-fea:nth-child(even) .mpay-block .item__wrap { flex-direction: row-reverse; }

.mpay-fea .testimonial-industry .item .item__box { background: #10253E; color: #FFF; }

@media (max-width: 991px) { .mpay-fea .mpay-block .item__wrap { flex-direction: column-reverse !important; } }

.home-why--alt .item__img .img { padding: 0; }

.home-why--alt .item__wrap { height: 100%; }

.masthead--whatsnew .masthead__left { text-align: left; }

.masthead--whatsnew .masthead__meta { display: inline-block; color: #FFF; font-size: 12px; line-height: 18px; padding: 4px 8px; border-radius: 4px; background: #128297; margin-bottom: 8px; }

.masthead--whatsnew .masthead__title { font-size: 40px; line-height: 48px; }

.masthead--whatsnew .masthead__desc { margin: 0; max-width: 100%; }

.masthead--whatsnew .masthead__action { padding-top: 32px; }

.masthead--whatsnew .masthead__media { padding-top: 40px; }

.masthead--whatsnew .masthead__media img { width: 100%; height: auto; }

.masthead--whatsnew .masthead__wrap { padding-top: 24px; padding-bottom: 0; }

.masthead--whatsnew .masthead__content { padding-bottom: 0; }

@media (max-width: 991px) { .masthead--whatsnew .masthead__title { font-size: 36px; line-height: 44px; }
  .masthead--whatsnew .masthead__title br { display: none; }
  .masthead--whatsnew .masthead__wrap { padding-bottom: 0; }
  .masthead--whatsnew .masthead__desc br { display: none; } }

.whatsnew__title { font-size: 40px; line-height: 48px; margin-bottom: 40px; }

.whatsnew--release { padding-top: 80px; padding-bottom: 32px; }

.whatsnew--release .post__img { display: block; position: relative; width: 100%; max-width: 34%; flex: 0 0 34%; }

.whatsnew--release .post__img .img { display: block; position: relative; width: 100%; padding-top: 62%; }

.whatsnew--release .post__img img { display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 4px; }

.whatsnew--release .post__content { display: block; padding-left: 20px; width: 100%; max-width: 66%; flex: 0 0 66%; }

.whatsnew--release .post__title { font-size: 18px; line-height: 28px; margin-bottom: 12px; }

.whatsnew--release .post__buttons { padding-top: 16px; }

.whatsnew--release .post__wrap { display: flex; padding-bottom: 48px; }

.whatsnew--release .post--large .post__wrap { flex-wrap: wrap; padding-right: 40px; }

.whatsnew--release .post--large .post__img, .whatsnew--release .post--large .post__content { max-width: 100%; flex: 0 0 100%; padding: 0; }

.whatsnew--release .post--large .post__img { margin-bottom: 24px; }

.whatsnew--release .post--large .post__title { font-size: 32px; line-height: 40px; }

.whatsnew--release .post--large .post__buttons { padding-top: 32px; }

.whatsnew--power { padding-top: 80px; padding-bottom: 80px; }

.whatsnew--power .post.load-more-post { display: none; }

.whatsnew--power .post__img { display: block; position: relative; width: 100%; max-width: 40%; flex: 0 0 40%; }

.whatsnew--power .post__img .img { display: block; position: relative; width: 100%; padding-top: 62%; }

.whatsnew--power .post__img img { display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 4px; }

.whatsnew--power .post__content { display: block; padding-left: 24px; width: 100%; max-width: 60%; flex: 0 0 60%; }

.whatsnew--power .post__title { font-size: 24px; line-height: 32px; margin-bottom: 24px; }

.whatsnew--power .post__buttons { padding-top: 24px; }

.whatsnew--power .post__wrap { display: flex; align-items: center; padding-bottom: 40px; }

.whatsnew__loadmore { padding-top: 24px; }

@media (max-width: 991px) { .whatsnew__title { font-size: 28px; line-height: 36px; margin-bottom: 24px; }
  .whatsnew--release { padding-top: 48px; padding-bottom: 0; }
  .whatsnew--release .post__title { font-size: 18px; line-height: 28px; }
  .whatsnew--release .post__wrap { padding-bottom: 40px; }
  .whatsnew--release .post--large .post__wrap { padding-right: 0; }
  .whatsnew--release .post--large .post__buttons { padding-top: 16px; }
  .whatsnew--release .post--large .post__title { font-size: 20px; line-height: 32px; }
  .whatsnew--power { padding-top: 48px; padding-bottom: 64px; padding-left: 48px; padding-right: 48px; }
  .whatsnew--power .post__wrap { flex-wrap: wrap; padding-bottom: 40px; }
  .whatsnew--power .post__img { max-width: 100%; flex: 0 0 100%; margin-bottom: 24px; }
  .whatsnew--power .post__content { max-width: 100%; flex: 0 0 100%; padding: 0; }
  .whatsnew--power .post__title { font-size: 20px; line-height: 32px; margin-bottom: 16px; } }

.block-timeline { padding-top: 64px; padding-bottom: 64px; background: #F2F4F7; }

.block-timeline__title { text-align: center; font-size: 40px; line-height: 48px; margin-bottom: 40px; }

.block-timeline .timeline-wrapper .issue-container .date { display: block; font-size: 12px; line-height: 20px; color: #545465; margin-bottom: 8px; }

.block-timeline .timeline-wrapper .issue-container .title { display: block; font-family: "Inter", sans-serif; font-weight: 500; font-size: 32px; line-height: 40px; color: #2B2A35; margin-bottom: 16px; }

.block-timeline .timeline-wrapper .issue-container .buttons { padding-top: 16px; }

.block-timeline .timeline-wrapper .issue-container .buttons a { text-decoration: underline; }

.block-timeline .timeline-wrapper .legend-1 .dot, .block-timeline .timeline-wrapper .legend-laporan .dot { background: #4B7BEC; }

.block-timeline .timeline-wrapper .legend-1.selected .label, .block-timeline .timeline-wrapper .legend-laporan.selected .label { background: #4B7BEC; }

.block-timeline .timeline-wrapper .legend-1.issue-container, .block-timeline .timeline-wrapper .legend-laporan.issue-container { border-top: 3px solid #4B7BEC; }

.block-timeline .timeline-wrapper .legend-2 .dot, .block-timeline .timeline-wrapper .legend-inventory .dot { background: #F5BC42; }

.block-timeline .timeline-wrapper .legend-2.selected .label, .block-timeline .timeline-wrapper .legend-inventory.selected .label { background: #F5BC42; }

.block-timeline .timeline-wrapper .legend-2.issue-container, .block-timeline .timeline-wrapper .legend-inventory.issue-container { border-top: 3px solid #F5BC42; }

.block-timeline .timeline-wrapper .legend-3 .dot, .block-timeline .timeline-wrapper .legend-akuntansi .dot { background: #46978D; }

.block-timeline .timeline-wrapper .legend-3.selected .label, .block-timeline .timeline-wrapper .legend-akuntansi.selected .label { background: #46978D; }

.block-timeline .timeline-wrapper .legend-3.issue-container, .block-timeline .timeline-wrapper .legend-akuntansi.issue-container { border-top: 3px solid #46978D; }

.block-timeline .timeline-wrapper .legend-4 .dot, .block-timeline .timeline-wrapper .legend-biaya .dot { background: #6557D6; }

.block-timeline .timeline-wrapper .legend-4.selected .label, .block-timeline .timeline-wrapper .legend-biaya.selected .label { background: #6557D6; }

.block-timeline .timeline-wrapper .legend-4.issue-container, .block-timeline .timeline-wrapper .legend-biaya.issue-container { border-top: 3px solid #6557D6; }

.block-timeline .timeline-wrapper .legend-5 .dot, .block-timeline .timeline-wrapper .legend-mekari-pay .dot { background: #A6E1FA; }

.block-timeline .timeline-wrapper .legend-5.selected .label, .block-timeline .timeline-wrapper .legend-mekari-pay.selected .label { background: #A6E1FA; }

.block-timeline .timeline-wrapper .legend-5.issue-container, .block-timeline .timeline-wrapper .legend-mekari-pay.issue-container { border-top: 3px solid #A6E1FA; }

.block-timeline .timeline-wrapper .legend-6 .dot, .block-timeline .timeline-wrapper .legend-perpajakan .dot { background: #00BCD4; }

.block-timeline .timeline-wrapper .legend-6.selected .label, .block-timeline .timeline-wrapper .legend-perpajakan.selected .label { background: #00BCD4; }

.block-timeline .timeline-wrapper .legend-6.issue-container, .block-timeline .timeline-wrapper .legend-perpajakan.issue-container { border-top: 3px solid #00BCD4; }

.block-timeline .timeline-wrapper .legend-7 .dot, .block-timeline .timeline-wrapper .legend-faktur .dot { background: #3F51B5; }

.block-timeline .timeline-wrapper .legend-7.selected .label, .block-timeline .timeline-wrapper .legend-faktur.selected .label { background: #3F51B5; }

.block-timeline .timeline-wrapper .legend-7.issue-container, .block-timeline .timeline-wrapper .legend-faktur.issue-container { border-top: 3px solid #3F51B5; }

.block-timeline .timeline-wrapper .legend-8 .dot, .block-timeline .timeline-wrapper .legend-mobile-app .dot { background: #EE5253; }

.block-timeline .timeline-wrapper .legend-8.selected .label, .block-timeline .timeline-wrapper .legend-mobile-app.selected .label { background: #EE5253; }

.block-timeline .timeline-wrapper .legend-8.issue-container, .block-timeline .timeline-wrapper .legend-mobile-app.issue-container { border-top: 3px solid #EE5253; }

.block-timeline .timeline-wrapper .slides-wrapper { position: relative; padding-bottom: 24px; }

.block-timeline .timeline-wrapper .slides-wrapper::before, .block-timeline .timeline-wrapper .slides-wrapper::after { z-index: 3; content: ""; display: block; width: 72px; height: 100%; background: #F2F4F7; position: absolute; top: 0; }

.block-timeline .timeline-wrapper .slides-wrapper::before { left: 0; }

.block-timeline .timeline-wrapper .slides-wrapper::after { right: 0; }

.block-timeline .timeline-wrapper .slides-wrapper .line { bottom: 30px; }

.block-timeline .timeline-wrapper .slides-wrapper #next, .block-timeline .timeline-wrapper .slides-wrapper #prev { z-index: 4; background: transparent; bottom: 8px; display: flex; align-items: center; justify-content: center; border-radius: 24px; border: 1px solid #B4B4B4; }

.block-timeline .timeline-wrapper .slides-wrapper #next:hover, .block-timeline .timeline-wrapper .slides-wrapper #prev:hover { border-color: #4B61DD; background: #FFF; box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05); }

.block-timeline .timeline-wrapper .slides-wrapper #next:hover svg path, .block-timeline .timeline-wrapper .slides-wrapper #prev:hover svg path { fill: #4B61DD; }

.block-timeline .timeline-wrapper .timeline-legends { text-align: center; max-width: 1000px; padding-top: 32px; margin: 0 auto; }

.block-timeline .timeline-wrapper .timeline-legends .item { display: inline-block; position: relative; font-size: 14px; line-height: 20px; padding-left: 28px; margin: 10px 24px; }

.block-timeline .timeline-wrapper .timeline-legends .item .dot { display: block; position: absolute; width: 16px; height: 16px; left: 0; top: 2px; border-radius: 8px; }

@media (max-width: 991px) { .block-timeline__title { font-size: 28px; line-height: 36px; margin-bottom: 24px; }
  .block-timeline .timeline-wrapper .timeline-legends { column-count: 2; column-gap: 18px; }
  .block-timeline .timeline-wrapper .timeline-legends .item { display: inline-block; text-align: left; width: 100%; margin: 6px 0; font-size: 12px; line-height: 18px; padding-left: 20px; }
  .block-timeline .timeline-wrapper .timeline-legends .item .dot { width: 14px; height: 14px; } }

.changelog__head { background: #F1F5F9; position: relative; }

.changelog__head::after { width: 50%; height: 100%; position: absolute; right: 0; top: 0; content: ''; display: block; background: url(../images/ornament-changelog.png) no-repeat center right; background-size: contain; }

.changelog__head-sub { display: block; margin: 0 0 8px; font-family: "Inter", sans-serif; font-weight: 600; color: #232933; }

.changelog__head-title { display: block; margin: 0 0 16px; font-size: 40px; line-height: 56px; }

.changelog__head-desc { max-width: 600px; }

.changelog__head-content { padding-top: 64px; padding-bottom: 64px; }

.changelog__head-image { display: none; }

.changelog__head-image img { display: block; margin-left: auto; }

.changelog__body { padding-top: 64px; padding-bottom: 64px; }

.changelog__body .sticky-full { transition: all .4s ease; }

.changelog__upcoming .cu-head { padding: 10px 24px; background: #71717A; border-radius: 4px 4px 0 0; color: #FFF; font-size: 14px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

.changelog__upcoming .cu-content { padding: 16px 24px; background: #F2F4F7; border-radius: 0 0 4px 4px; color: #626B79; }

.changelog__upcoming .cu-content ul { position: relative; }

.changelog__upcoming .cu-content ul li { padding-left: 24px; position: relative; }

.changelog__upcoming .cu-content ul li::before { content: ""; display: block; width: 4px; height: 4px; border-radius: 2px; background: #626B79; position: absolute; top: 10px; left: 8px; }

.changelog__sidebar { padding-top: 0; padding-bottom: 24px; transition: all .4s ease; }

.changelog__sidebar.stick { padding-right: 0; }

.changelog__sidebar.show-top { padding-top: 32px; transition: all .4s ease; }

.changelog__filter { padding-right: 40px; max-height: calc(100vh - 435px); overflow: hidden; }

.changelog__filter .title { display: block; padding: 0 0 16px; font-family: "Inter", sans-serif; font-weight: 600; color: #232933; border-bottom: 1px solid #D0D6DD; }

.changelog__filter .filter-acc { position: relative; max-height: 285px; }

.changelog__filter .filter-acc .item_count b { padding: 2px 6px; background: #E8F5EB; border-radius: 12px; font-size: 14px; line-height: 20px; color: #3C914D; }

.changelog__filter .filter-acc .item__head { position: relative; display: block; font-size: 14px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; color: #232933; padding: 12px 40px 12px 0; border-bottom: 1px solid #D0D6DD; cursor: pointer; }

.changelog__filter .filter-acc .item__head > span { font-size: 14px; line-height: 24px; color: #626B79; }

.changelog__filter .filter-acc .item__head::after { content: ""; display: block; width: 20px; height: 20px; background: url(../images/ic-dropdown.svg) no-repeat; background-size: contain; position: absolute; right: 4px; top: 14px; transform: rotate(180deg); transition: all .4s ease; }

.changelog__filter .filter-acc .item__head.collapsed::after { transform: none; }

.changelog__filter .filter-acc .item__head:not(.collapsed) { border-bottom-color: transparent; }

.changelog__filter .filter-acc .item__body { border-bottom: 1px solid #D0D6DD; }

.changelog__filter .filter-acc .item__body ul { position: relative; }

.changelog__filter .filter-acc .item__body ul > li { padding: 8px 0; }

.changelog__filter .filter-acc .item__body.collapse.show { max-height: 100px; overflow-x: hidden; overflow-y: auto; }

.changelog__filter .filter-action { padding-top: 24px; }

.changelog__filter .filter-action .apply_filter { display: none; }

.changelog__cta { margin-top: 24px; padding-right: 40px; }

.changelog__cta .box { border-radius: 8px; border: 1px solid #D0D6DD; background: #FFF; padding: 16px; font-size: 14px; line-height: 20px; font-family: "Inter", sans-serif; font-weight: 600; }

.changelog__cta .box__title { margin-bottom: 8px; }

.changelog__cta .box__action { margin-top: 16px; text-align: center; }

.changelog__cta .box__action .btn { width: 100%; }

.changelog__search { position: relative; padding-right: 40px; margin: 0 0 24px; }

.changelog__search .form-control { height: 44px; border-radius: 6px; padding-left: 44px; }

.changelog__search .ic-search { display: block; width: 20px; height: 20px; position: absolute; top: 12px; left: 12px; background: url(../images/ic-search.svg) no-repeat; background-size: contain; }

.changelog__posts:first-child .post:first-child { padding-top: 0; }

.changelog__posts.loading { position: relative; }

.changelog__posts.loading .post { opacity: 0.5; }

.changelog__posts.loading::before { content: ""; display: block; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.2); background-image: url(../images/loading.svg); background-repeat: no-repeat; background-size: 64px; background-position: center center; position: fixed; top: 0; left: 0; z-index: 2; }

.changelog__posts .post { display: flex; padding-top: 48px; padding-bottom: 28px; border-bottom: 1px solid #E2E2E2; }

.changelog__posts .post .badge { display: inline-block; padding: 0 6px; font-size: 14px; line-height: 20px; border-radius: 10px; }

.changelog__posts .post .badge--green { background-color: #14B8A6; color: #FFF; }

.changelog__posts .post .badge--purple { background-color: #8B5CF6; color: #FFF; }

.changelog__posts .post .badge--yellow { background-color: #F59E0B; color: #FFF; }

.changelog__posts .post .badge--gray { background-color: #8B95A5; color: #FFF; }

.changelog__posts .post .badge--blue { background-color: #3B82F6; color: #FFF; }

.changelog__posts .post__title { font-size: 32px; line-height: 48px; margin: 0 0 40px; }

.changelog__posts .post__items .item { padding-top: 20px; padding-bottom: 20px; }

.changelog__posts .post__items .item__badge { margin: 0 0 16px; font-size: 14px; line-height: 20px; }

.changelog__posts .post__items .item__content a { color: #4B61DD; }

.changelog__posts .post__items .item__content h3 { font-size: 16px; line-height: 24px; margin: 0 0 10px; }

.changelog__posts .post__items .item__content p { margin: 0 0 16px; }

.changelog__posts .post__items .item__content p:last-child { margin: 0; }

.changelog__posts .post__items .item__content img { display: block; max-width: 100%; height: auto; }

.changelog__posts .post__date { display: block; width: 100%; max-width: 15%; flex: 0 0 15%; margin-top: 10px; color: #626B79; }

.changelog__posts .post__content { display: block; width: 100%; max-width: 85%; flex: 0 0 85%; }

.changelog__posts .post--empty { min-height: 50vh; display: block; text-align: center; }

.changelog__posts .post--empty .img { display: block; width: 169px; height: 141px; background: url(../images/not-found.png) no-repeat; background-size: contain; margin: 0 auto; }

.changelog__posts .post--empty .title { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 8px; }

.changelog__posts .post--empty .text { display: block; text-align: center; color: #626B79; margin-bottom: 16px; }

.changelog__more { padding-top: 48px; text-align: center; }

.changelog__sticky-sm { display: none; }

@media (max-width: 1360px) and (min-width: 1200px) { .changelog__filter { overflow-x: hidden; overflow-y: auto; } }

@media (max-width: 991px) { .changelog__head { padding-left: 8px; padding-right: 8px; }
  .changelog__head-content { padding-top: 32px; padding-bottom: 32px; }
  .changelog__head::after { display: none; }
  .changelog__body { padding-top: 32px; }
  .changelog__sidebar { padding-left: 8px; padding-right: 8px; margin-bottom: 24px; }
  .changelog__search { padding-right: 0; margin: 0 0 24px; }
  .changelog__cta { padding-right: 0; }
  .changelog__filter { padding-right: 0; }
  .changelog__filter .filter-action { display: none !important; }
  .changelog__filter.set-as-sticky, .changelog__filter.set-sticky { display: block; width: 100%; height: 100%; background: #FFF; position: fixed; top: 0; left: -100vw; opacity: 0; visibility: hidden; z-index: 1001; padding: 54px 0 104px; transition: all .4s ease; }
  .changelog__filter.set-as-sticky .title, .changelog__filter.set-sticky .title { display: none; }
  .changelog__filter.set-as-sticky .sticky-sm-close, .changelog__filter.set-sticky .sticky-sm-close { display: block; width: 20px; height: 20px; position: absolute; top: 20px; right: 20px; background: url(../images/ic-cross.svg) no-repeat; background-size: contain; }
  .changelog__filter.set-as-sticky .filter-acc, .changelog__filter.set-sticky .filter-acc { display: block; width: 100%; max-height: calc(100vh - 158px); overflow: auto; padding: 0 20px 32px; }
  .changelog__filter.set-as-sticky .filter-acc .custom-control, .changelog__filter.set-sticky .filter-acc .custom-control { padding-left: 24px; min-height: 20px; line-height: 20px; }
  .changelog__filter.set-as-sticky .filter-acc .custom-control-label, .changelog__filter.set-sticky .filter-acc .custom-control-label { font-size: 14px; line-height: 20px; }
  .changelog__filter.set-as-sticky .filter-acc .custom-control-label::before, .changelog__filter.set-sticky .filter-acc .custom-control-label::before { width: 16px; height: 16px; left: -24px; }
  .changelog__filter.set-as-sticky .filter-acc .custom-control-label::after, .changelog__filter.set-sticky .filter-acc .custom-control-label::after { width: 16px; height: 16px; left: -24px; }
  .changelog__filter.set-as-sticky .filter-acc .custom-control-input:checked ~ .custom-control-label::before, .changelog__filter.set-sticky .filter-acc .custom-control-input:checked ~ .custom-control-label::before { background-size: 8px; }
  .changelog__filter.set-as-sticky .filter-action, .changelog__filter.set-sticky .filter-action { position: fixed; bottom: 0; left: 0; width: calc(100vw + 8px); padding: 32px 24px; margin-left: -8px; margin-right: -8px; box-shadow: 0px -3px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04); }
  .changelog__filter.set-as-sticky .filter-action .apply_filter, .changelog__filter.set-sticky .filter-action .apply_filter { display: block; }
  .changelog__filter.set-as-sticky .filter-action .btn, .changelog__filter.set-sticky .filter-action .btn { padding: 7px 16px; margin: 0 8px !important; white-space: nowrap; }
  .changelog__filter.show-main-sm { opacity: 1; visibility: visible; left: 0; }
  .changelog__filter.show-main-sm .filter-action { display: flex !important; }
  .changelog__upcoming { padding-left: 8px; padding-right: 8px; }
  .changelog__posts { padding-left: 8px; padding-right: 8px; display: block; }
  .changelog__posts .post { display: block; }
  .changelog__posts .post__date { max-width: 100%; flex: 0 0 100%; margin: 0 0 24px; }
  .changelog__posts .post__content { max-width: 100%; flex: 0 0 100%; }
  .changelog__sticky-sm { display: block; }
  .changelog__sticky-sm--bar { display: flex; position: fixed; width: 100%; top: 0; opacity: 0; left: 0; background: #FFF; padding: 16px; z-index: 99; border-top: 1px solid #E2E2E2; border-bottom: 1px solid #E2E2E2; box-shadow: 0px 3px 5px rgba(119, 119, 119, 0.2); transition: all .4s ease; }
  .changelog__sticky-sm--bar.show { opacity: 1; top: 56px; z-index: 101; }
  .changelog__sticky-sm--bar .changelog__search { margin: 0; }
  .changelog__sticky-sm--bar .changelog__filter-button { padding-left: 24px; }
  .changelog__sticky-sm--bar span.open-filter { display: block; font-size: 16px; line-height: 24px; padding: 10px 0; text-align: center; white-space: nowrap; color: #4B61DD; font-family: "Inter", sans-serif; font-weight: 600; } }

.sticky-full .changelog__sidebar { max-height: calc(100vh - 120px); overflow: auto; }

@media (max-width: 991px) { .sticky-full .changelog__sidebar { max-height: initial; } }

body.show-filter { position: fixed; }

.recom-bundle--alt { padding-top: 64px; padding-bottom: 64px; }

.recom-bundle--alt h2 { text-align: center; margin-bottom: 48px; }

.recom-bundle--alt .recom-bundle__nav .item { position: relative; padding: 20px 60px 20px 32px; background: #FFF; border: 1px solid #E2E2E2; border-radius: 4px; margin-bottom: 12px; transition: all .3s ease; }

.recom-bundle--alt .recom-bundle__nav .item[data-toggle] { cursor: pointer; }

.recom-bundle--alt .recom-bundle__nav .item:after { content: ""; display: block; width: 24px; height: 24px; background: url("../images/ic-chevron-right-round.svg") no-repeat center; background-size: contain; position: absolute; top: calc(50% - 12px); right: 30px; opacity: 0; transition: all .3s ease; }

.recom-bundle--alt .recom-bundle__nav .item__logo { position: relative; margin-bottom: 12px; }

.recom-bundle--alt .recom-bundle__nav .item__logo img { display: block; height: 26px; width: auto; }

.recom-bundle--alt .recom-bundle__nav .item.active { border-color: #005FBF; background: rgba(0, 95, 191, 0.1) !important; }

.recom-bundle--alt .recom-bundle__nav .item.active:after { opacity: 1; right: 24px; }

.recom-bundle--alt .recom-bundle__nav .item:not(.active):hover { background: #F2F4F7; }

.recom-bundle--alt .tab-content { height: 100%; padding-bottom: 12px; }

.recom-bundle--alt .tab-pane { padding: 32px; border: 1px solid #E2E2E2; border-radius: 4px; height: 100%; }

.recom-bundle--alt .tab-pane__sm { display: none; }

.recom-bundle--alt .tab-pane h3 { margin-bottom: 16px; }

.recom-bundle--alt .tab-pane span.cat { color: #2B2A35; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; display: block; margin-bottom: 8px; }

.recom-bundle--alt .tab-pane ul.custom-list > li { margin-bottom: 8px; }

.recom-bundle--alt .tab-pane ul.custom-list > li:last-child { margin-bottom: 0; }

.recom-bundle--alt .tab-pane .price:not(:last-child) { margin-bottom: 24px; }

.recom-bundle--alt .tab-pane .price .ps { font-size: 14px; line-height: 20px; color: #777; }

.recom-bundle--alt .tab-pane .price .pn { font-size: 28px; line-height: 48px; font-family: "Inter", sans-serif; font-weight: 500; color: #2B2A35; }

.recom-bundle--alt .tab-pane .price .pt { font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 500; color: #2B2A35; }

.recom-bundle--alt .tab-pane .tab-action { padding-top: 32px; }

@media (max-width: 991px) { .recom-bundle--alt { padding: 48px 8px; }
  .recom-bundle--alt__nav { display: none; }
  .recom-bundle--alt .tab-pane { margin-bottom: 24px; display: block; height: auto; opacity: 1; }
  .recom-bundle--alt .tab-pane__sm { display: block; }
  .recom-bundle--alt .tab-pane__sm .item__logo { margin-bottom: 12px; }
  .recom-bundle--alt .tab-pane__sm .item__desc { margin-bottom: 24px; }
  .recom-bundle--alt .tab-pane .hide-sm { display: none; }
  .recom-bundle--alt .tab-pane:last-child { margin-bottom: 0; } }

.pricing-table { position: relative; }

.pricing-table__header { background: #FFF; position: relative; transition: all linear .3s; }

.pricing-table__header.sticky-me1.stick, .pricing-table__header.sticky-me2.stick { top: 53px !important; transition: all linear .3s; background: #FFF; z-index: 5; }

.pricing-table__header.sticky-me1.stick .pricing-table__td .head__desc, .pricing-table__header.sticky-me1.stick .pricing-table__th .head__desc, .pricing-table__header.sticky-me2.stick .pricing-table__td .head__desc, .pricing-table__header.sticky-me2.stick .pricing-table__th .head__desc { display: none; }

.pricing-table__header.sticky-me1.stick .pricing-table__td .head__price span.badge, .pricing-table__header.sticky-me1.stick .pricing-table__td .head__price span.label, .pricing-table__header.sticky-me1.stick .pricing-table__th .head__price span.badge, .pricing-table__header.sticky-me1.stick .pricing-table__th .head__price span.label, .pricing-table__header.sticky-me2.stick .pricing-table__td .head__price span.badge, .pricing-table__header.sticky-me2.stick .pricing-table__td .head__price span.label, .pricing-table__header.sticky-me2.stick .pricing-table__th .head__price span.badge, .pricing-table__header.sticky-me2.stick .pricing-table__th .head__price span.label { display: none; }

.pricing-table__header.sticky-me1.stick.show-top, .pricing-table__header.sticky-me2.stick.show-top { top: 94px !important; transition: all linear .3s; }

.pricing-table__header .pricing-table__td, .pricing-table__header .pricing-table__th { text-align: left; }

.pricing-table__header .pricing-table__td:first-child, .pricing-table__header .pricing-table__th:first-child { min-width: 28.6% !important; width: 100%; align-items: end; font-size: 20px; line-height: 32px; }

.pricing-table__header .pricing-table__td .head, .pricing-table__header .pricing-table__th .head { display: block; height: 100%; width: 100%; }

.pricing-table__header .pricing-table__td .head__title, .pricing-table__header .pricing-table__th .head__title { font-size: 20px; line-height: 28px; margin-bottom: 8px; }

.pricing-table__header .pricing-table__td .head__desc, .pricing-table__header .pricing-table__th .head__desc { font-size: 14px; line-height: 20px; color: #626B79; margin-bottom: 24px; }

.pricing-table__header .pricing-table__td .head__price .pr, .pricing-table__header .pricing-table__th .head__price .pr { font-size: 20px; line-height: 32px; margin-bottom: 24px; display: block; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-table__header .pricing-table__td .head__price span.badge, .pricing-table__header .pricing-table__th .head__price span.badge { display: inline-block; vertical-align: middle; color: #005FBF; font-size: 14px; line-height: 20px; font-weight: 500; border: 1px solid #005FBF; background: #FFF; padding: 0 16px; border-radius: 14px; margin: 0 0 16px; text-decoration: none; min-height: 20px; }

.pricing-table__header .pricing-table__td .head__price span.badge.hide, .pricing-table__header .pricing-table__th .head__price span.badge.hide { opacity: 0; }

.pricing-table__header .pricing-table__td .head__price span.label, .pricing-table__header .pricing-table__th .head__price span.label { font-size: 14px; line-height: 20px; margin-bottom: 16px; display: block; color: #9090A2; text-decoration: line-through; min-height: 20px; }

.pricing-table__header .pricing-table__td .head__buttons, .pricing-table__header .pricing-table__th .head__buttons { text-align: center; }

.pricing-table__header .pricing-table__td .head__buttons .btn.cta-waba, .pricing-table__header .pricing-table__th .head__buttons .btn.cta-waba { padding: 12px 6px 12px 4px; }

.pricing-table__header .pricing-table__td .head__buttons a, .pricing-table__header .pricing-table__th .head__buttons a { margin-bottom: 14px; }

.pricing-table__header .pricing-table__td .head__buttons a:last-child, .pricing-table__header .pricing-table__th .head__buttons a:last-child { margin-bottom: 0; }

.pricing-table__header .pricing-table__td .head.head--new .head__pill, .pricing-table__header .pricing-table__th .head.head--new .head__pill { font-family: "Inter", sans-serif; font-weight: 600; padding: 2px 12px; display: inline-block; margin-bottom: 10px; background: #009BDE; font-size: 14px; line-height: 20px; color: #FFF; opacity: 0; visibility: hidden; border-radius: 6px; }

.pricing-table__header .pricing-table__td .head.head--new .head__pill.show, .pricing-table__header .pricing-table__th .head.head--new .head__pill.show { opacity: 1; visibility: visible; }

.pricing-table__header .pricing-table__td .head.head--new .head__price, .pricing-table__header .pricing-table__th .head.head--new .head__price { margin-bottom: 24px; }

.pricing-table__header .pricing-table__td .head.head--new .head__price span.label, .pricing-table__header .pricing-table__th .head.head--new .head__price span.label { margin-bottom: 8px; }

.pricing-table__header .pricing-table__td .head.head--new .head__price span.pr, .pricing-table__header .pricing-table__th .head.head--new .head__price span.pr { margin-bottom: 8px; }

.pricing-table__header .pricing-table__td .head.head--new .head__price span.pr.discuss, .pricing-table__header .pricing-table__th .head.head--new .head__price span.pr.discuss { margin-top: 40px; }

.pricing-table__header .pricing-table__td .head.head--new .head__price span.pr small, .pricing-table__header .pricing-table__th .head.head--new .head__price span.pr small { color: #9090A2; }

.pricing-table__header .pricing-table__td .head.head--new .head__price span.notes, .pricing-table__header .pricing-table__th .head.head--new .head__price span.notes { font-size: 14px; line-height: 20px; color: #9090A2; }

.pricing-table__tr { display: flex; align-items: stretch; justify-content: center; }

.pricing-table__td, .pricing-table__th { display: flex; padding: 16px; min-width: 23.8%; border-bottom: 1px solid #E2E2E2; border-left: 1px solid #E2E2E2; justify-content: center; align-items: center; text-align: center; flex-flow: column; }

.pricing-table__td:first-child, .pricing-table__th:first-child { border-left: 0; justify-content: start; text-align: left; align-items: start; min-width: 28.6% !important; width: 100%; }

.pricing-table__td > span, .pricing-table__th > span { position: relative; display: block; width: 100%; }

.pricing-table__td > span.check, .pricing-table__th > span.check { width: 32px; height: 32px; background: url(../images/ic-check-green.svg) no-repeat center; }

.pricing-table__td > span.uncheck, .pricing-table__th > span.uncheck { width: 32px; height: 32px; background: url(../images/ic-close-round.svg) no-repeat center; }

.pricing-table__td > span i.info, .pricing-table__th > span i.info { background: url("../images/ic-info.svg") no-repeat center; background-size: contain; width: 24px; height: 24px; display: inline-block; vertical-align: middle; margin-left: 8px; cursor: help; position: absolute; top: 0; right: 0; }

.pricing-table__td > span > .badge, .pricing-table__th > span > .badge { display: inline-block; position: absolute; right: 0; top: 0; padding: 2px 12px; background: #FBBF24; color: #FFF; border-radius: 6px; font-size: 14px; line-height: 20px; margin: 0; font-family: "Inter", sans-serif; font-weight: 600; }

.pricing-table__td ul, .pricing-table__th ul { list-style: disc; padding-left: 15px; margin-top: 14px; width: 100%; }

.pricing-table__td ul li, .pricing-table__th ul li { position: relative; width: 100%; margin-bottom: 8px; }

.pricing-table__td ul li i.info, .pricing-table__th ul li i.info { background: url("../images/ic-info.svg") no-repeat center; background-size: contain; width: 24px; height: 24px; display: inline-block; vertical-align: middle; margin-left: 8px; cursor: help; position: absolute; top: 0; right: 0; }

.pricing-table__td ul li > .badge, .pricing-table__th ul li > .badge { display: inline-block; position: absolute; right: 0; top: 0; padding: 2px 12px; background: #FBBF24; color: #FFF; border-radius: 6px; font-size: 14px; line-height: 20px; margin: 0; font-family: "Inter", sans-serif; font-weight: 600; }

.pricing-table--alt .pricing-table__header .pricing-table__td, .pricing-table--alt .pricing-table__header .pricing-table__th { text-align: left; justify-content: end; align-items: start; }

.pricing-table--alt .pricing-table__header .pricing-table__td:first-child, .pricing-table--alt .pricing-table__header .pricing-table__th:first-child { min-width: 23.6% !important; }

.pricing-table--alt .pricing-table__header .pricing-table__td:first-child span, .pricing-table--alt .pricing-table__header .pricing-table__th:first-child span { font-family: "Inter", sans-serif; font-weight: 600; }

.pricing-table--alt .pricing-table__td, .pricing-table--alt .pricing-table__th { min-width: 19.1%; }

.pricing-table--alt .pricing-table__td:first-child, .pricing-table--alt .pricing-table__th:first-child { min-width: 23.6% !important; }

.pricing-card__wrapper { padding: 0 !important; }

.pricing-card__slider .owl-stage-outer { padding-bottom: 60px !important; }

.pricing-card__slider.owl-carousel { display: block; }

.pricing-card__item { border: 1px solid #8B95A5; background: #F2F4F7; border-radius: 6px; }

.pricing-card__item .head { display: block; height: 100%; width: 100%; padding: 16px; }

.pricing-card__item .head__title { font-size: 20px; line-height: 28px; margin-bottom: 8px; }

.pricing-card__item .head__desc { font-size: 14px; line-height: 20px; color: #626B79; margin-bottom: 24px; }

.pricing-card__item .head__price .pr { font-size: 20px; line-height: 32px; margin-bottom: 24px; display: block; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing-card__item .head__price span.badge { display: inline-block; vertical-align: middle; color: #005FBF; font-size: 14px; line-height: 20px; font-weight: 500; border: 1px solid #005FBF; background: #FFF; padding: 0 16px; border-radius: 14px; margin: 0 0 16px; text-decoration: none; min-height: 20px; }

.pricing-card__item .head__price span.badge.hide { opacity: 0; }

.pricing-card__item .head__price span.label { font-size: 14px; line-height: 20px; margin-bottom: 16px; display: block; color: #9090A2; text-decoration: line-through; min-height: 20px; }

.pricing-card__item .head__buttons { text-align: center; }

.pricing-card__item .head__buttons a { margin-bottom: 14px; }

.pricing-card__item .head__buttons a:last-child { margin-bottom: 0; }

.pricing-card__item .body { padding: 16px; border-top: 1px solid #D0D6DD; }

.pricing-card__item .body ul li { position: relative; font-size: 16px; line-height: 24px; padding: 10px 5px 10px 40px; margin-bottom: 8px; }

.pricing-card__item .body ul li::before { content: ''; width: 32px; height: 32px; background-size: contain; position: absolute; top: 6px; left: 0; }

.pricing-card__item .body ul li.check::before { background: url("../images/ic-check-green.svg") no-repeat center; }

.pricing-card__item .body ul li.uncheck::before { background: url("../images/ic-close-round.svg") no-repeat center; }

.pricing-card__item .body ul li > ul { list-style: disc; padding: 0; margin-left: 15px; }

.pricing-card__item .body ul li > ul li { padding: 5px 10px; margin-bottom: 0; font-style: italic; }

.pricing-card__item .body ul li > ul li::before { display: none; }

.pricing-card .owl-item.active .pricing-card__item { background: #FFF; box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12); }

.pricing-card__nav { display: flex; justify-content: space-between; align-items: center; margin-bottom: 18px; position: relative; width: 100% !important; transition: all linear .3s; }

.pricing-card__nav.stick { position: fixed !important; top: 55px !important; left: 0 !important; width: 100% !important; opacity: 1 !important; z-index: 2; background: #FFF; height: 130px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); transition: all linear .3s; padding-left: 12px; padding-right: 12px; }

.pricing-card__nav.stick .owl-dots__wrap #selectedItem .item__cta { display: block; }

.pricing-card__nav.stick.show-top { position: fixed !important; }

.pricing-card__nav.show-top { position: relative !important; opacity: 1 !important; }

.pricing-card .owl-dots__wrap { display: inline-flex; width: calc(100% - 69px); flex-flow: column; justify-content: center; }

.pricing-card .owl-dots__wrap #selectedItem { font-family: "Inter", sans-serif; font-weight: 600; display: block; text-align: center; }

.pricing-card .owl-dots__wrap #selectedItem .item__title { margin-bottom: 8px; }

.pricing-card .owl-dots__wrap #selectedItem .item__cta { display: none; }

.pricing-card .owl-dots__wrap .owl-dots { margin: 0; }

.pricing-card .owl-dots__wrap .owl-dots .owl-dot { width: calc(100%/6); border-radius: 6px; }

.pricing-card .owl-navs { display: inline-flex; width: 30px; }

.pricing-card .owl-navs .owl-prev span::before { content: ''; display: block; background: rgba(0, 0, 0, 0.5) url(../images/icon-chevron-right-white.svg) no-repeat center; background-size: contain; width: 30px; height: 30px; border-radius: 50%; transform: scaleX(-1); }

.pricing-card .owl-navs .owl-next span::before { content: ''; display: block; background: rgba(0, 0, 0, 0.5) url(../images/icon-chevron-right-white.svg) no-repeat center; background-size: contain; width: 30px; height: 30px; border-radius: 50%; }

.pricing-cards--alt .card__head h3 { display: flex; justify-content: space-between; align-items: center; font-family: "Inter", sans-serif; font-weight: 600; }

.pricing-cards--alt .card__head > p { font-size: 16px; line-height: 24px; }

.pricing-cards--alt .card__head .bonus { padding: 12px; border-radius: 4px; background: #B4D3F2; font-size: 14px; line-height: 20px; margin-bottom: 24px; }

.pricing-cards--alt .card__head .bonus b { font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 8px; }

.pricing-cards--alt .card__head .bonus ul { list-style-type: disc; padding-left: 15px; }

.pricing-cards--alt .card__head .card__action { padding-top: 0; }

.pricing-cards--alt .card__toggle { color: #4B61DD; transition: all linear .3s; font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 24px; border-bottom: 0; }

.pricing-cards--alt .card__toggle:not(.collapsed) { border-bottom: 1px solid #D0D6DD; transition: all linear .3s; }

.pricing-cards--alt .card__toggle:hover { color: #4B61DD; }

.pricing-cards--alt .card__toggle::after { background: url(../images/ic-chev-blue-pricing.svg) no-repeat; background-size: contain; }

.pricing-cards--alt .card__action { text-align: center; padding: 26px 0 6px 0; }

.pricing-cards--alt .card__collapse-wrap { padding: 20px 0; }

.pricing-cards--alt .card__collapse-wrap span.cat { font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 24px !important; margin-bottom: 12px; text-decoration: none !important; padding-left: 16px; padding-right: 16px; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr { color: #232933; position: relative; padding-left: 35px; cursor: pointer; padding: 8px 50px; margin-bottom: 0; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr::before { width: 24px; height: 24px; top: 8px; left: 16px; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr::after { content: ''; display: inline-block; background: url(../images/ic-chev-gray-pricing.svg) no-repeat center; background-size: contain; position: absolute; top: 9px; right: 16px; height: 24px; width: 24px; transform: rotate(0deg); transition: transform linear .3s; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr > span { display: block; margin-bottom: 0; transition: all linear .3s; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.no-child::after { display: none !important; pointer-events: none !important; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.check::before { background: url(../images/ic-done.svg) no-repeat center; background-size: contain; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.medal { background: #ECF5FF; transition: background linear .3s; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.medal::before { background: url(../images/ic-medal.svg) no-repeat center; background-size: contain; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.locked { color: #8B95A5; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.locked span { text-decoration: line-through; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.locked .accr__content { color: #8B95A5; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.locked::before { background: url(../images/ic-locked.svg) no-repeat center; background-size: contain; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr__content { color: #626B79; height: 0; margin: 0; opacity: 0; visibility: hidden; transition: all linear .1s; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.active { padding-bottom: 12px; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.active > span { margin-bottom: 12px; transition: all linear .3s; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.active::after { transform: rotate(180deg); transition: transform linear .3s; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.active .accr__content { height: auto; opacity: 1; visibility: visible; transition: all linear .3s; }

.pricing-cards--alt .card__collapse-wrap ul.custom-list .accr.active.medal { background: #FFF; transition: background linear .3s; }

.pricing-cards--alt .card__collapse-wrap .link-more { text-align: center; margin-top: 26px; }

.modal--full-alt .modal-body { padding-bottom: 0 !important; }

.modal--full-alt .modal-body .container { display: flex; height: 100%; align-items: center; }

.modal--full-alt .modal-body .promo-benefit__title { font-size: 32px; line-height: 48px; }

@media (max-width: 991px) { .modal--full-alt .modal-body { padding-top: 45%; }
  .modal--full-alt .modal-body .promo-benefit__title { font-size: 28px; line-height: 36px; }
  .modal--full-alt .modal-body .promo-benefit__img { display: block; margin-bottom: 24px; }
  .modal--full-alt .modal-body .promo-benefit__wrap > .row { flex-direction: row-reverse; } }

.masthead--ltp { padding-top: 0; }

.masthead--ltp .masthead__logo { margin-bottom: 64px; }

.masthead--ltp .masthead__logo img { display: block; height: 55px; width: auto; margin: 0 auto; }

.masthead--ltp .masthead__icon { padding-top: 24px; padding-bottom: 35px; }

.masthead--ltp .masthead__icon img { display: block; height: 90px; width: auto; margin: 0 auto; }

.masthead--ltp .masthead__title { margin-bottom: 28px; }

.masthead--ltp .masthead__desc { max-width: 800px; margin: 0 auto; }

.masthead--ltp .masthead__wrap { padding-top: 64px; padding-bottom: 64px; text-align: center; }

@media (max-width: 991px) { .masthead--ltp .masthead__logo { margin-bottom: 32px; }
  .masthead--ltp .masthead__logo img { height: 40px; }
  .masthead--ltp .masthead__icon { padding-bottom: 32px; }
  .masthead--ltp .masthead__icon img { height: 80px; }
  .masthead--ltp .masthead__wrap { padding-top: 64px; padding-bottom: 64px; } }

.ltp-product { background: #F2F4F7; }

.ltp-product__title { text-align: center; margin-bottom: 28px; }

.ltp-product__desc { text-align: center; max-width: 600px; margin: 0 auto 64px; }

.ltp-product__wrap { padding-top: 40px; padding-bottom: 56px; }

.ltp-product .item { margin-bottom: 24px; }

.ltp-product .item__logo { margin-bottom: 14px; }

.ltp-product .item__logo img { display: block; height: 46px; width: auto; margin: 0 auto; }

.ltp-product .item__img { margin-bottom: 20px; }

.ltp-product .item__img img { display: block; max-width: 100%; height: auto; width: auto; margin: 0 auto; }

.ltp-product .item__title { text-align: center; font-size: 24px; line-height: 32px; margin-bottom: 20px; }

.ltp-product .item__desc { text-align: center; }

.ltp-product .item__action { text-align: center; padding-top: 48px; }

.ltp-product .item__wrap { background: #fff; border-radius: 4px; padding: 40px; }

@media (max-width: 991px) { .ltp-product__desc { margin-bottom: 32px; }
  .ltp-product__wrap { padding-left: 24px; padding-right: 24px; }
  .ltp-product .item__title { font-size: 20px; line-height: 30px; }
  .ltp-product .item__action { padding-top: 32px; }
  .ltp-product .item__wrap { padding: 24px; } }

.modal-ltp .close { position: absolute; top: 40px; right: 40px; color: #2B2A35; }

.modal-ltp .close::before { content: ""; display: inline-block; vertical-align: middle; width: 24px; height: 24px; margin-right: 8px; background: url("../images/ic-cancel.svg") no-repeat center; background-size: contain; }

.modal-ltp .close span { display: inline-block; vertical-align: middle; }

.modal-ltp__logo { margin-bottom: 32px; }

.modal-ltp__logo img { display: block; margin: 0 auto; height: 64px; width: auto; }

.modal-ltp__img img { display: block; width: auto; height: auto; max-width: 100%; margin: 0 auto; }

.modal-ltp__content { position: relative; }

.modal-ltp__action { margin-top: 40px; }

.modal-ltp .modal-body { padding-left: 80px; padding-right: 80px; }

.modal-ltp .modal-dialog { max-width: 1224px; margin: auto; }

@media (max-width: 991px) { .modal-ltp__img { margin-bottom: 24px; }
  .modal-ltp__img img { max-width: 80%; height: auto; }
  .modal-ltp__content { padding-bottom: 110px; }
  .modal-ltp__action { z-index: 10; position: fixed; bottom: 0; left: 0; width: 100%; padding: 24px 24px 16px; background: #FFF; border-top: 1px solid #E2E2E2; }
  .modal-ltp .close { top: 24px; right: 24px; }
  .modal-ltp .modal-dialog { margin: 0; }
  .modal-ltp .modal-content { margin: 0; border-radius: 0; padding-top: 80px; }
  .modal-ltp .modal-body { padding-left: 24px; padding-right: 24px; } }

.testimonial--promo .mpay-video__cover { padding-top: 56%; }

.testimonial--promo .mpay-video__cover img { height: auto; }

.product-featured .nav-tabs.product-featured__tabs::before { display: none; }

.product-featured .nav-tabs.product-featured__tabs .nav-item { opacity: 1; overflow: hidden; }

.product-featured .nav-tabs.product-featured__tabs .nav-link { position: relative; display: block; border: 0; text-align: center; opacity: 1; color: #2B2A35; background: #FFF; padding: 1.25rem 3.5rem 3.5rem; overflow: hidden; }

.product-featured .nav-tabs.product-featured__tabs .nav-link .icon { display: block; object-fit: contain; object-position: 50% 50%; margin: 0 auto 1rem; filter: grayscale(100%); }

.product-featured .nav-tabs.product-featured__tabs .nav-link .svg path { fill: #545465; transition: all .4s ease; }

.product-featured .nav-tabs.product-featured__tabs .nav-link span { display: block; font-size: 1rem; line-height: 1.5em; }

.product-featured .nav-tabs.product-featured__tabs .nav-link.active .icon { filter: grayscale(0); }

.product-featured .nav-tabs.product-featured__tabs .nav-link.active:after { content: ""; display: block; width: 82px; height: 82px; border-radius: 41px; background-color: #E5E5E5; position: absolute; top: calc(100% - 41px); left: calc(50% - 41px); background-image: url(../images/ic-chevron-down-cyan.svg); background-repeat: no-repeat; background-position: center 30%; }

.product-featured .tab-pane { padding: 42px 0; }

.product-featured .tab-pane .tab-title-sm { display: none; }

.product-featured .tab-pane h2 { margin-bottom: 24px; }

.product-featured .tab-pane .img img { display: block; width: 100%; height: auto; border-radius: 0.25rem; }

.product-featured .tab-pane .list-check li { padding-bottom: 2rem; }

.product-featured .tab-content { background-color: #E5E5E5; }

@media (max-width: 991px) { .product-featured { padding-top: 2.5rem; background: #E5E5E5; }
  .product-featured .nav-container { margin: 0; padding-top: 52px; background: transparent; }
  .product-featured .nav-item { width: 100%; left: 0; }
  .product-featured .nav-item.active { width: 100%; left: 0; }
  .product-featured .nav-item.active:after { right: 1.5rem; }
  .product-featured .nav-link { color: #2B2A35; font-size: 0.875rem; line-height: 1.429em; height: auto; max-height: initial; padding: 0.875rem 2.75rem 0.875rem 0.75rem !important; text-align: left !important; }
  .product-featured .nav-link .icon { display: none !important; }
  .product-featured .nav-link span { display: block !important; }
  .product-featured .nav-link span br { display: none; }
  .product-featured .nav-link.active { max-height: initial; padding: 0.875rem 2.75rem 0.875rem 0.75rem; }
  .product-featured .nav-link.active span { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  .product-featured .nav-link.active::after { display: none !important; }
  .product-featured .nav-tabs.product-featured__tabs { left: 0; top: 0; padding: 54px 0 0; margin-left: 0 !important; margin-right: 0 !important; position: absolute !important; }
  .product-featured .nav-tabs.product-featured__tabs.show .nav-link { height: auto; max-height: initial; padding: 0.875rem 2.75rem 0.875rem 0.75rem; }
  .product-featured .tab-pane { padding: 2.5rem 0 4rem; }
  .product-featured .tab-pane .tab-title-sm { display: block; margin: 0 0 2.5rem; }
  .product-featured .tab-pane .tab-title-sm .icon { margin: 0 0 0.5rem; }
  .product-featured .tab-pane .tab-title-sm .icon img { display: block; height: 3rem; width: auto; margin: 0 auto; }
  .product-featured .tab-pane .tab-title-sm .title { display: block; text-align: center; color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; }
  .product-featured .tab-pane .tab-title-sm .title br { display: none; }
  .product-featured .tab-pane .img { margin-bottom: 2rem; }
  .product-featured .tab-pane .img img { display: block; width: 100%; height: auto; border-radius: 0.25rem; }
  .product-featured .tab-pane .video { margin-bottom: 2rem; }
  .product-featured .tab-pane h2 { font-size: 1.5rem; line-height: 1.5em; margin-bottom: 1.5rem; }
  .product-featured .tab-pane .list-check li { padding-bottom: 2rem; } }

.card-box { background: #FFF; border: 1px solid #009BDE; border-radius: 12px; overflow: hidden; margin-bottom: 24px; height: calc(100% - 24px); }

.card-box__header { text-align: center; border-bottom: 1px solid #009BDE; background: #E0EEFF; padding: 24px 16px; }

.card-box__icon { display: inline-block; width: 52px; height: 52px; object-fit: contain; vertical-align: middle; margin-right: 8px; }

.card-box__title { display: inline-block; vertical-align: middle; text-align: left; }

.card-box__title p { margin-bottom: 0; }

.card-box__title b { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; }

.card-box__body { padding: 34px 24px 24px 24px; }

.card-vid { background: #FFFFFF; border-radius: 6px; padding: 6px; margin-bottom: 50px; height: calc(100% - 50px); position: relative; }

.card-vid__img { margin-bottom: 20px; border-radius: 6px; overflow: hidden; }

.card-vid__img img { height: 156px; width: auto; object-fit: cover; }

.card-vid__meta { font-size: 12px; line-height: 16px; color: #626B79; margin-bottom: 8px; padding-left: 8px; padding-right: 8px; }

.card-vid__meta span { position: relative; }

.card-vid__meta span:nth-child(2) { margin-left: 15px; }

.card-vid__meta span:nth-child(2)::before { content: '•'; position: absolute; left: -9px; top: -2px; display: inline-block; }

.card-vid__title { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; padding-left: 8px; padding-right: 8px; margin-bottom: 17px; }

.card-vid__action { position: absolute; bottom: -50px; left: 0; padding: 8px; background: #fff; width: 100%; border-radius: 0 0 6px 6px; opacity: 0; visibility: hidden; transition: all ease-out .3s; }

.card-vid__action .btn { width: 100%; }

.card-vid:hover .card-vid__action { opacity: 1; visibility: visible; transition: all ease-out .3s; z-index: 2; }

@media (max-width: 991px) { .card-vid__img img { width: 100%; height: auto; }
  .card-vid__action { position: relative; opacity: 1; visibility: visible; bottom: 0; } }

.fea-block { padding: 60px 0; }

.fea-block.row-reverse .row { flex-direction: row-reverse; }

.fea-block.row-reverse .row .promo-benefit__content { padding-right: 0; padding-left: 60px; }

@media (max-width: 991px) { .fea-block { padding: 40px 0; }
  .fea-block.row-reverse .row { flex-direction: column-reverse; }
  .fea-block.row-reverse .row .promo-benefit__content { padding-left: 0; }
  .fea-block .row { flex-direction: column-reverse; }
  .fea-block .promo-benefit__img { display: block; } }

.contact-ty { position: relative; }

.contact-ty:before { content: ""; display: block; width: 50%; height: 100%; background: #F2F4F7; position: absolute; left: 50%; top: 0; }

.contact-ty__logo { margin-bottom: 40px; }

.contact-ty__logo img { display: block; height: 48px; width: auto; }

.contact-ty__desc { margin-bottom: 32px; }

.contact-ty__desc + hr { margin-bottom: 32px; }

.contact-ty__form { margin-bottom: 80px; }

.contact-ty__form .form-title { margin-bottom: 32px; }

.contact-ty__form .btn-group--custom .btn { padding: 24px 16px 20px; border: 1px solid #e2e2e2; background-color: #FFF; }

.contact-ty__form .btn-group--custom .btn .icon { display: block; height: 32px; width: auto; margin: 0 auto 12px; }

.contact-ty__form .btn-group--custom .btn span { font-size: 12px; line-height: 16px; color: #000000; font-weight: 400; }

.contact-ty__form .form-group--products { padding-bottom: 40px; }

.contact-ty__form .form-group--pic { display: none; padding-bottom: 20px; }

.contact-ty__form .form-group--pic .pic-cb { margin-top: 8px; }

.contact-ty__form .form-group--pic .pic-cb label { margin: 0; font-weight: 400; }

.contact-ty__banner .item { display: none; }

.contact-ty__banner .item.active { display: block; }

.contact-ty__banner .item__img { margin-bottom: 24px; }

.contact-ty__banner .item__img img { display: block; max-width: 100%; width: auto; height: auto; }

.contact-ty__banner .item__title { font-size: 40px; line-height: 48px; margin-bottom: 24px; font-weight: 600; color: #2B2A35; }

.contact-ty__banner .item__desc { max-width: 600; }

.contact-ty .contact-ty__left { padding-top: 40px; padding-right: 24px; }

.contact-ty .contact-ty__right { padding-left: 48px; }

@media (max-width: 991px) { .contact-ty::before { display: none; }
  .contact-ty__form { margin-bottom: 48px; }
  .contact-ty .form-group--pic .col-lg-6 { margin-bottom: 12px; }
  .contact-ty .contact-ty__left { padding: 40px 24px 0; }
  .contact-ty .contact-ty__right { padding: 40px 24px 60px; background: #F2F4F7; } }

.contact-ty--alt { padding: 64px 0; background: #F2F4F7; }

.contact-ty--alt .contact-ty__left { padding: 0; }

.contact-ty--alt .contact-ty__banner .item__img img { margin: 24px auto; }

.contact-ty--alt .contact-ty__form { margin-bottom: 0; }

.contact-ty--alt .contact-ty__form .btn-group--custom .btn { height: calc(100% - 16px); text-align: left; padding: 16px 16px 16px 48px; position: relative; }

.contact-ty--alt .contact-ty__form .btn-group--custom .btn input[type="checkbox"] { position: absolute; visibility: visible; opacity: 1; z-index: 1; left: 20px; top: 25px; }

.contact-ty--alt .contact-ty__form .btn-group--custom .btn .icon { margin: 0 0 12px 0; }

.contact-ty--alt .contact-ty__form .btn-group--custom .btn span { color: #626B79; font-size: 16px; line-height: 24px; margin-bottom: 16px; display: block; }

.contact-ty--alt .contact-ty__form .btn-group--custom .btn span.more { color: #232933; margin-bottom: 0; }

.contact-ty--alt .contact-ty__form .btn-group--custom .btn span.more a { color: #4B61DD; text-decoration: underline; }

.contact-ty--alt .contact-ty__form .btn-group--custom .btn span.more a:hover { text-decoration: none; color: #172578; }

.advantage--ts .section-head__title { font-size: 40px; line-height: 48px; font-family: "Inter", sans-serif; font-weight: 600; }

@media (max-width: 991px) { .advantage--ts .section-head__title { font-size: 26px; line-height: 32px; } }

@media (max-width: 991px) { .advantage--ts { padding: 64px 16px 30px 16px; } }

.cta-simple--ts .block-cta-alt--simple .block-cta-alt__title { font-size: 26px; line-height: 32px; }

@media (max-width: 991px) { .cta-simple--ts .block-cta-alt--simple .block-cta-alt__title { font-size: 24px; line-height: 32px; } }

@media (max-width: 991px) { .cta-simple--ts .block-cta-alt--simple .container { padding-top: 0; } }

.pricing--ts { background: #143A72; color: #FFF; padding: 80px 0; }

@media (max-width: 991px) { .pricing--ts { padding: 64px 16px; } }

.pricing--ts .section-head { margin-bottom: 70px; }

@media (max-width: 991px) { .pricing--ts .section-head { padding-top: 0; } }

.pricing--ts .section-head__title { color: #FFF; font-size: 32px; line-height: 40px; margin-bottom: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

@media (max-width: 991px) { .pricing--ts .section-head__title { font-size: 26px; line-height: 32px; } }

.pricing--ts .pricing-cards .card { color: #545465; }

.pricing--ts .pricing-cards .card__wrap { background: #FFF; border-radius: 4px; height: 100%; }

.pricing--ts .pricing-cards .card__head { background: #F2F4F7; border-bottom: 1px solid #E2E2E2; padding: 24px; border-radius: 4px 4px  0 0; }

.pricing--ts .pricing-cards .card__head h3 { min-height: 65px; }

.pricing--ts .pricing-cards .card__head h3 div { position: relative; font-size: 18px; line-height: 30px; display: inline-block; }

.pricing--ts .pricing-cards .card__head h3 div span { display: block; font-size: 24px; line-height: 30px; }

@media (max-width: 375px) { .pricing--ts .pricing-cards .card__head h3 div span { font-size: 21px; } }

.pricing--ts .pricing-cards .card__head h3 div:nth-child(2) { padding-left: 50px; }

@media (max-width: 375px) { .pricing--ts .pricing-cards .card__head h3 div:nth-child(2) { padding-left: 35px; } }

.pricing--ts .pricing-cards .card__head h3 div:nth-child(2)::before { content: ''; width: 30px; height: 30px; background: url("../images/ic-round-plus.svg") no-repeat center; background-size: contain; left: 5px; top: calc(50% - 15px); display: inline-block; position: absolute; }

.pricing--ts .pricing-cards .card__head .price { margin-bottom: 20px; }

.pricing--ts .pricing-cards .card__head .price .streak { display: block; text-decoration-line: line-through; }

.pricing--ts .pricing-cards .card__head .price .pr { font-size: 32px; line-height: 40px; color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing--ts .pricing-cards .card__toggle { margin-bottom: 16px; transition: all linear .3s; position: relative; }

.pricing--ts .pricing-cards .card__toggle::after { content: ''; width: 25px; height: 25px; display: inline-block; position: absolute; top: 0; right: 0; background: url("../images/ic-chev-up.svg") no-repeat center; background-size: contain; transform: rotate(0deg); transition: all linear .3s; }

.pricing--ts .pricing-cards .card__toggle.collapsed { margin-bottom: 0; transition: all linear .3s; }

.pricing--ts .pricing-cards .card__toggle.collapsed::after { transform: rotate(180deg); transition: all linear .3s; }

.pricing--ts .pricing-cards .card__toggle span { color: #009BDE; cursor: pointer; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing--ts .pricing-cards .card__main { padding: 10px 24px; transition: all linear .3s; }

.pricing--ts .pricing-cards .card__main.has-collapse.open { padding: 10px 24px 24px 24px; transition: all linear .3s; }

.pricing--ts .pricing-cards .card__main .card__collapse-wrap .cat { color: #2B2A35; margin-bottom: 8px; display: block; font-family: "Inter", sans-serif; font-weight: 500; }

.pricing--ts .pricing-cards .card--highlight .card__wrap { border-radius: 0px 0px 4px 4px; position: relative; }

.pricing--ts .pricing-cards .card--highlight .card__wrap::before { content: 'JURNAL PREMIUM'; display: block; color: #FFF; background: #009BDE; padding: 14px 0; text-align: center; border-radius: 10px 10px 0 0; position: absolute; top: -48px; width: 100%; font-size: 16px; line-height: 20px; letter-spacing: 0.1em; font-family: "Inter", sans-serif; font-weight: 600; }

.pricing--ts .pricing-cards .card--highlight .card__head { border-radius: 0; }

.features-block--ts { padding: 80px 0; }

.features-block--ts:nth-child(even) .row { flex-direction: row-reverse; }

@media (max-width: 991px) { .features-block--ts { padding: 64px 16px; }
  .features-block--ts .row { flex-direction: column-reverse; }
  .features-block--ts .promo-benefit__img { display: block; } }

.features-block--ts .testimonial-small .item__box { background: #F2F4F7; color: #2B2A35; }

@media (max-width: 991px) { .features-block--ts .testimonial-small .testimonial-small__wrap { padding: 30px 0 0 0; } }

.features-block--ts .block-cta-alt--ts .container { padding-bottom: 0; }

@media (max-width: 991px) { .features-block--ts .block-cta-alt--ts .container { padding-left: 0; padding-right: 0; } }

.features-block--ts .block-cta-alt--ts .block-cta-alt__title.small { font-size: 24px; line-height: 32px; }

.features-block--ts.bg-gray .testimonial-small .item__box { background: #FFF; }

.masthead.masthead--alt .masthead__media .masthead__img { min-height: 500px; object-fit: contain; object-position: center; }

@media screen and (max-width: 1200px) { .masthead.masthead--alt .masthead__media .masthead__img { min-height: 0; } }

.cs-detail__head { padding-top: 50px; padding-bottom: 80px; }

.cs-detail__body { padding-bottom: 64px; }

.cs-detail__body .free-trial-block { margin-bottom: 32px; }

.cs-detail .sp-block { margin-bottom: 80px; }

.cs-detail .sp-block .img { margin-bottom: 16px; }

.cs-detail .sp-block .title { font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 12px; }

.cs-detail .sp-block__wrap { display: flex; align-items: center; padding: 16px 0; }

.cs-detail .sp-block__problem { display: block; width: 100%; max-width: 50%; flex: 0 0 50%; padding: 32px 48px; background: #F2F4F7; border-radius: 8px 0 0 8px; }

.cs-detail .sp-block__problem .title { color: #2B2A35; }

.cs-detail .sp-block__solution { display: block; position: relative; width: 100%; max-width: 50%; flex: 0 0 50%; padding: 32px 48px; color: #FFF; background: #2C9CA3; border-radius: 0 8px 8px 0; }

.cs-detail .sp-block__solution .img, .cs-detail .sp-block__solution .title, .cs-detail .sp-block__solution p { z-index: 2; position: relative; }

.cs-detail .sp-block__solution:before { content: ""; z-index: 1; display: block; position: absolute; width: 34px; height: calc(100% + 32px); top: -16px; left: -32px; background: #2C9CA3; border-radius: 6px 0 0 6px; clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, calc(100% - 2px) 50%, 0% 0%); }

.cs-detail .sp-block__solution:after { content: ""; z-index: 1; display: block; position: absolute; width: 100%; height: calc(100% + 32px); top: -16px; left: 0; background: #2C9CA3; border-radius: 0 8px 8px 0; }

@media (max-width: 375px) { .cs-detail .sp-block { margin-bottom: 48px; }
  .cs-detail .sp-block .sp-block__wrap { flex-direction: column; }
  .cs-detail .sp-block .sp-block__problem, .cs-detail .sp-block .sp-block__solution { max-width: 100%; flex: 0 0 100%; padding: 24px; }
  .cs-detail .sp-block .sp-block__problem { border-radius: 8px 8px 0 0; }
  .cs-detail .sp-block .sp-block__solution { border-radius: 0 0 8px 8px; }
  .cs-detail .sp-block .sp-block__solution:before, .cs-detail .sp-block .sp-block__solution:after { display: none; } }

.cs-detail .img-full { margin-bottom: 64px; }

.cs-detail .blockquote { max-width: 600px; margin: 64px auto; }

.cs-detail .blockquote blockquote { position: relative; font-style: normal; margin-bottom: 20px; }

.cs-detail .blockquote blockquote p { color: #2B2A35; font-size: 24px; line-height: 32px; font-family: "Inter", sans-serif; font-weight: 600; }

.cs-detail .blockquote blockquote:before { content: "\201C"; display: block; position: absolute; top: 0; left: -36px; font-size: 48px; line-height: 56px; color: #009BDE; font-family: "Inter", sans-serif; font-weight: 600; }

.cs-detail .blockquote blockquote:after { content: "\201D"; display: block; position: absolute; bottom: -25px; right: -25px; font-size: 48px; line-height: 56px; color: #009BDE; font-family: "Inter", sans-serif; font-weight: 600; }

.cs-detail .blockquote__author { display: flex; }

.cs-detail .blockquote__author .author__avatar { display: block; width: 40px; height: 40px; margin-right: 16px; }

.cs-detail .blockquote__author .author__text { width: 100%; max-width: calc(100% - 40px); }

.cs-detail .blockquote__author .author__text span { display: block; font-size: 14px; line-height: 20px; }

.cs-detail .blockquote__author .author__name { color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; }

@media (max-width: 375px) { .cs-detail .blockquote { padding-left: 15px; }
  .cs-detail .blockquote blockquote p { font-size: 20px; line-height: 28px; } }

.cs-detail .featured-block { padding: 40px 0 80px; }

.cs-detail .featured-block .item__img { margin-bottom: 8px; }

.cs-detail .featured-block .item__title { display: block; color: #2B2A35; margin-bottom: 12px; font-family: "Inter", sans-serif; font-weight: 500; }

@media (max-width: 375px) { .cs-detail .featured-block { padding-bottom: 48px; }
  .cs-detail .featured-block .item { margin-bottom: 32px; } }

.cs-detail .block-2-col { padding: 74px 0; }

.cs-detail .block-2-col .item:not(:last-child) { margin-bottom: 100px; }

.cs-detail .block-2-col .item:nth-child(even) { flex-direction: row-reverse; }

@media (max-width: 375px) { .cs-detail .block-2-col .item { flex-direction: column-reverse; }
  .cs-detail .block-2-col .item img { margin-bottom: 24px; }
  .cs-detail .block-2-col .item:nth-child(even) { flex-direction: column-reverse; } }

.cs-detail article { margin-bottom: 48px; }

.cs-detail article img { max-width: 100%; height: auto; }

.cs-detail article h2, .cs-detail article h3, .cs-detail article h4 { margin-bottom: 1rem; }

.cs-sidebar__wrap { max-width: 312px; margin-bottom: 48px; }

.cs-sidebar .item { margin-bottom: 32px; }

.cs-sidebar .item__title { font-size: 14px; line-height: 20px; color: #2B2A35; margin-bottom: 8px; font-family: "Inter", sans-serif; font-weight: 600; }

.cs-sidebar .item__desc { font-size: 14px; line-height: 20px; color: #2B2A35; }

.cs-sidebar .item__desc ul li { margin-bottom: 8px; }

.cs-sidebar .item__desc ul li a { color: #2B2A35; text-decoration: underline; opacity: 1; transition: all .4s ease; }

.cs-sidebar .item__desc ul li a:hover { opacity: .5; }

@media (max-width: 375px) { .cs-sidebar { margin-bottom: 64px; } }

.cs-more, .case-study { padding: 80px 0; background: #F2F4F7; }

.cs-more__filter, .case-study__filter { margin: 0 0 48px; }

.cs-more__filter .bootstrap-select > .dropdown-toggle, .case-study__filter .bootstrap-select > .dropdown-toggle { padding-top: 9px; padding-bottom: 9px; }

.cs-more__filter form, .case-study__filter form { display: flex; margin-left: -8px; margin-right: -8px; }

.cs-more__filter .form-group, .cs-more__filter .form-action, .case-study__filter .form-group, .case-study__filter .form-action { display: block; margin: 0; padding: 0 8px; width: 100%; flex-basis: 0; }

.cs-more__filter .form-group, .case-study__filter .form-group { flex-grow: 2; }

.cs-more__filter .form-action, .case-study__filter .form-action { flex-grow: 1.2; }

.cs-more__filter .form-action .btn, .case-study__filter .form-action .btn { display: block; width: 100%; padding-left: 15px; padding-right: 15px; }

@media (max-width: 375px) { .cs-more__filter, .case-study__filter { padding-left: 20%; padding-right: 20%; }
  .cs-more__filter form, .case-study__filter form { flex-direction: column; } }

.cs-more .item, .case-study .item { border-radius: 4px; margin-bottom: 48px; }

.cs-more .item__wrap, .case-study .item__wrap { display: flex; position: relative; flex-direction: column; height: 100%; }

.cs-more .item__img, .case-study .item__img { display: block; position: relative; width: 100%; padding-top: 56%; }

.cs-more .item__img img, .case-study .item__img img { display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 4px 4px 0 0; }

.cs-more .item__content, .case-study .item__content { padding: 20px 23px 24px; background: #fff; border: 1px solid #E2E2E2; border-radius: 0 0 4px 4px; }

.cs-more .item__title, .case-study .item__title { margin-bottom: 8px; font-size: 20px; line-height: 32px; }

.cs-more .item__title a, .case-study .item__title a { display: block; color: #2B2A35; text-decoration: none; }

.cs-more .item__title a:hover, .case-study .item__title a:hover { color: #334AC0; }

.cs-more .item__desc, .case-study .item__desc { margin-bottom: 16px; }

.cs-more .section-head { width: 100%; margin-bottom: 24px; display: flex; align-items: flex-end; }

.cs-more .section-head__title { font-size: 24px; line-height: 32px; margin-bottom: 0; }

.cs-more .section-head__more { margin-bottom: 0; margin-left: auto; padding-right: 32px; }

.cs-more .item { margin-bottom: 0; }

.big-number { display: flex; flex-wrap: wrap; justify-content: center; margin-left: -16px; margin-right: -16px; }

.big-number .item { width: 100%; max-width: 280px; flex: 0 0 280px; padding-right: 16px; padding-left: 16px; }

.big-number .item span.large { display: block; color: #2B2A35; font-size: 48px; line-height: 56px; font-family: "Inter", sans-serif; font-weight: 600; }

.big-number .item p { color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; }

@media (max-width: 375px) { .big-number .item { margin-bottom: 40px; } }

.free-trial-block { padding-top: 64px; }

.free-trial-block__wrap { display: flex; background: #F9FAFB; border-radius: 8px; box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); }

.free-trial-block__img { position: relative; width: 100%; max-width: 304px; flex: 0 0 304px; }

.free-trial-block__img img { display: block; width: 100%; height: auto; position: absolute; left: 0; top: 0; }

.free-trial-block__content { width: 100%; max-width: calc(100% - 304px); flex: 0 0 calc(100% - 304px); padding: 40px 40px 40px 0; }

@media (max-width: 768px) { .free-trial-block .free-trial-block__wrap { flex-direction: column; }
  .free-trial-block .free-trial-block__img { max-width: 100%; flex: 0 0 100%; }
  .free-trial-block .free-trial-block__img img { position: relative; max-width: 40%; margin: 0 auto; }
  .free-trial-block .free-trial-block__content { max-width: 100%; flex: 0 0 100%; padding: 24px; } }

.feature-box { padding: 60px 0 32px 0; }

.feature-box__title { font-size: 32px; line-height: 48px; text-align: center; margin-bottom: 24px; }

.feature-box .item { padding: 32px; height: 100%; margin-bottom: 40px; }

.feature-box .item__icon { margin-bottom: 8px; }

.feature-box .item__icon img { width: 48px; height: auto; }

.feature-box .item__title { font-size: 20px; line-height: 28px; margin-bottom: 8px; }

.feature-box .item:nth-last-child(-n+3) { margin-bottom: 0; }

.feature-box .item:hover { box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); border-radius: 4px; }

@media (max-width: 991px) { .feature-box { padding: 30px 0; }
  .feature-box__title { font-size: 1.5rem; line-height: 2rem; }
  .feature-box__title br { display: none; }
  .feature-box .item { box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); border-radius: 4px; height: auto; margin-bottom: 20px !important; } }

.masthead--hero .masthead__left { padding-right: 50px; }

.masthead--hero .masthead__title { font-size: 40px; line-height: 56px; margin-bottom: 16px; }

.masthead--hero .masthead__desc { margin-bottom: 24px; }

.masthead--hero .masthead__media { position: relative; }

@media (max-width: 991px) { .masthead--hero .masthead__wrap { padding: 32px 0; }
  .masthead--hero .masthead__content { padding-bottom: 0; }
  .masthead--hero .masthead__left { padding-right: 20px; padding-left: 20px; }
  .masthead--hero .masthead__title { font-size: 32px; line-height: 40px; }
  .masthead--hero .masthead__media.show-sm { margin-bottom: 18px; }
  .masthead--hero .masthead__action.show-sm { margin-bottom: 16px; } }

.our-customer--alt { padding: 64px 0; background: #F2F4F7; }

.our-customer--alt.white, .our-customer--alt.bg-white { background: #FFF; }

.our-customer--alt.white .logo-marquee, .our-customer--alt.bg-white .logo-marquee { padding-top: 64px; padding-bottom: 64px; }

.our-customer--alt.white .logo-marquee__title, .our-customer--alt.bg-white .logo-marquee__title { text-align: center; margin: 0 0 32px; }

.our-customer--alt.white .logo-marquee__desc, .our-customer--alt.bg-white .logo-marquee__desc { margin-bottom: 24px; }

.our-customer--alt.white .logo-marquee__desc p, .our-customer--alt.bg-white .logo-marquee__desc p { margin-bottom: 16px; }

.our-customer--alt.white .logo-marquee__desc p:last-child, .our-customer--alt.bg-white .logo-marquee__desc p:last-child { margin-bottom: 0; }

.our-customer--alt.white .logo-marquee__group, .our-customer--alt.bg-white .logo-marquee__group { display: flex; overflow: hidden; user-select: none; position: relative; }

.our-customer--alt.white .logo-marquee__group::before, .our-customer--alt.white .logo-marquee__group::after, .our-customer--alt.bg-white .logo-marquee__group::before, .our-customer--alt.bg-white .logo-marquee__group::after { content: ""; z-index: 2; display: block; width: 64px; height: 100%; position: absolute; top: 0; left: 0; background: linear-gradient(89.8deg, #FFF 49.02%, rgba(21, 62, 110, 0) 113.2%); }

.our-customer--alt.white .logo-marquee__group::after, .our-customer--alt.bg-white .logo-marquee__group::after { left: auto; right: 0; transform: matrix(-1, 0, 0, 1, 0, 0); }

.our-customer--alt.white .logo-marquee__group.reverse .logo-marquee__list, .our-customer--alt.bg-white .logo-marquee__group.reverse .logo-marquee__list { animation-direction: reverse; animation-delay: -3s; }

.our-customer--alt.white .logo-marquee__list, .our-customer--alt.bg-white .logo-marquee__list { display: flex; flex-shrink: 0; align-items: center; justify-content: space-around; min-width: 100%; margin-bottom: 24px; animation: scroll-x 60s linear infinite; }

.our-customer--alt.white .logo-marquee__list .item, .our-customer--alt.bg-white .logo-marquee__list .item { display: grid; place-items: center; margin: 0 24px; width: 148px; height: 56px; }

.our-customer--alt.white .logo-marquee__list .item img, .our-customer--alt.bg-white .logo-marquee__list .item img { display: block; max-width: 100%; max-height: 56px; width: auto; height: auto; }

.our-customer--alt.white .logo-marquee__action, .our-customer--alt.bg-white .logo-marquee__action { margin-top: 35px; }

@media (max-width: 991px) { .our-customer--alt.white .logo-marquee, .our-customer--alt.bg-white .logo-marquee { padding: 32px 0; }
  .our-customer--alt.white .logo-marquee__title br, .our-customer--alt.bg-white .logo-marquee__title br { display: none; }
  .our-customer--alt.white .logo-marquee__list, .our-customer--alt.bg-white .logo-marquee__list { animation-duration: 30s; } }

.our-customer--alt .our-customer__title { margin-bottom: 24px; max-width: 100%; }

.our-customer--alt .our-customer__action { margin-bottom: 0; }

@media (max-width: 991px) { .our-customer--alt { padding: 32px 30px; }
  .our-customer--alt .our-customer__title br { display: none; } }

.tab-feature { padding: 64px 0; }

.tab-feature__head { text-align: center; margin-bottom: 24px; }

.tab-feature__nav { display: flex; align-items: stretch; justify-content: space-evenly; padding: 12px 0; border-radius: 48px; margin-bottom: 27px; background: #EDF0F2; }

.tab-feature__nav .nav-item .nav-link { padding: 12px; margin: 0 6px; color: #626B79; border-radius: 48px; display: flex; font-size: 15px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-feature__nav .nav-item .nav-link:hover, .tab-feature__nav .nav-item .nav-link:focus, .tab-feature__nav .nav-item .nav-link:active, .tab-feature__nav .nav-item .nav-link.active { background: #0055A2; color: #fff; }

.tab-feature__title { font-size: 32px; line-height: 48px; margin-bottom: 16px; }

.tab-feature__desc { margin-bottom: 24px; }

.tab-feature__action { display: flex; align-items: center; }

.tab-feature__action a { margin-right: 14px; }

.tab-feature__action a:last-child { margin-right: 0; }

.tab-feature__toggle { display: none; }

.tab-feature__meta .tab-feature__title { font-size: 20px; line-height: 28px; }

.tab-feature__meta .tab-feature__img { display: none; }

@media (max-width: 991px) { .tab-feature { padding: 48px 8px; }
  .tab-feature__title { font-size: 28px; line-height: 36px; }
  .tab-feature__content .row { flex-direction: column-reverse; }
  .tab-feature__img { margin-bottom: 24px; display: none; }
  .tab-feature__nav { display: none; }
  .tab-feature__meta .tab-feature__title { font-size: 16px; line-height: 24px; display: none; }
  .tab-feature__toggle { display: block; position: relative; }
  .tab-feature__toggle::after { content: ''; width: 24px; height: 24px; position: absolute; right: 10px; top: 15px; background: url("../images/ic-caret-down.svg") no-repeat center; background-size: contain; transform: rotateZ(0deg); transition: all linear .3s; }
  .tab-feature__toggle .tab-feature__title { padding: 12px 40px 12px 0; font-size: 16px; line-height: 24px; margin-bottom: 0; color: #626B79; border-bottom: 1px solid #D0D6DD; font-family: "Inter", sans-serif; font-weight: 600; }
  .tab-feature__meta { margin-top: 12px; height: 0; opacity: 0; visibility: collapse; transition: all linear .3s; }
  .tab-feature .tab-pane { display: block; opacity: 1; visibility: visible; }
  .tab-feature .tab-pane.active.show .tab-feature__toggle::after { transform: rotateZ(180deg); transition: all linear .3s; }
  .tab-feature .tab-pane.active.show .tab-feature__toggle .tab-feature__title { color: #232933; border-bottom: 1px solid #4B61DD; }
  .tab-feature .tab-pane.active.show .tab-feature__meta { height: 100%; opacity: 1; visibility: visible; transition: all linear .3s; }
  .tab-feature .tab-pane.active.show .tab-feature__meta .tab-feature__img { display: block; margin: 16px auto; } }

.items-block--alt { padding: 32px 0; }

.items-block--alt .items-block__title { margin-bottom: 24px; text-align: center; }

.items-block--alt .items-block__wrap { margin-bottom: 24px; display: flex; justify-content: center; align-items: start; }

.items-block--alt .items-block__wrap .item { width: 236px; margin: 0 26px; }

.items-block--alt .items-block__wrap .item__icon { height: 236px; width: 236px; margin-bottom: 12px; }

.items-block--alt .items-block__wrap .item__icon img { width: auto; height: 100%; }

.items-block--alt .items-block__wrap .item__icon--sm { display: none; }

.items-block--alt .items-block__wrap .item__title { text-align: center; display: block; }

.items-block--alt .items-block__action { text-align: center; }

.items-block--alt .items-block__action a { margin-right: 14px; }

.items-block--alt .items-block__action a:last-child { margin-right: 0; }

@media (max-width: 991px) { .items-block--alt { padding: 48px 8px; }
  .items-block--alt .items-block__wrap { display: block; width: 100%; }
  .items-block--alt .items-block__wrap .item { display: block; width: 100%; margin: 0 0 25px 0; }
  .items-block--alt .items-block__wrap .item > .item__icon:not(.item__icon--sm) { display: none; }
  .items-block--alt .items-block__wrap .item .item__icon--sm { display: inline-block; vertical-align: top; width: 44px; height: auto; margin-right: 16px; }
  .items-block--alt .items-block__wrap .item .item__title { display: inline-block; vertical-align: top; text-align: left; width: calc(100% - 65px); } }

.tab-cs { padding: 64px 0; }

.tab-cs__toggle { display: none; }

.tab-cs__head { text-align: center; margin-bottom: 24px; }

.tab-cs__nav { display: flex; align-items: stretch; justify-content: space-evenly; padding: 12px 6px; background: #EDF0F2; border-radius: 48px; margin-bottom: 27px; }

.tab-cs__nav .nav-item .nav-link { padding: 12px 24px; margin: 0 6px; color: #626B79; border-radius: 48px; display: flex; font-size: 15px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-cs__nav .nav-item .nav-link:hover, .tab-cs__nav .nav-item .nav-link:focus, .tab-cs__nav .nav-item .nav-link:active, .tab-cs__nav .nav-item .nav-link.active { background: #0055A2; color: #fff; }

.tab-cs__title { font-size: 32px; line-height: 48px; margin-bottom: 16px; }

.tab-cs__desc { margin-bottom: 24px; }

.tab-cs__action { display: flex; align-items: center; }

.tab-cs__action a { margin-right: 14px; }

.tab-cs__action a:last-child { margin-right: 0; }

.tab-cs__content { border-radius: 4px; overflow: hidden; box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.75); }

.tab-cs__content .col-md-7 { padding-right: 0; }

.tab-cs__content .col-md-5 { padding-left: 0; }

.tab-cs__testimonial { background: #EDF0F2; padding: 44px 32px; height: 100%; }

.tab-cs__quote { margin: 0 0 16px 0; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 400; }

.tab-cs__info { display: flex; align-items: center; width: 100%; }

.tab-cs__info-left, .tab-cs__info-right { width: 50%; }

.tab-cs__info-right { text-align: right; }

.tab-cs__img { display: inline-block; width: 48px; height: 48px; margin-right: 16px; vertical-align: middle; overflow: hidden; }

.tab-cs__img img { height: 48px; width: auto; border-radius: 50%; object-fit: cover; }

.tab-cs__contact { display: inline-block; width: calc(100% - 69px); vertical-align: middle; }

.tab-cs__name { font-family: "Inter", sans-serif; font-weight: 600; }

.tab-cs__logo { display: inline-block; vertical-align: middle; }

.tab-cs__logo img { height: 48px; width: auto; }

.tab-cs__content { display: inline-flex; }

.tab-cs__stats { background: #0055A2; height: 100%; color: #FFF; padding: 32px 24px; }

.tab-cs__items { display: flex; align-items: stretch; }

.tab-cs__items .item { margin-right: 16px; }

.tab-cs__items .item__label { font-size: 20px; line-height: 28px; margin-bottom: 26px; display: block; font-family: "Inter", sans-serif; font-weight: 500; }

.tab-cs__items .item__number { font-size: 52px; line-height: 26px; margin-bottom: 26px; display: block; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-cs__items .item__desc { font-size: 20px; line-height: 28px; margin-bottom: 26px; display: block; font-family: "Inter", sans-serif; font-weight: 500; }

@media (max-width: 991px) { .tab-cs { padding: 48px 8px; }
  .tab-cs .tab-pane { display: block; opacity: 1; }
  .tab-cs .tab-pane.active.show .tab-cs__content { visibility: visible; height: auto; opacity: 1; display: block; transition: all linear .3s; }
  .tab-cs .tab-pane.active.show .tab-cs__toggle::after { transform: rotateZ(180deg); transition: all linear .3s; }
  .tab-cs .tab-pane.active.show .tab-cs__title { border-bottom: 1px solid #4B61DD; color: #2B2A35; }
  .tab-cs__toggle { display: block; position: relative; }
  .tab-cs__toggle::after { content: ''; width: 24px; height: 24px; position: absolute; right: 10px; top: 15px; background: url("../images/ic-caret-down.svg") no-repeat center; background-size: contain; transform: rotateZ(0deg); transition: all linear .3s; }
  .tab-cs__toggle .tab-cs__title { font-size: 16px; line-height: 24px; border-bottom: 1px solid #D0D6DD; padding: 12px 40px 12px 0; font-family: "Inter", sans-serif; font-weight: 600; }
  .tab-cs__title { font-size: 28px; line-height: 36px; color: #626B79; }
  .tab-cs__nav { display: none; overflow-x: auto; overflow-y: hidden; }
  .tab-cs__content { visibility: collapse; display: none; height: 0; opacity: 0; transition: all linear .3s; margin-bottom: 18px; }
  .tab-cs__content .col-md-7 { padding-right: 12px; }
  .tab-cs__content .col-md-5 { padding-left: 12px; }
  .tab-cs__quote { font-size: 18px; line-height: 24px; margin-bottom: 24px; }
  .tab-cs__quote blockquote { margin: 0 0 18px 0; }
  .tab-cs__info { display: block; }
  .tab-cs__info-left, .tab-cs__info-right { width: 100%; text-align: left; }
  .tab-cs__info-left { margin-bottom: 16px; }
  .tab-cs__stats, .tab-cs__items { display: block; }
  .tab-cs__stats .item, .tab-cs__items .item { margin-right: 0; margin-bottom: 32px; }
  .tab-cs__stats .item:last-child, .tab-cs__items .item:last-child { margin-bottom: 0; } }

.items-accr__wrap { padding-top: 32px; padding-bottom: 64px; }

.items-accr__wrap .row { align-items: center; }

.items-accr__img { transition: all .2s ease-in-out; }

.items-accr__img img { display: block; width: 100%; height: auto; }

.items-accr__img.img-out { transform: scale(1.1); opacity: 0; }

.items-accr__img.img-in { transform: scale(1); opacity: 1; }

.items-accr .accordion { margin-bottom: 0; }

.items-accr .accordion .card { position: relative; background: #FFF; border-radius: 4px; }

.items-accr .accordion .card__body { position: relative; box-shadow: none; background: transparent; padding-top: 0; margin-top: 0; }

.items-accr .accordion .card.open { box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75); margin-top: 12px; border: 1px solid #0055A2; }

.items-accr .accordion .card.open .card__header { border-color: transparent; padding-bottom: 12px; }

@media (max-width: 991px) { .items-accr { padding-left: 8px; padding-right: 8px; }
  .items-accr__wrap { padding: 48px 8px; }
  .items-accr__wrap .row { flex-direction: column-reverse; }
  .items-accr__wrap .row .accordion { margin-top: 18px; } }

.logo-marquee { padding-top: 64px; padding-bottom: 64px; }

.logo-marquee__title { text-align: center; margin: 0 0 32px; }

.logo-marquee__desc { margin-bottom: 24px; }

.logo-marquee__desc p { margin-bottom: 16px; }

.logo-marquee__desc p:last-child { margin-bottom: 0; }

.logo-marquee__group { display: flex; overflow: hidden; user-select: none; position: relative; }

.logo-marquee__group::before, .logo-marquee__group::after { content: ""; z-index: 2; display: block; width: 64px; height: 100%; position: absolute; top: 0; left: 0; background: linear-gradient(89.8deg, #F2F4F7 49.02%, rgba(255, 255, 255, 0) 113.2%); }

.logo-marquee__group::after { left: auto; right: 0; transform: matrix(-1, 0, 0, 1, 0, 0); }

.logo-marquee__group.reverse .logo-marquee__list { animation-direction: reverse; animation-delay: -3s; }

.logo-marquee__list { display: flex; flex-shrink: 0; align-items: center; justify-content: space-around; min-width: 100%; margin-bottom: 24px; animation: scroll-x 60s linear infinite; }

.logo-marquee__list .item { display: grid; place-items: center; margin: 0 24px; width: 148px; height: 56px; }

.logo-marquee__list .item img { display: block; max-width: 100%; max-height: 56px; width: auto; height: auto; }

.logo-marquee__action { margin-top: 35px; }

@media (max-width: 991px) { .logo-marquee { padding: 32px 0; }
  .logo-marquee__title br { display: none; }
  .logo-marquee__list { animation-duration: 30s; } }

.tab-sticky { background: #EAECFB; padding-bottom: 12px; }

.tab-sticky .section-head { padding: 64px 0 24px 0; }

.tab-sticky .section-head__title { text-align: center; }

.tab-sticky .nav-tabs { display: flex; justify-content: center; align-items: stretch; }

.tab-sticky .nav-tabs .nav-item { margin: 0 12px; }

.tab-sticky .nav-tabs .nav-item .nav-link { color: #626B79; font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; padding: 24px 24px 20px 24px; border-bottom: 4px solid transparent; display: inline-flex; justify-content: center; align-items: center; }

.tab-sticky .nav-tabs .nav-item .nav-link .nav-icon { margin-right: 16px; }

.tab-sticky .nav-tabs .nav-item .nav-link .nav-icon img { width: 52px; height: auto; filter: grayscale(0.8); }

.tab-sticky .nav-tabs .nav-item .nav-link.active, .tab-sticky .nav-tabs .nav-item .nav-link:hover { color: #232933; border-bottom: 4px solid #4B61DD; }

.tab-sticky .nav-tabs .nav-item .nav-link.active .nav-icon img, .tab-sticky .nav-tabs .nav-item .nav-link:hover .nav-icon img { filter: grayscale(0); }

.tab-sticky .nav-tabs.stick { top: 55px !important; background: #FFF; z-index: 5; border-bottom: 1px solid #E2E2E2; transition: top linear .3s; }

.tab-sticky .nav-tabs.stick .nav-item .nav-link { padding: 4px 24px; }

.tab-sticky .nav-tabs.stick.show-top { top: 95px !important; transition: top linear .3s; }

.tab-sticky .tab-content { background: #FFF; }

.tab-sticky .tab-content .faq-block { background: #FFF; }

.tab-sticky .tab-content .faq-block .section-head { padding: 0 0 24px 0; }

.tab-sticky .tab-content .faq-block .section-head__title { text-align: left; }

.tab-sticky .tab-content .calculator-block .section-head { padding: 0; }

.tab-sticky .tab-content .toggle-sm { display: none; }

.tab-sticky .tab-content .promo-benefit__title { font-size: 32px; line-height: 48px; margin-bottom: 8px; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-sticky .tab-content .promo-benefit__desc p { margin-bottom: 16px; }

@media (max-width: 991px) { .tab-sticky { padding-bottom: 0; }
  .tab-sticky__wrap { padding: 0; }
  .tab-sticky .section-head__title br { display: none; }
  .tab-sticky .nav-tabs { display: none; }
  .tab-sticky .tab-content .tab-pane { display: block; }
  .tab-sticky .tab-content .tab-pane.active .toggle-sm { border-bottom: 2px solid #4B61DD; }
  .tab-sticky .tab-content .tab-pane.active .toggle-sm::after { transform: rotate(180deg); transition: transform linear .3s; }
  .tab-sticky .tab-content .tab-pane.active .toggle-sm span { color: #232933; font-family: "Inter", sans-serif; font-weight: 600; }
  .tab-sticky .tab-content .tab-pane.active .toggle-sm__icon img { filter: grayscale(0); }
  .tab-sticky .tab-content .tab-pane.active .toggle-sm__content { display: block; }
  .tab-sticky .tab-content .toggle-sm { display: flex; width: 100%; align-items: center; justify-content: start; padding: 12px; border-bottom: 1px solid #626B79; position: relative; background: #EAECFB; }
  .tab-sticky .tab-content .toggle-sm::after { content: ''; position: absolute; width: 24px; height: 24px; background: url(../images/ic-caret-down.svg) no-repeat center; background-size: contain; top: 20px; right: 7px; transform: rotate(0deg); transition: transform linear .3s; }
  .tab-sticky .tab-content .toggle-sm__icon { margin-right: 12px; }
  .tab-sticky .tab-content .toggle-sm__icon img { height: 36px; width: auto; filter: grayscale(1); }
  .tab-sticky .tab-content .toggle-sm span { font-size: 16px; line-height: 24px; color: #626B79; }
  .tab-sticky .tab-content .toggle-sm__content { display: none; }
  .tab-sticky .tab-content .promo-benefit__title { font-size: 28px; line-height: 36px; } }

.calculator-block { padding: 64px 0; }

.calculator-block__calc .form { padding: 32px; border-radius: 8px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); }

.calculator-block__calc .form .form-result { padding: 40px 32px; background: #F2F4F7; text-align: center; }

.calculator-block__calc .form .form-result__fontest { border-bottom: 1px solid #D0D6DD; margin-bottom: 12px; }

.calculator-block__calc .form .form-result__fontest .form-result__row { display: flex; justify-content: space-between; }

.calculator-block__calc .form .form-result__fontest .form-result__row .label, .calculator-block__calc .form .form-result__fontest .form-result__row .fontest { width: 50%; font-size: 14px; line-height: 20px; margin-bottom: 12px; }

.calculator-block__calc .form .form-result__fontest .form-result__row small { font-size: 12px; line-height: 16px; margin-bottom: 12px; display: block; }

.calculator-block__calc .form .form-result__fontest .form-result__row .label { text-align: left; }

.calculator-block__calc .form .form-result__fontest .form-result__row .fontest { text-align: right; }

.calculator-block__calc .form .form-result__instalment .form-result__row { display: flex; justify-content: space-between; }

.calculator-block__calc .form .form-result__instalment .form-result__row .label, .calculator-block__calc .form .form-result__instalment .form-result__row .instalment { width: 50%; font-size: 16px; line-height: 24px; margin-bottom: 12px; font-family: "Inter", sans-serif; font-weight: 600; }

.calculator-block__calc .form .form-result__instalment .form-result__row .label small, .calculator-block__calc .form .form-result__instalment .form-result__row .instalment small { font-size: 12px; line-height: 16px; margin-bottom: 12px; display: block; }

.calculator-block__calc .form .form-result__instalment .form-result__row .label, .calculator-block__calc .form .form-result__instalment .form-result__row p { text-align: left; }

.calculator-block__calc .form .form-result__instalment .form-result__row .instalment { text-align: right; }

@media (max-width: 991px) { .calculator-block__calc .form .form-result__instalment { margin-top: 16px; }
  .calculator-block__calc .form .form-result__instalment .form-result__row { display: block; }
  .calculator-block__calc .form .form-result__instalment .form-result__row .label, .calculator-block__calc .form .form-result__instalment .form-result__row .instalment { width: 100%; display: block; text-align: left; } }

.calculator-block__calc .form .form-result__interest .form-result__row { display: flex; justify-content: space-between; }

.calculator-block__calc .form .form-result__warning { margin-top: 12px; display: block; background: #E0EEFF; border: 1px solid #1C44D5; border-radius: 4px; padding: 12px 12px 12px 48px; text-align: left; position: relative; }

.calculator-block__calc .form .form-result__warning::before { content: ''; width: 20px; height: 20px; position: absolute; top: 15px; left: 15px; background: url(../images/ic-info-blue.svg) no-repeat center; background-size: contain; }

.calculator-block__calc .form .form-result__warning b { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 8px; display: block; }

.calculator-block__calc .form .form-result__warning ul { list-style: disc; padding-left: 16px; }

.calculator-block__calc .form .form-result__year { text-align: left; }

.calculator-block__calc .form .form-result #fr-placeholder { display: block; transition: all ease-out .3s; position: relative; }

.calculator-block__calc .form .form-result #fr-result { opacity: 0; visibility: hidden; display: none; transition: all ease-out .3s; }

.calculator-block__calc .form .form-result.show { background: #FFF; padding: 12px 0; }

.calculator-block__calc .form .form-result.show::after { display: none; }

.calculator-block__calc .form .form-result.show #fr-placeholder { opacity: 0; visibility: hidden; display: none; transition: all ease-out .3s; }

.calculator-block__calc .form .form-result.show #fr-result { opacity: 1; visibility: visible; display: block; transition: all ease-out .3s; }

.calculator-block .section-head { margin-bottom: 24px; }

@media (max-width: 991px) { .calculator-block { padding: 32px 0; }
  .calculator-block .section-head__title br { display: none; }
  .calculator-block .section-head__desc { text-align: left; }
  .calculator-block .section-head__calc .form { padding: 24px; } }

.steps-block { padding: 64px 0; }

.steps-block__img { margin: 0 auto; }

.steps-block .steps { margin-bottom: 24px; }

.steps-block .steps ul { padding-left: 2.25rem; position: relative; }

.steps-block .steps ul li { position: relative; color: #626B79; font-size: 16px; line-height: 24px; }

.steps-block .steps ul li b { display: block; margin-bottom: 8px; }

.steps-block .steps ul li:hover { cursor: pofont; }

.steps-block .steps ul li:before { content: ""; width: 24px; height: 24px; border-radius: 50%; background: #D0D6DD; display: block; position: absolute; top: 0; left: -2.25rem; z-index: 1; }

.steps-block .steps ul li:not(:last-child) { padding-bottom: 1rem; }

.steps-block .steps ul li span.line { width: 2px; height: 100%; background: #D0D6DD; position: absolute; top: 0; left: -25px; }

.steps-block .steps ul li.active { color: #232933; }

.steps-block .steps ul li.active:before { background: #fff; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }

.steps-block .steps ul li.active:after { content: ""; width: 12px; height: 12px; border-radius: 50%; background: #009BDE; display: block; position: absolute; top: 6px; left: -30px; z-index: 2; }

.steps-block__steps .section-head__title { margin-bottom: 16px; text-align: left; }

.steps-block__action { margin-top: 32px; }

@media (max-width: 991px) { .steps-block { padding: 32px 0; }
  .steps-block__img { margin: 0 auto; }
  .steps-block .steps ul { padding-left: 2.25rem; position: relative; }
  .steps-block .steps ul li { position: relative; color: #626B79; font-size: 16px; line-height: 22px; }
  .steps-block .steps ul li b { display: block; margin-bottom: 8px; }
  .steps-block .steps ul li:hover { cursor: pofont; }
  .steps-block .steps ul li:before { content: ""; width: 24px; height: 24px; border-radius: 50%; background: #D0D6DD; display: block; position: absolute; top: 0; left: -2.25rem; z-index: 1; }
  .steps-block .steps ul li:not(:last-child) { padding-bottom: 1rem; }
  .steps-block .steps ul li span.line { width: 2px; height: 100%; background: #D0D6DD; position: absolute; top: 0; left: -25px; }
  .steps-block .steps ul li.active { color: #232933; }
  .steps-block .steps ul li.active:before { background: #fff; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }
  .steps-block .steps ul li.active:after { content: ""; width: 12px; height: 12px; border-radius: 50%; background: #009BDE; display: block; position: absolute; top: 6px; left: -30px; z-index: 2; }
  .steps-block__steps .section-head__title { font-size: 28px; line-height: 36px; margin-bottom: 16px; }
  .steps-block__action { margin-top: 32px; }
  .steps-block__action .btn { display: block; } }

.in-app .tab-sticky .nav-tabs.stick { top: 0 !important; }

.in-app .tab-sticky .nav-tabs.stick.show-top { top: 0 !important; }

.steps--list { padding: 64px 0; }

.steps--list ul.steps-list, .steps--list ol.steps-list { padding-left: 0; position: relative; }

.steps--list ul.steps-list li, .steps--list ol.steps-list li { padding-left: 60px; margin-bottom: 16px; position: relative; list-style: none; counter-increment: li; min-height: 48px; display: flex; align-items: center; }

.steps--list ul.steps-list li::before, .steps--list ol.steps-list li::before { content: ''; width: 1px; height: calc(100% + 16px); background: #D0D6DD; top: 16px; left: 24px; display: inline-block; position: absolute; }

.steps--list ul.steps-list li::after, .steps--list ol.steps-list li::after { display: inline-flex; justify-content: center; align-items: center; width: 48px; height: 48px; background: linear-gradient(340deg, #0087D9 9.7%, #00A8FD 47.12%, #66CFFF 84.53%); border-radius: 29px; border: 4px solid #EAECFB; left: 0; top: 0; position: absolute; content: counter(li); font-family: "Inter", sans-serif; font-weight: 600; color: #FFF; }

.steps--list ul.steps-list li:last-child, .steps--list ol.steps-list li:last-child { margin-bottom: 0; }

.steps--list ul.steps-list li:last-child::before, .steps--list ol.steps-list li:last-child::before { display: none; }

.steps--list ul.steps-list li b, .steps--list ol.steps-list li b { display: block; margin-bottom: 12px; }

.steps--list ul.steps-list li a, .steps--list ol.steps-list li a { color: #4B61DD; text-decoration: underline; }

.steps--list ul.steps-list li a:hover, .steps--list ol.steps-list li a:hover { color: #1f33a3; text-decoration: none; }

.steps--list .steps__desc { margin-bottom: 24px; }

@media (max-width: 991px) { .steps--list { padding: 32px 0; }
  .steps--list .steps__title { font-size: 28px; line-height: 36px; margin-bottom: 20px; }
  .steps--list .steps__img { display: block; padding-left: 0; margin-bottom: 16px; } }

.clients-block { padding: 64px 0; }

.clients-block__title { margin-bottom: 24px; text-align: center; }

.clients-block__list { display: flex; flex-wrap: wrap; justify-content: center; max-width: 845px; margin: 0 auto; }

.clients-block__list li { display: inline-flex; height: 56px; width: auto; margin-bottom: 18px; padding: 0 28px; align-items: center; }

.clients-block__list li img { object-fit: contain; width: auto; }

.clients-block__list li.br { display: block; padding: 0; margin: 0; width: 100%; height: 0; }

@media (max-width: 991px) { .clients-block { padding: 32px 8px; }
  .clients-block__title br { display: none; }
  .clients-block__list { justify-content: center; }
  .clients-block__list li { padding: 0 8px; height: 55px; }
  .clients-block__list li.br { display: none; } }

.product-featured--alt { background: #F2F4F7; }

.product-featured--alt .nav-container .nav-tabs .nav-item .nav-link { background: #F2F4F7; }

.product-featured--alt .nav-container .nav-tabs .nav-item .nav-link::after { background-color: #FFF; }

.product-featured--alt .tab-content { background: #FFF; }

@media (max-width: 991px) { .product-featured--alt { padding: 32px 12px; }
  .product-featured--alt .nav-container { position: absolute; background: #FFF; border: 1px solid #E2E2E2; width: calc(100% - 24px); }
  .product-featured--alt .nav-container .nav-tabs .nav-item { display: none; padding-left: 0; padding-right: 0; border: 1px solid #E2E2E2; }
  .product-featured--alt .nav-container .nav-tabs .nav-item a.nav-link { background: #FFF; }
  .product-featured--alt .nav-container .nav-tabs .nav-item.active { display: block; border: none; }
  .product-featured--alt .nav-container .nav-tabs.show .nav-item { display: block; }
  .product-featured--alt .tab-content { margin-top: 75px; }
  .product-featured--alt .tab-content .tab-pane { padding: 12px 0 12px; }
  .product-featured--alt .tab-content .tab-pane .feature__wrap .row { margin-bottom: 32px; }
  .product-featured--alt .tab-content .tab-pane .feature__wrap .row:last-child { margin-bottom: 0; } }

.feature__wrap .row { margin-bottom: 47px; }

.feature__wrap .row:nth-child(even) { flex-direction: row-reverse; }

.feature__wrap .feature__content { text-align: left; }

.feature__wrap .feature__title { font-size: 20px; line-height: 28px; text-align: left; margin-bottom: 8px !important; }

.feature__wrap .feature__desc { margin-bottom: 24px; }

.tab-cs--single { padding: 64px 0; }

@media (max-width: 991px) { .tab-cs--single { padding: 32px 8px; }
  .tab-cs--single .tab-cs__content { visibility: visible; display: flex; opacity: 1; height: auto; }
  .tab-cs--single .tab-cs__content .tab-cs__testimonial { padding: 20px; }
  .tab-cs--single .tab-cs__content .tab-cs__stats { padding: 20px; } }

.form-side { padding: 64px 0; background: #F2F4F7; }

@media (max-width: 991px) { .form-side { padding: 32px 8px; }
  .form-side__head { margin-bottom: 26px; }
  .form-side__img { margin-top: 16px; display: none; } }

.compare-cards__title { text-align: center; }

@media (max-width: 991px) { .compare-cards__title { font-size: 20px; line-height: 28px; margin-bottom: 24px; }
  .compare-cards__title br { display: none; } }

.compare-cards__features { margin-bottom: 32px; }

.compare-cards__features .item__img { margin-bottom: 8px; }

.compare-cards__features .item__img img { display: block; width: 48px; height: 48px; object-fit: contain; }

.compare-cards__features .item__title { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 500; color: #232933; margin-bottom: 8px; }

.compare-cards__features .item__wrap { padding: 32px; }

@media (max-width: 991px) { .compare-cards__features { margin-bottom: 0; }
  .compare-cards__features .item { padding-left: 32px; padding-right: 32px; padding-bottom: 24px; }
  .compare-cards__features .item__img { position: absolute; top: 0; left: 0; }
  .compare-cards__features .item__img img { display: block; width: 24px; height: 24px; }
  .compare-cards__features .item__title { font-size: 16px; line-height: 24px; }
  .compare-cards__features .item__wrap { position: relative; padding: 0 0 0 36px; } }

.compare-cards__compare { justify-content: center; padding: 64px 8px; }

.compare-cards__compare .compare-nav { display: none; }

.compare-cards__compare .item__head { padding: 24px; background: #EDF0F2; text-align: center; border-bottom: 1px solid #8B95A5; }

.compare-cards__compare .item__head .sub { display: block; color: #232933; }

.compare-cards__compare .item__head .title { font-size: 20px; line-height: 28px; color: #232933; font-weight: 600; }

.compare-cards__compare .item__body { padding: 24px; }

.compare-cards__compare .item__body .title { font-size: 16px; line-height: 24px; color: #232933; font-weight: 600; margin-bottom: 24px; }

.compare-cards__compare .item__body .custom-list b { display: block; color: #232933; }

.compare-cards__compare .item__body .custom-list li { margin-bottom: 32px; }

.compare-cards__compare .item__body .custom-list li:last-child { margin-bottom: 0; }

.compare-cards__compare .item__wrap { border: 1px solid #8B95A5; border-radius: 12px; overflow: hidden; position: relative; display: flex; flex-direction: column; }

.compare-cards__compare .item--highlight .item__head { background: #0055A2; border-bottom-color: #0055A2; }

.compare-cards__compare .item--highlight .item__head .sub { color: #FFF; }

.compare-cards__compare .item--highlight .item__head .title { color: #FFF; }

.compare-cards__compare .item--highlight .item__wrap { border-color: #0055A2; box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12); }

@media (max-width: 991px) { .compare-cards__compare { padding-top: 48px; padding-bottom: 48px; }
  .compare-cards__compare .compare-nav { display: block; position: relative; z-index: 2; }
  .compare-cards__compare .compare-nav__wrap { display: flex; position: relative; }
  .compare-cards__compare .compare-nav .nav-link { display: block; position: relative; width: 100%; text-align: center; border-radius: 12px 12px 0 0; background: #EDF0F2; padding: 12px 6px; border: 1px solid transparent; }
  .compare-cards__compare .compare-nav .nav-link.nav-link--highlight.active { border-color: #0055A2; }
  .compare-cards__compare .compare-nav .nav-link.nav-link--highlight.active .title { color: #0055A2; }
  .compare-cards__compare .compare-nav .nav-link.active { border-color: #8B95A5; background: #FFF; }
  .compare-cards__compare .compare-nav .nav-link.active .title { font-size: 20px; line-height: 28px; }
  .compare-cards__compare .compare-nav .nav-link.active::after { content: ""; display: block; width: 100%; height: 2px; position: absolute; top: 100%; left: 0; background: #FFF; }
  .compare-cards__compare .compare-nav .nav-link .sub { font-size: 14px; line-height: 23px; }
  .compare-cards__compare .compare-nav .nav-link .title { font-size: 16px; line-height: 24px; font-weight: 600; }
  .compare-cards__compare .item { display: none; }
  .compare-cards__compare .item.active { display: block; }
  .compare-cards__compare .item__head { display: none; }
  .compare-cards__compare .item__wrap { border-radius: 0 0 12px 12px; }
  .compare-cards__compare .item--highlight .item__wrap { top: auto; } }

.compare-cards__nav { max-width: 880px; margin: 0 auto 32px; position: relative; }

.compare-cards__nav::after { content: ""; display: block; width: calc(100% + 24px); height: 1px; background: #CFCDC8; position: absolute; bottom: 0; left: -12px; }

.compare-cards__nav .nav-sm { display: none; }

.compare-cards__nav .nav { display: flex; justify-content: center; }

.compare-cards__nav .nav-item { position: relative; width: 100%; padding-left: 12px; padding-right: 12px; }

.compare-cards__nav .nav-link { display: flex; align-items: center; justify-content: center; padding: 16px; height: 80px; border-bottom: 2px solid transparent; }

.compare-cards__nav .nav-link img { display: block; max-height: 40px; max-width: 100%; height: auto; width: auto; filter: brightness(0); opacity: .5; }

.compare-cards__nav .nav-link.active { border-bottom-color: #0055A2; }

.compare-cards__nav .nav-link.active img { filter: none; opacity: 1; }

.compare-cards__nav .owl-nav { position: absolute; width: 100%; height: 0; left: 0; top: 50%; }

.compare-cards__nav .owl-nav .owl-prev, .compare-cards__nav .owl-nav .owl-next { position: absolute; left: -56px; top: calc(100% - 28px); outline: none !important; }

.compare-cards__nav .owl-nav .owl-prev span, .compare-cards__nav .owl-nav .owl-next span { display: flex; align-items: center; justify-content: center; width: 56px; height: 56px; background-image: url(../images/ic-page-left.svg); background-size: auto 32px; background-size: contain; background-position: center; background-repeat: no-repeat; opacity: 0.4; transition: all .4s ease-in-out; }

.compare-cards__nav .owl-nav .owl-prev.disabled, .compare-cards__nav .owl-nav .owl-next.disabled { opacity: 0; }

.compare-cards__nav .owl-nav .owl-prev:hover span, .compare-cards__nav .owl-nav .owl-next:hover span { opacity: 1; }

.compare-cards__nav .owl-nav .owl-next { left: auto; right: -56px; }

.compare-cards__nav .owl-nav .owl-next span { background-image: url(../images/ic-page-right.svg); }

@media (max-width: 991px) { .compare-cards__nav::after { display: none; }
  .compare-cards__nav .nav-tabs { display: none; }
  .compare-cards__nav .nav-sm { display: block; position: relative; z-index: 10; }
  .compare-cards__nav .nav-sm .nav { display: none; position: absolute; top: 60px; left: 0; width: 100%; background: #FFF; border: 1px solid #E2E2E2; border-radius: 4px; padding: 6px 0; }
  .compare-cards__nav .nav-sm__selected { position: relative; display: block; font-size: 20px; line-height: 28px; font-weight: 600; color: #232933; display: block; height: auto; min-height: 52px; padding: 12px 32px 12px 0; border-bottom: 1px solid #0055A2; }
  .compare-cards__nav .nav-sm__selected::after { content: ""; display: block; width: 24px; height: 24px; position: absolute; top: 12px; right: 0; background: url(../images/ic-dropdown.svg) no-repeat center; background-size: contain; }
  .compare-cards__nav .nav-item { max-width: 100%; flex: 0 0 100%; }
  .compare-cards__nav .nav-link { font-size: 16px; line-height: 24px; font-weight: 500; color: #232933; display: block; height: auto; min-height: 40px; padding: 6px 12px; border: 0; }
  .compare-cards__nav .nav-link:hover { text-decoration: none; } }

.compare-cards__wrap { padding-top: 32px; padding-bottom: 32px; }

.three-blocks { padding: 64px 8px; }

.three-blocks__title { text-align: center; margin-bottom: 8px; }

.three-blocks .item { padding: 32px; position: relative; }

.three-blocks .item__icon { height: 32px; width: 32px; margin-bottom: 8px; }

.three-blocks .item__icon img { object-fit: contain; width: 100%; height: auto; }

.three-blocks .item__title { font-family: "Inter", sans-serif; font-weight: 500; font-size: 20px; line-height: 28px; margin-bottom: 8px; }

@media (max-width: 991px) { .three-blocks { padding: 32px 8px; }
  .three-blocks__title br { display: none; }
  .three-blocks .item { padding: 12px 12px 12px 33px; }
  .three-blocks .item__icon { position: absolute; left: 0; width: 24px; height: 24px; } }

.video-block { padding: 64px 20px; }

.video-block .mpay-video { padding: 0; }

.video-block .mpay-video__title { font-size: 32px; line-height: 48px; margin-bottom: 24px; }

.video-block .mpay-video__buttons { margin-top: 24px; text-align: center; }

.video-block .mpay-video__buttons a, .video-block .mpay-video__buttons .btn { margin-right: 14px; }

.video-block .mpay-video__buttons a:last-child, .video-block .mpay-video__buttons .btn:last-child { margin-right: 0; }

@media (max-width: 991px) { .video-block { padding: 32px 20px; }
  .video-block .mpay-video__title { font-size: 28px; line-height: 36px; }
  .video-block .mpay-video__buttons a, .video-block .mpay-video__buttons .btn { width: 100%; margin-right: 0; margin-bottom: 14px; }
  .video-block .mpay-video__buttons a:last-child, .video-block .mpay-video__buttons .btn:last-child { margin-bottom: 0; } }

.pricing-block { padding: 64px 0; }

.pricing-block__title { margin-bottom: 24px; text-align: center; }

.pricing-block .pricing-table { padding-bottom: 0; }

.pricing-block .pricing-table--alt .pricing-table__td, .pricing-block .pricing-table--alt .pricing-table__th { min-width: 25%; }

.pricing-block .pricing-table--alt .pricing-table__tr .pricing-table__th .head--alt { text-align: center; }

.pricing-block .pricing-table--alt .pricing-table__tr .pricing-table__th .head--alt .head__title { color: #0055A2; margin-bottom: 24px; }

.pricing-block .pricing-table--alt .pricing-table__tr .pricing-table__th .head--alt .head__price .pr { font-size: 24px; line-height: 32px; }

.pricing-block .pricing-table--alt .pricing-table__tr .pricing-table__th .head--alt .head__price .pr small { padding-left: 6px; font-size: 14px; line-height: 20px; vertical-align: middle; }

.pricing-block .pricing-card .head--alt { text-align: center; }

.pricing-block .pricing-card .head--alt .head__title { color: #0055A2; margin-bottom: 24px; }

.pricing-block .pricing-card .head--alt .head__price .pr { font-size: 24px; line-height: 32px; }

.pricing-block .pricing-card .head--alt .head__price .pr small { padding-left: 6px; font-size: 14px; line-height: 20px; vertical-align: middle; }

.pricing-block .pricing-card .pricing-card__slider .owl-stage-outer { padding-bottom: 16px; }

.pricing-block .pricing-card .pricing-card__nav .owl-dots__wrap { width: 100%; }

.pricing-block .pricing-card .pricing-card__nav .owl-dots__wrap .owl-dots .owl-dot { border: 0; }

@media (max-width: 991px) { .pricing-block { padding: 32px 8px; } }

.list-block { padding: 64px 0; }

.list-block__desc { margin-bottom: 24px; }

@media (max-width: 991px) { .list-block { padding: 32px 8px; } }

.onepager { padding: 144px 0 64px 0; }

.onepager__subtitle { font-family: "Inter", sans-serif; font-weight: 600; font-size: 14px; line-height: 20px; }

.onepager__title { font-family: "Inter", sans-serif; font-weight: 700; font-size: 40px; line-height: 56px; }

.onepager__author { font-size: 14px; line-height: 20px; position: relative; }

.onepager__author b { margin: 0 16px 0 8px; position: relative; text-transform: uppercase; }

.onepager__author b:after { content: '•'; width: 8px; height: 8px; position: absolute; right: -11px; }

.onepager__author span { color: #232933; }

.onepager__wrapper { margin-top: 64px; }

.onepager__content { padding-right: 50px; }

.onepager__content article h2 { font-family: "Inter", sans-serif; font-weight: 600; font-size: 32px; line-height: 48px; margin-bottom: 24px; }

.onepager__content article p { color: #232933; margin-bottom: 16px; }

.onepager__content article .table-scroll { margin-bottom: 18px; }

.onepager__content article .table--default { width: 100%; }

.onepager__content article .table--default thead { background: #D0D6DD; }

.onepager__content article .table--default tr th, .onepager__content article .table--default tr td { padding: 16px; text-align: center; position: relative; border: 1px solid #EDF0F2; }

.onepager__content article .table--default tr th:first-child, .onepager__content article .table--default tr td:first-child { text-align: left; border-left-color: #FFF; }

.onepager__content article .table--default tr th:last-child, .onepager__content article .table--default tr td:last-child { border-right-color: #FFF; }

.onepager__content article .table--default tr th .badge, .onepager__content article .table--default tr td .badge { position: absolute; font-family: "Inter", sans-serif; font-weight: 600; font-size: 14px; line-height: 20px; top: 8px; padding: 2px 12px; border-radius: 6px; }

.onepager__content article .table--default tr th .badge--green, .onepager__content article .table--default tr td .badge--green { background: #3C914D; color: #FFF; }

.onepager__content article .table--default tr th small, .onepager__content article .table--default tr td small { color: #626B79; font-size: 14px; line-height: 20px; display: block; }

.onepager__content article .table--default tr th { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; }

.onepager__sidebar .sidebar__sticky.stick { margin-top: 16px; }

.onepager__sidebar .sidebar__sticky.stick.show-top { margin-top: 32px; }

.onepager__sidebar .sidebar__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 24px; margin-bottom: 24px; }

.onepager__sidebar .sidebar__list li { margin-bottom: 16px; }

.onepager__sidebar .sidebar__list li a { color: #626B79; }

.onepager__sidebar .sidebar__list li a:hover { color: #4B61DD; }

@media (max-width: 991px) { .onepager__title { font-size: 32px; line-height: 38px; }
  .onepager__content { padding-right: 12px; }
  .onepager__content article h2 { font-size: 28px; line-height: 36px; }
  .onepager__content article .table-scroll { width: 100%; overflow-x: auto; }
  .onepager__content article .table--default tr th, .onepager__content article .table--default tr td { min-width: 170px; }
  .onepager__content article .table--default tr th .badge, .onepager__content article .table--default tr td .badge { width: 12px; height: 12px; border-radius: 50%; color: transparent; font-size: 0; line-height: 0; padding: 0; top: 10px; }
  .onepager__sidebar { position: fixed; top: 55px; left: 0; width: 100%; background: #EDF0F2; padding: 8px 16px; z-index: 10; }
  .onepager__sidebar .sidebar__list { display: none; }
  .onepager__sidebar .sidebar__title { position: relative; margin-bottom: 0; }
  .onepager__sidebar .sidebar__title::after { content: ''; width: 24px; height: 24px; background: url(../images/ic-caret-down.svg) no-repeat center; background-size: contain; position: absolute; right: 0; top: 0; transition: all linear .3s; }
  .onepager__sidebar.active .sidebar__title { margin-bottom: 24px; }
  .onepager__sidebar.active .sidebar__title::after { transform: rotate(180deg); transition: all linear .3s; }
  .onepager__sidebar.active .sidebar__list { display: block; } }

.masthead--contact .masthead__content { padding: 64px 0; }

.masthead--contact .masthead__title { font-size: 40px; line-height: 56px; margin-bottom: 0; font-family: "Inter", sans-serif; font-weight: 700; }

.masthead--contact .masthead__meta { text-align: center; display: block; }

@media (max-width: 991px) { .masthead--contact .masthead__wrap { padding: 0; }
  .masthead--contact .masthead__content { padding: 32px 10px; }
  .masthead--contact .masthead__title { font-size: 36px; line-height: 44px; }
  .masthead--contact .masthead__meta { text-align: center; display: block; } }

.contact-cards--alt { padding: 64px 0; }

.contact-cards--alt .item { margin-bottom: 0; }

.contact-cards--alt .item__wrap { background: #FFF; border: 1px solid #D0D6DD; border-radius: 4px; padding: 32px; }

.contact-cards--alt .item--sm { padding: 0; }

@media (max-width: 991px) { .contact-cards--alt { padding: 32px 10px; }
  .contact-cards--alt .item { margin-bottom: 24px; } }

.contact-headquarter { padding: 64px 0; }

.contact-headquarter__title { text-align: center; }

.contact-headquarter__desc { text-align: center; max-width: 600px; margin: 0 auto 3rem; color: #232933; }

.contact-headquarter .info--img { position: relative; z-index: 2; }

.contact-headquarter .info--img .img { max-height: 440px; max-width: 496px; overflow: hidden; border-radius: 4px; }

.contact-headquarter .info--img .img img { object-fit: contain; width: 100%; height: auto; }

.contact-headquarter .info { position: relative; z-index: 1; }

.contact-headquarter .info:before { content: ""; z-index: 1; display: block; position: absolute; width: 110%; height: 100%; top: 0; right: 0; background: #F2F4F7; border-radius: 0.25rem; }

.contact-headquarter .info__wrap { position: relative; z-index: 2; padding: 64px 64px 64px 45px; }

.contact-headquarter .info__title { margin: 0 0 1rem; }

.contact-headquarter .info__desc p { margin: 0 0 1rem; }

.contact-headquarter .info__desc ul li:not(:last-child) { margin: 0 0 1rem; }

.contact-headquarter .info__action { padding-top: 2.5rem; }

@media (max-width: 991px) { .contact-headquarter { padding: 32px 10px; background: #F2F4F7; }
  .contact-headquarter__desc { margin-bottom: 32px; }
  .contact-headquarter .info::before { display: none; }
  .contact-headquarter .info__wrap { padding: 32px 0 0 0; } }

.our-offices { padding: 64px 0; }

.our-offices__title { text-align: center; font-family: "Inter", sans-serif; font-weight: 600; }

.our-offices__desc { text-align: center; max-width: 600px; margin: 0 auto 3rem; color: #545465; }

.our-offices .item { margin: 0; width: 100%; max-width: 12.5%; flex: 0 0 12.5%; }

.our-offices .item__wrap { text-align: center; padding: 24px 0; border-radius: 0.25rem; }

.our-offices .item__img { margin: 0 auto 1rem; text-align: center; }

.our-offices .item__img img { display: inline-block; width: auto; height: 40px; }

.our-offices .item__title { font-size: 16px; line-height: 24px; margin: 0 0 0.5rem; text-align: center; font-family: "Inter", sans-serif; font-weight: 600; }

.our-offices .item__title:last-child { margin: 0; }

.our-offices .item__desc p { margin: 0 0 0.75rem; }

.our-offices .item__desc ul li:not(:last-child) { margin: 0 0 0.75rem; }

@media (max-width: 991px) { .our-offices { padding: 32px 10px; }
  .our-offices .item { margin: 0 0 1rem; width: 100%; max-width: 50%; flex: 0 0 50%; } }

.form-contact { padding: 64px 0; background: #F2F4F7; }

@media (max-width: 991px) { .form-contact { padding: 32px 0; } }

.form-contact__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 16px; text-align: center; }

.form-contact__sosproof .form-contact__testi-title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 12px; display: block; }

.form-contact__sosproof .form-contact__testimonial { background: #FFF; padding: 44px 32px; border-radius: 6px; margin-bottom: 32px; }

.form-contact__sosproof .form-contact__quote { margin: 0 0 16px 0; }

.form-contact__sosproof .form-contact__icon { display: inline-block; padding-right: 24px; margin-right: 24px; border-right: 1px solid #E2E2E2; vertical-align: middle; }

.form-contact__sosproof .form-contact__meta { display: inline-block; vertical-align: middle; width: calc(100% - 146px); }

.form-contact__sosproof .form-contact__img { width: 48px !important; height: 48px !important; display: inline-block !important; object-fit: contain; border-radius: 50%; margin-right: 16px; vertical-align: middle; }

.form-contact__sosproof .form-contact__author { width: calc(100% - 69px); display: inline-block; vertical-align: middle; }

.form-contact__sosproof .form-contact__author .form-contact__name { font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 24px; }

.form-contact__sosproof .form-contact__clients { margin: 32px 0; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; }

.form-contact__sosproof .form-contact__clients li { flex: 0 0 22.1%; max-width: 22.1%; display: flex; width: 148px; height: 56px; margin: 10px; align-items: center; justify-content: center; }

.form-contact__sosproof .form-contact__logo { width: 141px; display: inline-block; vertical-align: middle; }

.form-contact__sosproof .slider .form-contact__testimonial { margin-bottom: 0; }

.form-contact__sosproof .slider .owl-dots { display: flex; width: 100%; justify-content: center; align-items: center; margin: 32px 14px; }

.form-contact__sosproof .slider .owl-dots .owl-dot { background: #8B95A5; width: 43px; height: 4px; border-radius: 4px; }

.form-contact__sosproof .slider .owl-dots .owl-dot.active { background: #4B61DD; }

@media (max-width: 991px) { .form-contact__sosproof .form-contact__meta { width: 100%; margin-bottom: 24px; }
  .form-contact__sosproof .form-contact__clients li { flex: 0 0 28%; max-width: 28%; } }

.form-contact__form-box { margin-left: 40px; }

.form-contact__form-box .form-meeting__box { padding: 32px 24px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); border-radius: 6px; }

@media (max-width: 991px) { .form-contact__form-box { margin-left: 0; padding: 0; margin-bottom: 32px; }
  .form-contact__form-box__title { font-size: 21px; line-height: 28px; margin-bottom: 20px; } }

#datemeeting-sm .col-7, #datemeeting-sm .col-5 { width: 100%; max-width: 100%; flex: 100%; }

#datemeeting-sm .col-7 { margin-bottom: 12px; }

.tab-sidebar { padding: 64px 0; }

.tab-sidebar .section-head { text-align: center; }

.tab-sidebar .section-head__title { margin-bottom: 8px; }

.tab-sidebar .section-head__desc { margin-bottom: 48px; }

.tab-sidebar__title { font-size: 20px; line-height: 28px; margin-bottom: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-sidebar__sidebar { width: 90%; }

.tab-sidebar__sidebar .nav-tabs { border: 0; }

.tab-sidebar__sidebar .nav-tabs .nav-item { width: 100%; margin-bottom: 0; }

.tab-sidebar__sidebar .nav-tabs .nav-item .nav-link { font-size: 14px; line-height: 24px; border: 0; padding: 12px 30px 12px 0; display: block; width: 90%; border-bottom: 1px solid #D0D6DD; position: relative; color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-sidebar__sidebar .nav-tabs .nav-item .nav-link.active, .tab-sidebar__sidebar .nav-tabs .nav-item .nav-link:hover { color: #4B61DD; }

.tab-sidebar__sidebar .nav-tabs .nav-item .nav-link::after { content: ''; display: inline-block; width: 24px; height: 24px; background: url(../images/ic-caret-down.svg) no-repeat center; background-size: contain; position: absolute; right: 0; top: 14px; transform: rotate(270deg); }

.tab-sidebar .tab-content .tab-pane .row { margin-bottom: 24px; }

.tab-sidebar .tab-content .tab-pane .row:last-child { margin-bottom: 0; }

.tab-sidebar .tab-content .tab-pane .item { padding: 24px; }

.tab-sidebar .tab-content .tab-pane .item__icon { width: 48px; height: 48px; margin-bottom: 8px; object-fit: contain; }

.tab-sidebar .tab-content .tab-pane .item__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 24px; margin-bottom: 8px; }

.tab-sidebar .tab-content .tab-pane .card { padding: 24px; border: 1px solid #D0D6DD; border-radius: 4px; height: calc(100% - 24px); margin-bottom: 24px; }

.tab-sidebar .tab-content .tab-pane .card__img { width: 100%; height: 125px; object-fit: contain; margin-bottom: 8px; }

.tab-sidebar .tab-content .tab-pane .card__icon { width: 48px; height: 48px; object-fit: contain; margin-bottom: 8px; }

.tab-sidebar .tab-content .tab-pane .card__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 24px; margin-bottom: 8px; margin-top: 0; }

.tab-sidebar .tab-content .tab-pane .card__desc p { margin-bottom: 16px; }

@media (max-width: 991px) { .tab-sidebar { padding: 32px 10px; }
  .tab-sidebar .tab-content .tab-pane { display: block; }
  .tab-sidebar .tab-content .tab-pane .tab-sidebar__accr { font-size: 14px; line-height: 20px; color: #626B79; padding: 12px 0; font-family: "Inter", sans-serif; font-weight: 600; cursor: pofont; border-bottom: 1px solid #D0D6DD; position: relative; }
  .tab-sidebar .tab-content .tab-pane .tab-sidebar__accr::after { content: ''; display: inline-block; width: 24px; height: 24px; background: url(../images/ic-caret-down.svg) no-repeat center; background-size: contain; position: absolute; right: 0; top: 11px; }
  .tab-sidebar .tab-content .tab-pane .tab-sidebar__content { display: none; }
  .tab-sidebar .tab-content .tab-pane .tab-sidebar__content .item { position: relative; padding: 12px 16px 12px 40px; }
  .tab-sidebar .tab-content .tab-pane .tab-sidebar__content .item__icon { position: absolute; width: 24px; height: 24px; left: 0; }
  .tab-sidebar .tab-content .tab-pane .tab-sidebar__content .item__desc p { margin-bottom: 0; }
  .tab-sidebar .tab-content .tab-pane .tab-sidebar__content .card { margin-bottom: 24px; }
  .tab-sidebar .tab-content .tab-pane .tab-sidebar__toggle { margin-bottom: 0; }
  .tab-sidebar .tab-content .tab-pane.active .tab-sidebar__content { display: block; }
  .tab-sidebar .tab-content .tab-pane.active .tab-sidebar__accr { color: #4B61DD; border-bottom-color: #4B61DD; }
  .tab-sidebar .tab-content .tab-pane.active .tab-sidebar__accr::after { transform: rotate(180deg); }
  .tab-sidebar .tab-content .tab-pane.active .tab-sidebar__toggle { margin-bottom: 24px; } }

.integration__cta { border-top: 1px solid #E2E2E2; padding: 64px 0; }

@media (max-width: 991px) { .integration__cta { padding: 32px 10px; } }

.block-fea { padding: 64px 0; }

.block-fea__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 32px; line-height: 48px; margin-bottom: 24px; text-align: center; }

.block-fea__desc { margin-bottom: 24px; text-align: center; }

.block-fea .item__wrap .item { height: 100%; padding: 32px; }

.block-fea .item__wrap .item__icon { width: 48px; height: 48px; object-fit: contain; margin-bottom: 8px; }

.block-fea .item__wrap .item__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 8px; }

.block-fea .item__wrap .item__desc p { margin-bottom: 0; }

@media (max-width: 991px) { .block-fea { padding: 32px 0; }
  .block-fea__title { font-size: 28px; line-height: 36px; }
  .block-fea__title br { display: none; }
  .block-fea .item__wrap .col-12:last-child .item { margin-bottom: 0; }
  .block-fea .item__wrap .item { height: calc(100% - 24px); margin-bottom: 24px; padding: 0 0 0 44px; position: relative; }
  .block-fea .item__wrap .item__title { font-size: 20px; line-height: 28px; }
  .block-fea .item__wrap .item__icon { position: absolute; width: 24px; height: 24px; left: 7px; top: 2px; } }

.card-fea { padding: 64px 0; }

.card-fea__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 32px; line-height: 48px; margin-bottom: 24px; text-align: center; }

.card-fea__desc { margin-bottom: 24px; text-align: center; }

.card-fea .card__wrap .card { padding: 32px; border-radius: 4px; height: calc(100% - 24px); margin-bottom: 24px; }

.card-fea .card__wrap .card:hover { box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); }

.card-fea .card__wrap .card:hover .card__icon { filter: grayscale(0); transition: filter linear .3s; }

.card-fea .card__wrap .card:hover .card__action { opacity: 1; visibility: visible; transition: all linear .3s; }

.card-fea .card__wrap .card__img { width: 100%; height: 225px; object-fit: contain; background: #F2F4F7; margin-bottom: 8px; }

.card-fea .card__wrap .card__meta { position: relative; padding-left: 28px; }

.card-fea .card__wrap .card__icon { position: absolute; left: 0; top: 4px; width: 20px; height: 20px; object-fit: contain; filter: grayscale(1); transition: filter linear .3s; }

.card-fea .card__wrap .card__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 10px; }

.card-fea .card__wrap .card__desc { margin-bottom: 32px; }

.card-fea .card__wrap .card__desc p { margin-bottom: 0; }

.card-fea .card__wrap .card__action { opacity: 0; visibility: hidden; text-align: center; transition: all linear .3s; }

@media (max-width: 991px) { .card-fea { padding: 32px 0; }
  .card-fea__title { font-size: 28px; line-height: 36px; }
  .card-fea__title br { display: none; }
  .card-fea .card__wrap .card { padding: 0; margin-bottom: 0; }
  .card-fea .card__wrap .card:hover { box-shadow: none; }
  .card-fea .card__wrap .card:hover .card__icon { filter: grayscale(1); }
  .card-fea .card__wrap .card.active .card__accr { border-bottom-color: #019BDF; transition: all linear .3s; }
  .card-fea .card__wrap .card.active .card__accr::after { transform: rotate(180deg); transition: all linear .3s; }
  .card-fea .card__wrap .card.active .card__icon { filter: grayscale(0); opacity: 1; transition: all linear .3s; }
  .card-fea .card__wrap .card.active .card__title { color: #232933; transition: all linear .3s; }
  .card-fea .card__wrap .card.active .card__content { display: block; visibility: visible; opacity: 1; transition: all linear .3s; padding: 24px 12px; }
  .card-fea .card__wrap .card.active .card__action { opacity: 1; visibility: visible; text-align: left; margin-bottom: 30px; }
  .card-fea .card__wrap .card.active .card__meta { padding-left: 0; }
  .card-fea .card__wrap .card.active .card__desc { margin-bottom: 30px; }
  .card-fea .card__wrap .card__accr { padding: 12px 30px 12px 5px; border-bottom: 1px solid #D0D6DD; position: relative; transition: all linear .3s; }
  .card-fea .card__wrap .card__accr::after { position: absolute; content: ''; display: inline-block; width: 24px; height: 24px; background: url("../images/ic-caret-down.svg") no-repeat center; background-size: contain; right: 5px; top: 14px; transition: all linear .3s; }
  .card-fea .card__wrap .card__icon { position: relative; display: inline-block; vertical-align: middle; margin-right: 8px; top: 0; opacity: .75; transition: all linear .3s; }
  .card-fea .card__wrap .card__title { font-size: 16px; line-height: 24px; color: #626B79; display: inline-block; vertical-align: middle; width: calc(100% - 33px); margin-bottom: 0; transition: all linear .3s; }
  .card-fea .card__wrap .card__content { display: none; opacity: 0; visibility: hidden; transition: all linear .3s; } }

.cs-slider { padding: 64px 0; }

.cs-slider__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 32px; line-height: 48px; margin-bottom: 24px; text-align: center; }

.cs-slider__desc { margin-bottom: 24px; text-align: center; }

.cs-slider__slider .owl-item { border-radius: 4px; overflow: hidden; }

.cs-slider__slider .owl-dots .owl-dot { width: 32px; height: 4px; background: #8B95A5; border-radius: 4px; }

.cs-slider__slider .owl-dots .owl-dot.active { background: #4B61DD; }

.cs-slider__slider .row { overflow: hidden; }

.cs-slider__slider .row .col-md-7 { padding-right: 0; }

.cs-slider__slider .row .col-md-5 { padding-left: 0; }

.cs-slider__testimonial { background: #EDF0F2; padding: 44px 32px; height: 100%; }

.cs-slider__testi-title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 24px; }

.cs-slider__quote { margin: 0 0 16px 0; }

.cs-slider__info { display: flex; align-items: center; width: 100%; }

.cs-slider__info-left, .cs-slider__info-right { width: 50%; }

.cs-slider__info-right { text-align: right; }

.cs-slider__img { display: inline-block; width: 48px; height: 48px; margin-right: 16px; vertical-align: middle; overflow: hidden; }

.cs-slider__img img { height: 48px; width: auto; border-radius: 50%; object-fit: cover; }

.cs-slider__contact { display: inline-block; width: calc(100% - 69px); vertical-align: middle; }

.cs-slider__name { font-family: "Inter", sans-serif; font-weight: 600; }

.cs-slider__logo { display: inline-block; vertical-align: middle; }

.cs-slider__logo img { height: 48px; width: auto; }

.cs-slider__content { display: inline-flex; }

.cs-slider__stats { background: #0055A2; height: 100%; color: #FFF; padding: 32px 24px; }

.cs-slider__items { display: flex; align-items: stretch; }

.cs-slider__items .item { margin-right: 16px; }

.cs-slider__items .item__label { font-size: 20px; line-height: 28px; margin-bottom: 26px; display: block; font-family: "Inter", sans-serif; font-weight: 500; }

.cs-slider__items .item__number { font-size: 52px; line-height: 26px; margin-bottom: 26px; display: block; font-family: "Inter", sans-serif; font-weight: 600; }

.cs-slider__items .item__desc { font-size: 20px; line-height: 28px; margin-bottom: 26px; display: block; font-family: "Inter", sans-serif; font-weight: 500; }

@media (max-width: 991px) { .cs-slider { padding: 32px 0; }
  .cs-slider__title { font-size: 28px; line-height: 36px; }
  .cs-slider__title br { display: none; }
  .cs-slider__testimonial { padding: 24px; }
  .cs-slider__testi-title { margin-bottom: 8px; }
  .cs-slider__info { display: block; }
  .cs-slider__info-left { width: 100%; margin-bottom: 24px; }
  .cs-slider__info-right { width: 100%; text-align: left; }
  .cs-slider__info-right .cs-slider__logo img { height: 32px; }
  .cs-slider__stats { padding: 24px; }
  .cs-slider__items { display: block; }
  .cs-slider__slider .row { overflow: hidden; }
  .cs-slider__slider .row .col-md-7 { padding-right: 12px; }
  .cs-slider__slider .row .col-md-5 { padding-left: 12px; } }

.form-contact--alt { padding: 64px 0; background-color: #F2F4F7; background-repeat: no-repeat; background-position: 100px center; background-size: 35%; }

@media (max-width: 991px) { .form-contact--alt { padding: 42px 10px; background-image: none !important; } }

.form-contact--alt h1, .form-contact--alt h2, .form-contact--alt h3 { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 24px; }

@media (max-width: 991px) { .form-contact--alt h1 br, .form-contact--alt h2 br, .form-contact--alt h3 br { display: none; } }

.form-contact--alt .form-meeting__box { background: transparent; padding: 0; }

.form-contact--alt .form-contact__img img { max-width: 100%; margin: 0 auto; width: unset; }

.form-contact--alt .form-contact__wrap { background: transparent; box-shadow: none; margin-bottom: 0; padding: 0; }

.form-contact--alt .form-contact__title { font-size: 20px; line-height: 28px; margin-bottom: 24px; }

.form-contact--alt .form-group { margin-bottom: 20px; }

.form-contact--alt .form-group label { font-family: "Inter", sans-serif; font-weight: 600; font-size: 14px; line-height: 20px; margin-bottom: 4px; display: inline-block; }

.form-contact--alt .form-group small { color: #626B79; font-size: 12px; line-height: 16px; }

.form-contact--alt .form-group span.info { width: 20px; height: 20px; background: url("../images/ic-info.svg") no-repeat center; background-size: contain; display: inline-block; vertical-align: middle; margin-left: 5px; cursor: help; }

.form-contact--alt .form-group .form-control { border: 1px solid #D0D6DD; border-radius: 6px; }

.form-contact--alt .form-group .form-control:active, .form-contact--alt .form-group .form-control:hover, .form-contact--alt .form-group .form-control:focus { border-color: #314AD8; box-shadow: none; }

.form-contact--alt .form-group .select { background: #FFF; }

.form-contact--alt .form-group input[type="file"] { font-size: 13px; }

.form-contact--alt .form-group label.cb-label { font-family: "Inter", sans-serif; font-weight: 400; font-size: 14px; line-height: 20px; color: #232933; margin-bottom: 24px; }

.form-contact--alt .form-group label.cb-label a { color: #4B61DD; text-decoration: underline; }

.form-contact--alt .form-group label.cb-label a:hover { color: #1f33a3; text-decoration: none; }

@media (max-width: 991px) { .form-contact--alt .form-group .col-lg-6 { margin-bottom: 20px; }
  .form-contact--alt .form-group .col-lg-6:last-child { margin-bottom: 0; }
  .form-contact--alt .form-group label.cb-label { display: inline-block; width: calc(100% - 30px); vertical-align: top; } }

.masthead--accr .masthead__left { padding-right: 50px; }

.masthead--accr .masthead__accr.accordion { margin-bottom: 24px; }

.masthead--accr .masthead__accr.accordion .card { padding: 0; border: 1px solid #D0D6DD; border-radius: 4px; margin-bottom: 8px; background: #FFF !important; }

.masthead--accr .masthead__accr.accordion .card:last-child { margin-bottom: 0; }

.masthead--accr .masthead__accr.accordion .card.open .collapse { display: block !important; }

.masthead--accr .masthead__accr.accordion .card__header { padding: 20px 56px 0 16px !important; position: relative; border-bottom: 0; }

.masthead--accr .masthead__accr.accordion .card__header::after { content: "" !important; display: inline-block !important; width: 24px !important; height: 24px !important; position: absolute !important; background: url(../images/ic-caret-down.svg) no-repeat center !important; background-size: contain !important; right: 16px !important; top: 20px !important; }

.masthead--accr .masthead__accr.accordion .card__header.collapsed { padding-bottom: 20px !important; }

.masthead--accr .masthead__accr.accordion .card__title { text-align: left !important; font-size: 16px !important; line-height: 24px !important; margin-bottom: 4px !important; font-family: "Inter", sans-serif; font-weight: 600; }

.masthead--accr .masthead__accr.accordion .card__body { box-shadow: none; padding: 0 56px 20px 16px !important; margin-top: 0; }

.masthead--accr .masthead__accr.accordion .card__body ul { list-style: disc; padding-left: 15px; }

.masthead--accr .masthead__accr.accordion .card .collapse { display: none !important; }

@media (max-width: 991px) { .masthead--accr .masthead__left { padding-right: 12px; }
  .masthead--accr .masthead__accr.accordion { margin-bottom: 24px; }
  .masthead--accr .masthead__accr.accordion .card { padding: 0; border: 1px solid #D0D6DD; border-radius: 4px; margin-bottom: 8px; background: #FFF !important; }
  .masthead--accr .masthead__accr.accordion .card:last-child { margin-bottom: 0; }
  .masthead--accr .masthead__accr.accordion .card.open .collapse { display: block !important; }
  .masthead--accr .masthead__accr.accordion .card__header { padding: 20px 56px 0 16px !important; position: relative; }
  .masthead--accr .masthead__accr.accordion .card__header::after { content: "" !important; display: inline-block !important; width: 24px !important; height: 24px !important; position: absolute !important; background: url(../images/ic-caret-down.svg) no-repeat center !important; background-size: contain !important; right: 16px !important; top: 20px !important; }
  .masthead--accr .masthead__accr.accordion .card__header.collapsed { padding-bottom: 20px !important; }
  .masthead--accr .masthead__accr.accordion .card__title { text-align: left !important; font-size: 16px !important; line-height: 24px !important; margin-bottom: 4px !important; font-family: "Inter", sans-serif; font-weight: 600; }
  .masthead--accr .masthead__accr.accordion .card__body { box-shadow: none; padding: 0 56px 20px 16px !important; margin-top: 0; }
  .masthead--accr .masthead__accr.accordion .card .collapse { display: none !important; } }

.features-accr .accordion--section .block-accr { padding: 64px 0; }

.features-accr .accordion--section .block-accr__subtitle { font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 24px; }

.features-accr .accordion--section .block-accr__title { font-size: 32px; line-height: 48px; margin-bottom: 8px; }

.features-accr .accordion--section .block-accr__accr.accordion { margin-bottom: 24px; }

.features-accr .accordion--section .block-accr__accr.accordion .card { padding: 0; border: 1px solid #D0D6DD; border-radius: 4px; margin-bottom: 8px; background: #FFF !important; }

.features-accr .accordion--section .block-accr__accr.accordion .card:last-child { margin-bottom: 0; }

.features-accr .accordion--section .block-accr__accr.accordion .card.open .collapse { display: block !important; }

.features-accr .accordion--section .block-accr__accr.accordion .card__header { padding: 20px 56px 0 16px !important; position: relative; }

.features-accr .accordion--section .block-accr__accr.accordion .card__header::after { content: "" !important; display: inline-block !important; width: 24px !important; height: 24px !important; position: absolute !important; background: url(../images/ic-caret-down.svg) no-repeat center !important; background-size: contain !important; right: 16px !important; top: 20px !important; }

.features-accr .accordion--section .block-accr__accr.accordion .card__header.collapsed { padding-bottom: 20px !important; }

.features-accr .accordion--section .block-accr__accr.accordion .card__title { text-align: left !important; font-size: 16px !important; line-height: 24px !important; margin-bottom: 4px !important; font-family: "Inter", sans-serif; font-weight: 600; }

.features-accr .accordion--section .block-accr__accr.accordion .card__body { box-shadow: none; padding: 0 16px 20px 16px !important; margin-top: 0; }

.features-accr .accordion--section .block-accr__accr.accordion .card__body ul { list-style: disc; padding-left: 15px; }

.features-accr .accordion--section .block-accr__accr.accordion .card .collapse { display: none !important; }

@media (max-width: 991px) { .features-accr .accordion--section .block-accr { padding: 32px 0; }
  .features-accr .accordion--section .block-accr__title { font-size: 24px; line-height: 32px; }
  .features-accr .accordion--section .block-accr__img { margin-bottom: 24px; }
  .features-accr .accordion--section .block-accr__accr.accordion { margin-bottom: 24px; }
  .features-accr .accordion--section .block-accr__accr.accordion .card { padding: 0; border: 1px solid #D0D6DD; border-radius: 4px; margin-bottom: 8px; background: #FFF !important; }
  .features-accr .accordion--section .block-accr__accr.accordion .card:last-child { margin-bottom: 0; }
  .features-accr .accordion--section .block-accr__accr.accordion .card.open .collapse { display: block !important; }
  .features-accr .accordion--section .block-accr__accr.accordion .card__header { padding: 20px 56px 0 16px !important; position: relative; }
  .features-accr .accordion--section .block-accr__accr.accordion .card__header::after { content: "" !important; display: inline-block !important; width: 24px !important; height: 24px !important; position: absolute !important; background: url(../images/ic-caret-down.svg) no-repeat center !important; background-size: contain !important; right: 16px !important; top: 20px !important; }
  .features-accr .accordion--section .block-accr__accr.accordion .card__header.collapsed { padding-bottom: 20px !important; }
  .features-accr .accordion--section .block-accr__accr.accordion .card__title { text-align: left !important; font-size: 16px !important; line-height: 24px !important; margin-bottom: 4px !important; font-family: "Inter", sans-serif; font-weight: 600; }
  .features-accr .accordion--section .block-accr__accr.accordion .card__body { box-shadow: none; padding: 0 16px 20px 16px !important; margin-top: 0; }
  .features-accr .accordion--section .block-accr__accr.accordion .card .collapse { display: none !important; } }

.compare-cards.compare-cards--alt { padding: 64px 0; }

.compare-cards.compare-cards--alt .section-head { padding-bottom: 24px; }

.compare-cards.compare-cards--alt .compare-cards__compare { padding: 0; }

.compare-cards.compare-cards--alt .compare-cards__compare .item__head title { font-size: 20px !important; line-height: 28px !important; }

.compare-cards.compare-cards--alt .compare-cards__compare .item__body title { font-size: 16px; line-height: 24px; }

.compare-cards.compare-cards--alt .compare-cards__compare .item--highlight .item__head { background: linear-gradient(340deg, #0087D9 9.7%, #00A8FD 47.12%, #66CFFF 84.53%); border-color: #009BDE; }

.compare-cards.compare-cards--alt .compare-cards__compare .item--highlight .item__head title { font-size: 20px; line-height: 28px; }

.compare-cards.compare-cards--alt .compare-cards__compare .item--highlight .item__body { border-color: #0098DE; }

@media (max-width: 991px) { .compare-cards.compare-cards--alt { padding: 32px 0; } }

.tab-cs .section-head__title { margin-bottom: 24px; text-align: center; }

.tab-cs .slider .owl-dots .owl-dot { width: 32px; height: 4px; border-radius: 4px; background: #8B95A5; }

.tab-cs .slider .owl-dots .owl-dot.active { background: #4B61DD; }

.tab-inline { padding: 64px 0 0 0; }

.tab-inline .section-head { margin-bottom: 24px; }

.tab-inline .section-head__title { text-align: center; }

.tab-inline .nav-tabs { display: inline-flex; width: 100%; justify-content: center; align-items: stretch; }

.tab-inline .nav-tabs .nav-item { display: flex; flex: 0 0 25%; justify-content: center; }

.tab-inline .nav-tabs .nav-item .nav-link { display: inline-flex; justify-content: center; align-items: center; padding: 24px 24px 20px 24px; border-bottom: 4px solid transparent; }

.tab-inline .nav-tabs .nav-item .nav-link .nav-icon { margin-right: 16px; }

.tab-inline .nav-tabs .nav-item .nav-link span { font-family: "Inter", sans-serif; font-weight: 600; color: #232933; }

.tab-inline .nav-tabs .nav-item .nav-link.active, .tab-inline .nav-tabs .nav-item .nav-link:hover { border-bottom-color: #4B61DD; }

.tab-inline__content { background: #EAECFB; padding: 64px 0; }

.tab-inline .tab-content .toggle-sm { display: none; }

.tab-inline .tab-content .promo-benefit__title { font-size: 20px; line-height: 28px; margin-bottom: 8px; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-inline .tab-content .promo-benefit__desc p { margin-bottom: 16px; }

.tab-inline.in-app .tab-inline .tab-content .tab-pane .promo-benefit__content { color: #232933; font-size: 14px; line-height: 20px; }

.tab-inline.in-app .tab-inline .tab-content .tab-pane .promo-benefit__content .custom-list li { font-size: 14px; line-height: 20px; }

@media (max-width: 991px) { .tab-inline { padding: 32px 0; }
  .tab-inline .section-head__title br { display: none; }
  .tab-inline__content { padding: 0; background: transparent; }
  .tab-inline .tab-content .tab-pane { display: block; }
  .tab-inline .tab-content .tab-pane > .row { padding: 32px 0; background: #EAECFB; display: none; }
  .tab-inline .tab-content .tab-pane.active > .row { display: flex; }
  .tab-inline .tab-content .tab-pane.active .toggle-sm { border-bottom: 2px solid #4B61DD; }
  .tab-inline .tab-content .tab-pane.active .toggle-sm::after { transform: rotate(180deg); transition: transform linear .3s; }
  .tab-inline .tab-content .tab-pane.active .toggle-sm span { color: #232933; font-family: "Inter", sans-serif; font-weight: 600; }
  .tab-inline .tab-content .tab-pane.active .toggle-sm__icon img { filter: grayscale(0); }
  .tab-inline .tab-content .toggle-sm { display: flex; width: 100%; align-items: center; justify-content: start; padding: 12px; border-bottom: 1px solid #626B79; position: relative; }
  .tab-inline .tab-content .toggle-sm::after { content: ''; position: absolute; width: 24px; height: 24px; background: url(../images/ic-caret-down.svg) no-repeat center; background-size: contain; top: 20px; right: 7px; transform: rotate(0deg); transition: transform linear .3s; }
  .tab-inline .tab-content .toggle-sm__icon { margin-right: 12px; }
  .tab-inline .tab-content .toggle-sm__icon img { height: 36px; width: auto; filter: grayscale(1); }
  .tab-inline .tab-content .toggle-sm span { font-size: 16px; line-height: 24px; color: #626B79; }
  .tab-inline .tab-content .promo-benefit__title { text-align: left; }
  .tab-inline .tab-content .promo-benefit__img { display: block; margin-top: 24px; } }

.pricing-block--alt { padding: 64px 0; }

.pricing-block--alt .section-head { text-align: left; }

.pricing-block--alt .section-head__desc ul.custom-list.two-col { flex-direction: row; display: flex; flex: 0 0 50%; }

.pricing-block--alt .card { padding: 16px; border: 1px solid #D0D6DD; border-radius: 4px; }

.pricing-block--alt .card__head h3 { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; display: flex; width: 100%; align-items: center; justify-content: space-between; }

.pricing-block--alt .card__head h3 span.badge { font-size: 12px; line-height: 16px; }

.pricing-block--alt .card__head .bonus { padding: 22px 12px; border: 1px solid #009BDE; border-radius: 4px; background: #FFF; font-size: 14px; line-height: 20px; margin-bottom: 24px; }

.pricing-block--alt .card__head .bonus b { font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 8px; }

.pricing-block--alt .card__head .bonus ul { list-style: disc; padding-left: 15px; }

.pricing-block--alt .card__head .price { margin-bottom: 24px; }

.pricing-block--alt .card__head .price .label { display: block; text-decoration: line-through; color: #626B79; font-size: 14px; line-height: 20px; }

.pricing-block--alt .card__head .price .pr { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; color: #232933; display: block; }

.pricing-block--alt .card__head .price .pr small { color: #626B79; font-size: 14px; line-height: 20px; }

.pricing-block--alt .card__head .price .pr span.badge { padding: 4px 12px; background: #FFF; border: 1px solid #4B61DD; color: #4B61DD; font-size: 14px; line-height: 20px; font-family: "Inter", sans-serif; font-weight: 400; border-radius: 24px; margin-left: 8px; }

.pricing-block--alt .card__head .price .tc { display: block; font-size: 14px; line-height: 20px; color: #626B79; }

.pricing-block--alt .card.card--highlight { border-color: #009BDE; box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05); background: #EDFAFF; }

@media (max-width: 991px) { .pricing-block--alt { padding: 32px 0; }
  .pricing-block--alt .section-head__title { text-align: center; }
  .pricing-block--alt .section-head__title br { display: none; } }

.form-ticket { background: #F1F5F9; padding: 64px 0; }

.form-ticket .section-head { text-align: left; padding-right: 30px; padding-bottom: 0; }

.form-ticket .section-head .section-head__meta, .form-ticket .section-head .section-head__subtitle { font-size: 16px; line-height: 24px; color: #232933; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 7px; }

.form-ticket .section-head .section-head__title { position: relative; margin-bottom: 16px; }

.form-ticket .section-head .section-head__title .underline { position: relative; display: inline-flex; margin-right: 6px; }

.form-ticket .section-head .section-head__title .underline::before { content: ''; width: calc(100% + 30px); height: 18px; background: url(../images/red-underline.svg) no-repeat center; background-size: contain; position: absolute; left: -8px; bottom: -8px; }

.form-ticket .form-meeting__box { box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05); border-radius: 8px; padding: 32px; }

.form-ticket .ticket { margin-right: 70px; position: relative; }

.form-ticket .ticket::after { content: ''; width: 94px; height: 82px; background: url(../images/icon-percentage.svg) no-repeat center; background-size: 100%; position: absolute; right: -30px; bottom: 15px; }

.form-ticket .ticket__wrap { position: relative; background: url(../images/ticket-center.png) repeat-x; background-size: contain; padding: 28px; }

.form-ticket .ticket__wrap::before { content: ''; position: absolute; left: 0; top: 0; height: 100%; width: 17px; background: url(../images/ticket-left.png) no-repeat; background-color: #F1F5F9; background-size: 100% 100%; }

.form-ticket .ticket__wrap::after { content: ''; position: absolute; right: 0; top: 0; height: 100%; width: 18px; background: url(../images/ticket-right.png) no-repeat; background-color: #F1F5F9; background-size: 100% 100%; }

.form-ticket .ticket .title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 24px; margin-bottom: 8px; display: block; }

.form-ticket .ticket .price { display: flex; align-items: center; margin-bottom: 8px; }

.form-ticket .ticket .price .pr { display: inline-flex; font-size: 32px; line-height: 48px; color: #232933; font-family: "Inter", sans-serif; font-weight: 600; }

.form-ticket .ticket .price .tenor { font-size: 14px; line-height: 20px; color: #626B79; }

.form-ticket .ticket .price .desc, .form-ticket .ticket .price .desc p { font-size: 14px; line-height: 20px; color: #626B79; }

.form-ticket .ticket .price .snk { font-size: 14px; line-height: 20px; color: #626B79; }

@media (max-width: 991px) { .form-ticket { padding: 32px 0; }
  .form-ticket .section-head .section-head__meta, .form-ticket .section-head .section-head__subtitle { display: flex; align-items: center; }
  .form-ticket .section-head .section-head__meta span, .form-ticket .section-head .section-head__subtitle span { display: inline-flex; }
  .form-ticket .section-head .section-head__meta span.pill, .form-ticket .section-head .section-head__subtitle span.pill { flex: 0 0 75px; }
  .form-ticket .section-head .section-head__meta span.label, .form-ticket .section-head .section-head__subtitle span.label { flex: 0 0 calc(100% - 75px); }
  .form-ticket .section-head .section-head__title .underline { margin-right: 6px; }
  .form-ticket .ticket { margin-top: 8px; margin-bottom: 24px; margin-right: 0; }
  .form-ticket .ticket::after { width: 71px; height: 62px; right: -15px; }
  .form-ticket .ticket .desc { padding-right: 120px; } }

@media (max-width: 479px) { .form-ticket .ticket::after { right: 0; }
  .form-ticket .ticket .desc { padding-right: 30px; } }

.formpage { display: block; max-width: 600px; margin: 64px auto; border-radius: 6px; background: #FFF; box-shadow: 0 4px 8px 0 rgba(119, 119, 119, 0.1), 0 12px 20px 0 rgba(119, 119, 119, 0.2); }

.formpage__title { font-size: 32px; line-height: 48px; margin: 0 0 16px; text-align: center; }

.formpage__desc { text-align: center; margin: 0 0 48px; }

.formpage__img { border-radius: 6px 6px 0 0; overflow: hidden; }

.formpage__img img { display: block; width: 100%; height: auto; position: relative; }

.formpage__form { padding: 48px; }

.formpage .form-action { text-align: center; }

@media (max-width: 991px) { .formpage { margin: 48px 20px; }
  .formpage__title { font-size: 20px; line-height: 28px; }
  .formpage__desc { font-size: 14px; line-height: 20px; margin: 0 0 24px; }
  .formpage__form { padding: 24px 24px 32px; } }

.fea-cta { padding: 64px 0; }

.fea-cta .container { background: #EAECFB; padding: 26px 32px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); border-radius: 4px; }

.fea-cta .container .section__title { margin-bottom: 12px; }

@media (max-width: 991px) { .fea-cta { padding: 32px 0; margin-left: 16px; margin-right: 16px; }
  .fea-cta .container { padding: 24px; } }

.steps--box { margin-left: 16px; margin-right: 16px; }

.steps--box .container { background: #F2F4F7; border-radius: 8px; padding: 24px; }

@media (max-width: 991px) { .steps--box .container .row { flex-direction: column-reverse; } }

.invoice-generator .masthead { padding: 64px 0; }

@media (max-width: 991px) { .invoice-generator .masthead { padding: 32px 0; } }

.invoice-generator:first-child .invoice-generator__wrap { padding-top: 8rem; }

.invoice-generator__wrap { padding: 64px 0; background: #F2F4F7; font-size: 14px; line-height: 1.5em; }

.invoice-generator label { display: block; font-size: 14px; line-height: 1.5em; color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; margin: 0.5rem 0; }

.invoice-generator label.sr-only { opacity: 0; }

.invoice-generator .form-control { display: block; width: 100%; border: 1px solid #E2E2E2; height: 38px; padding: 6px 12px; font-size: 14px; border-radius: 4px; line-height: 1.5em; }

.invoice-generator input::-webkit-outer-spin-button, .invoice-generator input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

.invoice-generator input[type=number] { -moz-appearance: textfield; }

.invoice-generator textarea.form-control { height: auto; resize: none; }

.invoice-generator .form-upload { position: relative; }

.invoice-generator .form-upload__button input { display: block; opacity: 0; visibility: hidden; z-index: -10; height: 0; position: absolute; }

.invoice-generator .form-upload__button label { display: block; width: 100%; padding: 1.5rem; border: 2px dashed #E2E2E2; text-align: center; color: #777; cursor: pointer; font-size: 14px; line-height: 1.5em; font-family: "Inter", sans-serif; font-weight: 400; }

.invoice-generator .form-upload__button span.edit { display: none; }

.invoice-generator .form-upload__preview { display: none; }

.invoice-generator .form-upload__preview img { max-height: 90px; }

.invoice-generator .form-upload.has-img .form-upload__button label { width: auto; display: inline-block; padding: 6px 12px; border: 1px solid #E2E2E2; border-radius: 3px; color: #545465; background: #F2F4F7; font-family: "Inter", sans-serif; font-weight: 500; }

.invoice-generator .form-upload.has-img .form-upload__button span.edit { display: block; }

.invoice-generator .form-upload.has-img .form-upload__button span.add, .invoice-generator .form-upload.has-img .form-upload__button span.icon { display: none; }

@media (max-width: 991px) { .invoice-generator .form-upload { padding-bottom: 24px; border-bottom: 1px solid #E2E2E2; } }

.invoice-generator .form-section { margin-bottom: 2rem; }

.invoice-generator .form-section--table { width: auto; }

.invoice-generator .form-section--action { width: auto; margin-bottom: 0; border-top: 1px solid #E2E2E2; text-align: right; padding: 2rem 2rem 0; }

.invoice-generator .form-section--action .btn + .btn { margin-left: 1rem; }

.invoice-generator .form-section--action .btn-blue { min-width: 10rem; }

@media (max-width: 991px) { .invoice-generator .form-section { margin-bottom: 12px; } }

.invoice-generator .form-row { align-items: center; margin-bottom: 0.75rem; }

.invoice-generator .list-total { display: block; padding: 0 1.5rem 0 0; margin: 0; color: #212121; font-size: 14px; line-height: 1.5em; font-family: "Inter", sans-serif; font-weight: 400; }

.invoice-generator .list-total li { display: flex; }

.invoice-generator .list-total li p { display: block; width: 100%; max-width: 40%; flex: 0 0 40%; margin: 0 0 0.5rem; }

.invoice-generator .list-total li p.number { text-align: right; max-width: 60%; flex: 0 0 60%; }

.invoice-generator .list-total .list-total__final { border-top: 1px solid #E2E2E2; padding: 0.5rem 0 0; font-size: 16px; line-height: 1.5em; font-family: "Inter", sans-serif; font-weight: 500; }

@media (max-width: 991px) { .invoice-generator .list-total .list-total__final { margin-bottom: 0; } }

.invoice-generator .list-total .list-total__pyd { padding: 0.75rem 0 0; border-top: 1px solid #E2E2E2; display: none; }

.invoice-generator .list-total .list-total__pyd.show { display: flex; }

.invoice-generator .list-total .list-total__tyhb { display: none; }

.invoice-generator .sum-notes { display: none; }

.invoice-generator .notes .notes_label { font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 4px; }

.invoice-generator .notes .notes_value textarea { max-width: 432px; }

.invoice-generator .watermark { position: absolute; bottom: 0; left: 5px; visibility: hidden; opacity: 0; }

.invoice-generator__box { padding: 24px; background: #FFF; border-radius: 8px; max-width: 936px; margin: 0 auto; font-size: 14px; line-height: 20px; }

.invoice-generator__box.ready-to-print { width: 100%; max-width: 595pt; }

.invoice-generator__box.ready-to-print .form-upload__preview img { max-height: 90px; width: auto; height: 100%; }

.invoice-generator__box.ready-to-print .form-upload__button { display: none; }

.invoice-generator__box.ready-to-print .form-upload__button #invLogo, .invoice-generator__box.ready-to-print .form-upload__button span.edit, .invoice-generator__box.ready-to-print .form-upload__button span.add { display: none; }

.invoice-generator__box.ready-to-print .form-control { border: 0; padding: 0; margin-bottom: 5px; height: auto; }

.invoice-generator__box.ready-to-print input[type="date"]::-webkit-inner-spin-button, .invoice-generator__box.ready-to-print input[type="date"]::-webkit-calendar-picker-indicator { display: none; -webkit-appearance: none; }

.invoice-generator__box.ready-to-print .form-row { margin-bottom: 12px; }

.invoice-generator__box.ready-to-print .sr-only { display: none; }

.invoice-generator__box.ready-to-print .sum-action { visibility: hidden; opacity: 0; display: none; }

.invoice-generator__box.ready-to-print .form-section.line { border-top: 1px solid #D0D6DD; padding-top: 24px; }

.invoice-generator__box.ready-to-print .form-section .row .reverse-sm { flex-direction: unset; }

.invoice-generator__box.ready-to-print .form-section--action { display: none; }

.invoice-generator__box.ready-to-print .form-section--table { margin: 0 auto; }

.invoice-generator__box.ready-to-print .sum-notes { display: flex; position: relative; }

.invoice-generator__box.ready-to-print .sum-notes .watermark { position: absolute; bottom: 0; max-width: 500px; font-size: 14px; line-height: 20px; }

.invoice-generator__box.ready-to-print .sum-notes > div { display: block; margin-bottom: 8px; }

.invoice-generator__box.ready-to-print .sum-notes .notes_value { display: block; font-family: "Inter", sans-serif; font-weight: 400; }

.invoice-generator__box.ready-to-print .notes .notes_label { font-family: "Inter", sans-serif; font-weight: 600; }

.invoice-generator__box.ready-to-print span.print { display: block; visibility: visible; opacity: 1; margin-bottom: 4px; }

.invoice-generator__box.ready-to-print .invoice_name { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; padding: 0; text-align: right; }

.invoice-generator__box.ready-to-print input, .invoice-generator__box.ready-to-print textarea { user-select: none; }

.invoice-generator__box.ready-to-print label { margin: 0; }

.invoice-generator__box.ready-to-print .list-total { padding: 0 20px 0 0; margin: 0 0 0 120px; }

.invoice-generator__box.ready-to-print .list-total li.pemotongan, .invoice-generator__box.ready-to-print .list-total li.deposito { display: none; }

.invoice-generator__box.ready-to-print .list-total__final { border-bottom: 1px solid #E2E2E2; }

.invoice-generator__box.ready-to-print .watermark { position: absolute; bottom: 0; left: 10px; }

.invoice-generator__box.ready-to-print .table-wrap .table thead tr th { padding: 10px; }

.invoice-generator__box.ready-to-print .table-wrap .table thead tr th:first-child { padding-left: 10px; }

.invoice-generator__box.ready-to-print .table-wrap .table thead tr th:last-child { display: none; }

.invoice-generator__box.ready-to-print .table-wrap .table thead tr th:nth-child(7) { border-right: 1px solid #E2E2E2; }

.invoice-generator__box.ready-to-print .table-wrap .table tbody tr td:first-child { padding-left: 0; }

.invoice-generator__box.ready-to-print .table-wrap .table tbody tr td:last-child { display: none; }

.invoice-generator__box.ready-to-print .table-wrap .table tbody tr td input { height: auto; user-select: none; border: none; padding: 10px 8px; }

.invoice-generator__box.ready-to-print .table-wrap .table tbody tr td input.item_price, .invoice-generator__box.ready-to-print .table-wrap .table tbody tr td input.item_total { min-width: 135px; max-width: 135px; }

.invoice-generator__box.ready-to-print .table-wrap .table tbody tr td::before { background: transparent; }

.invoice-generator__box.ready-to-print .table-wrap .table tbody tr td textarea { border: none; min-width: 150px; max-width: 150px; }

.invoice-generator__box.ready-to-print .table-wrap .table tbody tr td.b_tax input, .invoice-generator__box.ready-to-print .table-wrap .table tbody tr td.b_disc input { padding: 8px 38px 8px 2px; }

.invoice-generator__box.ready-to-print .table-wrap .table tbody tr td.b_total input, .invoice-generator__box.ready-to-print .table-wrap .table tbody tr td.b_price input { padding: 8px 2px 8px 38px; }

.invoice-generator__box.ready-to-print .table-wrap .table tbody tr td .show-sm { display: none; }

@media (max-width: 991px) { .invoice-generator__box.ready-to-print .col-md-2 { flex: 0 0 16.666667%; max-width: 16.666667%; }
  .invoice-generator__box.ready-to-print .col-md-5 { flex: 0 0 41.666667%; max-width: 41.666667%; }
  .invoice-generator__box.ready-to-print .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .invoice-generator__box.ready-to-print .col-md-7 { flex: 0 0 58.333333%; max-width: 0 0 58.333333%; }
  .invoice-generator__box.ready-to-print .col-md-10 { flex: 0 0 83.333333%; max-width: 83.333333%; }
  .invoice-generator__box.ready-to-print .offset-md-2 { margin-left: 16.666667%; }
  .invoice-generator__box.ready-to-print table thead th:nth-child(3), .invoice-generator__box.ready-to-print table thead th:nth-child(4), .invoice-generator__box.ready-to-print table thead th:nth-child(5), .invoice-generator__box.ready-to-print table thead th:nth-child(6), .invoice-generator__box.ready-to-print table thead th:nth-child(7), .invoice-generator__box.ready-to-print table thead th:nth-child(8) { display: table-cell; }
  .invoice-generator__box.ready-to-print table tbody td:nth-child(3), .invoice-generator__box.ready-to-print table tbody td:nth-child(4), .invoice-generator__box.ready-to-print table tbody td:nth-child(5), .invoice-generator__box.ready-to-print table tbody td:nth-child(6), .invoice-generator__box.ready-to-print table tbody td:nth-child(7), .invoice-generator__box.ready-to-print table tbody td:nth-child(8) { display: table-cell; }
  .invoice-generator__box.ready-to-print table tbody td:nth-child(2) { padding-top: 0; }
  .invoice-generator__box.ready-to-print table tbody td .show-sm { display: none !important; }
  .invoice-generator__box.ready-to-print .form-section.line { border-top: 1px solid #D0D6DD; padding-top: 24px; }
  .invoice-generator__box.ready-to-print .form-section.row.reverse-sm { flex-direction: unset; } }

.invoice-generator__box label { display: block; font-size: 14px; line-height: 20px; color: #2B2A35; font-family: "Inter", sans-serif; font-weight: 500; margin: 0.5rem 0; }

.invoice-generator__box label.sr-only { opacity: 0; }

.invoice-generator__box .form-control { display: block; width: 100%; border: 1px solid #E2E2E2; height: 38px; padding: 6px 12px; font-size: 14px; border-radius: 6px; line-height: 20px; }

.invoice-generator__box span.print { color: #8B95A5; font-size: 12px; line-height: 16px; display: none; visibility: hidden; opacity: 0; }

.invoice-generator__box input::-webkit-outer-spin-button, .invoice-generator__box input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

.invoice-generator__box input[type=number] { -moz-appearance: textfield; }

.invoice-generator__box textarea.form-control { height: auto; resize: none; }

.invoice-generator__box .form-upload { position: relative; }

.invoice-generator__box .form-upload__button input { display: block; opacity: 0; visibility: hidden; z-index: -10; height: 0; position: absolute; }

.invoice-generator__box .form-upload__button label { display: block; width: 100%; padding: 12px; border: 1px dashed #4B61DD; border-radius: 4px; text-align: center; color: #777; cursor: pointer; font-size: 14px; line-height: 20px; background: #EAECFB; margin: 0; font-family: "Inter", sans-serif; font-weight: 400; }

.invoice-generator__box .form-upload__button span.icon { display: block; text-align: center; margin-bottom: 10px; }

.invoice-generator__box .form-upload__button span.edit { display: none; }

.invoice-generator__box .form-upload__preview { display: none; }

.invoice-generator__box .form-upload.has-img .form-upload__button label { width: auto; display: inline-block; padding: 6px 12px; border: 1px solid #E2E2E2; border-radius: 3px; color: #545465; background: #F2F4F7; font-family: "Inter", sans-serif; font-weight: 500; }

.invoice-generator__box .form-upload.has-img .form-upload__button span.edit { display: block; }

.invoice-generator__box .form-upload.has-img .form-upload__button span.add, .invoice-generator__box .form-upload.has-img .form-upload__button span.icon { display: none; }

.invoice-generator__box .form-section { margin-bottom: 24px; }

.invoice-generator__box .form-section.line { border-top: 1px solid #D0D6DD; padding-top: 24px; }

.invoice-generator__box .form-section--table { width: auto; margin-left: 0; margin-right: 0; }

.invoice-generator__box .form-section--action { width: auto; margin-left: -2rem; margin-right: -2rem; margin-bottom: 0; border-top: 1px solid #E2E2E2; text-align: right; padding: 2rem 2rem 0; }

.invoice-generator__box .form-section--action .btn + .btn { margin-left: 1rem; }

.invoice-generator__box .form-section--action .btn-blue { min-width: 10rem; }

@media (max-width: 991px) { .invoice-generator__box .form-section { margin-bottom: 12px; }
  .invoice-generator__box .form-section--action { width: 100%; margin-left: auto; margin-right: auto; padding: 0; }
  .invoice-generator__box .form-section--action .btn + .btn { margin-left: 0; }
  .invoice-generator__box .form-section.row.reverse-sm { flex-direction: column-reverse; }
  .invoice-generator__box .form-section.line { padding-top: 0; border-top: none; }
  .invoice-generator__box .form-section.line .form-row.row .col-md-6 .form-row.row { margin-bottom: 12px; } }

.invoice-generator__box .form-row { align-items: center; margin-bottom: 20px; }

.invoice-generator__box .form-row:last-child { margin-bottom: 0; }

@media (max-width: 991px) { .invoice-generator__box .form-row { margin-bottom: 12px; }
  .invoice-generator__box .form-row:last-child { margin-bottom: 24px; }
  .invoice-generator__box .form-row.margin-sm { margin-top: 24px; } }

.invoice-generator__box .table { width: 100%; border: 1px solid #E2E2E2; }

.invoice-generator__box .table .tooltip-inner { border-radius: 4px; font-size: 14px; line-height: 1.5em; }

.invoice-generator__box .table thead { background: #EDF0F2; }

.invoice-generator__box .table thead th { padding: 10px 12px; font-family: "Inter", sans-serif; font-weight: 600; font-size: 14px; line-height: 1.5em; text-align: left; vertical-align: top; border-top: 1px solid #E2E2E2; }

.invoice-generator__box .table tbody tr { border-bottom: 1px solid #E2E2E2; }

.invoice-generator__box .table tbody td { vertical-align: top; }

.invoice-generator__box .table tbody td:first-child { text-align: center; padding-top: 10px; }

.invoice-generator__box .table tbody td input { border: 1px solid #D0D6DD; background: #FFF; padding: 8px 12px; display: block; width: 100%; text-align: left; border-radius: 6px; }

.invoice-generator__box .table tbody td input.item_qty { text-align: center; }

.invoice-generator__box .table tbody td input.item_qty, .invoice-generator__box .table tbody td input.item_unit { min-width: 55px; max-width: 55px; }

.invoice-generator__box .table tbody td input.item_price, .invoice-generator__box .table tbody td input.item_total { min-width: 144px; max-width: 144px; position: relative; padding-left: 38px; text-align: right; }

.invoice-generator__box .table tbody td input.item_disc, .invoice-generator__box .table tbody td input.item_tax { min-width: 70px; max-width: 70px; padding-right: 38px; text-align: right; }

.invoice-generator__box .table tbody td textarea { border: 1px solid #D0D6DD; background: #FFF; padding: 8px 12px; display: block; width: 100%; min-width: 260px; text-align: left; border-radius: 6px; overflow: hidden; }

.invoice-generator__box .table tbody td.b_price, .invoice-generator__box .table tbody td.b_total { position: relative; }

.invoice-generator__box .table tbody td.b_price::before, .invoice-generator__box .table tbody td.b_total::before { content: 'Rp'; width: 30px; height: 34px; display: inline-block; position: absolute; left: 3px; top: 3px; z-index: 2; background: #EDF0F2; border-radius: 4px 0px 0px 4px; text-align: center; padding: 6px 0; font-family: "Inter", sans-serif; font-weight: 600; }

.invoice-generator__box .table tbody td.b_tax, .invoice-generator__box .table tbody td.b_disc { position: relative; }

.invoice-generator__box .table tbody td.b_tax::before, .invoice-generator__box .table tbody td.b_disc::before { content: '%'; width: 30px; height: 34px; display: inline-block; position: absolute; right: 3px; top: 3px; z-index: 2; background: #EDF0F2; border-radius: 0px 4px 4px 0px; text-align: center; padding: 7px 0; font-family: "Inter", sans-serif; font-weight: 600; }

.invoice-generator__box .table tbody td.b_del { padding-top: 8px; }

.invoice-generator__box .table .btn-del { display: inline-block; cursor: pointer; }

.invoice-generator__box .table .item--template { display: none; }

@media (max-width: 991px) { .invoice-generator__box .table thead th:nth-child(3), .invoice-generator__box .table thead th:nth-child(4), .invoice-generator__box .table thead th:nth-child(5), .invoice-generator__box .table thead th:nth-child(6), .invoice-generator__box .table thead th:nth-child(7), .invoice-generator__box .table thead th:nth-child(8) { display: none; }
  .invoice-generator__box .table tbody td:nth-child(3), .invoice-generator__box .table tbody td:nth-child(4), .invoice-generator__box .table tbody td:nth-child(5), .invoice-generator__box .table tbody td:nth-child(6), .invoice-generator__box .table tbody td:nth-child(7), .invoice-generator__box .table tbody td:nth-child(8) { display: none; }
  .invoice-generator__box .table tbody td:nth-child(2) { padding-top: 10px; }
  .invoice-generator__box .table tbody td .show-sm { margin-top: 10px; }
  .invoice-generator__box .table tbody td .show-sm .row { margin-bottom: 10px; }
  .invoice-generator__box .table tbody td .show-sm .row .col-6 input, .invoice-generator__box .table tbody td .show-sm .row .col-6 textarea, .invoice-generator__box .table tbody td .show-sm .row .col-12 input, .invoice-generator__box .table tbody td .show-sm .row .col-12 textarea { width: 100%; max-width: 100%; }
  .invoice-generator__box .table tbody td .show-sm .row .col-6 input::placeholder, .invoice-generator__box .table tbody td .show-sm .row .col-6 textarea::placeholder, .invoice-generator__box .table tbody td .show-sm .row .col-12 input::placeholder, .invoice-generator__box .table tbody td .show-sm .row .col-12 textarea::placeholder { text-align: left; }
  .invoice-generator__box .table tbody td .show-sm .row .col-6.b_price, .invoice-generator__box .table tbody td .show-sm .row .col-6.b_total, .invoice-generator__box .table tbody td .show-sm .row .col-12.b_price, .invoice-generator__box .table tbody td .show-sm .row .col-12.b_total { position: relative; }
  .invoice-generator__box .table tbody td .show-sm .row .col-6.b_price::before, .invoice-generator__box .table tbody td .show-sm .row .col-6.b_total::before, .invoice-generator__box .table tbody td .show-sm .row .col-12.b_price::before, .invoice-generator__box .table tbody td .show-sm .row .col-12.b_total::before { content: 'Rp'; width: 30px; height: 34px; display: inline-block; position: absolute; left: 14px; top: 2px; z-index: 2; background: #EDF0F2; border-radius: 4px 0px 0px 4px; text-align: center; padding: 6px 0; font-family: "Inter", sans-serif; font-weight: 600; }
  .invoice-generator__box .table tbody td .show-sm .row .col-6.b_tax, .invoice-generator__box .table tbody td .show-sm .row .col-6.b_disc, .invoice-generator__box .table tbody td .show-sm .row .col-12.b_tax, .invoice-generator__box .table tbody td .show-sm .row .col-12.b_disc { position: relative; }
  .invoice-generator__box .table tbody td .show-sm .row .col-6.b_tax::before, .invoice-generator__box .table tbody td .show-sm .row .col-6.b_disc::before, .invoice-generator__box .table tbody td .show-sm .row .col-12.b_tax::before, .invoice-generator__box .table tbody td .show-sm .row .col-12.b_disc::before { content: '%'; width: 30px; height: 34px; display: inline-block; position: absolute; right: 14px; top: 2px; z-index: 2; background: #EDF0F2; border-radius: 0px 4px 4px 0px; text-align: center; padding: 7px 0; font-family: "Inter", sans-serif; font-weight: 600; } }

.invoice-generator__box .sum-action { margin-bottom: 24px; }

.invoice-generator__box .list-total { display: block; padding: 0 30px 0 0; margin: 0 0 0 45px; color: #212121; font-size: 14px; line-height: 1.5em; align-items: center; justify-content: space-between; font-family: "Inter", sans-serif; font-weight: 400; }

.invoice-generator__box .list-total li { display: flex; margin-bottom: 16px; }

.invoice-generator__box .list-total li p { display: block; width: 100%; max-width: 30%; flex: 0 0 30%; margin: 0 0 0.5rem; }

.invoice-generator__box .list-total li p.number { text-align: right; max-width: 70%; flex: 0 0 70%; }

.invoice-generator__box .list-total .subtotal > p { font-family: "Inter", sans-serif; font-weight: 600; }

.invoice-generator__box .list-total .pemotongan p, .invoice-generator__box .list-total .deposito p { padding: 10px 0; }

.invoice-generator__box .list-total .pemotongan .form-group, .invoice-generator__box .list-total .deposito .form-group { margin-left: 25px; position: relative; }

.invoice-generator__box .list-total .pemotongan .form-group input, .invoice-generator__box .list-total .deposito .form-group input { text-align: right; }

.invoice-generator__box .list-total .pemotongan .form-group::before, .invoice-generator__box .list-total .deposito .form-group::before { content: 'Rp'; width: 30px; height: 34px; display: inline-block; position: absolute; left: 3px; top: 3px; z-index: 2; background: transparent; border-radius: 4px 0px 0px 4px; text-align: center; padding: 6px 0; font-family: "Inter", sans-serif; font-weight: 600; }

.invoice-generator__box .list-total .list-total__final { border-top: 1px solid #E2E2E2; padding: 0.5rem 0 0; font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

.invoice-generator__box .list-total .list-total__pyd { padding: 0.75rem 0 0; border-top: 1px solid #E2E2E2; display: none; }

.invoice-generator__box .list-total .list-total__pyd.show { display: flex; }

.invoice-generator__box .list-total .list-total__tyhb { font-size: 16px; line-height: 1.5em; display: none; font-family: "Inter", sans-serif; font-weight: 500; }

.invoice-generator__box .list-total .list-total__tyhb.show { display: flex; }

@media (max-width: 991px) { .invoice-generator__box .list-total { padding: 0; margin: 32px 0 0 0; } }

.invoice-generator.modal .modal-dialog { padding: 5rem 2rem 2rem; margin: 15vh auto; }

.invoice-generator.modal .modal-dialog .modal-content { padding: 20px; }

.invoice-generator.modal .modal-dialog .modal-content .form-section--action { padding-right: 0; padding-left: 0; }

.modal-preview .modal-dialog { width: 100%; max-width: 595pt; margin: 0 auto; }

.modal-preview .modal-content { padding: 0; }

.modal-preview .modal-content .close { top: -20px; right: -32px; filter: invert(1); }

.modal-agreement .modal-dialog { margin: 0 auto; max-width: 632px; }

.modal-agreement .modal-dialog .modal-content { padding: 32px; }

.modal-agreement .modal-dialog .modal-content .modal-body { text-align: center; }

.modal-agreement .modal-dialog .modal-content .modal-body .on-progress .icon { margin-bottom: 16px; }

.modal-agreement .modal-dialog .modal-content .modal-body .on-progress .icon img { height: 60px; width: auto; }

.modal-agreement .modal-dialog .modal-content .modal-body .on-load .icon, .modal-agreement .modal-dialog .modal-content .modal-body .on-stop .icon { margin-bottom: 16px; }

.modal-agreement .modal-dialog .modal-content .modal-body .on-load .icon img, .modal-agreement .modal-dialog .modal-content .modal-body .on-stop .icon img { height: 150px; width: auto; }

.modal-agreement .modal-dialog .modal-content .modal-body .on-load .label, .modal-agreement .modal-dialog .modal-content .modal-body .on-stop .label { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 20px; }

.modal-agreement .modal-dialog .modal-content .modal-body .on-load .form-mini label, .modal-agreement .modal-dialog .modal-content .modal-body .on-stop .form-mini label { font-family: "Inter", sans-serif; font-weight: 400; color: #232933; font-size: 14px; line-height: 20px; }

.modal-agreement .modal-dialog .modal-content .modal-body .on-load .form-mini .custom-checkbox, .modal-agreement .modal-dialog .modal-content .modal-body .on-stop .form-mini .custom-checkbox { text-align: left; }

.modal-agreement .modal-dialog .modal-content .modal-body .on-stop .label { margin-bottom: 16px; }

.modal-agreement .modal-dialog .modal-content .modal-body .on-stop .p { margin-bottom: 24px; }

.not-found { padding: 64px 0; color: #232933; text-align: left; }

.not-found p { font-size: 16px; line-height: 24px; }

.not-found h1 { font-family: "Inter", sans-serif; font-weight: 700; font-size: 40px; line-height: 56px; margin-bottom: 16px; color: #232933; }

.not-found .row .col-lg-6 { margin-left: 64px; padding-right: 105px; }

.not-found__title { font-family: "Inter", sans-serif; font-weight: 700; font-size: 40px; line-height: 56px; margin-bottom: 16px; color: #232933; }

.not-found__desc { margin-bottom: 24px; }

.not-found__desc p { margin-bottom: 16px; }

.not-found__desc p:last-child { margin-bottom: 0; }

.not-found__search form .form-group { position: relative; }

.not-found__search form .form-group label { display: block; font-family: "Inter", sans-serif; font-weight: 600; font-size: 14px; line-height: 20px; margin-bottom: 4px; }

.not-found__search form .form-group input.form-control { padding: 8px 8px 8px 44px; border-radius: 6px; }

.not-found__search form .form-group input.form-control::placeholder { color: #8B95A5; }

.not-found__search form .form-group::before { content: ''; width: 20px; height: 20px; display: inline-flex; background: url(../images/ic-search-2.svg) no-repeat center center; background-size: contain; position: absolute; left: 14px; bottom: 12px; opacity: 0.5; }

@media (max-width: 1025px) { .not-found .row .col-lg-6 { margin-left: 32px; padding-right: 12px; } }

@media (max-width: 991px) { .not-found { margin-top: 56px; padding: 32px 0; }
  .not-found h1 { font-size: 32px; line-height: 40px; margin-bottom: 16px; }
  .not-found__title { font-size: 32px; line-height: 40px; margin-bottom: 16px; }
  .not-found .row { flex-direction: column-reverse; }
  .not-found .row .col-lg-6 { margin-left: 0; padding-right: 15px; }
  .not-found__img { margin-top: 64px; }
  .not-found__img img { display: block; margin: 0 auto; } }

@media (max-width: 479px) { .not-found__search form .from-group:before { top: unset; bottom: 12px; } }

.cards-block { padding: 64px 0; }

.cards-block .card { height: 100%; }

.cards-block .card__img { margin-bottom: 24px; }

.cards-block .card__img img { height: 216px; width: 100%; object-fit: cover; border-radius: 4px; }

.cards-block .card__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 12px; }

.cards-block .card__desc { margin-bottom: 24px; }

@media (max-width: 991px) { .cards-block { padding: 32px 0; }
  .cards-block .card { height: calc(100% - 24px); margin-bottom: 24px; } }

/* Privacy */
.privacy-page { padding-top: 64px; padding-bottom: 64px; margin-top: 58px; }

.privacy-page .sidebar-menu .item { position: relative; border-bottom: 1px solid #E2E2E2; }

.privacy-page .sidebar-menu .item__link { display: block; color: #232933; padding: 12px 32px 12px 0; }

.privacy-page .sidebar-menu .item__link.active { color: #4B61DD; background: #FFF; font-family: "Inter", sans-serif; font-weight: 500; }

.privacy-page .sidebar-menu .item__link.active + .sub-item { display: block; }

.privacy-page .sidebar-menu .item__link:hover { color: #4B61DD; text-decoration: none; }

.privacy-page .sidebar-menu .item:first-child { border-top: 1px solid #E2E2E2; }

.privacy-page .sidebar-menu .sub-item { padding: 4px 0; display: none; }

.privacy-page .sidebar-menu .sub-item__link { display: block; position: relative; color: #232933; padding: 0 32px; margin-bottom: 20px; }

.privacy-page .sidebar-menu .sub-item__link:after { content: ""; display: none; position: absolute; width: 20px; height: 20px; right: 0; top: 2px; background: url(../images/ic-caret-right.svg); background-repeat: no-repeat; background-position: center; background-size: contain; }

.privacy-page .sidebar-menu .sub-item__link.active { color: #4B61DD; font-family: "Inter", sans-serif; font-weight: 500; }

.privacy-page .sidebar-menu .sub-item__link.active::after { display: block; }

.privacy-page .sidebar-menu .sub-item__link:hover { color: #4B61DD; text-decoration: none; }

.privacy-page__article { padding-left: 24px; }

.privacy-page__article article h1 { display: block; font-size: 40px; line-height: 56px; margin-bottom: 24px; }

.privacy-page__article article h2 { display: block; font-size: 24px; line-height: 32px; margin-bottom: 16px; }

.privacy-page__article article h3 { display: block; font-size: 18px; line-height: 27px; margin-bottom: 8px; }

.privacy-page__article article p { color: #2B2A35; margin-bottom: 24px; }

.privacy-page__article article p:last-child { margin-bottom: 0; }

@media (max-width: 991px) { .privacy-page { padding-top: 32px; padding-bottom: 32px; margin-top: 30px; }
  .privacy-page__sidebar { margin-bottom: 32px; margin-top: 32px; max-width: 100%; flex: 100%; }
  .privacy-page__article { padding-left: 0; }
  .privacy-page__article article h1 { font-size: 32px; line-height: 40px; } }

.masthead--promo { background: #FFF; height: 100vh; max-height: 635px; position: relative; padding-top: 0; }

.masthead--promo__video { display: flex; align-items: center; height: 100%; position: relative; }

.masthead--promo__video::after { z-index: 3; content: ""; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; background: linear-gradient(0deg, rgba(25, 25, 25, 0.2) 0%, rgba(25, 25, 25, 0.2) 100%); }

.masthead--promo__video .item__content { z-index: 4; position: relative; width: 100%; }

.masthead--promo__video .item__content .title { font-size: 40px; line-height: 56px; font-family: "Inter", sans-serif; font-weight: 700; color: #FFF; position: relative; }

.masthead--promo__video .item__content .title img { display: inline-block; width: auto; height: 50px; margin-right: 16px; }

.masthead--promo__video .item__content .title span { position: absolute; opacity: 0; left: 0; }

.masthead--promo__video .item__content .desc { color: #FFF; }

.masthead--promo__video .item__content .action { padding-top: 24px; margin-left: -8px; margin-right: -8px; }

.masthead--promo__video .item__content .action .btn { margin-left: 8px; margin-right: 8px; }

.masthead--promo__video .item__content .action .btn-blue-border, .masthead--promo__video .item__content .action .btn--border-primary { background: #FFF; }

.masthead--promo__video .item__content .action .btn-blue-border:hover, .masthead--promo__video .item__content .action .btn--border-primary:hover { color: #005FBF; }

.masthead--promo__video .item__content .action .link-down { padding-left: 16px; padding-right: 16px; }

.masthead--promo__video .item__content .action .link-down::before { display: none; }

.masthead--promo__video .item__content .action .link-down::after { content: ""; display: inline-block; vertical-align: middle; width: 20px; height: 20px; background: url(../images/ic-arrow-down-long.svg) no-repeat center; background-size: contain; margin-left: 8px; }

.masthead--promo__video .item__content .action .link-down:hover, .masthead--promo__video .item__content .action .link-down:focus, .masthead--promo__video .item__content .action .link-down.focus, .masthead--promo__video .item__content .action .link-down:not(:disabled):not(.disabled):active, .masthead--promo__video .item__content .action .link-down:not(:disabled):not(.disabled).active { color: #005FBF; text-decoration: none; }

.masthead--promo__video .item__content .action .link-down:hover::after, .masthead--promo__video .item__content .action .link-down:focus::after, .masthead--promo__video .item__content .action .link-down.focus::after, .masthead--promo__video .item__content .action .link-down:not(:disabled):not(.disabled):active::after, .masthead--promo__video .item__content .action .link-down:not(:disabled):not(.disabled).active::after { animation-duration: 2s; animation-iteration-count: infinite; animation-name: bounceDown; animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.masthead--promo__video .item__video { z-index: 2; display: block; width: 100%; height: 100%; top: 0; left: 0; position: absolute; object-fit: cover; object-position: center; }

.masthead--promo__video .item__bg { z-index: 1; position: relative; display: block; width: 100%; height: 100%; top: 0; left: 0; position: absolute; background-size: cover; background-position: center; }

.masthead--promo__slider { height: 100%; }

.masthead--promo__pagination { display: flex; justify-content: flex-start; width: 100%; position: absolute; bottom: 32px; left: calc(50% - 624px); text-align: left; max-width: 1248px; padding-left: 12px; padding-right: 12px; }

.masthead--promo__pagination .swiper-pagination { width: auto; }

.masthead--promo__pagination .swiper-pagination-bullet { width: 32px; height: 6px; border-radius: 3px; margin: 2px 6px; background: #FFF; opacity: .7; transition: all linear .3s; }

.masthead--promo__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active { background: #FFF; opacity: 1; transition: all linear .3s; }

.masthead--promo__navs { position: absolute; z-index: 2; width: 100%; left: 0; top: calc(50% - 28px); display: flex; justify-content: space-between; align-items: center; padding: 0 64px; }

.masthead--promo__navs .nav { width: 56px; height: 56px; display: flex; justify-content: center; align-items: center; background: #FFF; border-radius: 50px; padding: 16px; z-index: 3; cursor: pointer; }

.masthead--promo__navs .nav svg path { stroke: #D0D6DD; transition: all linear .3s; }

.masthead--promo__navs .nav:hover svg path { stroke: #4B61DD; transition: all linear .3s; }

.masthead--promo .items { height: 100%; }

.masthead--promo .items .item { height: 100%; }

.masthead--promo .items .item__bg { height: 100%; background: #0187d9; }

.masthead--promo .items .item__bg-img { width: 100%; height: 100%; object-fit: contain; object-position: center; }

.masthead--promo .items .item__bg-img-sm { display: none; }

@media (max-width: 1200px) { .masthead--promo__pagination { left: 0; max-width: 100%; padding: 0 32px; }
  .masthead--promo__pagination .swiper-pagination { width: auto; left: 0; top: 0; position: relative; }
  .masthead--promo__navs { left: 0; max-width: 100%; padding: 0 32px; } }

@media (max-width: 1024px) { .masthead--promo { margin-top: 0; } }

@media (max-width: 991px) { .masthead--promo { padding: 0; } }

@media (max-width: 479px) { .masthead--promo__video .item__content .action .btn { margin-left: 0; margin-right: 0; }
  .masthead--promo__pagination .swiper-pagination { width: 100%; text-align: center; }
  .masthead--promo__navs { display: none; }
  .masthead--promo .items .item__bg-img { display: none; }
  .masthead--promo .items .item__bg-img-sm { width: 100%; height: 100%; object-fit: contain; object-position: center; display: block; } }

.ribbon { padding: 0 0 64px 0; }

.ribbon__ribbon { display: flex; padding: 32px 0; background: url(../images/ribbon-center.png) repeat-x; background-size: contain; max-width: 626px; position: relative; z-index: 3; margin: -16.5px auto 0 auto; }

.ribbon__ribbon::before { content: ''; position: absolute; width: 93px; height: 100%; left: -92px; top: 0; background: url(../images/ribbon-left.png) no-repeat; background-size: contain; background-position: top right; }

.ribbon__ribbon::after { content: ''; position: absolute; width: 93px; height: 100%; right: -92px; top: 0; background: url(../images/ribbon-right.png) no-repeat; background-size: contain; background-position: top left; }

.ribbon__title { margin-bottom: 0; color: #FFF; display: inline-flex; text-align: center; font-family: "Inter", sans-serif; font-weight: 600; }

.ribbon .items { margin-top: 75px; }

.ribbon .items .item__box { height: 100%; background: #F1F5F9; border-radius: 16px; overflow: hidden; padding: 24px; }

.ribbon .items .item__img { margin: 0 auto 8px auto; display: flex; justify-content: center; }

.ribbon .items .item__img img { height: 100%; width: auto; max-height: 160px; }

.ribbon .items .item__title { font-size: 16px; line-height: 24px; margin-bottom: 10px; font-family: "Inter", sans-serif; font-weight: 600; }

@media (max-width: 991px) { .ribbon .items .item { height: calc(100% - 24px); margin-bottom: 24px; } }

@media (max-width: 768px) { .ribbon__ribbon { max-width: 433px; margin: -14px auto 0 auto; } }

@media (max-width: 479px) { .ribbon { padding-bottom: 32px; }
  .ribbon__title { font-size: 16px; line-height: 24px; }
  .ribbon__ribbon { padding: 18px 0 14px 0 !important; max-width: 172px !important; margin: -8px auto 0 auto !important; }
  .ribbon__ribbon::before { width: 70px !important; left: -69px !important; }
  .ribbon__ribbon::after { width: 70px !important; right: -69px !important; }
  .ribbon .items { margin-top: 29px; } }

.testimonial-gartner { padding: 64px 0; background: #F1F5F9; }

.testimonial-gartner__left { max-width: 430px; }

.testimonial-gartner__title { text-align: center; margin-bottom: 40px; }

.testimonial-gartner__list .title { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 24px; color: #232933; }

.testimonial-gartner__action { padding-top: 40px; margin-top: 40px; border-top: 1px solid #D0D6DD; }

.testimonial-gartner__action .title { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 24px; color: #232933; }

.testimonial-gartner__icons { display: flex; justify-content: flex-start; align-items: center; gap: 24px; margin-bottom: 32px; }

.testimonial-gartner__icons .icon { height: 32px; }

.testimonial-gartner__icons .icon img { height: 32px; width: auto; }

.testimonial-gartner__testimoni .items .item.swiper-slide { height: auto; }

.testimonial-gartner__testimoni .items .item__testimonial blockquote { margin: 0; }

.testimonial-gartner__testimoni .items .item__box { background: #FFF; border-radius: 16px; overflow: hidden; display: flex; align-items: stretch; height: 100%; }

.testimonial-gartner__testimoni .items .item__img { width: 230px; flex: 0 0 230px; }

.testimonial-gartner__testimoni .items .item__img img { height: 100%; object-fit: cover; }

.testimonial-gartner__testimoni .items .item__content { width: calc( 100% - 230px); flex: 0 0 calc( 100% - 230px); padding: 24px; display: flex; justify-content: space-between; flex-direction: column; }

.testimonial-gartner__testimoni .items .item__bottom { margin-top: auto; display: flex; justify-content: space-between; align-items: flex-end; position: relative; }

.testimonial-gartner__testimoni .items .item__left .name { font-family: "Inter", sans-serif; font-weight: 600; }

.testimonial-gartner__testimoni .items .item__left .job { font-size: 14px; line-height: 20px; }

.testimonial-gartner__testimoni .items .item__right { position: absolute; right: 0; bottom: 0; max-width: 125px; color: #232933; }

.testimonial-gartner__testimoni .items .item__right .bold { font-family: Permanent Marker; font-size: 36px; line-height: normal; }

.testimonial-gartner__testimoni .items .item__right .text { font-size: 14px; line-height: 20px; }

.testimonial-gartner__testimoni .items .item__right::before { content: ''; display: inline-flex; width: 60px; height: 60px; background: url(../images/arrow-testimonial.png) no-repeat center; background-size: contain; position: absolute; left: -65px; top: -15px; }

.testimonial-gartner__control { display: flex; justify-content: space-between; align-items: flex-start; }

.testimonial-gartner__pagination { display: flex; justify-content: flex-start; }

.testimonial-gartner__pagination .swiper-pagination { width: auto; position: relative; }

.testimonial-gartner__pagination .swiper-pagination-bullet { width: 32px; height: 6px; border-radius: 3px; margin: 2px 6px; background: #8B95A5; opacity: 0.5 !important; transition: all linear .3s; }

.testimonial-gartner__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active { background: #8B95A5; opacity: 1 !important; transition: all linear .3s; }

.testimonial-gartner__navs { display: flex; gap: 24px; }

.testimonial-gartner__navs .nav { width: 56px; height: 56px; display: flex; justify-content: center; align-items: center; background: #F8F9FB; border-radius: 50px; padding: 16px; cursor: pointer; }

.testimonial-gartner__navs .nav svg path { stroke: #D0D6DD; transition: all linear .3s; }

.testimonial-gartner__navs .nav:hover { background: #fff; transition: all linear .3s; }

.testimonial-gartner__navs .nav:hover svg path { stroke: #4B61DD; transition: all linear .3s; }

@media (max-width: 1024px) { .testimonial-gartner__testimoni .items .item__right { position: relative; }
  .testimonial-gartner__testimoni .items .item__right::before { width: 60px; height: 60px; left: -60px; top: -20px; } }

@media (max-width: 991px) { .testimonial-gartner__left { max-width: 100%; margin-bottom: 40px; }
  .testimonial-gartner__action { text-align: center; } }

@media (max-width: 479px) { .testimonial-gartner { padding: 32px 0; }
  .testimonial-gartner__title { font-size: 28px; line-height: 36px; margin-bottom: 24px; }
  .testimonial-gartner__title br { display: none; }
  .testimonial-gartner__testimoni .items { margin-top: 40px; }
  .testimonial-gartner__testimoni .items .item.swiper-slide { height: 100%; }
  .testimonial-gartner__testimoni .items .item__box { flex-direction: column; }
  .testimonial-gartner__testimoni .items .item__img { max-height: 343px; width: 100%; }
  .testimonial-gartner__testimoni .items .item__img img { height: 100%; width: 100%; }
  .testimonial-gartner__testimoni .items .item__content { flex: 0 0 100%; width: 100%; } }

.benefit-block { padding: 64px 0; }

.benefit-block__title { text-align: center; margin-bottom: 24px; }

.benefit-block__action { display: flex; flex-direction: column; align-items: center; gap: 24px; }

.benefit-block__action a { width: fit-content; }

.benefit-block .items { margin-top: 75px; }

.benefit-block .items .item__box { height: calc(100% - 24px); background: #F1F5F9; border-radius: 16px; overflow: hidden; padding: 24px; margin-bottom: 24px; }

.benefit-block .items .item__img { margin: 0 auto 8px auto; display: flex; justify-content: center; }

.benefit-block .items .item__img img { height: 100%; width: auto; max-height: 160px; }

.benefit-block .items .item__title { font-size: 16px; line-height: 24px; margin-bottom: 10px; font-family: "Inter", sans-serif; font-weight: 600; }

@media (max-width: 479px) { .benefit-block { padding: 32px 0; }
  .benefit-block__title br { display: none; } }

.sticky-button { padding: 12px; position: fixed; background: #FFF; z-index: 11; left: 50%; bottom: 40px; transform: translateX(-50%); border-radius: 30px; box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05); }

.sticky-button a { margin-bottom: 0 !important; border-radius: 30px; }

.sitemap { padding-top: 81px; padding-bottom: 160px; }

.sitemap__title { text-align: center; padding: 80px 0 0; margin-bottom: 64px; font-size: 48px; line-height: 56px; font-family: "Inter", sans-serif; font-weight: 600; }

.sitemap__label { color: #2B2A35; font-size: 20px; line-height: 32px; margin-bottom: 0; font-family: "Inter", sans-serif; font-weight: 600; }

.sitemap__list { display: block; -webkit-column-count: 3; -moz-column-count: 3; column-count: 3; margin-bottom: 32px; }

.sitemap__list li { display: block; padding: 6px 0; }

.sitemap__list li a { display: block; color: #1357FF; text-decoration: none; }

.sitemap__list li a:hover { text-decoration: underline; }

.sitemap hr { margin: 48px 0; }

@media (max-width: 991px) { __list { -webkit-column-count: 2; -moz-column-count: 2; column-count: 2; } }

.toc-container { display: flex; flex-wrap: wrap; justify-content: center; }

.toc-container h1, .toc-container h2, .toc-container h3 { flex: 0 0 100%; width: 100%; text-align: center; }

.toc-container .toc { max-width: 70%; flex: 0 0 100%; display: inline-flex; justify-content: center; gap: 10px; flex-wrap: wrap; padding: 48px 0; }

.toc-container .toc li a { display: inline-flex; padding: 10px; border: 1px solid #E2E2E2; }

.toc-container .toc li a:hover { background: #4B61DD; color: #FFF; border-color: #4B61DD; }

@media (max-width: 479px) { .toc-container .toc { max-width: 100%; } }

.toc_section { padding: 0 0 48px 0; border-bottom: 1px solid #E2E2E2; margin: 0 auto 48px auto; max-width: 70%; width: 100%; }

.toc_section ul { display: flex; flex-wrap: wrap; gap: 16px; justify-content: flex-start; align-items: flex-start; width: 100%; max-width: 100%; }

.toc_section ul li strong { font-size: 26px; line-height: 150%; margin-bottom: 18px; display: block; }

.toc_section ul li .content__list { display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: flex-start; flex: 0 0 100%; width: 100%; }

.toc_section ul li .content__list li { display: inline-flex; flex: 0 0 100%; width: 100%; max-width: calc(50% - 8px); }

.toc_section ul li .content__list li a { color: #4B61DD; }

.toc_section ul li .content__list li a:hover { color: #4B61DD; text-decoration: underline; }

@media (max-width: 991px) { .toc_section { max-width: 100%; } }

@media (max-width: 479px) { .toc_section ul li .content__list li { max-width: 100%; } }

.microsite { padding-top: 64px; }

.microsite__banner { margin: 0 0 24px; border-radius: 32px; }

.microsite__banner img { border-radius: 32px; display: block; width: 100%; height: auto; }

.microsite__banner .swiper-wrapper { padding-bottom: 0 !important; }

.microsite__banner .swiper-pagination { text-align: right; padding: 12px 28px; }

.microsite__banner .swiper-pagination .swiper-pagination-bullet { width: 44px; height: 8px; background: #8B95A5; opacity: 0.4; }

.microsite__banner .swiper-pagination .swiper-pagination-bullet-active { opacity: 1; }

.microsite__head { padding-bottom: 40px; }

.microsite__head .box { display: block; border-radius: 32px; background: #FFF; border: 1px solid #D0D6DD; padding: 24px; margin-bottom: 24px; box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.05), 0px 10px 30px -3px rgba(0, 0, 0, 0.05); }

.microsite__head .box__icon { width: 100%; max-width: 32px; flex: 0 0 32px; }

.microsite__head .box__title { width: 100%; padding: 0 8px; color: #2B2A35; font-weight: 600; }

.microsite__head .box__action { white-space: nowrap; }

.microsite__head .box__head { display: flex; align-items: center; margin: 0 0 16px; }

.microsite__head .box__content { font-size: 16px; line-height: 24px; color: #232933; margin: 0 0 24px; }

.microsite__head .box--spotify { background: #F7F4FF; }

.microsite__head .box--spotify .box__spotify iframe { width: 100%; height: 80px; display: block; margin: 0 0 16px; }

.microsite__head .box--spotify .box__spotify iframe:last-child { margin-bottom: 0; }

.microsite__head .box--yt .box__video { display: block; position: relative; }

.microsite__head .box--yt .box__video .btn-play { display: flex; flex-direction: column; justify-content: center; align-items: center; position: absolute; top: calc(50% - 30px); left: calc(50% - 30px); width: 60px; height: 60px; border-radius: 30px; background: #4b61dd; color: #fff; text-align: center; padding: 0; }

.microsite__head .box--yt .box__video .btn-play::before { display: none; }

.microsite__head .box--yt .box__video .btn-play .icon { width: 16px; height: 16px; background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='play-video'%3E%3Cpath id='Vector' fill-rule='evenodd' clip-rule='evenodd' d='M10.7723 4.66807L10.7455 4.65254C9.56002 3.93978 8.63574 3.38464 7.88333 3.02206C7.12863 2.65947 6.46254 2.44595 5.80714 2.51117C5.29865 2.56186 4.80642 2.72112 4.36268 2.97855C3.91893 3.23598 3.53368 3.58576 3.23214 4.005C2.84333 4.54461 2.68445 5.23718 2.60883 6.08192C2.53244 6.92434 2.53244 8.01598 2.53244 9.41587V12.2498C2.53244 13.6497 2.53244 14.7413 2.60883 15.5837C2.68521 16.4285 2.84333 17.121 3.23214 17.6607C3.5336 18.0798 3.91873 18.4295 4.36233 18.687C4.80594 18.9444 5.29802 19.1037 5.80638 19.1545C6.46178 19.2197 7.12863 19.007 7.8841 18.6428C8.63574 18.281 9.56002 17.7259 10.7455 17.0131L10.7723 16.9976L13.0792 15.6125L13.1059 15.5969V15.5962C14.2555 14.9051 15.1531 14.3663 15.81 13.8842C16.4707 13.4004 16.9642 12.9214 17.2247 12.3189C17.4276 11.8505 17.5324 11.3443 17.5324 10.8324C17.5324 10.3206 17.4276 9.8144 17.2247 9.34599C16.965 8.74504 16.4707 8.26521 15.81 7.78073C15.1531 7.29935 14.2555 6.75974 13.1059 6.0695L13.0792 6.05397L10.7723 4.66807Z' fill='%23FFFFFF'/%3E%3C/g%3E%3C/svg%3E"); background-repeat: no-repeat; background-size: contain; background-position: center; }

.microsite__head .box--yt .box__video .btn-play span { font-size: 10px; line-height: 16px; }

.microsite__head .box--yt .box__video img { position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; border-radius: 16px; object-fit: cover; object-position: center; }

.microsite__head .box--yt .box__video a { display: block; width: 100%; position: relative; padding-top: 53%; }

.microsite__head .box--ig { background: #FDECEE; }

.microsite__head .box--ig .box__imgs { display: flex; margin-left: -8px; margin-right: -8px; }

.microsite__head .box--ig .box__imgs .item { position: relative; display: block; padding-left: 8px; padding-right: 8px; width: 100%; max-width: 33.33%; flex: 0 0 33.33%; }

.microsite__head .box--ig .box__imgs .item a { position: relative; display: block; width: 100%; padding-top: 100%; }

.microsite__head .box--ig .box__imgs .item img { display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; object-fit: cover; object-position: center; border-radius: 16px; }

.microsite__head .box--url { display: flex; align-items: center; font-weight: 600; color: #2B2A35; text-decoration: none; }

.microsite__head .box--url .icon { width: 100%; max-width: 32px; flex: 0 0 32px; }

.microsite__head .box--url .icon img { width: 32px; height: 32px; object-fit: contain; object-position: center; }

.microsite__head .box--url .text { display: block; padding-left: 8px; width: 100%; max-width: calc(100% - 32px); flex: 0 0 calc(100% - 32px); }

.microsite__head .box--url:hover { color: #005FBF; text-decoration: none; }

.microsite__head .box--bg { background-color: #ECF5FF; position: relative; overflow: hidden; }

.microsite__head .box--bg::before { content: ""; position: absolute; top: -25px; right: -35px; width: 135px; height: 135px; background: #d7f6ff; border-radius: 50%; z-index: 1; }

.microsite__head .box--bg::after { content: ""; position: absolute; bottom: 0; right: 0; width: 235px; height: 55px; background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="178" height="57" viewBox="0 0 178 57" fill="none"><path opacity="0.4" d="M164.537 142.409C201.063 122.452 214.494 76.6635 194.537 40.1378C174.58 3.61208 128.792 -9.81947 92.2664 10.1376L40.114 38.6327C3.5883 58.5898 -9.8433 104.378 10.1137 140.904C30.0708 177.43 75.8591 190.861 112.385 170.904L164.537 142.409Z" fill="%2360A5FA"/></svg>'); background-size: cover; z-index: 1; }

.microsite__head .box--bg .icon, .microsite__head .box--bg .text { position: relative; z-index: 2; }

.microsite__head .box--bg-red { background-color: #FDECEE; }

.microsite__head .box--bg-blue { background-color: #F3F8FF; }

.microsite__sidebar { position: relative; max-width: 365px; flex: 0 0 365px; }

.microsite__sidebar .sidebar-sticky { position: sticky; top: 24px; }

.microsite__sidebar .sidebar-comp { display: flex; flex-wrap: wrap; gap: 12px; margin-bottom: 12px; justify-content: flex-start; align-items: center; }

.microsite__sidebar .sidebar-comp .logo { flex: 0 0 100%; max-width: 64px; }

.microsite__sidebar .sidebar-comp .logo img { display: block; height: 64px; width: 64px; object-fit: contain; object-position: center; }

.microsite__sidebar .sidebar-comp .info { flex: 0 0 100%; max-width: calc(100% - (64px + 12px)); }

.microsite__sidebar .sidebar-comp .info h1 { font-size: 32px; line-height: 48px; margin-bottom: 0; }

.microsite__sidebar .sidebar-comp .info .subtitle { display: block; font-size: 16px; line-height: 24px; font-weight: 600; color: #2B2A35; margin: 0; }

.microsite__sidebar p { margin: 0 0 12px; }

.microsite__sidebar .location { display: flex; color: #232933; transition: all .3s ease; }

.microsite__sidebar .location::before { content: ""; display: block; width: 24px; height: 24px; background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g id="location"><g id="Vector"><path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" stroke="%23626B79" stroke-width="1.5"/><path d="M12 13.43C13.7231 13.43 15.12 12.0331 15.12 10.31C15.12 8.58687 13.7231 7.19 12 7.19C10.2768 7.19 8.87995 8.58687 8.87995 10.31C8.87995 12.0331 10.2768 13.43 12 13.43Z" stroke="%23626B79" stroke-width="1.5"/></g></g></svg>'); background-repeat: no-repeat; background-size: contain; background-position: center; margin-right: 8px; transition: all .3s ease; }

.microsite__sidebar .location:hover { color: #005FBF; }

.microsite__sidebar .location:hover::before { background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g id="location"><g id="Vector"><path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" stroke="%234B61DD" stroke-width="1.5"/><path d="M12 13.43C13.7231 13.43 15.12 12.0331 15.12 10.31C15.12 8.58687 13.7231 7.19 12 7.19C10.2768 7.19 8.87995 8.58687 8.87995 10.31C8.87995 12.0331 10.2768 13.43 12 13.43Z" stroke="%234B61DD" stroke-width="1.5"/></g></g></svg>'); }

.microsite__main { max-width: calc(100% - 365px); flex: 0 0 calc(100% - 365px); padding-bottom: 64px; padding-left: 24px; }

.microsite__product .title { font-weight: 600; font-size: 20px; line-height: 28px; margin: 0 0 24px; }

.microsite__product .item { display: block; width: 100%; max-width: 50%; flex: 0 0 50%; border-radius: 24px; padding-left: 12px; padding-right: 12px; margin-bottom: 24px; }

.microsite__product .item__title, .microsite__product .item__desc { position: relative; z-index: 2; color: #FFF; margin: 0 0 16px; }

.microsite__product .item__bg { z-index: 1; position: absolute; top: 0; left: 0; width: 100%; height: 100%; border-radius: 32px; }

.microsite__product .item__bg.bg-talenta { background: linear-gradient(340deg, #BF000A 9.7%, #F22929 47.12%, #FB5B58 84.53%); }

.microsite__product .item__bg.bg-jurnal { background: linear-gradient(340deg, #0087D9 9.7%, #00A8FD 47.12%, #66CFFF 84.53%); }

.microsite__product .item__bg.bg-qontak { background: linear-gradient(340deg, #2E65EC 9.7%, #2979FF 47.12%, #26A9FF 84.53%); }

.microsite__product .item__bg.bg-klikpajak { background: linear-gradient(146deg, #FF7C1F 5.21%, #FF9100 47.89%, #FFC333 90.57%); }

.microsite__product .item__bg.bg-flex { background: linear-gradient(340deg, #643ECC 9.7%, #7C4DFF 47.12%, #A380FF 84.53%); }

.microsite__product .item__bg.bg-expense { background: linear-gradient(340deg, #01579B 24.67%, #075FA2 38.89%, #1978B7 62.08%, #2F95D1 84.53%); }

.microsite__product .item__bg img { display: block; width: 100%; height: 100%; object-fit: cover; object-position: bottom right; border-radius: 32px; }

.microsite__product .item__wrap { display: block; text-decoration: none; border-radius: 32px; overflow: hidden; padding: 24px; min-height: 300px; position: relative; }

.microsite__product .item__wrap:hover { text-decoration: none; }

.microsite__product .items { display: flex; flex-wrap: wrap; margin-left: -12px; margin-right: -12px; }

.microsite__footer { padding: 32px 24px; text-align: center; color: #626B79; }

@media screen and (max-width: 1024px) { .microsite { padding-top: 32px; }
  .microsite__head .box__title { max-width: calc(100% - 32px); flex: 0 0 calc(100% - 32px); }
  .microsite__head .box__action { max-width: 100%; flex: 0 0 100%; padding-top: 16px; }
  .microsite__head .box__action .btn { display: block; }
  .microsite__head .box__head { flex-wrap: wrap; }
  .microsite__sidebar { max-width: 100%; flex: 0 0 100%; padding-left: 16px; padding-right: 16px; padding-bottom: 24px; }
  .microsite__sidebar .sidebar-sticky { position: relative; top: auto; }
  .microsite__main { max-width: 100%; flex: 0 0 100%; padding-left: 16px; padding-right: 16px; } }

@media screen and (max-width: 580px) { .microsite__product .item { max-width: 100%; flex: 0 0 100%; } }

@media screen and (max-width: 336px) { .microsite__sidebar .sidebar-comp .info h1 { font-size: 28px; line-height: 36px; }
  .microsite__sidebar .sidebar-comp .info .subtitle { font-size: 14px; line-height: 20px; } }

.propose { padding: 64px 0; }

.propose.bg-gray { background: #F1F5F9; }

.propose.propose--alt .item__title { font-size: 16px; line-height: 24px; margin-bottom: 8px; font-family: "Inter", sans-serif; font-weight: 600; }

.propose__title { text-align: center; }

.propose__desc { text-align: center; margin-bottom: 24px; }

.propose .item { border: 1px solid #D0D6DD; border-radius: 4px; padding: 24px; height: calc(100% - 24px); background: #FFF; margin-bottom: 24px; }

.propose .item__icon { margin-bottom: 8px; }

.propose .item__icon img { height: 52px; width: auto; }

.propose .item__title { font-size: 20px; line-height: 28px; margin-bottom: 8px; font-family: "Inter", sans-serif; font-weight: 600; }

.propose .item__desc { color: #232933; }

.propose .item__desc p { margin-bottom: 0; }

.propose .item__desc strong { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; margin-top: 8px; display: inline-block; }

.propose.in-app { padding: 40px 0; }

.propose.in-app .propose__title { font-size: 20px; line-height: 28px; }

.propose.in-app .item__wrap .item { border: 0; padding: 0; }

.propose.in-app .item__wrap .item__icon img { height: 72px; width: 86px; object-fit: contain; }

.propose.in-app .item__wrap .item__title { font-size: 16px; line-height: 24px; margin-bottom: 8px; }

.propose.in-app .item__wrap .item__desc p { color: #626B79; }

@media (max-width: 991px) { .propose { padding: 32px 0; }
  .propose .item { margin-bottom: 24px; height: calc(100% - 24px); } }

.accr-steps { padding: 64px 0; }

.accr-steps .section__meta { font-family: "Inter", sans-serif; font-weight: 600; text-align: center; }

.accr-steps .section__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 32px; line-height: 48px; margin-bottom: 24px; text-align: center; }

.accr-steps .section__action { margin-bottom: 24px; text-align: center; }

.accr-steps__img { display: none; }

.accr-steps__img.active { display: block; }

.accr-steps__accr .card { margin-bottom: 18px; border: 1px solid #D0D6DD; overflow: hidden; border-radius: 4px; }

.accr-steps__accr .card.open { box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05); }

.accr-steps__accr .card.open .card__header { padding-bottom: 8px; }

.accr-steps__accr .card.open .card__title .badge { background: #651FFF; }

.accr-steps__accr .card__header { padding: 24px; border-bottom: 0; }

.accr-steps__accr .card__header::after { display: none; }

.accr-steps__accr .card__title .badge { background: #8B95A5; border-radius: 12px; color: #FFF; font-size: 14px; line-height: 20px; padding: 0 6px; display: inline-block; margin: 0 0 8px 0; font-family: "Inter", sans-serif; font-weight: 400; }

.accr-steps__accr .card__title .title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 16px; line-height: 24px; margin-bottom: 0; }

.accr-steps__accr .card__body { padding: 0 24px 24px 24px; margin: 0; }

.accr-steps__accr .card__body p { margin-bottom: 16px; }

.accr-steps__accr .card__body p:last-child { margin-bottom: 0; }

@media (max-width: 991px) { .accr-steps { padding: 32px 0; }
  .accr-steps .section__title { font-size: 28px; line-height: 36px; }
  .accr-steps .section__title br { display: none; } }

.banner-block { padding: 64px 0; }

.banner-block__wrapper { padding: 24px; background: #EDFAFF; border-radius: 8px; }

.banner-block__meta { font-family: "Inter", sans-serif; font-weight: 600; }

.banner-block__meta span.badge { margin-left: 4px; background: #009BDE; }

.banner-block__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 32px; line-height: 48px; margin-bottom: 8px; padding-right: 30px; }

.banner-block__desc { padding-right: 30px; }

.banner-block__desc p:last-child { margin-bottom: 0px; }

.banner-block__action { margin-top: 24px; }

.banner-block__action a { margin-right: 16px; }

.banner-block__action a:last-child { margin-right: 0; }

.banner-block__img { text-align: center; }

.banner-block__img img { display: inline-block; margin: 0 auto; }

.banner-block.banner-block--alt .banner-block__wrapper { padding: 32px 64px; background: #F1F5F9; border-radius: 8px; }

@media (max-width: 991px) { .banner-block { padding: 32px 0; }
  .banner-block__title { font-size: 28px; line-height: 36px; padding-right: 0; }
  .banner-block__desc { padding-right: 0; }
  .banner-block__action a { margin-right: 0; margin-bottom: 16px; }
  .banner-block__action a:last-child { margin-bottom: 0; }
  .banner-block__img { margin-top: 24px; } }

@media (max-width: 479px) { .banner-block__wrapper { margin-left: 0; margin-right: 0; padding: 24px 12px; }
  .banner-block.banner-block--alt .banner-block__wrapper { padding: 24px 12px; } }

.testi-compact { padding: 64px 0; }

.testi-compact .section-head__title { margin-bottom: 24px; }

.testi-compact__wrapper { background: #F1F5F9; padding: 32px; border-radius: 4px; margin: 0 48px; }

.testi-compact__testi { margin: 0 0 16px 0; }

.testi-compact__info { display: flex; align-items: center; width: 100%; }

.testi-compact__info-left, .testi-compact__info-right { width: 50%; }

.testi-compact__info-right { text-align: right; }

.testi-compact__img { display: inline-block; width: 48px; height: 48px; margin-right: 16px; vertical-align: middle; overflow: hidden; }

.testi-compact__img img { height: 48px; width: auto; border-radius: 50%; object-fit: cover; }

.testi-compact__logo { display: inline-block; vertical-align: middle; }

.testi-compact__logo img { height: 48px; width: auto; }

.testi-compact__contact { display: inline-block; width: calc(100% - 69px); vertical-align: middle; }

.testi-compact__name { font-family: "Inter", sans-serif; font-weight: 600; }

@media (max-width: 991px) { .testi-compact { padding: 32px 0; }
  .testi-compact__wrapper { margin: 0; } }

@media (max-width: 479px) { .testi-compact__info { flex-direction: column; }
  .testi-compact__info-left { width: 100%; flex: 0 0 100%; margin-bottom: 24px; }
  .testi-compact__info-right { width: 100%; flex: 0 0 100%; text-align: left; } }

.value-props { padding: 64px 0 0 0; background: #F1F5F9; }

.value-props__list .row { padding: 64px 0; }

.value-props__list .row .title { font-size: 20px; line-height: 28px; margin-bottom: 16px; }

.value-props__list .row .desc { margin-bottom: 32px; }

.value-props__list .row .action a { margin-right: 16px; }

.value-props__list .row .action a:last-child { margin-right: 0; }

.value-props__list .row:nth-child(even) { flex-direction: row-reverse; }

.value-props__title { text-align: center; }

@media (max-width: 991px) { .value-props { padding: 32px 0 0 0; }
  .value-props__list .row { padding: 32px 0; flex-direction: column-reverse; }
  .value-props__list .row:nth-child(even) { flex-direction: column-reverse; }
  .value-props__list .row .img img { display: block; margin: 0 auto; } }

.compliance { padding: 64px 0; }

.compliance__title { text-align: center; margin-bottom: 10px; }

.compliance__desc { text-align: center; margin-bottom: 40px; }

.compliance .item { padding: 50px 32px; text-align: center; background: #F1F5F9; backdrop-filter: blur(7.44421px); border-radius: 8px; height: 100%; margin-bottom: 24px; }

.compliance .item__img { margin-bottom: 29px; }

.compliance .item__img img { height: 120px; width: auto; }

@media (max-width: 991px) { .compliance { padding: 32px 0; }
  .compliance .item { margin-bottom: 24px; height: calc(100% - 24px); } }

.video-block { padding: 64px 0; text-align: center; }

.video-block__title { margin-bottom: 8px; }

.video-block__frame { margin-top: 24px; max-height: 376px; }

.video-block__frame .media img { max-height: 376px; object-fit: cover; width: 100%; height: 100%; margin: 0 auto; border-radius: 8px; }

.video-block .item { border: 1px solid #D0D6DD; border-radius: 4px; padding: 24px; height: 100%; text-align: left; }

.video-block .item__wrap { margin-top: 24px; }

.video-block .item__icon { margin-bottom: 8px; }

.video-block .item__icon img { height: 52px; width: auto; }

.video-block .item__title { font-size: 20px; line-height: 28px; margin-bottom: 8px; font-family: "Inter", sans-serif; font-weight: 600; }

.video-block .item__desc { color: #232933; }

.video-block .item__desc p { margin-bottom: 0; }

.video-block .item__desc strong { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; margin-top: 8px; display: inline-block; }

@media (max-width: 991px) { .video-block .item { margin-bottom: 24px; height: calc(100% - 24px); } }

@media (max-width: 479px) { .video-block { padding: 32px 0; }
  .video-block__title br { display: none; } }

.price-block { padding: 64px 0; }

.price-block__title { margin-bottom: 8px; }

.price-block__desc { margin-bottom: 24px; }

.price-block__left { padding-right: 60px; }

.price-block__cards .card { border: 1px solid #D0D6DD; border-radius: 6px; overflow: hidden; background: #FFF; height: calc(100% - 24px); margin-bottom: 24px; }

.price-block__cards .card.active { border-color: #009BDE; box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12); }

.price-block__cards .card__header { padding: 24px; }

.price-block__cards .card__header .discount__badge { display: inline-block; border-radius: 24px; border: 1px solid #4B61DD; background: #FFF; color: #4B61DD; font-size: 14px; line-height: 1; margin-bottom: 8px; padding: 4px 12px; }

.price-block__cards .card__header .package { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 8px; }

.price-block__cards .card__header .strike { color: #626B79; font-size: 14px; line-height: 20px; margin-bottom: 8px; text-decoration: line-through; }

.price-block__cards .card__header .pricing { display: flex; align-items: center; justify-content: flex-start; gap: 8px; padding: 0; margin: 0; }

.price-block__cards .card__header .pricing .price { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 32px; }

.price-block__cards .card__header .pricing .tenor { font-size: 14px; line-height: 20px; color: #626B79; }

.price-block__cards .card__header .action { margin-top: 24px; }

.price-block__cards .card__header .action a { width: 100%; display: block; margin-bottom: 16px; }

.price-block__cards .card__header .action a:last-child { margin-bottom: 0; }

.price-block__cards .card__body { padding: 0 24px 24px; }

@media (max-width: 479px) { .price-block { padding: 32px 0; }
  .price-block__title br { display: none; }
  .price-block__left { padding-right: 0; } }

.masthead-f__slider .items { position: relative; padding-bottom: 0; }

.masthead-f__slider .item { position: relative; min-height: calc(100vh - 117px); background: #F1F5F9; align-items: center; display: flex; }

.masthead-f__slider .item__logo { width: auto; height: 37px; margin-bottom: 8px; }

.masthead-f__slider .item__title { color: #FFF; font-size: 40px; line-height: 56px; max-width: 600px; margin: 0 0 24px; font-family: "Inter", sans-serif; font-weight: 700; margin-bottom: 8px; }

.masthead-f__slider .item__desc { max-width: 600px; color: #FFF; margin-bottom: 8px; }

.masthead-f__slider .item__action { max-width: 600px; padding-top: 16px; }

.masthead-f__slider .item__action a { margin-right: 16px; }

.masthead-f__slider .item__action a:last-child { margin-right: 0; }

.masthead-f__slider .item__bg { position: absolute; width: 100%; height: 100%; top: 0; left: 0; overflow: hidden; }

.masthead-f__slider .item__bg .item__video { z-index: 1; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; object-fit: cover; object-position: center; }

.masthead-f__slider .item__bg::before { z-index: 2; content: ""; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; background: linear-gradient(250deg, rgba(30, 127, 229, 0.06) 25.67%, #012f5f 97.38%); }

.masthead-f__slider .item__bg-img { object-fit: cover; height: 100%; width: 100%; object-position: center center; }

.masthead-f__slider .item__wrap { position: relative; z-index: 2; }

.masthead-f__slider .swiper-pagination { bottom: 32px !important; left: calc(50% - 624px) !important; text-align: left !important; max-width: 1248px !important; padding-left: 12px !important; padding-right: 12px !important; }

.masthead-f__slider .swiper-pagination .swiper-pagination-bullet-active { background: #FFF; }

.masthead-f__acv { padding-top: 64px; padding-bottom: 64px; background: #F1F5F9; }

.masthead-f__acv-stars { display: flex; max-width: 120px; margin: 0 auto 8px; }

.masthead-f__acv-stars .star { position: relative; display: block; width: 20px; height: 20px; margin: 0 2px; }

.masthead-f__acv-stars .star::before { content: ""; z-index: 1; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; background: url(../images/ic-star-w.svg) no-repeat; background-position: left; background-size: cover; }

.masthead-f__acv-stars .star span { z-index: 2; display: block; position: absolute; width: 100%; height: 100%; top: 0; left: 0; background: url(../images/ic-star.svg) no-repeat; background-position: left; background-size: cover; }

.masthead-f__acv-label { color: #232933; font-size: 16px; line-height: 24px; font-weight: 600; display: block; margin: 0 0 8px; text-align: center; }

.masthead-f__acv-logos .items { padding: 0; }

.masthead-f__acv-logos .items { display: flex; flex-wrap: wrap; justify-content: center; margin: 0 0 24px; }

.masthead-f__acv-logos .item { padding: 0 8px; width: auto; }

.masthead-f__acv-logos img { display: block; width: auto; height: 40px; }

.masthead-f__acv-testimonial { max-width: 552px; margin: 0 auto; }

.masthead-f__acv-testimonial .swiper-wrapper { align-items: stretch; }

.masthead-f__acv-testimonial .item { height: auto; color: #232933; background: #FFF; border-radius: 16px; }

.masthead-f__acv-testimonial .item__quote { display: block; width: 100%; margin: 0; padding-bottom: 32px; max-width: 600px; }

.masthead-f__acv-testimonial .item__name { font-weight: 600; }

.masthead-f__acv-testimonial .item__author { display: block; width: 100%; max-width: 60%; flex: 0 0 60%; margin-top: auto; }

.masthead-f__acv-testimonial .item__logo { display: block; width: 100%; max-width: 40%; flex: 0 0 40%; text-align: right; margin-top: auto; }

.masthead-f__acv-testimonial .item__wrap { padding: 32px; display: flex; height: 100%; flex-wrap: wrap; }

.masthead-f.heading-lg .masthead-f__slider .item__title { font-size: 40px; line-height: 56px; max-width: 600px; font-family: "Inter", sans-serif; font-weight: 700; margin-bottom: 8px; }

@media (max-width: 1200px) and (min-width: 768px) { .masthead-f__slider .swiper-pagination { left: 0; max-width: 100%; text-align: center; } }

@media (max-width: 991px) { .masthead-f__slider .item { min-height: 460px; }
  .masthead-f__slider .item__title { font-size: 32px; line-height: 40px; }
  .masthead-f__slider .swiper-pagination { left: 0; max-width: 100%; text-align: center; bottom: 16px; }
  .masthead-f__acv { padding-left: 16px; padding-right: 16px; padding-top: 32px; padding-bottom: 32px; }
  .masthead-f__acv-testimonial .item__author { max-width: 100%; flex: 0 0 100%; }
  .masthead-f__acv-testimonial .item__logo { display: none; }
  .masthead-f__acv-testimonial .item__wrap { padding: 24px; }
  .masthead-f.heading-lg .masthead-f__slider .item__title { font-size: 32px; line-height: 40px; max-width: 900px; }
  .masthead-f .item__action a { width: auto; display: inline-block; } }

@media (max-width: 479px) { .masthead-f__slider { display: flex; }
  .masthead-f__slider .item { min-height: calc(100vh - 56px); display: flex; align-items: flex-end; }
  .masthead-f__slider .item__bg-img { object-position: 65% center; }
  .masthead-f__slider .item__wrap { padding-bottom: 32px; }
  .masthead-f__slider .item__action a { width: 100%; display: block; } }

.clients { padding: 64px 0; background: #F2F4F7; text-align: center; }

.clients__title { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 56px; }

.clients.white, .clients.bg-white { background: #FFF; }

.clients.white .logo-marquee, .clients.bg-white .logo-marquee { padding-top: 64px; padding-bottom: 64px; }

.clients.white .logo-marquee__title, .clients.bg-white .logo-marquee__title { text-align: center; margin: 0 0 32px; }

.clients.white .logo-marquee__desc, .clients.bg-white .logo-marquee__desc { margin-bottom: 24px; }

.clients.white .logo-marquee__desc p, .clients.bg-white .logo-marquee__desc p { margin-bottom: 16px; }

.clients.white .logo-marquee__desc p:last-child, .clients.bg-white .logo-marquee__desc p:last-child { margin-bottom: 0; }

.clients.white .logo-marquee__group, .clients.bg-white .logo-marquee__group { display: flex; overflow: hidden; user-select: none; position: relative; }

.clients.white .logo-marquee__group::before, .clients.white .logo-marquee__group::after, .clients.bg-white .logo-marquee__group::before, .clients.bg-white .logo-marquee__group::after { content: ""; z-index: 2; display: block; width: 64px; height: 100%; position: absolute; top: 0; left: 0; background: linear-gradient(89.8deg, #FFF 49.02%, rgba(255, 255, 255, 0) 113.2%); }

.clients.white .logo-marquee__group::after, .clients.bg-white .logo-marquee__group::after { left: auto; right: 0; transform: matrix(-1, 0, 0, 1, 0, 0); }

.clients.white .logo-marquee__group.reverse .logo-marquee__list, .clients.bg-white .logo-marquee__group.reverse .logo-marquee__list { animation-direction: reverse; animation-delay: -3s; }

.clients.white .logo-marquee__group:last-child .logo-marquee__list, .clients.bg-white .logo-marquee__group:last-child .logo-marquee__list { margin-bottom: 0; }

.clients.white .logo-marquee__list, .clients.bg-white .logo-marquee__list { display: flex; flex-shrink: 0; align-items: center; justify-content: space-around; min-width: 100%; margin-bottom: 24px; animation: scroll-x 60s linear infinite; }

.clients.white .logo-marquee__list .item, .clients.bg-white .logo-marquee__list .item { display: grid; place-items: center; margin: 0 24px; width: 148px; height: 56px; }

.clients.white .logo-marquee__list .item img, .clients.bg-white .logo-marquee__list .item img { display: block; max-width: 100%; max-height: 56px; width: auto; height: auto; }

.clients.white .logo-marquee__action, .clients.bg-white .logo-marquee__action { margin-top: 35px; }

@media (max-width: 991px) { .clients.white .logo-marquee, .clients.bg-white .logo-marquee { padding: 32px 0; }
  .clients.white .logo-marquee__title br, .clients.bg-white .logo-marquee__title br { display: none; }
  .clients.white .logo-marquee__list, .clients.bg-white .logo-marquee__list { animation-duration: 30s; } }

@media (max-width: 991px) { .clients { padding: 32px 14px; }
  .clients .clients__title br { display: none; } }

@media (max-width: 479px) { .clients .clients__title { margin-bottom: 24px; } }

.tab-features { padding: 64px 0; }

.tab-features.alt .tab-features__tabs .nav .nav-item .nav-link { font-size: 20px; line-height: 28px; padding: 24px 0; }

.tab-features__head { text-align: center; margin-bottom: 24px; }

.tab-features__tabs { justify-content: center; }

.tab-features__tabs.block { margin-bottom: 32px; }

.tab-features__tabs.block .nav { background: #EDF0F2; border-radius: 48px; padding: 12px; border-bottom: none; }

.tab-features__tabs.block .nav .nav-item .nav-link { padding: 12px; border-radius: 48px; border-bottom: none; font-family: "Inter", sans-serif; font-weight: 400; }

.tab-features__tabs.block .nav .nav-item .nav-link.active { background: #4B61DD; color: #fff; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-features__tabs .nav { display: flex; justify-content: center; align-items: center; gap: 24px; border-bottom: 1px solid #D0D6DD; padding: 0 24px; }

.tab-features__tabs .nav .nav-item .nav-link { padding: 12px 0; font-size: 16px; line-height: 24px; color: #626B79; font-family: "Inter", sans-serif; font-weight: 600; display: inline-flex; border-block: 2px solid transparent; }

.tab-features__tabs .nav .nav-item .nav-link.active { border-bottom-color: #0055A2; }

.tab-features__tabs .nav .nav-item .nav-link:hover, .tab-features__tabs .nav .nav-item .nav-link.active { color: #232933; }

.tab-features .tab-content .tab-pane .toggle-sm { display: none; }

.tab-features .tab-content .tab-pane .content > section { padding: 32px 0 16px 0; }

@media (max-width: 991px) { .tab-features { padding: 32px 0; }
  .tab-features .tab-content .tab-pane:not(.active) { opacity: 1; display: block; }
  .tab-features .tab-content .tab-pane:not(.active) .toggle-sm { display: flex; font-size: 16px; line-height: 24px; color: #626B79; border-bottom: 1px solid #D0D6DD; padding: 12px 0; font-family: "Inter", sans-serif; font-weight: 600; position: relative; align-items: center; }
  .tab-features .tab-content .tab-pane:not(.active) .toggle-sm::after { content: ''; width: 24px; height: 24px; display: inline-flex; margin-left: auto; background: url(../images/ic-caret-down.svg) no-repeat center; background-position: contain; transform: rotate(0deg); transition: transform linear .3s; }
  .tab-features .tab-content .tab-pane:not(.active) .content { height: 0; opacity: 0; }
  .tab-features .tab-content .tab-pane.active .toggle-sm { display: flex; font-size: 16px; line-height: 24px; border-bottom: 1px solid #009BDE; color: #232933; padding: 12px 0; font-family: "Inter", sans-serif; font-weight: 600; position: relative; align-items: center; }
  .tab-features .tab-content .tab-pane.active .toggle-sm::after { content: ''; width: 24px; height: 24px; display: inline-flex; margin-left: auto; background: url(../images/ic-caret-down.svg) no-repeat center; background-position: contain; transform: rotate(180deg); transition: transform linear .3s; }
  .tab-features .tab-content .tab-pane.active .content { height: auto; opacity: 1; transition: opacity linear .3s; }
  .tab-features .tab-content .tab-pane.active .content > section { padding: 12px 0; } }

.features { padding: 64px 0; }

.features__outline, .features__sub, .features__subtitle { font-size: 16px; line-height: 24px; font-family: "Inter", sans-serif; font-weight: 600; }

.features__title { font-size: 32px; line-height: 48px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 8px; }

.features h3.features__title { font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 16px; }

.features__desc { margin-bottom: 24px; }

.features__actions { margin-top: 24px; }

.features__actions a { margin-right: 16px; }

.features__actions a:last-child { margin-right: 0; }

.features__custom-list ul li { display: flex; position: relative; gap: 8px; align-items: center; justify-content: flex-start; margin-bottom: 12px; }

.features__custom-list ul li:last-child { margin-bottom: 0; }

.features__custom-list ul li span.icon { content: ''; min-width: 24px; height: 24px; background-size: contain; background-repeat: no-repeat; background-position: center; }

@media (max-width: 991px) { .features { padding: 32px 0; }
  .features__img { margin-top: 24px; }
  .features__img .media { text-align: center; }
  .features__actions a { margin-right: 0; margin-bottom: 16px; }
  .features__actions a:last-child { margin-bottom: 0; } }

@media (max-width: 479px) { .features h3.features__title { font-size: 16px; line-height: 24px; margin-bottom: 16px; }
  .features__custom-list ul li { align-items: flex-start; } }

.sidebox-block { padding: 64px 0; background: #F1F5F9; }

.sidebox-block__title { margin-bottom: 24px; }

.sidebox-block .items { display: flex; justify-content: center; align-items: stretch; flex-wrap: wrap; gap: 24px; }

.sidebox-block .items .item { display: flex; flex: 0 0 calc(50% - 12px); padding: 24px; background: #FFF; border-radius: 4px; flex-wrap: wrap; }

.sidebox-block .items .item__icon { margin-bottom: 8px; width: 100%; }

.sidebox-block .items .item__icon img { height: 52px; width: auto; }

.sidebox-block .items .item__title { font-size: 16px; line-height: 24px; margin-bottom: 8px; display: block; width: 100%; }

@media (max-width: 991px) { .sidebox-block { padding: 32px 0; }
  .sidebox-block__img { margin-top: 24px; text-align: center; } }

@media (max-width: 479px) { .sidebox-block { background: #FFF; }
  .sidebox-block__title { text-align: center; }
  .sidebox-block .items .item { flex: 0 0 100%; padding: 0; } }

.integrations-block { padding: 64px 0; }

.integrations-block__actions { margin-top: 24px; }

.integrations-block__actions a { margin-right: 16px; }

.integrations-block__actions a:last-child { margin-right: 0; }

.integrations-block__list .logo-marquee { padding-top: 64px; padding-bottom: 64px; }

.integrations-block__list .logo-marquee__group { display: flex; overflow: hidden; user-select: none; position: relative; }

.integrations-block__list .logo-marquee__group::before, .integrations-block__list .logo-marquee__group::after { content: ""; z-index: 2; display: block; width: 64px; height: 100%; position: absolute; top: 0; left: 0; background: linear-gradient(89.8deg, #FFF 49.02%, rgba(255, 255, 255, 0) 113.2%); }

.integrations-block__list .logo-marquee__group::after { left: auto; right: 0; transform: matrix(-1, 0, 0, 1, 0, 0); }

.integrations-block__list .logo-marquee__group.reverse .logo-marquee__list { animation-direction: reverse; animation-delay: -3s; }

.integrations-block__list .logo-marquee__group:first-child .logo-marquee__list { padding-top: 15px; }

.integrations-block__list .logo-marquee__group:last-child .logo-marquee__list { padding-bottom: 15px; margin-bottom: 0; }

.integrations-block__list .logo-marquee__list { display: flex; flex-shrink: 0; align-items: center; justify-content: space-around; min-width: 100%; margin-bottom: 12px; animation: scroll-x 60s linear infinite; }

.integrations-block__list .logo-marquee__list .item { display: grid; place-items: center; margin: 0 6px; width: 100px; height: 100px; border-radius: 24px; box-shadow: 0px 2.0299999714px 4.0599999428px -2.706666708px rgba(0, 0, 0, 0.12), 0px 4.0599999428px 10.826666832px 0px rgba(0, 0, 0, 0.08), 0px 6.0900001526px 18.9466667175px 5.413333416px rgba(0, 0, 0, 0.05); }

.integrations-block__list .logo-marquee__list .item img { display: block; max-width: 100px; max-height: 100px; width: auto; height: auto; }

@media (max-width: 991px) { .integrations-block__list .logo-marquee__list { animation-duration: 30s; } }

@media (max-width: 479px) { .integrations-block__list .logo-marquee__group::after { width: 24px; }
  .integrations-block__list .logo-marquee__list .item { width: 68px; height: 68px; }
  .integrations-block__list .logo-marquee__list .item img { max-width: 68px; max-height: 68px; } }

@media (max-width: 1024px) { .integrations-block__title br { display: none; } }

@media (max-width: 991px) { .integrations-block { padding: 32px 0; }
  .integrations-block__list { margin-bottom: 24px; }
  .integrations-block__actions a { margin-right: 0; margin-bottom: 16px; }
  .integrations-block__actions a:last-child { margin-bottom: 0; } }

.tab-testimonial { padding: 64px 0; }

.tab-testimonial__title { margin-bottom: 24px; }

.tab-testimonial__tabs .nav { display: flex; justify-content: center; align-items: center; gap: 16px; padding: 12px 24px; background: #EDFAFF; border-radius: 16px 16px 0 0; }

.tab-testimonial__tabs .nav .nav-item .nav-link { display: inline-flex; padding: 12px 24px; font-size: 16px; line-height: 24px; color: #232933; border-radius: 16px; border-bottom: 0; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-testimonial__tabs .nav .nav-item .nav-link.active { background: #FFF; box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14); border-bottom: 0; }

.tab-testimonial__tabs .nav .nav-item .nav-link:hover { border-bottom: 0; }

.tab-testimonial .tab-content { width: auto; }

.tab-testimonial .tab-content .tab-pane { width: auto; }

.tab-testimonial .tab-content .tab-pane .toggle-sm { display: none; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap { background: #EDFAFF; border-radius: 0 16px 16px 16px; display: flex; overflow: hidden; box-shadow: none; padding: 0; width: auto; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content { flex: 0 0 calc(100% - 500px); padding: 24px 64px 24px 24px; display: flex; flex-direction: column; justify-content: center; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__title { display: block; width: 100%; margin-bottom: 32px; font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__custom-list ul li { display: flex; position: relative; gap: 8px; align-items: flex-start; justify-content: flex-start; margin-bottom: 12px; max-width: 640px; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__custom-list ul li:last-child { margin-bottom: 0; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__custom-list ul li span.icon { content: ''; min-width: 24px; height: 24px; background-size: contain; background-repeat: no-repeat; background-position: center; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__custom-list ul li span.text b { width: 100%; display: block; font-family: "Inter", sans-serif; font-weight: 600; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__actions { margin-top: 24px; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__actions a { margin-right: 16px; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__actions a:last-child { margin-right: 0; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card { max-width: 500px; width: 100%; background: #0055A2; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card .testimonial__img { height: 240px; max-width: 500px; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card .testimonial__img img { max-width: 500px; height: 240px; object-fit: cover; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card .testimonial__testi { padding: 56px 24px 32px 24px; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card .testimonial__msg { color: #FFF; margin: 0; font-size: 16px; line-height: 24px; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card .testimonial__meta { margin-top: 21px; color: #FFF; display: flex; justify-content: space-between; align-items: center; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card .testimonial__meta .meta__left { font-size: 14px; line-height: 20px; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card .testimonial__meta .meta__left .name { display: block; width: 100%; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 4px; }

.tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card .testimonial__meta .meta__right .logo { height: 32px; width: auto; object-fit: contain; }

@media (max-width: 991px) { .tab-testimonial { padding: 32px 0; }
  .tab-testimonial .tab-content .tab-pane:not(.active) { opacity: 1; display: block; }
  .tab-testimonial .tab-content .tab-pane:not(.active) .toggle-sm { display: flex; font-size: 16px; line-height: 24px; color: #626B79; border-bottom: 1px solid #D0D6DD; padding: 12px 0; font-family: "Inter", sans-serif; font-weight: 600; position: relative; align-items: center; }
  .tab-testimonial .tab-content .tab-pane:not(.active) .toggle-sm::after { content: ''; width: 24px; height: 24px; display: inline-flex; margin-left: auto; background: url(../images/ic-caret-down.svg) no-repeat center; background-position: contain; transform: rotate(0deg); transition: transform linear .3s; }
  .tab-testimonial .tab-content .tab-pane:not(.active) .content { height: 0; opacity: 0; }
  .tab-testimonial .tab-content .tab-pane.active .toggle-sm { display: flex; font-size: 16px; line-height: 24px; border-bottom: 1px solid #009BDE; color: #232933; padding: 12px 0; font-family: "Inter", sans-serif; font-weight: 600; position: relative; align-items: center; }
  .tab-testimonial .tab-content .tab-pane.active .toggle-sm::after { content: ''; width: 24px; height: 24px; display: inline-flex; margin-left: auto; background: url(../images/ic-caret-down.svg) no-repeat center; background-position: contain; transform: rotate(180deg); transition: transform linear .3s; }
  .tab-testimonial .tab-content .tab-pane.active .content { height: auto; opacity: 1; transition: opacity linear .3s; }
  .tab-testimonial .tab-content .tab-pane.active .content > section { padding: 12px 0; }
  .tab-testimonial .tab-content .tab-pane .testimonial__wrap { flex-direction: column; border-radius: 0; }
  .tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content { padding: 24px; }
  .tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__actions { margin-top: 24px; }
  .tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__actions a { margin-right: 0; margin-bottom: 16px; }
  .tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__content .testimonial__actions a:last-child { margin-bottom: 0; }
  .tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card { width: 100%; max-width: 100%; }
  .tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card .testimonial__img { width: 100%; max-width: 100%; }
  .tab-testimonial .tab-content .tab-pane .testimonial__wrap .testimonial__card .testimonial__img img { width: 100%; max-width: 100%; } }

.faq-block { padding: 64px 0; background: #F9FBFD; }

.faq-block .card__body ul { padding-left: 20px; list-style: disc; }

.faq-block .card__body ul li { margin-bottom: 4px; }

.faq-block .section-head__action { padding-top: 16px; }

.faq-block .section-head__action a { margin-right: 16px; }

.faq-block .section-head__action a:last-child { margin-right: 0; }

.faq-block--pricing { background: transparent; }

.faq-block.animate .section-head__title, .faq-block.animate .section-head__content, .faq-block.animate .section-head__action, .faq-block.animate .accordion--faq { transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 0; transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.faq-block.animate .section-head__content { transition-delay: .2s; }

.faq-block.animate .section-head__action { transition-delay: .2s; }

.faq-block.animate .accordion--faq { transition-delay: .2s; }

.faq-block.animate-in .section-head__title, .faq-block.animate-in .section-head__content, .faq-block.animate-in .section-head__action, .faq-block.animate-in .accordion--faq { transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1); opacity: 1; }

@media (max-width: 991px) { .faq-block { padding: 48px 8px; }
  .faq-block .section-head__action a { margin-right: 0; margin-bottom: 16px; }
  .faq-block .section-head__action a:last-child { margin-bottom: 0; } }

.faq-block .accordion { position: relative; margin-bottom: 32px; }

.faq-block .accordion .card { position: relative; margin-bottom: 4px; }

.faq-block .accordion .card__header { position: relative; display: block; padding: 20px 56px 20px 20px; cursor: pointer; border-bottom: 1px solid #E2E2E2; transition: all .2s ease-in-out; }

.faq-block .accordion .card__header:after { content: ""; display: block; width: 24px; height: 24px; background: url(../images/ic-chevron-down.svg) no-repeat center; background-size: contain; position: absolute; top: 20px; right: 20px; transform: rotate(180deg); transition: all .2s ease-in-out; }

.faq-block .accordion .card__header.collapsed:after { transform: none; }

.faq-block .accordion .card__title { font-size: 16px; line-height: 24px; display: block; font-family: "Inter", sans-serif; font-weight: 500; margin: 0; padding: 0; }

.faq-block .accordion .card__body { background: #FFF; border-radius: 4px; box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75); padding: 24px 20px; margin-top: 12px; }

.faq-block .accordion .card__body .img-sm { display: none; margin-top: 24px; }

@media (max-width: 768px) { .faq-block .accordion .card__body .img-sm { text-align: center; display: inline-block; width: 100%; height: auto; } }

.faq-block .accordion .card__action a { margin-right: 14px; }

.faq-block .accordion .card__action a:last-child { margin-right: 0; }

@media (max-width: 991px) { .faq-block .accordion .card__action { margin-right: 0; } }

.faq-block .accordion--faq .card { margin-bottom: 16px; background: #FFF; border-radius: 4px; overflow: hidden; border: 1px solid #E2E2E2; transition: all .2s ease-in-out; }

.faq-block .accordion--faq .card.open { margin-bottom: 24px; box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }

.faq-block .accordion--faq .card__header { padding: 28px 80px 12px 24px; border: 0; }

.faq-block .accordion--faq .card__header:after { top: 28px; right: 16px; background: url(../images/ic-cancel.svg) no-repeat center; background-size: contain; transform: rotate(90deg); }

.faq-block .accordion--faq .card__header.collapsed { padding-bottom: 28px; }

.faq-block .accordion--faq .card__header.collapsed .card__title { font-family: "Inter", sans-serif; font-weight: 400; }

.faq-block .accordion--faq .card__header.collapsed:after { content: ""; background: url(../images/ic-chevron-down.svg) no-repeat center; background-size: contain; transform: rotate(-90deg); }

.faq-block .accordion--faq .card__body { border-radius: 0; box-shadow: none; padding: 0 80px 52px 24px; margin: 0; }

.faq-block .accordion--faq .card__body .img-sm { display: none; margin-top: 24px; }

@media (max-width: 768px) { .faq-block .accordion--faq .card__body .img-sm { text-align: center; display: inline-block; width: 100%; height: auto; } }

.faq-block--alt { background: #F2F4F7; padding: 64px 0; }

.faq-block--alt .card__body ul { padding-left: 20px; list-style: disc; }

.faq-block--alt .card__body ul li { margin-bottom: 4px; }

@media (max-width: 991px) { .faq-block--alt { padding: 32px 8px; }
  .faq-block--alt .section-head__content { max-width: 100% !important; } }

.cta-box { padding: 64px 0; }

.cta-box__wrap { border-radius: 16px; background: #F7F4FF; padding: 54px 54px 86px 54px; display: flex; justify-content: space-between; }

.cta-box__wrap .box__left { max-width: 600px; }

.cta-box__wrap .box__left .box__icon { margin-bottom: 24px; }

.cta-box__wrap .box__left .box__icon img { height: 48px; width: auto; }

.cta-box__wrap .box__left .box__title { font-size: 32px; line-height: 48px; font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 16px; }

.cta-box__wrap .box__right { display: flex; align-items: center; }

.cta-box__wrap .box__right .box__action { display: flex; flex-direction: column; gap: 24px; }

.cta-box__meta { display: flex; justify-content: center; margin-top: -50px; }

.cta-box__meta .items { display: flex; justify-content: center; align-items: center; gap: 96px; background: #10243e; border-radius: 16px; padding: 24px; max-width: 1100px; }

.cta-box__meta .items .item { max-width: 286px; width: 100%; text-align: center; color: #FFF; position: relative; }

.cta-box__meta .items .item__icon { margin-bottom: 12px; }

.cta-box__meta .items .item__icon img { height: 32px; width: auto; }

.cta-box__meta .items .item__title { color: #FFF; font-size: 20px; line-height: 28px; margin-bottom: 12px; font-family: "Inter", sans-serif; font-weight: 600; }

.cta-box__meta .items .item::after { content: ''; width: 2px; height: 88px; background: #FFF; border-radius: 34px; display: inline-block; position: absolute; right: -48px; top: calc((100% - 88px) / 2); }

.cta-box__meta .items .item:last-child::after { display: none; }

@media (max-width: 991px) { .cta-box { padding: 32px 0; }
  .cta-box__wrap { padding: 32px 32px 64px 32px; flex-direction: column; }
  .cta-box__wrap .box__left { margin-bottom: 24px; }
  .cta-box__wrap .box__right .box__action { flex-direction: row; } }

@media (max-width: 479px) { .cta-box__wrap .box__left .box__title { font-size: 28px; line-height: 36px; }
  .cta-box__wrap .box__right .box__action { flex-wrap: wrap; gap: 16px; }
  .cta-box__wrap .box__right .box__action a { width: 100%; flex: 0 0 100%; margin-bottom: 0; }
  .cta-box__meta { margin: -40px auto 0 auto; max-width: 311px; }
  .cta-box__meta .items { flex-wrap: wrap; padding: 24px; }
  .cta-box__meta .items .item::after { width: 88px; height: 2px; bottom: -48px; right: calc((100% - 88px) / 2); top: unset; } }

.masthead__slider { max-height: 500px; overflow: hidden; display: flex; flex-direction: row-reverse; flex: 0 0 100%; width: 100%; }

.masthead__slider .logo-marquee__group { display: inline-block; position: relative; width: calc(100% / 4); }

.masthead__slider .logo-marquee__group::before { width: 100%; height: 20px; background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%); }

.masthead__slider .logo-marquee__group::after { width: 100%; height: 20px; background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%); bottom: 0 !important; top: unset !important; }

.masthead__slider .logo-marquee__group:nth-child(2) { margin-top: 20%; }

.masthead__slider .logo-marquee__group:nth-child(3) { margin-top: 35%; }

.masthead__slider .logo-marquee__group:nth-child(4) { margin-top: 50%; }

.masthead__slider .logo-marquee__group:nth-child(even) .logo-marquee__list { animation-direction: reverse; }

.masthead__slider .logo-marquee__list { flex-direction: column; animation: scroll-y 30s linear infinite; width: 100%; gap: 0; animation-duration: 45s; min-width: 100%; }

.masthead__slider .logo-marquee__list .item { width: 100%; height: auto; max-width: 100%; max-height: auto; padding-left: 0; padding-right: 0; }

.masthead__slider .logo-marquee__list .item img { width: 100%; height: auto; max-height: 100%; }

@media (max-width: 991px) { .masthead__slider { margin-top: 16px; }
  .masthead__slider .logo-marquee__group:first-child { margin-top: 0; } }

@media (max-width: 479px) { .masthead__slider { max-height: 299px; } }

.client-testimonial { padding: 64px 0; background: #F2F4F7; }

.client-testimonial .testi-big { margin-top: 128px; margin-bottom: 32px; padding: 32px; background: #FFF; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); border-radius: 4px 0px 0px 4px; display: flex; align-items: center; justify-content: space-between; gap: 78px; }

.client-testimonial .testi-big__img { max-width: 394px; flex: 0 0 100%; display: flex; position: relative; }

.client-testimonial .testi-big__img img { width: 100%; object-fit: contain; margin-right: 78px; margin-top: -140px; }

.client-testimonial .testi-big__content { flex: 0 0 calc(100% - (394px + 78px)); }

.client-testimonial .testi-big__quote { margin: 0 0 26px 0; }

.client-testimonial .testi-big__quote p { margin-bottom: 16px; }

.client-testimonial .testi-big__quote p:last-child { margin-bottom: 0; }

.client-testimonial .testi-big__meta { display: flex; justify-content: space-between; align-items: center; }

.client-testimonial .testi-big__meta b { font-family: "Inter", sans-serif; font-weight: 600; }

.client-testimonial .testi-big__meta b, .client-testimonial .testi-big__meta span { display: block; }

.client-testimonial .testi-big__logo { width: auto; height: 50px; object-fit: contain; }

.client-testimonial .testi-big__action { margin-top: 32px; }

.client-testimonial .testi-sm { background: #FFF; border-radius: 4px 0px 0px 4px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); padding: 32px; height: 100%; display: flex; justify-content: space-between; flex-direction: column; }

.client-testimonial .testi-sm__quote { margin: 0 0 24px 0; }

.client-testimonial .testi-sm__quote p { margin-bottom: 16px; }

.client-testimonial .testi-sm__quote p:last-child { margin-bottom: 0; }

.client-testimonial .testi-sm__meta { display: flex; align-items: center; }

.client-testimonial .testi-sm__img { height: 105px; width: auto; margin-right: 16px; }

.client-testimonial .testi-sm__img img { height: 105px; width: 96px; object-fit: contain; }

.client-testimonial .testi-sm__info b, .client-testimonial .testi-sm__info span { display: block; }

.client-testimonial .testi-sm__info span { margin-bottom: 4px; }

@media (max-width: 991px) { .client-testimonial { padding: 32px 0; }
  .client-testimonial .testi-big { flex-direction: column; padding: 24px 12px; margin-top: 85px; margin-bottom: 24px; gap: 24px; }
  .client-testimonial .testi-big__img { margin-right: 0; margin-bottom: 8px; margin-top: 0; height: 292px; }
  .client-testimonial .testi-big__meta { flex-direction: column; align-items: flex-start; }
  .client-testimonial .testi-big__info { margin-bottom: 12px; }
  .client-testimonial .testi-sm { padding: 24px 12px; margin-bottom: 24px; height: calc(100% - 24px); }
  .client-testimonial .testi-sm__img { display: none; } }

.card-testi { padding: 64px 0; }

.card-testi .card__wrap { margin-top: 32px; }

.card-testi__filter { padding-top: 16px; }

.card-testi__filter form { display: flex; }

.card-testi__filter .form-group { margin: 0; display: block; width: 100%; max-width: 35%; flex: 0 0 35%; padding-left: 8px; padding-right: 8px; }

.card-testi__filter .form-action { padding-top: 0; display: block; width: 100%; max-width: 30%; flex: 0 0 30%; padding-left: 8px; padding-right: 8px; }

.card-testi__filter .form-action .btn { display: block; width: 100%; }

.card-testi .card { border-radius: 4px; overflow: hidden; border: 0; height: calc(100% - 24px); margin-bottom: 24px; }

.card-testi .card__img-box { display: block; width: 100%; padding-top: 56%; position: relative; }

.card-testi .card__img-box img { display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; object-fit: cover; object-position: center; }

.card-testi .card__content { padding: 24px; position: relative; display: flex; flex-direction: column; height: 100%; }

.card-testi .card__icon { position: absolute; width: 24px; height: 24px; object-fit: contain; top: 25px; left: 24px; }

.card-testi .card__title { padding-left: 32px; font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 16px; }

.card-testi .card__title.no-icon { padding-left: 0; }

.card-testi .card__desc { margin-bottom: 16px; }

.card-testi .card__desc p { min-height: 96px; }

.card-testi .card__info { margin-bottom: 4px; }

.card-testi .card__info b, .card-testi .card__info span { display: block; }

.card-testi .card__info b { font-family: "Inter", sans-serif; font-weight: 600; }

.card-testi .card__logo { height: 48px; width: auto; object-position: left center; object-fit: contain; }

.card-testi .card__action { margin-top: 16px; visibility: hidden; opacity: 0; transition: all linear .3s; }

.card-testi .card:hover { box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); }

.card-testi .card:hover .card__action { visibility: visible; opacity: 1; transition: all linear .3s; }

@media (max-width: 991px) { .card-testi { padding: 32px 0; }
  .card-testi__filter { padding-top: 8px; }
  .card-testi__filter form { display: block; }
  .card-testi__filter .form-group { max-width: 100%; flex: 0 0 100%; margin-top: 8px; padding: 0; }
  .card-testi__filter .form-action { margin-top: 8px; max-width: 100%; flex: 0 0 100%; padding: 0; }
  .card-testi .card { border: 1px solid #D0D6DD; }
  .card-testi .card__icon { left: 12px; top: 13px; }
  .card-testi .card__content { padding: 12px 12px 24px 12px; }
  .card-testi .card__action { visibility: visible; opacity: 1; }
  .card-testi .card:hover { box-shadow: none; } }

.block-feat__title { text-align: center; margin: 0 0 8px; }

.block-feat__desc { text-align: center; margin: 0 0 24px; }

.block-feat__action { justify-content: center; margin: 0 -8px 24px; display: flex; }

.block-feat__action .btn { margin-left: 8px; margin-right: 8px; }

.block-feat__list { display: block; width: 100%; padding: 12px; margin-right: 24px; }

.block-feat__list .item__head { display: flex; align-items: center; border-bottom: 1px solid #D0D6DD; cursor: pointer; transition: all .4s ease; }

.block-feat__list .item__head .icon { display: block; width: 100%; max-width: 60px; flex: 0 0 60px; padding: 12px; }

.block-feat__list .item__head .icon .box img { display: block; width: 36px; height: 36px; filter: grayscale(100); opacity: 0.7; transition: all .4s ease; }

.block-feat__list .item__head .text { display: block; width: 100%; max-width: calc(100% - 60px); flex: 0 0 calc(100% - 60px); font-family: "Inter", sans-serif; font-weight: 500; color: #626B79; }

.block-feat__list .item__box { display: none; }

.block-feat__list .item.active .item__head { border-bottom-color: #4B61DD; border-bottom-width: 4px; }

.block-feat__list .item.active .icon .box img { filter: grayscale(0); opacity: 1; }

.block-feat__list .item.active .text { color: #232933; }

.block-feat__list .item__action a { margin-right: 16px; }

.block-feat__list .item__action a:last-child { margin-right: 16px; }

.block-feat__box { background: #EAECFB; padding: 32px; display: none; }

.block-feat__box.active { display: block; }

.block-feat__box img { margin: 0 auto 24px auto; }

.block-feat__box h3 { font-size: 20px; line-height: 28px; margin-bottom: 8px; font-family: "Inter", sans-serif; font-weight: 600; }

.block-feat__box .item__action a { margin-right: 16px; }

.block-feat__box .item__action a:last-child { margin-right: 16px; }

.block-feat__left { padding-top: 24px; padding-right: 52px !important; }

.block-feat__right { padding-top: 24px; }

.block-feat__wrap { padding-top: 64px; padding-bottom: 64px; }

@media (max-width: 991px) { .block-feat { padding-left: 8px; padding-right: 8px; }
  .block-feat .item__box { background: #F2F4F7; padding: 24px 24px 32px; }
  .block-feat .item__box img { display: block; width: auto; height: auto; max-width: 100%; margin: 0 auto 24px auto; }
  .block-feat .item.active .item__box { display: block; }
  .block-feat__action { flex-wrap: wrap; margin: 0; }
  .block-feat__action .btn { display: block; margin-bottom: 8px; }
  .block-feat__list { padding: 0; }
  .block-feat__box { padding: 0 24px 24px; display: none; }
  .block-feat__box.active { display: none; }
  .block-feat__left { padding-right: 12px !important; }
  .block-feat__right { display: none; }
  .block-feat__wrap { padding-top: 32px; padding-bottom: 32px; } }

@media (max-width: 767px) { .block-feat .item__action a { margin-right: 0; margin-bottom: 16px; }
  .block-feat .item__action a.btn { width: 100%; display: block; }
  .block-feat .item__action a:last-child { margin-bottom: 0; }
  .block-feat__box .item__action a { margin-right: 0; margin-bottom: 16px; }
  .block-feat__box .item__action a.btn { width: 100%; display: block; }
  .block-feat__box .item__action a:last-child { margin-bottom: 0; } }

.single-price-card { padding: 64px 0; }

.single-price-card .badge { font-size: 14px; line-height: 20px; color: #FFF; padding: 2px 6px; border-radius: 12px; margin-bottom: 10px; }

.single-price-card .badge--red { background: #EF4444; }

.single-price-card__meta { font-family: "Inter", sans-serif; font-weight: 600; margin-bottom: 8px; }

.single-price-card__title { margin: 0 auto 60px; position: relative; max-width: 545px; width: 100%; }

.single-price-card__title:after { content: ''; height: 40px; width: 60%; background: url(../images/red-line.png) no-repeat center; background-size: contain; position: absolute; bottom: -30px; left: 20%; }

.single-price-card .card-price { padding: 24px; max-width: 300px; width: 100%; display: flex; flex-wrap: wrap; flex: 0 0 100%; justify-content: center; position: relative; border-radius: 6px; border: 1px solid #009BDE; background: #FFF; box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 24px 48px 0px rgba(209, 209, 209, 0.75); }

.single-price-card .card-price__wrapper { display: flex; flex-wrap: wrap; justify-content: center; align-items: stretch; gap: 24px; position: relative; }

.single-price-card .card-price__title { font-family: "Inter", sans-serif; font-weight: 600; font-size: 20px; line-height: 28px; margin-bottom: 8px; text-align: center; }

.single-price-card .card-price__meta { text-align: center; display: flex; align-items: center; justify-content: center; flex-wrap: wrap; margin-bottom: 24px; }

.single-price-card .card-price__meta .price { font-size: 20px; line-height: 32px; width: 100%; flex: 0 0 100%; font-family: "Inter", sans-serif; font-weight: 600; }

.single-price-card .card-price__meta .price .tenor { display: inline-flex; font-size: 14px; line-height: 20px; font-family: "Inter", sans-serif; font-weight: 400; }

.single-price-card .card-price__action { margin-bottom: 24px; width: 100%; }

.single-price-card .card-price__action a.btn { width: 100%; }

.single-price-card .card-price__floating { position: absolute; width: 227px; top: 0; right: calc(-227px - 40px); font-family: 'Permanent Marker', cursive; font-size: 32px; line-height: normal; color: #000; }

.single-price-card .card-price__floating::after { content: ''; width: 75px; height: 75px; background: url(../images/arrow-testimonial.png) no-repeat center; background-size: contain; position: absolute; bottom: -70px; left: 0; transform: rotate(295deg); }

@media (max-width: 991px) { .single-price-card { padding: 32px 0; }
  .single-price-card .card-price__floating { display: none; } }

.testimoni-twoblock { padding: 64px 0; }

.testimoni-twoblock__title { text-align: center; margin-bottom: 24px; }

.testimoni-twoblock__box { display: flex; justify-content: center; align-items: stretch; border-radius: 4px; overflow: hidden; }

.testimoni-twoblock__box .left { flex: 0 0 calc(100% - 270px); width: calc(100% - 270px); display: flex; align-content: center; background: #F2F4F7; flex-wrap: wrap; gap: 16px; padding: 32px; }

.testimoni-twoblock__box .left blockquote { margin: 0; flex: 0 0 100%; }

.testimoni-twoblock__box .left .info { display: flex; flex: 0 0 100%; justify-content: space-between; align-items: center; }

.testimoni-twoblock__box .left .info__left { flex: 0 0 calc(100% - 200px); display: flex; gap: 16px; }

.testimoni-twoblock__box .left .info__left .img { flex: 0 0 48px; border-radius: 50%; overflow: hidden; }

.testimoni-twoblock__box .left .info__left .img img { width: 48px; height: 48px; object-fit: cover; }

.testimoni-twoblock__box .left .info__left .meta { flex: 0 0 calc(100% - (48px + 16px)); }

.testimoni-twoblock__box .left .info__left .meta .name { font-family: "Inter", sans-serif; font-weight: 600; }

.testimoni-twoblock__box .left .info__right { flex: 0 0 200px; }

.testimoni-twoblock__box .left .info__right .logo { display: flex; justify-content: flex-end; }

.testimoni-twoblock__box .left .info__right .logo img { display: inline-flex; height: 48px; width: auto; }

.testimoni-twoblock__box .right { flex: 0 0 270px; width: 270px; background: #0055A2; color: #FFF; padding: 32px 24px; display: flex; flex-wrap: wrap; gap: 16px; }

.testimoni-twoblock__box .right .title, .testimoni-twoblock__box .right .desc { flex: 0 0 100%; font-size: 20px; line-height: 28px; font-family: "Inter", sans-serif; font-weight: 600; }

.testimoni-twoblock__box .right .big { flex: 0 0 100%; font-size: 52px; line-height: 56px; font-family: "Inter", sans-serif; font-weight: 700; }

.testimoni-twoblock__box .right .action { flex: 0 0 100%; }

@media (max-width: 991px) { .testimoni-twoblock { padding: 32px 0; }
  .testimoni-twoblock__box { flex-direction: column; }
  .testimoni-twoblock__box .left, .testimoni-twoblock__box .right { flex: 0 0 100%; width: 100%; } }

@media (max-width: 479px) { .testimoni-twoblock__title br { display: none; }
  .testimoni-twoblock__box .left .info { flex-wrap: wrap; gap: 16px; }
  .testimoni-twoblock__box .left .info__left, .testimoni-twoblock__box .left .info__right { flex: 0 0 100%; width: 100%; }
  .testimoni-twoblock__box .left .info__right .logo { justify-content: flex-start; } }

.lottie-block { padding: 64px; }

.lottie-block__title { text-align: center; margin-bottom: 24px; }

.lottie-block dotlottie-player, .lottie-block lottie-player { width: 100% !important; }

.lottie-block.inapp { padding: 40px 0; }

@media (max-width: 991px) { .lottie-block { padding: 32px 0; } }

@media (max-width: 479px) { .lottie-block__title br { display: none; } }
