.block-fea {
  padding: 64px 0;
  &__title {
    @include font-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
    text-align: center; }
  &__desc {
    margin-bottom: 24px;
    text-align: center; }
  .item__wrap {
    .item {
      height: 100%;
      padding: 32px;
      &__icon {
        width: 48px;
        height: 48px;
        object-fit: contain;
        margin-bottom: 8px; }
      &__title {
        @include font-600();
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px; }
      &__desc {
        p {
          margin-bottom: 0; } } } }
  @include maxtablet() {
    padding: 32px 0;
    &__title {
      font-size: 28px;
      line-height: 36px;
      br {
        display: none; } }
    .item__wrap {
      .col-12 {
        &:last-child {
          .item {
            margin-bottom: 0; } } }
      .item {
        height: calc(100% - 24px);
        margin-bottom: 24px;
        padding: 0 0 0 44px;
        position: relative;
        &__title {
          font-size: 20px;
          line-height: 28px; }
        &__icon {
          position: absolute;
          width: 24px;
          height: 24px;
          left: 7px;
          top: 2px; } } } } }


.card-fea {
  padding: 64px 0;
  &__title {
    @include font-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
    text-align: center; }
  &__desc {
    margin-bottom: 24px;
    text-align: center; }
  .card__wrap {
    .card {
      padding: 32px;
      border-radius: 4px;
      height: calc(100% - 24px);
      margin-bottom: 24px;
      &:hover {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
        .card__icon {
          filter: grayscale(0);
          transition: filter linear .3s; }
        .card__action {
          opacity: 1;
          visibility: visible;
          transition: all linear .3s; } }
      &__img {
        width: 100%;
        height: 225px;
        object-fit: contain;
        background: #F2F4F7;
        margin-bottom: 8px; }
      &__meta {
        position: relative;
        padding-left: 28px; }
      &__icon {
        position: absolute;
        left: 0;
        top: 4px;
        width: 20px;
        height: 20px;
        object-fit: contain;
        filter: grayscale(1);
        transition: filter linear .3s; }
      &__title {
        @include font-600();
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 10px; }
      &__desc {
        margin-bottom: 32px;
        p {
          margin-bottom: 0; } }
      &__action {
        opacity: 0;
        visibility: hidden;
        text-align: center;
        transition: all linear .3s; } } }
  @include maxtablet() {
    padding: 32px 0;
    &__title {
      font-size: 28px;
      line-height: 36px;
      br {
        display: none; } }
    .card__wrap {
      .card {
        padding: 0;
        margin-bottom: 0;
        &:hover {
          box-shadow: none;
          .card__icon {
            filter: grayscale(1); } }
        &.active {
          .card__accr {
            border-bottom-color: #019BDF;
            transition: all linear .3s;
            &::after {
              transform: rotate(180deg);
              transition: all linear .3s; } }
          .card__icon {
            filter: grayscale(0);
            opacity: 1;
            transition: all linear .3s; }
          .card__title {
            color: #232933;
            transition: all linear .3s; }
          .card__content {
            display: block;
            visibility: visible;
            opacity: 1;
            transition: all linear .3s;
            padding: 24px 12px; }
          .card__action {
            opacity: 1;
            visibility: visible;
            text-align: left;
            margin-bottom: 30px; }
          .card__meta {
            padding-left: 0; }
          .card__desc {
            margin-bottom: 30px; } }
        &__accr {
          padding: 12px 30px 12px 5px;
          border-bottom: 1px solid #D0D6DD;
          position: relative;
          transition: all linear .3s;
          &::after {
            position: absolute;
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url('../images/ic-caret-down.svg') no-repeat center;
            background-size: contain;
            right: 5px;
            top: 14px;
            transition: all linear .3s; } }

        &__icon {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
          top: 0;
          opacity: .75;
          transition: all linear .3s; }
        &__title {
          font-size: 16px;
          line-height: 24px;
          color: #626B79;
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 33px);
          margin-bottom: 0;
          transition: all linear .3s; }
        &__content {
          display: none;
          opacity: 0;
          visibility: hidden;
          transition: all linear .3s; } } } } }



.cs-slider {
  padding: 64px 0;
  &__title {
    @include font-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
    text-align: center; }
  &__desc {
    margin-bottom: 24px;
    text-align: center; }
  &__slider {
    .owl-item {
      border-radius: 4px;
      overflow: hidden; }
    .owl-dots {
      .owl-dot {
        width: 32px;
        height: 4px;
        background: #8B95A5;
        border-radius: 4px;
        &.active {
          background: #4B61DD; } } }
    .row {
      overflow: hidden;
      .col-md-7 {
        padding-right: 0; }
      .col-md-5 {
        padding-left: 0; } } }
  &__testimonial {
    background: #EDF0F2;
    padding: 44px 32px;
    height: 100%; }
  &__testi-title {
    @include font-600();
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px; }
  &__quote {
    margin: 0 0 16px 0; }
  &__info {
    display: flex;
    align-items: center;
    width: 100%;
    &-left,
    &-right {
      width: 50%; }
    &-right {
      text-align: right; } }
  &__img {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    vertical-align: middle;
    overflow: hidden;
    img {
      height: 48px;
      width: auto;
      border-radius: 50%;
      object-fit: cover; } }
  &__contact {
    display: inline-block;
    width: calc(100% - 69px);
    vertical-align: middle; }
  &__name {
    @include font-600(); }
  &__logo {
    display: inline-block;
    vertical-align: middle;
    img {
      height: 48px;
      width: auto; } }
  &__content {
    display: inline-flex; }
  &__stats {
    background: #0055A2;
    height: 100%;
    color: #FFF;
    padding: 32px 24px; }
  &__items {
    display: flex;
    align-items: stretch;
    .item {
      margin-right: 16px;
      &__label {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 26px;
        display: block;
        @include font-500(); }
      &__number {
        font-size: 52px;
        line-height: 26px;
        margin-bottom: 26px;
        display: block;
        @include font-600(); }
      &__desc {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 26px;
        display: block;
        @include font-500(); } } }
  @include maxtablet() {
    padding: 32px 0;
    &__title {
      font-size: 28px;
      line-height: 36px;
      br {
        display: none; } }
    &__testimonial {
      padding: 24px; }
    &__testi-title {
      margin-bottom: 8px; }
    &__info {
      display: block;
      &-left {
        width: 100%;
        margin-bottom: 24px; }
      &-right {
        width: 100%;
        text-align: left;
        .cs-slider__logo {
          img {
            height: 32px; } } } }
    &__stats {
      padding: 24px; }
    &__items {
      display: block; }



    &__slider {
      .row {
        overflow: hidden;
        .col-md-7 {
          padding-right: 12px; }
        .col-md-5 {
          padding-left: 12px; } } } } }


.form-contact--alt {
  padding: 64px 0;
  background-color: #F2F4F7;
  background-repeat: no-repeat;
  background-position: 100px center;
  background-size: 35%;
  @include maxtablet() {
    padding: 42px 10px;
    background-image: none!important; }
  h1,h2,h3 {
    @include font-600();
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
    @include maxtablet() {
      br {
        display: none; } } }
  .form-meeting__box {
    background: transparent;
    padding: 0; }
  .form-contact__img {
    img {
      max-width: 100%;
      margin: 0 auto;
      width: unset; } }
  .form-contact__wrap {
    background: transparent;
    box-shadow: none;
    margin-bottom: 0;
    padding: 0; }
  .form-contact__title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px; }
  .form-group {
    margin-bottom: 20px;
    label {
      @include font-600();
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      display: inline-block; }
    small {
      color: #626B79;
      font-size: 12px;
      line-height: 16px; }
    span.info {
      width: 20px;
      height: 20px;
      background: url('../images/ic-info.svg') no-repeat center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      cursor: help; }

    .form-control {
      border: 1px solid #D0D6DD;
      border-radius: 6px;
      &:active,
      &:hover,
      &:focus {
        border-color: #314AD8;
        box-shadow: none; } }

    .select {
      background: #FFF; }

    input[type="file"] {
      font-size: 13px; }

    label.cb-label {
      @include font-400();
      font-size: 14px;
      line-height: 20px;
      color: #232933;
      margin-bottom: 24px;
      a {
        color: #4B61DD;
        text-decoration: underline;
        &:hover {
          color: darken(#4B61DD, 20%);
          text-decoration: none; } } }

    @include maxtablet() {
      .col-lg-6 {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0; } }
      label.cb-label {
        display: inline-block;
        width: calc(100% - 30px);
        vertical-align: top; } } } }
