.masthead--accr {
  .masthead__left {
    padding-right: 50px; }
  .masthead__accr {
    &.accordion {
      margin-bottom: 24px;
      .card {
        padding: 0;
        border: 1px solid #D0D6DD;
        border-radius: 4px;
        margin-bottom: 8px;
        background: #FFF!important;
        &:last-child {
          margin-bottom: 0; }
        &.open {
          .collapse {
            display: block!important; } }
        &__header {
          padding: 20px 56px 0 16px!important;
          position: relative;
          border-bottom: 0;
          &::after {
            content: ''!important;
            display: inline-block!important;
            width: 24px!important;
            height: 24px!important;
            position: absolute!important;
            background: url(../images/ic-caret-down.svg) no-repeat center!important;
            background-size: contain!important;
            right: 16px!important;
            top: 20px!important; }
          &.collapsed {
            padding-bottom: 20px!important; } }
        &__title {
          text-align: left!important;
          font-size: 16px!important;
          line-height: 24px!important;
          margin-bottom: 4px!important;
          @include font-600(); }
        &__body {
          box-shadow: none;
          padding: 0 56px 20px 16px !important;
          margin-top: 0;
          ul {
            list-style: disc;
            padding-left: 15px; } }
        .collapse {
          display: none!important; } } } }
  @include maxtablet() {
    .masthead__left {
      padding-right: 12px; }
    .masthead__accr {
      &.accordion {
        margin-bottom: 24px;
        .card {
          padding: 0;
          border: 1px solid #D0D6DD;
          border-radius: 4px;
          margin-bottom: 8px;
          background: #FFF!important;
          &:last-child {
            margin-bottom: 0; }
          &.open {
            .collapse {
              display: block!important; } }
          &__header {
            padding: 20px 56px 0 16px!important;
            position: relative;
            &::after {
              content: ''!important;
              display: inline-block!important;
              width: 24px!important;
              height: 24px!important;
              position: absolute!important;
              background: url(../images/ic-caret-down.svg) no-repeat center!important;
              background-size: contain!important;
              right: 16px!important;
              top: 20px!important; }
            &.collapsed {
              padding-bottom: 20px!important; } }
          &__title {
            text-align: left!important;
            font-size: 16px!important;
            line-height: 24px!important;
            margin-bottom: 4px!important;
            @include font-600(); }
          &__body {
            box-shadow: none;
            padding: 0 56px 20px 16px!important;
            margin-top: 0; }
          .collapse {
            display: none!important; } } } } } }


.features-accr {
  .accordion--section {
    .block-accr {
      padding: 64px 0;
      &__subtitle {
        @include font-600();
        font-size: 16px;
        line-height: 24px; }
      &__title {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 8px; }
      &__accr {
        &.accordion {
          margin-bottom: 24px;
          .card {
            padding: 0;
            border: 1px solid #D0D6DD;
            border-radius: 4px;
            margin-bottom: 8px;
            background: #FFF!important;
            &:last-child {
              margin-bottom: 0; }
            &.open {
              .collapse {
                display: block!important; } }
            &__header {
              padding: 20px 56px 0 16px!important;
              position: relative;
              &::after {
                content: ''!important;
                display: inline-block!important;
                width: 24px!important;
                height: 24px!important;
                position: absolute!important;
                background: url(../images/ic-caret-down.svg) no-repeat center!important;
                background-size: contain!important;
                right: 16px!important;
                top: 20px!important; }
              &.collapsed {
                padding-bottom: 20px!important; } }
            &__title {
              text-align: left!important;
              font-size: 16px!important;
              line-height: 24px!important;
              margin-bottom: 4px!important;
              @include font-600(); }
            &__body {
              box-shadow: none;
              padding: 0 16px 20px 16px!important;
              margin-top: 0;
              ul {
                list-style: disc;
                padding-left: 15px; } }
            .collapse {
              display: none!important; } } } }
      @include maxtablet() {
        padding: 32px 0;
        &__title {
          font-size: 24px;
          line-height: 32px; }
        &__img {
          margin-bottom: 24px; }
        &__accr {
          &.accordion {
            margin-bottom: 24px;
            .card {
              padding: 0;
              border: 1px solid #D0D6DD;
              border-radius: 4px;
              margin-bottom: 8px;
              background: #FFF!important;
              &:last-child {
                margin-bottom: 0; }
              &.open {
                .collapse {
                  display: block!important; } }
              &__header {
                padding: 20px 56px 0 16px!important;
                position: relative;
                &::after {
                  content: ''!important;
                  display: inline-block!important;
                  width: 24px!important;
                  height: 24px!important;
                  position: absolute!important;
                  background: url(../images/ic-caret-down.svg) no-repeat center!important;
                  background-size: contain!important;
                  right: 16px!important;
                  top: 20px!important; }
                &.collapsed {
                  padding-bottom: 20px!important; } }
              &__title {
                text-align: left!important;
                font-size: 16px!important;
                line-height: 24px!important;
                margin-bottom: 4px!important;
                @include font-600(); }
              &__body {
                box-shadow: none;
                padding: 0 16px 20px 16px!important;
                margin-top: 0; }
              .collapse {
                display: none!important; } } } } } } } }


.compare-cards.compare-cards--alt {
  padding: 64px 0;
  .section-head {
    padding-bottom: 24px; }
  .compare-cards__compare {
    padding: 0;
    .item {
      &__head {
        title {
          font-size: 20px!important;
          line-height: 28px!important; } }
      &__body {
        title {
          font-size: 16px;
          line-height: 24px; } }
      &--highlight {
        .item__head {
          background: linear-gradient(340deg, #0087D9 9.7%, #00A8FD 47.12%, #66CFFF 84.53%);
          border-color: #009BDE;
          title {
            font-size: 20px;
            line-height: 28px; } }
        .item__body {
          border-color: #0098DE; } } } }
  @include maxtablet() {
    padding: 32px 0; } }

.tab-cs {
  .section-head__title {
    margin-bottom: 24px;
    text-align: center; }
  .slider {
    .owl-dots {
      .owl-dot {
        width: 32px;
        height: 4px;
        border-radius: 4px;
        background: #8B95A5;
        &.active {
          background: #4B61DD; } } } } }

.tab-inline {
  padding: 64px 0 0 0;
  .section-head {
    margin-bottom: 24px;
    &__title {
      text-align: center; } }
  .nav-tabs {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: stretch;
    .nav-item {
      display: flex;
      flex: 0 0 25%;
      justify-content: center;
      .nav-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 24px 24px 20px 24px;
        border-bottom: 4px solid transparent;
        .nav-icon {
          margin-right: 16px; }
        span {
          @include font-600();
          color: #232933; }
        &.active,
        &:hover {
          border-bottom-color: #4B61DD; } } } }
  &__content {
    background: #EAECFB;
    padding: 64px 0; }
  .tab-content {
    .toggle-sm {
      display: none; }
    .promo-benefit {
      &__title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
        @include font-600(); }
      &__desc {
        p {
          margin-bottom: 16px; } } } }
  &.in-app {
    .tab-inline {
      .tab-content {
        .tab-pane {
          .promo-benefit__content {
            color: #232933;
            font-size: 14px;
            line-height: 20px;
            .custom-list {
              li {
                font-size: 14px;
                line-height: 20px; } } } } } } }
  @include maxtablet() {
    padding: 32px 0;
    .section-head {
      &__title {
        br {
          display: none; } } }
    &__content {
      padding: 0;
      background: transparent; }
    .tab-content {
      .tab-pane {
        display: block;
        &>.row {
          padding: 32px 0;
          background: #EAECFB;
          display: none; }
        &.active {
          &>.row {
            display: flex; }
          .toggle-sm {
            border-bottom: 2px solid #4B61DD;
            &::after {
              transform: rotate(180deg);
              transition: transform linear .3s; }
            span {
              color: #232933;
              @include font-600(); }
            &__icon {
              img {
                filter: grayscale(0); } } } } }
      .toggle-sm {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: start;
        padding: 12px;
        border-bottom: 1px solid #626B79;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
          background: url(../images/ic-caret-down.svg) no-repeat center;
          background-size: contain;
          top: 20px;
          right: 7px;
          transform: rotate(0deg);
          transition: transform linear .3s; }
        &__icon {
          margin-right: 12px;
          img {
            height: 36px;
            width: auto;
            filter: grayscale(1); } }
        span {
          font-size: 16px;
          line-height: 24px;
          color: #626B79; } }
      .promo-benefit {
        &__title {
          text-align: left; }
        &__img {
          display: block;
          margin-top: 24px; } } } } }


.pricing-block--alt {
  padding: 64px 0;
  .section-head {
    text-align: left;
    &__desc {
      ul.custom-list.two-col {
        flex-direction: row;
        display: flex;
        flex: 0 0 50%; } } }
  .card {
    padding: 16px;
    border: 1px solid #D0D6DD;
    border-radius: 4px;
    &__head {
      h3 {
        font-size: 20px;
        line-height: 28px;
        @include font-600();
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        span.badge {
          font-size: 12px;
          line-height: 16px; } }
      .bonus {
        padding: 22px 12px;
        border: 1px solid #009BDE;
        border-radius: 4px;
        background: #FFF;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        b {
          @include font-600();
          margin-bottom: 8px; }
        ul {
          list-style: disc;
          padding-left: 15px; } }
      .price {
        margin-bottom: 24px;
        .label {
          display: block;
          text-decoration: line-through;
          color: #626B79;
          font-size: 14px;
          line-height: 20px; }
        .pr {
          font-size: 20px;
          line-height: 28px;
          @include font-600();
          color: #232933;
          display: block;
          small {
            color: #626B79;
            font-size: 14px;
            line-height: 20px; }
          span.badge {
            padding: 4px 12px;
            background: #FFF;
            border: 1px solid #4B61DD;
            color: #4B61DD;
            font-size: 14px;
            line-height: 20px;
            @include font-400();
            border-radius: 24px;
            margin-left: 8px; } }
        .tc {
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: #626B79; } } }

    &.card--highlight {
      border-color: #009BDE;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
      background: #EDFAFF; } }
  @include maxtablet() {
    padding: 32px 0;
    .section-head {
      &__title {
        text-align: center;
        br {
          display: none; } } } } }



.form-ticket {
  background: #F1F5F9;
  padding: 64px 0;
  .section-head {
    text-align: left;
    padding-right: 30px;
    padding-bottom: 0;
    .section-head__meta,
    .section-head__subtitle {
      font-size: 16px;
      line-height: 24px;
      color: #232933;
      @include font-600();
      margin-bottom: 7px; }
    .section-head__title {
      position: relative;
      margin-bottom: 16px;
      .underline {
        position: relative;
        display: inline-flex;
        margin-right: 6px;
        &::before {
          content: '';
          width: calc(100% + 30px);
          height: 18px;
          background: url(../images/red-underline.svg) no-repeat center;
          background-size: contain;
          position: absolute;
          left: -8px;
          bottom: -8px; } } } }
  .form-meeting__box {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 32px; }
  .ticket {
    margin-right: 70px;
    position: relative;
    &::after {
      content: '';
      width: 94px;
      height: 82px;
      background: url(../images/icon-percentage.svg) no-repeat center;
      background-size: 100%;
      position: absolute;
      right: -30px;
      bottom: 15px; }
    &__wrap {
      position: relative;
      background: url(../images/ticket-center.png) repeat-x;
      background-size: contain;
      padding: 28px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 17px;
        background: url(../images/ticket-left.png) no-repeat;
        background-color: #F1F5F9;
        background-size: 100% 100%; }
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 18px;
        background: url(../images/ticket-right.png) no-repeat;
        background-color: #F1F5F9;
        background-size: 100% 100%; } }
    .title {
      @include font-600();
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
      display: block; }
    .price {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .pr {
        display: inline-flex;
        font-size: 32px;
        line-height: 48px;
        color: #232933;
        @include font-600(); }
      .tenor {
        font-size: 14px;
        line-height: 20px;
        color: #626B79; }
      .desc, .desc p {
        font-size: 14px;
        line-height: 20px;
        color: #626B79; }
      .snk {
        font-size: 14px;
        line-height: 20px;
        color: #626B79; } } }
  @include maxtablet() {
    padding: 32px 0;
    .section-head {
      .section-head__meta,
      .section-head__subtitle {
        display: flex;
        align-items: center;
        span {
          display: inline-flex;
          &.pill {
            flex: 0 0 75px; }
          &.label {
            flex: 0 0 calc(100% - 75px); } } }
      .section-head__title {
        .underline {
          margin-right: 6px; } } }
    .ticket {
      margin-top: 8px;
      margin-bottom: 24px;
      margin-right: 0;
      &::after {
        width: 71px;
        height: 62px;
        right: -15px; }
      .desc {
        padding-right: 120px; } } }
  @include mobilelg() {
    .ticket {
      &::after {
        right: 0; }
      .desc {
        padding-right: 30px; } } } }
