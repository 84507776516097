.masthead--contact {
  .masthead__content {
    padding: 64px 0; }
  .masthead__title {
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 0;
    @include font-700; }
  .masthead__meta {
    text-align: center;
    display: block; }
  @include maxtablet() {
    .masthead__wrap {
      padding: 0; }
    .masthead__content {
      padding: 32px 10px; }
    .masthead__title {
      font-size: 36px;
      line-height: 44px; }
    .masthead__meta {
      text-align: center;
      display: block; } } }

.contact-cards--alt {
  padding: 64px 0;
  .item {
    margin-bottom: 0;
    &__wrap {
      background: #FFF;
      border: 1px solid #D0D6DD;
      border-radius: 4px;
      padding: 32px; }
    &--sm {
      padding: 0; } }
  @include maxtablet() {
    padding: 32px 10px;
    .item {
      margin-bottom: 24px; } } }

.contact-headquarter {
  padding: 64px 0;
  &__title {
    text-align: center; }
  &__desc {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 3rem;
    color: #232933; }
  .info--img {
    position: relative;
    z-index: 2;
    .img {
      max-height: 440px;
      max-width: 496px;
      overflow: hidden;
      border-radius: 4px;
      img {
        object-fit: contain;
        width: 100%;
        height: auto; } } }
  .info {
    position: relative;
    z-index: 1;
    &:before {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      width: 110%;
      height: 100%;
      top: 0;
      right: 0;
      background: #F2F4F7;
      border-radius: 0.25rem; }
    &__wrap {
      position: relative;
      z-index: 2;
      padding: 64px 64px 64px 45px; }
    &__title {
      margin: 0 0 1rem; }
    &__desc {
      p {
        margin: 0 0 1rem; }
      ul li:not(:last-child) {
        margin: 0 0 1rem; } }
    &__action {
      padding-top: 2.5rem; } }
  @include maxtablet() {
    padding: 32px 10px;
    background: #F2F4F7;
    &__desc {
      margin-bottom: 32px; }
    .info {
      &::before {
        display: none; }
      &__wrap {
        padding: 32px 0 0 0; } } } }


.our-offices {
  padding: 64px 0;
  &__title {
    text-align: center;
    @include font-600; }
  &__desc {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 3rem;
    color: #545465; }
  .item {
    margin: 0;
    width: 100%;
    max-width: 12.5%;
    flex: 0 0 12.5%;
    &__wrap {
      text-align: center;
      padding: 24px 0;
      // border: 1px solid $border
      border-radius: 0.25rem; }
    &__img {
      margin: 0 auto 1rem;
      text-align: center;
      img {
        display: inline-block;
        width: auto;
        height: 40px; } }
    &__title {
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 0.5rem;
      text-align: center;
      @include font-600;
      &:last-child {
        margin: 0; } }
    &__desc {
      p {
        margin: 0 0 0.75rem; }
      ul li:not(:last-child) {
        margin: 0 0 0.75rem; } } }

  @include maxtablet() {
    padding: 32px 10px;
    .item {
      margin: 0 0 1rem;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%; } } }


.form-contact {
  padding: 64px 0;
  background: #F2F4F7;
  @include maxtablet() {
    padding: 32px 0; }
  &__title {
    @include font-600();
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
    text-align: center; }
  &__sosproof {
    .form-contact__testi-title {
      @include font-600();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
      display: block; }
    .form-contact__testimonial {
      background: #FFF;
      padding: 44px 32px;
      border-radius: 6px;
      margin-bottom: 32px; }
    .form-contact__quote {
      margin: 0 0 16px 0; }
    .form-contact__icon {
      display: inline-block;
      padding-right: 24px;
      margin-right: 24px;
      border-right: 1px solid #E2E2E2;
      vertical-align: middle; }
    .form-contact__meta {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 146px); }
    .form-contact__img {
      width: 48px!important;
      height: 48px!important;
      display: inline-block!important;
      object-fit: contain;
      border-radius: 50%;
      margin-right: 16px;
      vertical-align: middle; }
    .form-contact__author {
      width: calc(100% - 69px);
      display: inline-block;
      vertical-align: middle;
      .form-contact__name {
        @include font-600();
        font-size: 16px;
        line-height: 24px; } }
    .form-contact__clients {
      margin: 32px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      li {
        flex: 0 0 22.1%;
        max-width: 22.1%;
        display: flex;
        width: 148px;
        height: 56px;
        margin: 10px;
        align-items: center;
        justify-content: center; } }
    .form-contact__logo {
      width: 141px;
      display: inline-block;
      vertical-align: middle; }
    .slider {
      .form-contact__testimonial {
        margin-bottom: 0; }
      .owl-dots {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 32px 14px;
        .owl-dot {
          background: #8B95A5;
          width: 43px;
          height: 4px;
          border-radius: 4px;
          &.active {
            background: #4B61DD; } } } }
    @include maxtablet {
      .form-contact__meta {
        width: 100%;
        margin-bottom: 24px; }
      .form-contact__clients {
        li {
          flex: 0 0 28%;
          max-width: 28%; } } } }

  &__form-box {
    margin-left: 40px;
    .form-meeting__box {
      padding: 32px 24px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
      border-radius: 6px; }
    @include maxtablet {
      margin-left: 0;
      padding: 0;
      margin-bottom: 32px;
      &__title {
        font-size: 21px;
        line-height: 28px;
        margin-bottom: 20px; } } } }


#datemeeting-sm {
  .col-7,
  .col-5 {
    width: 100%;
    max-width: 100%;
    flex: 100%; }
  .col-7 {
    margin-bottom: 12px; } }
