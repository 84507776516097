.lottie-block {
  padding: 64px;
  &__title {
    text-align: center;
    margin-bottom: 24px; }
  dotlottie-player,
  lottie-player {
    width: 100%!important; }

  &.inapp {
    padding: 40px 0; }

  @include maxtablet() {
    padding: 32px 0; }
  @include mobilelg() {
    &__title {
      br {
        display: none; } } } }


