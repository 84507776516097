/* ------------------------------------------------------------------------------------------------ */
/* Common */
/* ------------------------------------------------------------------------------------------------ */

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }


hr {
  border: 0;
  border-top: 1px solid #e2e2e2; }

.tab-pane {
  &:not(.active) {
    display: none; } }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  &::-webkit-scrollbar-track {
    background: #D0D6DD;
    border-radius: 10px; }
  &::-webkit-scrollbar-thumb {
    background: rgba(#4B61DD,.8);
    border-radius: 5px;
 }    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5)
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(#333,.4); } }

// section:not(.masthead),
// footer,
// .footer
//   content-visibility: auto

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1248px; } }

.container {
  padding-left: 12px;
  padding-right: 12px; }

.row {
  margin-left: -12px;
  margin-right: -12px;
  &> .col,
  &> [class*="col-"] {
    padding-left: 12px;
    padding-right: 12px; } }


.hidden {
  display: none!important; }

input,textarea {
  padding: 0;
  border-radius: 0; }
.block {
  display: block; }
.fright {
  float: right; }
.text-right {
  text-align: right; }

p {
  margin: 0;
  padding: 0;
  strong,b {
    font-weight: bold; }
  em,i {
    font-style: italic; }
  u {
    text-decoration: underline; } }

h1, h2, h3, h4 {
  margin: 0 0 8px; }

article {
  p {
    word-break: break-word;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    clear: both; }
  a {
    &:visited {
      text-decoration: underline; }
    &:hover {
      text-decoration: none; } }
  blockquote {
    font-style: italic;
    &:before {
      content: "\201C";
      display: inline-block;
      padding-right: 0.4em; } }
  li {
    ul, ol {
      margin: 0 1.5em; } }
  ul, ol {
    margin: 0 0 32px;
    &:last-child {
      margin: 0; }
    padding-left: 20px; }

  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      list-style: upper-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: lower-alpha; } } } }
  li {
 }    //margin-bottom: 10px

  iframe {
    border: 0; } }

ul,ol {
  padding: 0;
  margin: 0; }

figure {
  margin: 0; }

figcaption {
  font-size: 14px;
  line-height: 20px;
  color: #626B79;
  margin: 8px 0; }

img {
  max-width: 100%;
  height: auto;
  &.aligncenter {
    display: block;
    margin: auto; }
  &.alignright {
    float: right;
    margin-left: 16px; } }

.font-16 {
  font-size: 16px;
  line-height: 26px; }

ul {
  list-style: none; }
label {
  font-weight: normal; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

html, body {
  @include font-400();
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: $text;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  input {
    font-family: inherit; } }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }


input {
  &:focus {
    outline: none;
    box-shadow: none; } }
* {
  outline: none !important; }

.text-center {
  text-align: center; }







