.sidebox-block {
  padding: 64px 0;
  background: #F1F5F9;
  &__title {
    margin-bottom: 24px; }
  .items {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 24px;
    .item {
      display: flex;
      flex: 0 0 calc(50% - 12px);
      padding: 24px;
      background: #FFF;
      border-radius: 4px;
      flex-wrap: wrap;
      &__icon {
        margin-bottom: 8px;
        width: 100%;
        img {
          height: 52px;
          width: auto; } }
      &__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        display: block;
        width: 100%; } } }
  @include maxtablet() {
    padding: 32px 0;
    &__img {
      margin-top: 24px;
      text-align: center; } }
  @include mobilelg() {
    background: #FFF;
    &__title {
      text-align: center; }
    .items {
      .item {
        flex: 0 0 100%;
        padding: 0; } } } }
