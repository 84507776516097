.masthead {
  &--whatsnew {
    .masthead__left {
      text-align: left; }
    .masthead__meta {
      display: inline-block;
      color: #FFF;
      font-size: 12px;
      line-height: 18px;
      padding: 4px 8px;
      border-radius: 4px;
      background: #128297;
      margin-bottom: 8px; }
    .masthead__title {
      font-size: 40px;
      line-height: 48px; }
    .masthead__desc {
      margin: 0;
      max-width: 100%; }
    .masthead__action {
      padding-top: 32px; }
    .masthead__media {
      padding-top: 40px;
      img {
        width: 100%;
        height: auto; } }
    .masthead__wrap {
      padding-top: 24px;
      padding-bottom: 0; }
    .masthead__content {
      padding-bottom: 0; }
    @include maxtablet {
      .masthead__title {
        font-size: 36px;
        line-height: 44px;
        br {
          display: none; } }
      .masthead__wrap {
        padding-bottom: 0; }
      .masthead__desc {
        br {
          display: none; } } } } }

.whatsnew {
  &__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 40px; }

  &--release {
    padding-top: 80px;
    padding-bottom: 32px;
    .post {
      &__img {
        display: block;
        position: relative;
        width: 100%;
        max-width: 34%;
        flex: 0 0 34%;
        .img {
          display: block;
          position: relative;
          width: 100%;
          padding-top: 62%; }
        img {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 4px; } }
      &__content {
        display: block;
        padding-left: 20px;
        width: 100%;
        max-width: 66%;
        flex: 0 0 66%; }
      &__title {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 12px; }
      &__buttons {
        padding-top: 16px; }
      &__wrap {
        display: flex;
        padding-bottom: 48px; }
      &--large {
        .post__wrap {
          flex-wrap: wrap;
          padding-right: 40px; }
        .post__img,
        .post__content {
          max-width: 100%;
          flex: 0 0 100%;
          padding: 0; }
        .post__img {
          margin-bottom: 24px; }
        .post__title {
          font-size: 32px;
          line-height: 40px; }
        .post__buttons {
          padding-top: 32px; } } } }

  &--power {
    padding-top: 80px;
    padding-bottom: 80px;
    .post {
      &.load-more-post {
        display: none; }
      &__img {
        display: block;
        position: relative;
        width: 100%;
        max-width: 40%;
        flex: 0 0 40%;
        .img {
          display: block;
          position: relative;
          width: 100%;
          padding-top: 62%; }
        img {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 4px; } }
      &__content {
        display: block;
        padding-left: 24px;
        width: 100%;
        max-width: 60%;
        flex: 0 0 60%; }
      &__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px; }
      &__buttons {
        padding-top: 24px; }
      &__wrap {
        display: flex;
        align-items: center;
        padding-bottom: 40px; } } }

  &__loadmore {
    padding-top: 24px; }

  @include maxtablet {
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 24px; }
    &--release {
      padding-top: 48px;
      padding-bottom: 0;
      .post {
        &__title {
          font-size: 18px;
          line-height: 28px; }
        &__wrap {
          padding-bottom: 40px; }
        &--large {
          .post__wrap {
            padding-right: 0; }
          .post__buttons {
            padding-top: 16px; }
          .post__title {
            font-size: 20px;
            line-height: 32px; } } } }

    &--power {
      padding-top: 48px;
      padding-bottom: 64px;
      padding-left: 48px;
      padding-right: 48px;
      .post {
        &__wrap {
          flex-wrap: wrap;
          padding-bottom: 40px; }
        &__img {
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 24px; }
        &__content {
          max-width: 100%;
          flex: 0 0 100%;
          padding: 0; }
        &__title {
          font-size: 20px;
          line-height: 32px;
          margin-bottom: 16px; } } } } }

.block-timeline {
  padding-top: 64px;
  padding-bottom: 64px;
  background: #F2F4F7;

  &__title {
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 40px; }

  .timeline-wrapper {
    .issue-container {
      .date {
        display: block;
        font-size: 12px;
        line-height: 20px;
        color: #545465;
        margin-bottom: 8px; }
      .title {
        display: block;
        @include font-500();
        font-size: 32px;
        line-height: 40px;
        color: #2B2A35;
        margin-bottom: 16px; }
      .buttons {
        padding-top: 16px;
        a {
          text-decoration: underline; } } }

    @mixin legend($color) {
      .dot {
        background: $color; }
      &.selected .label {
        background: $color; }
      &.issue-container {
        border-top: 3px solid $color; } }

    .legend-1,
    .legend-laporan {
      @include legend(#4B7BEC); }
    .legend-2,
    .legend-inventory {
      @include legend(#F5BC42); }
    .legend-3,
    .legend-akuntansi {
      @include legend(#46978D); }
    .legend-4,
    .legend-biaya {
      @include legend(#6557D6); }
    .legend-5,
    .legend-mekari-pay {
      @include legend(#A6E1FA); }
    .legend-6,
    .legend-perpajakan {
      @include legend(#00BCD4); }
    .legend-7,
    .legend-faktur {
      @include legend(#3F51B5); }
    .legend-8,
    .legend-mobile-app {
      @include legend(#EE5253); }

    .slides-wrapper {
      position: relative;
      padding-bottom: 24px;
      &::before,
      &::after {
        z-index: 3;
        content: "";
        display: block;
        width: 72px;
        height: 100%;
        background: #F2F4F7;
        position: absolute;
        top: 0; }
      &::before {
        left: 0; }
      &::after {
        right: 0; }
      .line {
        bottom: 30px; }
      #next,
      #prev {
        z-index: 4;
        background: transparent;
        bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        border: 1px solid #B4B4B4;
        &:hover {
          border-color: $blue;
          background: #FFF;
          box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
          svg path {
            fill: $blue; } } } }

    .timeline-legends {
      text-align: center;
      max-width: 1000px;
      padding-top: 32px;
      margin: 0 auto;
      .item {
        display: inline-block;
        position: relative;
        font-size: 14px;
        line-height: 20px;
        padding-left: 28px;
        margin: 10px 24px;
        .dot {
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          left: 0;
          top: 2px;
          border-radius: 8px; } } } }

  @include maxtablet {
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 24px; }
    .timeline-wrapper {
      .timeline-legends {
        column-count: 2;
        column-gap: 18px;
        .item {
          display: inline-block;
          text-align: left;
          width: 100%;
          margin: 6px 0;
          font-size: 12px;
          line-height: 18px;
          padding-left: 20px;
          .dot {
            width: 14px;
            height: 14px; } } } } } }
