/* ------------------------------------------------------------------------------------------------ */
/* Content */
/* ------------------------------------------------------------------------------------------------ */

.masthead {
  &__meta,&__subtitle {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    margin-bottom: 8px;
    color: #232933;
    @include font-600();
    .pill {
      margin-left: 4px; } }

  &__title {
    @include font-700();
    color: $black;
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 56px;
    color: $black;
    @include mobile {
      font-size: 28px;
      line-height: 36px; } }
  &__content {
    padding: 64px 0;
    &.no-align {
      text-align: unset !important;
      p {
        max-width: 100% !important; } } }
  &__action {
    margin-left: -8px;
    margin-right: -8px;
    .btn {
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 16px; }
    // .btn + .btn
 }    //   margin-left: 16px
  &__form {
    background: #FFFFFF;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    padding: 32px;
    border-radius: 8px; }
  &--home {
    @include maxtablet {
      .masthead__wrap {
        padding-top: 4rem!important; }
      .masthead__content {
        padding: 0 12px 0 12px!important; } } }
  &--home,
  &--fitur-alt {
    position: relative;
    .masthead__title {
      margin-left: auto;
      margin-right: auto;
      max-width: 83.333333%;
      @include mobile {
        max-width: 100%; } }
    .masthead__content {
      text-align: center;
      padding-bottom: 48px;
      p {
        max-width: 600px;
        margin: 0 auto 40px; } }
    .masthead__action {
      .btn {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 16px;
        @include mobile {
          width: 100%;
          margin-left: 0;
          margin-right: 0; } } }
    &.animate {
      .masthead__title,
      .masthead__content p,
      .masthead__action {
        transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 0;
        transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .masthead__content p {
        transition-delay: .25s; }
      .masthead__action {
        transition-delay: .2s; } }
    &.animate-in {
      .masthead__title,
      .masthead__content p,
      .masthead__action {
        transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 1; } } }

  &--fitur-alt {
    .masthead__content {
      padding-bottom: 80px;
      p:last-child {
        margin-bottom: 0; } } }
  &--alt {
    .masthead__media {
      position: relative;
      &.show-sm {
        margin-bottom: 16px; }
      .media {
        display: block;
        position: relative;
        width: 100%;
        &--video {
          position: relative; }
        &--img {
          // padding-left: 40px
          img {
            display: block;
            width: 100%;
            height: auto; } }
        &__cover {
          display: block;
          width: 100%;
          height: 100%; }
        &__play {
          display: block;
          width: 80px;
          height: 80px;
          border-radius: 40px;
          background: url(../images/ic-play-circle-alt.svg) no-repeat center;
          background-size: contain;
          position: absolute;
          top: calc(50% - 40px);
          left: calc(50% - 40px);
          @include pulse(); } } }
    .masthead__wrap {
      padding-top: 1.875rem;
      padding-bottom: 1.875rem; }
    .masthead__content {
      padding-top: 0;
      padding-bottom: 0; }
    .masthead__desc {
      margin-bottom: 24px; }
    .owl-dots {
      .owl-dot {
        background: #E2E2E2;
        width: 32px;
        height: 4px;
        border-radius: 2px;
        &.active {
          background: #009BDD; } }
      @include maxtablet() {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0; } } }
  &--seo {
    position: relative;
    .masthead__title {
      @include font-600;
      font-size: 48px;
      line-height: 56px;
      color: $black;
      margin: 0 0 24px;
      margin-left: auto;
      margin-right: auto;
      max-width: 83.333333%;
      @include mobile {
        max-width: 100%;
        font-size: 2rem;
        line-height: 2.5rem; } }
    .masthead__content {
      text-align: center;
      padding-bottom: 48px;
      p {
        max-width: 600px;
        margin: 0 auto 40px; } }
    .masthead__meta {
      display: inline-flex; }
    .masthead__desc {
      margin-bottom: 24px; }
    .masthead__action {
      .btn {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 16px;
        @include mobile {
          width: 100%;
          margin-left: 0;
          margin-right: 0; } } }
    &.animate {
      .masthead__title,
      .masthead__content p,
      .masthead__action {
        transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 0;
        transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .masthead__content p {
        transition-delay: .2s; }
      .masthead__action {
        transition-delay: .2s; } }
    &.animate-in {
      .masthead__title,
      .masthead__content p,
      .masthead__action {
        transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 1; } } }

  &--seo {
    .masthead__content {
      padding-bottom: 80px;
      p:last-child {
        margin-bottom: 0; } } }

  &--m-overview,
  &--m-integration,
  &--fitur,
  &--solusi,
  &--pricing-alt {
    .masthead__left {
      padding-top: 36px;
      padding-bottom: 36px; }
    .masthead__content {
      padding-top: 50px;
      padding-bottom: 90px; }
    .masthead__desc {
      margin-bottom: 24px;
      p {
        &:last-child {
          margin-bottom: 0; } } }
    .masthead__action {
      &+ .masthead__media {
        padding-top: 32px; } }
    .masthead__media {
      position: relative;
      .media {
        display: block;
        position: relative;
        width: 100%;
        &--video {
          padding-top: 83.333333%;
          position: relative; }
        &--img {
          // padding-left: 40px
          img {
            display: block;
            width: 100%;
            height: auto; } }
        &__cover {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover; }
        &__play {
          display: block;
          width: 80px;
          height: 80px;
          border-radius: 40px;
          background: url(../images/ic-play-circle-alt.svg) no-repeat center;
          background-size: contain;
          position: absolute;
          top: calc(50% - 40px);
          left: calc(50% - 40px);
          @include pulse(); } } }
    .masthead__full {
      padding-top: 30px;
      padding-bottom: 40px;
      .masthead__media {
        .media--video {
          padding-top: 40.849673%; } } } }
  &--m-customers {
    .masthead__meta {
      justify-content: center; } }
  &--m-integration {
    .masthead__content {
      padding-bottom: 50px; } }
  &--m-customers,
  &--solusi-center,
  &--pricing {
    .masthead__subtitle,
    .masthead__meta {
      text-align: center;
      justify-content: center; }
    .masthead__title {
      margin: 0;
      &.small {
        font-size: 40px;
        line-height: 56px;
        margin-bottom: 8px;
        @include mobile {
          font-size: 2rem;
          line-height: 2.5rem; } } }
    .masthead__content {
      text-align: center;
      padding-bottom: 48px; }
    .masthead__media {
      padding-top: 48px;
      position: relative;
      .media {
        display: block;
        position: relative;
        width: 100%;
        &--img {
          img {
            display: block;
            width: 100%;
            height: auto; } }
        &--logo {
          display: flex;
          justify-content: center;
          margin-left: -8px;
          margin-right: -8px; }
        .logo-col {
          position: relative;
          display: block;
          width: 100%;
          max-width: 14.285714%;
          flex: 0 0 14.285714%;
          padding: 0 8px;
          &:nth-child(1) {
            margin-top: 9%; }
          &:nth-child(2) {
            margin-top: 14%; }
          &:nth-child(3) {
            margin-top: 7%; }
          &:nth-child(5) {
            margin-top: 14%; }
          &:nth-child(6) {
            margin-top: 7%; }
          &:nth-child(7) {
            margin-top: 14%; }
          .item {
            position: relative;
            width: 100%;
            margin-bottom: 16px;
            overflow: hidden;
            border-radius: 4px;
            box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2); }
          img {
            display: block;
            width: 100%;
            height: auto;
            margin: 0; } } } } }

  &--fitur,
  &--pricing-alt {
    .masthead__content {
      padding-bottom: 30px; } }
  &--promo {
    background: radial-gradient(73.27% 70.82% at 50% 65.13%, #81B8D4 0%, #007DE5 37.44%, #004DC2 100%);
    color: #FFF;
    .masthead__left {
      text-align: left;
      padding-right: 30px;
      @include maxtablet {
        padding-right: 12px; } }
    .masthead__title {
      color: #FFF;
      position: relative; }
    .masthead__hero {
      margin-bottom: 15px; }
    .masthead__content {
      text-align: center; }
    .masthead__desc {
      margin-bottom: 24px; }
    .masthead__action {
      .btn {
        &:last-child {
          margin-left: 12px;
          @include maxtablet {
            margin-left: 0; } } } }


    .masthead__logo {
      margin-bottom: 48px; }

    .masthead__form {
      background: $white;
      box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
      border-radius: 8px;
      padding: 40px;
      text-align: left;
      color: $text;
      .form-group {
        &> label {
          @include font-500;
          font-size: 16px;
          line-height: 24px;
          color: $text; } }
      @include mobile {
        padding: 40px 16px; } }

    &-alt {
      .masthead__content {
        padding: 0 0 35px; }
      .masthead__title {
        font-size: 2rem;
        line-height: 3rem; }
      .masthead__desc {
        margin-bottom: 2rem; } } }

  &--landing {
    @include mobile {
      .masthead__hero {
        display: none; } } }
  @include maxtablet {
    .masthead__wrap {
      padding: 1.875rem 8px; }
    .masthead__content {
      padding: 0 12px 40px 12px; }
    .masthead__meta,
    .masthead__subtitle {
      font-size: 16px;
      line-height: 24px;
      display: flex;
      margin-bottom: 0;
      justify-content: space-between;
      align-items: start;
      @include font-600();
      &> span {
        &.badge {
          width: 156px; } }
      div {
        .span.badge {
          width: auto; } } }
    .masthead__title {
      font-size: 32px;
      line-height: 40px; }
    .masthead__action {
      margin-left: 0;
      margin-right: 0;
      a {
        margin-left: 0;
        margin-right: 0;
        &.first,&:first-child {
          margin-bottom: 16px; } } }
    .masthead__media {
      margin-top: 25px; }
    .masthead__form {
      margin-top: 40px;
      padding: 24px; }


    &--m-overview,
    &--m-integration,
    &--fitur {
      .masthead__left {
        padding-top: 18px;
        padding-bottom: 32px; }
      .masthead__content {
        padding-top: 25px;
        padding-bottom: 60px; }
      .masthead__action {
        padding-top: 0; }
      .masthead__media {
        .media--img {
          padding-left: 0; } } }
    &--m-integration {
      .masthead__content {
        padding-bottom: 30px; } }
    &--m-customers {
      .masthead__meta {
        justify-content: center!important; }
      .masthead__content {
        text-align: center; }
      .masthead__media {
        padding-top: 48px; } }
    &--fitur {
      .masthead__content {
        padding-bottom: 20px; } } }
  &--promo-alt {
    background: conic-gradient(from 62.36deg at 64.53% 47.25%, #0050C4 0deg, #008CF2 360deg);
    color: #FFF;
    padding: 5rem 0 24px 0;
    .masthead {
      &__content {
        padding-bottom: 0; }
      &__title {
        color: #FFF;
        font-size: 3rem;
        line-height: 3.5rem; }
      &__desc {
        margin: 0 0 40px 0; }
      &__action {
        margin-bottom: 48px;
        .btn-white {
          color: #009BDE;
          border-color: #FFF;
          &:hover {
            background: darken(#FFF, 10%); } }

        .btn-white-border {
          &:hover {
            color: #009BDE!important; } } }
      &__logo {
        margin-bottom: 48px; } }
    @include maxtablet {
      background: conic-gradient(from 62.36deg at 64.53% 47.25%, #0050C4 0deg, #008CF2 360deg);
      padding: 5rem 0 24px 0;
      .masthead {
        &__logo {
          margin-bottom: 40px;
          img {
            width: 130px; } }
        &__wrap {
          padding-top: 0;
          padding-bottom: 0; }
        &__content {
          padding-top: 0; }
        &__title {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 40px;
          br {
            display: none; } }
        &__desc {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 12px;
          display: none;
          br {
            display: none; } }
        &__action {
          margin-bottom: 40px;
          padding: 0 16px;
          a {
            display: block;
            width: 100%;
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0; } } }
        &__img {
          img {
            height: auto; } } } } }
  &--industry {
    .masthead__content {
      padding-top: 32px;
      padding-bottom: 32px; }
    .masthead__desc {
      margin-bottom: 24px; } } }

.featured {
  &__images {
    position: relative;
    max-width: 900px;
    margin: 0 auto 48px;
    .item {
      position: relative;
      display: block;
      position: relative;
      width: 100%;
      padding-top: 62%;
      margin: 0 auto;
      // &:not(.show)
      //   display: none
      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        object-fit: contain; } }
    @include maxtablet {
      margin-bottom: 12px;
      .item {
        width: calc(100% - 72px); } } }

  &__thumbs {
    position: relative;
    .owl-nav {
      display: none; }
    .nav {
      display: flex;
      justify-content: center;
      align-items: stretch;
      margin-left: -12px;
      margin-right: -12px; }
    .item {
      display: block;
      width: 100%;
      max-width: 25%;
      flex: 0 0 25%;
      padding-left: 12px;
      padding-right: 12px;
      &__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        color: $black;
        @include font-500(); } }
    @include maxtablet() {
      margin-left: 20px;
      margin-right: 20px;
      padding-left: 15px;
      padding-right: 15px;
      align-items: center;
      .owl-nav {
        display: block; }
      .item {
        max-width: 100%;
        flex: 0 0 100%;
        &:not(.active) {
          display: none; } } }
    .nav-link {
      display: flex;
      position: relative;
      height: 100%;
      flex-direction: column;
      position: relative;
      padding: 20px;
      border: 1px solid $border;
      border-radius: 4px;
      cursor: pointer;
      min-height: 144px;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
      transition: all .3s ease-in-out;
      &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 4px;
        border: 2px solid #009BDE;
        opacity: 0;
        transition: all .4s ease; }
      &.active {
        &:after {
          opacity: 1; } } } }

  &__slider {
    position: relative;
    max-width: 1100px;
    margin: 0 auto 48px;
    .owl-stage-outer {
      overflow: visible;
      .owl-item {
        opacity: 1;
        transform: scale(1);
        transition: all .75s ease-in-out; }
      .owl-item:not(.active) {
        opacity: 0;
        transform: scale(.5); } }
    .item {
      position: relative;
      display: block;
      position: relative;
      width: 100%;
      padding-top: 62%;
      margin: 0 auto;
      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        object-fit: contain; }
      @include mobile {
        width: 100%;
        height: 62%; }
      @include mobilesm {
        padding-top: 40%; } }
    // &:not(.owl-loaded)
    //   .item:not(:first-child)
 }    //     display: none
  &__tab {
    position: relative;
    max-width: 1224px;
    margin: 0 auto -16px;
    .owl-item {
      padding: 0 12px;
      &:first-child {
        padding-left: 0; }
      &:last-child {
        padding-right: 0; } }
    .item {
      position: relative;
      padding: 20px;
      border: 1px solid $border;
      border-radius: 4px;
      cursor: pointer;
      min-height: 144px;
      margin-bottom: 16px;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
      transition: all .3s ease-in-out;
      &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }
      .progress {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0; }
      &__title {
        @include font-500;
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        color: $black; } }
    .current .item {
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
      .progress {
        opacity: 1;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
        path,
        rect {
          @include dash(9s); } } } }

  &--home {
    padding-bottom: 80px;
    min-height: 50vh;
    .featured__slider {
      max-width: 900px; }

    &.animate {
      .featured__wrap {
        transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 0;
        transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-delay: .75s; } }
    &.animate-in {
      .featured__wrap {
        transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 1; } } }


  &--fitur {
    padding-bottom: 80px;
    .featured__images {
      max-width: 808px; }
    .featured__thumbs {
      .item {
        max-width: 33.333333%;
        flex: 0 0 33.333333%;
        &__action {
          position: relative;
          z-index: 2; }
        @include mobile() {
          max-width: 100%;
          flex: 0 0 100%; } } }
    &.animate {
      .featured__wrap {
        transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 0;
        transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-delay: .75s; } }
    &.animate-in {
      .featured__wrap {
        transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 1; } } }

  &--solusi {
    padding-top: 80px;
    padding-bottom: 80px;
    .section-head {
      padding-bottom: 32px; }
    .featured__slider {
      max-width: 808px; }
    .featured__tab .item {
      min-height: 170px; }
    &.animate {
      .featured__wrap {
        transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 0;
        transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-delay: .75s; } }
    &.animate-in {
      .featured__wrap {
        transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 1; } } }

  @include responsive(1200px) {
    &__slider {
      .item {
        width: 80%;
        margin-left: auto;
        margin-right: auto; } }
    &__tab {
      padding: 0 24px;
      .owl-item {
        padding: 0 12px;
        &:first-child {
          padding-left: 12px; }
        &:last-child {
          padding-right: 12px; } } }
    .owl-nav {
      .owl-prev {
        left: -20px; }
      .owl-next {
        right: -20px; } } }

  @include mobilesm {
    &--home,
    &--fitur {
      padding-bottom: 60px; } } }

.reason {
  &__slider {
    &__wrap {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center; }
    .item {
      display: block;
      width: 100%;
      max-width: 624px;
      position: relative;
      .img {
        width: 100%;
        height: auto;
        transform: scale(1);
        opacity: 1;
        transition: all .4s ease-in-out;
        // &.img-out
        //   transform: scale(1.1)
        //   opacity: .1
        // &.img-in
        //   transform: scale(1)
 } } }        //   opacity: 1

  &__title {
    margin-bottom: 32px; }
  &__tab {
    position: relative;
    ul {
      position: relative; }
    .item {
      position: relative;
      display: block;
      width: 100%;
      padding-left: 46px;
      margin-bottom: 32px;
      // cursor: pointer
      opacity: .5;
      @include font-400;
      transition: all .4s linear;
      &:before,
      &:after {
        z-index: 2;
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 8px;
        left: 8px;
        border-radius: 4px;
        background: $text;
        transform: none;
        transition: transform .1s ease-in-out, background .2s linear; }
      &:before {
        z-index: 1;
        background: #005FBF;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        top: 2px;
        left: 2px;
        opacity: .2;
        transform: scale(0); }
      &:hover,
      &.active {
        opacity: 1;
        @include font-500;
        &:before {
          transform: scale(1); }
        &:after {
          background: $blue; } } } }
  &__cta {
    margin-left: -12px;
    margin-right: -12px;
    .btn {
      margin: 0 12px; } }

  &--home {
    padding-top: 80px;
    padding-bottom: 80px;
    &.animate {
      .reason__slider {
        transform: translateX(-100px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 0;
        transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-delay: .2s; }
      .reason__title,
      .reason__tab .item,
      .reason__cta {
        transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 0;
        transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .reason__title {
        transition-delay: 1s; }
      .reason__tab .item {
        &:nth-child(1) {
          transition-delay: 1.25s; }
        &:nth-child(2) {
          transition-delay: 1.5s; }
        &:nth-child(3) {
          transition-delay: 1.75s; }
        &:nth-child(4) {
          transition-delay: 2s; }
        &:nth-child(5) {
          transition-delay: 2.25s; } }
      .reason__cta {
        transition-delay: 2.5s; } }
    &.animate-in {
      .reason__slider,
      .reason__title,
      .reason__tab .item,
      .reason__cta {
        transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 1; } } }


  @include maxtablet {
    &__cta {
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      .btn {
        min-width: 100%;
        margin: 0;
        &:first-child {
          margin-bottom: 16px; } } } }

  @include mobilesm {
    &__slider {
      .item {
        padding: 0;
        margin-bottom: 24px; } }
    &--home {
      padding: 60px 0; } } }

.feature {
  &__title {
    max-width: 600px;
    margin: 0 auto 16px;
    text-align: center; }
  &__content {
    max-width: 600px;
    margin: 0 auto 48px;
    text-align: center; }
  &__action {
    display: inline-block; }
  .item {
    margin-bottom: 48px;
    &__img {
      position: relative;
      max-height: 56px;
      margin-bottom: 8px;
      .img {
        display: block;
        height: 100%;
        width: auto; }
      @include mobile {
        vertical-align: top;
        display: inline-block;
        width: 56px;
        margin-right: 8px; } }
    &__meta {
      @include mobile {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 68px); } }
    &__title {
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 12px; } }

  &--home {
    padding-top: 80px;
    padding-bottom: 32px;
    &.animate {
      .feature__title,
      .feature__content p,
      .feature__action,
      .col-lg-3 {
        transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 0;
        transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); } }
    &.animate-in {
      .feature__title,
      .feature__content p,
      .feature__action,
      .col-lg-3 {
        transform: translateX(0px) translateY(0) translateZ(0px) rotate(0deg) scale(1, 1);
        opacity: 1; }
      .feature__content p {
        transition-delay: .25s; }
      .feature__action {
        transition-delay: .5s; }
      .col-lg-3 {
        &:nth-child(1) {
          transition-delay: .75s; }
        &:nth-child(2) {
          transition-delay: 1s; }
        &:nth-child(3) {
          transition-delay: 1.25s; }
        &:nth-child(4) {
          transition-delay: 1.5s; }
        &:nth-child(5) {
          transition-delay: 1.75s; }
        &:nth-child(6) {
          transition-delay: 2s; }
        &:nth-child(7) {
          transition-delay: 2.25s; }
        &:nth-child(8) {
          transition-delay: 2.5s; } } } }

  &--landing {
    padding-bottom: 64px;
    .feature__title {
      max-width: 808px;
      font-size: 2rem;
      line-height: 2.5rem;
      margin-bottom: 3.375rem; } }


  @include mobilesm {
    &--home {
      padding: 40px 0; } } }

.marquee-container:after {
  content: "";
  display: block;
  width: 100%;
  clear: both; }

.our-customer,
.case-studies {
  &__title {
    max-width: 650px;
    margin: 0 auto 16px;
    text-align: center; }
  &__content {
    max-width: 600px;
    margin: 0 auto 48px;
    text-align: center; }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 16.666667%;
      flex: 0 0 16.666667%;
      min-height: 64px;
      padding: 6px;
      img {
        display: block;
        max-width: 100%;
        height: auto; } }
    @include mobile {
      .item:nth-child(n+10) {
        display: none; } } }
  &__slider {
    margin-bottom: 32px;
    // padding: 0 64px
    opacity: 1;
    transition: all .3s ease-in-out;
    white-space: nowrap;
    .item {
      display: inline-block;
      vertical-align: middle;
      // width: 100%
      // max-width: 10%
      // flex: 0 0 10%
      // align-items: center
      // justify-content: center
      // height: 56px
      position: relative;
      padding: 0 24px;
      img {
        display: block;
        width: auto;
        height: auto;
        max-height: 56px;
        margin: 0 auto; } }
    .owl-stage-outer {
      overflow: visible; }
    // &:not(.owl-loaded)
    //   opacity: 0
    //   .item:not(:first-child)
 }    //     display: none
  &__action {
    text-align: center;
    margin-bottom: 64px;
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; }
      @include maxtablet() {
        margin-right: 0;
        margin-bottom: 16px;
        width: 100%;
        &:last-child {
          margin-bottom: 0; } } } }
  &__cta {
    text-align: center;
    margin-bottom: 64px;
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; }
      @include maxtablet() {
        margin-right: 0;
        margin-bottom: 16px;
        width: 100%;
        &:last-child {
          margin-bottom: 0; } } } }

  &__toolbar {
    padding-top: 10px;
    padding-bottom: 56px;
    position: relative;
    z-index: 2;
    .dropdown.bootstrap-select {
      width: auto;
      display: inline-block;
      .bttn {
        width: auto;
        min-width: 100px;
        padding: 0 30px 0 12px;
        font-size: 14px;
        line-height: 34px;
        &:after {
          top: 0;
          line-height: 36px; } }
      .dropdown-menu {
        min-width: 220px !important;
        .inner {
          overflow-x: hidden; } } }
    .slider-category {
      position: relative;
      overflow: hidden;
      &__nav {
        z-index: 2;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        width: 100%;
        height: 0; }
      &__wrap {
        z-index: 1;
        position: relative;
        white-space: nowrap;
        display: flex;
        width: fit-content; }
      .item-nav {
        width: 60px;
        height: 36px;
        background: #FFF;
        background: linear-gradient(90deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);
        padding: 3px 0;
        cursor: pointer;
        .svg {
          display: block;
          path {
            fill: $text;
            transition: all .3s ease-in-out; } }
        &.prev {
          display: none; }
        &.next {
          margin-left: auto;
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%);
          .svg {
            margin-left: auto; } }
        &:hover {
          .svg path {
            fill: $black; } } }
      .item {
        display: inline-block;
        margin: 0 4px;
        padding: 6px 8px;
        border-radius: 4px;
        &__link {
          color: $text;
          &:hover {
            color: $blue; } }
        &.active {
          background: #F2F4F7;
          .item__link {
            color: $black;
            @include font-500(); } } } } }
  &__posts {
    position: relative; }

  .slider {
    .owl-item {
      &> div {
        max-width: 100%; } } }

  .post {
    margin-bottom: 40px;
    &__img {
      position: relative;
      cursor: pointer;
      .play {
        position: absolute;
        z-index: 2;
        top: calc(50% - 24px);
        left: calc(50% - 24px);
        .icon {
          width: 48px;
          height: 48px;
          display: block; }
        &:after {
          content: "";
          display: block;
          width: 64px;
          height: 64px;
          border-radius: 32px;
          background: $blue;
          opacity: .2;
          position: absolute;
          top: calc(50% - 32px);
          left: calc(50% - 32px);
          transform: scale(.5);
          transition: all .5s ease-in-out; } }
      .block-img {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 56.25%;
        overflow: hidden;
        border-radius: 4px 4px 0 0;
        img {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          z-index: 1; } }
      &:hover {
        .play:after {
          transform: scale(1); } } }

    &__content {
      padding: 24px;
      border: 1px solid $border;
      border-top: 0;
      border-radius: 0 0 4px 4px; }
    &__title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
      a {
        color: $black; }
      &:hover a {
        color: $blue; } }
    &__category {
      display: block;
      margin-bottom: 4px;
      a {
        color: $text; }
      &:hover a {
        color: $text;
        text-decoration: underline; } }
    p {
      display: block;
      padding-top: 8px;
      margin-bottom: 16px; } }
  &__cta {
    text-align: center; }

  &--home {
    position: relative;
    background: #F8FAFC;
    margin-top: 6vw;
    padding-bottom: 80px;
    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 10vw;
      background: url(../images/bg-shape-02.svg) no-repeat top center;
      background-size: 100% auto;
      position: absolute;
      top: -6vw;
      left: 0;
      right: 0;
      z-index: -1; } }

  &--m-overview {
    padding-top: 80px;
    padding-bottom: 80px;
    .our-customer__slider {
      margin-bottom: 0; } }

  &--pricing {
    padding-top: 80px;
    padding-bottom: 80px;
    .our-customer__slider {
      margin-top: 48px;
      margin-bottom: 0; } }

  &--solusi {
    padding-top: 80px;
    padding-bottom: 80px;
    .our-customer__title {
      margin-bottom: 32px; }
    .our-customer__slider {
      margin-bottom: 40px; } }


  &.animate {
    .our-customer__title,
    .our-customer__content,
    .our-customer__slider,
    .our-customer__cta,
    .case-studies__toolbar,
    .our-customer__posts .col-md-4 {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); } }
  &.animate-in {
    .our-customer__title,
    .our-customer__content,
    .our-customer__slider,
    .our-customer__cta,
    .case-studies__toolbar,
    .our-customer__posts .col-md-4 {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; }
    .our-customer__content {
      transition-delay: .25s; }
    .our-customer__slider,
    .our-customer__cta {
      transition-delay: .5s; }
    .our-customer__posts .col-md-4 {
      &:nth-child(1) {
        transition-delay: .75s; }
      &:nth-child(2) {
        transition-delay: 1s; }
      &:nth-child(3) {
        transition-delay: 1.25s; } } }


  @include mobilesm {
    &__toolbar {
      .right {
        padding-top: 16px; } }
    &--home {
      padding-top: 40px;
      padding-bottom: 60px; }
    &--m-overview,
    &--pricing {
      padding-top: 60px;
      padding-bottom: 60px; } } }

.case-studies {
  padding-bottom: 80px;
  .post {
    margin-bottom: 48px;
    &.animate {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    &.animate-in {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }
  &--related {
    padding: 80px 0;
    background: #F8FAFC;
    .post {
      margin-bottom: 0; }
    .section-head {
      position: relative;
      padding-bottom: 24px;
      text-align: left;
      &__title {
        font-size: 24px;
        line-height: 32px;
        margin: 0;
        padding-right: 220px; }
      &__action {
        position: absolute;
        top: 0;
        right: 30px; } } }


  @include mobilesm {
    padding-bottom: 60px;
    .post {
      margin-bottom: 32px; } } }

.get-started {
  position: relative;
  text-align: center;
  padding: 80px 0;
  background: #002A38;
  &:before {
    z-index: 1;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/patterns-01.png) repeat center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .2; }
  &__wrap {
    position: relative;
    z-index: 2; }
  &__title {
    margin-bottom: 8px;
    color: #FFF; }
  &__content {
    max-width: 600px;
    margin: 0 auto 32px;
    color: #FFF; }

  &.animate {
    transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
    opacity: 0;
    transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1);
    .get-started__title,
    .get-started__content,
    .get-started__action {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); } }
  &.animate-in {
    transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
    opacity: 1;
    .get-started__title,
    .get-started__content,
    .get-started__action {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; }
    .get-started__title {
      transition-delay: .25s; }
    .get-started__content {
      transition-delay: .5s; }
    .get-started__action {
      transition-delay: .75s; } }

  @include mobilesm {
    padding: 40px 0; } }

section.desc {
  padding: 64px 0 32px 0;
  .container {
    .row {
      flex-direction: row-reverse;
      @include mobile {
        flex-direction: normal; } } }
  .section-head {
    text-align: left;
    &__title {
      line-height: 40px;
      @include mobile {
        line-height: 30px; } }
    &__action {
      a {
        margin-right: 16px;
        @include mobile {
          margin-right: 0;
          margin-bottom: 16px;
          width: 100%; } } } } }

.whatis {
  background: #F8FAFC;
  @include bgCurveTop();
  &__wrap {
    padding-top: 80px;
    padding-bottom: 48px; }




  .item {
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 32px 0 0;
    margin-bottom: 32px;
    background: #FFF;
    @include mobile {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); }
    &__icon {
      margin: 0 40px 8px;
      img,
      .svg {
        display: block;
        height: 48px;
        width: auto; } }
    &__title {
      font-size: 20px;
      line-height: 32px;
      margin: 0 40px 8px; }
    &__content {
      margin: 0 40px 32px; }
    &__img {
      position: relative;
      .img {
        position: relative;
        width: 100%;
        img {
          width: 100%;
          height: auto; } } } }


  &.animate {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .item {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .section-head__content {
      transition-delay: .25s; }
    .section-head__action {
      transition-delay: .5s; }
    .item {
      transition-delay: .25s; } }

  &.animate-in {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .item {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include mobilesm {
    &__wrap {
      padding-top: 40px;
      padding-bottom: 60px; }
    .item {
      padding-top: 24px;
      &__icon {
        margin: 0 20px 8px; }
      &__title {
        margin: 0 20px 8px; }
      &__content {
        margin: 0 20px 24px; } }

    .small-banner__img {
      display: none; } } }

.whymust {
  background: #F8FAFC;
  @include bgCurveBot();
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }

  &.animate {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .table-wm__container {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .section-head__content {
      transition-delay: .25s; }
    .section-head__action {
      transition-delay: .5s; }
    .table-wm__container {
      transition-delay: .75s; } }

  &.animate-in {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .table-wm__container {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include mobilesm {
    &__wrap {
      padding-top: 40px;
      padding-bottom: 60px; } } }

.table-wm {
  position: relative;
  display: block;
  &__row {
    display: flex;
    border-radius: 4px;
    align-items: center;
    min-height: 80px; }
  &__head {
    display: block;
    position: relative;
    width: 100%;
    max-width: 34.666667%;
    flex: 0 0 34.666667%;
    padding: 16px 24px;
    @include font-500(); }
  &__left {
    display: block;
    position: relative;
    width: 100%;
    max-width: 32%;
    flex: 0 0 32%;
    // padding-right: 16px
    padding: 16px 24px; }
  &__right {
    display: block;
    position: relative;
    width: 100%;
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
    padding: 16px 24px; }

  &__thead {
    display: block;
    padding: 0 8px;
    th, td {
      padding: 10px 24px;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      @include font-500(); } }

  &__tbody {
    display: block;
    padding: 8px;
    border-radius: 4px;
    background: #F2F4F7;
    .table-wm__row:nth-child(odd) {
      background: #E6EBF1; }
    .table-wm__right {
      span {
        display: block;
        position: relative;
        padding-left: 34px; }
      .icon {
        display: block;
        position: absolute;
        left: 0;
        top: 2px;
        width: 20px;
        height: 20px; } } }

  @include mobilesm() {
    &__row {
      flex-wrap: wrap;
      padding-top: 8px;
      padding-bottom: 8px; }
    &__head {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      text-align: center;
      padding: 10px 16px 0; }
    &__left {
      max-width: 40%;
      flex: 0 0 40%;
      padding: 10px 16px; }
    &__right {
      max-width: 60%;
      flex: 0 0 60%;
      padding: 10px 16px; }
    &__thead {
      th, td {
        text-align: center; }
      th.table-wm__head {
        padding-bottom: 0; }
      th.table-wm__left {
        padding-right: 0; }
      th, td {
        padding: 10px 16px; }
      .table-wm__row {
        align-items: flex-end; } }
    &__tbody {
      .table-wm__row {
        align-items: flex-start; }
      .table-wm__right {
        span {
          padding-left: 20px; }
        .icon {
          width: 14px;
          height: 14px;
          top: 3px; } } } } }

.accordion {
  position: relative;
  margin-bottom: 32px;
  .card {
    position: relative;
    margin-bottom: 4px;
    &__header {
      position: relative;
      display: block;
      padding: 20px 56px 20px 20px;
      cursor: pointer;
      border-bottom: 1px solid #E2E2E2;
      transition: all .3s ease-in-out;
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url(../images/ic-chevron-down.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        top: 20px;
        right: 20px;
        transform: rotate(180deg);
        transition: all .5s ease-in-out; }
      &.collapsed:after {
        transform: none; } }
    &__title {
      font-size: 16px;
      line-height: 24px;
      display: block;
      @include font-500();
      margin: 0;
      padding: 0; }
    &__body {
      background: #FFF;
      border-radius: 4px;
      box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75);
      padding: 24px 20px;
      margin-top: 12px;
      .img-sm {
        display: none;
        margin-top: 24px;
        @include mobile {
          text-align: center;
          display: inline-block;
          width: 100%;
          height: auto; } } }
    &__action {
      a {
        margin-right: 14px;
        &:last-child {
          margin-right: 0; } }
      @include maxtablet {
        margin-right: 0; } } }
  &--faq {
    .card {
      margin-bottom: 16px;
      background: #FFF;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid $border;
      transition: all .5s ease-in-out;
      &.open {
        margin-bottom: 24px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }
      &__header {
        padding: 28px 80px 12px 24px;
        border: 0;
        &:after {
          top: 28px;
          right: 16px;
          background: url(../images/ic-cancel.svg) no-repeat center;
          background-size: contain;
          transform: rotate(90deg); }
        &.collapsed {
          padding-bottom: 28px;
          .card__title {
            @include font-400(); }
          &:after {
            content: "";
            background: url(../images/ic-chevron-down.svg) no-repeat center;
            background-size: contain;
            transform: rotate(-90deg); } } }
      &__body {
        border-radius: 0;
        box-shadow: none;
        padding: 0 80px 52px 24px;
        margin: 0;
        .img-sm {
          display: none;
          margin-top: 24px;
          @include mobile {
            text-align: center;
            display: inline-block;
            width: 100%;
            height: auto; } } } } }

  &--sm {
    padding: 0 16px;
    .card {
      border-bottom: 1px solid #E2E2E2;
      &:first-child {
        border-top: 1px solid #E2E2E2; }
      &__header {
        border-bottom: 0;
        padding: 12px 30px 12px 12px;
        &::after {
          top: calc(50% - 12px);
          right: 0; }
        .icon {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          display: inline-block;
          vertical-align: middle; } }
      &__title {
        display: inline-block;
        width: calc(100% - 57px);
        vertical-align: middle; }

      &__body {
        padding: 0;
        box-shadow: none;

        .post {
          .post__content {
            padding-left: 0;
            margin-bottom: 24px; } } } } } }

.howjurnal {
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px;
    .row {
      align-items: center; }
    @include mobile {
      .reverse-sm {
        flex-direction: column-reverse; } }
    .section-head__action {
      a {
        margin-right: 15px;
        &:last-child {
          margin-right: 0; }
        @include mobile {
          display: block;
          width: 100%;
          margin-bottom: 12px; } } } }
  &--home {
    .howjurnal__wrap {
      padding-top: 0; } }
  &__img {
    transition: all .5s ease-in-out;
    img {
      display: block;
      width: 100%;
      height: auto; }
    // &.img-out
    //   transform: scale(1.1)
    //   opacity: 0
    // &.img-in
    //   transform: scale(1)
    //   opacity: 1
    @include mobile {
      display: none; } }
  .accordion {
    .card {
      position: relative;
      background: #FFF;
      border-radius: 4px;
      border: 2px solid transparent;
      .progress {
        // display: block
        // width: 100%
        // height: 100%
        // position: absolute
        // top: 0
        // left: 0
        // opacity: 0
        display: none; }
      &__body {
        position: relative;
        box-shadow: none;
        background: transparent;
        padding-top: 0;
        margin-top: 0; }
      &.open {
        border-color: #009BDE;
        box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75);
        margin-top: 12px;
        .card__header {
          border-color: transparent;
          padding-bottom: 12px; }
        .progress {
          opacity: 1;
          rect {
            @include dash2(8s); } } } } }

  &.animate {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .accordion,
    .howjurnal__img {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .section-head__content {
      transition-delay: .25s; }
    .section-head__action {
      transition-delay: .5s; }
    .accordion {
      transition-delay: .75s; }
    .howjurnal__img {
      transition-delay: 1s; } }

  &.animate-in {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .accordion,
    .howjurnal__img {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include mobilesm {
    &__wrap {
      padding-top: 40px;
      padding-bottom: 60px; } } }

.integration-posts {
  padding-top: 80px;
  padding-bottom: 80px;
  .section-head {
    padding-bottom: 40px;
    &__title {
      margin-bottom: 0; } }

  .post {
    display: block;
    color: $text;
    transition: all .3s ease-in-out;
    &__img {
      position: relative;
      .icon {
        display: block;
        height: 48px;
        width: auto;
        margin-bottom: 8px; }
      .block-img {
        width: 100%;
        padding-top: 52.78%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0; } } }
    &__title {
      font-size: 16px;
      line-height: 24px;
      @include font-500();
      margin-bottom: 16px;
      color: $black;
      a {
        color: $black;
        &:hover {
          color: $blue; } } } }

  .post-featured {
    padding-bottom: 80px; }

  .post-partners {
    padding-bottom: 56px;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px; }
    .post {
      margin-bottom: 24px;
      &__img {
        border-radius: 4px 4px 0 0;
        overflow: hidden; }
      &__content {
        border: 1px solid $border;
        border-top: 0;
        border-radius: 4px;
        padding: 12px 16px; }
      &__title {
        margin-bottom: 4px; }
      &:hover {
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); } } }

  .integration__cta {
    padding-top: 48px;
    .cta__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px; }
    &.animate {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    &.animate-in {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  .animate {
    .section-head__title,
    .post-featured .post,
    .post-partners__title,
    .post-partners .post {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); } }
  .animate-in {
    .section-head__title,
    .post-featured .post,
    .post-partners__title,
    .post-partners .post {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include mobilesm {
    padding-top: 40px;
    padding-bottom: 60px;
    .post-featured {
      padding-bottom: 60px;
      .post {
        text-align: center;
        .icon {
          margin-left: auto;
          margin-right: auto; }
        &__content {
          padding: 0 24px; } } }
    .post-partners {
      padding-bottom: 36px; }
    .integration__cta {
      padding-top: 32px;
      .cta__title {
        font-size: 18px;
        line-height: 26px; } } } }

.categories {
  &__title {
    font-size: 14px;
    line-height: 20px;
    color: #777;
    @include font-500();
    text-transform: uppercase;
    margin-bottom: 8px; }
  &__list {
    margin-bottom: 32px;
    li {
      display: block;
      width: 100%;
      padding: 8px 0;
      @include font-500();
      span, a {
        color: $black;
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        &:after {
          content: "";
          display: inline-block;
          @include triangle("right", 4px, 9px, #1357FF);
          margin-left: 11px;
          transform: scale(0) translateX(-20px);
          transition: all .3s ease-in-out; }
        &:hover {
          color: #1357FF; }
        &.active {
          color: #1357FF;
          text-decoration: none;
          &:after {
            transform: scale(1) translateX(0); } } } } }
  &.animate {
    .categories__title,
    .categories__list li {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .categories__list li {
      &:nth-child(1) {
        transition-delay: .25s; }
      &:nth-child(2) {
        transition-delay: .5s; }
      &:nth-child(3) {
        transition-delay: .75s; }
      &:nth-child(4) {
        transition-delay: 1s; }
      &:nth-child(5) {
        transition-delay: 1.25s; }
      &:nth-child(6) {
        transition-delay: 1.5s; }
      &:nth-child(7) {
        transition-delay: 1.75s; } } }
  &.animate-in {
    .categories__title,
    .categories__list li {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include mobile {
    text-align: center;
    &__list {
      li {
        display: inline-block;
        width: auto;
        padding: 8px 12px;
        span, a {
          &:after {
            display: none; } } } } } }

.ukm {
  padding: 80px 0;
  background: #F8FAFC;
  &--fitur {
    background: #FFF;
    .testimonial-big {
      margin-bottom: 0;
      &__wrap {
        box-shadow: none;
        padding-bottom: 30px; } } }
  &--solusi {
    background: #F9FBFD;
    .testimonial-big {
      margin-bottom: 0;
      &__wrap {
        box-shadow: none;
        padding-bottom: 30px;
        background: #F9FBFD; } }

    &.top {
      background: #FFF;
      .testimonial-big {
        padding-top: 0; }
      .testimonial-big__wrap {
        background: #FFF;
        .block-img {
          padding-top: 100%; } } } }

  &.animate {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .testimonial-big__img,
    .testimonial-big__content,
    .testimonial__container .col-lg-6 {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .section-head__content {
      transition-delay: .25s; }
    .section-head__action {
      transition-delay: .5s; }
    .testimonial-big__img {
      transition-delay: .75s; }
    .testimonial-big__content {
      transition-delay: 1s; }
    .testimonial__container .col-lg-6 {
      &:nth-child(1) {
        transition-delay: 1.25s; }
      &:nth-child(2) {
        transition-delay: 1.5s; } } }
  &.animate-in {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .testimonial-big__img,
    .testimonial-big__content,
    .testimonial__container .col-lg-6 {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include mobilesm {
    padding: 40px 0 26px;
    .section-head {
      padding-bottom: 24px; } } }

.testimonial-big {
  padding-top: 80px;
  margin-bottom: 32px;
  &__wrap {
    display: flex;
    background: #FFF;
    box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.4);
    border-radius: 4px;
    padding: 60px 60px 48px 80px; }
  &__img {
    position: relative;
    display: block;
    width: 100%;
    max-width: 36.163%;
    flex: 0 0 36.163%;
    .block-img {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 81.632%; }
    .img {
      position: relative;
      z-index: 1;
      display: block;
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0; }
    .play {
      display: block;
      position: absolute;
      right: -10%;
      bottom: -5%;
      z-index: 2;
      width: 28.5%;
      border-radius: 50%;
      @include pulse2();
      .svg,
      img {
        width: 100%;
        height: auto;
        display: block; } } }
  &__content {
    padding-left: 90px; }
  &__category {
    font-size: 12px;
    line-height: 20px;
    @include font-500();
    text-transform: uppercase;
    position: relative;
    padding: 2px 0 2px 28px;
    margin-bottom: 28px;
    .icon {
      display: inline-block;
      height: 24px;
      width: 24px;
      position: absolute;
      left: 0;
      top: 0; } }
  &__blockquote {
    display: block;
    margin: 0 0 24px;
    font-size: 20px;
    line-height: 32px;
    color: $black; }
  &__footer {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    img {
      display: block;
      max-height: 56px;
      width: auto; } }
  &__author {
    padding: 16px 24px;
    font-size: 14px;
    line-height: 20px;
    &:not(:first-child) {
      border-left: 1px solid $border;
      margin-left: 24px; }
    span {
      display: block;
      &+ span {
        margin-top: 4px; } }
    .author__name {
      text-transform: uppercase;
      color: $black;
      @include font-500(); } }

  @include mobile {
    padding-top: 40px;
    &__wrap {
      padding: 30px 30px 24px 40px; }
    &__content {
      padding-left: 45px; } }
  @include mobilelg {
    &__wrap {
      flex-wrap: wrap;
      padding: 24px; }
    &__img {
      max-width: 80%;
      flex: 0 0 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 32px; }
    &__content {
      padding-left: 0; }
    &__blockquote {
      font-size: 18px;
      line-height: 26px; }
    &__footer {
      flex-wrap: wrap; }
    &__author {
      padding: 0;
      width: 100%;
      &:not(:first-child) {
        border-left: 0;
        margin-top: 24px;
        margin-left: 0; } } } }

.testimonial {
  padding: 0 32px;
  &__wrap {
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.4);
    padding: 32px 0;
    margin-bottom: 32px;
    .owl-dots {
      margin: 0;
      padding: 0 32px;
      text-align: left;
      line-height: 8px; } }
  &__header {
    display: flex;
    padding-bottom: 32px; }
  &__user {
    width: 100%;
    max-width: 96px;
    flex: 0 0 96px;
    img {
      display: block;
      height: 105px;
      width: auto; } }
  &__author {
    width: 100%;
    max-width: calc(65% - 96px);
    flex: 0 0 calc(65% - 96px);
    font-size: 14px;
    line-height: 20px;
    padding-left: 24px;
    padding-top: 20px;
    span {
      display: block; }
    .author__name {
      text-transform: uppercase;
      color: $black;
      @include font-500(); }
    .author__title {
      margin-top: 4px; }
    .author__logo {
      max-height: 48px;
      width: auto !important;
      margin-top: 14px; } }
  &__category {
    font-size: 12px;
    line-height: 20px;
    @include font-500();
    text-transform: uppercase;
    position: relative;
    padding: 2px 0 2px 28px;
    margin-top: 18px;
    margin-left: auto;
    margin-bottom: 28px;
    width: 100%;
    max-width: 35%;
    flex: 0 0 35%;
    .icon {
      display: inline-block;
      height: 24px;
      width: 24px !important;
      position: absolute;
      left: 0;
      top: 0; } }
  &__blockquote {
    margin: 0 0 40px;
    font-size: 20px;
    line-height: 32px;
    &:not(:last-child) {
      margin-bottom: 24px; } }
  &--landing {
    text-align: left;
    .small-banner__box {
      padding: 3rem;
      @include mobilesm {
        padding: 30px 16px; } }
    .testimonial__data {
      display: flex;
      align-items: center;
      .testimonial__img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%; } }
      .testimonial_name {
        @include font-500(); } }
    .testimonial__box {
      blockquote {
        margin-left: 0;
        margin-right: 0;
        @include font-500();
        font-size: 32px;
        line-height: 40px;
        @include mobilesm {
          font-size: 16px;
          line-height: 24px; } } } }
  &--thumbs {
    padding: 20px 0;
    ul.nav.nav-tabs {
      text-align: center;
      li.nav-item.item {
        display: inline-block;
        margin: 0 30px;
        cursor: pointer;
        a.nav-link {
          img {
            opacity: .5;
            filter: invert(.5); } }
        &.active {
          a.nav-link.active {
            img {
              opacity: 1;
              filter: invert(0); } } } } } }


  @include mobilesm {
    padding: 24px;
    &__header {
      flex-wrap: wrap; }
    &__author {
      max-width: calc(100% - 96px);
      flex: 0 0 calc(100% - 96px); }
    &__category {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      margin-left: 0;
      margin-top: 16px;
      margin-bottom: 0; }
    &__blockquote {
      font-size: 18px;
      line-height: 26px; } } }

.blog {
  padding: 80px 0;
  .section-head {
    &__title {
      margin-bottom: 0; } }

  &__video {
    position: relative;
    margin-bottom: 48px;
    .video__cover {
      position: relative;
      width: 100%;
      padding-top: 45%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; } }
    .video__play {
      display: block;
      width: 80px;
      height: 80px;
      border-radius: 40px;
      background: url(../images/ic-play-circle.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
      @include pulse2(); } }
  // &__posts
  //   margin-left: -28px
  //   margin-right: -28px
  //   .col-md-4
  //     padding-left: 28px
  //     padding-right: 28px
  .post {
    position: relative;
    padding-bottom: 48px;
    padding-left: 16px;
    padding-right: 16px;
    &__img {
      position: relative;
      z-index: 2;
      .block-img {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 66.666667%;
        overflow: hidden;
        border-radius: 4px;
        margin-bottom: 20px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0; } } }
    &__title {
      position: relative;
      z-index: 2;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 12px;
      @include font-500();
      padding-left: 30px;
      .icon {
        display: block;
        position: absolute;
        top: 3px;
        left: 0; } }
    &__content {
      position: relative;
      z-index: 2;
      color: $black;
      margin-bottom: 20px; }
    &__meta {
      margin-bottom: 12px;
      span {
        display: inline-block; }
      .name {
        @include font-500();
        color: $black;
        margin-right: 6px;
        font-size: 14px;
        line-height: 20px;
        &:after {
          content: ","; } } }
    &__logo {
      img {
        max-height: 56px;
        width: auto;
        display: block; } }
    &__footer {
      position: relative;
      z-index: 2; }
    .link-more {
      position: absolute;
      z-index: 4;
      left: 16px;
      bottom: 20px;
      opacity: 0;
      transition: all .3s ease-in-out; }
    &:before {
      content: "";
      z-index: 1;
      display: block;
      width: calc(100% + 32px);
      height: calc(100% + 48px);
      position: absolute;
      top: -24px;
      left: -16px;
      background: #FFF;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      opacity: 0;
      transform: scale(0.9);
      transition: all .3s ease-in-out; }
    &:hover {
      z-index: 3;
      .link-more {
        bottom: 0;
        opacity: 1; }
      &:before {
        opacity: 1;
        transform: none; } }


    &.animate {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    &.animate-in {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  &.animate {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .blog__video {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .section-head__content {
      transition-delay: .25s; }
    .section-head__action {
      transition-delay: .5s; }
    .blog__video {
      transition-delay: .75s; } }
  &.animate-in {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .blog__video {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include mobilesm {
    padding: 40px 0;
    .section-head__title:last-child {
      margin-bottom: 16px; } } }

.allinone,
.advantage {
  padding: 80px 0;
  .post-featured {
    position: relative; }
  &--landing {
    .post {
      text-align: center;
      &__img {
        .icon {
          display: inline-block!important; } }
      &__content {
        padding-right: 0!important; } } }
  .post {
    display: block;
    color: $text;
    transition: all .3s ease-in-out;
    @include mobile {
      margin-bottom: 40px;
      text-align: left; }
    &__img {
      position: relative;
      .icon {
        display: block;
        height: 48px;
        width: auto;
        margin-bottom: 8px; } }
    &__title {
      font-size: 16px;
      line-height: 24px;
      @include font-500();
      margin-bottom: 16px;
      color: $black;
      a {
        color: $black;
        &:hover {
          color: $blue; } } }
    &__content {
      padding-right: 40px; } }
  &--form-alt {
    .post {
      &__img {
        display: inline-block;
        width: 40px;
        margin-right: 16px;
        vertical-align: top; }
      &__content {
        display: inline-block;
        width: calc(100% - 61px); } } }
  &.animate {
    .section-head__title,
    .section-head__content,
    .post-featured .row .col-md-4 {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .post-featured .row .col-md-4 {
      &:nth-child(1) {
        transition-delay: .25s; }
      &:nth-child(2) {
        transition-delay: .5s; }
      &:nth-child(3) {
        transition-delay: .75s; } } }
  &.animate-in {
    .section-head__title,
    .section-head__content,
    .post-featured .row .col-md-4 {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include mobile {
    .post__content {
      padding-right: 0; } }
  @include mobilesm {
    padding: 40px 0 40px; } }

.features {
  padding: 80px 0 32px;
  .post {
    display: block;
    color: $text;
    transition: all .3s ease-in-out;
    margin-bottom: 48px;
    &__img {
      display: block;
      position: relative;
      margin-bottom: 16px;
      .block-img {
        width: 100%;
        padding-top: 60.2%;
        position: relative;
        border: 1px solid $border;
        border-radius: 6px;
        box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.25);
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0; } } }
    &__title {
      font-size: 16px;
      line-height: 24px;
      @include font-500();
      margin-bottom: 8px;
      color: $black;
      a {
        color: $black;
        &:hover {
          color: $blue; } } }
    &__content {
      padding-left: 32px;
      position: relative;
      .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: auto; } }
    &.animate {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    &.animate-in {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  &.animate {
    .section-head__subtitle,
    .section-head__title,
    .section-head__desc {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); } }
  &.animate-in {
    .section-head__subtitle,
    .section-head__title,
    .section-head__desc {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }
  @include mobilesm {
    padding: 40px 0 24px; } }

.fitur-blocks {
  .fitur-block:nth-child(even) {
    .row {
      flex-direction: row-reverse; } }
  &--solusi {
    padding-top: 80px;
    .section-head {
      padding-bottom: 40px; } }
  &--alt {
    .fitur-block {
      padding: 128px 0 0;
      &:last-child {
        padding-bottom: 64px; }
      @include mobile() {
        padding: 64px 0; } } }
  &.animate {
    .section-head__title,
    .section-head__content,
    .section-head__desc, {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); } }
  &.animate-in {
    .section-head__title,
    .section-head__content,
    .section-head__desc, {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } } }

.fitur-block {
  padding: 128px 0 0;
  &:last-child {
    padding-bottom: 64px; }
  .row {
    align-items: center; }
  &__img {
    position: relative;
    .block-img {
      position: relative;
      width: 100%;
      padding-top: 83.333333%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; } } }
  &__title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px; }
  &__content {
    p {
      display: block;
      margin-bottom: 32px; } }
  &__action {
    a {
      margin-right: 12px;
      &:last-child {
        margin-right: 0; } } }
  &__list {
    position: relative;
    margin-bottom: 32px;
    li {
      position: relative;
      display: block;
      padding-left: 36px;
      margin-bottom: 16px;
      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background: url(../images/ic-check.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0; }
      &:last-child {
        margin-bottom: 0; } }

    .feature__action {
      a {
        margin-right: 12px;
        margin-bottom: 12px;
        @include mobilesm {
          margin-right: 0; } } } }

  &.animate {
    .fitur-block__img,
    .fitur-block__title,
    .fitur-block__content p,
    .fitur-block__content li,
    .feature__action,
    .btn {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .fitur-block__img {
      transition-delay: .25s; }
    .fitur-block__title {
      transition-delay: .5s; }
    .fitur-block__content p {
      transition-delay: .75s; }
    .fitur-block__content li {
      transition-delay: 1s; }
    .feature__action,
    .btn {
      transition-delay: 1.25s; } }
  &.animate-in {
    .fitur-block__img,
    .fitur-block__title,
    .fitur-block__content p,
    .fitur-block__content li,
    .feature__action,
    .btn {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include mobilesm {
    padding: 48px 0;
    &__img {
      margin-bottom: 16px; } } }

.coa {
  padding: 80px 0 64px;
  &__img {
    position: relative;
    .block-img {
      position: relative;
      width: 100%;
      padding-top: 62%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; } } }
  &.animate {
    .section-head__title,
    .section-head__content,
    .coa__img {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .section-head__content {
      transition-delay: .25s; }
    .coa__img {
      transition-delay: .5s; } }
  &.animate-in {
    .section-head__title,
    .section-head__content,
    .coa__img {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } } }

.benefits {
  padding: 80px 0 64px;
  background: #F9FBFD;
  // .section-head:last-child
  //   padding-bottom: 0
  &__list {
    position: relative;
    margin-bottom: 24px;
    column-count: 2;
    &:last-child {
      margin-bottom: 0; }
    &.alt {
      column-count: 1;
      padding-left: 24px; }
    li {
      position: relative;
      display: block;
      padding-left: 36px;
      margin-bottom: 16px;
      padding-right: 24px;
      &:last-child {
        margin-bottom: 0; }
      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background: url(../images/ic-check.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0; } } }
  &.animate {
    .section-head__title,
    .section-head__content,
    .benefits--promo_wrap,
    .benefits__list {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .section-head__content {
      transition-delay: .25s; }
    .benefits__list {
      transition-delay: .5s; } }
  &.animate-in {
    .section-head__title,
    .section-head__content,
    .benefits--promo_wrap,
    .benefits__list {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  &--pricing {
    .benefits__list {
      column-count: 1;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      li {
        display: block;
        width: 100%;
        max-width: 33.333333%;
        flex: 0 0 33.333333%; } } }

  @include mobilesm {
    padding: 40px 0 24px;
    &--pricing {}
    .benefits__list {
      column-count: 1;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      li {
        display: block;
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%; }
      &.alt {
        li {
          max-width: 100%;
          flex: 0 0 100%; } } } }


  &--promo {
    background: #FFF;
    &.lg {
      padding-top: 0px; }
    &.sm {
      padding-top: 24px;
      .benefits--promo_slider {
        .item {
          display: flex;
          padding: 16px;
          align-items: center;
          &_img {
            min-width: 80px;
            margin-right: 16px;
            img {
              margin-bottom: 0; } }
          @include mobile {
            display: block;
            &_img {
              display: block;
              margin: 0 auto 8px; }
            &_meta {
              display: block; } } } } }
    &_wrap {
      background: rgb(255,255,255);
      @include mobile {
        background: #f2f4f7; } }
    &_list {
      padding: 56px 0 64px 0;
      position: relative;
      &.blue {
        &::before {
          content: '';
          width: 80%;
          position: absolute;
          height: 100%;
          top: 0;
          left: calc(50% - (80% / 2));
          background: radial-gradient(103.57% 220.66% at 45.46% 48.06%, #81B8D4 0%, #007DE5 39.03%, #004DC2 100%);
          border-radius: 24px;
          z-index: 1;
          @include mobile {
            width: calc(100% + 24px);
            left: -12px;
            border-radius: 0; } } }
      &.green {
        &::before {
          content: '';
          width: 80%;
          position: absolute;
          height: 100%;
          top: 0;
          left: calc(50% - (80% / 2));
          background: radial-gradient(103.57% 220.66% at 45.46% 48.06%, #A8CE4D 0%, #83C838 39.03%, #499C21 100%);
          border-radius: 24px;
          z-index: 1;
          @include mobile {
            width: calc(100% + 24px);
            left: -12px;
            border-radius: 0; } } } }
    &_slider {
      display: flex;
      position: relative;
      z-index: 2;
      .item {
        width: calc(100% / 3);
        background: #FFF;
        border-radius: 8px;
        margin: 5px 12px;
        padding: 32px;
        box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
        img {
          margin-bottom: 16px; }
        h4 {
          font-size: 20px;
          line-height: 32px;
          @include font-400(); }
        p {
          font-size: 16px;
          line-height: 24px;
          color: #545465; }
        &:first-child {
          margin-left: 0; }
        &:last-child {
          margin-right: 0; }
        @include mobile {
          width: 100%;
          display: block;
          border: 1px solid rgba(119, 119, 119, 0.1);
          box-shadow: none;
          text-align: center;
          img {
            width: 100px;
            margin: 0 auto 16px; } } } }

    &_badge {
      width: 100%;
      left: 0;
      top: -24px;
      position: absolute;
      text-align: center;
      z-index: 2;
      span {
        border-radius: 8px;
        color: #FFF;
        padding: 8px 24px;
        display: inline-block;
        font-size: 20px;
        line-height: 32px;
        background: #FFFFFF;
        border: 1px solid #F2F4F7;
        box-shadow: 0px 12px 12px rgba(0, 95, 191, 0.08), 0px 16px 24px rgba(0, 95, 191, 0.08);
        border-radius: 100px;
        color: $blue;
        @include font-500;
        @include mobile {
          font-size: 16px;
          line-height: 20px; } } } } }

.faq-block {
  padding: 64px 0;
  background: #F9FBFD;
  .card {
    &__body {
      ul {
        padding-left: 20px;
        list-style: disc;
        li {
          margin-bottom: 4px; } } } }
  .section-head__action {
    padding-top: 16px;
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; } } }
  &--pricing {
    background: transparent; }

  &.animate {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .accordion--faq {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .section-head__content {
      transition-delay: .25s; }
    .section-head__action {
      transition-delay: .5s; }
    .accordion--faq {
      transition-delay: .75s; } }
  &.animate-in {
    .section-head__title,
    .section-head__content,
    .section-head__action,
    .accordion--faq {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }

  @include maxtablet {
    padding: 48px 8px;
    .section-head__action {
      a {
        margin-right: 0;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } } } }




.sidebar {
  &__wrap {
    position: relative; }
  &__data {
    padding: 24px 0;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    display: flex;
    flex-wrap: wrap;
    h4,
    span {
      display: block;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
      margin: 0; }
    span {
      margin-left: auto;
      text-align: right; }
    p {
      display: block;
      width: 100%;
      margin: 8px 0 0; } } }

.cs-detail {
  padding-bottom: 80px;
  .block-append {
    background: #F9FBFD;
    border-radius: 4px;
    padding: 30px 40px;
    margin-bottom: 64px;
    h3 {
      position: relative;
      font-size: 32px;
      line-height: 48px;
      margin: 0;
      small {
        color: $blue;
        display: inline-block;
        vertical-align: top;
        font-size: 20px;
        line-height: 32px;
        margin: 0 4px;
        position: relative;
        top: -2px; } }
    p {
      font-size: 14px;
      line-height: 20px; }
    .row {
      margin: 0 -30px; }
    .item {
      padding: 10px 30px;
      position: relative;
      &:not(:last-child):after {
        content: "";
        display: block;
        height: calc(100% - 28px);
        width: 1px;
        background: $border;
        position: absolute;
        right: 0;
        top: 14px; } } }

  article {
    color: $text;
    .text-category {
      display: block;
      font-size: 16px;
      line-height: 24px;
      @include font-400;
      margin-bottom: 4px; }
    h1 {
      display: block;
      font-size: 48px;
      line-height: 56px;
      @include font-600();
      color: $black;
      margin-bottom: 16px; }
    h2 {
      display: block;
      font-size: 32px;
      line-height: 48px;
      @include font-500();
      color: $black;
      margin-bottom: 16px; }
    h3 {
      display: block;
      font-size: 20px;
      line-height: 32px;
      @include font-500();
      color: $black;
      margin-bottom: 8px; }
    p {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0; } } }

  &__sidebar {
    .sidebar {
      margin-top: -200px;
      &__wrap {
        background: #009BDE;
        border-radius: 8px;
        padding: 0 24px;
        color: #FFF; }
      &__data {
        span {
          @include font-500(); } } } }

  &.animate {
    transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
    opacity: 0;
    transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  &.animate-in {
    transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
    opacity: 1; }

  @include mobile {
    &__sidebar .sidebar {
      margin-top: 48px; } } }


// NOTE: FOR THIS SECTION NEED MAINTANCE FOR FIX USING DISPLAY GRID
.pricing,
.pricing-alt {
  padding-bottom: 24px;
  .section-head {
    padding: 40px 0;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0; } }
  &__wrap {
    .row {
      flex-direction: row-reverse; } }
  &__switcher {
    background: #F2F4F7;
    border-radius: 100px;
    display: inline-block;
    padding: 8px 4px;
    margin-bottom: 3rem;
    a {
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 8px 29px;
      color: #545465;
      &:hover {
        text-decoration: none; }
      &.active {
        background: #FFF;
        color: $blue;
        border-radius: 100px;
        box-shadow: 0px 4px 12px rgba(50, 50, 71, 0.08), 0px 16px 24px rgba(50, 50, 71, 0.08);
        .item__head {
          .item__desc {
            .big {
              font-size: 24px;
              line-height: 32px; } } } } } }
  &__cta {
    padding-top: 24px;
    text-align: center; }
  .col-md-6 {
    padding-bottom: 24px; }
  .item {
    background: #FFF;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 100%;
    &__head {
      position: relative;
      width: 100%;
      padding: 32px 24px;
      background: #F2F4F7;
      border-bottom: 1px solid #E2E2E2;
      display: flex;
      flex-direction: column;
      span.big {
        display: block;
        color: $black;
        font-size: 28px;
        line-height: 48px;
        @include font-500();
        margin-bottom: 12px; } }
    &__title {
      display: block;
      position: relative;
      width: 100%;
      font-size: 1.5rem;
      line-height: 2rem;
      padding-bottom: 24px;
      @include font-500();
      span.badge {
        display: inline-block;
        position: relative;
        top: -1px;
        background: #FFF;
        border-radius: 4px;
        color: $blue;
        font-size: 0.75rem;
        line-height: 1.25rem;
        padding: 0 8px;
        margin: 0 0 0 7px;
        vertical-align: middle; }
      &:after {
        content: "";
        display: block;
        width: 100%;
        border-top: 1px solid #E2E2E2;
        position: absolute;
        bottom: 0;
        left: 0; } }
    &__desc {
      width: 100%;
      min-height: 132px;
      &.tx-md {
        color: $black;
        font-size: 24px;
        line-height: 32px;
        @include font-500(); } }
    &__cta {
      width: 100%;
      margin-top: auto;
      padding-top: 32px;
      .btn {
        width: 100%;
        @include font-500();
        &+ .btn {
          margin-top: 12px; } } }


    &__body {
      width: 100%;
      padding: 32px 24px;

      .bonus {
        border-radius: 4px;
        padding: 12px;
        margin-bottom: 32px; }
      @include mobile {
        display: none;
        &.show {
          display: block; } } }
    &__mobile {
      display: none;
      text-align: center;
      padding: 20px 45px 36px;
      position: {}
      &::after {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 40px;
        left: calc(50% - 12px);
        background: url(../images/ic-arrow-down-blue.svg) no-repeat center; }
      @include mobile {
        display: block;
        &.hide {
          display: none; } } }

    .pricing-list {
      padding-bottom: 20px;
      &__title {
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: block;
        @include font-500();
        text-transform: uppercase;
        margin-bottom: 8px; }
      &__label {
        font-size: 1rem;
        line-height: 1.5rem;
        display: block;
        @include font-500();
        margin-bottom: 1.5rem; }
      &__item {
        display: block;
        position: relative;
        margin-bottom: 12px;
        padding-left: 32px;
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          left: 0;
          top: 4px;
          background: transparent url(../images/ic-check.svg) no-repeat center;
          background-size: contain; }
        .tooltips {
          position: absolute;
          right: -15px;
          top: 1px; } } }

    &.active {
      border: 2px solid $blue;
      box-shadow: 0px 4px 16px rgba(180, 188, 208, 0.75);
      .item__head {
        background: $blue;
        color: #FFF;
        span.big {
          color: #FFF;
          font-size: 24px;
          line-height: 32px; } }
      .item__desc {
        &.tx-md {
          color: #FFF; } }
      .item__title {
        color: #FFF; }
      .item__cta {
        .btn-white {
          &:hover {
            border-color: #FFF; } }
        .btn-white-border {
          background: transparent;
          color: #FFF;
          &:hover {
            color: $blue;
            background: #FFF; } } } } }

  &.animate {
    transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
    opacity: 0;
    transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  &.animate-in {
    transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
    opacity: 1; } }


.pricing-alt {
  .item {
    &__head {
      background: #FFF;
      display: block;
      height: auto;
      border: 0;
      padding-bottom: 0; }
    &__icon {
      display: block;
      margin-bottom: 12px;
      img {
        display: block;
        height: 40px;
        width: auto; } }
    &__title {
      font-size: 20px;
      line-height: 32px;
      min-height: 88px; }
    &__desc {
      span.big {
        font-size: 20px;
        line-height: 32px; } }
    &__body {
      // min-height: 410px
      display: flex;
      flex-direction: column;
      height: 100%;
      .item__cta {
        width: 100%; } } } }


.pricing-ao {
  .section-head {
    padding: 40px 0;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0; } }
  .item {
    background: #FFF;
    border: 1px solid $border;
    padding: 32px 0;
    border-radius: 4px;
    &__left {
      padding: 0 32px; }
    &__right {
      padding: 32px 32px 0 0; }
    &__icon {
      img {
        display: block;
        height: 40px;
        width: auto;
        margin: 0 0 12px; } }
    &__title {
      font-size: 20px;
      line-height: 24px;
      @include font-500();
      margin-bottom: 24px; }
    &__desc {
      color: $black; }
    small {
      display: block;
      font-size: 12px;
      line-height: 18px;
      color: #777;
      margin-bottom: 0; }
    span.big {
      display: block;
      margin-bottom: 16px;
      color: $black;
      font-size: 24px;
      line-height: 32px;
      @include font-500(); }
    .pricing-list {
      color: $text;
      &__title {
        font-size: 16px;
        line-height: 24px;
        @include font-500();
        text-transform: uppercase;
        margin-bottom: 12px; }
      &__item {
        display: block;
        position: relative;
        margin-bottom: 12px;
        padding-left: 32px;
        &:last-child {
          margin-bottom: 0; }
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          left: 0;
          top: 4px;
          background: transparent url(../images/ic-check.svg) no-repeat center;
          background-size: contain; } } } }

  &__cta {
    text-align: right; } }

.pricing-detail {
  display: block;
  width: 100%;
  border-color: $border;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  h3 {
    display: block;
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 32px;
    @include font-500(); }
  h4 {
    display: block;
    margin-bottom: 0;
    color: $black;
    font-size: 20px;
    line-height: 32px;
    @include font-500(); }
  span.check {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: transparent url(../images/ic-check.svg) no-repeat center;
    background-size: contain;
    line-height: 0;
    margin: 4px; }
  .pd {
    &-tr,
    &-title,
    &-cta {
      display: flex;
      position: relative;
      width: 100%; }
    &-th,
    &-td {
      width: 100%;
      max-width: 18%;
      flex: 0 0 18%;
      border-color: $border;
      border-style: solid;
      text-align: center;
      &:first-child {
        max-width: 28%;
        flex: 0 0 28%;
        text-align: left; } }
    &-th {
      padding: 48px 24px 36px;
      border-width: 0 1px 1px 0; }
    &-td {
      padding: 12px 24px;
      border-width: 0 1px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        justify-content: flex-start; }
      &.sr {
        padding-top: 20px; } }
    &-cta {
      .pd-td {
        padding: 24px; } } }
  &__head {
    display: block;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top: 0;
    background: #FFF; }
  &__body {
    position: relative;
    z-index: 1;
    overflow: auto;
    width: auto;
    // margin-right: -5px
    // height: calc(100vh - 506px)
    div.pd-tr:nth-of-type(odd) {
      background: #FBFBFB;
      background: lighten($border, 5%); } }
  &__footer {
    display: block;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    bottom: 0;
    background: #FFF;
    .btn {
      padding-left: 10px;
      padding-right: 10px; } } }


.pricing-detail-sm {
  padding: 12px 12px 0;
  .nav {
    display: flex;
    border-right: 1px solid $border;
    &-item {
      display: block;
      width: 100%;
      max-width: 25%;
      flex: 0 0 25%;
      .nav-link {
        display: block;
        text-align: center;
        padding: 12px 24px;
        border-width: 1px 0 1px 1px;
        border-style: solid;
        border-color: $border;
        color: $black;
        font-size: 16px;
        line-height: 24px;
        @include font-500();
        &.active {
          background: #f2f2f2; } } } }
  .pdsm {
    &__head {
      display: flex;
      width: calc(100% - 48px);
      margin: 0 auto; }
    &__body {
      padding-top: 24px; }
    &__content {
      h4 {
        color: $black;
        font-size: 18px;
        line-height: 32px;
        @include font-500(); } }
    &__cta {
      display: block;
      width: 100%;
      position: -webkit-sticky;
      position: sticky;
      z-index: 2;
      bottom: 0;
      padding: 16px;
      text-align: center;
      background: #FFF;
      border-top: 1px solid $border; }
    &__list {
      display: block;
      width: 100%;
      margin-bottom: 24px;
      li {
        display: block;
        width: 100%;
        padding: 8px 12px;
        margin: 0;
        &:nth-child(odd) {
          background: #FBFBFB; } } } }

  @include mobilesm() {
    .nav {
      border: 1px solid $border;
      position: relative;
      padding-top: 49px;
      &:after {
        content: "";
        display: block;
        width: 28px;
        height: 28px;
        background: url(../images/ic-dropdown.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        right: 16px;
        top: 10px; }
      &-item {
        flex: 0 0 100%;
        max-width: 100%;
        .nav-link {
          border-top: 0;
          max-height: 0;
          opacity: 0;
          visibility: hidden;
          padding: 0 24px;
          border: 0;
          transition: all .4s ease-in-out;
          text-align: left;
          &.active {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-height: 50px;
            visibility: visible;
            padding: 12px 24px;
            opacity: 1;
            padding-right: 30px;
            background: #f2f2f2; } } }
      &.expand {
        .nav-item .nav-link.active {
          border-bottom: 1px solid $border; }
        .nav-item .nav-link:not(.active) {
          max-height: 50px;
          opacity: 1;
          padding: 12px 24px;
          border-bottom: 1px solid $border;
          visibility: visible; } } }

    .pdsm {
      &__head {
        width: 100%;
        flex-wrap: wrap; } } } }





.modal {
  width: 100%;
  &--referral {
    .modal-dialog {
      max-width: 1224px;
      padding: 0; }
    .modal-close {
      width: 40px;
      height: 40px;
      z-index: 5; }
    .modal-body {
      padding: 3rem 6.5rem 5rem;
      h2,h3 {
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 1rem; }
      ol {
        padding-bottom: 1.25rem;
        li {
          font-size: 1rem;
          line-height: 1.5rem;
          color: #545465;
          margin-bottom: 1.25rem;
          margin-left: 1rem;
          padding-left: 1rem; } } }
    @include mobile {
      .modal-body {
        padding: 16px 7px;
        h2,h3 {
          font-size: 1.5rem;
          line-height: 2rem; } } } } }


.modal--pricing {
  .modal-dialog {
    max-width: 1280px;
    // max-height: calc(100vh - 120px)
    border-radius: 4px;
    box-shadow: none;
    padding: 80px 28px;
    margin: 60px auto; }
  .modal-header {
    text-align: center; }


  @media (max-width: 991px) {
    .modal-dialog {
      width: calc(100% - 24px);
      max-width: 100%;
      margin: 30px 12px;
      padding: 32px 16px; } }

  .pricing-detail {
    display: block; }
  .pricing-detail-sm {
    display: none; }

  @include mobile {
    .pricing-detail {
      display: none; }
    .pricing-detail-sm {
      display: block; } } }



.suit {
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  &__list {
    display: flex;
    flex-wrap: wrap;
    .icon {
      position: absolute;
      display: block;
      width: 32px;
      height: auto;
      left: 0;
      top: 0; }
    li {
      position: relative;
      display: block;
      min-height: 48px;
      width: 100%;
      margin-bottom: 24px;
      padding-left: 44px;
      &:last-child {
        margin-bottom: 0; } } }
  &__label {
    font-size: 12px;
    line-height: 18px;
    color: #B4B4B4;
    margin-bottom: 16px; }
  &__card {
    padding: 24px;
    border: 2px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    &.hightlight {
      border: 2px solid #005FBF;
      box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.75);
      .suit__label {
        color: $blue; } } } }

.modal-download {
  .modal-content {
    box-shadow: none!important; } }


.timeline {
  position: relative;
  display: block;
  &__wrap {
    padding-top: 60px;
    padding-bottom: 60px; }
  &__img {
    padding-left: 24px;
    z-index: 2;
    img {
      display: block;
      width: 100%;
      height: auto; }
    &.fixed {
      position: fixed; } }
  &__list {
    &.alt {
      &> li {
        padding-bottom: 200px; } }
    &> li {
      position: relative;
      padding-left: 32px;
      padding-bottom: 120px;
      opacity: .75;
      transition: all .3s ease-in-out;

      .img {
        display: none;
        padding: 0 24px;
        text-align: center;
        img {
          max-width: 100%;
          display: block;
          margin: 0 auto 16px; } }
      h3 {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 8px; }
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        background: #E2E2E2;
        border: 4px solid transparent;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
        top: 10px;
        left: -7px;
        z-index: 2;
        border-radius: 50%; }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 100%;
        background: #E2E2E2;
        top: 25px;
        left: 0;
        z-index: 1; }
      &:last-child {
        padding-bottom: 0!important;
        &::after {
          display: none; } }
      &.active {
        opacity: 1;
        &:before {
          background: $blue;
          border: 4px solid #FFF; } } } }


  &--promo {
    .timeline__list {
      &> li {
        padding-bottom: 48px;
        &:hover {
          cursor: pointer; } } } }

  @include mobilelg {
    &__wrap {
      padding-top: 40px;
      padding-bottom: 60px; }
    &__img {
      display: none; }
    &__list {
      li {
        padding: 0;
        opacity: 1;
        text-align: left;
        .img {
          display: block;
          margin-bottom: 32px; }

        &:before {
          display: none; } }
      .owl-dots {
        text-align: left;
        .owl-dot {
          background: #E2E2E2;
          width: 32px;
          height: 4px;
          border-radius: 2px; } } } } }




.form-block {
  padding: 5rem 0;
  background: #F2F2F3;
  font-size: 14px;
  line-height: 1.5em;
  &:first-child {
    padding-top: 10rem; }
  &__wrap {
    padding: 2rem;
    background: #FFF;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
    border-radius: 4px; }
  .form-group {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0; } }
  .form-action {
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 1.5rem; }
    .btn {
      display: block;
      width: 100%; }
    .btn + .btn {
      margin-top: 12px; } }
  label:not(.custom-control-label) {
    display: block;
    color: #252525;
    font-size: 14px;
    line-height: 1.25em;
    @include font-500;
    margin: 0 0 .2em; }
  .form-control {
    display: block;
    font-size: 14px;
    width: 100%;
    border: 1px solid $border;
    height: 38px;
    padding: 6px 12px;
    border-radius: 4px;
    &:focus {
      border-color: $primary; } }
  textarea.form-control {
    height: auto;
    resize: none; }
  .dropdown.bootstrap-select {
    .bttn {
      font-size: 14px;
      line-height: 36px;
      padding-left: 12px;
      &:after {
        top: 0;
        line-height: 38px; } }
    div.dropdown-menu a {
      padding: 8px 12px; } }
  .custom-control + .custom-control {
    margin-top: .2rem; }

  a {
    color: #0060BF;
    text-decoration: none;
    &:hover {
      text-decoration: underline; } } }


.small-banner {
  .slider {
    height: 100%; }
  &__wrap {
    padding: 2rem 0;
    &.pb {
      padding-top: 0;
      padding-bottom: 80px; } }
  &__box {
    display: block;
    position: relative;
    padding: 3rem 35% 3rem 3rem;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
    // background: #F8FAFC
    background: #FFFFFF;
    &.center {
      padding: 3rem;
      text-align: center;
      @include mobilesm {
        padding: 1.2rem; } }
    &--alt {
      padding: 3rem; }
    @include mobile {
      padding: 2rem;
      margin-bottom: 40px; } }
  &__title {
    h3,h2,span {
      display: block;
      font-size: 24px;
      line-height: 1.5em;
      @include font-500;
      margin: 0 0 1rem; } }
  &__action {
    margin-top: 32px;
    a,.btn {
      &:first-child {
        margin-right: 16px; } }
    @include mobile {
      text-align: center;
      .btn {
        display: block;
        width: 100%; }
      a,.btn {
        &:first-child {
          margin-right: 0;
          margin-bottom: 16px; } } } }


  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-top: 10px;
    padding-right: 0;
    height: 100%;
    img {
      display: inline-block;
      height: 100%;
      width: auto; }
    &--alt {
      position: relative;
      padding-left: 6px;
      margin-right: -12px;
      img {
        margin-top: -50%;
        display: block;
        max-width: 100%;
        width: auto;
        height: auto; } }
    @include mobile {
      position: relative;
      max-width: 100%;
      padding: 0;
      margin: 24px 0 10px;
      text-align: center;
      height: auto;
      img {
        height: auto; }
      &--alt {
        img {
          margin-top: 0; } } } }

  .slider {
    position: relative; }
  .owl {
    &-item {
      padding: 10px 15px 30px 15px;
      @include mobile {
        padding: 10px 10px 30px 10px; } }
    &-dots {
      position: absolute;
      bottom: 30px;
      left: 70px;
      button {
        background: #E2E2E2;
        &.active {
          background: #009BDE; } } } }
  &--promo {
    .small-banner__box {
      background: #F2F4F7;
      box-shadow: 0px 12px 12px rgba(50, 50, 71, 0.08), 0px 16px 24px rgba(50, 50, 71, 0.08);
      border-radius: 8px; } } }



.slider--promo {
  padding: 64px 0;
  .item {
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      position: relative;
      label {
        position: absolute;
        top: 0;
        left: 0;
        color: transparent; }
      img {
        max-height: 480px; } } }
  .owl-dot {
    background: #E2E2E2!important;
    &.active {
      background: #005FBF!important; } } }

.banner-cta {
  padding-bottom: 80px;
  &__box {
    background: #F2F4F7;
    border-radius: 8px;
    .col-12 {
      padding: 44px 48px;
      &:last-child {
        padding: 0; } } }
  &__title {}
  &__desc {
    margin-bottom: 12px; }
  &__action {
    a {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto;
      label {
        position: absolute;
        left: 0;
        top: 0;
        color: transparent; }
      &:hover {
        cursor: pointer;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); } } }
  &__img {
    img {
      width: 100%;
      object-fit: cover; } }
  @include mobile {
    &__box {
      .col-12 {
        padding: 20px 22px; } } } }


.custom-form {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: #F2F4F7;
  .container-full {
    height: 100%;
    &> .row {
      height: 100%; } }
  &__wrapper {
    flex-direction: row-reverse; }
  &__meta {
    padding: 40px 100px 24px;
    height: 100%;

    &-title,
    &-desc {
      display: block;
      @include mobile {
        display: none; }
      .custom-list {
        position: relative;
        margin-bottom: 32px;
        li {
          position: relative;
          display: inline-block;
          padding-left: 36px;
          margin-bottom: 16px;
          margin-right: 16px;
          &:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background: url(../images/ic-check.svg) no-repeat center;
            background-size: contain;
            position: absolute;
            top: 0;
            left: 0; }
          &:last-child {
            margin-bottom: 0; } } } }
    &-logo {
      margin-bottom: 64px;
      display: block;
      @include mobile {
        display: none; } }

    &-illustration {
      text-align: center;
      margin-bottom: 64px; }

    &-testimonials {
      margin-top: 40px;
      margin-bottom: 64px;
      @include mobile {
        margin-top: 0;
        margin-bottom: 30px; } }

    .testimonial__wrap {
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      padding-bottom: 0;
      margin-bottom: 0;
      .testimonial-big__content {
        padding: 0;
        .testimonial-big__blockquote {
          @include font-500; }
        .testimonial-big__footer {
          padding-bottom: 0;
          img {
            width: auto!important; } } }
      .owl-dots {
        padding: 0;
        margin: 24px 0 0 0;
        button.owl-dot {
          background: #E2E2E2;
          &.active {
            background: #009BDE; } } } }

    .nav-tabs {
      margin-top: 8px;
      margin-bottom: 64px;
      .nav-item {
        display: inline-block;
        width: 48px;
        height: 48px;
        .nav-link {
          opacity: .5;
          &.active {
            opacity: 1; } } } }

    &-footer {
      label {
        @include font-500;
        display: block;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 15px; } }
    &-icon {
      margin-bottom: 48px;
      a {
        margin-right: 25px; } }
    @include mobile {
      padding: 0px 16px 30px; } }

  &__form {
    padding: 64px 100px 24px;
    &-logo {
      display: none;
      margin-bottom: 50px;
      @include mobile {
        display: block;
        margin-bottom: 30px;
        img {
          max-height: 40px;
          width: auto; } } }
    &-title {
      @include font-600;
      font-size: 2rem;
      line-height: 2.5rem;
      margin-bottom: 1rem;
      display: none;
      @include mobile {
        display: block; } }
    &-subtitle {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 4px; }
    &-desc {
      margin-bottom: 1.5rem;
      display: none;
      .custom-list {
        position: relative;
        margin-bottom: 32px;
        li {
          position: relative;
          display: inline-block;
          padding-left: 36px;
          margin-bottom: 16px;
          margin-right: 16px;
          &:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background: url(../images/ic-check.svg) no-repeat center;
            background-size: contain;
            position: absolute;
            top: 0;
            left: 0; }
          &:last-child {
            margin-bottom: 0; } } }
      @include mobile {
        display: block; } }
    @include mobile {
      padding: 40px 16px 30px; } }
  &__box {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
    border-radius: 4px;
    max-width: 496px;
    padding: 40px;
    @include mobile {
      padding: 20px; } } }

.tf {
  text-align: center;
  position: relative;
  z-index: 1;
  background: #F8FAFC;
  &.bg {
    background: #F8FAFC; }
  &.blue {
    background: #143A72; }
  &-title {
    padding: 80px 0 48px;
    @include mobile {
      padding: 40px 0; } }
  h3,.tf-cta__title {
    margin: 0 auto 64px;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
    @include mobilesm {
      font-size: 21px;
      line-height: 37px; } }
  &_img {
    width: 80px;
    //height: 70px
    background-color: #F2F2F2;
    background-repeat: no-repeat;
    display: block;
    margin: 0 auto 16px; }

  h3,.tf-cta__title {
    font-weight: 500;
    @extend .font-16;
    margin: 0 0 8px; }
  &_item {
    padding: 48px 60px 64px;
    @include mobile {
      padding: 48px 15px 34px; }
    &.is-highlight {
      background: #002A38;
      color: #FFF;
      h3,.tf-cta__title {
        color: #FFF; }
      @include mobilelg {
        border-radius: 0; } } }
  .btn-white {
    min-width: 180px;
    max-width: 100%;
    border: none;
    &:hover {
      background: darken(#143A72,5%); } }
  &--alt {
    background: #F2F4F7;
    .tf_item {
      &.is-highlight {
        background: #232933; }
      h2,
      h3,
      .tf-cta__title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 12px; }
      img {
        height: 72px;
        width: auto; } } } }



.testimonial {
  &__label {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
    display: inline-block; }
  &--promo {
    background: #F2F4F7;
    padding: 80px 0;
    .testimonial__wrap {
      background: transparent;
      margin-bottom: 0;
      box-shadow: none;
      padding: 0;
      .testimonial-big__content {
        padding: 0; }
      .testimonial-big__blockquote {
        @include font-500;
        font-size: 32px;
        line-height: 40px; }
      .testimonial-big__footer {
        padding-bottom: 0; } } } }


// style for in app

.in-app {
  .btn {
    padding: 8px 16px; }
  .btn-blue {
    @include font-500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px; }
  .btn-grey {
    background: #FBFBFB;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #777777;
    &:hover {
      background: darken(#FBFBFB, 10%); } }
  .btn-yellow {
    background: #F8E51E;
    border: 1px solid #F8E51E;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #293B85;
    &:hover {
      background: darken(#F8E51E, 10%); } }
  .btn-blue-border {
    font-size: 14px;
    line-height: 20px; }
  hr.separator {
    margin: 0;
    border-top: 1px solid $border; } }

.web-wrapper.in-app {
  overflow: hidden;
  padding: 24px 24px 0;
  background: #F2F4F7;
  main {
    border: 1px solid #dcdee1;
    border-radius: 4px 4px 0 0;
    background: #FFF; }
  &.opt {
    padding: 0;
    background: #fff;
    main {
      border: none;
      border-radius: 0;
      background: #FFF; } } }

.masthead.in-app {
  // background: #FBFBFB
  padding-top: 56px;
  .masthead__content {
    padding-top: 0; }
  .masthead__logo {
    margin-bottom: 24px;
    display: inline-block; }
  .masthead__title {
    @include font-500();
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.2px;
    color: #212121;
    margin-bottom: 24px; }
  p {
    @include font-400();
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #777777; }
  @include mobile {
    .masthead__action {
      .btn {
        margin-left: 0;
        margin-bottom: 16px; } } } }

.advantage.in-app {
  padding-top: 0;
  padding-bottom: 0;
  .section-head__title {
    @include font-500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #000000; }
  .post__img {
    margin-bottom: 16px; }
  .post__content {
    padding-right: 80px;
    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #777777; } }
  .allinone__wrap {
    padding: 80px 0;
    border-bottom: 1px solid #E2E2E2; }
  .post.text-center {
    .post__content {
      padding-right: 0; }
    .post__img.lg {
      display: inline-block;
      img {
        width: 80px;
        height: 80px; } } } }

.timeline.in-app {
  .timeline__wrap {
    border-bottom: 1px solid #E2E2E2; }
  .section-head__title {
    @include font-500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #000000; }
  .timeline__list {
    h3 {
      @include font-500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: #212121; }
    &> li {
      padding: 24px;
      margin-left: 32px;
      margin-bottom: 48px;
      &::before {
        top: 40px;
        left: -35px; }
      &::after {
        height: 130%;
        top: 60px;
        left: -27px; } }
    li.active {
      background: #F2F4F7;
      border-radius: 16px; } }

  @include mobilelg {
    .timeline__img {
      display: none; }
    .timeline__list {
      li.active {
        background: transparent;
        h3 {
          text-align: center; } } } } }

.tf.in-app {
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  .tf_item.is-highlight {
    padding: 60px 0;
    background: #002A38;
    @include mobile {
      padding-left: 12px;
      padding-right: 12px; } }
  h3 {
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.2px;
    @include font-500;
    margin-bottom: 8px; }
  p {
    @include font-400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    margin-bottom: 24px; } }

.copyright.in-app {
  padding: 24px 12px;
  p {
    @include font-400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #777777; } }

.testi {
  background: linear-gradient(90deg, #fff 26%, #F2F4F7 26%);
  .testimonial-big {
    padding-top: 0;
    margin: 0;
    &__wrap {
      padding: 64px 0;
      background: transparent;
      box-shadow: none;
      .testimonial-big__img {
        .block-img {
          padding: 0;
          img {
            position: relative; } } } }
    &__content {
      padding-left: 128px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .testimonial-big__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px; }
      .testimonial-big__blockquote {
        margin-bottom: 27px;
        p {
          font-size: 20px;
          line-height: 28px; } } } }

  @include mobile {
    background: #F2F4F7;
    .testimonial-big__content {
      padding-left: 0; } } }

/* Contact */
.contact-cards {
  padding: 0 0 46px;
  .item {
    margin-bottom: 24px;
    &__wrap {
      display: flex;
      height: 100%;
      flex-direction: column;
      padding: 2rem 2rem 1.5rem;
      text-align: center;
      background: #F2F4F7;
      border-radius: 0.25rem; }
    &__img {
      margin: 0 0 0.75rem;
      img {
        display: block;
        width: auto;
        height: 5rem;
        margin: 0 auto; } }
    &__title {
      margin: 0 0 1rem;
      @include mobile {
        img {
          display: none !important; } } }
    &__desc {
      margin: 0 0 2rem;
      ul > li {
        margin: 0 0 0.5rem;
        &:last-child {
          margin: 0; } } }
    &__action {
      .btn + .btn {
        margin-top: 1rem;
        display: block; }
      .btn-link {
        color: #005FBF;
        &:hover {
          text-decoration: underline; } } } }
  .item--sm {
    background: #F2F4F7;
    border-radius: 4px;
    margin-bottom: 12px;
    .item {
      &__wrap {
        padding: 8px 12px;
        flex-direction: row;
        align-items: center; }
      &__img {
        width: 40px;
        height: 40px;
        margin-bottom: 0;
        margin-right: 16px;
        img {
          width: 40px;
          height: 40px; } }
      &__title {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px; } } } }

.form-meeting {
  background: #F2F4F7;
  padding: 5rem 0;
  &__title {
    margin: 0 0 1rem; }
  &__desc {
    margin: 0 0 3rem; }
  &__img {
    display: block;
    margin: 0 0 1.5rem; }
  &__box {
    padding: 2.5rem;
    background: #FFF;
    border-radius: 0.25rem;
    @include maxtablet {
      overflow-x: auto; } }


  &--landing {
    .form-meeting__title {
      font-size: 2rem;
      line-height: 2.5rem;
      margin-bottom: 2.5rem; }
    .form-meeting__box {
      box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); } } }

.tf {
  &_item.is-highlight {
    h2 {
      color: #FFF; } } }

.form-meeting {
  background: #F2F4F7;
  padding: 5rem;
  &__title {
    margin: 0 0 1rem; }
  &__desc {
    margin: 0 0 3rem; }
  &__img {
    display: block;
    margin: 0 0 1.5rem; }
  &__box {
    padding: 2.5rem;
    background: $white;
    border-radius: 0.25rem; } }


.footer--landing {
  padding: 24px 0;
  background: #F3F3F3;
  &-alt {
    background: #FFF; }
  .text-right {
    b {
      margin-right: 20px; }
    span {
      a {
        margin-right: 16px;
        &:last-child {
          margin-right: 0; } } } }
  @include mobilesm {
    .container {
      .row {
        .col-md-6 {
          text-align: center;
          margin-bottom: 16px;
          b {
            display: block;
            margin-bottom: 12px; } } } } } }

.tf--landing {
  background: url('../images/pattern-cta.svg');
  color: #FFF;
  h1,h2,h3,h4,h5,h6 {
    color: #FFF; } }

.block-clients {
  padding-top: 0;
  padding-bottom: 5rem;
  background: #F2F4F7;
  &__title {
    text-align: center;
    font-size: 1.25rem;
    line-height: 2rem; }
  &__desc {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 3rem;
    p {
      margin-bottom: 1rem; } }
  ul.clients {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      max-width: 16.666667%;
      flex: 0 0 16.666667%;
      margin: 24px 0;
      img {
        height: 60px;
        width: auto; }
      .img--2 {
        display: none; } } }

  &--pricing {
    background: #F2F4F7;
    padding-top: 56px;
    padding-bottom: 56px; } }

.slider-section {
  &.owl-carousel {
    [class^="col"] {
      max-width: 100%; }
    .owl-dots {
      .owl-dot {
        width: 40px;
        height: 5px;
        border-radius: 15px;
        background: #eee;
        &.active {
          background: $blue; } } } } }

// style for in app cross sell

.crossell-form {
  margin-top: 100px;
  background: #FFF;
  box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
  padding: 32px;
  border-radius: 8px; }

.crossell-socproof {
  padding-bottom: 64px;
  padding-top: 64px;
  &__title {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 52px; }
  &__desc {
    text-align: center;
    margin-bottom: 48px; }
  .item {
    margin-bottom: 20px;
    &__wrap {
      padding-left: 16px; }
    &__number {
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 2px;
        height: 32px;
        position: absolute;
        top: calc(50% - 16px);
        left: -16px;
        background-color: #C02A34; }
      span {
        color: #C02A34;
        font-size: 32px;
        line-height: 48px;
        @include font-500();
        display: block;
        margin-bottom: 0; } }
    &__desc {
      max-width: 85%; } }

  @include maxtablet {
    padding-top: 32px;
    padding-bottom: 32px; } }

.crossell-clients {
  padding-top: 80px;
  padding-bottom: 64px;
  h2 {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px; }
  .clients {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -32px;
    .item {
      display: flex;
      width: 100%;
      height: 60px;
      max-width: 16.666667%;
      flex: 0 0 16.666667%;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      margin-bottom: 24px;
      &.show {
        display: flex !important; }
      img {
        display: block;
        max-width: 100%;
        max-height: 60px;
        width: auto;
        height: auto; } } }

  .clients-more {
    padding-top: 36px;
    text-align: center; }
  @include maxtablet {
    padding-top: 32px;
    // padding-bottom: 32px
    .clients {
      .item {
        max-width: 33.333%;
        flex: 33.333%;
        img {
          height: 32px; } } } } }

.howjurnal.howjurnal--xsell {
  .section-head {
    &__title {
      font-size: 24px;
      line-height: 32px; } }

  .accordion .card.open {
    border-color: #C02A34; } }

.feature-block {
  &.row-reverse {
    .row {
      flex-direction: row-reverse; } }
  &__wrap {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .section-head {
    text-align: center;
    margin-bottom: 3rem; }

  .post {
    position: relative;
    &__img {
      margin-bottom: 0.5rem;
      .icon {
        display: block;
        width: auto;
        height: 3.5rem; } }
    &__content {
      padding-right: 2.5rem;
      &.np {
        padding-right: 0; } }
    &__title {
      display: block;
      color: $text;
      font-size: 1rem;
      line-height: 1.5em;
      @include font-500();
      margin-bottom: 0.75rem; } }

  &.title-only {
    .feature-block__wrap {
      padding-top: 64px;
      padding-bottom: 0;
      @include mobile {
        padding-top: 0;
        padding-bottom: 0; } }
    .section-head {
      margin-bottom: 0; }
    &.promo {
      background: linear-gradient(267.45deg, #0158CA 7.3%, #0169D7 42.37%, #016DDB 81.39%);
      .section-head {
        padding-bottom: 48px;
        &__title {
          color: $white;
          font-size: 28px;
          line-height: 36px; } } }
    @include mobile {
      &.promo {
        background: linear-gradient(267.45deg, #0159CB 7.3%, #0169D8 49.9%, #016EDB 81.39%);
        .section-head {
          padding-bottom: 20px;
          &__title {
            font-size: 26px;
            line-height: 32px; } } } } }

  @include maxtablet {
    .post {
      &__content {
        padding-right: 0; } } } }

.page-tabs {
  position: relative;
  &__header {
    padding-top: 4rem; }

  &__title {
    display: block;
    width: 100%;
    text-align: center;
    color: #212121;
    max-width: 600px;
    margin: 0 auto 28px; }
  &__desc {
    max-width: 600px;
    margin: 0 auto 64px;
    @include maxtablet {
      margin: 0 auto 40px; } }
  .nav-tabs {
    .nav-link {
      display: block;
      border: 1px solid $border;
      border-radius: 0.25rem;
      padding: 1.5rem;
      text-align: center;
      color: #212121;
      background: #FFFFFF;
      transition: all .3s ease;
      height: 100%;
      .icon {
        display: block;
        height: 48px;
        width: 48px;
        margin: 0 auto 12px; }
      span {
        display: block;
        font-size: 0.875rem;
        line-height: 1.5em;
        color: #212121; }
      h3 {
        display: block;
        font-size: 1.5rem;
        line-height: 1.333em;
        margin: 0;
        color: #212121; }
      &:hover {
        text-decoration: none;
        background: #F2F4F7;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }
      &.active {
        text-decoration: none;
        background: #F2F4F7;
        border-color: $blue;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
        .icon {
          path {
            fill: #005FBF; } } } } }

  @media (min-width: 1600px) {
    .container.container--nav-tabs {
      max-width: 1600px; } }

  &--alt {
    .page-tabs__header {
      @include mobile {
        padding: 4rem 0 6rem 0; } }
    .page-tabs__title {
      @include maxtablet {
        padding-top: 30px; } }
    .page-tabs__body {
      .mobile-section {
        display: none;
        // text-align: center
        @include mobile {
          display: block;
          .img {
            margin-bottom: 16px;
            max-height: 48px;
            width: auto; }
          h3 {
            margin-bottom: 12px; }
          h4 {
            margin-bottom: 12px; } } } }

    .page-tabs__dropdown {
      position: relative;
      @include mobile {
        margin-top: -10px; }
      .selected {
        display: none;
        width: 100%;
        height: 48px;
        position: absolute;
        left: 0;
        top: 0;
        padding: 10px 35px 10px 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #2B2A35;
        font-size: 16px;
        line-height: 1.85rem;
        @include font-500;
        &::after {
          content: '';
          background: url(../images/arrow-down.svg) no-repeat center;
          width: 24px;
          height: 24px;
          display: inline-block;
          position: absolute;
          bottom: 12px;
          right: 10px; }
        @include mobile {
          height: 48px;
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #E2E2E2;
          border-radius: 4px;
          display: block; } }
      &.active {
        .nav-tabs {
          // overflow-x: hidden
          // overflow-y: scroll
          height: auto;
          left: 12px;
          background: #FFF;
          padding: 0;
          .nav-item {
            padding: 0;
            .nav-link {
              opacity: 1;
              visibility: visible;
              max-height: 100%;
              &.active {
                max-height: 100%; } } } } } }

    .nav-tabs {
      .nav-item {
        &__title {
          display: flex;
          align-items: center;
          text-align: left;
          margin-bottom: 0.75rem;
          @include maxtablet {
            margin-bottom: 0; }
          h3 {
            font-size: 16px;
            line-height: 24px;
            @include mobile {
              font-size: 1rem;
              line-height: 1.5rem; } }
          .ic {
            max-width: 40px;
            min-width: 40px;
            margin-right: 1rem; } }
        .nav-link {
          background: #FFF;
          border: 0;
          position: relative;
          padding: 2rem;
          text-align: left;

          &:hover,
          &.active {
            box-shadow: none;
            background: #F2F4F7;
            border-radius: 8px 8px 0 0;
            &::after {
              content: '';
              background: url(../images/arrow-down.svg) no-repeat center;
              width: 24px;
              height: 24px;
              display: inline-block;
              position: absolute;
              bottom: 0.75rem;
              left: calc(50% - 12px); }
            @include mobile {
              &::after {
                display: none; } } }
          @include mobile {
            border-radius: 0!important;
            border: 1px solid #E2E2E2;
            padding: 12px;
            height: auto;
            .ic {
              display: none; } } } }
      &.slider-tabs {
        .owl-stage-outer {
          overflow: visible; }
        .owl-stage {
          display: flex;
          flex-wrap: nowrap;
          &::after {
            display: none; } }
        .owl-item {
          position: relative;
          width: 100% !important;
          padding: 0 12px; }
        .nav-item {
          display: flex;
          height: 100%; }

        @include maxtablet() {
          width: 100%;
          .nav-item {
            width: 100%;
            display: block; } } } }

    .page-tabs {
      &__section-head {
        padding-top: 80px;
        padding-bottom: 48px; }
      &__title {
        margin-bottom: 16px;
        text-align: center; }
      &__desc {
        text-align: center; }
      &__body {
        background: #F2F4F7; } }

    .page-tabs__wrap.sticky-me {
      z-index: 20;
      .page-tabs__header {
        transition: all .4s ease;
        padding-top: 0; }
      .nav-tabs .nav-item {
        &__title {
          transition: all .4s ease;
          .ic {
            transition: all .4s ease; } }
        .nav-link {
          transition: all .4s ease; } }
      &.stick {
        background: #FFF;
        bottom: auto !important;
        border-bottom: 1px solid $border;
        .page-tabs__header {
          padding-top: 56px; }
        .nav-tabs .nav-item {
          &__title {
            margin: 0;
            .ic {
              height: 30px;
              width: 30px;
              min-width: 30px;
              margin-right: 12px; }
            h3 {
              font-size: 16px;
              line-height: 24px; } }
          .nav-link {
            display: flex;
            align-items: center;
            border-radius: 0;
            padding-top: 12px;
            padding-bottom: 12px;
            p {
              display: none; }
            &::after {
              display: none; } } } }
      &.show-top.stick {
        .page-tabs__header {
          padding-top: 97px; } } }

    @include mobile {
      background: #F2F4F7;
      .nav-tabs {
        position: absolute;
        z-index: 2;
        height: 0;
        overflow: hidden;
        top: 49px;
        left: 0;
        .nav-link {
          .nav-item__title {
            h3 {
              margin-bottom: 0; } }
          p {
            display: none; } } } } }

  &--red {
    .page-tabs__header {
      padding-top: 0;
      .nav-tabs {
        .nav-item {
          .nav-link {
            padding: 16px 24px;
            border: 1px solid #F2F4F7;
            border-bottom-width: 0;
            background: transparent;
            border-radius: 8px 8px 0 0;
            box-shadow: none;
            cursor: pointer;
            .nav-item__title {
              text-align: left;
              cursor: pointer;
              display: flex;
              align-items: center;
              .ic.svg {
                width: 40px;
                height: auto;
                margin-right: 16px;
                filter: brightness(0) invert(1); }
              label {
                @include font-600;
                margin-bottom: 0;
                color: #FFF; } }
            &.active,&:hover {
              background: #FFF;
              cursor: pointer;
              .nav-item__title {
                .ic.svg {
                  width: 40px;
                  margin-right: 16px;
                  filter: brightness(1) invert(0); }
                label {
                  color: $red; } } } } } }

      .page-tabs__dropdown {
        position: relative;
        @include mobile {
          margin-top: -10px; }
        .selected {
          display: none;
          width: 100%;
          height: 48px;
          position: absolute;
          left: 0;
          top: 0;
          padding: 10px 35px 10px 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #2B2A35;
          font-size: 16px;
          line-height: 1.85rem;
          @include font-500();
          &::after {
            content: '';
            background: url(../images/arrow-down.svg) no-repeat center;
            width: 24px;
            height: 24px;
            display: inline-block;
            position: absolute;
            bottom: 12px;
            right: 10px; }
          @include mobile {
            height: 48px;
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #E2E2E2;
            border-radius: 4px;
            display: block; } }
        &.active {
          .nav-tabs {
            overflow-x: hidden;
            overflow-y: scroll;
            height: auto!important;
            border: 1px solid $border;
            margin: 0;
            padding-right: 0;
            .nav-item {
              background: $white;
              margin: 0;
              border: 0;
              padding: 0;
              .nav-link {
                .nav-item__title {
                  text-align: left;
                  label {
                    color: $text-dark; } } } } } } } }
    .page-tabs__body {
      background: #fff; }

    .page-tabs__wrap {
      &.sticky-me {
        &.stick {
          z-index: 2;
          background: linear-gradient(267.45deg, #039DDF 7.3%, #16A6E5 42.37%, #19A8E6 81.39%);
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
          .page-tabs__header {
            .page-tabs__dropdown {
              .nav-tabs {
                .nav-item {
                  .nav-link {
                    border-radius: 0;
                    border-top-width: 0;
                    padding: 8px 24px; } } } } } } } }


    @include mobile {
      .page-tabs__wrap {
        height: 75px;
        width: 100%!important;
        .page-tabs__header {
          .page-tabs__dropdown {
            margin-top: 7px;
            .nav-tabs {
              position: absolute;
              z-index: 2;
              height: 0;
              overflow: hidden;
              top: 49px;
              left: 0;
              .nav-link {
                .nav-item__title {
                  h3 {
                    margin-bottom: 0; }
                  .ic.svg {
                    filter: unset!important; } }

                p {
                  display: none; } } } } } }
      .page-tabs__body {
        background:#F2F4F7 {} } }



    @include mobile {
      .page-tabs__wrap {
        height: 65px;
        width: 100%!important;
        .page-tabs__header {
          .page-tabs__dropdown {
            margin-top: 7px;
            .nav-tabs {
              position: absolute;
              z-index: 2;
              height: 0;
              overflow: hidden;
              top: 49px;
              left: 0;
              .nav-link {
                .nav-item__title {
                  h3 {
                    margin-bottom: 0; }
                  .ic.svg {
                    filter: unset!important; } }
                p {
                  display: none; } } } } } }
      .page-tabs__body {
        background:#F2F4F7 {} } } }
  &--accr {
    @include maxtablet {
      .page-tabs__wrap {
        display: none; }
      .tab-content--accr {
        padding: 0 16px 64px 16px;
        .tab-pane--accr {
          display: block;
          opacity: 1;
          padding: 20px 0;
          background: #FFF;
          border-radius: 4px;
          margin-bottom: 2px;
          .mobile-section {
            position: relative;
            padding-left: 12px;
            padding-right: 12px;
            img {
              display: none; }
            h3 {
              font-size: 18px;
              line-height: 28px;
              padding-right: 20px; }
            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              top: 5px;
              right: 10px;
              width: 20px;
              height: 20px;
              background: url('../images/ic-chevron-down.svg') no-repeat center;
              background-size: contain;
              translate: all linear .3s; } }
          .mobile-section p,
          .fitur-blocks,
          .testimonial-industry,
          .block-cta {
            display: none;
            background: #FFF; }
          .testimonial-industry {
            padding-bottom: 30px;
            .item__box {
              background: #F2F4F7; } }
          .block-cta {
            .container {
              padding-top: 20px;
              padding-bottom: 20px; } }
          .fitur-blocks--industry {
            .fitur-block {
              padding: 24px 12px; } }

          &.active {
            .mobile-section {
              &::after {
                transform: rotateZ(180deg);
                translate: all linear .3s; } }
            .mobile-section p,
            .fitur-blocks,
            .testimonial-industry,
            .block-cta {
              display: block;
              background: #FFF; } } } } } } }


.promo-banner {
  padding: 67px 0 0 0;
  &__wrap {
    background: $white;
    padding-bottom: 64px;
    border-bottom: 1px solid #E3E3E8;
    .post {
      color: #2B2A35;
      margin-bottom: 48px;
      &__img {
        margin-bottom: 12px;
        img {
          width: 80px!important;
          height: auto!important; } }
      &__title {
        margin-bottom: 16px;
        display: block;
        font-size: 20px;
        line-height: 32px;
        @include font-600; } } }
  &__title {
    margin-bottom: 48px;
    font-size: 28px;
    line-height: 36px; }
  &__content {
    padding-left: 48px;
    padding-right: 48px; }
  &__img {
    margin-bottom: 64px;
    img {
      object-fit: cover;
      width: 100%;
      height: auto; } }
  &__action {
    padding-left: 16px;
    padding-right: 16px;
    a {
      display: inline-block;
      margin-right: 16px;
      &:last-child {
        margin-right: 0; } } }

  @include maxtablet {
    padding: 24px 0;
    &__wrap {
      border-radius: 0 0 6px 6px;
      border-bottom: 0;
      padding-bottom: 48px;
      .post {
        margin-bottom: 30px;
        &__img {
          display: inline-block;
          width: 56px;
          height: 56px;
          margin-right: 16px;
          vertical-align: top;
          img {
            width: 56px!important;
            height: auto!important; } }
        &__content {
          display: inline-block;
          width: calc(100% - 77px);
          vertical-align: top;
          p {
            display: none; } }
        &__title {
          margin: 8px 0;
          font-size: 16px;
          line-height: 23px; } } }
    &__content {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 40px; }
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 37px; }
    &__action {
      margin-top: 10px;
      a {
        display: block;
        width: 100%;
        margin-bottom: 16px;
        margin-right: 0;
        &:last-child {
          margin-bottom: 0; } }
      &.alt {
        margin-top: 28px; } }
    &__img {
      margin-bottom: 0;
      img {
        height: auto; } } } }

.promo-benefit {
  padding: 64px 0 80px 0;
  &__content {
    padding-right: 60px; }
  &__subtitle,
  &__meta {
    font-size: 16px;
    line-height: 24px;
    @include font-600(); }
  &__title {
    margin-bottom: 28px; }
  &__action {
    margin-top: 30px;
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; } } }
  &__label {
    @include font-600;
    margin-bottom: 12px; }
  &__img {
    img {
      object-fit: contain; }
    .video {
      video {
        width: 100%;
        height: auto;
        pointer-events: none; } } }
  @include maxtablet {
    padding: 24px 0;
    &__wrap {
      color: $text;
      .row {
        flex-direction: column-reverse; } }
    &__content {
      padding-right: 0;
      .custom-list {
        margin-bottom: 36px; } }
    &__subtitle,
    &__meta {
      text-align: center; }
    &__title {
      font-size: 26px;
      line-height: 32px;
      text-align: center; }
    &__action {
      a {
        width: 100%;
        display: block;
        margin-bottom: 16px;
        margin-right: 0;
        &:last-child {
          margin-bottom: 0; } } }
    &__img {
      margin-bottom: 30px;
      display: none;
      img {
        height: auto; } } } }


.promo-steps {
  padding-top: 80px;
  padding-bottom: 40px;
  &__wrap {
    .section-head {
      &__title {
        text-align: center;
        margin-bottom: 64px;
        color: $text!important; } }
    .post {
      text-align: center;
      &__img {
        img {
          width: 160px;
          height: auto;
          margin-bottom: 16px; } }
      &__title {
        @include font-600;
        margin-bottom: 12px;
        display: block;
        font-size: 1.25rem;
        line-height: 2rem; }
      &__content {
        padding-right: 0; } }
    .with-arrow {
      .post {
        position: relative;
        &::after {
          background: url('../images/arrow-dash-dark.svg') no-repeat center;
          position: absolute;
          display: inline-block;
          top: 25%;
          right: -25%;
          content: '';
          width: 160px;
          height: 11px;
 } }          //animation: arrow 2s linear infinite
      &:last-child {
        .post {
          &::after {
            display: none; } } } } }
  @include maxtablet {
    padding: 24px 0 14px 0;
    &__wrap {
      .post {
        display: block;
        text-align: left;
        padding: 0 24px;
        &__img {
          display: inline-block;
          vertical-align: top;
          width: 56px;
          margin-right: 16px;
          img {
            width: 100px;
            height: auto; } }
        &__content {
          display: inline-block;
          vertical-align: top;
          width: calc(100% - 77px);
          p {
            opacity: 0;
            height: 0;
            transition: height .5s linear; } }
        &__title {
          font-size: 16px;
          line-height: 23px;
          margin: 8px 0;
          position: relative;
          &::after {
            content: '';
            display: inline-block;
            position: absolute;
            right: -30px;
            top: 0;
            width: 25px;
            height: 25px;
            background: url('../images/arrow-down.svg') no-repeat center;
            background-size: contain;
            transition: .3s linear; } }
        &.show {
          .post__content {
            p {
              opacity: 1;
              height: auto;
              transition: height .5s linear; } }
          .post__title {
            &::after {
              transform: rotate(180deg);
              transition: .3s linear; } } } }
      .section-head {
        &__title {
          font-size: 28px!important;
          line-height: 36px!important;
          margin-bottom: 40px!important; } } }
    .with-arrow {
      .post {
        position: relative;
        padding: 0 24px 56px 24px;
        margin-bottom: 0;
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 50px;
          top: 86px;
          height: calc(100% - 125px);
          width: 2px;
          border-right: 2px dotted #9090A2; }
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 30px;
          height: 20px;
          bottom: 25px;
          top: unset;
          left: 36px;
          background: url('../images/arrow-down.svg') no-repeat center;
          background-size: contain; } }
      &:last-child {
        .post {
          &::before,
          &::after {
            display: none; } } } } } }

.section-tabs {
  &__header {
    .nav-tabs {
      background: #F2F4F7;
      border-radius: 100px;
      display: inline-flex;
      padding: 4px;
      overflow: hidden;
      .nav-item {
        cursor: pointer;
        border: 0;
        .nav-link {
          padding: 8px 20px!important;
          height: auto;
          position: relative;
          border: 0;
          cursor: pointer;
          background: transparent;
          display: inline-block;
          &.active {
            background: $blue;
            border-radius: 100px;
            box-shadow: none;
            .nav-item__title {
              label {
                color: $white;
                margin-bottom: 0; } }
            &:hover {
              background: $blue;
              box-shadow: none;
              .nav-item__title {
                label {
                  color: $white; } } } }
          &.alt {
            &.active {
              background: $white;
              color: #e1eefc;
              border-radius: 100px;
              box-shadow: none;
              border: 1px solid #4B61DD;
              .nav-item__title {
                label {
                  color: #4B61DD;
                  margin-bottom: 0; } }
              &:hover {
                background: $white;
                color: #e1eefc;
                border-radius: 100px;
                box-shadow: none;
                border: 1px solid #4B61DD;
                .nav-item__title {
                  label {
                    color: #4B61DD;
                    margin-bottom: 0; } } } } }
          &:hover {
            background: transparent;
            cursor: pointer;
            box-shadow: none;
            .nav-item__title {
              label {
                color: $text; } } }
          .nav-item__title {
            margin-bottom: 0;
            cursor: pointer;
            @include font-600;
            label {
              color: #9090A2;
              margin-bottom: 0;
              @include font-600; } } } } } }
  &__body {
    padding-top: 40px; }
  @include mobile {
    &__header {
      position: relative;
      text-align: center;
      .nav-tabs {
        position: relative;
        height: auto;
        top: inherit;
        left: inherit;
        background: #FFF; } } } }

.promo-form {
  @include maxtablet {
    .form-meeting__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px; }
    .form-meeting__desc {
      display: none; } } }

.download-cta,
.cta-block {
  &.mt-70 {
    margin-top: 70px;
    @include mobilelg {
      margin-top: 20px; } }
  &__box {
    background: #F9FAFB;
    box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
    border-radius: 8px; }
  &__meta {
    padding: 40px 40px 40px 0; }
  &__img {
    img {
      margin-top: -20%;
      display: block;
      max-width: 100%;
      width: auto;
      height: auto; } }
  &__desc {
    margin-bottom: 24px; }
  &.download-cta--enterprise {
    padding-top: 80px;
    padding-bottom: 64px;
    .download-cta__img {
      padding-left: 6px;
      margin-right: -12px;
      img {
        margin-top: -20%; } } }
  @include mobile {
    &__box {
      padding: 24px 24px 16px;
      border-radius: 8px; }
    &__meta {
      padding: 16px 0 0 0;
      text-align: center; }
    &__title {
      font-size: 26px;
      line-height: 32px; }
    &__img {
      img {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto; } }
    &.download-cta--enterprise {
      padding-top: 80px;
      padding-bottom: 64px;
      .download-cta__img {
        padding-left: 0;
        margin-right: 0;
        img {
          margin-top: 0;
          max-width: 200px; } } } } }

.cta-block {
  padding: 40px 0 80px 0;
  &__box {
    background: #FFF; }
  &__meta {
    padding: 48px; }
  &__title {
    font-size: 32px;
    line-height: 40px; }
  &__action {
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; } } }
  @include maxtablet {
    padding: 0 0 80px 0;
    &__title {
      font-size: 28px;
      line-height: 36px; }
    &__action {
      a {
        margin-right: 0;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } }
    &__meta {
      padding: 0; } } }

.pricing-testi {
  padding: 48px 0;
  position: relative;
  &.promo {
    padding-bottom: 80px; }
  &:before {
    content: "";
    display: block;
    width: 78vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #F2F4F7; }
  &__sub {
    display: block;
    color: #545465;
    margin: 0 0 8px; }

  .more {
    display: inline-block;
    color: $link;
    transition: all .4s ease;
    img, svg {
      display: inline-block;
      vertical-align: middle;
      width: 1.5rem;
      height: 0.5rem;
      margin-left: 0.5rem; }
    &:hover {
      color: $link;
      text-decoration: underline; } }
  .list-item {
    display: block;
    &:not(:first-child) {
      margin-top: 3rem; }
    .item {
      position: relative;
      padding-left: 64px;
      margin-bottom: 40px;
      .icon {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: 50% 50%; }
      span {
        display: block;
        color: $text; }
      .number {
        font-size: 3rem;
        line-height: 1.167em;
        @include font-500(); } } }
  .owl-dots {
    text-align: left;
    padding: 0 42px;
    bottom: 3rem; }
  .owl-dot {
    display: inline-block;
    background: transparent!important;
    span {
      background-color: #FFF;
      opacity: 0.3; } }
  .owl-dot.active span {
    opacity: 1;
    background-color: #FFF; }
  .testi {
    background: #10253E;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 0.5rem;
    .logo {
      margin-bottom: 2rem;
      img {
        display: block;
        height: 2rem;
        width: auto; } }
    .quote {
      p {
        font-size: 1.5rem;
        line-height: 1.333em;
        @include font-500();
        margin-bottom: 1.5rem; }
      span {
        display: block;
        font-size: 0.875rem;
        line-height: 1.429em;
        margin-bottom: 4px; }
      .name {
        @include font-500(); } }
    .more {
      display: block;
      position: absolute;
      bottom: 2rem;
      right: 2.5rem;
      color: #F5D4CF;
      svg path {
        fill: #F5D4CF; } }
    &__img {
      position: relative;
      width: 100%;
      padding-top: 42%;
      .btn-play {
        z-index: 2;
        display: block;
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 3.75rem;
        // background-color: $primary
        background-image: url(../images/ic-play.svg);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;
 }        // +pulse(0,95,191)
      img {
        z-index: 1;
        border-radius: 0.5rem 0.5rem 0 0;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%; } }
    &__body {
      position: relative;
      padding: 2rem 2.5rem 2rem 2.5rem;
      border-radius: 0 0 0.5rem 0.5rem;
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        background: #FFF; } } }
  .owl-item .testi__body {
    padding-bottom: 5rem; }
  &:not([class*="theme-"]) {
    .testi__img .btn-play {
      background-color: $primary;
      @include pulseCustom(0,95,191,primary); }
    .testi .more {
      color: #A68AF1;
      svg path {
        fill: #A68AF1; } } }
  &--alt {
    background: #F2F4F7;
    padding: 0;
    &:before {
      display: none; }
    .pricing-testi__wrap {
      position: relative;
      max-width: 83.333%;
      padding: 32px 0;
      margin: 0 auto;
      &:before {
        content: "";
        display: block;
        width: 90%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: #10253E; } }
    .slider-testi-alt {
      padding-left: 56px; }
    .product-testi,
    .block-testi {
      &__block-img {
        position: relative;
        width: 100%;
        padding-top: 100%;
        img {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: 50% 50%;
          border-radius: 8px; }
        .btn-play {
          z-index: 2;
          display: block;
          width: 3.75rem;
          height: 3.75rem;
          border-radius: 3.75rem;
          background-color: $blue;
          background-image: url(../images/ic-play-white.svg);
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          bottom: 1.5rem;
          right: 1.5rem;
          @include pulseCustom(0,95,191,primaryPulse); } } }
    .owl-item .testi__body {
      padding-bottom: 1.5rem; }
    .owl-dots {
      bottom: 8px;
      padding: 0;
      left: 8px; }
    .owl-dot {
      outline: none !important; }
    .owl-dot span {
      background: #e2e2e2;
      opacity: 1;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin: 0 6px; }
    .owl-dot.active span {
      background: $blue;
      border-color: $blue; }
    .testi {
      .quote {
        max-width: 90%;
        color: #FFF;
        p {
          font-size: 1.5rem;
          line-height: 1.3333em;
          margin-bottom: 40px; } }
      .more {
        position: relative;
        bottom: auto;
        right: auto;
        margin-top: 32px; }
      &__body {
        padding: 0 0 32px 0;
        &:before {
          display: none; }
 } }        // min-height: 496px

    @include maxtablet {
      padding-top: 0;
      &.promo {
        padding-top: 0;
        padding-bottom: 0; }
      &::before {
        display: none; }
      .product-testi__block-img {
        img {
          border-radius: 8px 8px 0 0; } }
      .pricing-testi__wrap {
        max-width: 100%;
        padding: 0;
        &::before {
          display: none; } }
      .slider-testi-alt {
        padding-left: 0; }
      .testi {
        margin: 0;
        height: 100%;
        border-radius: 0 0 8px 8px;
        padding: 2rem 1.5rem 2rem 1.5rem;
        .quote {
          max-width: 100%; } }
      .owl-dots {
        position: relative;
        text-align: center;
        left: unset;
        bottom: unset;
        background: #10253E;
        margin: 0;
        padding: 20px 0;
        .owl-dot {
          span {
            background: rgba(999,999,999,.7); }
          &.active {
            span {
              background: #FFF; } } } }
      .owl-stage {
        display: flex!important;
        align-items: stretch!important;
        .slider-item {
          height: 100%; } } }
    @include mobile {
      .col-lg-4,.col-lg-8 {
        padding: 0; }
      .testi {
        border-radius: 0; }
      .product-testi__block-img {
        img {
          border-radius: 0; } } } } }

.pricing-socproof {
  padding-bottom: 64px;
  padding-top: 64px;
  background: #F2F4F7;
  .item {
    margin-bottom: 20px;
    &__wrap {
      padding-left: 16px; }
    &__number {
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 2px;
        height: 32px;
        position: absolute;
        top: calc(50% - 16px);
        left: -16px;
        background-color: $primary; }
      span {
        color: $primary;
        font-size: 32px;
        line-height: 48px;
        @include font-500();
        display: block;
        margin-bottom: 0; } }
    &__desc {
      max-width: 85%; } }

  @include maxtablet {
    padding-top: 48px; } }

.promo-blue {
  background: radial-gradient(58.93% 675.17% at 52.51% 65.54%, #008CF2 0%, #004DC2 100%);
  color: #FFF;
  padding: 40px 0;
  &__title {
    color: #FFF; }
  @include mobile {
    text-align: center;
    &__title {
      font-size: 26px;
      line-height: 32px; }
    &__img {
      margin-top: 30px; } } }

.promo-form {
  @include mobile {
    .form-meeting__title {
      font-size: 26px;
      line-height: 32px; } } }

.pricing-main {
  padding-bottom: 0;
  &__nav {
    text-align: center;
    padding-bottom: 40px;
    .nav {
      display: inline-block;
      background: #EDF0F2;
      border-radius: 24px;
      padding: 1.5px;
      li {
        display: inline-block;
        width: auto;
        position: relative; } }
    .nav-item {
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 24px;
      @include font-600;
      color: #8B95A5;
      opacity: 0.6;
      border-radius: 100px;
      transition: all .4s ease;
      border: 1.5px solid transparent;
      &.active {
        background-color: #FFF;
        opacity: 1;
        color: #4B61DD;
        border: 1.5px solid #4B61DD;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); } } }
  .pricing-cards {
    padding-bottom: 64px; }
  .tnc {
    position: absolute;
    top: 100%;
    right: 12px;
    display: block;
    padding-top: 16px;
    text-align: right; }
  .card {
    &__wrap {
      display: flex;
      flex-direction: column;
      height: 100%; }
    &__head {
      padding: 20px;
      border: 1px solid $border;
      background: #F2F4F7;
      border-radius: 4px 4px 0 0;
      min-height: 274px;
      display: flex;
      flex-direction: column;
      &.alt {
        min-height: unset; }
      h3 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
        .badge {
          display: inline-block;
          font-size: 12px;
          line-height: 20px;
          padding: 4px 8px;
          vertical-align: middle;
          margin-left: 15px;
          margin-right: 0;
          color: #FFF;
          background: #009BDE;
          position: relative;
          top: -2px; } }
      p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        color: #232933; }
      .price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: auto;
        min-height: 75px;
        margin-bottom: 24px;
        .sf {
          display: block;
          min-height: 20px;
          margin-bottom: 4px;
          font-size: 14px;
          line-height: 20px; }
        .pr {
          display: block;
          color: #2B2A35;
          font-size: 20px;
          line-height: 28px;
          @include font-600();
          small {
            display: inline-block;
            color: #626B79;
            font-size: 14px;
            line-height: 20px;
            @include font-400();
            margin-left: 6px; } }
        span {
          &.badge {
            color: #4B61DD;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            border: 1px solid #4B61DD;
            background: #FFF;
            padding: 4px 12px;
            border-radius: 14px;
            margin: 0 0 0 8px;
            text-decoration: none; }
          &.label {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 0;
            display: block;
            color: #626B79;
            text-decoration: line-through; }
          &.tc {
            font-size: 14px;
            line-height: 20px;
            color: #626B79; } } } }
    &__toggle {
      position: relative;
      padding: 16px 40px 16px 20px;
      color: #009BDE;
      cursor: pointer;
      @include font-500();
      &:hover {
        color: darken(#009BDE, 10%); }
      &::after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url("../images/ic-chevron-up-cyan.svg") no-repeat center;
        background-size: contain;
        position: absolute;
        top: calc(50% - 12px);
        right: 16px;
        transform: rotate(0);
        transition: all .3s ease; }
      &.collapsed {
        &::after {
          transform: rotate(180deg); } } }
    &__collapse-wrap {
      padding: 8px 20px 40px; }
    &__main {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
      border: 1px solid $border;
      border-top: 0;
      background: #FFF;
      border-radius: 0 0 4px 4px;
      span.cat {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #2B2A35;
        margin-bottom: 4px;
        @include font-500(); }
      .card__collapse-wrap {
        span.cat {
          font-size: 16px;
          line-height: 28px;
          text-decoration: underline; } }
      ul.custom-list {
        &:not(:last-child) {
          margin-bottom: 24px; }
        &> li {
          padding-left: 32px;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0; } } }
      .card__bonus {
        padding: 16px 12px;
        span.cat {
          margin-bottom: 16px;
          font-size: 16px;
          line-height: 24px; }
        .box {
          padding: 12px;
          background-color: #FFF5DC;
          border-radius: 4px; } } }
    &--highlight {
      .card__wrap {
        box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); }
      .card__head {
        border-color: #009BDE;
        background: #EDFAFF; }
      .card__main {
        border-color: #009BDE; } } }
  .cta-all-feature {
    padding-top: 48px; }
  @include maxtablet {
    padding-bottom: 0;
    .pricing-cards {
      padding-bottom: 32px; }
    &__nav {
      padding-bottom: 32px;
      position: relative;
      .nav {
        position: relative;
        width: auto;
        top: 0;
        .nav-item {
          position: relative;
          width: auto;
          left: 0;
          &.active {
            &::after {
              display: none; } } } } }
    .card {
      margin-bottom: 24px; } }

  @include mobile {
    .pricing-cards {
      flex-direction: column-reverse; } } }

.recom-addon {
  padding-top: 64px;
  padding-bottom: 64px;
  h2 {
    margin-bottom: 32px;
    text-align: center; }
  .item {
    margin-bottom: 24px;
    &__title {
      margin-bottom: 16px;
      transition: all .3s ease;
      .icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-right: 12px; } }
    &__content {
      margin-bottom: 16px; }
    &__wrap {
      border: 1px solid $border;
      border-radius: 4px;
      padding: 20px;
      position: relative;
      &[data-toggle] {
        cursor: pointer;
        box-shadow: 0px 0px 0px rgba(119, 119, 119, 0.1), 0px 0px 0px rgba(119, 119, 119, 0.2);
        transition: all .3s ease;
        .item__title {
          position: relative;
          &:after {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            right: 8px;
            opacity: 0;
            top: 4px;
            background: url(../images/ic-arrow-down-blue.svg);
            transform: rotate(-90deg);
            transition: all .3s ease; } }
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 4px;
          border: 2px solid transparent;
          transition: all .3s ease; }
        &:hover {
          box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
          border: 1px solid $primary;
          .item__title {
            color: $primary; }
          .item__title:after {
            right: 0;
            opacity: 1; } } } }
    &:first-child:last-child {
      margin-left: auto;
      margin-right: auto; } }

  @include maxtablet {
    padding-top: 48px;
    padding-bottom: 48px;
    h2 {
      margin-bottom: 24px; } }

  @include mobile {
    padding-left: 12px;
    padding-right: 12px; } }

.modal-add-ons {
  .item {
    margin-bottom: 24px;
    &__title {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 20px;
      @include font-500(); }
    &__desc {
      margin-bottom: 24px; }
    &__price {
      display: block;
      &:not(:last-child) {
        margin-bottom: 24px; }
      .sf {
        display: block;
        min-height: 20px;
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 20px; }
      .pr {
        display: block;
        color: #2B2A35;
        font-size: 24px;
        line-height: 32px;
        @include font-500();
        small {
          display: inline-block;
          color: #545465;
          font-size: 14px;
          line-height: 20px;
          @include font-400();
          margin-left: 6px; } } }
    &__list {
      margin-bottom: 28px;
      span.cat {
        display: block;
        color: $text-dark;
        @include font-500();
        margin-bottom: 8px; } }
    &__wrap {
      padding: 24px 20px;
      height: 100%;
      border: 1px solid $border;
      border-radius: 4px; } } }

.recom-bundle {
  padding-top: 64px;
  padding-bottom: 64px;
  h2 {
    text-align: center;
    margin-bottom: 48px; }
  &__nav {
    .item {
      position: relative;
      padding: 20px 60px 20px 32px;
      background: #FFF;
      border: 1px solid $border;
      border-radius: 4px;
      margin-bottom: 12px;
      transition: all .3s ease;
      &[data-toggle] {
        cursor: pointer; }
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url("../images/ic-chevron-right-round.svg") no-repeat center;
        background-size: contain;
        position: absolute;
        top: calc(50% - 12px);
        right: 30px;
        opacity: 0;
        transition: all .3s ease; }
      &__logo {
        position: relative;
        margin-bottom: 12px;
        img {
          display: block;
          height: 26px;
          width: auto; } }
      &.active {
        border-color: $primary;
        background: #F9E2DE80;
        &:after {
          opacity: 1;
          right: 24px; } }
      &:not(.active):hover {
        background: #F2F4F7; } } }
  .tab-pane {
    padding: 32px;
    border: 1px solid $border;
    border-radius: 4px;
    &__sm {
      display: none; }
    h3 {
      margin-bottom: 16px; }
    span.cat {
      color: #2B2A35;
      font-size: 16px;
      line-height: 24px;
      @include font-500();
      display: block;
      margin-bottom: 8px; }
    ul.custom-list {
      &> li {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0; } } }
    .price {
      &:not(:last-child) {
        margin-bottom: 24px; }
      .ps {
        font-size: 14px;
        line-height: 20px;
        color: #777; }
      .pn {
        font-size: 28px;
        line-height: 48px;
        @include font-500();
        color: #2B2A35; }
      .pt {
        font-size: 16px;
        line-height: 24px;
        @include font-500();
        color: #2B2A35; } }
    .tab-action {
      padding-top: 32px; } }
  @include maxtablet() {
    padding-top: 48px;
    padding-bottom: 48px;
    h2 {
      margin-bottom: 24px; }
    &__nav {
      display: none; }
    .tab-pane {
      margin-bottom: 24px;
      display: block;
      &__sm {
        display: block;
        .item__logo {
          margin-bottom: 12px; }
        .item__desc {
          margin-bottom: 24px; } }
      .hide-sm {
        display: none; }
      &:last-child {
        margin-bottom: 0; } } }

  .tnc {
    display: block;
    padding-top: 16px;
    font-size: 14px;
    line-height: 20px;
    color: #545465; }

  .bundle {
    display: flex;
    &__logo {
      img {
        display: block;
        height: 48px;
        width: auto; } }
    &__price {
      margin-top: auto;
      margin-bottom: 12px;
      .sf {
        display: block;
        margin-block-end: 8px; }
      .pr {
        display: block;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.5px;
        @include font-500();
        small {
          display: inline-block;
          font-size: 18px;
          line-height: 28px;
          margin-left: 4px;
          color: #545465; }
        &--alt {
          font-size: 30px;
          line-height: 48px; } } }
    &__action {
      .btn {
        width: 100%; } }
    span.cat {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #2B2A35;
      margin-bottom: 12px;
      @include font-500(); }
    &__head {
      padding: 32px;
      background: #E5EFF8;
      border: 1px solid #005FBF;
      border-radius: 4px 0 0 4px;
      width: 100%;
      max-width: 38%;
      flex: 0 0 38%;
      display: flex;
      flex-direction: column;
      &.theme--klikpajak {
        background: #FBF3DD;
        border-color: #F96D01; } }
    &__main {
      padding: 32px;
      background: #FFF;
      border: 1px solid $border;
      border-left: 0;
      border-radius: 0 4px 4px 0;
      width: 100%;
      max-width: 62%;
      flex:  0 0 62%; }

    @include maxtablet() {
      flex-wrap: wrap;
      &__logo {
        img {
          height: 32px;
          width: auto; } }
      &__price {
        padding-top: 32px;
        .sf {
          margin-bottom: 4px; }
        .pr {
          font-size: 28px;
          line-height: 36px;
          small {
            font-size: 16px;
            line-height: 24px; } } }
      &__head {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 24px 24px 16px;
        border-radius: 4px 4px 0 0; }
      &__main {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 24px;
        border-radius: 0 0 4px 4px;
        border-left: 1px solid $border;
        border-top: 0; } } }

  @include mobile {
    padding-left: 12px;
    padding-right: 12px; } }


.our-customer.pr-new {
  padding: 64px 0;
  .our-customer__title {
    max-width: 100%; }
  @include maxtablet {
    padding: 48px 8px; } }

.block-cta {
  text-align: center;
  position: relative;
  .container {
    padding-top: 64px;
    padding-bottom: 64px; }
  &__icon {
    margin-bottom: 8px;
    img {
      display: block;
      height: 72px;
      width: auto;
      margin: 0 auto; } }
  &__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
    &.small {
      font-size: 24px;
      line-height: 32px; } }
  &__desc {
    max-width: 600px;
    margin: 0 auto 32px; }
  &__buttons {
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; } } }
  hr.separator {
    max-width: 1224px;
    margin: 0 auto; }
  &--trial {
    background-color: #002A38;
    background-image: url('../images/pattern-cta.svg');
    color: #FFF;
    .block-cta__title {
      color: #FFF; }
    .block-cta__desc {
      color: #FFF; } }
  &.bg-snow {
    background: #F2F4F7; }
  &--duo {
    .col-lg-6 {
      position: relative;
      padding-left: 7%;
      padding-right: 7%;
      &::after {
        content: '';
        display: inline-block;
        height: 80%;
        width: 1px;
        position: absolute;
        background: #e2e2e2;
        top: 10%;
        right: 0; }
      &:last-child {
        &::after {
          display: none; } } } }
  @include maxtablet() {
    &__title,
    &__title.small {
      font-size: 24px;
      line-height: 32px; }
    .container {
      padding-top: 48px;
      padding-bottom: 48px;
      padding-left: 8px;
      padding-right: 8px; }
    &--duo {
      .col-lg-6 {
        padding-bottom: 40px;
        &::after {
          width: 80%;
          height: 1px;
          bottom: 0;
          left: 10%;
          top: unset;
          right: unset; }
        &:last-child {
          padding-top: 40px;
          padding-bottom: 0; } } }
    &__actions {
      a {
        margin-right: 0;
        margin-bottom: 16px;
        width: 100%;
        &:last-child {
          margin-bottom: 0; } } } } }

.block-banner {
  padding-top: 24px;
  padding-bottom: 24px;
  &__title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px; }
  &__box {
    position: relative;
    padding: 60px 48px;
    background-color: #F8FAFC;
    box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
    border-radius: 8px; }
  &__content {
    max-width: 60%; }
  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 40%;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom center; } }

  @include maxtablet {
    padding-left: 12px;
    padding-right: 12px;
    &__box {
      padding: 24px; }
    &__content {
      max-width: 100%; }
    &__buttons {
      .link-more {
        padding-top: 0;
        padding-bottom: 0; } }
    &__img {
      display: none; } } }

.faq-block.pr-new {
  background-color: #FFF;
  padding-left: 12px;
  padding-right: 12px; }

.sb {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  background: #EEF0FF;
  &__wrap {
    max-width: 800px;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  &__close {
    z-index: 1;
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    color: #525151;
    font-family: Arial,sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: hsla(0,0%,100%,.6);
    outline: none;
    padding: 0;
    text-align: center;
    transform: none;
    transition: transform .2s ease-in-out,background-color .2s ease-in-out;
    cursor: pointer;
    &:hover {
      background: #FFF;
      transform: scale(1.1); } }
  &__content {
    text-align: left;
    color: #FFF;
    h4 {
      font-size: 20px;
      line-height: 32px;
      margin: 0 0 4px;
      @include mobile {
        font-size: 14px;
        line-height: 20px; } }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #2B2A35;
      @include mobile {
        font-size: 11px;
        line-height: 20px; }
      a {
        color: #1357FF;
        font-weight: normal;
        text-decoration: underline; } }
    .badge {
      font-size: 12px;
      line-height: 16px;
      background: #706FD3;
      border-radius: 4px;
      color: #FFF;
      text-transform: uppercase;
      padding: 5px 8px;
      margin-right: 8px;
      @include font-600; } }
  &__cta {
    margin-left: 48px;
    .btn {
      font-size: 14px;
      line-height: 34px;
      border-radius: 3px;
      box-shadow: none;
      background: #FFFFFF;
      border-color: #FFFFFF;
      color: #143A72;
      &:hover {
        background: #FFFFFF;
        border-color: #FFFFFF;
        color: #143A72;
        opacity: .85; } } }

  &.custom-cs {
    background: radial-gradient(58.93% 675.17% at 52.51% 65.54%, #008CF2 0%, #004DC2 100%);
    .sb__wrap {
      max-width: 1000px;
      padding-top: 12px;
      padding-bottom: 12px; }
    .sb__close {
      color: $primary; }
    .sb__content {
      p {
        color: #FFF;
        font-size: 20px;
        line-height: 30px; }
      span {
        display: inline-block;
        vertical-align: middle; }
      span.large {
        font-size: 32px;
        line-height: 40px;
        font-weight: 900;
        margin-left: 24px; } }
    .sb__cta {
      .btn {
        color: $primary;
        &:hover {
          opacity: 1;
          background: transparent;
          color: #FFF; } } } }

  @include mobile {
    position: fixed;
    top: auto;
    bottom: 0;
    z-index: 2;
    &__wrap {
      flex-wrap: wrap; }
    &__close {
      right: 10px;
      top: 10px;
      width: 18px;
      height: 18px;
      line-height: 18px;
      background: #FFF; }
    &__content,
    &__cta {
      width: 100%;
      text-align: center; }
    &__cta {
      text-align: center;
      margin: 12px 0 0; } } }

// INDUSTRY REVAMP
.masthead--industry {
  .masthead__content {
    padding-top: 32px;
    padding-bottom: 32px; }
  .masthead__desc {
    margin-bottom: 24px; }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    .masthead__content {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0; }
    .masthead__title {
      font-size: 28px;
      line-height: 36px; }
    .masthead__desc {
      margin-bottom: 32px; }
    .masthead__media {
      padding-top: 32px;
      display: none; } } }

.industry-clients {
  &__title {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    max-width: 648px;
    margin: 0 auto 32px; }
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 12px;
      height: 64px;
      width: 100%;
      max-width: 12.5%;
      flex: 0 0 12.5%;
      img {
        display: block;
        max-height: 56px;
        max-width: 100%;
        height: auto;
        width: auto; } } }
  &__action {
    padding-top: 40px;
    text-align: center; }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    &__title {
      font-size: 20px;
      line-height: 28px; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; }
    &__list {
      .item {
        max-width: 25%;
        flex: 0 0 25%; } } } }

.page-tabs--industry {
  padding-top: 80px;
  @include maxtablet {
    padding-top: 24px;
    padding-left: 8px;
    padding-right: 8px;
    .page-tabs__header {
      padding: 0 12px 48px;
      margin-bottom: 24px; }
    .mobile-section {
      padding-left: 12px;
      padding-right: 12px; }
    .page-tabs__dropdown {
      margin: 0; } } }

.fitur-blocks--industry {
  padding-top: 64px;
  .section-head {
    padding-bottom: 0;
    &__content {
      margin-bottom: 40px; }
    &__action {
      .btn {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 16px; } } }

  .fitur-block {
    padding-top: 64px;
    padding-bottom: 64px;
    ul.custom-list > li {
      margin-bottom: 12px; }
    &__action {
      padding-top: 24px; } }
  @include maxtablet() {
    .section-head {
      padding-left: 12px;
      padding-right: 12px;
      .btn {
        margin-left: 0;
        margin-right: 0; }
      &__title {
        font-size: 24px;
        line-height: 32px; } }
    .fitur-block {
      padding: 48px 12px;
      &__title {
        font-size: 24px;
        line-height: 32px; } } } }

.testimonial-industry {
  background: #F2F4F7;
  &__wrap {
    padding-bottom: 48px; }
  .item {
    &__quote {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      @include font-500; }
    &__author {
      .author-logo {
        display: inline-block;
        vertical-align: middle;
        padding-right: 24px;
        img {
          height: 32px;
          width: auto;
          display: block; } }
      .auhtor-content {
        display: inline-block;
        vertical-align: middle;
        padding: 12px 24px;
        border-left: 1px solid $border;
        span {
          display: block; }
        .name {
          @include font-500(); } } }
    &__img {
      position: absolute;
      top: 0;
      left: 12px;
      width: 20%;
      padding: 36px 0;
      .img {
        display: block;
        width: 100%;
        padding-top: 100%;
        position: relative;
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center; } } }
    &__box {
      margin-left: 10%;
      background: #FFF;
      padding: 48px 40px 48px 14%;
      box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
      border-radius: 6px; } }
  @include maxtablet() {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 64px;
    &__wrap {
      padding-bottom: 0; }
    .item {
      &__box {
        margin-left: 0;
        margin-top: 50%;
        padding: 24px; }
      &__img {
        position: relative;
        left: 0;
        width: 100%;
        margin-top: -50%;
        margin-bottom: 32px;
        text-align: center;
        padding: 0;
        .img {
          width: 208px;
          height: 208px;
          padding-top: 0;
          display: inline-block; } }
      &__quote {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 32px; }
      &__author {
        .author-logo {
          display: block;
          margin-bottom: 16px; }
        .auhtor-content {
          display: block;
          padding: 0;
          border-left: 0; } } } } }


.advantage--industry {
  .section-head {
    &__content {
      margin-bottom: 40px; } }
  @include maxtablet() {
    padding: 64px 12px 32px;
    .section-head {
      padding-bottom: 32px; }
    .post {
      margin-bottom: 32px; } } }

.block-addons {
  background: #011C40;
  margin-bottom: 80px;
  &__title {
    color: #FFF;
    text-align: center; }
  &__buttons {
    text-align: center;
    margin-bottom: 56px; }
  .package {
    background: #FFF;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    height: 100%;
    position: relative;
    &__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 32px;
      @include font-500(); }
    &__price {
      margin-bottom: 24px;
      b {
        display: inline-block;
        font-size: 24px;
        line-height: 32px;
        @include font-500(); }
      small {
        font-size: 16px;
        line-height: 24px;
        display: inline-block;
        margin-left: 4px; } }
    &__desc {
      margin-bottom: 60px;
      max-height: 250px; }
    &__action {
      position: absolute;
      padding: 32px 24px;
      left: 0;
      bottom: 0;
      width: 100%; } }
  .items-addons {
    position: relative;
    padding-left: 48px;
    margin-left: -8px;
    margin-right: -8px;
    &::before {
      content: "+";
      display: block;
      width: 30px;
      height: 48px;
      position: absolute;
      top: calc(50% - 24px);
      left: 9px;
      font-size: 40px;
      line-height: 48px;
      color: #FFF;
      font-weight: 500; }
    .col-md-4 {
      padding-left: 8px;
      padding-right: 8px; }
    .item {
      background: #FFF;
      padding: 32px 24px;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      height: 100%;
      position: relative;
      &__icon {
        margin-bottom: 8px;
        img {
          display: block;
          height: 40px;
          width: auto; } }
      &__title {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 24px;
        @include font-500(); }
      &__price {
        margin-bottom: 24px;
        span {
          display: block; }
        b {
          display: inline-block;
          font-size: 18px;
          line-height: 24px;
          @include font-500(); }
        small {
          font-size: 16px;
          line-height: 24px;
          display: inline-block;
          margin-left: 4px; } }
      &__desc {
        margin-bottom: 60px;
        max-height: 250px; }
      &__action {
        position: absolute;
        padding: 32px 24px;
        left: 0;
        bottom: 0;
        width: 100%; } } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }

  @include maxtablet() {
    &__wrap {
      padding: 64px 24px; }
    .items-addons {
      padding-left: 0;
      padding-top: 64px;
      &::before {
        top: 6px;
        left: calc(50% - 16px); }
      .col-md-4 {
        margin-bottom: 16px; }
      .item {
        display: block;
        position: relative;
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 18px;
          height: 18px;
          background: url('../images/ic-chevron-down.svg') no-repeat center;
          background-size: contain;
          top: 35px;
          right: 15px;
          trasition: all linear .3s; }
        &__icon {
          display: inline-block;
          vertical-align: middle;
          width: 32px;
          height: 32px;
          margin-right: 16px;
          img {
            width: 32px;
            height: 32px; } }
        &__title {
          display: inline-block;
          vertical-align: middle;
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 8px; }
        &__price,
        &__desc {
          padding-left: 52px; }
        &__price {
          margin-bottom: 0; }
        &__desc {
          display: none;
          margin-top: 16px; }
        &.show {
          &::after {
            transform: rotateZ(180deg);
            trasition: all linear .3s; }
          .item__desc {
            display: block; } } } } } }



.block-cs {
  padding-bottom: 80px;
  .item {
    border-radius: 8px;
    overflow: hidden;
    &__wrap {
      height: 100%; }
    &__left {
      background: #011C40;
      padding: 40px;
      color: #FFF;
      .author {
        margin-bottom: 24px;
        &__img {
          display: inline-block;
          vertical-align: middle;
          img {
            display: block;
            width: 60px;
            height: 60px;
            object-fit: cover;
            object-position: center;
            border-radius: 60px;
            background-color: #6CAEBE; } }
        &__content {
          display: inline-block;
          vertical-align: middle;
          padding-left: 20px;
          span {
            display: block;
            font-size: 14px;
            line-height: 20px; } } }
      .quote {
        font-size: 24px;
        line-height: 32px;
        @include font-500(); }
      &.alt {
        background: #005FBF; } }
    &__right {
      background-color: #F2F4F7;
      padding: 40px 32px;
      .number {
        color: #048444;
        font-size: 64px;
        line-height: 72px;
        @include font-500();
        margin-bottom: 12px; }
      .desc {
        font-size: 24px;
        line-height: 32px;
        @include font-500();
        margin-bottom: 32px; } } }
  .slider-cs-dot {
    margin: 0;
    padding: 32px 0 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .owl-dot {
      width: auto;
      height: 40px;
      margin: 0 24px;
      background-color: transparent;
      cursor: pointer;
      img {
        display: block;
        height: 40px;
        width: auto;
        // filter: brightness(0%)
        opacity: 0.4;
        margin-bottom: 20px;
        transition: all .4s ease; }
      .bar {
        display: block;
        position: relative;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background-color: #EDF6FB;
        overflow: hidden;
        opacity: 0;
        transition: all .4s ease;
        span {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: $blue; } }
      &.active {
        background-color: transparent;
        img {
          filter: initial;
          opacity: 1; }
        .bar {
          opacity: 1;
          span {
            animation: progressBar 8s linear; } } } } }
  &__buttons {
    padding-top: 48px; }

  .section-head {
    padding: 80px 0 64px; }
  .slider-cs.no-slider {
    display: block;
    &+ .slider-cs-dot {
      .owl-dot img {
        opacity: 1; } } }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 64px;
    .item {
      &__left {
        padding: 24px 32px;
        .quote {
          font-size: 18px;
          line-height: 28px; } }
      &__right {
        padding: 24px 32px;
        .number {
          font-size: 48px;
          line-height: 1.25em; }
        .desc {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 24px; } } }
    .slider-cs-dot {
      display: none; }
    .section-head {
      padding: 60px 0;
      &__title {
        font-size: 24px;
        line-height: 32px; } } } }

.home-why {
  background: #F2F4F7;
  &--center {
    .home-why__desc {
      margin-bottom: 48px; } }

  &__sub {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    color: #626B79; }
  &__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 28px; }
  &__desc {
    margin-bottom: 48px; }
  &__img {
    img {
      display: block;
      width: 100%;
      height: auto; } }
  .item {
    height: 100%;
    &__img {
      position: relative;
      margin-bottom: 12px;
      .img {
        display: inline-block;
        padding: 18px;
        background: #EDF6FB;
        border-radius: 16px; }
      img {
        display: block;
        height: 44px;
        width: auto; } }
    &__title {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 12px; }
    &__wrap {
      padding: 32px;
      background: #fff;
      border-radius: 8px;
      display: block; }
    &__desc {
      color: #2B2A35; } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }

  @include maxtablet {
    &__img {
      margin-bottom: 16px;
      img {
        max-width: 80%; } }
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px; }
    &__desc {
      margin-bottom: 32px; }
    .item {
      &__img {
        .img {
          padding: 12px;
          img {
            height: 32px;
            width: auto; } } }
      &__wrap {
        padding: 24px;
        margin-bottom: 18px; } }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px;
      padding-left: 20px;
      padding-right: 20px; } } }

.features-accr {
  .accordion--section {
    .card {
      padding-top: 80px;
      &:nth-child(even) {
        background: #F2F4F7;
        .section-tabs__header {
          .nav-tabs {
            background: #FFF; } } }
      .card__header {
        border: 0;
        text-align: center;
        .card__title {
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 28px; }
        .card__desc {
          margin-bottom: 32px; }
        &::after {
          display: none; } }
      .collapse {
        display: block!important; } } }
  @include maxtablet {
    .accordion--section {
      padding-left: 8px;
      padding-right: 8px;
      .card {
        padding-top: 40px;
        padding-bottom: 14px;
        &:nth-child(even) {
          background: #F2F4F7;
          .section-tabs__header {
            .nav-tabs {
              background: #FFF!important; } } }
        .card__header {
          position: relative;
          .card__title {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 24px; }
          .card__toggle {
            margin-bottom: 40px;
            a {
              &.link-show {
                display: show; }
              &.link-close {
                display: none; } } }
          .card__desc {
            display: none; } }
        .collapse {
          display: none!important;
          .section-tabs {
            ul.nav-tabs {
              width: auto;
              background: #F2F4F7;
              .nav-link {
                opacity: 1;
                visibility: visible;
                max-height: auto!important; } }
            .tab-content {
              padding-left: 12px;
              padding-right: 12px; } }
          .promo-benefit__wrap {
            margin-bottom: 30px; }
          .promo-benefit__title {
            font-size: 24px;
            line-height: 32px;
            text-align: left;
            br {
              display: none; } }
          .testimonial-small__wrap {
            padding: 15px 0 60px; }
          .block-cta-alt {
            .container {
              padding-top: 0;
              padding-bottom: 30px;
              .block-cta-alt__title {
                &.small {
                  font-size: 24px;
                  line-height: 32px; } } } }
          .promo-benefit__wrap {
            .row {
              flex-direction: row-reverse; } }
          .testimonial-small {
            .item__img {
              position: absolute;
              width: 208px;
              height: 208px;
              left: calc(50% - 104px);
              top: 0; }
            .item__box {
              padding: 140px 24px 24px 24px;
              margin-top: 104px; } } }
        &.open {
          .card__header {
            .card__desc {
              display: block; }
            .card__toggle {
              a {
                &.link-show {
                  display: none; }
                &.link-close {
                  display: block; } } } }
          .collapse {
            display: block!important; } } } } } }


.testimonial-small {
  &__wrap {
    padding: 80px 0; }
  .item {
    &__quote {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      @include font-500; }
    &__author {
      .author-logo {
        display: inline-block;
        vertical-align: middle;
        padding-right: 24px;
        img {
          height: 32px;
          width: auto;
          display: block; } }
      .auhtor-content {
        display: inline-block;
        vertical-align: middle;
        padding: 12px 24px;
        border-left: 1px solid $border;
        span {
          display: block; }
        .name {
          @include font-500(); } } }
    &__img {
      position: absolute;
      top: 0;
      left: 12px;
      width: 20%;
      padding: 36px 0;
      .img {
        display: block;
        width: 100%;
        padding-top: 100%;
        position: relative;
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center; } } }
    &__box {
      margin-left: 10%;
      background: #10253E;
      color: #FFF;
      padding: 48px 40px 48px 14%;
      box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
      border-radius: 6px; } }
  @include mobilelg() {
    padding-left: 12px;
    padding-right: 12px;
    .item {
      &__box {
        margin-left: 0;
        padding: 24px; }
      &__img {
        position: relative;
        left: 0;
        padding: 0;
        width: 100%;
        margin-bottom: 32px; }
      &__quote {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 32px; }
      &__author {
        .author-logo {
          display: block;
          margin-bottom: 16px; }
        .auhtor-content {
          display: block;
          padding: 0;
          border-left: 0; } } } } }

.block-cta-alt {
  text-align: center;
  position: relative;
  .container {
    padding-top: 60px;
    padding-bottom: 60px; }

  &__icon {
    margin-bottom: 8px;
    img {
      display: block;
      height: 72px;
      width: auto;
      margin: 0 auto; } }
  &__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
    &.small {
      font-size: 24px;
      line-height: 32px; } }
  &__desc {
    max-width: 600px;
    margin: 0 auto 32px; }
  hr.separator {
    max-width: 1224px;
    margin: 0 auto; }
  &--trial {
    background-color: #002A38;
    background-image: url('../images/pattern-cta.svg');
    color: #FFF;
    .block-cta-alt__title {
      color: #FFF; }
    .block-cta-alt__desc {
      color: #FFF; } }
  &--simple {
    .container {
      padding-top: 0; }
    .block-cta-alt__buttons {
      padding-top: 20px;
      a {
        margin-right: 16px;
        margin-bottom: 0;
        &:last-child {
          margin-right: 0; } } }
    .block-cta-alt__title {
      position: relative;
      padding-top: 32px;
      &::before {
        content: '';
        display: inline-block;
        width: 48px;
        height: 6px;
        background: #4B61DD;
        top: 0;
        left: calc(50% - 24px);
        position: absolute; } } }

  &.bg-snow {
    background: #F2F4F7; }
  @include maxtablet() {
    .container {
      padding-top: 64px;
      padding-bottom: 64px;
      padding-left: 24px;
      padding-right: 24px; }
    &--simple {
      .block-cta-alt__buttons {
        a {
          margin-right: 0;
          margin-bottom: 16px;
          &:last-child {
            margin-right: 0;
            margin-bottom: 16px; } } } } } }

.tf-block {
  overflow: hidden;
  width: 100%;

  &__container {
    position: relative; }
  &__wrap {
    display: flex!important;
    position: relative;
    left: 0;
    transition: all .3s ease;
    &--alt {
      position: relative;
      padding: 4px;
      background: #FFFFFF;
      box-shadow: 0px 23px 17px rgba(50, 50, 71, 0.1);
      border-radius: 4px;
      gap: 6px;
      .owl-nav {
        position: absolute!important;
        .owl-prev,
        .owl-next {
          span {
            width: 44px!important;
            height: 44px!important; } } }
      .owl-dots {
        padding-top: 60px!important;
        bottom: unset!important; }
      &.duo {
        .item {
          flex: 0 0 calc((100%/2) - 4px);
          width: calc((100%/2) - 4px); } }
      .item {
        flex: 0 0 calc((100%/3) - 4px);
        width: calc((100%/3) - 4px); }
      @include maxtablet() {
        gap: 0;
        .item {
          flex: 0 0 100%;
          width: 100%; } }
      &.old {
        &.duo {
          .item {
            width: 100%;
            flex: 0 0 100%; } } } } }

  &__scrollwrap {
    margin-top: 62px;
    background: $border;
    border-radius: 2px; }
  &__scrollbar {
    height: 4px;
    background: #005FBF;
    border-radius: 2px;
    cursor: pointer; }
  .item {
    display: block;
    &:last-child {
      margin-right: 0 !important; }
    &__img {
      display: block;
      width: 40px !important;
      height: 40px;
      object-fit: contain;
      object-position: 50% 50%;
      margin-bottom: 8px; }
    &__title {
      color: #FFF;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      min-height: 56px; }
    &__head {
      display: block;
      padding: 16px 20px;
      border-radius: 4px 4px 0 0;
      color: #FFF;
      min-height: 160px; }
    &__desc {
      display: block;
      min-height: 48px; }
    &__body {
      display: block;
      padding: 16px 20px;
      border-radius: 0 0 4px 4px;
      color: #2B2A35;
      background: #FFFFFF;
      min-height: 320px; }
    &--alt {
      .item__body {
        background: #F2F4F7; } } }
  &--alt {
    padding-bottom: 80px;
    .item__title {
      min-height: auto; }
    .owl-carousel {
      .owl-stage-outer {
        overflow: hidden!important; }
      .owl-nav {
        top: unset!important;
        .owl-prev {
          span.prev {
            img {
              transform: scaleX(-1) rotateZ(180deg); } } } } } }

  .owl-carousel .owl-stage-outer {
    overflow: visible; }
  .owl-nav {
    position: relative;
    padding-top: 40px;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-end;
    .owl-prev,
    .owl-next {
      display: block;
      position: relative;
      width: 48px;
      height: 48px;
      top: 0;
      &:focus {
        outline: none; }
      &.disabled {
        span {
          background: transparent;
          border-color: #b4b4b4;
          img {
            filter: grayscale(100%);
            opacity: 0.5; } } }
      &:first-child {
        margin-right: 10px; }
      &:last-child {
        margin-left: 10px; }
      span {
        display: block;
        font-size: 20px;
        line-height: 48px;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        // border: 1px solid #b4b4b4
        border: 1px solid $blue;
        background: #FFF;
        padding: 12px;
        img {
          display: block;
          position: relative; } }
      span.prev {
        img {
          transform: scaleX(-1); } } } }
  .owl-dots {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 22px;
    display: flex;
    width: calc(100% - 270px); }
  .owl-dot {
    margin: 0 4px;
    display: inline-block;
    vertical-align: middle;
    &:hover, &:focus {
      outline: none; } }
  .owl-dot {
    width: 32px!important;
    span {
      display: block;
      width: 32px!important;
      height: 4px;
      background: $border;
      border-radius: 2px; }
    &:hover, &:focus {
      outline: none; } }
  .owl-dot.active span {
    background: #005FBF; }


  @include mobile() {
    .tf-block__wrap {
      display: block;
      max-width: 90%;
      &--alt {
        max-width: 100%;
        .owl-dots {
          position: absolute!important;
          padding-top: 30px!important; } } }
    .tf-block__scrollwrap {
      display: none; }
    .owl-dots {
      position: relative;
      bottom: auto;
      margin-top: 32px;
      width: 100%;
      text-align: center; }
    .owl-dot {
      margin: 0 4px;
      display: inline-block;
      vertical-align: middle;
      &:hover, &:focus {
        outline: none; } }
    .owl-dot span {
      display: block;
      width: 32px;
      height: 4px;
      background: $border;
      border-radius: 2px;
      &:hover, &:focus {
        outline: none; } }
    .owl-dot.active span {
      background: #005FBF; } } }

.support-block {
  padding: 5rem 0;
  .section-head {
    text-align: center;
    margin-bottom: 3rem; }
  @include maxtablet {
    padding-left: 8px;
    padding-right: 8px;
    .section-head {
      .section-head__title {
        font-size: 28px;
        line-height: 36px; } } } }

// MULTI ACCOUNT
.masthead--multiacc {
  padding: 0;
  .masthead__logo {
    margin-bottom: 48px; }
  .masthead__content {
    padding: 0; }
  .masthead__desc {
    max-width: 600px;
    margin: 0 auto; }
  .masthead__wrap {
    padding-top: 40px;
    padding-bottom: 64px; }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px; } }

.multiacc-cards {
  padding: 64px 0;
  .item {
    &__title {
      display: block;
      color: $text-dark;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin: 0 0 8px; }
    &__desc {
      font-size: 14px;
      line-height: 20px;
      p {
        font-size: 14px;
        line-height: 20px; } }
    &__price {
      .price {
        color: #9090A2;
        span {
          text-decoration: line-through; }
        .badge {
          color: $blue;
          font-size: 12px;
          line-height: 28px;
          font-weight: 500;
          border: 1px solid $blue;
          background: #FFF;
          padding: 0 16px;
          border-radius: 14px;
          margin: 0 0 8px;
          text-decoration: none; }
        .label {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
          color: #545465; } }
      .price-after {
        font-weight: 500;
        padding-top: 8px;
        span {
          display: inline-block;
          font-size: 20px;
          line-height: 32px; }
        small {
          display: inline-block;
          margin-left: 4px;
          font-size: 14px;
          line-height: 20px;
          color: #545465; } } }
    &__buttons {
      padding-top: 24px; }
    &__head {
      padding: 24px 32px;
      border-radius: 4px 4px 0 0;
      background: #F2F4F7;
      border-bottom: 1px solid $border; }
    &__body {
      padding: 24px 32px 32px; }
    &__wrap {
      display: flex;
      flex-direction: column;
      border: 1px solid $border;
      border-radius: 4px; } }

  .item--blue {
    .item__head {
      background: #EEF9FB;
      border-bottom: 1px solid #009BDE; }
    .item__wrap {
      border: 1px solid #009BDE;
      box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); } }

  &__wrap {
    padding-bottom: 64px; }
  @include maxtablet() {
    padding: 32px 12px;
    .item {
      margin-bottom: 32px;
      order: 1; }
    .item--blue {
      order: 0; }
    &__head {
      margin-bottom: 26px; } } }

.testimonial-multiacc {
  &__wrap {
    padding-bottom: 64px; }
  .item {
    &__quote {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      @include font-500; }
    &__author {
      .author-logo {
        display: inline-block;
        vertical-align: middle;
        padding-right: 24px;
        img {
          height: 32px;
          width: auto;
          display: block; } }
      .auhtor-content {
        display: inline-block;
        vertical-align: middle;
        padding: 12px 24px;
        border-left: 1px solid $border;
        span {
          display: block; }
        .name {
          @include font-500(); } } }
    &__img {
      position: absolute;
      top: 0;
      left: 12px;
      width: 20%;
      padding: 36px 0;
      .img {
        display: block;
        width: 100%;
        padding-top: 100%;
        position: relative;
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center; } } }
    &__box {
      margin-left: 10%;
      color: #FFF;
      background: #10253E;
      padding: 48px 40px 48px 14%;
      box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
      border-radius: 6px; } }
  @include mobilelg() {
    padding-left: 12px;
    padding-right: 12px;
    .item {
      &__box {
        margin-left: 0;
        padding: 24px; }
      &__img {
        position: relative;
        left: 0;
        padding: 0;
        width: 100%;
        margin-bottom: 32px; }
      &__quote {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 32px; }
      &__author {
        .author-logo {
          display: block;
          margin-bottom: 16px; }
        .auhtor-content {
          display: block;
          padding: 0;
          border-left: 0; } } } } }

// PARTNER ACQUISITION
.masthead--pac {
  .masthead__desc {
    margin-bottom: 24px;
    p {
      &:last-child {
        margin-bottom: 0; } } }
  .masthead__content {
    padding-top: 30px;
    padding-bottom: 64px; }
  @include maxtablet() {
    .masthead__content {
      padding-bottom: 30px; } } }

.advantage--pac {
  .section-head__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 48px; }
  @include maxtablet() {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 12px;
    padding-right: 12px;
    .section-head__title {
      font-size: 28px;
      line-height: 36px; } } }

.fya {
  background: #F2F4F7;
  &__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 48px; }
  &__content {
    ul.custom-list {
      &> li {
        &:last-child {
          margin-bottom: 0; } } } }
  &__button {
    padding-top: 32px; }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    .row {
      flex-direction: column-reverse; }
    &__title {
      font-size: 28px;
      line-height: 36px; }
    &__img {
      margin-bottom: 40px; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; } } }

.block-clients__pac {
  background: transparent;
  padding: 0;
  .block-clients {
    &__title {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 48px; }
    &__list {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 100%;
        max-width: 16.666666%;
        flex: 0 0 16.666666%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 12px;
        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%; } } }
    &__wrap {
      padding-top: 80px;
      padding-bottom: 64px; } }
  @include maxtablet() {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 12px;
    padding-right: 12px;
    .block-clients {
      &__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 40px;
        br {
          display: none; } }
      &__list {
        .item {
          max-width: 33.333333%;
          flex: 0 0 33.333333%;
          height: 64px;
          padding: 12px 16px; } }
      &__wrap {
        padding-top: 64px;
        padding-bottom: 64px; } } } }

.pca-steps {
  padding-top: 80px;
  padding-bottom: 80px;
  &__wrap {
    .section-head {
      &__title {
        text-align: center;
        margin-bottom: 64px;
        color: $text!important; } }
    .post {
      text-align: center;
      &__img {
        img {
          width: 80px;
          height: auto;
          margin-bottom: 16px; } }
      &__title {
        @include font-600;
        margin-bottom: 12px;
        display: block;
        font-size: 1.25rem;
        line-height: 2rem; }
      &__content {
        padding-right: 0; } }
    .with-arrow {
      .post {
        position: relative;
        &::after {
          background: url('../images/arrow-dash-dark.svg') no-repeat center;
          position: absolute;
          display: inline-block;
          top: 25%;
          right: -25%;
          content: '';
          width: 160px;
          height: 11px;
 } }          //animation: arrow 2s linear infinite
      &:last-child {
        .post {
          &::after,
          &::before {
            display: none!important; } } } } }
  &.alt {
    .pca-steps__wrap {
      .with-arrow {
        .post {
          &::after {
            right: -35%; }
          &__title {
            font-size: 18px;
            line-height: 28px;
            @include font-600; } } } } }
  @include maxtablet {
    padding: 24px 12px 14px 12px;
    .section-head {
      .section-head__title {
        font-size: 28px;
        line-height: 36px; } }
    &__wrap {
      .post {
        display: block;
        text-align: left;
        padding: 0 24px;
        &__img {
          display: inline-block;
          vertical-align: top;
          width: 56px;
          margin-right: 16px;
          img {
            width: 100px;
            height: auto; } }
        &__content {
          display: inline-block;
          vertical-align: top;
          width: calc(100% - 77px);
          p {
            opacity: 0;
            height: 0;
            transition: height .5s linear; } }
        &__title {
          font-size: 16px;
          line-height: 23px;
          margin: 8px 0;
          position: relative; }
        &.show {
          .post__content {
            p {
              opacity: 1;
              height: auto;
              transition: height .5s linear; } } } }
      .section-head {
        &__title {
          font-size: 28px!important;
          line-height: 36px!important;
          margin-bottom: 40px!important; } } }
    .with-arrow {
      .post {
        position: relative;
        padding: 0 24px 56px 24px;
        margin-bottom: 0;
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 50px;
          top: 86px;
          height: calc(100% - 125px);
          width: 2px;
          border-right: 2px dotted #9090A2; }
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 30px;
          height: 20px;
          bottom: 25px;
          top: unset;
          left: 36px;
          background: url('../images/arrow-down.svg') no-repeat center;
          background-size: contain; } }
      &:last-child {
        .post {
          &::before,
          &::after {
            display: none; } } } }
    &.alt {
      padding: 64px 12px;
      .section-head {
        padding-bottom: 0; }
      .pca-steps__wrap {
        .with-arrow {
          .post {
            padding-left: 0;
            padding-right: 0;
            &::before {
              top: 65px;
              left: 25px;
              height: calc(100% - 85px); }
            &::after {
              bottom: 10px;
              left: 11px; } }
          &:last-child {
            .post {
              padding-bottom: 30px; } } } } } } }

.pca-cta {
  &__title {
    color: #FFF;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px; }
  &__desc {
    color: #FFF; }
  &__buttons {
    padding-top: 20px;
    .btn-white {
      color: #10253E;
      border-color: #FFF;
      opacity: 1;
      transition: all .3s ease;
      &:hover {
        background: #FFF;
        opacity: 0.5; } }
    a,.btn {
      margin-right: 14px;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0; } } }
  &__box {
    background: #10253E;
    border-radius: 8px;
    color: #FFF;
    padding: 40px;
    text-align: center; }
  &__wrap {
    padding-top: 24px;
    padding-bottom: 24px; }
  @include maxtablet() {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 28px; }
    &__buttons {
      padding-top: 40px;
      a,.btn {
        margin-right: 0;
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0; } } }
    &__box {
      padding: 40px 20px 24px; } } }

.pca-table-block {
  .pca-table {
    display: block;
    width: 100%;
    border-top: 1px solid $border;
    border-left: 1px solid $border;
    span.check {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      background: url("../images/ic-check.svg") no-repeat center;
      background-size: contain; }
    span.uncheck {
      display: inline-block;
      vertical-align: middle;
      width: 17px;
      height: 17px;
      background: url("../images/ic-cancel-circle.svg") no-repeat center;
      background-size: contain; }
    .head {
      &__title {
        display: block;
        color: #005FBF;
        font-size: 24px;
        line-height: 32px;
        @include font-500();
        margin-bottom: 24px; }
      &__price {
        display: block;
        margin-bottom: 24px;
        span {
          font-size: 28px;
          line-height: 36px;
          @include font-500(); }
        small {
          font-size: 14px;
          line-height: 20px;
          margin-left: 4px; } }
      &__buttons {
        a,.btn {
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0; } } } }
    .cta-sm {
      display: none; }
    &__row {
      position: relative;
      display: grid;
      grid-template-columns: 312px auto auto auto; }
    &__th {
      display: flex;
      align-items: center;
      width: 100%;
      // +font-500()
      padding: 16px;
      border-right: 1px solid $border;
      border-bottom: 1px solid $border;
      &.has-bg {
        background-color: #F2F4F7; } }
    &__td {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      padding: 16px;
      border-right: 1px solid $border;
      border-bottom: 1px solid $border;
      &.has-bg {
        background-color: #F2F4F7; } } }

  &__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 48px;
    text-align: center; }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  .tnc {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    color: #545465;
    margin-top: 16px; }
  &.alt {
    .pca-table-block__title {
      margin-bottom: 68px; }
    .pca-table {
      &__row {
        grid-template-columns: 392px auto auto; }
      &__th,
      &__td {
        &.head {
          background: #F2F4F7;
          &.highlight {
            font-size: 24px;
            line-height: 32px;
            color: #005FBF;
            background: #EDF6FB;
            margin-top: -10%;
            padding-top: 24px;
            padding-bottom: 24px;
            border: 1.5px solid #005FBF;
            border-radius: 8px 8px 0 0; } }
        &.highlight {
          border-left: 1.5px solid #005FBF;
          border-right: 1.5px solid #005FBF;
          &.last {
            border-bottom: 1.5px solid #005FBF;
            border-radius: 0 0 8px 8px; } } } }
    .pca-table-block__action {
      margin-top: 40px; } }
  @include maxtablet() {
    .pca-table {
      .hide-sm {
        display: none; }
      .cta-sm {
        display: block;
        background: #FFF;
        .btn {
          margin-bottom: 0 !important; } }
      .head {
        &__title {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 12px; }
        &__price {
          margin-bottom: 0;
          span {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 0;
            display: block; }
          small {
            display: block;
            margin: 0; } }
        &__buttons {
          display: none; } }
      &__th {
        padding: 14px;
        grid-column-start: 1;
        grid-column-end: 4;
        background: #F2F4F7; }
      &__td {
        padding: 14px;
        &.has-bg {
          background: #FFF; } }
      &__row {
        width: 100%;
        grid-template-columns: auto auto auto; } }
    &.alt {
      .pca-table-block__title {
        font-size: 28px;
        line-height: 36px; }
      .pca-table {
        &__row {
          grid-template-columns: auto auto; }
        &__th {
          grid-column-start: 1;
          grid-column-end: 3; }
        &__th,
        &__td {
          &.head {
            background: #F2F4F7;
            &.highlight {
              font-size: 18px;
              line-height: 24px;
              color: #005FBF;
              background: #EDF6FB;
              margin-top: -10%;
              padding: 14px 24px;
              border: 1.5px solid #005FBF;
              border-radius: 8px 8px 0 0; } }
          &.highlight {
            border-left: 1px solid #E3E3E8;
            border-right: 1px solid #E3E3E8;
            &.last {
              border-bottom: 1px solid #E3E3E8;
              border-radius: 0; } } } } } } }

.pca-testi.pricing-testi--alt {
  @include maxtablet() {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 64px;
    .product-testi__block-img {
      margin: 0 auto;
      padding-top: 70%;
      .btn-play {
        right: 20%; }
      img {
        border-radius: 8px;
        width: 70%;
        height: 100%;
        left: 15%;
        bottom: 0;
        top: auto; }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 50%;
        background: #10253E;
        position: absolute;
        bottom: -1px;
        left: 0;
        border-radius: 6px 6px 0 0; } }
    .testi {
      padding: 24px;
      &__body {
        padding: 0; } }
    .owl-dots {
      border-radius: 0 0 6px 6px; } } }

.form-footer {
  padding-top: 64px;
  text-align: center;
  .img {
    display: block;
    margin-bottom: 24px; }
  .title {
    display: block;
    font-size: 24px;
    line-height: 32px;
    @include font-500();
    margin-bottom: 12px; }
  p {
    a {
      color: #1357FF;
      &:hover {
        text-decoration: underline; } } } }

// IN-APP JURNAL X TALENTA & IN-APP JURNAL X KP
.masthead-inapp {
  font-family: "Inter",-apple-system,BlinkMacSystemFont,Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  &__logo {
    margin-bottom: 40px;
    img {
      display: block;
      height: 40px;
      width: auto; } }
  &__title {
    font-size: 40px;
    line-height: 48px; }
  &__desc {
    font-size: 14px;
    line-height: 20px; }
  &__img {
    padding-top: 40px; }
  &__form {
    padding: 32px;
    box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
    border-radius: 8px;
    .form-group > label {
      font-size: 14px;
      line-height: 20px; }
    .form-control {
      font-size: 14px;
      padding-top: 5px;
      padding-bottom: 5px; }
    .dropdown.bootstrap-select .btn, .dropdown.bootstrap-select button {
      font-size: 14px;
      line-height: 36px; }
    .form-action {
      padding-top: 24px;
      .btn {
        line-height: 18px; } } }
  &__wrap {
    padding-top: 48px;
    padding-bottom: 64px; } }

.block-clients--in-talenta,
.block-clients--in-klikpajak {
  font-family: "Inter",-apple-system,BlinkMacSystemFont,Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  background: transparent;
  padding: 0;
  .block-clients {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 32px; }
    &__list {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 100%;
        max-width: 16.666666%;
        flex: 0 0 16.666666%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 12px;
        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%; } } }
    &__buttons {
      padding-top: 24px;
      text-align: center; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; } }
  @include maxtablet() {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 12px;
    padding-right: 12px;
    .block-clients {
      &__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 40px;
        br {
          display: none; } }
      &__list {
        .item {
          max-width: 33.333333%;
          flex: 0 0 33.333333%;
          height: 64px;
          padding: 12px 16px; } }
      &__wrap {
        padding-top: 64px;
        padding-bottom: 64px; } } } }

.advantage--in-talenta {
  font-family: "Inter",-apple-system,BlinkMacSystemFont,Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  padding: 0;
  .advantage {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      text-align: center; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; } } }

.howjurnal--in-talenta,
.howjurnal--in-klikpajak {
  font-family: "Inter",-apple-system,BlinkMacSystemFont,Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  .section-head {
    margin-bottom: 40px;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
      text-align: center; }
    &__buttons {
      text-align: center; } }
  .accordion {
    .card__title {
      font-size: 14px;
      line-height: 20px; } }
  .accordion {
    .card__header {
      padding-top: 16px;
      padding-bottom: 16px; } }
  .howjurnal {
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; } } }

.pricing-testi--in-talenta,
.pricing-testi--in-klikpajak {
  font-family: "Inter",-apple-system,BlinkMacSystemFont,Inter,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  background: #FFF;
  margin-bottom: 80px;
  .testi .quote {
    p {
      color: #FFF;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 24px; }
    span {
      font-size: 12px;
      line-height: 16px; } }
  .link-more-white {
    font-size: 14px;
    line-height: 20px; }
  .slider-testi-alt {
    padding-left: 0;
    &:not(.owl-carousel) {
      .testi__body {
        padding-bottom: 0; } } }
  .pricing-testi {
    &__img-custom {
      max-width: 260px; }
    &__wrap {
      max-width: 100%;
      &::before {
        border-radius: 4px; } } } }

.footertop-inapp {
  background: #143A72;
  border-radius: 0 0 4px 4px;
  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    color: #FFF;
    margin-bottom: 24px; }
  &--in-klikpajak {
    background: #1C1D1E; } }

.product-article {
  article {
    margin-bottom: 80px;
    @include maxtablet {
      margin-bottom: 64px; } } }


.form-box {
  background: #FFFFFF;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 64px 54px;
  @include maxtablet {
    padding: 24px; } }


#tnc {
  ol {
    counter-reset: item;
    padding-left: 0;
    position: relative;
    &> li {
      position: relative;
      display: block;
      padding-left: 40px;
      margin-bottom: 20px;
      p {
        margin-bottom: 12px; }
      h2 {
        font-size: 24px;
        line-height: 36px; }
      &::before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 24px;
        line-height: 36px;
        @include font-600(); }
      &> ol {
        &> li {
          position: relative;
          padding-left: 50px;
          margin-bottom: 12px;
          p {
            margin-bottom: 12px; }
          h3 {
            font-size: 18px;
            line-height: 28px; }
          &::before {
            content: counters(item, ".") ". ";
            counter-increment: item;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 18px;
            line-height: 28px;
            @include font-600(); }
          &> ol {
            &> li {
              position: relative;
              padding-left: 55px;
              margin-bottom: 12px;
              p {
                margin-bottom: 12px; }
              h4,h5 {
                font-size: 16px;
                line-height: 24px; }
              &::before {
                content: counters(item, ".") ". ";
                counter-increment: item;
                position: absolute;
                left: 0;
                top: 0;
                font-size: 16px;
                line-height: 24px;
                @include font-600(); } } } } } } }
  ul {
    list-style: disc;
    position: relative;
    padding-left: 15px;
    li {
      display: list-item;
      margin-bottom: 12px;
      p {
        margin-bottom: 12px; }
      &::before {
        display: none; } } } }


.device-info {
  background: #F2F2F3;
  padding: 100px 20px 35px;
  &__box {
    padding: 25px;
    background: #FFF;
    border-radius: 6px; }
  &__img {
    margin-bottom: 16px;
    img {
      margin: 0 auto; } }
  &__desc {
    p {
      font-size: 24px;
      line-height: 32;
      text-align: center;
      @include font-600; } } }
