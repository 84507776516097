.masthead-f {
  // margin-top: 115px
  &__slider {
    .items {
      position: relative;
      padding-bottom: 0; }
    .item {
      position: relative;
      min-height: calc(100vh - 117px);
      background: #F1F5F9;
      align-items: center;
      display: flex;
      &__logo {
        width: auto;
        height: 37px;
        margin-bottom: 8px; }
      &__title {
        color: #FFF;
        font-size: 40px;
        line-height: 56px;
        max-width: 600px;
        margin: 0 0 24px;
        @include font-700();
        margin-bottom: 8px; }
      &__desc {
        max-width: 600px;
        color: #FFF;
        margin-bottom: 8px; }
      &__action {
        max-width: 600px;
        padding-top: 16px;
        a {
          margin-right: 16px;
          &:last-child {
            margin-right: 0; } } }

      &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        .item__video {
          z-index: 1;
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center; }
        &::before {
          z-index: 2;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          // background: linear-gradient(264.52deg, rgba(16, 36, 62, 0.06) 47.24%, #07172C 83.73%)
          background: linear-gradient(250deg, rgba(30, 127, 229, 0.06) 25.67%, rgba(1, 47, 95, 1) 97.38%); }
        &-img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          object-position: center center; } }
      &__wrap {
        position: relative;
        z-index: 2; } }
    .swiper-pagination {
      bottom: 32px!important;
      left: calc(50% - 624px)!important;
      text-align: left!important;
      max-width: 1248px!important;
      padding-left: 12px!important;
      padding-right: 12px!important;
      .swiper-pagination-bullet-active {
        background: #FFF; } } }
  &__acv {
    padding-top: 64px;
    padding-bottom: 64px;
    background: #F1F5F9;
    &-stars {
      display: flex;
      max-width: 120px;
      margin: 0 auto 8px;
      .star {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 2px;
        &::before {
          content: "";
          z-index: 1;
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: url(../images/ic-star-w.svg) no-repeat;
          background-position: left;
          background-size: cover; }
        span {
          z-index: 2;
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: url(../images/ic-star.svg) no-repeat;
          background-position: left;
          background-size: cover; } } }
    &-label {
      color: $text;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      display: block;
      margin: 0 0 8px;
      text-align: center; }
    &-logos {
      .items {
        padding: 0; }
      .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 0 24px; }
      .item {
        padding: 0 8px;
        width: auto; }
      img {
        display: block;
        width: auto;
        height: 40px; } }
    &-testimonial {
      max-width: 552px;
      margin: 0 auto;
      .swiper-wrapper {
        align-items: stretch; }
      .item {
        height: auto;
        color: $text;
        background: #FFF;
        border-radius: 16px;
        &__quote {
          display: block;
          width: 100%;
          margin: 0;
          padding-bottom: 32px;
          max-width: 600px; }
        &__name {
          font-weight: 600; }
        &__author {
          display: block;
          width: 100%;
          max-width: 60%;
          flex: 0 0 60%;
          margin-top: auto; }
        &__logo {
          display: block;
          width: 100%;
          max-width: 40%;
          flex: 0 0 40%;
          text-align: right;
          margin-top: auto; }
        &__wrap {
          padding: 32px;
          display: flex;
          height: 100%;
          flex-wrap: wrap; } } } }
  &.heading-lg {
    .masthead-f__slider {
      .item {
        &__title {
          font-size: 40px;
          line-height: 56px;
          max-width: 600px;
          @include font-700();
          margin-bottom: 8px; } } } }
  @include tablets() {
    &__slider {
      .swiper-pagination {
        left: 0;
        max-width: 100%;
        text-align: center; } } }
  @include maxtablet() {
    &__slider {
      .item {
        min-height: 460px;
        &__title {
          font-size: 32px;
          line-height: 40px; } }
      .swiper-pagination {
        left: 0;
        max-width: 100%;
        text-align: center;
        bottom: 16px; } }
    &__acv {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 32px;
      padding-bottom: 32px;
      &-testimonial {
        .item {
          &__author {
            max-width: 100%;
            flex: 0 0 100%; }
          &__logo {
            display: none; }
          &__wrap {
            padding: 24px; } } } }
    &.heading-lg {
      .masthead-f__slider {
        .item {
          &__title {
            font-size: 32px;
            line-height: 40px;
            max-width: 900px; } } } }
    .item__action {
      a {
        width: auto;
        display: inline-block; } } }
  @include mobilelg() {
    &__slider {
      display: flex;
      .item {
        min-height: calc(100vh - 56px);
        display: flex;
        align-items: flex-end;
        &__bg {
          &-img {
            object-position: 65% center; } }
        &__wrap {
          padding-bottom: 32px; }
        &__action {
          a {
            width: 100%;
            display: block; } } } } } }

