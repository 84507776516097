.sitemap {
  padding-top: 81px;
  padding-bottom: 160px;

  &__title {
    text-align: center;
    padding: 80px 0 0;
    margin-bottom: 64px;
    font-size: 48px;
    line-height: 56px;
    @include font-600(); }

  &__label {
    color: #2B2A35;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0;
    @include font-600(); }

  &__list {
    display: block;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    margin-bottom: 32px;

    li {
      display: block;
      padding: 6px 0;

      a {
        display: block;
        color: #1357FF;
        text-decoration: none;

        &:hover {
          text-decoration: underline; } } } }
  hr {
    margin: 48px 0; } }
@include maxtablet() {
  &__list {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; } }


