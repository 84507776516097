.testi-compact {
  padding: 64px 0;
  .section-head__title {
    margin-bottom: 24px; }
  &__wrapper {
    background: #F1F5F9;
    padding: 32px;
    border-radius: 4px;
    margin: 0 48px; }
  &__testi {
    margin: 0 0 16px 0; }
  &__info {
    display: flex;
    align-items: center;
    width: 100%;
    &-left,
    &-right {
      width: 50%; }
    &-right {
      text-align: right; } }
  &__img {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    vertical-align: middle;
    overflow: hidden;
    img {
      height: 48px;
      width: auto;
      border-radius: 50%;
      object-fit: cover; } }
  &__logo {
    display: inline-block;
    vertical-align: middle;
    img {
      height: 48px;
      width: auto; } }
  &__contact {
    display: inline-block;
    width: calc(100% - 69px);
    vertical-align: middle; }
  &__name {
    @include font-600(); }
  @include maxtablet() {
    padding: 32px 0;
    &__wrapper {
      margin: 0; } }
  @include mobilelg() {
    &__info {
      flex-direction: column;
      &-left {
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 24px; }
      &-right {
        width: 100%;
        flex: 0 0 100%;
        text-align: left; } } } }
