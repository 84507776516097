.toc-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  h1,h2,h3 {
    flex: 0 0 100%;
    width: 100%;
    text-align: center; }
  .toc {
    max-width: 70%;
    flex: 0 0 100%;
    display: inline-flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 48px 0;
    li {
      a {
        display: inline-flex;
        padding: 10px;
        border: 1px solid #E2E2E2;
        &:hover {
          background: #4B61DD;
          color: #FFF;
          border-color: #4B61DD; } } } }
  @include mobilelg() {
    .toc {
      max-width: 100%; } } }


.toc_section {
  padding: 0 0 48px 0;
  border-bottom: 1px solid #E2E2E2;
  margin: 0 auto 48px auto;
  max-width: 70%;
  width: 100%;
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    li {
      strong {
        font-size: 26px;
        line-height: 150%;
        margin-bottom: 18px;
        display: block; }

      .content__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 0 0 100%;
        width: 100%;
        li {
          display: inline-flex;
          flex: 0 0 100%;
          width: 100%;
          max-width: calc(50% - 8px);
          a {
            color: #4B61DD;
            &:hover {
              color: #4B61DD;
              text-decoration: underline; } } } } } }
  @include maxtablet() {
    max-width: 100%; }
  @include mobilelg() {
    ul {
      li {
        .content__list {
          li {
            max-width: 100%; } } } } } }

