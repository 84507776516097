.testimoni-twoblock {
  padding: 64px 0;
  &__title {
    text-align: center;
    margin-bottom: 24px; }
  &__box {
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-radius: 4px;
    overflow: hidden;
    .left {
      flex:  0 0 calc(100% - 270px);
      width: calc(100% - 270px);
      display: flex;
      align-content: center;
      background: #F2F4F7;
      flex-wrap: wrap;
      gap: 16px;
      padding: 32px;
      blockquote {
        margin: 0;
        flex: 0 0 100%; }
      .info {
        display: flex;
        flex: 0 0 100%;
        justify-content: space-between;
        align-items: center;
        &__left {
          flex:  0 0 calc(100% - 200px);
          display: flex;
          gap: 16px;
          .img {
            flex: 0 0 48px;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 48px;
              height: 48px;
              object-fit: cover; } }
          .meta {
            flex: 0 0 calc(100% - (48px + 16px));
            .name {
              @include font-600(); } } }
        &__right {
          flex: 0 0 200px;
          .logo {
            display: flex;
            justify-content: flex-end;
            img {
              display: inline-flex;
              height: 48px;
              width: auto; } } } } }

    .right {
      flex: 0 0 270px;
      width: 270px;
      background: #0055A2;
      color: #FFF;
      padding: 32px 24px;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      .title,
      .desc {
        flex: 0 0 100%;
        font-size: 20px;
        line-height: 28px;
        @include font-600(); }
      .big {
        flex: 0 0 100%;
        font-size: 52px;
        line-height: 56px;
        @include font-700(); }
      .action {
        flex: 0 0 100%; } } }
  @include maxtablet() {
    padding: 32px 0;
    &__box {
      flex-direction: column;
      .left,
      .right {
        flex: 0 0 100%;
        width: 100%; } } }
  @include mobilelg() {
    &__title {
      br {
        display: none; } }
    &__box {
      .left {
        .info {
          flex-wrap: wrap;
          gap: 16px;
          &__left,
          &__right {
            flex: 0 0 100%;
            width: 100%; }
          &__right {
            .logo {
              justify-content: flex-start; } } } } } } }
