/* ------------------------------------------------------------------------------------------------ */
/* Fonts */
/* ------------------------------------------------------------------------------------------------ */


//+fontGenerator("Helvetica Neue",(200,400, 500, 800),(HelveticaNeueLight,HelveticaNeue,HelveticaNeueMedium,HelveticaNeueBold))

// +fontGenerator("Graphik",(400),(graphik-regular))
// +fontGenerator("Graphik",(500),(graphik-medium))
// +fontGenerator("Graphik",(600),(graphik-semibold))

// +fontGenerator("Roboto",(400),(roboto-regular))
// +fontGenerator("Roboto",(500),(roboto-medium))
// +fontGenerator("Roboto",(600),(roboto-semibold))

// +fontGenerator("Inter",(400),(Inter-Regular))
// +fontGenerator("Inter",(500),(Inter-Medium))
// +fontGenerator("Inter",(600),(Inter-SemiBold))
// +fontGenerator("Inter",(700),(Inter-Bold))

@mixin font-400 {
  font-family: "Inter", sans-serif;
  font-weight: 400; }

@mixin font-500 {
  font-family: "Inter", sans-serif;
  font-weight: 500; }

@mixin font-600 {
  font-family: "Inter", sans-serif;
  font-weight: 600; }

@mixin font-700 {
  font-family: "Inter", sans-serif;
  font-weight: 700; }


// =robo-400
//   font-weight: 400

// =robo-500
//   font-weight: 500

// =robo-600
//   font-weight: 600
