/* ------------------------------------------------------------------------------------------------ */
/* Footer */
/* ------------------------------------------------------------------------------------------------ */
.footer {
  &_sc1 {
    background: #F2F4F7; }

  &_sc11 {
    padding: 64px 0;
    // .col-lg-2
    //   img
 }    //     max-height: 40px
  h3,
  .footer-menu__title {
    @extend .font-16;
    // font-weight: bold
    @include font-500();
    margin: 0 0 8px; }
  &_menu {
    li:not(:last-child) {
      margin: 0 0 8px; } }
  a {
    color: $text;
    &:hover {
      color: $blue;
      text-decoration: underline; }
    span.tag {
      display: inline-block;
      color: $blue;
      margin-left: 16px; } }
  hr {
    margin: 0; }
  &_sc12 {
    padding: 25px 0 56px; }
  &_addr {
    p:nth-last-child(2) {
      margin: 0; }
    &_item {
      margin: 0 0 8px;
      padding-left: 85px;
      position: relative;
      h3,p,b,.footer-menu__title {
        margin: 0; }
      b {
        @include font-600();
        font-size: 16px;
        line-height: 26px;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0; }
      @include maxtablet() {
        padding-left: 0!important;
        b {
          position: relative;
          display: block; } } } }

  &_tel {
    //position: absolute
    //bottom: 0
    //left: 12px
 }    //right: 12px
  &_social {
    a {
      &:hover {
        text-decoration: none;
        opacity: 0.5; }
      &:not(:last-child) {
        margin-right: 10px; } } }
  &_lang.bootstrap-select {
    .filter-option {
      display: inline-block;
      vertical-align: middle; }
    .bttn {
      border: none;
      font-weight: 400;
      text-align: right;
      padding-right: 0;
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        position: relative;
        display: inline-block;
        background: url("../images/icon-lang.svg") center;
        background-size: cover;
        vertical-align: middle;
        margin-right: 10px; }
      &:after {
        display: none; } }
    div.dropdown-menu {
      max-width: 160px;
      right: 0;
      left: auto !important;
      border: none;
      a {
        border: solid #afafaf;
        border-width: 0 1px; }
      li:first-child {
        a {
          border-top-width: 1px;
          border-radius: 4px 4px 0 0; } }
      li:last-child {
        a {
          border-bottom-width: 1px;
          border-radius: 0 0 4px 4px; } } } }
  &_sc2 {
    padding: 35px 0;
    border-top: 1px solid #e2e2e2;
    a {
      color: #4A26C4; }
    @include maxtablet() {
      .col-md-8 {
        text-align: left; } }
    @include mobilelg() {
      .col-md-8 {
        text-align: center; } } }
  &_mekari {
    display: inline-block;
    margin-right: 30px; }

  &_logo {
    display: block;
    height: 55px;
    width: auto;
    margin-bottom: 24px;
    @include maxtablet {
      height: 50px; } }

  &_menu {
    margin-bottom: 16px; }

  .cert {
    margin: 8px -7px 24px;
    .img {
      display: inline-block;
      vertical-align: top;
      max-width: 100px;
      padding: 0 7px;
      img {
        display: block; } }

    @include xsdesktop() {
      .img {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0; } } } }

  &_social {
    margin-bottom: 24px;
    a {
      &:not(:last-child) {
        margin-right: 10px; } } }

  .link-more {
    margin-right: 36px; }


  .wpml-ls-legacy-dropdown .wpml-ls-flag {
    margin-right: 12px; } }

.footer_sc2.footer_sc2_alt {
  background: #FAFBFA;
  padding: 24px 0; }

.footer-alt-small {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #E6E9EF;
  &__logo {
    display: inline-block;
    vertical-align: middle;
    a {
      display: inline-block; }
    img {
      display: block;
      height: 55px;
      width: auto; } }
  .copyright {
    display: inline-block;
    vertical-align: middle;
    padding-left: 56px;
    font-size: 14px;
    line-height: 20px; }
  .link-external {
    font-size: 14px;
    line-height: 24px;
    &::before {
      position: relative;
      top: -2px; } }
  &__buttons {
    text-align: right; }

  @include maxtablet() {
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    &__logo {
      margin-bottom: 16px; }
    .copyright {
      padding: 0 24px; }
    &__buttons {
      padding-top: 16px;
      text-align: center; } } }

