.clients {
  padding: 64px 0;
  background: #F2F4F7;
  text-align: center;
  &__title {
    font-size: 20px;
    line-height: 28px;
    @include font-600();
    margin-bottom: 56px; }
  &.white,
  &.bg-white {
    background: #FFF;
    .logo-marquee {
      padding-top: 64px;
      padding-bottom: 64px;
      &__title {
        text-align: center;
        margin: 0 0 32px; }
      &__desc {
        margin-bottom: 24px;
        p {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0; } } }
      &__group {
        display: flex;
        overflow: hidden;
        user-select: none;
        position: relative;
        &::before,
        &::after {
          content: "";
          z-index: 2;
          display: block;
          width: 64px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(89.8deg, #FFF 49.02%, rgba(255, 255, 255, 0) 113.2%); }
        &::after {
          left: auto;
          right: 0;
          transform: matrix(-1, 0, 0, 1, 0, 0); }
        &.reverse .logo-marquee__list {
          animation-direction: reverse;
          animation-delay: -3s; }
        &:last-child {
          .logo-marquee__list {
            margin-bottom: 0; } } }
      &__list {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-around;
        min-width: 100%;
        margin-bottom: 24px;
        animation: scroll-x 60s linear infinite;
        .item {
          display: grid;
          place-items: center;
          margin: 0 24px;
          width: 148px;
          height: 56px;
          img {
            display: block;
            max-width: 100%;
            max-height: 56px;
            width: auto;
            height: auto; } } }
      &__action {
        margin-top: 35px; }
      @include maxtablet {
        padding: 32px 0;
        &__title {
          br {
            display: none; } }
        &__list {
          animation-duration: 30s; } } } }
  @include maxtablet() {
    padding: 32px 14px;
    .clients__title {
      br {
        display: none; } } }
  @include mobilelg() {
    .clients__title {
      margin-bottom: 24px; } } }
