.tab-sidebar {
  padding: 64px 0;
  .section-head {
    text-align: center;
    &__title {
      margin-bottom: 8px; }
    &__desc {
      margin-bottom: 48px; } }
  &__title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
    @include font-600(); }
  &__sidebar {
    width: 90%;
    .nav-tabs {
      border: 0;
      .nav-item {
        width: 100%;
        margin-bottom: 0;
        .nav-link {
          font-size: 14px;
          line-height: 24px;
          border: 0;
          padding: 12px 30px 12px 0;
          display: block;
          width: 90%;
          border-bottom: 1px solid #D0D6DD;
          position: relative;
          color: #2B2A35;
          @include font-600();
          &.active,
          &:hover {
            color: $blue; }
          &::after {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url(../images/ic-caret-down.svg) no-repeat center;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 14px;
            transform: rotate(270deg); } } } } }
  .tab-content {
    .tab-pane {
      .row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0; } }
      .item {
        padding: 24px;
        &__icon {
          width: 48px;
          height: 48px;
          margin-bottom: 8px;
          object-fit: contain; }
        &__title {
          @include font-600();
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px; } }
      .card {
        padding: 24px;
        border: 1px solid #D0D6DD;
        border-radius: 4px;
        height: calc(100% - 24px);
        margin-bottom: 24px;
        &__img {
          width: 100%;
          height: 125px;
          object-fit: contain;
          margin-bottom: 8px; }
        &__icon {
          width: 48px;
          height: 48px;
          object-fit: contain;
          margin-bottom: 8px; }
        &__title {
          @include font-600();
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px;
          margin-top: 0; }
        &__desc {
          p {
            margin-bottom: 16px; } } } } }
  @include maxtablet() {
    padding: 32px 10px;
    .tab-content {
      .tab-pane {
        display: block;
        .tab-sidebar__accr {
          font-size: 14px;
          line-height: 20px;
          color: #626B79;
          padding: 12px 0;
          @include font-600();
          cursor: pofont;
          border-bottom: 1px solid #D0D6DD;
          position: relative;
          &::after {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url(../images/ic-caret-down.svg) no-repeat center;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 11px; } }
        .tab-sidebar__content {
          display: none;
          .item {
            position: relative;
            padding: 12px 16px 12px 40px;
            &__icon {
              position: absolute;
              width: 24px;
              height: 24px;
              left: 0; }
            &__desc {
              p {
                margin-bottom: 0; } } }
          .card {
            margin-bottom: 24px; } }
        .tab-sidebar__toggle {
          margin-bottom: 0; }
        &.active {
          .tab-sidebar__content {
            display: block; }
          .tab-sidebar__accr {
            color: $blue;
            border-bottom-color: $blue;
            &::after {
              transform: rotate(180deg); } }
          .tab-sidebar__toggle {
            margin-bottom: 24px; } } } } } }


.integration__cta {
  border-top: 1px solid $border;
  padding: 64px 0;
  @include maxtablet {
    padding: 32px 10px; } }
