// IN APP JURNAL PAY
.masthead--jurnal-pay {
  background: linear-gradient(180deg, #008CF2 0%, #004DC2 100%);
  position: relative;
  padding-top: 0;
  .masthead__logo {
    display: inline-block;
    margin-bottom: 44px; }
  .masthead__title {
    color: #FFF;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 16px; }
  .masthead__note {
    font-size: 12px;
    line-height: 16px;
    color: #FFF;
    position: absolute;
    left: 12px;
    bottom: 0; }
  .masthead__left {
    min-height: 370px; }
  .masthead__content {
    position: relative;
    padding: 40px 0 40px;
    .text-xl {
      font-size: 48px;
      line-height: 56px;
      font-weight: 700;
      color: #FFF; }
    .btn-grey {
      color: #005FBF; } }
  .masthead__desc {
    margin-bottom: 40px;
    p {
      font-size: 14px;
      line-height: 24px;
      color: #FFF; } }
  .masthead__media {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 45%;
    .play-button {
      width: 76px;
      height: 76px;
      background: url('../images/ic-play-circle-alt.svg') no-repeat center;
      background-size: contain;
      border-radius: 50%;
      position: absolute;
      left: calc(50% - 38px);
      top: calc(50% - 38px);
      @include pulse(); } }
  @include maxtablet {
    .masthead__title {
      font-size: 24px;
      line-height: 36px;
      br {
        display: none; } }
    .masthead__note {
      position: relative;
      bottom: auto;
      left: auto;
      padding-top: 40px; }
    .masthead__left {
      min-height: initial; }
    .masthead__content {
      padding: 40px 12px;
      .text-xl {
        font-size: 28px;
        line-height: 36px; } }
    .masthead__media {
      position: relative;
      right: auto;
      bottom: auto;
      max-width: 100%; } } }

.jp-video {
  padding: 46px 0;
  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px; }
  &__video {
    margin-bottom: 35px; } }

.jp-featured {
  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px; }
  &__desc {
    max-width: 600px;
    margin: 0 auto 64px; }
  &__main {
    padding-bottom: 64px;
    .item {
      display: flex;
      margin: 0 0 32px;
      &__img {
        display: flex;
        width: 60px;
        height: 60px;
        background: #FFF;
        box-shadow: 0px 4px 4px rgba(50, 50, 71, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.06);
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        img {
          display: block;
          width: 32px;
          height: 32px;
          object-fit: contain;
          object-position: center; } }
      &__content {
        max-width: calc(100% - 60px);
        padding-left: 16px;
        .title {
          display: block;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          margin-bottom: 4px; }
        .text {
          display: block;
          font-size: 12px;
          line-height: 16px;
          color: #777; } } }
    span.line-dashed {
      position: absolute;
      z-index: 1;
      top: 0;
      left: -330px;
      width: 100%;
      height: calc(50% - 50px);
      border-bottom: 2px dashed #009BDE;
      border-left: 2px dashed #009BDE;
      border-radius: 0 0 0 16px; }
    .box-l {
      position: relative;
      background: #F2F4F7;
      border: 1px solid #E2E2E2;
      border-radius: 16px;
      padding: 32px 24px;
      z-index: 2;
      .logo {
        margin-bottom: 24px; }
      .item {
        position: relative;
        z-index: 2;
        max-width: 320px;
        &:last-child {
          margin-bottom: 0; } }
      span.line {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 28px;
        width: calc(100% + 60px);
        height: calc(100% + 60px);
        border-bottom: 2px dashed #009BDE;
        border-left: 2px dashed #009BDE;
        border-radius: 0 0 0 16px; } }
    .box-r {
      position: relative;
      z-index: 3;
      background: #E8F3F7;
      border: 1.5px solid #009BDE;
      border-radius: 16px;
      padding: 24px 24px 40px;
      .logo {
        display: block;
        height: 40px;
        width: auto;
        margin: 0 0 20px; }
      .item:last-child {
        margin-bottom: 0; } }

    .box-b {
      position: relative;
      z-index: 2;
      span.line {
        display: block;
        width: 1px;
        height: 48px;
        border-left: 2px dashed #009BDE;
        position: absolute;
        left: 50%;
        bottom: 100%; }
      &__wrap {
        position: relative;
        z-index: 3;
        background: linear-gradient(180deg, #008CF2 0%, #004DC2 100%);
        padding: 16px 20px;
        border-radius: 16px;
        margin-top: 48px;
        span.title {
          color: #FFF;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500; }
        p {
          color: #FFF;
          font-size: 12px;
          line-height: 16px; } } } }

  &__wrap {
    padding-top: 64px;
    padding-bottom: 0;
    .page-tabs {
      .page-tabs__header {
        padding: 0 12px 40px 12px;
        display: flex;
        justify-content: center;
        .nav-tabs {
          display: flex;
          background: #F2F4F7;
          border-radius: 100px;
          padding: 4px;
          .nav-item {
            .nav-link {
              padding: 12px 24px;
              border-radius: 100px;
              background: transparent;
              border: 1.5px solid transparent;
              .nav-item__title {
                label {
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: 600;
                  color: #9090A2; } }
              &.active {
                background: #FFF;
                border: 1.5px solid #005FBF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                .nav-item__title {
                  label {
                    color: #005FBF; } } }
              &:hover {
                box-shadow: none; } } } } } } }
  @include maxtablet {
    &__wrap {
      padding: 40px 12px; } } }

.jp-oppst {
  .item {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 12px; } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet {
    .item {
      &__desc {
        margin-bottom: 24px; } }
    &__wrap {
      padding: 40px 12px; } } }


.jp-benefit {
  padding: 64px 0;
  &__subtitle {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 10px; }
  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px; }
  &__desc {
    margin-bottom: 40px;
    p {
      font-size: 14px;
      line-height: 24px; } }
  .item {
    background: $white;
    padding: 24px;
    border-radius: 4px;
    &__icon {
      margin-bottom: 12px; }
    &__title {
      display: block;
      font-size: 14px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 12px; } } }


.jp-features {
  padding: 64px 0;
  &__title {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 16px;
    &.small {
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        width: 48px;
        height: 6px;
        background: #009BDE;
        top: -30px;
        left: calc(50% - 24px);
        position: absolute; } } }
  &__desc {
    margin-bottom: 40px;
    p {
      font-size: 14px;
      line-height: 24px; } }
  &__img {
    margin-bottom: 40px; }
  &__action {
    a {
      margin-right: 14px;
      &:last-child {
        margin-right: 0; } } }
  ul.custom-list {
    li {
      font-size: 14px;
      line-height: 24px; } } }

.jp-cs {
  padding: 64px 0;
  &__title {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 16px; }
  &__desc {
    margin-bottom: 64px;
    p {
      font-size: 14px;
      line-height: 24px; } }
  &.block-cs {
    .item__left {
      .quote {
        font-size: 20px;
        line-height: 28px; } }
    .item__right {
      background: $white;
      .desc {
        font-size: 20px;
        line-height: 28px; } } } }
