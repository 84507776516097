.banner-block {
  padding: 64px 0;
  &__wrapper {
    padding: 24px;
    background: #EDFAFF;
    border-radius: 8px; }
  &__meta {
    @include font-600();
    span.badge {
      margin-left: 4px;
      background: #009BDE; } }
  &__title {
    @include font-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 8px;
    padding-right: 30px; }
  &__desc {
    padding-right: 30px;
    p {
      &:last-child {
        margin-bottom: 0px; } } }
  &__action {
    margin-top: 24px;
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; } } }
  &__img {
    text-align: center;
    img {
      display: inline-block;
      margin: 0 auto; } }
  &.banner-block--alt {
    .banner-block__wrapper {
      padding: 32px 64px;
      background: #F1F5F9;
      border-radius: 8px; } }
  @include maxtablet() {
    padding: 32px 0;
    &__title {
      font-size: 28px;
      line-height: 36px;
      padding-right: 0; }
    &__desc {
      padding-right: 0; }
    &__action {
      a {
        margin-right: 0;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } }
    &__img {
      margin-top: 24px; } }
  @include mobilelg() {
    &__wrapper {
      margin-left: 0;
      margin-right: 0;
      padding: 24px 12px; }
    &.banner-block--alt {
      .banner-block__wrapper {
        padding: 24px 12px; } } } }

