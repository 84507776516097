/* ------------------------------------------------------------------------------------------------ */
/* General */
/* ------------------------------------------------------------------------------------------------ */
body {
  //opacity: 0
  transition: all .2s;
  &.ready {
    opacity: 1; }
  &.full {
    background: #f9f9f9; }
  &.pause {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden; } }

.web-wrapper {
  height: auto; }

h1 {
  @include font-700;
  font-size: 40px;
  line-height: 56px;
  color: $black;
  margin: 0 0 8px;
  @include maxtablet {
    font-size: 28px;
    line-height: 36px; } }

h2 {
  @include font-600;
  font-size: 32px;
  line-height: 48px;
  color: $black;
  margin: 0 0 8px;
  @include maxtablet {
    font-size: 28px;
    line-height: 36px; } }

h3 {
  @include font-500;
  font-size: 24px;
  line-height: 32px;
  color: $black;
  margin: 0 0 8px;
  @include maxtablet {
    font-size: 20px;
    line-height: 28px; } }

h4 {
  @include font-500;
  font-size: 20px;
  line-height: 32px;
  color: $black;
  margin: 0 0 8px;
  @include maxtablet {
    font-size: 16px;
    line-height: 24px; } }


small {
  @include font-400;
  font-size: 14px;
  line-height: 30px;
  b {
    @include font-500; }
  @include maxtablet {
    font-size: 0.875rem;
    line-height: 1.5rem; } }

b {
  @include font-500; }

body.has-sb {
  .header.fixed {
    top: 0;
    .header__top {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
      transition: all .4s ease; }
    &.show-top {
      top: 0;
      .header__top {
        max-height: 40px;
        padding-top: 8px;
        padding-bottom: 8px;
        opacity: 1;
        visibility: visible;
        height: auto; } } }
  main {
    padding-top: 88px;
    @include mobile {
      padding-top: 0; } } }

.btn {
  display: inline-block;
  text-align: center;
  color: #fff;
  cursor: pointer;
  transition: all .2s;
  border-radius: 6px;
  background-color: #4B61DD;
  padding: 7px 24px;
  font-size: 16px;
  line-height: 24px;
  @include font-500();
  border: 1px solid #4B61DD;
  // box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2)
  &:hover {
    background: darken(#4B61DD,10%); }
  &.small,
  &.btn-sm {
    padding: 0.4375rem 1.5rem;
    font-size: 16px;
    line-height: 1.5em; }
  &.add {
    padding: 7px 24px 7px 16px;
    position: relative;
    &::before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      margin-top: -5px;
      margin-right: 8px;
      background: url('../images/ic-add-white.svg') no-repeat center;
      background-size: contain; }
    img {
      display: none!important; } }
  &.cta-waba {
    padding: 7px 16px 7px 16px;
    position: relative;
    &::before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      margin-top: -5px;
      margin-right: 8px;
      background: url('../images/ic-waba-white.svg') no-repeat center;
      background-size: contain; }
    img {
      display: none!important; } }
  &.cta-phone {
    padding: 7px 24px 7px 16px;
    position: relative;
    &::before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      margin-top: -5px;
      margin-right: 8px;
      background: url('../images/ic-phone.svg') no-repeat center;
      background-size: contain; }
    img {
      display: none!important; } }
  @include mobile {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
    &::first-child {
      margin-bottom: 16px; } }
  &-transparent {
    background: none;
    color: #545465;
    font-weight: 400;
    border-color: transparent;
    box-shadow: none;
    &.w-border {
      border-color: #E2E2E2; }
    &:hover {
      background: none;
      color: $red; } }
  &-transparent--invert {
    background: none;
    color: #005FBF;
    font-weight: 400;
    border-color: transparent;
    box-shadow: none;
    &.w-border {
      border-color: #005FBF; }
    &:hover {
      background: none;
      color: $black; } }
  &-bred {
    color: #4B61DD;
    background: none;
    box-shadow: none;
    &:hover {
      color: #FFF;
      background: #4B61DD;
      border-color: #4B61DD; } }
  &-block {
    display: block;
    width: 100%; }
  &-blue {
    background: #4B61DD;
    border-color: #4B61DD;
    font-size: 16px;
    @include font-500();
    box-shadow: none;
    &:hover {
      background: darken(#4B61DD,10%);
      border-color: darken(#4B61DD,10%);
      .with-arrow {
        @include bounce(); } }
    &.with-arrow {
      position: relative;
      padding-right: 35px;
      color: #FFF;
      &:after {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background: url(../images/ic-arrow-right-w.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        right: 10px;
        left: unset;
        top: calc(50% - 10px); }
      &:hover {
        text-decoration: none;
        &::after {
          right: 10px;
          left: unset;
          @include bounce(); } } }
    .with-arrow {
      width: 20px;
      height: 20px;
      background: url(../images/ic-arrow-right-w.svg) no-repeat center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px; }
    &.play {
      padding: 12px 24px 12px 16px;
      position: relative;
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -5px;
        margin-right: 8px;
        background: url('../images/ic-play-w.svg') no-repeat center;
        background-size: contain; } } }
  &-blue-border {
    background: #FFF;
    color: #4B61DD;
    border-color: #4B61DD;
    font-weight: 500;
    box-shadow: none;
    &.cta-waba {
      &::before {
        background: url('../images/ic-waba-purple.svg') no-repeat center; } }
    &.add {
      &::before {
        background: url('../images/ic-add-blue.svg') no-repeat center; } }
    &:hover {
      color: #FFF;
      background: #4B61DD;
      border-color: #4B61DD;
      &.cta-waba {
        &::before {
          background: url('../images/ic-waba-white.svg') no-repeat center; } }
      &.add {
        &::before {
          background: url('../images/ic-add-white.svg') no-repeat center; } }
      .with-arrow {
        filter: invert(1) brightness(10);
        @include bounce(); } }
    .with-arrow {
      width: 20px;
      height: 20px;
      background: url(../images/ic-arrow-right.svg) no-repeat center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px; }
    .icon {
      width: 20px;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-flex;
      margin-right: 8px;
      position: relative;
      transform: translateY(3px); } }
  &-blue-alt {
    background: #009BDE;
    border-color: #009BDE;
    font-weight: 500;
    box-shadow: none;
    &:hover {
      background: darken(#009BDE,10%); } }
  &-white {
    background: #FFF;
    color: #4B61DD;
    border-color: #FFF;
    box-shadow: none;
    &.cta-waba {
      &::before {
        background: url('../images/ic-waba-blue.svg') no-repeat center; } }
    &:hover {
      background: #4B61DD;
      border-color: #4B61DD;
      color: #fff;
      img {
        filter: invert(1) brightness(5); } } }
  &-white-border {
    background: transparent;
    color: #FFF;
    border-color: #FFF;
    font-weight: 500;
    box-shadow: none;
    &:hover {
      color: #005FBF;
      background: #FFF;
      border-color: #FFF;
      &.cta-waba {
        &::before {
          background: url('../images/ic-waba-blue.svg') no-repeat center; } } }
    &.cta-waba {
      &::before {
        background: url('../images/ic-waba-white.svg') no-repeat center; } } }
  &-cyan {
    color: #002A38;
    background: #5ED2FA;
    border-color: #5ED2FA;
    font-size: 16px;
    @include font-500();
    box-shadow: none;
    &:hover {
      background: darken(#5ED2FA,10%);
      border-color: darken(#5ED2FA,10%); }
    &.cta-waba {
      position: relative;
      padding-left: 16px;
      &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px;
        width: 24px;
        height: 24px;
        background: url("../images/ic-waba-dark.svg") no-repeat center;
        background-size: contain;
        margin-right: 6px; } } }

  &-trans {
    background: transparent;
    color: #FFF;
    border-color: $border;
    &:hover {
      color: $blue;
      background: #FFF;
      border-color: $border; } }
  &-link {
    background: transparent;
    border-color: transparent;
    color: $text;
    box-shadow: none;
    @include font-500;
    &:hover {
      background: transparent;
      border-color: transparent;
      color: $blue;
      box-shadow: none;
      .icon path {
        fill: $blue; } } }
  &-play {
    background: transparent;
    color: $blue;
    border-color: transparent;
    box-shadow: none;
    padding-left: 16px;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
      width: 20px;
      height: 20px;
      padding: 2px;
      background-color: transparent;
      background-image: url("../images/ic-play-video.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-right: 6px; }
    &:hover {
      background: transparent;
      border: 1px solid transparent;
      // &::before
 } }      //   +bounce()

  &-icon {
    .icon {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
      margin-right: 14px;
      height: 16px;
      width: auto;
      path {
        transition: all .3s ease-in-out; } }
    .play-button {
      width: auto;
      height: 11px;
      display: inline-block;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 14px solid #545465;
      border-radius: 3px;
      margin: 2px 10px -4px 0;
      transition: all .3s ease-in-out; }
    &:hover {
      .play-button {
        border-left-color: #005FBF;
        transition: all .3s ease-in-out; } } }


  &.disabled {
    pointer-events: none;
    background: #EDF0F2;
    border-color: #EDF0F2;
    color: #8B95A5;
    &.cta-waba {
      pointer-events: none!important;
      &::before {
        filter: brightness(.6); } } }
  &-black {
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    background: #2B2A35;
    border-color: #2B2A35;
    &:hover {
      background: darken(#2B2A35, 15%);
      border-color: darken(#2B2A35, 15%); } }

  &-purple {
    background: #4B61DD;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      background: darken(#4B61DD, 15%); } }

  &-white-app {
    background: $white;
    border: 1px solid #EAECFB;
    border-radius: 6px;
    color: #4B61DD;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      background: darken(#FFF, 5%); } } }

p {
  margin: 0 0 24px;
  &:last-child {
    margin: 0; }
  &.line {
    margin: 0 0 8px; } }

a {
  transition: all .2s;
  &.link {
    color: #4B61DD;
    &:hover {
      color: darken(#4B61DD,30%);
      text-decoration: underline; } }
  &.with-arrow {
    color: #334AC0;
    &:hover {
      text-decoration: underline;
      &:after {
        left: 4px; } } }
  &.full-link {
    @include absfull; }
  &.link-blue {
    color: #1D65FF;
    text-decoration: underline;
    .fa {
      position: relative;
      left: 10px;
      top: 4px;
      font-size: 24px;
      //font-weight: 600
      transition: all .2s; }
    &:hover {
      color: darken(#1D65FF,10%);
      .fa {
        left: 5px; } } } }


.owl-dots {
  text-align: center;
  margin: 16px 0; }
.owl-dot {
  width: 8px;
  height: 8px;
  background: #eee;
  border-radius: 50%;
  display: inline-block;
  margin: 0 6px;
  cursor: pointer;
  &.active {
    background: $red; } }

.not-found {
  padding: 160px 0;
  text-align: center;
  color: #ccc;
  &.maintenance {
    color: #777;
    img {
      margin-bottom: 30px; }
    h1 {
      font-size: 32px; } }
  h1 {
    font-size: 240px;
    line-height: 1; }
  p {
    font-size: 20px;
    line-height: 24px; } }

.collapse:not(.show) {
  display: none; }
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease; }

.flex-row-reverse {
  flex-direction: row-reverse!important; }

/** .tooltip
  padding-bottom: 11px
  .arrow
    position: absolute
    bottom: 0
    border: solid
    border-width: 11px 10px 0
    border-color: #212121 transparent */
    //left: calc(50% - 10px) !important
//&-inner
//  padding: 5px 17px
//  background-color: #212121
//  color: #fff
//  border-radius: 10px **/

.lazyloaded:not(.bg) {
  background-color: transparent; }

.pagination-row {
  display: block;
  padding: 20px 0 40px; }

.pagination {
  display: flex;
  justify-content: center; }

.page-item {
  display: block;
  min-width: auto;
  height: 24px;
  line-height: 24px;
  text-align: center;
  margin: 0 5px;
  padding: 0 5px;
  border-radius: 12px;
  color: #545465;
  &.active {
    @include font-500;
    .page-link {
      color: $blue; } }
  &.prev {
    margin-right: 16px; }
  &.next {
    margin-left: 16px; } }


.page-link {
  font-size: 16px;
  line-height: 24px;
  color: $text;
  .svg {
    display: block;
    height: 24px;
    width: auto; }
  &:hover {
    color: $blue;
    .svg path {
      stroke: $blue; } } }


.owl {
  &-carousel {
    position: relative; }
  &-nav {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 0;
    button:focus {
      outline: none !important; }
    .owl-prev,
    .owl-next {
      width: 30px;
      height: 30px;
      position: absolute;
      border-radius: 15px;
      overflow: hidden;
      top: -15px;
      background-size: contain;
      opacity: 1;
      transition: all .3s ease-in-out;
      cursor: pointer;
      &:hover {
        opacity: .5; } }
    .owl-prev {
      left: 0; }
    .owl-next {
      right: 0; }
    .prev,
    .next {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5) url(../images/icon-chevron-right-white.svg) no-repeat center;
      background-size: contain; }
    .prev {
      transform: scaleX(-1); } } }

body.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  z-index: 1073;
  top: 0;
  left: 0;
  width: 101%;
  height: 100%;
  overflow: auto;
  display: none;
  transition: all .2s;
  &:not(.show) {
    opacity: 0;
    .modal-dialog {
      transform: translate(0,-50px); } }
  .close {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
    opacity: 1;
    transition: all .3s ease-in-out;
    img {
      display: block;
      width: 26px;
      height: auto; }
    &:hover {
      opacity: .6; } }
  .modal-dialog {
    transition: all .3s ease-in-out;
    max-width: 770px;
    pointer-events: none; }
  .modal-content {
    padding: 34px 0;
    background: #fff;
    margin: 30px auto;
    position: relative;
    border-radius: 6px;
    pointer-events: auto;
    box-shadow: 0 4px 8px 0 rgba(119,119,119,0.1), 0 12px 20px 0 rgba(119,119,119,0.2); }

  &.proto {
    .modal-dialog {
      padding: 48px 58px;
      max-width: 520px; } }
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0;
    &.show {
      opacity: 0.8!important; } }
  h2 {
    margin: 0 0 16px; }
  &-download.proto {
    article {
      max-width: 312px;
      margin: 0 0 24px; }
    form {
      max-width: 392px; } }
  &-download {
    iframe {
      height: 85vh; } }

  &-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    line-height: 1;
    &:hover {
      transform: rotate(90deg); } }
  &.modal-download {
    .modal-content {
      box-shadow: none!important; } }
  &.modal--md {
    &:not(.show) {
      opacity: 0;
      .modal-dialog {
        transform: translate(0, 100%); } }
    .close {
      top: 32px;
      right: 40px;
      width: 70px;
      display: flex;
      .svg {
        path {
          fill: $blue; } }
      span {
        position: relative;
        top: 1px;
        display: inline-block;
        margin-left: 4px;
        vertical-align: middle;
        color: $blue; } }
    ul.list-check {
      h4 {
        margin-bottom: 4px;
        font-size: 20px;
        line-height: 28px; }
      li {
        padding-bottom: 24px;
        &::before {
          top: 3px; } } }
    .modal {
      &__icon {
        width: 40px;
        margin: 0 auto 8px;
        img {
          display: block;
          width: 100%;
          height: auto; } }
      &__img {
        position: relative;
        img {
          display: block;
          width: 100%;
          height: auto; }
        small {
          display: block;
          position: absolute;
          padding-top: 16px;
          top: 100%;
          left: 0;
          font-size: 12px;
          line-height: 16px; } }
      &__vid {
        position: relative;
        img {
          display: block;
          width: 100%;
          height: auto; }
        small {
          display: block;
          position: absolute;
          padding-top: 16px;
          top: 100%;
          left: 0;
          font-size: 12px;
          line-height: 16px; }
        .btn-play {
          z-index: 2;
          display: block;
          width: 68px;
          height: 68px;
          border-radius: 34rem;
          background-color: $blue;
          background-image: url(../images/ic-play-white.svg);
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          bottom: calc(50% - 34px);
          right: calc(50% - 34px);
          &:hover {
            @include pulseCustom(0,95,191,primary); } } }
      &__title {
        margin-bottom: 12px; }
      &__desc {
        margin-bottom: 40px;
        p b {
          color: $text; } }
      &-footer {
        position: absolute;
        background: #FFF;
        padding: 32px 6px;
        width: 100%;
        bottom: 0;
        left: 0; }
      &-body {
        height: 100%;
        overflow: auto;
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 80px; }
      &-dialog {
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        max-width: 100%;
        padding: 0;
        margin: 0;
        background: transparent;
        box-shadow: none; }
      &-content {
        position: relative;
        border-radius: 0;
        margin: 0;
        height: 90vh; } }

    @include mobile() {
      .modal__img {
        margin-bottom: 60px; }
      .modal-body {
        padding-left: 16px;
        padding-right: 16p; }
      .modal-dialog {
        justify-items: flex-end; }
      .close {
        top: 6px;
        right: 20px; } } }
  &.modal-subscribe {
    &.show {
      display: flex!important;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center; }
    .modal-dialog {
      width: 100%;
      position: relative;
      .modal-content {
        padding: 60px;
        .modal-body {
          img {
            margin-bottom: 30px; } } } } }

  &.modal--full {
    &:not(.show) {
      opacity: 0;
      .modal-dialog {
        transform: translate(0, 100%); } }
    .close {
      top: 32px;
      right: 40px;
      .svg {
        path {
          fill: $blue; } }
      span {
        position: relative;
        top: 1px;
        display: inline-block;
        margin-left: 4px;
        vertical-align: middle;
        color: $blue; }
      img {
        display: inline-block;
        vertical-align: middle; } }
    ul.list-check {
      h4 {
        margin-bottom: 4px;
        font-size: 20px;
        line-height: 28px; }
      li {
        padding-bottom: 24px;
        &::before {
          top: 3px; } } }
    .modal {
      &__icon {
        width: 40px;
        margin: 0 auto 8px;
        img {
          display: block;
          width: 100%;
          height: auto; } }
      &__img {
        position: relative;
        padding: 32px 0;
        img {
          display: block;
          width: 100%;
          height: auto; }
        small {
          display: block;
          position: absolute;
          padding-top: 16px;
          top: 100%;
          left: 0;
          font-size: 12px;
          line-height: 16px; } }
      &__vid {
        position: relative;
        padding: 32px 0;
        img {
          display: block;
          width: 100%;
          height: auto; }
        small {
          display: block;
          position: absolute;
          padding-top: 16px;
          bottom: 0;
          left: 0;
          font-size: 12px;
          line-height: 16px; }
        .btn-play {
          z-index: 2;
          display: block;
          width: 68px;
          height: 68px;
          border-radius: 34rem;
          background-color: $blue;
          background-image: url(../images/ic-play-white.svg);
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          bottom: calc(50% - 34px);
          right: calc(50% - 34px);
          &:hover {
            @include pulseCustom(0,95,191,primary); } } }
      &__title {
        margin-bottom: 12px; }
      &__desc {
        margin-bottom: 40px;
        p b {
          color: $text-dark; } }
      &-footer {
        position: absolute;
        background: #FFF;
        padding: 32px 6px;
        width: 100%;
        bottom: 0;
        left: 0; }
      &-body {
        height: 100%;
        overflow: auto;
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 80px; }
      &-dialog {
        border-radius: 0;
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 100vh;
        max-width: 100%;
        padding: 0;
        margin: 0;
        background: transparent;
        box-shadow: none; }
      &-content {
        position: relative;
        width: 100%;
        border-radius: 0;
        margin: 0;
        height: 90vh;
        padding: 64px 16px 20px 6px; } }

    @include mobile() {
      .modal__img,
      .modal__vid {
        margin-bottom: 60px; }
      .modal__vid {
        .btn-play {
          width: 48px;
          height: 48px;
          bottom: calc(50% - 24px);
          right: calc(50% - 24px);
          @include pulseCustom(0,95,191,primary); } }
      .modal-body {
        padding-left: 16px;
        padding-right: 16p; }
      .modal-content {
        padding-left: 0;
        padding-right: 0; }
      .close {
        top: 16px;
        right: 16px;
        span {
          display: none; } } } }
  &.modal--referral {
    &.show {
      display: flex!important;
      justify-content: center;
      .modal-content {
        padding: 0!important; } } } }
.fade {
  transition: opacity .15s linear; }

.load-more {
  display: none; }


// NEW JURNAL
.link-more {
  color: $blue;
  position: relative;
  font-size: 16px;
  margin-right: 26px;
  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: url(../images/ic-arrow-right.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    right: -27px;
    top: calc(50% - 10px); }
  &:hover {
    color: $blue;
    text-decoration: underline;
    &:after {
      @include bounce(); } }
  @include mobile {
    padding: 8px 0;
    display: inline-block; } }

.link-more-white {
  color: #FFF;
  position: relative;
  font-size: 16px;
  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: url(../images/ic-arrow-right-w.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    right: -27px;
    top: calc(50% - 10px); }
  &:hover {
    color: #FFF;
    text-decoration: underline;
    &:after {
      @include bounce(); } }
  @include mobile {
    padding: 8px 0;
    display: inline-block; } }

.link-external {
  color: $blue;
  position: relative;
  display: inline-flex;
  align-items: center;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10.8346 9.16675L17.668 2.33341M18.3346 5.66675V1.66675L14.3346 1.66675M9.16797 1.66675L7.5013 1.66675C3.33464 1.66675 1.66797 3.33341 1.66797 7.50008L1.66797 12.5001C1.66797 16.6667 3.33464 18.3334 7.5013 18.3334H12.5013C16.668 18.3334 18.3346 16.6667 18.3346 12.5001V10.8334" stroke="%234B61DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') center/contain no-repeat;
    margin-left: 8px; }
  &:hover {
    text-decoration: underline; } }


.header--landing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  padding: 30px 0; }


.section-head {
  padding-bottom: 48px;
  &.action {
    padding-top: 40px;
    .section-head__action {
      a {
        margin-right: 16px;
        &:last-child {
          margin-right: 0; }
        @include maxtablet() {
          margin-right: 0;
          margin-bottom: 12px;
          width: 100%;
          &:last-child {
            margin-bottom: 0; } } } } }
  &:not(.align-left) {
    text-align: center; }
  .section-head__title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0; } }
  .section-head__content {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0; } }
  .section-head__action {
    position: relative; }

  @include maxtablet {
    .section-head__title {
      font-size: 24px;
      line-height: 32px; } } }


@mixin bgCurveTop() {
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 10vw;
    background: url(../images/bg-shape-02.svg) no-repeat top center;
    background-size: 100% auto;
    position: absolute;
    top: -6vw;
    left: 0;
    right: 0;
    z-index: -1; } }

@mixin bgCurveBot() {
  position: relative;
  * {
    z-index: 2; }
  &:after {
    content: "";
    display: block;
    width: 100%;
    padding-top: 10vw;
    background: url(../images/bg-shape-01.svg) no-repeat top center;
    background-size: 100% auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1; } }


ul.custom-list-1 {
  display: block;
  width: 100%;
  &> li {
    position: relative;
    display: block;
    padding-left: 36px;
    margin-bottom: 16px;
    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background: url(../images/ic-check.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 2px; } } }

ul.custom-list {
  display: block;
  width: 100%;
  &.two-col {
    display: flex;
    flex-wrap: wrap;
    &> li {
      order: 2;
      width: 50%;
      @include mobilelg {
        width: 100%; } } }

  &> li {
    position: relative;
    display: block;
    padding-left: 32px;
    margin-bottom: 16px;
    h4,
    .title {
      display: block;
      font-size: 1rem;
      line-height: 1.5rem;
      @include font-500();
      margin-bottom: 4px; }
    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background: url(../images/ic-check.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      left: 1px;
      top: 3px; } }
  &.dark {
    &> li {
      margin-bottom: 28px;
      h4 {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 4px; }
      &::before {
        background: url(../images/ic-check-dark.svg) no-repeat center;
        background-size: contain; } } }
  &.white {
    &> li {
      margin-bottom: 28px;
      h4 {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 4px; }
      &::before {
        background: url(../images/ic-check-white.svg) no-repeat center;
        background-size: contain; } } }
  &.alt {
    &> li {
      &::before {
        background: url(../images/ic-check-alt.svg) no-repeat center;
        background-size: contain; } } }
  &.blue {
    &> li {
      &::before {
        background: url(../images/ic-check-blue.svg) no-repeat center;
        background-size: contain; } } }
  &.red {
    &> li {
      &::before {
        background: url(../images/ic-check-red.svg) no-repeat center;
        background-size: contain; } } }
  &.red-alt {
    &> li {
      &::before {
        background: url(../images/ic-check2.svg) no-repeat center;
        background-size: contain; } } }
  &.green-alt {
    &> li {
      &::before {
        background: url(../images/ic-check1.svg) no-repeat center;
        background-size: contain; } } }
  &.qmark {
    &> li {
      &::before {
        top: 4px;
        background: url(../images/ic-qmark.svg) no-repeat center;
        background-size: contain; } } }
  &.cross {
    &> li {
      &::before {
        top: 4px;
        background: url(../images/ic-cross.svg) no-repeat center;
        background-size: contain; } } }
  &.plus {
    &>li {
      &::before {
        top: 4px;
        background: url(../images/ic-plus-blue.svg) no-repeat center;
        background-size: contain; } } } }


img[data-src] {
  opacity: 1;
  transition: opacity .25s ease-in-out;
  will-change: opacity;

  &[src] {
    opacity: 1; } }

.badge {
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
  font-size: 14px;
  line-height: 20px;
  padding: 2px 8px;
  border-radius: 4px;
  background: $border;
  @include font-500;
  &--new {
    background: #FFB700;
    color: $black;
    padding: 3px 9px 1px;
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 12px;
      height: 12px;
      background-image: url(../images/ic-star.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin: 0 6px 2px 0; } }
  &--blue {
    background: #009BDE;
    color: $white; }
  &--yellow {
    background: #F4B94B;
    color: $white; }
  &--green {
    background: #14B8A6;
    color: $white; } }



.pill {
  display: inline-flex;
  background: $border;
  color: #FFF;
  @include font-400();
  font-size: 14px;
  line-height: 20px;
  color: #FFF;
  padding: 2px 6px;
  border-radius: 12px;
  &.blue {
    background: #009BDE; }
  &.red {
    background: #EF4444; } }

.slider-xs {
  .owl-dot {
    background: #eee!important;
    &.active {
      background: #009BDD!important; } } }

.hidden-sm {
  display: flex!important;
  @include maxtablet() {
    display: none!important; } }

.hidden-sm-b {
  display: block!important;
  @include maxtablet() {
    display: none!important; } }

.hidden-sm-ib {
  display: inline-block!important;
  @include maxtablet() {
    display: none!important; } }

.show-sm {
  display: none!important;
  @include maxtablet() {
    display: block!important; } }

.show-sm-ib {
  display: none!important;
  @include maxtablet() {
    display: inline-block!important; } }

.show-sm-f {
  display: none!important;
  @include maxtablet() {
    display: flex!important; } }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }

.fade.show {
  opacity: 1; }

.collapse {
  display: none; }

.collapse.show {
  display: block; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.pt-30 {
  padding-top: 30; }

.ic-wa {
  z-index: 99999;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 13px;
  right: 23px;
  pointer-events: none;
  &.show {
    pointer-events: auto; }
  // background-color: $mekarios
  // border-radius: 30px
  // padding: 12px
  img {
    z-index: 2;
    position: relative;
    display: block;
    height: 73px;
    width: 73px;
    min-width: 73px; }
  span {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #25d366;
    font-size: 12px;
    line-height: 14px;
    // padding: 8px 12px 8px 12px
    margin-left: -10px;
    border-radius: 0 20px 20px 0;
    color: #FFF;
    transition: all .3s ease;
    opacity: 0;
    font-size: 0;
    padding: 8px 0;
    max-width: 0; }

  &:hover {
    color: #FFF;
    text-decoration: none!important;
    span {
      font-size: 12px;
      padding: 8px 12px;
      opacity: 1;
      max-width: 110px;
      text-decoration: none!important; } }
  @include mobile {
    &--alt {
      right: 23px;
      bottom: 13px; }
    &:hover {
      span {
        display: none;
        opacity: 0; } } } }

.bg-white {
  background: #FFF!important; }

.bg-snow {
  background: #F2F4F7;
  @include mobile {
    background:#F2F4F7 {} } }

.bg-dark {
  background: #10253E;
  color: #FFF; }

.bg-half-snow {
  background-color: #F2F4F7;
  background-image: linear-gradient(#F2F4F7 50%, $white 0%); }

.mt-40 {
  margin-top: 40px; }

ol.list-number {
  li {
    list-style-type: decimal;
    margin-bottom: 20px; } }

.cta-waba {
  pointer-events: none;
  &.ic-wa {
    display: none; }
  &.show {
    pointer-events: auto;
    &.ic-wa {
      display: inline-flex; } } }




.row-reverse {
  flex-direction: row-reverse; }

.bg-gray {
  background: #F2F4F7; }

.link-show {
  color: #1357FF;
  position: relative;
  span {
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url(../images/ic-chev-down.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      right: -32px;
      top: calc(50% - 12px); } }
  &:hover {
    color: #1357FF;
    text-decoration: underline; } }

.link-close {
  color: #1357FF;
  position: relative;
  span {
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url(../images/ic-chev-up.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      right: -32px;
      top: calc(50% - 12px); } }
  &:hover {
    color: #1357FF;
    text-decoration: underline; } }

#masking {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999; }


.tooltip {
  position: absolute;
  z-index: 2;
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;

  &.show {
    opacity: 0.9; }

  .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; } } }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0; }

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #0055A2; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #0055A2; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0; }

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #0055A2; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #0055A2; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #0055A2;
  border-radius: 0.25rem; }


input[type="email"],#email {
  text-transform: lowercase; }


.modal-inquiry {
  .close {
    z-index: 2; }
  .modal-dialog {
    margin: 0 auto;
    max-width: 1224px;
    width: 100%; }
  .modal-content {
    padding: 0;
    overflow: hidden; } }


.inq-sidebar {
  padding: 64px 40px;
  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
    text-align: center;
    @include font-600(); }
  &__testimonial {
    background: #FFF;
    padding: 40px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); }
  &__quote {
    margin: 0 0 16px 0;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600; }
  &__icon {
    display: inline-block;
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid #E2E2E2;
    vertical-align: middle; }
  &__meta {
    display: inline-block;
    vertical-align: middle; }
  &__clients {
    margin: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    li {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
      display: flex;
      width: 114px;
      height: 100px;
      margin: 10px;
      align-items: center;
      justify-content: center; } }
  @include maxtablet {
    display: none; } }

.inq-form {
  padding: 64px 40px;
  &__title {
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
    @include font-600(); }
  .form-meeting__box {
    padding: 0; }
  @include maxtablet {
    padding: 30px 16px;
    &__title {
      font-size: 21px;
      line-height: 28px;
      margin-bottom: 20px; } } }

.pb-0 {
  padding-bottom: 0; }

.alert {
  position: relative;
  padding: 12px 16px;
  margin-bottom: 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  &-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  &.hide {
    display: none; } }

.btn-play--white {
  position: absolute;
  top: calc(50% - 50px);
  right: calc(50% - 50px);
  .bg {
    z-index: 2;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-image: url(../images/ic-play-blue.svg);
    background-size: 22px;
    background-color: #FFF;
    background-repeat: no-repeat;
    background-position: 53% 33%;
    position: relative;
    @include pulseBig(); }
  span {
    z-index: 4;
    display: block;
    width: 100%;
    font-size: 10px;
    line-height: 16px;
    color: $text-dark;
    position: absolute;
    bottom: 25%;
    left: 0;
    text-align: center; } }

.btn-play--blue {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  .bg {
    z-index: 2;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-image: url(../images/ic-play-white.svg);
    background-size: 22px;
    background-color: #4B61DD;
    background-repeat: no-repeat;
    background-position: 53% 33%;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    @include pulseBlue(); }
  span {
    z-index: 4;
    display: block;
    width: 100%;
    font-size: 10px;
    line-height: 16px;
    color: #FFF;
    position: absolute;
    bottom: 25%;
    left: 0;
    text-align: center; } }

#scroll-top {
  display: inline-block;
  background-color: #4B61DD;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50px;
  position: fixed;
  bottom: 98px;
  right: 26px;
  transition: background-color .1s, opacity .2s, visibility .2s;
  opacity: 0;
  visibility: hidden;
  z-index: 7;
  text-align: center;
  &::after {
    content: '';
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    background: url('../images/ic-chev-up-white.svg') no-repeat center;
    background-size: contain;
    margin: 10% auto; }
  &:hover {
    cursor: pointer;
    background-color: darken(#4B61DD, 10%); }
  &.alt {
    bottom: 30px; }

  &:active {
    background-color: darken(#4B61DD, 10%); }

  &.show {
    opacity: 1;
    visibility: visible; }
  @include maxtablet {
    bottom: 90px; } }

.padding-sm {
  padding: 32px 0!important; }

.media.media--video {
  position: relative; }

.nav-tabs.product-featured__tabs {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-end;
  // border-bottom: 1px solid $border
  max-width: 100%;
  overflow-y: auto;
  &::before {
    content: "";
    z-index: 1;
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $border; }
  .nav-link {
    display: block;
    color: $muted;
    text-decoration: none;
    background: #F9FAFB;
    padding: 8px 24px;
    border: 1px solid $border;
    border-radius: 0;
    .icon, span {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      pointer-events: none; }
    .icon {
      top: -1px;
      margin-right: 0.3rem;
      pointer-events: none;
      img {
        display: block;
        height: 1.25rem;
        width: auto;
        opacity: 1;
        transition: all .4s ease;
        pointer-events: none;
        &:nth-child(1) {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0; } } }
    .logo img {
      display: block;
      height: 40px;
      width: auto;
      opacity: 0.5;
      filter: grayscale(100%);
      transition: all .4s ease;
      pointer-events: none; }
    &:hover {
      color: $text;
      text-decoration: none;
      background: #FFFFFF;
      .icon img {
        &:nth-child(1) {
          opacity: 1; } }
      .logo img {
        opacity: 1;
        filter: grayscale(0); } }
    &.active {
      @include font-500();
      background: #FFFFFF;
      color: $text;
      padding-top: 12px;
      border-radius: 0.25rem 0.25rem 0 0;
      border-bottom-color: #FFFFFF;
      pointer-events: none;
      .icon img {
        &:nth-child(1) {
          opacity: 1; } }
      .logo img {
        opacity: 1;
        filter: grayscale(0); } } }
  .nav-item {
    z-index: 2;
    display: block;
    position: relative;
    margin-left: -1px; } }

@include maxtablet() {
  .nav-container {
    z-index: 20;
    padding-top: 100px;
    position: relative;
    border: 0;
    background-color: $white; }
  .nav-tabs {
    display: block;
    width: 100%;
    position: absolute;
    top: 24px;
    left: 0;
    overflow: visible;
    padding: 0 16px;
    padding-top: 56px;
    &:before {
      display: none; }
    .nav-item.active {
      position: absolute;
      top: 0;
      left: 16px;
      width: calc(100% - 32px);
      &:after {
        content: "";
        display: block;
        z-index: 2;
        position: absolute;
        width: 20px;
        height: 20px;
        top: calc(50% - 10px);
        right: 1rem;
        background-image: url(../images/ic-chevron-down.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; } }
    .nav-link {
      z-index: 1;
      position: relative;
      width: 100%;
      height: 52px;
      top: 1px;
      padding: 0;
      border: 0;
      opacity: 0;
      visibility: hidden;
      margin: 0 0 -1px;
      padding: 0 1rem;
      border-radius: 0;
      transition: all .4s ease;
      .logo {
        position: relative;
        top: -2px;
        img {
          height: 32px;
          width: auto; } }
      &.active {
        z-index: 2;
        position: relative;
        padding: 12px 1rem;
        border: 1px solid $border;
        max-height: 52px;
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
        border-radius: 0.25rem;
        .logo {
          img {
            height: 32px;
            width: auto; } } } }
    &.show {
      .nav-link {
        opacity: 1;
        visibility: visible;
        max-height: 52px;
        padding: 12px 1rem;
        border: 1px solid $border; } } } }

.breadcrumb {
  padding: 16px 0;
  border-top: 1px solid #D0D6DE;
  &__wrap {
    font-size: 12px;
    line-height: 16px;
    a {
      color: #4B61DD;
      text-decoration: underline;
      &:hover {
        color: darken(#4B61DD, 20%); }
      span.home {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        font-size: 0;
        line-height: 0;
        background: url(../images/logomark-mekari-jurnal.svg) no-repeat center;
        background-size: contain; } } } }

.pricing-clients {
  background: #F2F4F7;
  padding-top: 80px;
  padding-bottom: 58px;
  &__title, h2 {
    @include font-500;
    text-align: center;
    font-size: 32px;
    line-height: 48px;
    color: $black;
    margin: 0 0 24px;
    @include mobile {
      font-size: 1.5rem;
      line-height: 2rem; } }
  &.bg-white,
  &.white {
    .clients {
      background: #FFF; } }
  .clients {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -32px;
    justify-content: center;
    .item {
      display: flex;
      width: 100%;
      max-width: 16.666667%;
      flex: 0 0 16.666667%;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      margin-bottom: 48px;
      &.show {
        display: flex !important; }
      img {
        display: block;
        max-width: 100%;
        max-height: 80px;
        width: auto;
        height: auto!important; } }
    &--alt {
      justify-content: center;
      .item {
        max-width: calc(16.666667% * 2);
        flex: 0 0 calc(16.666667% * 2);
        padding: 0;
        display: flex;
        justify-content: space-around;
        text-align: center;
        margin-bottom: 0;
        img {
          padding: 0 21px;
          display: inline-block;
          margin-bottom: 48px; } } } }

  .clients-more {
    text-align: center;
    margin-bottom: 24px; }
  @include maxtablet {
    h2 {
      font-size: 26px;
      line-height: 32px; }
    .clients {
      .item {
        max-width: 33.333%;
        flex: 33.333%;
        padding: 0 12px;
        img {
          height: 70px; } }
      &--alt {
        margin-left: 0;
        padding-bottom: 70px;
        .item {
          max-width: 100%;
          flex: 100%;
          padding: 0;
          display: block;
          img {
            height: auto;
            max-width: 100%;
            padding: 0 10px;
            margin-bottom: 16px; } } } }
    &.promo {
      padding-top: 65px;
      padding-left: 8px;
      padding-right: 8px; }
    .slider-clients {
      &.owl-carousel {
        .owl-dots {
          display: block;
          width: 100%;
          text-align: center;
          .owl-dot {
            display: inline-block;
            margin: 0 6px;
            width: 30px;
            height: 5px;
            border-radius: 5px;
            background: #e3e3e8;
            &.active {
              background: #005FBF; } } } } } } }


.section-center {
  text-align: center; }

.section__subtitle,
.section__meta {
  @include font-600();
  font-size: 16px;
  line-height: 24px;
  @include maxtablet() {
    br {
      display: none; } } }

.section__title {
  @include font-600();
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 8px;
  @include maxtablet() {
    font-size: 28px;
    line-height: 36px;
    br {
      display: none; } } }

.section__desc {
  margin-bottom: 24px;
  a {
    color: #4B61DD;
    text-decoration: underline;
    &:hover {
      color: darken(#4B61DD, 20%);
      text-decoration: none; } }
  @include maxtablet() {
    br {
      display: none; } } }

.section__action {
  a {
    margin-right: 16px;
    &:last-child {
      margin-right: 0; } }
  @include maxtablet() {
    margin-bottom: 24px;
    a {
      width: 100%;
      display: block;
      margin-right: 0;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0; }
      &.link-more {
        display: inline; } } } }


ul.disc {
  list-style: disc;
  padding-left: 16px; }


.warning {
  padding: 12px 16px 12px 52px;
  background: #EAECFB;
  border-radius: 6px;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  &::before {
    content: '';
    width: 24px;
    height: 24px;
    background: url(../images/ic-info-blue.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    left: 16px;
    top: 10px; }
  a {
    color: #4B61DD;
    text-decoration: underline;
    &:hover {
      color: darken(#4B61DD, 20%);
      text-decoration: none; } } }


.flatpickr-calendar {
  .dropdown.bootstrap-select {
    position: inherit;
    button {
      border: none;
      font-size: 16px;
      line-height: 28px;
      &:after {
        top: 1px; } } } }


.mpay-footer-top {
  text-align: center;
  background: #FFF;
  padding-top: 64px;
  padding-bottom: 64px;
  &__logomark {
    margin-bottom: 16px; }
  &__title {
    margin-bottom: 20px; }
  &__desc {
    margin: 0 auto 32px;
    max-width: 600px; }
  &__buttons {
    .btn {
      margin-left: 6px;
      margin-right: 6px; } }
  @include maxtablet() {
    padding: 32px 8px;
    &__buttons {
      .btn {
        margin-left: 0;
        margin-right: 0; } } } }

// PRICING REVAMP
// .top-banner
//   background: linear-gradient(90deg, #3A00A9 1.56%, #7B3CEF 44.12%, #3A00A9 68.21%, #7B3CEF 92.31%)
//   margin-top: 115px
//   display: block
//   a.container
//     display: block
//   &__img
//     img
//       display: inline-flex
//       width: auto
//       height: auto
//       max-height: 64px
//   &__meta
//     color: #FFF
//     font-size: 18px
//     line-height: 1.5em
//     +font-500()
//   &__title
//     color: #FFF
//     font-size: 28px
//     line-height: 1.5em
//     +font-600()
//   &__desc
//     color: #FFF
//     p
//       font-size: 14px
//       line-height: 20px
//     span.link
//       font-size: 15px
//       line-height: 22px
//       font-weight: 500
//       text-decoration: underline
//       &:hover
//         color: darken(#FFF, 10%)
//   &__content
//     padding-top: 14px
//     padding-bottom: 14px
//     text-align: center
//   &__buttons
//     padding-top: 20px
//     padding-bottom: 20px
//     .btn
//       padding: 11px 24px
//       font-size: 16px
//       line-height: 24px
//     .btn-white
//       border-color: #FFF
//   +maxtablet
//     margin-top: 56px
//     &__img
//       display: none
//     &__meta
//       font-size: 16px
//       line-height: 24px
//       margin-bottom: 8px
//     &__title
//       font-size: 28px
//       line-height: 36px
//       margin-bottom: 12px
//     &__content
//       padding-top: 28px
//     &__buttons
//       text-align: center
//       padding-top: 0
//       padding-bottom: 28px
//       .btn
//         display: inline-block
//         width: auto
//         margin-bottom: 0

.tbs {
  z-index: 20;
  display: block;
  background: linear-gradient(180deg, #40c3ff 50%, #0080d9 100%);
  color: #FFF;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: all .4s ease;
  opacity: 0;
  &-bot {
    display: block;
    background: url(../images/sticky-speakers.svg) no-repeat;
    background-position: center right;
    background-size: contain;
    .row {
      min-height: 120px; } }
  &.is-ready {
    opacity: 1; }
  &__wrap {
    padding: 4px 0;
    min-height: 36px;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    display: flex;
    width: 100%; }
  &__img {
    display: inline-block;
    text-align: left;
    img {
      width: 100%;
      height: auto;
      margin-top: -5px; } }
  &__content {
    display: block;
    text-align: center;
    color: #FFF;
    span {
      display: inline-block;
      vertical-align: middle; }
    span.large {
      font-size: 18px;
      line-height: 1.5em;
      @include font-600(); } }
  &__cta {
    display: block;
    margin-left: auto;
    .link {
      text-decoration: underline;
      font-size: 13px;
      line-height: 1.5em;
      &:hover {
        color: darken(#FFF, 10%); } }
    .btn-dummy {
      color: #fff;
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 20px;
        background: url(../images/ic-arrow-right-long-white.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        right: -32px;
        top: calc(50% - 10px); }
      &:hover {
        color: #fff;
        text-decoration: underline;
        &:after {
          @include bounce(); } } }
    // .btn-dummy
    //   display: inline-block
    //   font-size: 16px
    //   line-height: 34px
    //   background: #FFF
    //   border: 1px solid #FFF
    //   color: $primary
    //   border-radius: 4px
    //   padding: 0 24px
    //   margin: 2px
    //   +font-500
    //   transition: all .3s ease
    //   &:hover
    //     background: $primary
 }    //     color: #FFF
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 64px;
    height: 30px;
    top: 0;
    right: 0;
    span {
      display: flex;
      height: 16px;
      width: 16px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      background: #FFF;
      color: $primary; } }
  &.fixed {
    top: 57px;
    opacity: 1;
    &.show-top {
      top: 97px; } }

  @include maxtablet {
    top: 0;
    &-bot {
      display: none; }
    &> .container {
      &> .row {
        min-height: 50px; } }
    &__wrap {
      padding-left: 0;
      padding-right: 0; }
    &__content {
      span {
        display: block; }
      span.meta {
        font-size: 12px;
        line-height: 16px;
        display: block; }
      span.large {
        margin-left: 0;
        font-size: 14px;
        line-height: 1.25rem; } }
    &__cta {
      min-width: 200px; }
    &__close {
      align-items: flex-start;
      justify-content: flex-end;
      width: 40px;
      height: 40px;
      padding: 12px;
      span {
        height: 14px;
        width: 14px;
        font-size: 18px; } }
    &.fixed {
      top: 56px;
      &.show-top {
        top: 56px; } } }
  @include mobile {
    &__wrap {
      flex-wrap: wrap; }
    &__content {
      text-align: center;
      width: 100%; }
    &__cta {
      text-align: center;
      max-width: 100%;
      width: 100%; } } }

.top-banner--alt .tb__close {
  position: fixed;
  right: 0;
  top: 8px;
  padding: 8px;
  z-index: 4;
  cursor: pointer;
  display: none;

  span {
    font-size: 24px;
    line-height: 24px;
    color: #000;
    border-radius: 8px;
    display: block;
    width: 16px;
    height: 16px;
    text-align: center; } }

@media (max-width: 991px) {
  .top-banner--alt .tb__close {
    top: 6px;
    right: 2px; } }

@media (max-width: 479px) {
  .top-banner--alt .tb__close {
    top: 10px;
    right: -2px; } }

.top-banner-sticky {
  z-index: 879;
  position: absolute;
  display: none;
  text-decoration: none;
  color: #232933;
  width: 100%;
  background: #EDFAFF;
  opacity: 0;
  transition: opacity linear .2s;
  &:hover {
    color: #232933;
    text-decoration: none; }

  .tb__logo {
    display: none;
    width: 100%;
    max-width: 13%;
    -webkit-flex: 0 0 13%;
    -ms-flex: 0 0 13%;
    flex: 0 0 13%;

    img {
      display: block;
      height: 24px;
      width: auto; } }

  .tb__text {
    display: block;
    width: 100%;
    max-width: 70%;
    -webkit-flex: 0 0 70%;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    text-align: left;

    span {
      &.sub, &.sub-sm {
        display: none; }

      &.title {
        display: block;
        font-size: 15px;
        line-height: 24px;
        font-family: "Inter", sans-serif;
        font-weight: 400;

        b {
          font-family: "Inter", sans-serif;
          font-weight: 400; } }



      &.title-sm {
        display: none;
        font-size: 28px;
        line-height: 1.5em;
        font-family: "Inter", sans-serif;
        font-weight: 600; } } }

  .tb__action {
    display: block;
    width: 100%;
    max-width: 25%;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    text-align: right;
    margin-left: auto;

    span.link {
      display: inline-block;
      text-decoration: underline;
      color: #232933;
      position: relative;
      font-size: 14px;
      line-height: 20px;
      font-family: "Inter", sans-serif;
      font-weight: 600;

      &:hover {
        color: #232933;
        text-decoration: underline; }

      &:after {
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-name: bounce2;
        animation-name: bounce2;
        -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
        animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); } } }

  .tb__wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 64px; }

  .tb__close {
    position: absolute;
    right: 12px;
    top: 0;
    padding: 8px;
    display: none;

    span {
      font-size: 16px;
      line-height: 16px;
      background: #FFF;
      color: #000;
      border-radius: 8px;
      display: block;
      width: 16px;
      height: 16px;
      text-align: center; } } }

@media (max-width: 991px) {
  .top-banner-sticky {
    opacity: 1;

    .tb__logo {
      display: none; }

    .tb__action {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      max-width: 100%;
      width: 100%;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      text-align: center;

      span.link {
        font-size: 14px;
        line-height: 20px; } }

    .tb__text {
      max-width: 100%;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;

      span {
        &.sub {
          display: none;
          font-size: 10px;
          line-height: 12px; }

        &.sub-sm {
          display: block;
          font-size: 12px;
          line-height: 16px; }

        &.title {
          display: none;
          br {
            display: none; } }

        &.title-sm {
          display: block;
          font-size: 13px;
          line-height: 20px;
          padding-right: 20px;
          text-align: center;
          font-family: "Inter", sans-serif;
          font-weight: 400; } } }

    .tb__close {
      right: 0;
      top: calc( 50% - 15px);

      span {
        font-size: 16px;
        line-height: 14px;
        background: #FFF;
        color: #000;
        border-radius: 7px;
        display: block;
        width: 14px;
        height: 14px;
        text-align: center; } }

    .tb__wrap {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      height: 48px;
      padding: 4px 0; } } }

@media (max-width: 479px) {
  .top-banner-sticky .tb__action span.link {
    font-size: 12px;
    line-height: 20px; } }

.top-banner-bot {
  display: block;
  text-decoration: none;
  color: #232933;
  width: 100%;
  background: #EDFAFF;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  &:hover {
    color: #232933;
    text-decoration: none; }

  .tb__logo {
    display: none;
    width: 100%;
    max-width: 16.666667%;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;

    img {
      display: block;
      height: 64px;
      width: auto; } }

  .tb__text {
    display: block;
    width: 100%;
    max-width: 65%;
    -webkit-flex: 0 0 65%;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-left: 64px;

    span {
      &.sub {
        display: block;
        font-size: 18px;
        line-height: 1.5em;
        font-family: "Inter", sans-serif;
        font-weight: 500; }

      &.sub-sm {
        display: none; }

      &.title {
        display: block;
        font-size: 21px;
        line-height: 28px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        margin: 0 0 12px;

        b {
          font-family: "Inter", sans-serif;
          font-weight: 600; } } } }

  .tb__action span.link {
    display: inline-block;
    text-decoration: underline;
    color: #232933;
    position: relative;
    padding-right: 24px;
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter", sans-serif;
    font-weight: 600;

    &:hover {
      color: #232933;
      text-decoration: underline; }

    &:after {
      -webkit-animation-duration: 2s;
      animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-name: bounce2;
      animation-name: bounce2;
      -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
      animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); } }

  .tb__img {
    z-index: 1;
    display: block;
    position: absolute;
    width: 548px;
    height: 100%;
    top: 0;
    left: 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right bottom; }

  .tb__wrap {
    z-index: 2;
    position: relative;
    padding-top: 16px;
    padding-bottom: 16px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 144px; } }

@include xsdesktop() {
  .top-banner-bot {
    z-index: 101; } }


@include maxtablet() {
  .top-banner-bot {
    background: linear-gradient(90.02deg, #3A00A9 -8.78%, #7B3CEF 26.34%, #3A00A9 64.86%, #7B3CEF 99.98%);
    margin-bottom: 0;
    display: none;
    z-index: 101;

    .tb__logo, .tb__img {
      display: none; }

    .tb__text {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      max-width: 100%;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;

      span {
        &.sub {
          display: none;
          font-size: 12px;
          line-height: 1.5em; }

        &.sub-sm {
          display: block;
          font-size: 16px;
          line-height: 1.5em; }

        &.title {
          font-size: 18px;
          line-height: 1.5em;
          margin-bottom: 8px; }

        &.link {
          font-size: 14px;
          line-height: 1.5em; } } }

    .tb__wrap {
      padding-top: 16px;
      padding-bottom: 16px; } } }

.top-banner--alt {
  .top-banner-sticky {
    background: #EDFAFF;
    z-index: 3;

    &.img-md {

      .tb__wrap {
        .tb__logo {
          width: 180px;
          height: 64px;
          flex: 0 0 180px;
          background-size: contain;
          max-width: 180px; }

        .tb__text {
          max-width: calc(100% - (180px + 265px));
          flex: 0 0 calc(100% - (180px + 265px));
          span {
            &.meta {
              &> img {
                max-height: 20px;
                width: auto; } }
            &.title {
              font-size: 16px;
              line-height: 24px;
              &> img {
                max-width: 112px; } } } }
        .tb__action {
          width: auto;
          flex: 0 0 auto;
          span.link {
            display: inline-flex;
            align-items: center;
            font-size: 16px;
            line-height: 24px; } } }

      &.mcon {
        .tb__wrap {
          .tb__logo {
            width: 190px;
            flex: 0 0 190px;
            max-width: 100%;
            height: 64px; } }
        .tb__text {
          max-width: calc(100% - (190px + 265px));
          flex: 0 0 calc(100% - (190px + 265px));
          span {
            &.title {
              font-size: 20px;
              line-height: 28px;
              color: #FFF; }
            &.meta {
              color: #FFF;
              font-size: 16px;
              line-height: 24px; } } }
        .tb__action {
          width: auto;
          flex: 0 0 auto;
          span.link {
            display: inline-block;
            color: #232933;
            font-size: 16px;
            line-height: 24px;
            @include font-600();
            padding: 8px 16px;
            background-color: #ffed51;
            border-radius: 4px;
            &::after {
              content: "";
              display: inline-block;
              vertical-align: middle;
              width: 20px;
              height: 20px;
              background: url(../images/ic-arrow-right.svg) no-repeat center;
              background-size: contain;
              position: relative;
              top: -2px;
              margin-left: 8px;
              filter: grayscale(100%) brightness(0.5);
              @include bounce(); }
            &:hover {
              text-decoration: none; } } } } }


    &.ebook {
      .tb__wrap {
        .tb__logo {
          height: 100%; }
        .tb__text {
          max-width: calc(100% - (180px + 265px));
          flex: 0 0 calc(100% - (180px + 265px)); } } }

    .tb__wrap {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 32px;
      flex-wrap: wrap;
      justify-content: space-between;

      .tb__logo {
        display: block;
        width: 100%;
        max-width: 90px;
        flex: 0 0 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }

      .tb__text {
        padding: 6px 0;
        text-align: left;
        width: 100%;
        flex: 0 0 100%;
        max-width: calc(100% - (90px + 250px + 64px));
        span {
          &.title {
            font-size: 20px;
            line-height: 28px;
            font-family: "Inter", sans-serif;
            font-weight: 600;

            br {
              display: none; } }

          &.meta-sm, &.action {
            display: none; } } }

      .tb__action {
        max-width: 250px;
        width: 100%;
        flex: 0 0 100%;
        display: flex;
        justify-content: flex-end; }

      .tb__action span.link {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        text-decoration: none;
        color: #4B61DD;
        position: relative;
        font-size: 16px;
        line-height: 24px;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 4px;

        &:after {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../images/ic-arrow-right.svg) no-repeat center;
          background-size: contain; }

        &:hover {
          color: #4B61DD;
          text-decoration: underline;

          &:after {
            -webkit-animation-duration: 2s;
            animation-duration: 2s;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-name: bounce2;
            animation-name: bounce2;
            -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
            animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); } } } } }

  .top-banner-bot {
    background: #EDFAFF;

    &.img-md {
      .tb__wrap {
        .tb__text {
          max-width: calc(100% - (300px + 225px));
          span {
            &.title {
              font-size: 20px;
              line-height: 28px; } } }

        .tb__img {
          max-width: 300px;
          width: 100%;
          padding: 0;
          margin-right: 57px;
          img {
            height: 100%;
            width: auto;
            max-width: 300px;
            max-height: 144px; } } }

      &.mcon {
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-image: url(../images/banner-tbmcon-l.webp);
          background-size: auto 100%;
          background-position: left top;
          background-repeat: no-repeat; }
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-image: url(../images/banner-tbmcon-r.webp);
          background-size: auto 100%;
          background-position: right top;
          background-repeat: no-repeat; }
        .tb__wrap {
          .tb__text {
            span {
              &.title {
                color: #fff;
                font-size: 22px;
                line-height: 150%; }
              &.meta,
              &.meta-sm {
                color: #FFF; }
              &.action {
                span.link {
                  display: inline-block;
                  color: #232933;
                  font-size: 16px;
                  line-height: 24px;
                  @include font-600();
                  padding: 8px 16px;
                  background-color: #ffed51;
                  border-radius: 4px;
                  &::after {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 20px;
                    height: 20px;
                    background: url(../images/ic-arrow-right.svg) no-repeat center;
                    background-size: contain;
                    position: relative;
                    top: -2px;
                    margin-left: 8px;
                    filter: grayscale(100%) brightness(.5);
                    @include bounce(); } } } } }
          .tb__action {
            span.link {
              display: inline-block;
              color: #232933;
              font-size: 16px;
              line-height: 24px;
              @include font-600();
              padding: 8px 16px;
              background-color: #ffed51;
              border-radius: 4px;
              &::after {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 20px;
                height: 20px;
                background: url(../images/ic-arrow-right.svg) no-repeat center;
                background-size: contain;
                position: relative;
                top: -2px;
                margin-left: 8px;
                filter: grayscale(100%) brightness(.5);
                @include bounce(); }
              &:hover {
                text-decoration: none; } } } } } }
    &.ebook {
      .tb__wrap {
        .tb__img {
          width: 180px;
          padding-top: 0;
          padding-bottom: 0;
          min-height: 144px;
          display: flex;
          align-items: flex-end; }
        .tb__text {
          margin-left: 57px;
          flex: 0 0 calc(100% - (245px + 225px));
          max-width: calc(100% - (245px + 225px));
          span {
            &.title {
              font-size: 20px;
              line-height: 28px;
              br {
                display: unset; } } } }
        .tb__action {
          min-width: 245px; } } }

    .tb__wrap {
      padding-top: 0;
      padding-bottom: 0;
      position: relative;

      &::before {
        content: '';
        display: none;
        width: 173px;
        height: 100%;
        background: url(../images/ornament-bg-banner.png) no-repeat;
        background-position: left center;
        background-size: contain;
        position: absolute;
        left: -30px;
        top: 0; }

      .tb__img {
        position: relative;
        width: auto;
        height: 100%;
        padding-top: 3.6px;
        padding-bottom: 3.6px;
        top: unset;
        left: unset; }

      .tb__text {
        text-align: left;
        margin: 0 auto;
        padding: 12px 0;

        span {
          &.title {
            font-size: 32px;
            line-height: 48px;
            margin-bottom: 0;
            font-family: "Inter", sans-serif;
            font-weight: 600; }

          &.meta {
            font-size: 16px;
            line-height: 24px;

            p {
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 0; }

            small {
              font-size: 14px;
              line-height: 20px; } }

          &.link {
            font-family: "Inter", sans-serif;
            font-weight: 400;
            text-decoration: none;
            color: #4B61DD;
            position: relative;
            font-size: 16px;
            line-height: 24px;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 4px;

            &:after {
              content: "";
              display: block;
              width: 20px;
              height: 20px;
              background: url(../images/ic-arrow-right.svg) no-repeat center;
              background-size: contain; }

            &:hover {
              color: #4B61DD;
              text-decoration: underline;

              &:after {
                -webkit-animation-duration: 2s;
                animation-duration: 2s;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
                -webkit-animation-name: bounce2;
                animation-name: bounce2;
                -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
                animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); } } }

          &.title-sm, &.meta-sm, &.action {
            display: none; } } }

      .tb__action {
        min-width: 200px;
        -webkit-flex: 0 0 200px;
        -ms-flex: 0 0 200px;
        flex: 0 0 200px;

        span.link {
          font-family: "Inter", sans-serif;
          font-weight: 400;
          text-decoration: none;
          color: #4B61DD;
          position: relative;
          font-size: 16px;
          line-height: 24px;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 4px;
          padding-right: 0;

          &:after {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background: url(../images/ic-arrow-right.svg) no-repeat center;
            background-size: contain; }

          &:hover {
            color: #4B61DD;
            text-decoration: underline;

            &:after {
              -webkit-animation-duration: 2s;
              animation-duration: 2s;
              -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
              -webkit-animation-name: bounce2;
              animation-name: bounce2;
              -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
              animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); } } } } }

    .tb__close {
      position: absolute;
      display: none; } } }

@media screen and (max-width: 1200px) {
  .top-banner--alt {

    .top-banner-sticky {
      &.img-md {
        .tb__wrap {
          justify-content: space-between;
          .tb__logo {
            display: none; }

          .tb__text {
            max-width: calc(100% - 355px);
            flex: 0 0 calc(100% - 355px);
            span {
              &.title {
                font-size: 16px;
                line-height: 24px;
                &> img {
                  max-width: 112px; } } } }

          .tb__action {
            .link {
              justify-content: flex-end; } } }
        &.mcon {
          .tb__wrap {
            .tb__text {
              span {
                &.title {
                  font-size: 18px;
                  line-height: 26px; } } } } } }

      &.ebook {
        .tb__wrap {
          .tb__logo {
            display: none; }
          .tb__text {
            span {
              &.title {
                font-size: 18px;
                line-height: 26px; } } }
          .tb__action {
            max-width: 30%;
            flex: 0 0 30%; } } } }

    .top-banner-bot {
      &.img-md {
        .tb__wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .tb__text {
            max-width: calc(100% - 225px);
            margin: 0;
            span {
              &.title {
                font-size: 20px;
                line-height: 28px; } } }

          .tb__img {
            display: none; }

          .tb__action {
            display: flex;
            justify-content: flex-end; } } }

      &.ebook {
        .tb__wrap {
          .tb__text {
            span {
              &.title {
                br {
                  display: none; } } } } } } } } }


@media screen and (max-width: 1024px) {
  .top-banner--alt {
    .top-banner-sticky {

      &.img-md {
        .tb__wrap {
          .tb__text {
            max-width: 70%;
            flex: 0 0 70%; }
          .tb__action {
            max-width: auto;
            flex: 0 0 auto; } }
        &.mcon {
          .tb__action {
            max-width: auto;
            flex: 0 0 auto; } } }



      &.ebook {
        .tb__wrap {
          .tb__text {
            margin-left: 24px; } } }

      .tb__wrap {
        .tb__logo {
          display: none; } } }

    .top-banner-bot {
      z-index: 101;

      &.img-md {
        &.mcon {
          &::before {
            background-image: url(../images/banner-tbmcon-r-sm.webp); }
          &::after {
            background-image: url(../images/banner-tbmcon-l-sm.webp); }
          .tb__wrap {
            .tb__text {
              text-align: center;
              max-width: 100%;
              flex: 0 0 100%;
              width: 100%;
              span {
                &.title {
                  font-size: 32px;
                  line-height: 48px; }
                &.meta {
                  display: none; }
                &.meta-sm,
                &.action {
                  display: block; }
                &.action {
                  margin-top: 8px; } } }
            .tb__action {
              display: none; } } } }

      .tb__wrap {
        max-width: 100%; }

      .tb__text {
        max-width: 55%;
        -webkit-flex: 0 0 55%;
        -ms-flex: 0 0 55%;
        flex: 0 0 55%; } } } }

@media screen and (max-width: 991px) {
  .top-banner--alt {

    .top-banner-sticky {

      &.img-md {
        .tb__wrap {
          .tb__text {
            padding-left: 0; }
          .tb__action {
            max-width: auto;
            flex: 0 0 auto; } }

        &.mcon {
          .tb__text {
            span {
              &.title-sm,
              &.meta-sm {
                color: #FFF; } } }
          .tb__action {
            max-width: auto;
            flex: 0 0 auto;
            span.link {
              padding: 0;
              background: transparent;
              color: #fff;
              &::after {
                filter: invert(100%) brightness(1000%); } } } } }



      &.ebook {
        .tb__wrap {
          .tb__logo {
            display: none; }
          .tb__text {
            padding-left: 0;
            margin-left: 0;
            span {
              &.title-sm {
                font-size: 16px;
                line-height: 24px;
                text-align: left; } } } } }

      .tb__wrap {
        max-width: 100%;
        flex: 0 0 100%;
        flex-wrap: wrap;
        gap: 8px;
        padding: 0 16px;
        height: auto;

        .tb__text {
          // display: -webkit-flex
          // display: -ms-flexbox
          // display: flex
          // text-align: left
          // -webkit-flex-direction: column
          // -ms-flex-direction: column
          // flex-direction: column
          // -webkit-justify-content: center
          // -ms-flex-pack: center
          // justify-content: center
          // -webkit-align-items: flex-start
          // -ms-flex-align: start
          // align-items: flex-start
          // width: auto
          // -webkit-flex: unset
          // -ms-flex: unset
          // flex: unset
          // padding: 0 0 0 32px

          span {
            &.title, &.meta {
              display: none;
              br {
                display: none; } }

            &.title-sm {
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              font-size: 16px;
              line-height: 24px;
              font-family: "Inter", sans-serif;
              font-weight: 600; }

            &.meta-sm {
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              font-size: 14px;
              line-height: 20px; } } }

        .tb__action {
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin-left: auto;
          width: auto;
          -webkit-flex: unset;
          -ms-flex: unset;
          flex: unset;

          span.link {
            padding-right: 24px; } } }

      .tb__close {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: none; } }

    .top-banner-bot {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      z-index: 101;

      &.img-md {
        .tb__wrap {
          .tb__text {
            flex: 0 0 100%;
            padding-left: 24px;
            padding-right: 24px;
            max-width: 100%;
            span {
              &.meta {
                display: none; }
              &.meta-sm {
                display: block; } } }

          .tb__action {
            display: none; } }
        &.mcon {
          .tb__wrap {
            .tb__text {
              span {
                &.title {
                  font-size: 20px;
                  line-height: 28px; } } } } } }

      &.ebook {
        .tb__wrap {
          .tb__text {
            max-width: calc(100% - (180px + 155px));
            flex: 0 0 calc(100% - (180px + 155px)); } } }


      .tb__wrap {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .tb__img {
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin-right: 32px;
          -webkit-flex: 0 0 165px;
          -ms-flex: 0 0 165px;
          flex: 0 0 165px;

          img {
            object-fit: contain; } }

        .tb__text {
          -webkit-flex: 0 0 calc(100% - 200px);
          -ms-flex: 0 0 calc(100% - 200px);
          flex: 0 0 calc(100% - 200px);
          max-width: calc(100% - 200px);
          padding-right: 24px;

          span.action {
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            margin-top: 8px; } }

        .tb__action {
          display: none; } } } } }

// @media screen and (max-width: 906px)
//   .top-banner--alt
//     .top-banner-sticky
//       &.ebook
//         .tb__wrap
//           .tb__action
//             max-width: 30%
//             flex: 0 0 30%


@media screen and (max-width: 906px) {

  .top-banner--alt {
    .top-banner-sticky {
      &.ebook {
        .tb__wrap {
          .tb__text {
            flex: 0 0 calc(100% - (180px + 180px));
            max-width: calc(100% - (180px + 180px));
            span {
              &.title-sm {
                font-size: 16px;
                line-height: 24px; } } }
          .tb__action {
            max-width: 35%;
            flex: 0 0 35%; } } } } } }

@media screen and (max-width: 790px) {

  .top-banner--alt {
    .top-banner-sticky {
      &.ebook {
        .tb__wrap {
          .tb__text {
            span {
              &.title-sm {
                font-size: 16px;
                line-height: 24px; } } }
          .tb__action {
            span.link {
              font-size: 14px;
              line-height: 20px; } } } } } } }

// @media screen and (max-width: 700px)

//   .top-banner--alt
//     .top-banner-sticky
//       &.ebook
//         .tb__wrap
//           .tb__text
//             flex: 0 0 calc(100% - (180px + 65px))
//             max-width: calc(100% - (180px + 65px))

//           .tb__action
//             display: none

// @media screen and (max-width: 768px)
//   .top-banner--alt
//     .top-banner-sticky
//       .tb__wrap
//         .tb__text
//           max-width: 100%

//           .action
//             .link
//               font-family: "Inter", sans-serif
//               font-weight: 400
//               text-decoration: none
//               color: #4B61DD
//               position: relative
//               font-size: 14px
//               line-height: 20px
//               display: -webkit-flex
//               display: -ms-flexbox
//               display: flex
//               -webkit-align-items: center
//               -ms-flex-align: center
//               align-items: center
//               gap: 4px

//               &:after
//                 content: ""
//                 display: block
//                 width: 20px
//                 height: 20px
//                 background: url(../images/ic-arrow-right.svg) no-repeat center
//                 background-size: contain

//               &:hover
//                 color: #4B61DD
//                 text-decoration: underline

//                 &:after
//                   -webkit-animation-duration: 2s
//                   animation-duration: 2s
//                   -webkit-animation-iteration-count: infinite
//                   animation-iteration-count: infinite
//                   -webkit-animation-name: bounce2
//                   animation-name: bounce2
//                   -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1)
//                   animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1)
//         .tb__action
//           display: none

// @media screen and (max-width: 700px)
//   .top-banner--alt

//     .top-banner-sticky
//       &.ebook
//         .tb__action
//           display: none
//         .tb__wrap
//           .tb__text
//             flex: 0 0 100%
//             max-width: 100%
//             span
//               &.action
//                 display: flex
//                 margin-top: 5px
//                 .link
//                   font-family: "Inter", sans-serif
//                   font-weight: 400
//                   text-decoration: none
//                   color: #4B61DD
//                   position: relative
//                   font-size: 14px
//                   line-height: 20px
//                   display: -webkit-flex
//                   display: -ms-flexbox
//                   display: flex
//                   -webkit-align-items: center
//                   -ms-flex-align: center
//                   align-items: center
//                   gap: 4px

//                   &:after
//                     content: ""
//                     display: block
//                     width: 20px
//                     height: 20px
//                     background: url(../images/ic-arrow-right.svg) no-repeat center
//                     background-size: contain

//                   &:hover
//                     color: #4B61DD
//                     text-decoration: underline

//                     &:after
//                       -webkit-animation-duration: 2s
//                       animation-duration: 2s
//                       -webkit-animation-iteration-count: infinite
//                       animation-iteration-count: infinite
//                       -webkit-animation-name: bounce2
//                       animation-name: bounce2
//                       -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1)
//                       animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1)



//     .top-banner-bot
//       &.ebook
//         .tb__wrap
//           .tb__img
//             display: none
//           .tb__text
//             max-width: 100%
//             flex: 0 0 100%
//             margin-left: 0
//             margin-right: 0
//             text-align: center
//             padding-left: 0
//             padding-right: 0
//             span
//               &.action
//                 justify-content: center

@media screen and (max-width: 768px) {
  .top-banner--alt {
    .top-banner-sticky {
      top: -76px;

      &.img-md {
        .tb__wrap {
          .tb__text {
            max-width: auto;
            flex: 0 0 auto;
            width: auto;
            text-align: left;
            span {
              &.title-sm,
              &.meta-sm {
                text-align: left;
                justify-content: flex-start;
                flex: 0 0 100%;
                width: 100%; }
              &.action {
                display: none; } } }
          .tb__action {
            max-width: auto;
            flex: 0 0 auto;
            display: flex; } }
        &.mcon {
          .tb__text {
            max-width: auto;
            flex: 0 0 auto;
            width: auto;
            text-align: left;
            span {
              &.title-sm,
              &.meta-sm {
                text-align: left;
                justify-content: flex-start;
                flex: 0 0 100%;
                width: 100%; }
              &.action {
                display: none; } } }
          .tb__action {
            max-width: auto;
            flex: 0 0 auto;
            display: flex; } } }

      &.ebook {
        .tb__wrap {
          .tb__text {
            span {
              &.title-sm {
                text-align: center;
                font-size: 16px;
                line-height: 24px; } } } } }
      .tb__wrap {
        height: 100%;

        .tb__logo {
          display: none; }

        .tb__text {
          max-width: 100%;
          -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          text-align: left;
          padding-left: 0;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;

          span {
            &.title {
              display: none;

              b {
                font-family: "Inter", sans-serif;
                font-weight: 600; } }

            &.title-sm {
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              font-family: "Inter", sans-serif;
              font-weight: 600;

              b {
                font-family: "Inter", sans-serif;
                font-weight: 600; } }

            &.meta-sm {
              text-align: center; }

            &.action {
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              margin-top: 5px;
              .link {
                font-family: "Inter", sans-serif;
                font-weight: 400;
                text-decoration: none;
                color: #4B61DD;
                position: relative;
                font-size: 16px;
                line-height: 24px;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                gap: 4px;

                &:after {
                  content: "";
                  display: block;
                  width: 20px;
                  height: 20px;
                  background: url(../images/ic-arrow-right.svg) no-repeat center;
                  background-size: contain; }

                &:hover {
                  color: #4B61DD;
                  text-decoration: underline;

                  &:after {
                    -webkit-animation-duration: 2s;
                    animation-duration: 2s;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                    -webkit-animation-name: bounce2;
                    animation-name: bounce2;
                    -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
                    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); } } } } } }

        .tb__action {
          display: none; } }

      .tb__close {
        top: 0;
        display: none; } }

    .top-banner-bot {
      z-index: 101;

      &.img-md {
        .tb__wrap {
          .tb__text {
            max-width: 100%;
            padding: 0;

            span {
              &.meta-sm {
                font-size: 14px;
                line-height: 20px; } } } } }


      .tb__wrap {
        &::before, .tb__img {
          display: none; }

        .tb__text {
          padding: 0;
          -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;

          span {
            &.meta {
              display: none; }

            &.meta-sm, &.action {
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex; }

            &.title {
              font-size: 20px;
              line-height: 28px;

              b {
                font-family: "Inter", sans-serif;
                font-weight: 600; } }

            &.title-sm b {
              font-family: "Inter", sans-serif;
              font-weight: 600; }

            &.meta-sm small {
              font-size: 14px;
              line-height: 20px; } } }

        .tb__action {
          display: none; } }

      .tb__close {
        top: -7px;
        display: none;
        right: 0; } } } }

@media screen and (max-width: 600px) {
  .top-banner--alt {
    .top-banner-sticky {
      &.img-md {
        .tb__wrap {
          .tb__text {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            justify-content: center;
            text-align: center;
            span {
              &.title-sm,
              &.meta-sm {
                justify-content: center;
                text-align: center; }
              &.action {
                display: flex;
                flex: 0 0 100%;
                width: 100%;
                justify-content: center; } } }

          .tb__action {
            display: none; } }
        &.mcon {
          .tb__wrap {
            .tb__text {
              flex: 0 0 100%;
              width: 100%;
              max-width: 100%;
              justify-content: center;
              text-align: center;
              span {
                &.title-sm,
                &.meta-sm {
                  justify-content: center;
                  text-align: center; }
                &.action {
                  display: flex;
                  flex: 0 0 100%;
                  width: 100%;
                  justify-content: center;
                  span.link {
                    color: #FFF;
                    @include font-600();
                    &::after {
                      filter: invert(100%) brightness(1000%); } } } } }
            .tb__action {
              display: none; } } } } }

    .top-banner-bot {
      &.img-md {
        &.mcon {
          .tb__wrap {
            .tb__text {
              span {
                &.title {
                  font-size: 18px;
                  line-height: 26px; } } } } } } } } }

@media screen and (max-width: 395px) {
  .top-banner--alt {
    .top-banner-sticky {
      &.ebook {
        .tb__wrap {
          min-height: 101px; } } }
    .top-banner-bot {
      &.img-md {
        &.mcon {
          .tb__wrap {
            .tb__text {
              padding: 12px 0; } } } } } } }


@media screen and (max-width: 375px) {
  .top-banner--alt {
    .top-banner-sticky {
      &.img-md {
        .tb__wrap {
          min-height: 101px;
          .tb__text {
            span {
              &.title-sm {
                font-size: 14px;
                line-height: 20px;
                @include font-600(); }
              &.meta-sm {
                line-height: 20px;
                font-size: 14px; }

              &.action {
                .link {
                  font-size: 14px; } } } } }
        &.mcon {
          .tb__wrap {
            .tb__text {
              span {
                &.title-sm {
                  font-size: 14px;
                  line-height: 20px;
                  @include font-600(); }
                &.meta-sm {
                  line-height: 20px;
                  font-size: 14px; } } } } } } } } }

body.has-tb.tbs-show .top-banner-sticky {
  height: auto;
  opacity: 1;
  display: block;
  position: relative;
  transition: opacity linear .2s; }

@media (max-width: 1024px) {
  body.has-tb.has-tb--alt {
    // .top-banner, .web-wrapper
    //   position: relative
    //   top: -50px

    // &.tbs-show
    //   .price-table__head.stick-it
    //     top: 130px !important
    //     transition: top linear .3s

    //   &.tbs-show-top .price-table__head.stick-it
    //     top: 66px !important
 } }    //     transition: top linear .3s

@media (max-width: 991px) {
  body.has-tb.has-tb--alt {
    // margin-top: 56px

    header .header__left {
      top: 0; }

    &.tbs-show {
      // header.fixed
      //   -webkit-transform: translateY(64px)
      //   transform: translateY(64px)

      // .price-table__head.stick-it
      //   top: 120px !important
      //   transition: top linear .3s

      &.tbs-show-top {
        header.fixed {
          top: 0; }

        // .price-table__head.stick-it
        //   top: 120px !important
 }        //   transition: top linear .3s

      .top-banner-sticky {
        background: #EDFAFF;
        top: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0); } } } }

@media (max-width: 479px) {
  body.has-tb.has-tb--alt.tbs-show header.fixed {
    // -webkit-transform: translateY(76px)
 } }    // transform: translateY(76px)

@media (max-width: 1024px) {
  body.has-tb {
    &.menu-open {
      &.tbs-show header.fixed {
        // transform: translateY(0)!important
 } } } }        // transition: all linear .3s

@media (max-width: 991px) {
  body.has-tb {
    // margin-top: 110px

    header .header__left {
      top: 48px; }

    &.tbs-show {
      &.tbs-show-top header.fixed {
        top: 0; }

      &.menu-open header.fixed {
        -webkit-transform: translateY(0) !important;
        transform: translateY(0) !important; } }

    &.menu-open {
      .top-banner-sticky {
        visibility: hidden; }

      header {
        z-index: 999;

        .header__left {
          top: 0;
          transition: top linear .3s; } }

      &.tbs-show header.fixed {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        transition: all linear .3s; } } } }


body.has-tb {
  .masthead, {
    padding-top: 0; }
  .masthead-f {
    margin-top: 0; }
  &.tbs-show {
    .top-banner-sticky {
      opacity: 1;
      display: block;
      z-index: 101;
      position: relative;
      transition: all lienar .2s; }
    header {
      top: 0;
      .header__top {
        transition: all .4s ease;
        opacity: 1;
        visibility: visible; }
      &.fixed {
        // transform: translateY(40px)
        // +maxtablet()
        //   transform: translateY(0)
        // .header__top
        //   height: 0
        //   opacity: 0
        //   padding: 0
 } }        //   visibility: hidden
    &.tbs-show-top {
      header.fixed {
        // top: -40px
        // .header__top
        //   height: auto
        //   opacity: 1
        //   padding: 8px 0
        //   visibility: visible
        // +xsdesktop()
 } } }        //   top: 0

  &.has-tb--alt {
    &.tbs-show {
      header {
        &.fixed {
          // transform: translateY(64px)
 } }          // transition: all linear .3s
      // &.tbs-show-top
      //   header
      //     &.fixed
 }      //       top: -64px
    @media screen and (max-width: 1199px) {
      &.menu-open {
        .header-inline {
          .top-banner {
            .top-banner-sticky {
              display: none; } } } } }

    @include maxtablet() {
      // margin-top: 56px
      header {
        .header__left {
          top: 0; } }
      &.tbs-show {
        header {
          &.fixed {
            // transform: translateY(56px)
 } }            // transition: all linear .3s
        &.tbs-show-top {
          header {
            &.fixed {
              top: 0; } } }
        .top-banner-sticky {
          background: #EDFAFF;
          transform: translateY(0); } } }
    @include mobilelg() {
      &.tbs-show {
        header {
          &.fixed {
 } } } }            // transform: translateY(80px)
    @media screen and (max-width: 395px) {
      &.tbs-show {
        header {
          &.fixed {
 } } } } }            // transform: translateY(101px) //tambahan untuk sticky img-md
  @include maxtablet() {
    // margin-top: 110px
    header {
      .header__left {
        top: 48px; } }
    &.tbs-show {
      &.tbs-show-top {
        header.fixed {
          top: 0; } }
      &.menu-open {
        header.fixed {
 } } }          // transform: translateY(0)!important
    &.menu-open {
      .top-banner-sticky {
        visibility: hidden; }
      header {
        z-index: 999999;
        .header__left {
          top: 0;
          transition: top linear .3s; } }
      &.tbs-show {
        header.fixed {
 } } } } }          // transform: translateY(0)



body.has-tbs-top {
  &.single {
    .header {
      top: 64px; } }
  .tbs {
    top: 0;
    &.fixed.show-top {
      top: 0; } }
  .header.fixed {
    // top: 36px
    .header__top {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
      transition: all .4s ease; }
    &.show-top {
      top: 36px;
      .header__top {
        max-height: 40px;
        padding-top: 8px;
        padding-bottom: 8px;
        opacity: 1; } } }
  @include maxtablet {
    &.single {
      .tbs {
        top: 0; }
      .header .header__left {
        top: 48px; } }
    .tbs {
      top: 0;
      &.fixed {
        top: 0; } }
    .header {
      top: 126px !important;
      .header__left {
        top: 48px;
        transition: all .4s ease; } }
    &.menu-open {
      .header {
        top: 0 !important;
        .header__left {
          top: 0 !important; } }
      .tbs {
        display: none; } }
    &.tbs-top {
      .header .header__left {
        top: 48px; } } } }


.swiper {
  .swiper-wrapper {
    padding-bottom: 32px; }
  .swiper-pagination {
    line-height: 0;
    bottom: 0;
    .swiper-pagination-bullet {
      width: 32px;
      height: 6px;
      border-radius: 3px;
      margin: 2px 6px;
      background: #8B95A5;
      opacity: 1; }
    .swiper-pagination-bullet-active {
      background: $primary; } }
  .swiper-pagination-vertical {
    .swiper-pagination-bullet {
      width: 8px;
      height: 32px;
      border-radius: 4px;
      margin: 12px 0;
      background: #8B95A5;
      opacity: 1; }
    .swiper-pagination-bullet-active {
      background: $primary; } } }

