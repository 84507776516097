.masthead--hero {
  .masthead__left {
    padding-right: 50px; }
  .masthead__title {
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 16px; }
  .masthead__desc {
    margin-bottom: 24px; }
  .masthead__media {
    position: relative; }
  @include maxtablet() {
    .masthead__wrap {
      padding: 32px 0; }
    .masthead__content {
      padding-bottom: 0; }
    .masthead__left {
      padding-right: 20px;
      padding-left: 20px; }
    .masthead__title {
      font-size: 32px;
      line-height: 40px; }
    .masthead__media {
      &.show-sm {
        margin-bottom: 18px; } }
    .masthead__action {
      &.show-sm {
        margin-bottom: 16px; } } } }

.our-customer--alt {
  padding: 64px 0;
  background: #F2F4F7;
  &.white,
  &.bg-white {
    background: #FFF;
    .logo-marquee {
      padding-top: 64px;
      padding-bottom: 64px;
      &__title {
        text-align: center;
        margin: 0 0 32px; }
      &__desc {
        margin-bottom: 24px;
        p {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0; } } }
      &__group {
        display: flex;
        overflow: hidden;
        user-select: none;
        position: relative;
        &::before,
        &::after {
          content: "";
          z-index: 2;
          display: block;
          width: 64px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(89.8deg, #FFF 49.02%, rgba(21,62,110, 0) 113.2%); }
        &::after {
          left: auto;
          right: 0;
          transform: matrix(-1, 0, 0, 1, 0, 0); }
        &.reverse .logo-marquee__list {
          animation-direction: reverse;
          animation-delay: -3s; } }
      &__list {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-around;
        min-width: 100%;
        margin-bottom: 24px;
        animation: scroll-x 60s linear infinite;
        .item {
          display: grid;
          place-items: center;
          margin: 0 24px;
          width: 148px;
          height: 56px;
          img {
            display: block;
            max-width: 100%;
            max-height: 56px;
            width: auto;
            height: auto; } } }
      &__action {
        margin-top: 35px; }
      @include maxtablet {
        padding: 32px 0;
        &__title {
          br {
            display: none; } }
        &__list {
          animation-duration: 30s; } } } }
  .our-customer__title {
    margin-bottom: 24px;
    max-width: 100%; }
  .our-customer__action {
    margin-bottom: 0; }
  @include maxtablet() {
    padding: 32px 30px;
    .our-customer__title {
      br {
        display: none; } } } }

.tab-feature {
  padding: 64px 0;
  &__head {
    text-align: center;
    margin-bottom: 24px; }
  &__nav {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    padding: 12px 0;
    border-radius: 48px;
    margin-bottom: 27px;
    background: #EDF0F2;
    .nav-item {
      .nav-link {
        padding: 12px;
        margin: 0 6px;
        color: #626B79;
        border-radius: 48px;
        display: flex;
        font-size: 15px;
        line-height: 24px;
        @include font-600();
        &:hover,
        &:focus,
        &:active,
        &.active {
          background: #0055A2;
          color: #fff; } } } }
  &__title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px; }
  &__desc {
    margin-bottom: 24px; }
  &__action {
    display: flex;
    align-items: center;
    a {
      margin-right: 14px;
      &:last-child {
        margin-right: 0; } } }
  &__toggle {
    display: none; }
  &__meta {
    .tab-feature__title {
      font-size: 20px;
      line-height: 28px; }
    .tab-feature__img {
      display: none; } }
  @include maxtablet() {
    padding: 48px 8px;
    &__title {
      font-size: 28px;
      line-height: 36px; }
    &__content {
      .row {
        flex-direction: column-reverse; } }
    &__img {
      margin-bottom: 24px;
      display: none; }
    &__nav {
      display: none; }
    &__meta {
      .tab-feature__title {
        font-size: 16px;
        line-height: 24px;
        display: none; } }
    &__toggle {
      display: block;
      position: relative;
      &::after {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
        top: 15px;
        background: url('../images/ic-caret-down.svg') no-repeat center;
        background-size: contain;
        transform: rotateZ(0deg);
        transition: all linear .3s; }
      .tab-feature__title {
        padding: 12px 40px 12px 0;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        color: #626B79;
        border-bottom: 1px solid #D0D6DD;
        @include font-600(); } }
    &__meta {
      margin-top: 12px;
      height: 0;
      opacity: 0;
      visibility: collapse;
      transition: all linear .3s; }
    .tab-pane {
      display: block;
      opacity: 1;
      visibility: visible;
      &.active.show {
        .tab-feature__toggle {
          &::after {
            transform: rotateZ(180deg);
            transition: all linear .3s; }
          .tab-feature__title {
            color: #232933;
            border-bottom: 1px solid $blue; } }
        .tab-feature__meta {
          height: 100%;
          opacity: 1;
          visibility: visible;
          transition: all linear .3s;
          .tab-feature__img {
            display: block;
            margin: 16px auto; } } } } } }


.items-block {
  &--alt {
    padding: 32px 0;
    .items-block__title {
      margin-bottom: 24px;
      text-align: center; }
    .items-block__wrap {
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      align-items: start;
      .item {
        width: 236px;
        margin: 0 26px;
        &__icon {
          height: 236px;
          width: 236px;
          margin-bottom: 12px;
          img {
            width: auto;
            height: 100%; }
          &--sm {
            display: none; } }
        &__title {
          text-align: center;
          display: block; } } }
    .items-block__action {
      text-align: center;
      a {
        margin-right: 14px;
        &:last-child {
          margin-right: 0; } } }
    @include maxtablet() {
      padding: 48px 8px;
      .items-block__wrap {
        display: block;
        width: 100%;
        .item {
          display: block;
          width: 100%;
          margin: 0 0 25px 0;
          &>.item__icon:not(.item__icon--sm) {
            display: none; }
          .item__icon--sm {
            display: inline-block;
            vertical-align: top;
            width: 44px;
            height: auto;
            margin-right: 16px; }
          .item__title {
            display: inline-block;
            vertical-align: top;
            text-align: left;
            width: calc(100% - 65px); } } } } } }


.tab-cs {
  padding: 64px 0;
  &__toggle {
    display: none; }
  &__head {
    text-align: center;
    margin-bottom: 24px; }
  &__nav {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    padding: 12px 6px;
    background: #EDF0F2;
    border-radius: 48px;
    margin-bottom: 27px;
    .nav-item {
      .nav-link {
        padding: 12px 24px;
        margin: 0 6px;
        color: #626B79;
        border-radius: 48px;
        display: flex;
        font-size: 15px;
        line-height: 24px;
        @include font-600();
        &:hover,
        &:focus,
        &:active,
        &.active {
          background: #0055A2;
          color: #fff; } } } }
  &__title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px; }
  &__desc {
    margin-bottom: 24px; }
  &__action {
    display: flex;
    align-items: center;
    a {
      margin-right: 14px;
      &:last-child {
        margin-right: 0; } } }
  &__content {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.75);
    .col-md-7 {
      padding-right: 0; }
    .col-md-5 {
      padding-left: 0; } }
  &__testimonial {
    background: #EDF0F2;
    padding: 44px 32px;
    height: 100%; }
  &__quote {
    margin: 0 0 16px 0;
    font-size: 16px;
    line-height: 24px;
    @include font-400(); }
  &__info {
    display: flex;
    align-items: center;
    width: 100%;
    &-left,
    &-right {
      width: 50%; }
    &-right {
      text-align: right; } }
  &__img {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    vertical-align: middle;
    overflow: hidden;
    img {
      height: 48px;
      width: auto;
      border-radius: 50%;
      object-fit: cover; } }
  &__contact {
    display: inline-block;
    width: calc(100% - 69px);
    vertical-align: middle; }
  &__name {
    @include font-600(); }
  &__logo {
    display: inline-block;
    vertical-align: middle;
    img {
      height: 48px;
      width: auto; } }
  &__content {
    display: inline-flex; }
  &__stats {
    background: #0055A2;
    height: 100%;
    color: #FFF;
    padding: 32px 24px; }
  &__items {
    display: flex;
    align-items: stretch;
    .item {
      margin-right: 16px;
      &__label {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 26px;
        display: block;
        @include font-500(); }
      &__number {
        font-size: 52px;
        line-height: 26px;
        margin-bottom: 26px;
        display: block;
        @include font-600(); }
      &__desc {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 26px;
        display: block;
        @include font-500(); } } }
  @include maxtablet() {
    padding: 48px 8px;
    .tab-pane {
      display: block;
      opacity: 1;
      &.active.show {
        .tab-cs__content {
          visibility: visible;
          height: auto;
          opacity: 1;
          display: block;
          transition: all linear .3s; }
        .tab-cs__toggle {
          &::after {
            transform: rotateZ(180deg);
            transition: all linear .3s; } }
        .tab-cs__title {
          border-bottom: 1px solid $blue;
          color: #2B2A35; } } }
    &__toggle {
      display: block;
      position: relative;
      &::after {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
        top: 15px;
        background: url('../images/ic-caret-down.svg') no-repeat center;
        background-size: contain;
        transform: rotateZ(0deg);
        transition: all linear .3s; }
      .tab-cs__title {
        font-size: 16px;
        line-height: 24px;
        border-bottom: 1px solid #D0D6DD;
        padding: 12px 40px 12px 0;
        @include font-600(); } }

    &__title {
      font-size: 28px;
      line-height: 36px;
      color: #626B79; }
    &__nav {
      display: none;
      overflow-x: auto;
      overflow-y: hidden; }
    &__content {
      visibility: collapse;
      display: none;
      height: 0;
      opacity: 0;
      transition: all linear .3s;
      margin-bottom: 18px;
      .col-md-7 {
        padding-right: 12px; }
      .col-md-5 {
        padding-left: 12px; } }
    &__quote {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
      blockquote {
        margin: 0 0 18px 0; } }
    &__info {
      display: block;
      &-left,
      &-right {
        width: 100%;
        text-align: left; }
      &-left {
        margin-bottom: 16px; } }
    &__stats,
    &__items {
      display: block;
      .item {
        margin-right: 0;
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 0; } } } } }


.items-accr {
  &__wrap {
    padding-top: 32px;
    padding-bottom: 64px;
    .row {
      align-items: center; } }
  &__img {
    transition: all .2s ease-in-out;
    img {
      display: block;
      width: 100%;
      height: auto; }
    &.img-out {
      transform: scale(1.1);
      opacity: 0; }
    &.img-in {
      transform: scale(1);
      opacity: 1; } }
  .accordion {
    margin-bottom: 0;
    .card {
      position: relative;
      background: #FFF;
      border-radius: 4px;
      &__body {
        position: relative;
        box-shadow: none;
        background: transparent;
        padding-top: 0;
        margin-top: 0; }
      &.open {
        box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75);
        margin-top: 12px;
        border: 1px solid #0055A2;
        .card__header {
          border-color: transparent;
          padding-bottom: 12px; } } } }

  @include maxtablet() {
    padding-left: 8px;
    padding-right: 8px;
    &__wrap {
      padding: 48px 8px;
      .row {
        flex-direction: column-reverse;
        .accordion {
          margin-top: 18px; } } } } }

.logo-marquee {
  padding-top: 64px;
  padding-bottom: 64px;
  &__title {
    text-align: center;
    margin: 0 0 32px; }
  &__desc {
    margin-bottom: 24px;
    p {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0; } } }
  &__group {
    display: flex;
    overflow: hidden;
    user-select: none;
    position: relative;
    &::before,
    &::after {
      content: "";
      z-index: 2;
      display: block;
      width: 64px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(89.8deg, #F2F4F7 49.02%, rgba(255, 255, 255, 0) 113.2%); }
    &::after {
      left: auto;
      right: 0;
      transform: matrix(-1, 0, 0, 1, 0, 0); }
    &.reverse .logo-marquee__list {
      animation-direction: reverse;
      animation-delay: -3s; } }
  &__list {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-around;
    min-width: 100%;
    margin-bottom: 24px;
    animation: scroll-x 60s linear infinite;
    .item {
      display: grid;
      place-items: center;
      margin: 0 24px;
      width: 148px;
      height: 56px;
      img {
        display: block;
        max-width: 100%;
        max-height: 56px;
        width: auto;
        height: auto; } } }
  &__action {
    margin-top: 35px; }
  @include maxtablet {
    padding: 32px 0;
    &__title {
      br {
        display: none; } }
    &__list {
      animation-duration: 30s; } } }
