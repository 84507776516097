.partner-map {
  padding: 80px 12px;
  &__title {
    color: #FFF;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 40px; }
  &__desc {
    margin-bottom: 64px; }
  &__img {
    img {} }
  &__label {
    justify-content: center;
    text-align: center;
    margin-top: 18px; }
  &__accr {
    .item {
      padding: 16px 16px 16px 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      position: relative;
      &::after {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background-size: contain;
        background: url('../images/ic-arrow-down-w.svg') no-repeat center;
        top: 16px;
        right: 16px;
        position: absolute; }
      &.active {
        background: $white;
        color: #2B2A35;
        border-bottom: none;
        border-radius: 4px;
        &::after {
          background: url('../images/ic-chevron-down.svg') no-repeat center;
          transform: rotateZ(180deg); }
        .item__desc {
          display: block; } }
      &__title {
        font-size: 18px;
        line-height: 28px;
        padding-right: 40px;
        @include font-600; }
      &__desc {
        display: none; } } }
  @include maxtablet {
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 28px; }
    &__desc {
      margin-bottom: 40px; }
    br {
      display: none; } } }

.partner-programs {
  padding: 80px 12px 40px 12px;
  &__title {
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 24px; }
  .item {
    padding: 32px 16px;
    &__icon {
      margin-bottom: 16px; } }
  @include maxtablet {
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 40px; }
    .item {
      padding-top: 0;
      padding-bottom: 16px;
      &__icon {
        width: 48px;
        margin-right: 16px;
        display: inline-block;
        vertical-align: middle;
        img {
          width: 48px;
          height: auto; } }
      &__title {
        width: calc(100% - 69px);
        display: inline-block;
        vertical-align: middle; } }
    br {
      display: none; } } }

.partner-rewards {
  padding: 64px 12px;
  &__title {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 40px;
    text-align: center; }
  &__video {
    display: flex;
    justify-content: center;
    position: relative;
    .play-btn {
      position: absolute;
      background: url('../images/ic-play-circle-alt.svg') no-repeat center;
      background-size: contain;
      height: 80px;
      width: 80px;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
      border-radius: 50%;
      @include pulse(); } }
  @include maxtablet {
    padding-bottom: 24px;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 32px; }
    &__video {
      .play-btn {
        height: 50px;
        width: 50px;
        top: calc(50% - 25px);
        left: calc(50% - 25px); } }
    br {
      display: none; } } }

.partner-cta {
  padding: 64px 12px;
  &__title {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 40px;
    text-align: center; }
  &__desc {
    text-align: center;
    margin-bottom: 40px; }
  &__action {
    text-align: center;
    margin-bottom: 40px; }
  @include maxtablet {
    padding: 48px 12px;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 32px; }
    br {
      display: none; } } }


.partner-marquee {
  &__slider {
    .item {
      display: inline-block;
      img {
        display: inline-block;
        padding: 0 10px; } } }
  @include maxtablet {
    &__slider {
      .item {
        margin: 0 8px;
        max-width: 100vw;
        img {
          padding: 0;
          object-fit: cover;
          width: calc(50% - 11px);
          height: auto;
          &:last-child {
            margin-left: 16px; }
          &:first-child {
            height: 263px;
            width: 100%;
            padding-bottom: 16px; } } } } } }


