.feature-box {
  padding: 60px 0 32px 0;
  &__title {
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 24px; }
  .item {
    padding: 32px;
    height: 100%;
    margin-bottom: 40px;
    &__icon {
      margin-bottom: 8px;
      img {
        width: 48px;
        height: auto; } }
    &__title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px; }
    &:nth-last-child(-n+3) {
      margin-bottom: 0; }
    &:hover {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
      border-radius: 4px; } }
  @include maxtablet {
    padding: 30px 0;
    &__title {
      font-size: 1.5rem;
      line-height: 2rem;
      br {
        display: none; } }
    .item {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      height: auto;
      margin-bottom: 20px!important; } } }
