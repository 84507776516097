/* ------------------------------------------------------------------------------------------------ */
/* Content Blog */
/* ------------------------------------------------------------------------------------------------ */

.masthead-blog {
  &__wrap {
    padding-top: 32px;
    padding-bottom: 40px; }
  &__right {
    position: relative;
    display: flex;
    flex-direction: column; }
  &__img {
    display: block;
    position: relative;
    padding-top: 50%;
    border-radius: 4px;
    overflow: hidden;
    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; } }
  &__subtitle {
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 2px;
    a {
      color: #545465;
      &:hover {
        color: #545465;
        text-decoration: underline; } } }
  &__title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
    a {
      color: #2B2A35;
      &:hover {
        color: $blue; } } }
  &__content {
    margin-bottom: 12px;
    p {
      margin: 0; }
    a {
      color: $blue;
      &:hover {
        color: $blue;
        text-decoration: underline; } } }
  &__meta {
    margin-top: auto;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 2px;
    span {
      display: inline-block;
      vertical-align: middle; }
    .author {
      b {
        display: inline-block;
        padding-left: 8px; } }
    .date {
      position: relative;
      &:before {
        content: "";
        border-radius: 50%;
        height: 3px;
        width: 3px;
        background: #545465;
        display: inline-block;
        vertical-align: middle;
        margin: 0 12px; } } } }

.ec {
  &__wrap {
    padding-top: 32px;
    padding-bottom: 32px;
    margin-bottom: 64px;
    border-width: 1px 0;
    border-style: solid;
    border-color: $border; }
  &__right {
    border-left: 1px solid $border; }
  &__title {
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    @include font-400(); }
  .item {
    padding-left: 40px;
    &__subtitle {
      font-size: 12px;
      line-height: 26px;
      letter-spacing: 2px;
      text-transform: uppercase;
      a {
        color: #2B2A35;
        &:hover {
          color: $blue; } } }
    &__title {
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 16px;
      a {
        color: #2B2A35;
        &:hover {
          color: $blue; } } }
    &__content {
      p:last-of-type {
        margin: 0; }
      a {
        color: $blue;
        &:hover {
          color: $blue;
          text-decoration: underline; } } }
    &__meta {
      padding-top: 12px;
      font-size: 12px;
      line-height: 26px;
      letter-spacing: 2px;
      span {
        display: inline-block;
        vertical-align: middle; }
      .author {
        b {
          display: inline-block;
          padding-left: 8px; } }
      .date {
        position: relative;
        &:before {
          content: "";
          border-radius: 50%;
          height: 3px;
          width: 3px;
          background: #545465;
          display: inline-block;
          vertical-align: middle;
          margin: 0 12px; } } } } }



.blog-post {
  .post {
    position: relative;
    &__img {
      display: block;
      position: relative;
      width: 100%;
      padding-top: 60%;
      margin-bottom: 24px;
      .img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0; } }
    &__subtitle {
      font-size: 12px;
      line-height: 26px;
      letter-spacing: 2px;
      text-transform: uppercase;
      a {
        color: #2B2A35;
        &:hover {
          color: $blue; } } }
    &__title {
      font-size: 21px;
      line-height: 32px;
      margin: 0 0 16px;
      a {
        color: $black; }
      &:hover a {
        color: $blue; } }
    &__content {
      p:last-of-type {
        margin: 0; }
      a {
        color: $blue;
        &:hover {
          color: $blue;
          text-decoration: underline; } } }
    &__meta {
      padding-top: 32px;
      font-size: 12px;
      line-height: 26px;
      letter-spacing: 2px;
      span {
        display: inline-block;
        vertical-align: middle; }
      .author {
        b {
          display: inline-block;
          padding-left: 8px; } }
      .date {
        position: relative;
        &:before {
          content: "";
          border-radius: 50%;
          height: 3px;
          width: 3px;
          background: #545465;
          display: inline-block;
          vertical-align: middle;
          margin: 0 12px; } } }

    &.cover {
      .post__img {
        padding-top: 100%;
        .img {
          object-fit: contain; } } } }

  &--related {
    .post {
      position: relative;
      display: flex;
      align-items: center;
      padding-bottom: 25px;
      margin: 0 0 32px;
      &:after {
        content: "";
        display: block;
        width: calc(100% - 24px);
        height: 1px;
        background: $border;
        position: absolute;
        bottom: 0;
        left: 12px; }
      &:last-child:after,
      &:nth-last-child(2):after {
        display: none; }

      &__col,
      &__col-2 {
        position: relative;
        display: block;
        width: 100%; }
      &__col {
        max-width: 104px;
        flex: 0 0 104px; }
      &__col-2 {
        max-width: calc(100% - 104px);
        flex: 0 0 calc(100% - 104px);
        padding-left: 24px; }
      &__img {
        padding-top: 100%;
        margin: 0;
        .img {
          border-radius: 4px; } }
      &__subtitle {
        color: #009BDE; }
      &__title {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 8px; } } } }

.new-post,
.publikasi,
.multimedia,
.blog-product {
  &__wrap {
    margin-bottom: 64px; }
  .section-head {
    position: relative;
    margin-bottom: 24px;
    padding: 0;
    text-align: left;
    &__title {
      font-size: 32px;
      line-height: 48px;
      margin: 0;
      padding-right: 220px; }
    &__action {
      position: absolute;
      bottom: 0;
      right: 30px; } } }



.multimedia {
  .item {
    &__video {
      position: relative;
      width: 100%;
      margin-bottom: 16px;
      .play {
        display: block;
        position: absolute;
        z-index: 2;
        top: calc(50% - 24px);
        left: calc(50% - 24px);
        .icon {
          width: 48px;
          height: 48px;
          display: block; }
        &:after {
          content: "";
          display: block;
          width: 64px;
          height: 64px;
          border-radius: 32px;
          background: $blue;
          opacity: .2;
          position: absolute;
          top: calc(50% - 32px);
          left: calc(50% - 32px);
          transform: scale(.5);
          transition: all .5s ease-in-out; } }
      .block-img {
        position: relative;
        z-index: 1;
        width: 100%;
        padding-top: 60%;
        img {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover; } }
      &:hover {
        .play:after {
          transform: scale(1);
          cursor: pointer; } } }
    &__title {
      font-size: 20px;
      line-height: 32px;
      a {
        color: $black;
        &:hover {
          color: $blue;
          text-decoration: none; } } } } }



.blog-product {
  &__wrap {
    margin-bottom: 24px; }
  .post {
    margin-bottom: 40px; }
  .post__meta {
    padding-bottom: 16px;
    border-bottom: 1px solid $border; } }



.newsletter {
  text-align: center;
  color: #FFF;
  &__wrap {
    background-color: #009BDE;
    background-image: url(../images/patterns-02.svg);
    background-size: 69px;
    padding: 56px 20px 72px 20px;
    border-radius: 4px;
    margin-bottom: 64px; }
  &__title {
    color: #FFFFFF;
    font-size: 32px;
    line-height: 48px;
    @include font-600();
    margin-bottom: 16px; }
  &__content {
    margin-bottom: 24px; }
  &__form {
    .form-group {
      position: relative;
      margin: 0;
      display: flex;
      justify-content: center; }
    .form-control {
      display: block;
      background: #FFF;
      border: 0;
      height: 50px;
      padding: 6px 15px;
      width: 100%;
      max-width: 320px;
      flex: 0 0 320px;
      border-radius: 4px 0 0 4px; }
    button[type="submit"] {
      display: block;
      line-height: 50px;
      background: #2B2A35;
      width: 100%;
      max-width: 128px;
      flex: 0 0 128px;
      padding: 0;
      color: #FFF;
      border: 0;
      border-radius: 0 4px 4px 0;
      cursor: pointer;
      transition: all .3s ease;
      &:hover {
        background: darken(#2B2A35, 10%); } } } }



.blog-testimonial {
  margin-bottom: 120px;
  &__wrap {
    background: #F8F9FA;
    box-shadow: 0px 9px 11px -2px #dbdbdb;
    border-radius: 4px; }

  .owl-dots {
    position: absolute;
    bottom: -58px;
    left: 0;
    width: 100%; }

  .item {
    &__left {
      position: relative;
      padding: 80px 0 64px 104px;
      &:before {
        content: "";
        background: url(../images/icon-comma.svg) no-repeat;
        background-size: contain;
        background-position: center;
        position: absolute;
        display: block;
        width: 188px;
        height: 120px;
        top: 25px;
        left: 35px; } }

    &__title {
      font-size: 32px;
      line-height: 40px;
      @include font-600();
      letter-spacing: -0.5px;
      color: $black;
      margin-bottom: 32px;
      a {
        color: #2B2A35;
        &:hover {
          color: $blue; } } }
    &__content {
      margin-bottom: 24px; }
    &__foot {
      .author {
        display: block;
        @include font-600(); }
      .logo {
        display: block;
        height: 24px;
        width: auto;
        margin-top: 4px; } }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0 4px 4px 0; } } }



.blog-banner {
  &__wrap {
    background: #009BDE;
    border-radius: 8px;
    margin-bottom: 64px; }
  &__left {
    display: flex;
    justify-content: center; }
  &__right {
    display: flex;
    justify-content: center;
    align-items: flex-end; }
  &__content {
    max-width: 460px;
    padding: 92px 12px; }
  &__title {
    color: #FFF;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px; } }



.blog-single {
  padding-top: 10px;
  padding-bottom: 120px;
  &__article {
    article {
      padding-bottom: 25px; }
    iframe {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 0 25px; }
    h2 {
      font-size: 40px;
      line-height: 50px;
      margin: 60px 0 25px; }
    p {
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 25px;
      color: $black; }
    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 0 25px; } }
  &__ads {
    margin-bottom: 64px;
    img {
      display: block;
      width: 100%;
      height: auto; } }
  &__tag {
    margin-right: -4px;
    margin-left: -4px;
    margin-bottom: 30px;
    a {
      display: inline-block;
      border: 1px solid $border;
      border-radius: 4px;
      @include font-600;
      color: #2B2A35;
      padding: 5px 10px;
      margin: 0 4px 8px;
      transition: all .3s ease;
      &:hover {
        color: #FFF;
        border-color: #009BDE;
        background: #009BDE; } } }


  &__sidebar {
    .bs-meta {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase; }
    .bs-author {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      @include font-600;
      display: flex;
      align-items: center;
      &__img {
        position: relative;
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        overflow: hidden;
        background: $border;
        img {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover; } }
      &__name {
        &:not(:first-child) {
          padding-left: 24px; } } }
    .bs-share {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 24px;
      &__title {
        margin-bottom: 16px; }
      &__wrap {
        display: flex;
        margin-left: -4px;
        margin-right: -4px;
        a {
          display: block;
          margin: 0 4px;
          opacity: 1;
          transition: all .3s ease;
          &:hover {
            opacity: .6; } }
        img {
          display: block;
          width: auto;
          height: 40px; } } }

    hr {
      margin: 16px 0; }

    .blog-post {
      opacity: 1;
      transition: all .3s ease; }
    .post {
      &__img {
        margin-bottom: 8px; }
      &__subtitle {
        line-height: 20px; }
      &__title {
        font-size: 16px;
        line-height: 24px; } } }

  .sidebar__wrap.fixed {
    .blog-post {
      display: none; } } }



.ebook-posts,
.ebook-single {
  &:first-child {
    padding-top: 192px; }

  .post {
    margin-bottom: 48px;
    &__img {
      position: relative;
      width: 100%;
      padding-top: 128.73%;
      img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: contain; } }
    &__title {
      font-size: 21px;
      line-height: 26px;
      margin-bottom: 16px;
      color: #2B2A35;
      @include font-600(); }
    &__subtitle {
      display: block;
      font-size: 12px;
      line-height: 26px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 8px; }
    &__content {
      border-bottom: 1px solid $border;
      padding-bottom: 32px;
      p {
        font-size: 15px;
        line-height: 26px; } }

    &--featured {
      .post__right {
        display: flex;
        flex-direction: column; }
      .post__img {
        padding-top: 100%; }
      .post__title {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 24px; }
      .post__content {
        height: 100%;
        position: relative;
        padding-bottom: 52px;
        p {
          font-size: 16px;
          line-height: 26px; }
        .link-more {
          position: absolute;
          bottom: 45px; } } } }

  &__more {
    text-align: center;
    padding: 32px 16px 60px;
    .btn {
      line-height: 48px; } } }


.ebook-single {
  &:first-child {
    padding-top: 152px; }
  .post {
    &--featured {
      margin: 0;
      padding: 80px 0;
      .post__content {
        border-bottom: 0; }
      .post__action {
        padding-top: 32px;
        .btn {
          line-height: 48px;
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2); } } } } }



.block-highlight {
  padding: 100px 0;
  background: #F8F9FA;
  h2 {
    color: #2B2A35;
    font-size: 32px;
    line-height: 40px;
    @include font-600();
    letter-spacing: -0.05px; }
  .block-img {
    position: relative;
    padding-top: 70%;
    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; } }

  .item {
    h3 {
      font-size: 21px;
      line-height: 32px;
      @include font-600();
      margin-bottom: 8px; }
    .img {
      margin-bottom: 8px;
      img {
        display: block;
        height: 42px;
        width: auto; } } }

  &__top {
    margin-bottom: 48px; } }


.what-inside {
  padding: 100px 0;
  h2 {
    font-size: 32px;
    line-height: 40px;
    @include font-600();
    margin-bottom: 24px; }

  .img-wrap {
    max-width: 350px;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: -8px;
      left: -16px;
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      box-shadow: -4px 4px 8px 0 rgba(0,0,0,0.2);
      z-index: -1; }
    &:after {
      top: -16px;
      left: -32px;
      z-index: -2; }
    .img {
      position: relative;
      width: 100%;
      padding-top: 140%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; } } } }


.download-ebook {
  &__wrap {
    text-align: center;
    padding: 100px 0;
    border-top: 1px solid #e2e2e2; }
  h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px; }
  p {
    margin-bottom: 48px; } }

.share-ebook {
  &__wrap {
    text-align: center;
    padding: 100px 0;
    border-top: 1px solid #e2e2e2; } }
