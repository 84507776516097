.header-inline {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 105;
  background-color: #FFF;
  border-bottom: 0;
  transition: border-bottom linear .2s;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0;
    transition: padding linear .2s;
    flex: 0 0 100%;
    width: 100%; }
  &__logo {
    flex: 0 0 100%;
    width: 100%;
    max-width: 106px;
    position: relative;
    padding: 0;
    .logo {
      img {
        height: 32px;
        width: auto;
        object-fit: contain; } }
    .back {
      display: none; } }

  &__right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 16px;
    padding: 0;
    .wpml-custom {
      .wpml-ls-legacy-dropdown-click {
        max-width: 86px;
        width: 100%;
        ul {
          li.wpml-ls-item {
            a.wpml-ls-item-toggle {
              color: #626B79;
              font-size: 14px;
              line-height: 20px;
              padding-right: calc(10px + 1.2em);
              &:hover {
                color: #626B79;
                border-radius: 8px; }
              &> img {
                margin-right: 4px; } }
            ul.wpml-ls-sub-menu {
              background: #fff;
              padding: 4px;
              border-radius: 12px;
              overflow: hidden;
              border: 1px solid #D0D6DD;
              width: 115px;
              text-align: center;
              li {
                &.wpml-ls-item {
                  &> a.wpml-ls-link {
                    color: #232933;
                    padding: 16px;
                    border-radius: 8px;
                    &:hover {
                      background: #E0EEFF; }
                    img {
                      display: none; } } }

                &.wpml-ls-item-id {
                  &> a {
                    &::before {
                      content: "Indonesia"; }
                    &> span {
                      display: none; } } }
                &.wpml-ls-item-en {
                  &> a {
                    &::before {
                      content: "English"; }
                    &> span {
                      display: none; } } } } } } } } } }
  &__cta {
    display: none; }
  &__cta-sm {
    display: flex;
    .btn,.link {
      margin-bottom: 0; }
    .btn {
      padding: 3px 12px;
      font-size: 14px;
      line-height: 20px; } }
  &__cta-mobile {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    a,a.btn,a.link,a.more-link,.wpml-custom {
      max-width: calc((100%/2) - 16px);
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
      &.btn-block {
        max-width: 100%; } }
    a.btn {
      padding: 7px 14px; }
    a.link,a.more-link {
      text-align: right;
      font-size: 16px;
      line-height: 24px;
      @include font-600(); }
    .wpml-custom {
      .wpml-ls-legacy-dropdown-click {
        max-width: 190px;
        width: 100%;
        ul {
          li.wpml-ls-item {
            a.wpml-ls-item-toggle {
              color: #626B79;
              font-size: 14px;
              line-height: 20px;
              width: 100%;
              max-width: max-content;
              padding-right: calc(10px + 1.2em);
              &:hover {
                color: #626B79;
                border-radius: 8px; }
              &> img {
                margin-right: 4px; } }
            ul.wpml-ls-sub-menu {
              background: #fff;
              padding: 4px;
              border-radius: 12px;
              overflow: hidden;
              border: 1px solid #D0D6DD;
              width: 115px;
              text-align: center;
              li {
                &.wpml-ls-item {
                  &> a.wpml-ls-link {
                    color: #232933;
                    padding: 16px;
                    border-radius: 8px;
                    &:hover {
                      background: #E0EEFF; }
                    img {
                      display: none; } } }

                &.wpml-ls-item-id {
                  &> a {
                    &::before {
                      content: "Indonesia"; }
                    &> span {
                      display: none; } } }
                &.wpml-ls-item-en {
                  &> a {
                    &::before {
                      content: "English"; }
                    &> span {
                      display: none; } } } } } } } } } }

  &__burger {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .burger-menu {
      display: inline-flex;
      cursor: pointer;
      width: 24px;
      height: 24px;
      border-radius: 2px;
      cursor: pointer;
      position: relative;
      span {
        position: absolute;
        top: 4px;
        right: 0;
        width: 100%;
        height: 3px;
        background: #009bde;
        transform: rotate(0deg);
        opacity: 1;
        transition: transform linear .2s, opacity linear .2s;
        &:nth-child(2) {
          width: 75%;
          top: 12px; }
        &:nth-child(3) {
          top: 19px; } } } }
  &__dropdown {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    // height: 0
    transform: translateX(-100%);
    transition: transform linear .2s, height linear .2s; } }


body {
  overflow: unset;
  &.menu-open {
    overflow: hidden;
    .header-inline {
      height: 100vh;
      transition: height linear .15s;
      &__burger {
        .burger-menu {
          span {
            &:nth-child(1) {
              top: 10px;
              transform: rotate(135deg);
              transition: transform linear .2s; }
            &:nth-child(2) {
              top: 10px;
              opacity: 0;
              transition: opacity linear .2s; }
            &:nth-child(3) {
              top: 10px;
              transform: rotate(45deg);
              transition: transform linear .2s; } } } }
      &__dropdown {
        transform: translateX(0);
        transition: transform linear .10s, height linear .2s; } } }
  &.header-fixed {
    .header-inline {
      border-bottom: 1px solid #E2E2E2;
      transition: border-bottom linear .2s; } } }

@media screen and (max-width: 1199px) {
  .header-inline {
    &__main {
      display: none; }
    &__dropdown {
      position: absolute; } }

  body {
    &.menu-open {
      &.submenu-open {
        .header-inline {
          &__dropdown {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            transition: top lienar .2s; } } } } } }

// @media screen and (max-width: 991px)
//   .header-inline
//     &__dropdown
//       position: relative
//       transition: position linear .2s

@media screen and (max-width: 990px) {
  body {
    &.menu-open {
      .header-inline {
        &__dropdown {
          height: calc(100dvh - 57px); } } } } }

@media screen and (min-width: 438px) {
  .header-inline {
    &__cta-mobile {
      a.btn {
        font-size: 16px;
        line-height: 24px;
        padding: 7px 24px; } } } }

@media screen and (min-width: 991px) {
  .header-inline {
    &__wrapper {
      transition: padding linear .2s;
      padding: 0; }
    &__right {
      padding: 16px 0; }
    &__logo {
      padding: 16px 0;
      .logo {
        img {
          height: 40px; } } }
    &__cta {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      a.link,
      a.more-link {
        font-size: 14px;
        line-height: 20px;
        color: #4B61DD;
        @include font-600();
        transition: all linear .2s;
        &:hover {
          color: darken(#4B61DD, 10%);
          transition: all linear .2s; }
        &.seperator {
          padding-right: 16px;
          border-right: 1px solid #D0D6DD; } } }
    &__cta-sm,
    &__cta-mobile {
      display: none; }
    &__cta-mobile {
      a,a.btn,a.link,a.more-link,.wpml-custom {
        width: max-content;
        flex: 0 0 max-content; } }
    &__dropdown {
      transform: translateX(-100%);
      transition: transform linear .2s; } }

  body {
    &.header-fixed {
      .header-inline {
        &__wrapper {
          padding: 0;
          transition: padding linear .2s, border-bottom linear .2s; } } }
    overflow: unset;
    &.menu-open {
      overflow: hidden; } } }

@media screen and (min-width: 992px) {
  .header-inline {} }

@media screen and (min-width: 1200px) {
  .header-inline {
    height: auto;
    &__dropdown {
      transform: translateX(0); }
    &__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 40px;
      width: 100%;
      max-width: calc(100% - (484px + 40px)); }
    &__cta-mobile {
      display: none;
      a.link {
        font-size: 14px;
        line-height: 20px;
        color: #4B61DD;
        @include font-600();
        transition: all linear .2s; } }
    &__main {
      flex: 0 0 100%;
      width: 100%;
      max-width: calc(100% - (106px + 40px));
      display: flex; }
    &__burger {
      display: none; } }

  body {
    &.menu-open {
      overflow: unset;

      .header-inline {
        height: auto; } } } }







