.masthead.masthead--alt {
  .masthead__media {
    .masthead__img {
      min-height: 500px;
      object-fit: contain;
      object-position: center;
      @media screen and (max-width: 1200px) {
        min-height: 0; } } } }

.cs-detail {
  &__head {
    padding-top: 50px;
    padding-bottom: 80px; }
  &__body {
    padding-bottom: 64px;
    .free-trial-block {
      margin-bottom: 32px; } }

  .sp-block {
    margin-bottom: 80px;
    .img {
      margin-bottom: 16px; }
    .title {
      @include font-600();
      margin-bottom: 12px; }
    &__wrap {
      display: flex;
      align-items: center;
      padding: 16px 0; }
    &__problem {
      display: block;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
      padding: 32px 48px;
      background: #F2F4F7;
      border-radius: 8px 0 0 8px;
      .title {
        color: $text-dark; } }
    &__solution {
      display: block;
      position: relative;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
      padding: 32px 48px;
      color: #FFF;
      background: #2C9CA3;
      border-radius: 0 8px 8px 0;
      .img,
      .title,
      p {
        z-index: 2;
        position: relative; }
      &:before {
        content: "";
        z-index: 1;
        display: block;
        position: absolute;
        width: 34px;
        height: calc(100% + 32px);
        top: -16px;
        left: -32px;
        background: #2C9CA3;
        border-radius: 6px 0 0 6px;
        clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, calc(100% - 2px) 50%, 0% 0%); }
      &:after {
        content: "";
        z-index: 1;
        display: block;
        position: absolute;
        width: 100%;
        height: calc(100% + 32px);
        top: -16px;
        left: 0;
        background: #2C9CA3;
        border-radius: 0 8px 8px 0; } }
    @include mobilesm {
      margin-bottom: 48px;
      .sp-block__wrap {
        flex-direction: column; }
      .sp-block__problem,
      .sp-block__solution {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 24px; }
      .sp-block__problem {
        border-radius: 8px 8px 0 0; }
      .sp-block__solution {
        border-radius: 0 0 8px 8px;
        &:before,
        &:after {
          display: none; } } } }

  .img-full {
    margin-bottom: 64px; }

  .blockquote {
    max-width: 600px;
    margin: 64px auto;
    blockquote {
      position: relative;
      font-style: normal;
      margin-bottom: 20px;
      p {
        color: #2B2A35;
        font-size: 24px;
        line-height: 32px;
        @include font-600(); }
      &:before {
        content: "\201C";
        display: block;
        position: absolute;
        top: 0;
        left: -36px;
        font-size: 48px;
        line-height: 56px;
        color: $brand;
        @include font-600(); }
      &:after {
        content: "\201D";
        display: block;
        position: absolute;
        bottom: -25px;
        right: -25px;
        font-size: 48px;
        line-height: 56px;
        color: $brand;
        @include font-600(); } }
    &__author {
      display: flex;
      .author__avatar {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 16px; }
      .author__text {
        display: {}
        width: 100%;
        max-width: calc(100% - 40px);
        // padding-left: 16px
        span {
          display: block;
          font-size: 14px;
          line-height: 20px; } }
      .author__name {
        color: #2B2A35;
        @include font-500(); } }
    @include mobilesm {
      padding-left: 15px;
      blockquote {
        p {
          font-size: 20px;
          line-height: 28px; } } } }

  .featured-block {
    padding: 40px 0 80px;
    .item {
      &__img {
        margin-bottom: 8px; }
      &__title {
        display: block;
        color: #2B2A35;
        margin-bottom: 12px;
        @include font-500(); } }
    @include mobilesm {
      padding-bottom: 48px;
      .item {
        margin-bottom: 32px; } } }

  .block-2-col {
    padding: 74px 0;
    .item {
      &:not(:last-child) {
        margin-bottom: 100px; }
      &:nth-child(even) {
        flex-direction: row-reverse; } }
    @include mobilesm {
      .item {
        flex-direction: column-reverse;
        img {
          margin-bottom: 24px; }
        &:nth-child(even) {
          flex-direction: column-reverse; } } } }

  article {
    margin-bottom: 48px;
    img {
      max-width: 100%;
      height: auto; }
    h2, h3, h4 {
      margin-bottom: 1rem; } } }

.cs-sidebar {
  &__wrap {
    max-width: 312px;
    margin-bottom: 48px; }
  .item {
    margin-bottom: 32px;
    &__title {
      font-size: 14px;
      line-height: 20px;
      color: $text-dark;
      margin-bottom: 8px;
      @include font-600(); }
    &__desc {
      font-size: 14px;
      line-height: 20px;
      color: $text-dark;
      ul {
        li {
          margin-bottom: 8px;
          a {
            color: $text-dark;
            text-decoration: underline;
            opacity: 1;
            transition: all .4s ease;
            &:hover {
              opacity: .5; } } } } } }
  @include mobilesm {
    margin-bottom: 64px; } }

.cs-more,
.case-study {
  padding: 80px 0;
  background: #F2F4F7;
  &__filter {
    margin: 0 0 48px;
    .bootstrap-select > .dropdown-toggle {
      padding-top: 9px;
      padding-bottom: 9px; }
    form {
      display: flex;
      margin-left: -8px;
      margin-right: -8px; }
    .form-group,
    .form-action {
      display: block;
      margin: 0;
      padding: 0 8px;
      width: 100%;
      flex-basis: 0; }
    .form-group {
      flex-grow: 2; }
    .form-action {
      flex-grow: 1.2;
      .btn {
        display: block;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px; } }
    @include mobilesm {
      padding-left: 20%;
      padding-right: 20%;
      form {
        flex-direction: column; } } }

  .item {
    border-radius: 4px;
    margin-bottom: 48px;
    &__wrap {
      display: flex;
      position: relative;
      flex-direction: column;
      height: 100%; }
    &__img {
      display: block;
      position: relative;
      width: 100%;
      padding-top: 56%;
      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 4px 4px 0 0; } }
    &__content {
      padding: 20px 23px 24px;
      background: #fff;
      border: 1px solid $border;
      border-radius: 0 0 4px 4px; }
    &__title {
      margin-bottom: 8px;
      font-size: 20px;
      line-height: 32px;
      a {
        display: block;
        color: #2B2A35;
        text-decoration: none;
        &:hover {
          color: $link; } } }
    &__desc {
      margin-bottom: 16px; } } }

.cs-more {
  .section-head {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    align-items: flex-end;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0; }
    &__more {
      margin-bottom: 0;
      margin-left: auto;
      padding-right: 32px; } }
  .item {
    margin-bottom: 0; } }

.big-number {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -16px;
  margin-right: -16px;
  .item {
    width: 100%;
    max-width: 280px;
    flex: 0 0 280px;
    padding-right: 16px;
    padding-left: 16px;
    span.large {
      display: block;
      color: $text-dark;
      font-size: 48px;
      line-height: 56px;
      @include font-600(); }
    p {
      color: $text-dark;
      @include font-500(); } }
  @include mobilesm {
    .item {
      margin-bottom: 40px; } } }

.free-trial-block {
  padding-top: 64px;
  &__wrap {
    display: flex;
    background: #F9FAFB;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); }
  &__img {
    position: relative;
    width: 100%;
    max-width: 304px;
    flex: 0 0 304px;
    img {
      display: block;
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      top: 0; } }
  &__content {
    width: 100%;
    max-width: calc(100% - 304px);
    flex: 0 0 calc(100% - 304px);
    padding: 40px 40px 40px 0; }
  @include mobile {
    .free-trial-block__wrap {
      flex-direction: column; }
    .free-trial-block__img {
      max-width: 100%;
      flex: 0 0 100%;
      img {
        position: relative;
        max-width: 40%;
        margin: 0 auto; } }
    .free-trial-block__content {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 24px; } } }
