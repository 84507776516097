.features {
  padding: 64px 0;
  &__outline,
  &__sub,
  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    @include font-600(); }
  &__title {
    font-size: 32px;
    line-height: 48px;
    @include font-600();
    margin-bottom: 8px; }
  h3.features__title {
    font-size: 20px;
    line-height: 28px;
    @include font-600();
    margin-bottom: 16px; }
  &__desc {
    margin-bottom: 24px; }
  &__actions {
    margin-top: 24px;
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; } } }
  &__custom-list {
    ul {
      li {
        display: flex;
        position: relative;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0; }
        span.icon {
          content: '';
          min-width: 24px;
          height: 24px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center; } } } }
  @include maxtablet() {
    padding: 32px 0;
    &__img {
      margin-top: 24px;
      .media {
        text-align: center; } }
    &__actions {
      a {
        margin-right: 0;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } } }
  @include mobilelg() {
    h3.features__title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px; }
    &__custom-list {
      ul {
        li {
          align-items: flex-start; } } } } }

