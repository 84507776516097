.onepager {
  padding: 144px 0 64px 0;
  &__subtitle {
    @include font-600();
    font-size: 14px;
    line-height: 20px; }
  &__title {
    @include font-700();
    font-size: 40px;
    line-height: 56px; }
  &__author {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    b {
      margin: 0 16px 0 8px;
      position: relative;
      text-transform: uppercase;
      &:after {
        content: '•';
        width: 8px;
        height: 8px;
        position: absolute;
        right: -11px; } }
    span {
      color: #232933; } }
  &__wrapper {
    margin-top: 64px; }
  &__content {
    padding-right: 50px;
    article {
      h2 {
        @include font-600();
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 24px; }
      p {
        color: #232933;
        margin-bottom: 16px; }
      .table {
        &-scroll {
          margin-bottom: 18px; }
        &--default {
          width: 100%;
          thead {
            background: #D0D6DD; }
          tr {
            th,
            td {
              padding: 16px;
              text-align: center;
              position: relative;
              border: 1px solid #EDF0F2;
              &:first-child {
                text-align: left;
                border-left-color: #FFF; }
              &:last-child {
                border-right-color: #FFF; }
              .badge {
                position: absolute;
                @include font-600();
                font-size: 14px;
                line-height: 20px;
                top: 8px;
                padding: 2px 12px;
                border-radius: 6px;
                &--green {
                  background: #3C914D;
                  color: #FFF; } }
              small {
                color: #626B79;
                font-size: 14px;
                line-height: 20px;
                display: block; } }


            th {
              @include font-600();
              font-size: 20px;
              line-height: 28px; } } } } } }

  &__sidebar {
    .sidebar {
      &__sticky {
        &.stick {
          margin-top: 16px;
          &.show-top {
            margin-top: 32px; } } }
      &__title {
        @include font-600();
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px; }
      &__list {
        li {
          margin-bottom: 16px;
          a {
            color: #626B79;
            &:hover {
              color: #4B61DD; } } } } } }
  @include maxtablet() {
    &__title {
      font-size: 32px;
      line-height: 38px; }
    &__content {
      padding-right: 12px;
      article {
        h2 {
          font-size: 28px;
          line-height: 36px; }
        .table {
          &-scroll {
            width: 100%;
            overflow-x: auto; }
          &--default {
            tr {
              th,
              td {
                min-width: 170px;
                .badge {
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  color: transparent;
                  font-size: 0;
                  line-height: 0;
                  padding: 0;
                  top: 10px; } } } } } } }
    &__sidebar {
      position: fixed;
      top: 55px;
      left: 0;
      width: 100%;
      background: #EDF0F2;
      padding: 8px 16px;
      z-index: 10;
      .sidebar__list {
        display: none; }
      .sidebar__title {
        position: relative;
        margin-bottom: 0;
        &::after {
          content: '';
          width: 24px;
          height: 24px;
          background: url(../images/ic-caret-down.svg) no-repeat center;
          background-size: contain;
          position: absolute;
          right: 0;
          top: 0;
          transition: all linear .3s; } }
      &.active {
        .sidebar__title {
          margin-bottom: 24px;
          &::after {
            transform: rotate(180deg);
            transition: all linear .3s; } }
        .sidebar__list {
          display: block; } } } } }

