.tab-testimonial {
  padding: 64px 0;
  &__title {
    margin-bottom: 24px; }
  &__tabs {
    .nav {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      padding: 12px 24px;
      background: #EDFAFF;
      border-radius: 16px 16px 0 0;
      .nav-item {
        .nav-link {
          display: inline-flex;
          padding: 12px 24px;
          font-size: 16px;
          line-height: 24px;
          color: #232933;
          border-radius: 16px;
          border-bottom: 0;
          @include font-600();
          &.active {
            background: #FFF;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
            border-bottom: 0; }
          &:hover {
            border-bottom: 0; } } } } }
  .tab-content {
    width: auto;
    .tab-pane {
      width: auto;
      .toggle-sm {
        display: none; }
      .testimonial__wrap {
        background: #EDFAFF;
        border-radius: 0 16px 16px 16px;
        display: flex;
        overflow: hidden;
        box-shadow: none;
        padding: 0;
        width: auto;
        .testimonial__content {
          flex: 0 0 calc(100% - 500px);
          padding: 24px 64px 24px 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .testimonial__title {
            display: block;
            width: 100%;
            margin-bottom: 32px;
            font-size: 20px;
            line-height: 28px;
            @include font-600(); }

          .testimonial__custom-list {
            ul {
              li {
                display: flex;
                position: relative;
                gap: 8px;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 12px;
                max-width: 640px;
                &:last-child {
                  margin-bottom: 0; }
                span.icon {
                  content: '';
                  min-width: 24px;
                  height: 24px;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center; }
                span.text {
                  b {
                    width: 100%;
                    display: block;
                    @include font-600(); } } } } }

          .testimonial__actions {
            margin-top: 24px;
            a {
              margin-right: 16px;
              &:last-child {
                margin-right: 0; } } } }
        .testimonial__card {
          max-width: 500px;
          width: 100%;
          background: #0055A2;

          .testimonial__img {
            height: 240px;
            max-width: 500px;
            img {
              max-width: 500px;
              height: 240px;
              object-fit: cover; } }
          .testimonial__testi {
            padding: 56px 24px 32px 24px; }
          .testimonial__msg {
            color: #FFF;
            margin: 0;
            font-size: 16px;
            line-height: 24px; }
          .testimonial__meta {
            margin-top: 21px;
            color: #FFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .meta__left {
              font-size: 14px;
              line-height: 20px;
              .name {
                display: block;
                width: 100%;
                @include font-600();
                margin-bottom: 4px; } }
            .meta__right {
              .logo {
                height: 32px;
                width: auto;
                object-fit: contain; } } } } } } }

  @include maxtablet() {
    padding: 32px 0;
    .tab-content {
      .tab-pane {
        &:not(.active) {
          opacity: 1;
          display: block;
          .toggle-sm {
            display: flex;
            font-size: 16px;
            line-height: 24px;
            color: #626B79;
            border-bottom: 1px solid #D0D6DD;
            padding: 12px 0;
            @include font-600();
            position: relative;
            align-items: center;
            &::after {
              content: '';
              width: 24px;
              height: 24px;
              display: inline-flex;
              margin-left: auto;
              background: url(../images/ic-caret-down.svg) no-repeat center;
              background-position: contain;
              transform: rotate(0deg);
              transition: transform linear .3s; } }
          .content {
            height: 0;
            opacity: 0; } }

        &.active {
          .toggle-sm {
            display: flex;
            font-size: 16px;
            line-height: 24px;
            border-bottom: 1px solid #009BDE;
            color: #232933;
            padding: 12px 0;
            @include font-600();
            position: relative;
            align-items: center;
            &::after {
              content: '';
              width: 24px;
              height: 24px;
              display: inline-flex;
              margin-left: auto;
              background: url(../images/ic-caret-down.svg) no-repeat center;
              background-position: contain;
              transform: rotate(180deg);
              transition: transform linear .3s; } }
          .content {
            height: auto;
            opacity: 1;
            transition: opacity linear .3s;
            &> section {
              padding: 12px 0; } } }
        .testimonial__wrap {
          flex-direction: column;
          border-radius: 0;
          .testimonial__content {
            padding: 24px;
            .testimonial__actions {
              margin-top: 24px;
              a {
                margin-right: 0;
                margin-bottom: 16px;
                &:last-child {
                  margin-bottom: 0; } } } }
          .testimonial__card {
            width: 100%;
            max-width: 100%;
            .testimonial__img {
              width: 100%;
              max-width: 100%;
              img {
                width: 100%;
                max-width: 100%; } } } } } } } }
