.tab-sticky {
  background: #EAECFB;
  padding-bottom: 12px;
  .section-head {
    padding: 64px 0 24px 0;
    &__title {
      text-align: center; } }
  .nav-tabs {
    display: flex;
    justify-content: center;
    align-items: stretch;
    .nav-item {
      margin: 0 12px;
      .nav-link {
        color: #626B79;
        @include font-600();
        font-size: 20px;
        line-height: 28px;
        padding: 24px 24px 20px 24px;
        border-bottom: 4px solid transparent;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        .nav-icon {
          margin-right: 16px;
          img {
            width: 52px;
            height: auto;
            filter: grayscale(.8); } }
        &.active, &:hover {
          color: #232933;
          border-bottom: 4px solid #4B61DD;
          .nav-icon {
            img {
              filter: grayscale(0); } } } } }
    &.stick {
      top: 55px!important;
      background: #FFF;
      z-index: 5;
      border-bottom: 1px solid #E2E2E2;
      transition: top linear .3s;
      .nav-item {
        .nav-link {
          padding: 4px 24px; } }
      &.show-top {
        top: 95px!important;
        transition: top linear .3s; } } }
  .tab-content {
    background: #FFF;
    .faq-block {
      background: #FFF;
      .section-head {
        padding: 0 0 24px 0;
        &__title {
          text-align: left; } } }
    .calculator-block {
      .section-head {
        padding: 0; } }
    .toggle-sm {
      display: none; }
    .promo-benefit {
      &__title {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 8px;
        @include font-600(); }
      &__desc {
        p {
          margin-bottom: 16px; } } } }

  @include maxtablet() {
    padding-bottom: 0;
    &__wrap {
      padding: 0; }
    .section-head {
      &__title {
        br {
          display: none; } } }
    .nav-tabs {
      display: none; }

    .tab-content {
      .tab-pane {
        display: block;
        &.active {
          .toggle-sm {
            border-bottom: 2px solid #4B61DD;
            &::after {
              transform: rotate(180deg);
              transition: transform linear .3s; }
            span {
              color: #232933;
              @include font-600(); }
            &__icon {
              img {
                filter: grayscale(0); } }
            &__content {
              display: block; } } } }
      .toggle-sm {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: start;
        padding: 12px;
        border-bottom: 1px solid #626B79;
        position: relative;
        background: #EAECFB;
        &::after {
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
          background: url(../images/ic-caret-down.svg) no-repeat center;
          background-size: contain;
          top: 20px;
          right: 7px;
          transform: rotate(0deg);
          transition: transform linear .3s; }
        &__icon {
          margin-right: 12px;
          img {
            height: 36px;
            width: auto;
            filter: grayscale(1); } }
        span {
          font-size: 16px;
          line-height: 24px;
          color: #626B79; }
        &__content {
          display: none; } }
      .promo-benefit {
        &__title {
          font-size: 28px;
          line-height: 36px; } } } } }

.calculator-block {
  padding: 64px 0;
  &__calc {
    .form {
      padding: 32px;
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);

      .form-result {
        padding: 40px 32px;
        background: #F2F4F7;
        text-align: center;
        &__fontest {
          border-bottom: 1px solid #D0D6DD;
          margin-bottom: 12px;
          .form-result__row {
            display: flex;
            justify-content: space-between;
            .label,
            .fontest {
              width: 50%;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 12px; }
            small {
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 12px;
              display: block; }
            .label {
              text-align: left; }
            .fontest {
              text-align: right; } } }
        &__instalment {
          .form-result__row {
            display: flex;
            justify-content: space-between;
            .label,
            .instalment {
              width: 50%;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 12px;
              @include font-600();
              small {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 12px;
                display: block; } }
            .label, p {
              text-align: left; }
            .instalment {
              text-align: right; } }
          @include maxtablet {
            margin-top: 16px;
            .form-result__row {
              display: block;
              .label,
              .instalment {
                width: 100%;
                display: block;
                text-align: left; } } } }
        &__interest {
          .form-result__row {
            display: flex;
            justify-content: space-between; } }
        &__warning {
          margin-top: 12px;
          display: block;
          background: #E0EEFF;
          border: 1px solid #1C44D5;
          border-radius: 4px;
          padding: 12px 12px 12px 48px;
          text-align: left;
          position: relative;
          &::before {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            top: 15px;
            left: 15px;
            background: url(../images/ic-info-blue.svg) no-repeat center;
            background-size: contain; }
          b {
            font-size: 20px;
            line-height: 28px;
            @include font-600();
            margin-bottom: 8px;
            display: block; }
          ul {
            list-style: disc;
            padding-left: 16px; } }
        &__year {
          text-align: left; }

        #fr-placeholder {
          display: block;
          transition: all ease-out .3s;
          position: relative; }
        #fr-result {
          opacity: 0;
          visibility: hidden;
          display: none;
          transition: all ease-out .3s; }
        &.show {
          background: #FFF;
          padding: 12px 0;
          &::after {
            display: none; }
          #fr-placeholder {
            opacity: 0;
            visibility: hidden;
            display: none;
            transition: all ease-out .3s; }
          #fr-result {
            opacity: 1;
            visibility: visible;
            display: block;
            transition: all ease-out .3s; } } } } }

  .section-head {
    margin-bottom: 24px; }
  @include maxtablet() {
    padding: 32px 0;
    .section-head {
      &__title {
        br {
          display: none; } }
      &__desc {
        text-align: left; }
      &__calc {
        .form {
          padding: 24px; } } } } }


.steps-block {
  padding: 64px 0;
  &__img {
    margin: 0 auto; }
  .steps {
    margin-bottom: 24px;
    ul {
      padding-left: 2.25rem;
      position: relative;
      li {
        position: relative;
        color: #626B79;
        font-size: 16px;
        line-height: 24px;
        b {
          display: block;
          margin-bottom: 8px; }
        &:hover {
          cursor: pofont; }
        &:before {
          content: "";
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #D0D6DD;
          display: block;
          position: absolute;
          top: 0;
          left: -2.25rem;
          z-index: 1; }
        &:not(:last-child) {
          padding-bottom: 1rem; }
        span.line {
          width: 2px;
          height: 100%;
          background: #D0D6DD;
          position: absolute;
          top: 0;
          left: -25px; }
        &.active {
          color: #232933;
          &:before {
            background: #fff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }
          &:after {
            content: "";
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #009BDE;
            display: block;
            position: absolute;
            top: 6px;
            left: -30px;
            z-index: 2; } } } } }

  &__steps {
    .section-head__title {
      margin-bottom: 16px;
      text-align: left; } }
  &__action {
    margin-top: 32px; }
  @include maxtablet() {
    padding: 32px 0;
    &__img {
      margin: 0 auto; }
    .steps {
      ul {
        padding-left: 2.25rem;
        position: relative;
        li {
          position: relative;
          color: #626B79;
          font-size: 16px;
          line-height: 22px;
          b {
            display: block;
            margin-bottom: 8px; }
          &:hover {
            cursor: pofont; }
          &:before {
            content: "";
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #D0D6DD;
            display: block;
            position: absolute;
            top: 0;
            left: -2.25rem;
            z-index: 1; }
          &:not(:last-child) {
            padding-bottom: 1rem; }
          span.line {
            width: 2px;
            height: 100%;
            background: #D0D6DD;
            position: absolute;
            top: 0;
            left: -25px; }
          &.active {
            color: #232933;
            &:before {
              background: #fff;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }
            &:after {
              content: "";
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: #009BDE;
              display: block;
              position: absolute;
              top: 6px;
              left: -30px;
              z-index: 2; } } } } }

    &__steps {
      .section-head__title {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 16px; } }
    &__action {
      margin-top: 32px;
      .btn {
        display: block; } } } }


.in-app {
  .tab-sticky {
    .nav-tabs {
      &.stick {
        top: 0!important; }
      &.stick.show-top {
        top: 0!important; } } } }


.steps--list {
  padding: 64px 0;
  ul.steps-list,
  ol.steps-list {
    padding-left: 0;
    position: relative;
    li {
      padding-left: 60px;
      margin-bottom: 16px;
      position: relative;
      list-style: none;
      counter-increment: li;
      min-height: 48px;
      display: flex;
      align-items: center;
      &::before {
        content: '';
        width: 1px;
        height: calc(100% + 16px);
        background: #D0D6DD;
        top: 16px;
        left: 24px;
        display: inline-block;
        position: absolute; }
      &::after {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        background: linear-gradient(340deg, #0087D9 9.7%, #00A8FD 47.12%, #66CFFF 84.53%);
        border-radius: 29px;
        border: 4px solid #EAECFB;
        left: 0;
        top: 0;
        position: absolute;
        content: counter(li);
        @include font-600();
        color: #FFF; }
      &:last-child {
        margin-bottom: 0;
        &::before {
          display: none; } }
      b {
        display: block;
        margin-bottom: 12px; }
      a {
        color: #4B61DD;
        text-decoration: underline;
        &:hover {
          color: darken(#4B61DD, 20%);
          text-decoration: none; } } } }
  .steps__desc {
    margin-bottom: 24px; }
  @include maxtablet() {
    padding: 32px 0;
    .steps__title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 20px; }
    .steps__img {
      display: block;
      padding-left: 0;
      margin-bottom: 16px; } } }


