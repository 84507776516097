.masthead__slider {
  max-height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  flex: 0 0 100%;
  width: 100%;
  .logo-marquee {
    &__group {
      display: inline-block;
      position: relative;
      width: calc(100% / 4);
      &::before {
        width: 100%;
        height: 20px;
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); }
      &::after {
        width: 100%;
        height: 20px;
        background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        bottom: 0!important;
        top: unset!important; }
      &:nth-child(2) {
        margin-top: 20%; }
      &:nth-child(3) {
        margin-top: 35%; }
      &:nth-child(4) {
        margin-top: 50%; }
      &:nth-child(even) {
        .logo-marquee__list {
          animation-direction: reverse; } } }
    &__list {
      flex-direction: column;
      animation: scroll-y 30s linear infinite;
      width: 100%;
      gap: 0;
      animation-duration: 45s;
      min-width: 100%;
      .item {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: auto;
        padding-left: 0;
        padding-right: 0;
        img {
          width: 100%;
          height: auto;
          max-height: 100%; } } } }
  @include maxtablet() {
    margin-top: 16px;
    .logo-marquee {
      &__group {
        &:first-child {
          margin-top: 0; } } } }
  @include mobilelg() {
    max-height: 299px; } }
