.cta-box {
  padding: 64px 0;
  &__wrap {
    border-radius: 16px;
    background: #F7F4FF;
    padding: 54px 54px 86px 54px;
    display: flex;
    justify-content: space-between;
    .box__left {
      max-width: 600px;
      .box__icon {
        margin-bottom: 24px;
        img {
          height: 48px;
          width: auto; } }
      .box__title {
        font-size: 32px;
        line-height: 48px;
        @include font-600();
        margin-bottom: 16px; } }
    .box__right {
      display: flex;
      align-items: center;
      .box__action {
        display: flex;
        flex-direction: column;
        gap: 24px; } } }
  &__meta {
    display: flex;
    justify-content: center;
    margin-top: -50px;
    .items {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 96px;
      background: #10243e;
      border-radius: 16px;
      padding: 24px;
      max-width: 1100px;
      .item {
        max-width: 286px;
        width: 100%;
        text-align: center;
        color: #FFF;
        position: relative;
        &__icon {
          margin-bottom: 12px;
          img {
            height: 32px;
            width: auto; } }
        &__title {
          color: #FFF;
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 12px;
          @include font-600(); }
        &::after {
          content: '';
          width: 2px;
          height: 88px;
          background: #FFF;
          border-radius: 34px;
          display: inline-block;
          position: absolute;
          right: -48px;
          top: calc((100% - 88px) / 2); }
        &:last-child {
          &::after {
            display: none; } } } } }

  @include maxtablet() {
    padding: 32px 0;
    &__wrap {
      padding: 32px 32px 64px 32px;
      flex-direction: column;
      .box__left {
        margin-bottom: 24px; }
      .box__right {
        .box__action {
          flex-direction: row; } } } }
  @include mobilelg() {
    &__wrap {
      .box__left {
        .box__title {
          font-size: 28px;
          line-height: 36px; } }
      .box__right {
        .box__action {
          flex-wrap: wrap;
          gap: 16px;
          a {
            width: 100%;
            flex: 0 0 100%;
            margin-bottom: 0; } } } }
    &__meta {
      margin: -40px auto 0 auto;
      max-width: 311px;
      .items {
        flex-wrap: wrap;
        padding: 24px;
        .item {
          &::after {
            width: 88px;
            height: 2px;
            bottom: -48px;
            right: calc((100% - 88px) / 2);
            top: unset; } } } } } }
