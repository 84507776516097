/* ------------------------------------------------------------------------------------------------ */
/* Form */
/* ------------------------------------------------------------------------------------------------ */

//label
//  display: block
.field_group {
    margin: 0 0 20px;
    label {
        display: block;
        font-size: 14px;	font-weight: 500;	letter-spacing: 0.2px;	line-height: 20px;
        margin: 0 0 5px; }

    label.error,.validation_message {
        color: #D74022;
        font-weight: 400;
        padding: 4px 0;
        font-size: 12px; letter-spacing: 0.2px;	line-height: 16px; }

    &.gfield_error {
        .field {
            border-color: $red; } }
    .gfield_required {
        color: $red;
        font-weight: 400; } }
.field {
    display: block;
    width: 100%;
    height: 36px;
    border: 1px solid #E2E2E2;	border-radius: 4px;
    background: #FFF;
    padding: 8px 12px;
    font-size: 14px;
    &:focus {
        outline : none; }
    &.error {
        border-color: $red; } }

//textarea.field
//  height: auto


.form-group {
  margin-bottom: 12px;
  position: relative;
  > label {
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 5px;
    display: block;
    @include font-500; }
  > small {
    font-size: 12px;
    line-height: 16px;
    color: #777; }
  .form-check {
    label {
      display: inline-block!important;
      @include font-400; } }
  &:last-child {
    margin-bottom: 0; }
  &.error {
    .form-control {
      border-color: #C0392B; }
    small.error {
      color: #C0392B; }
    .dropdown {
      &.bootstrap-select {
        button.select-control {
          border-color: #C0392B; } } } }
  &.success {
    position: relative;
    .form-control {
      border-color: #179E63; }
    &::after {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      background: url('../images/ic-check.svg') no-repeat center;
      background-size: contain;
      display: inline-block;
      right: 10px;
      bottom: 11px; }
    .dropdown {
      &.bootstrap-select {
        button.select-control {
          border-color: #179E63; } } } }
  &.date {
    &::after {
      position: absolute;
      content: '';
      width: 18px;
      height: 18px;
      background: url('../images/ic-calendar.svg') no-repeat center;
      background-size: contain;
      display: inline-block;
      right: 10px;
      top: 43px; } }
  &:has(input[type="checkbox"]),
  &:has(button[type="submit"]) {
    &.success {
      &::after {
        display: none; } } }
  &.form-hidden {
    &.success {
      &::after {
        display: none; } } } }


.form-action {
  position: relative; }

.form-control {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 4px;
  padding: 8px 16px;
  width: 100%;
  &:hover,
  &:focus,
  &:active:focus {
    outline: 0;
    border: 1px solid $blue; }
  &.disabled {
    background: #e9ecef; }
  &.error {
    border-color: {} } }

.radio {
    margin: 12px 0 !important;
    position: relative;
    display: block;
    cursor: pointer;
    &_con {
        font-weight: 400;
        padding-left: 30px;
        &:before,&:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #e2e2e2;
            background: #fff; }
        &:after {
            width: 8px;
            height: 8px;
            top: 6px;
            left: 6px;
            border: none; } }

    input[type="radio"] {
        position: absolute;
        top: 0;
        width: 0;
        visibility: hidden;
        &:checked + div {
            &:before {
                background: #0060BF; } } }
    &.with-input {
        .radio_con {
            &:before {
                top: 8px; }
            &:after {
                top: 14px; } } }
    &+ .radio {
        margin-top: 4px; } }

//.radio-group
//  .form-group
//      padding-left: 30px

.dropdown.bootstrap-select {
    position: relative;
    select {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: block;
        width: 0.5px;
        height: 100%;
        padding: 0;
        opacity: 0;
        border: none;
        visibility: hidden; }
    .btn,button {
        cursor: pointer;
        border: 1px solid #E2E2E2;
        border-radius: 4px;
        font-size: 16px;
        letter-spacing: -0.04px;
        line-height: 42px;
        width: 100%;
        text-align: left;
        padding: 0 23px 0 12px;
        outline: none !important;
        background: #FFF;
        position: relative;
        &::after {
          content: '';
          width: 20px;
          height: 20px;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M5 7.5L10 12.5L15 7.5" stroke="%23626B79" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          right: 10px;
          top: calc(50% - 10px); } }
    div.dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        text-align: left;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #AFAFAF;
        border-radius: 4px;
        margin: 2px 0 0;
        //padding: 10px
        width: 100%;
        min-width: 100px;
        //transform: none !important
        box-shadow: 0 16px 40px 0 rgba(0,0,0,0.25);
        a {
            display: block;
            padding: 10px 12px;
            color: #485460;
            font-weight: 400;
            cursor: pointer;
            font-size: 14px;	line-height: 20px; }
        li {
            position: relative;
            .fa {
                display: none; }
            //a.fa
            //  display: block
            //  &:before
            //      display: none
            &:hover {
                a {
                    background: rgba(#000,0.1);
                    text-decoration: none; } }
            &.selected,&.active {
                a {
                    background: rgba(#000,0.1); } }
            &:not(:last-child) {
 } }                //border-bottom: 1px solid #D8D8D7
        &.show {
            display: block; } }
    &.show {
        .bttn {
            border-color: #1565BE; } } }



.bs-searchbox {
    padding: 10px;
    background: #eee;
    .form-control {
        width: 100%;
        height: 40px;
        border: 1px solid #000;
        padding: 6px 12px; } }

.bs-placeholder {
  .filter-option-inner-inner {
    color: #777; } }

li.no-results {
    padding: 10px; }

//.form-action
//  margin-top: 24px

//.form-action
//  text-align: right
//  margin-top: 32px
//  .register_step
//      float: left
//      padding: 6px 0
//      color: #777
//  .btn
//      line-height: 36px;
//      min-width: 88px
//      padding: 0 16px
//      font-size: 16px;
//      font-weight: bold;
//      box-shadow: none
//      border: none;
//      -webkit-appearance: none;
//  .back
//      background-color: #D6D6D6;
//      color: #777777;
//      font-weight: 500
//      margin-right: 8px


//span.e-length
//  font-size: 12px;
//  margin-left: 12px;
//  background: $red;
//  color: #fff;
//  display: inline-block;
//  padding: 0 10px;
//  border-radius: 4px;

.validation_error {
    display: none; }

.gfield_radio > li {
    margin: 12px 0 !important;
    position: relative;
    display: block;
    padding-left: 30px;
    label,div.con {
        font-weight: 400;

        cursor: pointer;
        &:before,&:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #e2e2e2;
            background: #fff; }
        &:after {
            width: 8px;
            height: 8px;
            top: 6px;
            left: 6px;
            border: none; } }

    input[type="radio"] {
        position: absolute;
        top: 0;
        width: 0;
        visibility: hidden;
        &:checked + label, {
            &:before {
                background: #0060BF; } } }
    &.with-input {
        .con {
            &:before {
                top: 8px; }
            &:after {
                top: 14px; } } }
    &+ .radio {
        margin-top: 4px; } }


// GFORM
.bs {
  .gform_wrapper {
    form {
      display: flex;
      align-items: flex-end;
      @include mobile {
        flex-wrap: wrap; } }
    ul li.gfield {
      margin: 0 !important;
      margin-top: 0 !important;
      &.gfield_error {
        background: none;
        border: none;
        padding: 0; } }
    .field_description_below .gfield_description {
        padding: 0;
        color: $red;
        font-weight: 400;
        position: absolute; }
    .field {
      height: 38px !important; }


    .gform_footer {
      padding: 0 !important;
      margin: 0 !important;
      width: 150px;
      max-width: 150px;

      .gform_button {
        width: 100% !important;
        margin: 0 !important;
        line-height: 38px !important; } }


    .top_label input.medium,
    .top_label select.medium {
      width: 100% !important; }

    .field_group {
      margin-bottom: 0; } } }

// CUSTOM CONTROL
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff; }

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #FFF;
  border: 1px solid #E2E2E2; }

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 2px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: $primary; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }


.date_field {
  position: relative; }

.datepicker.datepicker-dropdown {
  position: absolute;
  background: #FFF;
  border: 1px solid #777;
  padding: 10px; }


.btn-group.btn-group-toggle {
  background-color: #FFF;
  border-radius: 1.25rem;
  border: 3px solid #FFF;
  display: flex;
  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: -10; }
  .btn {
    display: block;
    flex-grow: 1;
    flex-basis: 1;
    font-size: 0.75rem;
    line-height: 1.429em;
    @include font-500();
    margin: 0;
    border: 1.5px solid transparent;
    border-radius: 1rem;
    color: #545465;
    background: #FFFFFF;
    cursor: pointer;
    &:not(:disabled):not(.disabled):active,
    &.active {
      border-color: $primary;
      background-color: $primary;
      color: #FFF; } } }

.btn-group.btn-group--custom {
  margin-bottom: -1rem;
  .row {
    margin: 0 -0.5rem;
    &> .col,
    &> [class*="col-"] {
      padding-left: 0.5rem;
      padding-right: 0.5rem; } }
  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: -10; }
  .btn {
    display: block;
    position: relative;
    width: 100%;
    border: 1px solid $border;
    border-radius: 0.25rem;
    padding: 1.5rem;
    font-size: 0.75rem;
    line-height: 1.5em;
    margin: 0 0 1rem;
    cursor: pointer;
    .icon {
      display: block;
      height: 2rem;
      width: 2rem;
      object-fit: contain;
      margin: 0 auto 0.5rem; }
    &:hover {
      color: $text;
      border-color: #005FBF; }
    &.active {
      border-color: #005FBF;
      &:after {
        content: "";
        z-index: 1;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        background-image: url(../images/ic-check-blue.svg);
        background-color: $white;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: calc(-0.75rem + 1px);
        right: calc(-0.75rem + 1px);
        border-radius: 1.25rem;
        border: 2px solid $white; } } } }


.custom-form {
  .field_group {
    &:not(:last-child) {
      margin-bottom: 1.5rem; }
    &> label {
      display: block;
      color: #2B2A35;
      font-size: 1rem;
      line-height: 1.5em;
      @include font-500();
      margin: 0 0 0.25rem; } }

  .field {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: $text;
    background-color: #FFF;
    border: 1px solid $border;
    border-radius: 3px;
    transition: border-color 0.3s ease-in-out;
    &:focus {
      border-color: $primary;
      outline: 0; }
    &::placeholder {
      color: #AAA;
      opacity: 1; }
    &:disabled,
    &[readonly] {
      background-color: #e9ecef;
      opacity: 1; } }
  .multiple {
    display: block;
    width: 100%;
    height: auto;
    padding: 6px 12px;
    font-weight: normal;
    color: $text;
    background-color: #FFF;
    border: 1px solid $border;
    border-radius: 3px;
    transition: border-color 0.3s ease-in-out;
    &:focus {
      border-color: $primary;
      outline: 0; }
    &::placeholder {
      color: #AAA;
      opacity: 1; }
    &:disabled,
    &[readonly] {
      background-color: #e9ecef;
      opacity: 1; } }
  textarea.field {
    height: auto; }

  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%; } }

.form-hidden {
  display: none;
  position: absolute;
  visibility: hidden; }


.form-row {
  small {
    display: none; }
  .error {
    .form-control {
      border-color: #C02A34; }
    small {
      display: inline-block;
      color: #C02A34;
      font-size: 10px;
      line-height: 18px; } } }

input:-internal-autofill-selected {
  background: #FFF!important; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px white inset !important; }

.captcha-wrap {
  display: flex;
  align-items: center;
  margin: 24px 0; }

.captcha-field {
  padding: 8px 16px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 150px;
  height: 36px; }

.captcha-canvas {
  /*prevent interaction with the canvas */
  pointer-events: none;
  margin-right: 8px;
  display: flex;
  align-items: center;
  .captcha {
    background: #f6f6f7;
    border-radius: 6px; } }

.captcha-refresh {
  width: 20px;
  height: 20px;
  background: url(../images/ic-refresh.png) no-repeat center;
  background-size: contain;
  display: inline-block;
  cursor: pointer;
  opacity: .8;
  margin-right: 24px;
  &:hover {
    opacity: 1; } }
