.block-feat {
  &__title {
    text-align: center;
    margin: 0 0 8px; }
  &__desc {
    text-align: center;
    margin: 0 0 24px; }
  &__action {
    justify-content: center;
    margin: 0 -8px 24px;
    display: flex;
    .btn {
      margin-left: 8px;
      margin-right: 8px; } }
  &__list {
    display: block;
    width: 100%;
    padding: 12px;
    margin-right: 24px;
    .item {
      &__head {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #D0D6DD;
        cursor: pointer;
        transition: all .4s ease;
        .icon {
          display: block;
          width: 100%;
          max-width: 60px;
          flex: 0 0 60px;
          padding: 12px;
          .box {
            img {
              display: block;
              width: 36px;
              height: 36px;
              filter: grayscale(100);
              opacity: 0.7;
              transition: all .4s ease; } } }
        .text {
          display: block;
          width: 100%;
          max-width: calc(100% - 60px);
          flex: 0 0 calc(100% - 60px);
          @include font-500;
          color: #626B79; } }
      &__box {
        display: none; }
      &.active {
        .item__head {
          border-bottom-color: #4B61DD;
          border-bottom-width: 4px; }
        .icon .box {
          img {
            filter: grayscale(0);
            opacity: 1; } }
        .text {
          color: #232933; } }
      &__action {
        a {
          margin-right: 16px;
          &:last-child {
            margin-right: 16px; } } } } }
  &__box {
    background: #EAECFB;
    padding: 32px;
    display: none;
    &.active {
      display: block; }
    img {
      margin: 0 auto 24px auto; }
    h3 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      @include font-600(); }
    .item__action {
      a {
        margin-right: 16px;
        &:last-child {
          margin-right: 16px; } } } }
  &__left {
    padding-top: 24px;
    padding-right: 52px !important; }
  &__right {
    padding-top: 24px; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    padding-left: 8px;
    padding-right: 8px;
    .item {
      &__box {
        background: #F2F4F7;
        padding: 24px 24px 32px;
        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          margin: 0 auto 24px auto; } }


      &.active {
        .item__box {
          display: block; } } }
    &__action {
      flex-wrap: wrap;
      margin: 0;
      .btn {
        display: block;
        margin-bottom: 8px; } }
    &__list {
      padding: 0; }
    &__box {
      padding: 0 24px 24px;
      display: none;
      &.active {
        display: none; } }
    &__left {
      padding-right: 12px !important; }
    &__right {
      display: none; }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } }

  @include mobilexl() {
    .item {
      &__action {
        a {
          margin-right: 0;
          margin-bottom: 16px;
          &.btn {
            width: 100%;
            display: block; }
          &:last-child {
            margin-bottom: 0; } } } }
    &__box {
      .item__action {
        a {
          margin-right: 0;
          margin-bottom: 16px;
          &.btn {
            width: 100%;
            display: block; }
          &:last-child {
            margin-bottom: 0; } } } } } }
