.card-testi {
  padding: 64px 0;
  .card__wrap {
    margin-top: 32px; }
  &__filter {
    padding-top: 16px;
    form {
      display: flex; }
    .form-group {
      margin: 0;
      display: block;
      width: 100%;
      max-width: 35%;
      flex: 0 0 35%;
      padding-left: 8px;
      padding-right: 8px; }
    .form-action {
      padding-top: 0;
      display: block;
      width: 100%;
      max-width: 30%;
      flex: 0 0 30%;
      padding-left: 8px;
      padding-right: 8px;
      .btn {
        display: block;
        width: 100%; } } }
  .card {
    border-radius: 4px;
    overflow: hidden;
    border: 0;
    height: calc(100% - 24px);
    margin-bottom: 24px;
    &__img-box {
      display: block;
      width: 100%;
      padding-top: 56%;
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center; } }
    &__content {
      padding: 24px;
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%; }
    &__icon {
      position: absolute;
      width: 24px;
      height: 24px;
      object-fit: contain;
      top: 25px;
      left: 24px; }
    &__title {
      padding-left: 32px;
      @include font-600();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
      &.no-icon {
        padding-left: 0; } }
    &__desc {
      margin-bottom: 16px;
      p {
        min-height: 96px; } }
    &__info {
      margin-bottom: 4px;
      b,span {
        display: block; }
      b {
        @include font-600(); } }
    &__logo {
      height: 48px;
      width: auto;
      object-position: left center;
      object-fit: contain; }
    &__action {
      margin-top: 16px;
      visibility: hidden;
      opacity: 0;
      transition: all linear .3s; }
    &:hover {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
      .card__action {
        visibility: visible;
        opacity: 1;
        transition: all linear .3s; } } }
  @include maxtablet() {
    padding: 32px 0;
    &__filter {
      padding-top: 8px;
      form {
        display: block; }
      .form-group {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 8px;
        padding: 0; }
      .form-action {
        margin-top: 8px;
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0; } }
    .card {
      border: 1px solid #D0D6DD;
      &__icon {
        left: 12px;
        top: 13px; }
      &__content {
        padding: 12px 12px 24px 12px; }
      &__action {
        visibility: visible;
        opacity: 1; }
      &:hover {
        box-shadow: none; } } } }
